const env = require("../../config.js").default;
const common = require("./common.jsx");

class customConfig{
  // csoft
  // prefix
  // languages []

  async init(){
    var localConfig, requestedData = [], doRequest = false;
    this.frontendCustomization = {};
    this.frontendCustomization.header="header_centrato";
    if(!common.get_localStorage("customConfig") || common.get_localStorage("customConfig")===null || common.get_localStorage("customConfig")===undefined || common.get_localStorage("customConfig")==="" ){
      doRequest = true;
      requestedData.push("customConfig");
    }else{
      localConfig = JSON.parse(common.get_localStorage("customConfig"));
      for (var index in localConfig){
        this[index] = localConfig[index];
      }
    }
    if(!common.get_localStorage("language") || common.get_localStorage("language")===null || common.get_localStorage("language")===undefined || common.get_localStorage("language")==="" ){
      doRequest = true;
      requestedData.push("currentLanguage");
    }
    if(!common.get_localStorage("labels") || common.get_localStorage("labels")===null || common.get_localStorage("labels")===undefined || common.get_localStorage("labels")==="" ){
      doRequest = true;
      requestedData.push("labels");
    }else{
      env.labels = JSON.parse(common.get_localStorage("labels"));
    }
    if(!common.get_localStorage("nazioni") || common.get_localStorage("nazioni")===null || common.get_localStorage("nazioni")===undefined || common.get_localStorage("nazioni")==="" ){
      doRequest = true;
      requestedData.push("nazioni");
    }

    if(common.get_localStorage("subscriptions") !== JSON.stringify(window.cc.subscriptions)){
      doRequest = true;
      requestedData.push("subscriptions");
    }

    if(!common.get_localStorage("terms") || common.get_localStorage("terms")===null || common.get_localStorage("terms")===undefined || common.get_localStorage("terms")===""){
      doRequest = true;
      requestedData.push("terms");
    }
    if(common.get_localStorage("userInfo") !== undefined){
      this.marche_portale = JSON.parse(common.get_localStorage("userInfo"))['marche_portale'];
      this.marche_attive = JSON.parse(common.get_localStorage("userInfo"))['marche_attive'];
      this.tipologieTicket = JSON.parse(common.get_localStorage("userInfo"))['tipologieTicket'];
      window.cc.assetsPath = JSON.parse(common.get_localStorage("userInfo"))['assetsPath'];
      window.cc.checkDiskSpace = JSON.parse(common.get_localStorage("userInfo"))['checkDiskSpace'];
    }
    if(!common.get_localStorage("sisterCompany") || common.get_localStorage("sisterCompany")===null || common.get_localStorage("sisterCompany")===undefined || common.get_localStorage("sisterCompany")===""){
      doRequest = true;
      requestedData.push("sisterCompany");
    }

    if(doRequest)
      await this.getSWVar(requestedData);
  }

  async getSWVar(requestedData){
    await common.apiCall(env.BACKEND + "@swvar?lang="+common.getLanguage(true), "GET", {}, false)
    .then(backendConfig=>{
      if(backendConfig.error === false){
        if(requestedData.indexOf("customConfig") > -1){
          for (var index in backendConfig){
            if(index !== "labels" || index !== "nazioni" || index !== "currentLanguage")
              this[index] = backendConfig[index];
          }
          this.companyInfo = JSON.parse(this.companyInfo);
          this.frontendCustomization = JSON.parse(this.frontendCustomization);

          var customConfig = JSON.stringify(this);
          common.set_localStorage("customConfig", customConfig);
        }

        if(requestedData.indexOf("labels") > -1){
          common.set_localStorage("labels", JSON.stringify(backendConfig.labels));
          env.labels = backendConfig.labels;
        }
        if(common.get_localStorage("userInfo") !== undefined){
          this.marche_portale = JSON.parse(common.get_localStorage("userInfo"))['marche_portale'];
          this.marche_attive = JSON.parse(common.get_localStorage("userInfo"))['marche_attive'];
          this.tipologieTicket = JSON.parse(common.get_localStorage("userInfo"))['tipologieTicket'];
          window.cc.assetsPath = JSON.parse(common.get_localStorage("userInfo"))['assetsPath'];
          window.cc.checkDiskSpace = JSON.parse(common.get_localStorage("userInfo"))['checkDiskSpace'];
        }

        if(requestedData.indexOf("currentLanguage") > -1){
          common.set_localStorage("language", backendConfig.currentLanguage);
        }

        if(requestedData.indexOf("nazioni") > -1){
          common.set_localStorage("nazioni", JSON.stringify(backendConfig.nazioni));
        }
        if(requestedData.indexOf("terms") > -1){
          common.set_localStorage("terms", JSON.stringify(backendConfig.terms));
        }
        if(requestedData.indexOf("sisterCompany") > -1){
          common.set_localStorage("sisterCompany", JSON.stringify(backendConfig.sisterCompany));
          window.cc.sisterCompany = backendConfig.sisterCompany;
        }


        if(requestedData.indexOf("subscriptions") > -1){
          var subscriptionsOrder = [];
          Object.keys(subscriptionsOrder).map((index)=>{
            subscriptionsOrder.push(index);
            return "";
          });
          subscriptionsOrder.sort(function(a, b){
            if(backendConfig["subscriptions"][a]["hours"] > backendConfig["subscriptions"][b]["hours"])
              return 1
            else
              return -1
          });

          common.set_localStorage("subscriptions", JSON.stringify(backendConfig.subscriptions));
          this.subscriptions = backendConfig.subscriptions;
        }
      }else{
        return false;
      }
    })
  }

}

export default customConfig;
