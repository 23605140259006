import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
const common = require('../common/common');
const env = require("../../config.js").default;

try {
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
}

 class NewsInEvidenzaBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount(props){

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;
    var data = { fullobjects: 1, Subject: "sw_in_evidenza", sort_on: "modified", sort_order: "reverse", "effective_evidenza.query": formattedToday, "effective_evidenza.range": "max", "expires_evidenza.query": formattedToday, "expires_evidenza.range": "min" };
    common.apiCall(env.BACKEND + common.getLanguage() + '/@search', "GET", data, true, true, "json", false, '')
    .then(newsAnswer=>{
      if(newsAnswer.error === false)
        this.setState({newsAnswer, paginationLength: newsAnswer.items.length});
      else
        console.error("Error");
    });
  }

  renderNewsInEvidenza(responsive){
    try{
      var resultNodes = this.state.newsAnswer.items.map((result, key) => {
        result.shortDescription = truncateWords(result.description, 100, '...');
        return (
          <NewsInEvidenza key={key} mykey={key} thisNews={result} responsive={responsive}/>
        );
      });
      return resultNodes;
    }catch (error){
      return ;
    }
  }

  render(props) {
    var sw_label_evidenza = common.getTrad('In evidenza');
    if(!this.props.responsive){
      return (
        <div className="col-lg-4 col-md-5 col-sm-5 hidden-xs-down" id="newsPreview">
          <h2 className="titolo_news_head animated lightSpeedIn " style={{animationDelay: '0.5s' }}>
            {sw_label_evidenza}
          </h2>
          <div className="content-news-circolari content_news" style={{overflowX: "auto"}}>
            {this.renderNewsInEvidenza(false)}

          </div>
        </div>
      );
    }else{
      var paginationHtml = [];
      if(this.state.paginationLength !== undefined){
        for (var i = 0; i < this.state.paginationLength; i++) {
          paginationHtml.push(<li data-target="#carouselExampleInterval" key={i} data-slide-to={i} className={i === 0 ? "active" : ""}></li>)
        }
      }
      return(
        <div className="carousel-container content_news">
          <h2 className="titolo_news_head">
            {sw_label_evidenza}
          </h2>
          <div id="carouselExampleInterval" className="carousel slide" data-ride="carousel">

            <div className="carousel-inner">
              {this.renderNewsInEvidenza(true)}
            </div>
            <ol className="carousel-indicators">
              {paginationHtml}
            </ol>
          </div>
        </div>
      )
    }
  }
}


class NewsInEvidenza extends React.Component {
  constructor(props){
    super(props)
    this.state = {};
    this.state.userInfo={};
    this.state.userInfo.roles=[];
  }
  UNSAFE_componentWillMount(){
    //Carica le informazioni dell'utente nello state
    common.getUserInfo().then(userInfo =>{
      if(userInfo !== undefined && userInfo !== false){
        this.setState({userInfo: userInfo});
      }
    });
  }

  componentDidMount(){
    const $ = window.$;
    $('.carousel').carousel();
  }

  render(props) {
    if(!this.props.responsive){
      return (
        <div className="media_col animated fadeInDown" style={{animationDelay: "0.0s"}}>
  				<div className="media-body">
  					<Link to={common.getUrlPath(this.props.thisNews["@id"])}>
  						<h4 className="media-heading">{this.props.thisNews.title}</h4>
  					</Link>
  					<span><small>{moment(this.props.thisNews.created).format('LL')}</small></span>

            {common.beautifyShortDescription(this.props.thisNews.shortDescription)} {common.set_localStorage("goBackPreviousPage", "")} {common.set_localStorage("goBack", "0")}
  				</div>

  				<div className="newsFooter">
  					<div className="tagContainer animated fadeIn" style={{animationDelay: "0.8s"}}>
              {
                common.getUrlPath(this.props.thisNews.parent["@id"]).search("/messaggi-sistema") > -1 && this.state.userInfo.roles.indexOf("Reviewer") <= -1 ?
                  <>
                    <i className="fas fa-tag"></i>&nbsp;
                    {this.props.thisNews.parent.title}
                  </>
                :
                <Link to={common.getUrlPath(this.props.thisNews.parent["@id"])} >
                  <i className="fas fa-tag"></i>&nbsp;
                  {this.props.thisNews.parent.title}
                </Link>
              }
  					</div>
  					<Link to={common.getUrlPath(this.props.thisNews["@id"])} className="link_news animated jackInTheBox" style={{animationDelay: "1.0s"}}>
              <span>+</span> {common.getTrad("Continua a leggere")} {common.set_localStorage("goBackPreviousPage", "")} {common.set_localStorage("goBack", "0")}
  					</Link>
            <div className="clear"></div>
  				</div>
  			</div>
      );
    } else{
      return(
        <div className={(this.props.mykey === 0 ? "active" : "") + " carousel-item"}>
          <div className="category_home">
            <Link to={common.getUrlPath(this.props.thisNews["@id"])}>
              <h4 className="media-heading">{this.props.thisNews.title}</h4>
            </Link>
            <span><small>{moment(this.props.thisNews.created).format('LL')}</small></span><br/>
            {common.beautifyShortDescription(this.props.thisNews.shortDescription)}
          </div>
        </div>
      )
    }
  }
}


function truncateWords(sentence, amount, tail) {
  const words = sentence.split(' ');

  if (amount >= words.length) {
    return sentence;
  }

  const truncated = words.slice(0, amount);
  return `${truncated.join(' ')}${tail}`;
}


export default NewsInEvidenzaBox;
