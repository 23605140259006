import React from 'react';
import { Link } from "react-router-dom";
import bootbox from 'bootbox';
const common = require('../common/common');
const env = require("../../config.js").default;
var tus = require("tus-js-client")

class Manuali extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.userInfo = {};
    this.state.elencoMan = {};
    this.state.elencoMan.items = [];
    this.state.renderMan = false;
    this.state.metodoUpload = '';
  }

  UNSAFE_componentWillMount(props){
    document.getElementById('body').className='content_page';
    common.setStateUserInfo(this);
  }

	componentDidMount(props){
    var autenticated = false;
    if(this.props.roles.indexOf("Authenticated") > -1)
      autenticated = true;

    common.apiCall(common.getBackendUrl(env.BACKEND + "@sw-get-man"), "GET", {}, autenticated)
    .then(elencoMan=>{
      if(this.state.userInfo.is_admin_softway || (elencoMan !== undefined && elencoMan.error === false && Object.keys(elencoMan).length - 1 > 0))
        this.setState({elencoMan: elencoMan, renderMan: true});
    });
  }

  componentDidUpdate() {
    const $ = window.$;
    if (JSON.parse(common.get_localStorage("sidebarOpen"))) {
      $("#content_sidebar").removeClass("pushed");
      $(".blocco_menu_laterale").removeClass("no_width");
      $("#content_page").removeClass("main-pushed");
      $(".mobile-bar").addClass('open');
      // Mostro icona chiusura (sidebar aperta)
      $('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'translate(0, 8px) rotate(45deg)');
      $('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '0');
      $('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'translate(0, -8px) rotate(-45deg)');
    } else {
      $("#content_sidebar").addClass("pushed");
      $(".blocco_menu_laterale").addClass("no_width");
      $("#content_page").addClass("main-pushed");
      $(".mobile-bar").removeClass('open');
      // Mostro icona menù (sidebar chiusa)
      $('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'none');
      $('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '1');
      $('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'none');
    }
  }

  openSidebar(e){
    e.preventDefault();
    const $ = window.$;
    $("#content_sidebar").toggleClass("pushed");
    $(".blocco_menu_laterale").toggleClass("no_width");
    $("#content_page").toggleClass("main-pushed");
    $('.bar').toggleClass('animate');
    $(".mobile-bar").toggleClass('open');
    common.set_localStorage("sidebarOpen", !$("#content_sidebar").hasClass("pushed"));
    // Check se la sidebar è aperta o è chiusa
    if ($("#content_sidebar").hasClass("pushed") === true) {
      $('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'none');
      $('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '1');
      $('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'none');
    } else {
      $('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'translate(0, 8px) rotate(45deg)');
      $('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '0');
      $('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'translate(0, -8px) rotate(-45deg)');
    }
  }

  elencoUpload(linkManuale, langMan, key){
    const $ = window.$;
    $('#formManualeFile').trigger('click');
    $('#formManualeFile').change((e) => {
      e.preventDefault();

      var file =  e.target.files[0], linkManualeUpload = '';
      var data = {SearchableText: langMan, portal_type: "File", show_inactive: 1}
      common.apiCall(common.getBackendUrl(linkManuale + "/@search"), "GET", data, true)
      .then(editManuale=>{
        let metodoUpload = '';
        if(editManuale.items_total > 0){
          metodoUpload = "@tus-replace";
          linkManualeUpload = editManuale.items[0]['@id'];
        }else{
          metodoUpload = "@tus-upload";
          linkManualeUpload = linkManuale;
        }
        // Get the selected file from the input element
        // Create a new tus upload
        var upload = new tus.Upload(file, {
          endpoint: linkManualeUpload + "/"+ metodoUpload,
          retryDelays: [0, 3000, 5000, 10000, 20000],
          withCredentials: true,
          overridePatchMethod: false,
          headers: {
            'Accept': 'application/json',
            "authorization": "Bearer " + common.getLocalLoginToken(),
          },
          metadata: {
            filename: langMan +"." + (file.name).split(".")[1],
            filetype: file.type
          },
          onError: function(error) {
            $('#notifica_messaggio').html('<div class="sw-alert alert alert-danger alert-dismissible fade show">' + common.getTrad("Errore qualcosa è andato storto.") + '<br/>' + error +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'+
            '<span aria-hidden="true">&times;</span>'+
            '</button></div>');
          },
          onProgress: function(bytesUploaded, bytesTotal) {
            var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
            $(".bottone_default").attr("disabled", true);
            $("#row_" + key +"  .progress").show();
            $("#row_" + key +"  .progress .progress-bar").css("width", percentage + "%").html(percentage + "%");
          },
          onSuccess: function() {
            setTimeout(function(){
              $("#row_" + key +"  .progress").hide();
              $(".bottone_default").removeAttr("disabled");
              $('#notifica_messaggio').html('<div class="sw-alert alert alert-success animated">' + common.getTrad("Caricamento elemento avvenuto correttamente.") + '</div>');
              window.location.reload()
            },5000);
          }
        });
        upload.start()
      });
    });
    return false;
	}

  async handleDownloadFile(id, fileurl, parentTitle){
    const $ = window.$;
    $(".downloadFileLoader_" + id).css("display", "inline");
    $(".downloadIconFile_" + id).css("display", "none");
    var autenticated = false;
    if(this.props.roles.indexOf("Authenticated") > -1){
      autenticated = true;
    }
    await common.apiCall(fileurl, "GET", {}, autenticated, false, "json")
    .then(data => {
      common.apiCall(data.file.download, "GET", {}, autenticated, false, "blob")
      .then(result => {
        var element = document.createElement('a');
        element.setAttribute('href', result);
        element.setAttribute('download', parentTitle);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
        $(".downloadFileLoader_" + id).css("display", "none");
        $(".downloadIconFile_" + id).css("display", "inline");
      });

      return data;
    });
    return;
  }

  handleClickRemoveFile(elementToRemove, folderMan, nameMan){
    bootbox.confirm({
      message: common.getTrad("L'azione cancellerà in modo definitivo il manuale <b>{{titolo_manuale}}</b> dalla cartella <b>{{cartella_manuale}}</b>.<br><b>Si è sicuri di volerlo eliminare?</b>", {titolo_manuale: nameMan, cartella_manuale: folderMan}),
      buttons: {
        confirm: {
          label: common.getTrad('Si'),
          className: 'btn-danger'
        },
        cancel: {
          label: common.getTrad('No'),
          className: 'btn-light'
        }
      },
      callback: (result) => {
        if(result){
          this.deleteContent(elementToRemove);
        }
      }
    });
  }

  async deleteContent(elementToRemove){
		var url = common.getBackendUrl(elementToRemove), response;

		response = await common.apiCall(url, "DELETE", {}, true, false, "blob");
    if(response)
      window.location.reload()
	}

  render(props){
    var manObjGroup = Object.keys(this.state.elencoMan);
    return (
      <div id="portal-columns" className="clearfix">
        <div className="row">
          {this.props.login ?
            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-4 blocco_menu_laterale">
              <div className="mobile-bar cons">
                {/* <div className="hamburger-menu cons" onClick={(e) => this.openSidebar(e)}>
											<div className="bar cons"></div>
										</div> */}
                <div className="hamburger">
                  <input
                    type="checkbox"
                    className="check_hamburger"
                    name="check_hamburger"
                    checked={common.get_localStorage("sidebarOpen")}
                    onClick={(e) => this.openSidebar(e)}
                  />
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>

              <div id="content_sidebar">
                <div className="card">
                  <div className="card-header">
                    <Link className="card-link" title={common.getTrad("Home Page")} to="/">
                      <span className="ico-menu">
                        <figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-home.svg"} title={common.getTrad("Home Page")} alt={common.getTrad("Home Page")} className="svg icona_menu"></figure>
                      </span>
                      <span className="title-menu">{common.getTrad("Home Page")}</span>
                    </Link>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <Link className="card-link" title={common.getTrad("Manuali")} to="/">
                      <span className="ico-menu">
                        <figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-rmi.svg"} className="svg icona_menu"></figure>
                      </span>
                      <span className="title-menu">{common.getTrad("Manuali")}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          :
            ""
          }
          <div id="notifica_messaggio"></div>
          <div className={(this.props.login ? "col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8" : "col-xl-12")  + " linea_sfumata"} id="content_page">
            <div className="row">
              <div className="content_titolo">
                <div className="content_titolo_int">
                  <span className="titolo_sezione">{common.getTrad("Elenco manuali")}
                  </span>
                </div>
              </div>
            </div>
            <div id="content-core" className="row" style={{width: '100%'}}>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
                <div className="tab-pane active">
                  <div className="row">

                  {this.state.renderMan ?
                    manObjGroup.map((result, key) => {
                      if(this.state.elencoMan[result] !== false){
                        return (
                          <div className="col-xl-12 mb-5" id={"row_" + key} key={key}>
                            <h2>
                              {common.getTrad(this.state.elencoMan[result].title)}
                            </h2>
                            <div className="btn-group">
                              <button className="btn dropdown-toggle bottone_default" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className={"downloadIconFile_" + key}><i className="fas fa-download"></i></span>
                                <span style={{display: "none"}} className={"downloadFileLoader_" + key}><i className="fas fa-spinner fa-spin"></i></span>
                                &nbsp;&nbsp;{common.getTrad("Download")}
                              </button>
                              <div className="dropdown-menu">
                                {this.state.elencoMan[result].items !== undefined && this.state.elencoMan[result].items.length > 0 ?
                                  this.state.elencoMan[result].items.map((resultChild, keyChild) => {
                                    return (
                                      <div key={Math.random()}>
                                        <div style={{width: '80%', float: 'left'}}>
                                          <button style={{padding: '.25rem 1rem'}} onClick={() => {this.handleDownloadFile(key, resultChild['@id'], common.getTrad(this.state.elencoMan[result].title) + "_" + resultChild.title)}} type="button" className="dropdown-item">
                                            <img alt={common.getTrad("lingua_" + resultChild.title.toUpperCase().split('.')[0])} title={common.getTrad("lingua_" + resultChild.title.toUpperCase().split('.')[0])} width="25" src={env.STATIC + "images/bandiere/" + resultChild.title.split('.')[0] + ".svg"} />
                                              &nbsp;{common.getTrad("lingua_" + resultChild.title.toUpperCase().split('.')[0])}
                                          </button>
                                        </div>
                                        {this.state.userInfo.is_admin_softway ?
                                          <button style={{width: '20%', float: 'left', padding: '0px'}} className="btn float-right" onClick={() => this.handleClickRemoveFile(resultChild['@id'], common.getTrad(this.state.elencoMan[result].title), resultChild.title)} >
                                            <i className="fas fa-trash-alt"></i>
                                          </button>
                                        :
                                          <></>
                                        }
                                      </div>
                                    )
                                  })
                                :
                                  <></>
                                }
                               </div>
                            </div>
                            {this.state.userInfo.is_admin_softway ?
                              <div className="btn-group ml-0 ml-sm-3 mt-3 mt-sm-0">
                                <button className="btn dropdown-toggle bottone_default" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="fas fa-upload"></i> {common.getTrad("Upload")}
                                </button>
                                <div className="dropdown-menu elencoUpload">
                                  {window.cc.languages.map((resultItem, keyItem) => {
                                    return (
                                      <button onClick={() => this.elencoUpload(this.state.elencoMan[result].upload, resultItem.toLowerCase(), key)} type="button" key={Math.random()} className="dropdown-item">
                                        <img alt={common.getTrad("lingua_" + resultItem.toUpperCase())} title={common.getTrad("lingua_" + resultItem.toUpperCase())} width="25" src={env.STATIC + "images/bandiere/" + resultItem + ".svg"} />
                                         &nbsp;{common.getTrad("lingua_" + resultItem.toUpperCase())}
                                      </button>
                                    )
                                  })}
                                </div>
                              </div>
                            :
                              <></>
                            }
                            <hr/>
                            <div className="progress" style={{display: 'none'}}>
                              <div className="progress-bar progress-bar-striped" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div>
                        )
                      }
                    })
                  :
                    <div className="col-xl-12 text-center content_error">
                      <h1 className="animated fadeInUp delay-03s">&nbsp;</h1>
                      <h3 className="animated fadeInUp delay-06s">
                        {common.getTrad("Nessun manuale presente nel Service.")}
                      </h3>
                      <br/>
                      <h3 className="animated fadeInUp delay-09s">
                        <Link to="/">
                          <i className="fas fa-arrow-left"></i>
                          <b>{common.getTrad("Torna alla Home Page")}</b>
                        </Link>
                      </h3>
                    </div>
                  }
                    <form id="formManuale" method="POST" encType="multipart/form-data" style={{display: "none"}}>
              			  <input type="file" name="file_manuale" id="formManualeFile" />
              			</form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Manuali;
