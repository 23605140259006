import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
const common = require('../common/common');
const env = require("../../config.js").default;

try {
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
}
class MainteinanceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.pathname = this.props.pathname;
    this.state.render = false;
    this.state.tagliando = {};
    this.state.tagliando.veicolo = [];
    this.state.tagliando.tagliandi = {};
  }

  UNSAFE_componentWillMount(props) {
    this.setState({ render: false });
  }

  cercaTagliandi() {
    const $ = window.$;
    // filtro telaio
    if ($("#EDTNTELA").val() === '') {
      $("input#EDTNTELA").addClass("is-invalid");
      $("input#EDTNTELA").attr("placeholder", common.getTrad("Inserisci il telaio..."));
      this.setState({ render: false });
    } else {
      $("input#EDTNTELA").removeClass("is-invalid");
      $('input#EDTNTELA').attr('readonly', 'readonly');
      $("#btn_cercaT").addClass("loading").attr("disabled", true);
      var data = $("#EDTNTELA").val();
      common.apiCall(env.BACKEND + "@get-tagliandi?telaio=" + data, "GET", {}, true)
        .then(response => {
          $("#resetta_ricerca").show();
          $("#btn_cercaT").hide().removeClass("loading").attr("disabled", false);
          this.setState({ render: true, tagliando: response });
          $("#table_MainteinanceTable").DataTable({
            "retrieve": true,
            responsive: true,
            "tabIndex": -1,
            "paging": false,
            "info": false,
            "searching": false,
            "lengthChange": false,
            data: this.state.tagliando.tagliandi,
            columns: [
              {
                title: common.getTrad("Rif. Doc."),
                "width": "20px",
                data: "TNDOC",
                "render": function (data, type, row) {
                  var apStr = row.NDOCU + "";
                  var apDim = apStr.length;
                  var apNum = ('00000' + row.NDOCU)
                  return (data + apNum.substr(apDim, 5))
                }
              },
              {
                title: common.getTrad("Data intervento"),
                data: "UDOCU",
                type: 'datetime-moment',
                "render": function (data, type, row) {
                  if (data !== null) {
                    return moment(data).format('LL')
                  } else {
                    return ""
                  }
                }
              },
              {
                title: common.getTrad("Causale"),
                data: "CCAUS",
              },
              {
                title: common.getTrad("Km"),
                data: "NKMPE"
              },
              {
                title: common.getTrad("N. Tagliando"),
                data: "NDOCU"
              },
              {
                title: common.getTrad("Dealer"),
                data: "DRIVE",
                visible: (window.cc.csoft === 850100 ? false : true),
              },
              {
                "class": "show_note_link",
                orderable: false,
                data: "DDOCU",
                "render": function (data, type, row, meta) {
                  return ReactDOMServer.renderToStaticMarkup(
                    <button data-id={meta.row} data-note={data} className={"btn btn-light show_note nota_" + meta.row}>
                    </button>
                  )
                }
              }
            ],
            "language": {
              "url": env.STATIC + "lib/datatables/i18n/" + common.getLanguage() + ".lang"
            },
            fnRowCallback: (nRow, aData, displayNum, iDisplayIndex, iDisplayIndexFull) => {
              $(nRow).on('click', '.show_note_link', (e) => {
                var table = $("#table_MainteinanceTable").DataTable();
                var row = table.row(nRow)
                if (row.child.isShown()) {
                  row.child.hide();
                  $(".nota_" + displayNum).removeClass("show");
                } else {
                  $(".nota_" + displayNum).addClass("show");
                  row.child(common.getTrad("Note") + ": " + (aData.DDOCU !== undefined && aData.DDOCU !== null && aData.DDOCU !== '' ? aData.DDOCU : "")).show();
                  $(row.child()).addClass($(nRow)[0].className)
                }
                return false;
              });
            }
          });
        });
      $('table').resize();
    }
  }

  componentDidMount(props) {
    const $ = window.$;
    $("#resetta_ricerca").hide();


    // abilito il cerca telaio solamente se clicco su bottone oppure clicco con un INIVO.
    // l'INVIO funziona solamente al primo tentativo. per evitare che la tabella dia errore e venga caricata più volte.
    $("#btn_cercaT").click((e) => {
      e.preventDefault();
      this.cercaTagliandi();
      if ($("input#EDTNTELA").val() != '' && this.state.tagliando.status === undefined) {
        this.cercaTagliandi();
      }
    });

    $(document).keypress(function (e) {
      if (!$("input#EDTNTELA").attr("readonly")) {
        if (e.which === 13) {
          $('#btn_cercaT').click();
        }
      }
    });

    $("#resetta_ricerca").click((e) => {
      e.preventDefault();
      this.setState({ render: false });
      $("input#EDTNTELA").val("");
      $('input#EDTNTELA').removeAttr('readonly');
      $("#btn_cercaT").show();
      $("#resetta_ricerca").hide();
    });
  }

  componentDidUpdate(props) {
    const $ = window.$;
    $("#table_MainteinanceTable").on("click", ".show_note", (e) => {
      e.preventDefault();
      var ID = $(e.target).data('id');
      var DDOCU = $(e.target).data('note');
      if (!$(".note_responsive_" + ID).hasClass("show")) {
        $(".nota_" + ID).addClass("show");
        $("ul.dtr-details[data-dtr-index='" + ID + "']").append('<li class="note_responsive_' + ID + ' show"><span class="dtr-data">' + (DDOCU !== undefined && DDOCU !== null && DDOCU !== '' ? DDOCU : "") + '</span></li>');
      } else {
        $(".nota_" + ID).removeClass("show");
        $(".note_responsive_" + ID).remove();
      }
      return false;
    });
  }

  render(props) {
    return (
      <>
        <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 p-0" role="alert" id="ricerca_filtro_telaio">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 content_filtro">
            <h4>{common.getTrad("Ricerca Telaio")}
              <figure className="svg" src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-telaio.svg"} alt={common.getTrad("Ricerca Telaio")} title={common.getTrad("Ricerca Telaio")}></figure></h4>
            <hr />
            <div className="input-group">
              <input name="EDTNTELA" id="EDTNTELA" className="form-control input-sm text-uppercase" type="text" maxLength="17" />
              <span className="input-group-btn bottone_ricerca">
                <button id="btn_cercaT" className="btn btn-light">
                  <span className="d-none d-md-block d-sm-block d-md-none">
                    <span title={common.getTrad("Cerca")} className="fas fa-search initialIcon"></span>
                    <span className="fas fa-spinner fa-spin loadingIcon"></span>
                  </span>
                  <span className="d-block d-sm-none">
                    <span className="fas fa-search initialIcon"></span>
                    <span className="fas fa-spinner fa-spin loadingIcon"></span>
                  </span>
                </button>
                <button id="resetta_ricerca" className="btn btn-light">
                  <span title={common.getTrad("Resetta")} className="fas fa-redo initialIcon"></span>
                  <span className="fas fa-spinner fa-spin loadingIcon"></span>
                </button>
              </span>
            </div>
          </div>
        </div>
        {this.state.render ?
          this.state.tagliando.status === 200 ?
            <div className="tab active">
              <div className="col">
                <h4>{common.getTrad("Dati del veicolo")}</h4>
                <div className="d-none d-md-block">
                  <table id="dati_veicolo" className="table table-striped table-bordered dt-responsive nowrap mb-5" style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Marca")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.ZMAVE}</td>
                        <td className="voce_dati"><b>{common.getTrad("Stato")}</b></td>
                        <td className="valore_dati">
                          {this.state.tagliando.veicolo.FSTAT !== undefined && this.state.tagliando.veicolo.FSTAT !== null ?
                            this.state.tagliando.veicolo.FSTAT === "N" ?
                              common.getTrad("Nuovo")
                              :
                              this.state.tagliando.veicolo.FSTAT === "V" ?
                                common.getTrad("Venduto")
                                :
                                this.state.tagliando.veicolo.FSTAT === "S" ?
                                  common.getTrad("Garanzia Scaduta")
                                  :
                                  <></>
                            :
                            <></>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Descrizione")}</b></td>
                        <td className="valore_dati">
                          {
                            this.state.tagliando.veicolo.DCOLO !== null && this.state.tagliando.veicolo.DCOLO !== undefined ?
                              this.state.tagliando.veicolo.DVEIC + " " + this.state.tagliando.veicolo.DCOLO
                              :
                              this.state.tagliando.veicolo.DVEIC
                          }
                        </td>
                        <td className="voce_dati"><b>{common.getTrad("Cilindrata")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.NCILI}</td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Cod. Modello")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.CMODE}</td>
                        <td className="voce_dati"><b>{common.getTrad("Matr. Motore")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.NMOTO}</td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Targa")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.NTARG}</td>
                        <td className="voce_dati"><b>{common.getTrad("Data Immatr.")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.UIMMA ? moment(this.state.tagliando.veicolo.UIMMA).format('L') : ""}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-md-none">
                  <table id="dati_veicoloResponsive" className="table table-striped table-bordered dt-responsive nowrap mb-5" style={{ width: '100%' }}>
                    <tbody>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Marca")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.ZMAVE}</td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Stato")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.FSTAT}</td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Descrizione")}</b></td>
                        <td className="valore_dati">
                          {
                            this.state.tagliando.veicolo.DCOLO !== null && this.state.tagliando.veicolo.DCOLO !== undefined ?
                              this.state.tagliando.veicolo.DVEIC + " " + this.state.tagliando.veicolo.DCOLO
                              :
                              this.state.tagliando.veicolo.DVEIC
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Cilindrata")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.NCILI}</td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Cod. Modello")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.CMODE}</td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Matr. Motore")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.NMOTO}</td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Targa")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.NTARG}</td>
                      </tr>
                      <tr>
                        <td className="voce_dati"><b>{common.getTrad("Data Immatr.")}</b></td>
                        <td className="valore_dati">{this.state.tagliando.veicolo.UIMMA ? moment(this.state.tagliando.veicolo.UIMMA).format('L') : ""}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {this.state.tagliando.tagliandi ?
                <>
                  <h4 className="col">{common.getTrad("Elenco manutenzioni")}</h4>
                  <table id="table_MainteinanceTable" className="table table-striped table-bordered dt-responsive" style={{ width: '100%' }}></table>
                </>
                :
                ""
              }
            </div>
            :
            this.state.tagliando.message !== undefined && this.state.tagliando.message !== null ?
              <div className="sw-alert alert alert-warning" role="alert">
                {this.state.tagliando.message}
              </div>
              :
              <></>
          :
          <></>
        }
      </>
    )
  }
}

export default MainteinanceTable;
