import React from 'react';
import { Route, Routes, Link } from "react-router-dom";

import LoaderView from '../views/loader';

const env = require("../../config.js").default;
const common = require('../common/common');

class ConfirmEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount(){
    var email = common.getParam("email");
    var token = common.getParam("token");
    if(email !== undefined && email !== null && token !== undefined && token !== null){
      if(this.props.login === true){

        this.setState({titolo: common.getTrad("Attivazione account RMI")});
        this.setState({descrizione: common.getTrad("Per attivare un nuovo account, non devi aver fatto accesso al portale. Esci dal portale per completare l'attivazione")});
      }else{
        common.apiCall(env.BACKEND + "@sw-new-user-email-confirm", "GET", {"email": email, "token": token}, false)
        .then((response) => {
          if(response.error === false){
            this.setState({titolo: common.getTrad("Attivazione account RMI") + " " + ((response.errorCode !== undefined && response.errorCode !== "" && response.errorCode !== null) ? common.getTrad("NON RIUSCITA") : common.getTrad("RIUSCITA"))  });
            this.setState({descrizione: response.message});
          }else{
            this.setState({titolo: common.getTrad("Attivazione account RMI")});
            this.setState({descrizione: common.getTrad("Attivazione account non riuscita. Si prega di riprovare.")});
          }
        });
      }
    }
    else{
      this.setState({titolo: common.getTrad("Attivazione account RMI")});
      this.setState({descrizione: common.getTrad("L'indirizzo inserito non è valido. Provare a copiare ed incollare nella barra degli indirizzi del browser il link ricevuto via email.")});
    }
  }
  componentDidMount(){
    document.title = document.title = window.cc.companyInfo.service + " Service" + " - SignUp";
    document.getElementById('body').className='sign_up';
  }

  render(props){
    if(this.state.titolo){
      return(
        <div className="app-content content registrazione_io">
          <div className="content-wrapper">
            <div className="content-body">
              <section id="icon-tabs">
                <div className="row">
                  <div id="content_registrazione_form">
                    <h2 className="text-center mt-5 mb-2">{this.state.titolo}</h2>
                    <fieldset className="mt-2 col">
                      <div className="row">
                        <div className="text-center col-md-12 mb-4">
                          <p>{this.state.descrizione}</p>
                          {this.props.login === true ?
                            <Link to="/logout">
                              <button type="button" className="bottone_default btn btn-light">
                                {common.getTrad("Esci")}
                              </button>
                            </Link>
                            :
                          <Link to={common.getUrlPath(env.FRONTEND)}>
                            <button type="button" className="bottone_default btn btn-light">
                              {common.getTrad("Accedi al portale RMI")}
                            </button>
                          </Link>
                        }
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <Routes>
          <Route path="/" element={<LoaderView />} />
        </Routes>
      );
    }
  }

}


export default ConfirmEmail;
