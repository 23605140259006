//import { unstable_usePrompt as usePrompt } from 'react-router-dom'

function Prompt(props) {
    //usePrompt(props.message,props.when)

    return (window.onbeforeunload = function () {
        if (!window.contentSaved){
            return props.message;
        }
    });
}

export default Prompt