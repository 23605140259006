import React from 'react';
const env = require("../../config.js").default;
const common = require('../common/common');


class MultiSelectCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.stopUpdate = false;
    this.state.setModelliChecked = true;
    this.state.marche_attive = this.props.marche_attive;
  }

  componentDidMount() {
    if (!this.props.modelRequired && !this.props.edit) {
      if (this.state.marche_attive.length === window.cc.marche_portale.length) {
        this.props.onClick(['tutti_modelli'])

      } else {
        var modelliMarche = [];
        for (var i = 0; i < this.state.marche_attive.length; i++) {
          modelliMarche.push(this.state.marche_attive[i].ZFORN + "_tutti_modelli")
        }
        this.props.onClick(modelliMarche);
      }
    }
  }

  componentDidUpdate() {
    const $ = window.$;
    // in update entro solo la prima volta e ogni volta che viene selezionato un modello nuovo o tolto
    // cambio il semaforo evitando che faccia la render all'infinito
    if (!this.state.stopUpdate && this.props.edit && this.props.modelliSelected !== undefined) {
      var modelliSelected = this.props.modelliSelected;
      for (var i = 0; i < modelliSelected.length; i++) {
        if (modelliSelected[i] !== undefined && modelliSelected[i] !== null) {
          if (modelliSelected[i] === "tutti_modelli") {
            $("#selectModelliGruppi input.inputCheck").prop('checked', true);
          } else if (modelliSelected[i].indexOf("_tutti_modelli") > -1) {
            $("#selectModelliGruppi input.inputCheck[group='" + modelliSelected[i].slice(0, 2) + "']").prop('checked', true);
          } else {
            $("#selectModelliGruppi #" + modelliSelected[i]).prop('checked', true)
          }
        }
      }

      $("#selectModelliGruppi .content_input input:checked").each(function () {
        $("#selectModelliGruppiChecked .content_input." + $(this).val()).show();
      });
    }
    // $("#filtraCercati").on("click", () => {
    //   this.selectFilteredModels();
    // });
  }

  onLoadSelect() {
    const $ = window.$;
    if (!this.state.stopUpdate && this.state.setModelliChecked && !this.props.modelRequired && !this.props.edit) {
      $("#selectModelliGruppi .content_input.first_group:not(.first_input) input").attr('disabled', true);
      for (var i = 0; i < this.state.marche_attive.length; i++) {
        $("#selectModelliGruppi .content_input.first_group:not(.first_input) input#" + this.state.marche_attive[i].ZFORN + "_tutti_modelli").attr('disabled', false);
      }
      $("#selectModelliGruppiChecked .content_input:not([disabled])").show();
      $("#selectModelliGruppi .content_input input:not([disabled])").prop('checked', true);
    }
    $("#selectModelliGruppiChecked .card").each(function () {
      var id = $(this).data('id');
      var collapseID = id + "_collapse_Original";
      if ($('#' + collapseID + ' input:checked').length === 0) {
        $(this).hide();
      } else {
        $(this).show();
      }
    });
  }

  getSelezionati(element, group, mySelect) {
    // TODO: get selezionati
    /*
    array_selezionati
    marche selezionate
    1. verifico se seleziona tutti capo è cheked.
    Se si return "tutti_modelli"
    2. ciclo per seleziona tutti delle varie marche
    se checked array_selezionati + "tutti_modelli_[group]"
    marche selezionate + [group]
    3. ciclo per tutti i singleCheck con gruppo non presente in marche selezionate
    se checked array_selezionati + singleCheck.id
    return array_selezionati
    */
    const $ = window.$;
    var elementValue = element.val();
    var modelliSelected = [];
    var marcheSelected = [];
    var groupCheckedChecks;
    if (elementValue === 'tutti_modelli') {
      modelliSelected = $("#tutti_modelli").is(':checked') ? [elementValue] : [];
    } else {
      modelliSelected = [];
      if ($("#" + group + "_tutti_modelli_filtrati").is(':checked')) {
        if (element.length === 1) {
          groupCheckedChecks = $(".singleCheck:checked", mySelect)
          //$("#filtraCercati").prop("checked", false)
        } else {
          groupCheckedChecks = $("div[data-id='" + group + "'] :checkbox:not(.selezionaTutti):not(:hidden), div[data-id='" + group + "'] :checkbox:hidden:checked")
          this.setState({ stopUpdate: true, setModelliChecked: false });
        }
        // Aggiungo i modelli per marca se presenti. 
        // Filtrando e selezionando solo i modelli filtrati se li perdeva
        var additionalChecks = $(".selezionaTutti:not(#tutti_modelli):checked", mySelect);
        groupCheckedChecks = groupCheckedChecks.add(additionalChecks);
      } else {
        groupCheckedChecks = $(".selezionaTutti:not(#tutti_modelli):checked", mySelect);
      }
      var singleCheckedChecks = $(".singleCheck:checked", mySelect);
      for (var i = 0; i < groupCheckedChecks.length; i++) {
        if ($(groupCheckedChecks[i]).attr('group') !== undefined && marcheSelected.indexOf($(groupCheckedChecks[i]).attr('group')) <= -1)
          marcheSelected.push($(groupCheckedChecks[i]).attr('group'));
        if (modelliSelected.indexOf($(groupCheckedChecks[i]).val()) <= -1)
          modelliSelected.push($(groupCheckedChecks[i]).val());
      }
      for (var s = 0; s < singleCheckedChecks.length; s++) {
        if (marcheSelected.indexOf($(singleCheckedChecks[s]).attr('group')) <= -1) {
          if (modelliSelected.indexOf($(singleCheckedChecks[s]).val()) <= -1)
            modelliSelected.push($(singleCheckedChecks[s]).val());
        }
      }
      if (!$("#" + group + "_tutti_modelli_filtrati").is(':checked')) {
        if (window.cc.marche_portale !== undefined && window.cc.marche_portale.length === marcheSelected.length) {
          modelliSelected = ['tutti_modelli'];
        }
      }
      if (element.length === 1) {
        $("#" + group + "_tutti_modelli_filtrati").prop("checked", false);
      }
    }
    this.props.onClick(modelliSelected);
    $("#selectModelliGruppiChecked .content_input.first_input").show();
    $("#selectModelliGruppiChecked .content_input:not(.first_input)").hide();
    $("#selectModelliGruppi .content_input input:checked").each(function () {
      $("#selectModelliGruppiChecked .content_input." + $(this).val()).show();
    });
  }

  ricercaAvanzataModelli(params, elements) {
    const $ = window.$;
    var keywords = (params.toUpperCase()).split(/[.+-/_ ]/);
    var keywordsLength = keywords.length;
    var i = 0;

    elements.show();
    for (i; i < keywordsLength; i++) {
      $("#selectModelliGruppi .card-body > .content_input:not(:contains('" + keywords[i] + "'))").hide();
    }
  }

  setCheckedModel(e, update = false) {
    this.setState({ stopUpdate: update, setModelliChecked: false });
    const $ = window.$;
    var mySelect = $("#" + this.props.id);
    var element = $(e.target);
    var group = element.attr("group");

    var checkGroup = $(".selezionaTutti[group='" + group + "']:not([disabled])", mySelect);
    var checkedGroup = checkGroup.is(':checked');
    var singleChecks = $(".singleCheck[group='" + group + "']:not([disabled])", mySelect);
    var singleCheckedChecks = $(".singleCheck[group='" + group + "']:checked:not([disabled])", mySelect);

    var checkTuttiCapo = $(".selezionaTutti#tutti_modelli:not([disabled])", mySelect);
    var tuttiChecks = $(".inputCheck:not([disabled])", mySelect);

    if (element.attr("id") === 'tutti_modelli') {
      var checkedTuttiCapo = checkTuttiCapo.is(':checked');
      tuttiChecks.prop('checked', checkedTuttiCapo);
      this.getSelezionati(element, group, mySelect);
      return false;
    }

    if (element.attr("id") === checkGroup.attr("id")) {
      singleChecks.prop('checked', checkedGroup);
    } else {
      checkGroup.prop('checked', singleCheckedChecks.length === singleChecks.length);
    }
    this.getSelezionati(element, group, mySelect);
    var singleCheckstutti = $(".singleCheck:not([disabled])", mySelect);
    var singleCheckedCheckstutti = $(".singleCheck:checked:not([disabled])", mySelect);
    checkTuttiCapo.prop('checked', singleCheckstutti.length === singleCheckedCheckstutti.length); // senza [group]

    var tuttiSelezionati = $("#selectModelliGruppi .card[data-id='" + group + "'] .content_input:not(.first_group):visible").toArray().every(function (singleCheckVisible) {
      return $(singleCheckVisible)[0].firstChild.checked === true;
    });
    if (tuttiSelezionati) {
      $("#" + group + "_tutti_modelli_filtrati").prop("checked", true)
    } else {
      $("#" + group + "_tutti_modelli_filtrati").prop("checked", false)
    }
  }

  removeSelectModel(all_brand, marca = null) {
    const $ = window.$;
    if (all_brand && all_brand != 'filtered') {
      this.setState({ stopUpdate: all_brand })
      var tuttiChecks = $("#selectModelliGruppi input");
      tuttiChecks.prop('checked', false);
      $("#selectModelliGruppiChecked .content_input:not(.first_input)").hide();
      this.props.onClick([]);
    } else if (all_brand === 'filtered') {
      this.setState({ stopUpdate: all_brand })
      $("div[data-id='" + marca + "'] :checkbox:not(.selezionaTutti):not(:hidden)").prop('checked', false);
      $("div[data-id='" + marca + "'] :checkbox:not(.selezionaTutti):not(:hidden)").each(function () {
        $("#selectModelliGruppiChecked .content_input." + $(this)[0].id).hide()
      });
      this.props.onClick([]);
    }
  }

  searchModel(e) {
    const $ = window.$;
    var element = $(e.target);
    $('#selectModelliGruppi .card-header[aria-expanded=false]:visible').click();

    this.ricercaAvanzataModelli(element.val(), $("#selectModelliGruppi .card-body > .content_input"));
    if (element.val() !== undefined && element.val() !== '') {
      $(".boxSelezionaTuttiFiltrati").show();
      $(".checkSelezionaTuttiFiltrati").prop("disabled", false);
      $("#tutti_modelli").prop("disabled", true);
      $("#selectModelliGruppi .card").each(function () {
        var id_card = $(this).data('id');
        $("#selectModelliGruppi .card[data-id='" + id_card + "']").show();
        if ($("#selectModelliGruppi .card[data-id='" + id_card + "'] .content_input:not(.first_group):visible").length === 0) {
          $("#selectModelliGruppi .card[data-id='" + id_card + "']").hide();
        } else {
          $("#selectModelliGruppi .card[data-id='" + id_card + "']").show();
        }
        var tuttiSelezionati = $("#selectModelliGruppi .card[data-id='" + id_card + "'] .content_input:not(.first_group):visible").toArray().every(function (singleCheckVisible) {
          return $(singleCheckVisible)[0].firstChild.checked === true;
        });
        if (tuttiSelezionati) {
          $("#"+ id_card +"_tutti_modelli_filtrati").prop("checked", true)
        } else {
          $("#" + id_card + "_tutti_modelli_filtrati").prop("checked", false)
        }
      });
    } else {
      $(".boxSelezionaTuttiFiltrati").hide();
      $(".selezionaTuttiFiltrati").prop("checked", false)
      $(".checkSelezionaTuttiFiltrati").prop("disabled", true);
      $("#tutti_modelli").prop("disabled", false);
      $("#selectModelliGruppi .card").show();
      $('#selectModelliGruppi .card-header[aria-expanded=true]:visible').click();
    }
  }

  selectFilteredModels = (marca = null) => {
    const $ = window.$;
    if (!$("#" + marca + "_tutti_modelli_filtrati").is(':checked')) {
      // var isTutteMarche = (this.state.marche_attive.length === window.cc.marche_portale.length);
      // this.removeSelectModel(isTutteMarche)
      this.removeSelectModel('filtered', marca)
    } else {
      $("[id$='_collapse_Original']").addClass("show");
      var checkboxesToSelect = $("div[data-id='" + marca + "'] :checkbox:not(:hidden):not(.selezionaTutti)");
      checkboxesToSelect.prop("checked", true);
      this.getSelezionati(checkboxesToSelect, marca, $("#selectModelliGruppi"));
    }
  };

  render(props) {
    const $ = window.$;
    var numSelected = $("#selectModelliGruppi .content_input input.singleCheck:checked");

    var marchePortale = window.cc.marche_portale;

    for (var i = 0; i < marchePortale.length; i++) {
      var disabled = $("#" + marchePortale[i].ZFORN + "_collapse_Original input:first").is(':disabled');
      $(".content_input.first_group." + marchePortale[i].ZFORN + "_tutti_modelli input").attr('disabled', disabled);
    }

    $("#selectModelliGruppiChecked .card").each(function () {
      var id = $(this).data('id');
      var collapseID = id + "_collapse_Original";
      if ($('#' + collapseID + ' input:checked').length === 0) {
        $(this).hide();
      } else {
        $(this).show();
      }
    });

    var isTutteMarche = (this.state.marche_attive.length === window.cc.marche_portale.length);

    return (
      <div id={this.props.id} className="col p-0" onLoad={() => this.onLoadSelect()}>
        {this.props.showInput ?
          <div id="idSearch" className="input_head">
            <input id="idSearchInput" onChange={(e) => this.searchModel(e)} className="form-control" type="search" placeholder={common.getTrad("Cerca...")} autoFocus="" autoComplete="off" />
            <i className="fas fa-search"></i>
          </div>
          :
          <></>
        }
        <div className={"content_input first_group first_input" + (this.props.showInput ? "" : " text-right input_head pl-2")}>
          {this.props.showInput ?
            <>
              <div className="d-flex flex-column justify-content-start">
                <div className="d-flex justify-content-start align-item-center">
                  <input type="checkbox" value="tutti_modelli"
                    id="tutti_modelli"
                    disabled={isTutteMarche ? false : true}
                    className="selezionaTutti checkboxClean"
                    onChange={(e) => this.setCheckedModel(e, true)}
                  />&nbsp;
                  <label htmlFor="tutti_modelli">
                    {common.getTrad("Seleziona tutti")}
                  </label>
                </div>
              </div>
            </>
            :
            <>
              {numSelected !== undefined ?
                <>
                  {numSelected.length > 0 ?
                    <>
                      <span style={{ textShadow: "0 0 black" }}>{numSelected.length}</span>&nbsp;
                      {numSelected.length === 1 ? common.getTrad("selezionato") : common.getTrad("selezionati")}
                      <span className="float-right col">
                        <span
                          title={!isTutteMarche ? common.getTrad("Non hai il permesso di deselezionare tutti i modelli") : ''}
                          style={{ cursor: (isTutteMarche ? 'pointer' : 'not-allowed'), textShadow: '0 0 black' }}
                          onClick={(state) => this.removeSelectModel(isTutteMarche)}>{common.getTrad("Deseleziona tutti")}</span>
                      </span>
                    </>
                    :
                    common.getTrad("Nessuno selezionato")
                  }
                </>
                :
                <div>&nbsp;</div>
              }
            </>
          }
        </div>
        <div id={"accordion" + this.props.id} className="content_scrollbar_multiselect">
          {this.props.modelli.map((group, index) => {
            return (
              <div data-id={group.id_group} className="card" key={index}>
                <div className={"card-header" + (this.props.showInput ? " collapsed" : "")} id={group.id_group + "_head_" + this.props.head}
                  data-toggle="collapse" data-target={"#" + group.id_group + "_collapse_" + this.props.collapse}
                  aria-expanded={this.props.showDelete} aria-controls={group.id_group + "_tutti_modelli"}
                >
                  <p className="mb-0 titleBrand">
                    <img src={env.SWAN + "static/images/loghi/" + group.id_group.toLowerCase() + ".png"} onError={(e) => { e.target.onerror = null; e.target.src = "../static/images/loghi/" + group.id_group.toLowerCase() + ".png" }} title={group.gruppo} alt={group.gruppo} />
                    <span className="chevronClass float-right"></span>
                  </p>
                </div>
                <div style={{ display: this.props.showInput ? '' : 'none' }} className={"pt-3 content_input first_group " + group.id_group + "_tutti_modelli"}>
                  <input style={{ display: this.props.showInput ? '' : 'none' }} type="checkbox" value={group.id_group + "_tutti_modelli"}
                    id={group.id_group + "_tutti_modelli"} group={group.id_group}
                    className="selezionaTutti inputCheck checkboxClean"
                    onChange={(e) => this.setCheckedModel(e, true)}
                  />
                  <label htmlFor={group.id_group + "_tutti_modelli"}>
                    {this.props.showDelete ?
                      common.getTrad("Deseleziona tutti i modelli {{group}}", { group: group.gruppo })
                      :
                      common.getTrad("Seleziona tutti i modelli {{group}}", { group: group.gruppo })
                    }
                  </label>
                </div>
                {!this.props.showDelete ?
                  <div style={{ display: 'none' }} className={"boxSelezionaTuttiFiltrati content_input first_group " + group.id_group + "_tutti_modelli_filtrati"}>
                    <input style={{ display: this.props.showInput ? '' : 'none' }} type="checkbox" value={group.id_group + "_tutti_modelli_filtrati"}
                      id={group.id_group + "_tutti_modelli_filtrati"} group={group.id_group}
                      className="selezionaTuttiFiltrati inputCheck checkboxClean checkSelezionaTuttiFiltrati"
                      onChange={() => this.selectFilteredModels(group.id_group)}
                    />
                    <label htmlFor={group.id_group + "_tutti_modelli_filtrati"}>
                      {common.getTrad("Seleziona solo i modelli filtrati")}
                    </label>
                  </div>
                :
                  ""
                }
                <div id={group.id_group + "_collapse_" + this.props.collapse} className={"collapse" + (this.props.showInput ? " collapsed" : " show")} >
                  <div className="card-body">
                    {this.props.modelli[index]['children'].map((model, index) => {
                      return (
                        <div style={{ display: this.props.showInput ? '' : 'none' }} className={"content_input " + model.id} key={index}>
                          <input disabled={model.disabled} style={{ display: this.props.showInput ? '' : 'none' }} className="singleCheck inputCheck checkboxClean" group={model.group}
                            onChange={(e) => this.setCheckedModel(e, true)} type="checkbox"
                            value={model.id} id={model.id}
                          />
                          <label disabled={model.disabled} htmlFor={model.id} title={model.modello}>
                            <>
                              {model.modello.toUpperCase()}
                              <p style={{ display: 'none' }}>{group.gruppo}</p>
                            </>
                            {this.props.showDelete ?
                              <span title={common.getTrad("rimuovilo dall'elenco")} className="removeCheck"></span>
                              :
                              <></>
                            }
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default MultiSelectCustom;
