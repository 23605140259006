
export const wizardStepsInit = () => {
  (function($){
    var form = $(".steps-validation").show();

    $(".steps-validation").steps({
        headerTag: "h6",
        bodyTag: "fieldset",
        transitionEffect: "fade",
        titleTemplate: '<span class="step">#index#</span> #title#',

        onStepChanging: function (event, currentIndex, newIndex)
        {
            // Allways allow previous action even if the current form is not valid!
            if (currentIndex > newIndex)
            {
                return true;
    		}

            // Needed in some cases if the user went back (clean up)
            if (currentIndex < newIndex)
            {
                // To remove error styles
                form.find(".body:eq(" + newIndex + ") label.error").remove();
                form.find(".body:eq(" + newIndex + ") .error").removeClass("error");
    		}
    		 // To remove error styles
                form.find(".body:eq(" + newIndex + ") label.error").remove();
                form.find(".body:eq(" + newIndex + ") .error").removeClass("error");


            form.validate().settings.ignore = ":disabled,:hidden";
            return form.valid();
    	},
        onFinishing: function (event, currentIndex)
        {
            form.validate().settings.ignore = ":disabled";
            return form.valid();
    	},
        onFinished: function (event, currentIndex)
        {
    		//  alert("Submitted!");
    	}
    });

    // Initialize validation
    

    // Form Wizard Step Icon
    $('.step-icon').each(function(){
      var $this = $(this);
      if($this.siblings('span.step').length > 0){
        $this.siblings('span.step').empty();
        $(this).appendTo($(this).siblings('span.step'));
      }
    });

  })(window.jQuery);
}
