import React from 'react';
const common = require('../common/common');

class Indirizzi extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.lingue = {};
  }

  componentDidMount(props){
    this.setState({lingue: this.props.lingue});
    const $ = window.$;
    $(document).ready(function () {
       $("#CPROVACL").focus();
   });
   $("#CPROVACL").focus();
  }

  componentDidUpdate(){
    const $ = window.$;
    $('#CPROVACL').focus();
    // se viene cliccato su aggiungi indirizzo sede mostro gli input della sede
    $('#indirizzo_spedizione').hide();


    $( '#CambiaIndirizzo' ).on( 'input keyup change', function () {

      if ($('#CambiaIndirizzo').is(':checked')){
        $("#indirizzo_spedizione").show(200);
        // aggiungo il required per i campi mostrati
        $('#CAPINAIS').addClass('required');
        $('#DLOCAAIS').addClass('required');
        $('#DINDIAIS').addClass('required');
        $('#RCLIEAIS').addClass('required');
      } else {
        $("#indirizzo_spedizione").hide(200);
        $('#CAPINAIS').removeClass('required');
        $('#DLOCAAIS').removeClass('required');
        $('#DINDIAIS').removeClass('required');
        $('#RCLIEAIS').removeClass('required');
      }
    });
}
  render(props){

    return(

       <>
          <h6><i className="step-icon fas fa-map-marker-alt" title={common.getTrad("Indirizzi")}></i></h6>
          <fieldset className="mt-2 col">
            <div className="row mt-2">
              <div className="col-md-12">
                <h4>{common.getTrad("Indirizzo Fatturazione")}:</h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="CPROVACL">{common.getTrad("Provincia")}:</label>
                  <input autoFocus type="text" autoComplete="address-level1" className="form-control" maxLength="10" placeholder={common.getTrad("Inserisci la provincia")} name="CPROVACL" id="CPROVACL" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="DLOCAACL">{common.getTrad("Città")}: <span className="danger">*</span></label>
                  <input type="text" autoComplete="address-level2" className="form-control required" maxLength="150" placeholder={common.getTrad("Inserisci la città")} name="DLOCAACL" id="DLOCAACL" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                <label htmlFor="DINDIACL">{common.getTrad("Indirizzo")}: <span className="danger">*</span></label>
                <input type="text" autoComplete="street-address" className="form-control required" maxLength="150" placeholder={common.getTrad("Inserisci il tuo indirizzo")} name="DINDIACL" id="DINDIACL" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="CAPCLACL">{common.getTrad("CAP")}: <span className="danger">*</span></label>
                <input type="text" autoComplete="postal-code" className="form-control required" placeholder={common.getTrad("Inserisci il CAP")} maxLength="10" name="CAPCLACL" id="CAPCLACL" />
              </div>
            </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p></p>
                <div className="d-inline-block custom-control custom-checkbox">
                  <input type="checkbox" autoComplete="off" name="CambiaIndirizzo" className="custom-control-input" onChange={this.handleCheckboxChange} checked={this.state.isChecked} id="CambiaIndirizzo"/>
                  <label className="custom-control-label" htmlFor="CambiaIndirizzo">{common.getTrad("Specifica indirizzo SEDE OPERATIVA differente")}</label>
                </div>
              </div>
            </div>
            <div id="indirizzo_spedizione">
              <div className="row mt-2">
                <div className="col-md-12 mb-3">
                  <h4>{common.getTrad("Indirizzo Sede Operativa")}:</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="RCLIEAIS">{common.getTrad("Nome sede operativa")}: <span className="danger">*</span></label>
                    <input type="text" autoComplete="organization" className="form-control" maxLength="150" placeholder={common.getTrad("Nome identificativo della sede (es. indicazioni pubblicitarie)")} id="RCLIEAIS" name="RCLIEAIS" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="CPROVAIS">{common.getTrad("Provincia")}:</label>
                    <input type="text" autoComplete="address-level1" className="form-control" placeholder={common.getTrad("Inserisci la provincia")} name="CPROVAIS" id="CPROVAIS" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="DLOCAAIS">{common.getTrad("Città")}: <span className="danger">*</span></label>
                    <input type="text" autoComplete="address-level2" className="form-control" placeholder="Inserisci la città" name="DLOCAAIS" id="DLOCAAIS" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="DINDIAIS">{common.getTrad("Indirizzo")}: <span className="danger">*</span></label>
                    <input type="text" autoComplete="street-address" className="form-control" placeholder={common.getTrad("Inserisci il tuo indirizzo")} name="DINDIAIS" id="DINDIAIS" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="CAPINAIS">{common.getTrad("CAP")}: <span className="danger">*</span></label>
                    <input type="text" autoComplete="postal-code" className="form-control" placeholder={common.getTrad("Inserisci il CAP")} name="CAPINAIS" id="CAPINAIS" />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
 </>
      )

  }

}

export default Indirizzi;
