import React from 'react';
import jQuery from 'jquery';
import bootbox from 'bootbox';
import 'jquery-validation/dist/jquery.validate.min.js';
import Sidebar from '../Sidebar/Sidebar';

const env = require("../../config.js").default;
const common = require('../common/common');
window.jQuery = jQuery;
window.$ = jQuery;

try {
  require ('jquery-validation/dist/localization/messages_'+ common.getLanguage(false) +'.js');
} catch (err) {}

class MyProfile extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.state.userInfo = {};
    this.state.userInfo.roles = [];
    this.state.userInfo.userInfo = {};
    this.state.userInfo.userInfo.WUS = {};
    this.state.userInfo.userInfo.groups = [];
    this.state.userInfo.userInfo.INSP = [{}];
    this.state.userInfo.userInfo.GTSFINI = [{}];
    this.isMyProfile = false;
    this.state.nazioni = {};
  }

  UNSAFE_componentWillMount(){
    this.setState({nazioni: window.cc.nazioni});
    // this.state.nazioni = JSON.parse(this.state.nazioni);
    common.apiCall(env.BACKEND + "@get-employee", "GET", {}, true)
      .then(response=>{
        this.setState({employee_rmi: response});
    });

    common.getUserInfo().then(userInfo =>{
      this.setState({userInfo: userInfo});
    })

  }
  onClickUser(utente){
    if(utente === 0){
        this.setState({userEdit: ""})
        this.setState({isMyProfile: false});
        return
    }

    this.setState({userEdit: utente})
    this.setState({isMyProfile: true});
  }

  render(props){
    return (
      <div id="portal-columns"  className="clearfix">
         <div className="row">
          <Sidebar myprofile={true} userEdit={this.state.userEdit} onClick={utente => this.onClickUser(utente)} employee_rmi={(this.state.employee_rmi)} type="user"></Sidebar>

          {!this.state.isMyProfile ?
            <PageProfile userInfo={this.state.userInfo} nazioni={this.state.nazioni}></PageProfile>
          :
            <PageUser userInfo={this.state.userInfo} nazioni={this.state.nazioni} userEdit={this.state.userEdit}></PageUser>
          }
      </div>
    </div>
    )
  }
}

class PageProfile extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.newUtente = false;
    this.state.userEdit = "";
  }

  renderSelectLingue(){
    try{
      return window.cc.languages.map(function(index, key){
        return(
          <option key={key} value={index}>
          {env.languageName[index]}
          </option>
        )
      });
    }catch(e){}
  }

  UNSAFE_componentWillMount(props){
    document.getElementById('body').className='my_profile';

    this.setState({newUtente: false});

    const $ = window.$;
    $('#annullaUtente').click((e) => {
      e.preventDefault();
      this.setState({newUtente: false});
    });

    this.setState({passwordScaduta: false, emailScaduta: false});
    const notificaLogin = common.getParam("pwd");
    if(notificaLogin !== undefined && notificaLogin !== null){
      if(parseInt(notificaLogin) === 1 )
        this.setState({passwordScaduta: true});
      if(parseInt(notificaLogin) === 2 )
        this.setState({emailScaduta: true});
      if(parseInt(notificaLogin) === 3 )
        this.setState({emailScaduta: true, passwordScaduta: true});
      $("html,body").animate({scrollTop: $("body").height()});
    }
  }

  componentDidMount(props){
    const $ = window.$;

    $('#newUtente').click((e) => {
      e.preventDefault();
      this.setState({newUtente: true});
    });
  }

  componentDidUpdate(props){
    const $ = window.$;

    $(document).on('keydown', '#DCAPOWUS', function(e) {
      if (e.keyCode === 32) return false;
    });
    $(document).on('keydown', '#cpwd0', function(e) {
      if (e.keyCode === 32) return false;
    });


    $('#conferma_email, #new_password_confirm, #conferma_password').bind("cut copy paste", function(e) {
      e.preventDefault();
    });
    if(this.state.newUtente === false){
      $('#newUtente').show();
    }else{
      $('#newUtente').hide();
    }

    $('#newUtente').click((e) => {
      e.preventDefault();
      this.setState({newUtente: true});
    });
  }

  validationForm() {
    const $ = window.$;

    $(".modifica_utente_io").validate({
      ignore: 'input[type=hidden]', // ignore hidden fields
      errorClass: 'danger',
      successClass: 'success',
      highlight: function(element, errorClass) {
          $(element).removeClass(errorClass);
    },
      unhighlight: function(element, errorClass) {
          $(element).removeClass(errorClass);
    },
      errorPlacement: function(error, element) {
          error.insertAfter(element);
    },
      rules: {
        new_password_confirm: {
          equalTo: "#new_password"
        }
      }
    });
  }

  handleSubmit(e){
    e.preventDefault();
    this.validationForm();
    const $ = window.$;

    if(!$(".modifica_utente_io").valid() === false){
    $("#changePasswordStatus").fadeOut("fast");
    $("#changePasswordStatus").removeClass("alert-success");
    $("#changePasswordStatus").removeClass("alert-danger");
    var data = {
      "current-password":$("#current_password").val(),
      "new-password":$("#new_password").val(),
      "email": (common.hideFieldByUser(this.props.userInfo.userInfo.WUS, 'my-profile', 'email_recupero') ? this.props.userInfo.userInfo.WUS.DCAPO : $("#email_recupero").val()),
    };

    common.apiCall(env.BACKEND + "@sw-change-user-password", "POST", data, true)
    .then((response) => {
      var errorMessage = "";

      if(response.message !== undefined && response.message != null && response.message !== "")
        errorMessage = response.message;
      else if(response.errorText !== undefined && response.errorText != null && response.errorText !== "")
        errorMessage = response.errorText;

      if(response.error !== false){
        $("#changePasswordStatus").addClass("alert-danger");
        $("#changePasswordStatus").html(common.getTrad("Errore. Operazione non riuscita. Ripovare più tardi") + " " + errorMessage);
      }else{
        if(response.errorCode !== undefined && response.errorCode !== null && response.errorCode !== ""){
          $("#changePasswordStatus").addClass("alert-danger");
          if(errorMessage !== "")
            $("#changePasswordStatus").html(errorMessage);
          else
            $("#changePasswordStatus").html(common.getTrad("Errore. Operazione non riuscita. Ripovare più tardi"));
        }else{
          $("#current_password").val("");
          $("#new_password").val("");
          $("#new_password_confirm").val("");
          $("#changePasswordStatus").addClass("alert-success");
          if(errorMessage !== "")
            $("#changePasswordStatus").html(errorMessage);
          else
            $("#changePasswordStatus").html(common.getTrad("Password cambiata con successo"));
          this.setState({passwordScaduta: false, emailScaduta: false});
        }
      }
      $("#changePasswordStatus").fadeIn("fast");
      $("#changePasswordStatus").fadeOut(5000);
      if($("#email_recupero").val() !== this.props.userInfo.userInfo.WUS){
        common.remove_localStorage("userInfo");
        common.getUserInfo().then(userInfo =>{
          this.setState({userInfo: userInfo});
        })
      }
    });
  }

}

  // creaazione di un nuovo Utente
  validationFormUtente() {
    const $ = window.$;
    $(".nuovo_utente").validate({
        ignore: 'input[type=hidden]', // ignore hidden fields
        errorClass: 'danger',
        successClass: 'success',
        highlight: function(element, errorClass) {
            $(element).removeClass(errorClass);
      },
        unhighlight: function(element, errorClass) {
            $(element).removeClass(errorClass);
      },
        errorPlacement: function(error, element) {
            error.insertAfter(element);
      },
        rules: {
          conferma_password: {
            equalTo: "#cpwd0"
          },
          conferma_email: {
            equalTo: "#DCAPOWUS"
          }
      }
    });
  }

  handleAddUtente(e){
    e.preventDefault();
    this.validationFormUtente();
    const $ = window.$;
    var data = {};
     if(!$(".nuovo_utente").valid() === false){
      data = {
        "CNAZIACL": this.props.userInfo.userInfo.CNAZI,
        "NPIVAACL": this.props.userInfo.userInfo.NPIVA,
        "RUSERWUS" : $('#RUSERWUS').val(),
        "DCAPOWUS": $('#DCAPOWUS').val(),
        "cpwd0":    $('#cpwd0').val(),
        "CLIN1WUS": this.props.userInfo.userInfo.CLING,
        "confirmURL": env.FRONTEND + "confirmEmail"
      };

      $("body #app .loadingDiv").fadeIn("fast");
      common.apiCall(env.BACKEND + "@sw-new-employee", "POST", data, false)
      .then(data => {
        if(data.error === false){
          if(data.errorCode === 403){
            $("body #app .loadingDiv").fadeOut();
            bootbox.alert(data.message);
          }else{
            bootbox.alert(data.message, function(){
              window.location.href = env.FRONTEND + 'my-profile';
            });
          }
        }
      });
    }
  }

  render(props){
    var nazione = this.props.userInfo.userInfo.CNAZI;
    var indexNazione = 1;
    if(this.props.nazioni.length > 0 && nazione !== undefined && nazione !== null){
      indexNazione = common.object_value_in_array(this.props.nazioni, 'CNASWTNA', nazione);
      if(this.props.nazioni[indexNazione] !== undefined){
        var objectNazione = this.props.nazioni[indexNazione];
      }
    }

    return(
      <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8 linea_sfumata" id="content_page">
        <div className="row">
          <div className="content_titolo">
            <div className="content_titolo_int">
              <span className="titolo_sezione">{this.state.newUtente === false ? common.getTrad("Il mio profilo") : common.getTrad("Nuovo Utente")}</span>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
          {this.props.userInfo.userInfo.FCONS === 'S' && this.props.userInfo.userInfo.FADMI === 'N' ?
            <button className="btn bottone_default" id="newUtente"  style={{marginBottom: "1.5em"}}><span>+</span> {common.getTrad("Nuovo Utente")}</button>
           :
            ""
          }
          {this.state.newUtente === false ?
            <div id="modifica_utente_io" className="tab-pane active">
              <div className="row elenco-voci-dettaglio">
                <div className="col-md-12">
                  <h4>{common.getTrad("Riepilogo Dati")}</h4>
                  <hr/>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <h4>{common.getTrad("Anagrafica")}:</h4>
                  <p>
                    <span className="label">{common.getTrad("Ragione Sociale")}</span>
                    <b><span className="label_value">{ this.props.userInfo.userInfo.WUS.RCLIE }</span></b>
                  </p>
                  <div className="clear"></div>
                  {objectNazione !== undefined && this.props.nazioni !== undefined && this.props.nazioni !== null && this.props.nazioni.length > 0 ?
                    <p><span className="label">{common.getTrad("Paese")}</span> <b><span className="label_value">
                      {objectNazione.TNAZITNA === 'EU' ? common.getTrad("nazione_" + objectNazione.CISO2TNA ) : objectNazione.DNAZITNA} -&nbsp;
                      <img src={env.STATIC + "images/bandiere/"+  objectNazione.CISO3TNA+".svg"}
                        className="img-fluid" width="24"
                        title={objectNazione.TNAZITNA === 'EU' ? common.getTrad("nazione_" + objectNazione.CISO2TNA ) : objectNazione.DNAZITNA}
                        alt={objectNazione.TNAZITNA === 'EU' ? common.getTrad("nazione_" + objectNazione.CISO2TNA ) : objectNazione.DNAZITNA}
                      />
                      </span></b>
                    </p>
                  :
                    <p>
                      <span className="label">{common.getTrad("Paese")}</span>
                      <b><span className="label_value"></span></b>
                    </p>
                  }

                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Partita IVA")}</span>
                    <b><span className="label_value">{ this.props.userInfo.userInfo.NPIVA }</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Legale rappresentante")}</span>
                    <b><span className="label_value">{this.props.userInfo.userInfo.RLERAACL}</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Email legale rappresentante")}</span>
                    <b><span className="label_value">{ this.props.userInfo.userInfo.DCAPO}</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Lingua preferita")}</span>
                    <b><span className="label_value">{ common.getTrad("lingua_" + this.props.userInfo.userInfo.CLING)}</span></b>
                  </p>
                  <div className="clear"></div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <h4>{this.props.userInfo.userInfo.RMI ? common.getTrad("Indirizzo Fatturazione") : common.getTrad("Indirizzo Sede")}:</h4>
                  <p>
                    <span className="label">{common.getTrad("Provincia")}</span>
                    <b><span className="label_value">{ this.props.userInfo.userInfo.CPROV }</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Città")}</span>
                    <b><span className="label_value">{ this.props.userInfo.userInfo.DLOCA }</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Indirizzo")}</span>
                    <b><span className="label_value">{ this.props.userInfo.userInfo.DINDI }</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("CAP")}</span>
                    <b><span className="label_value">{ this.props.userInfo.userInfo.CAPCL }</span></b>
                  </p>
                  <div className="clear"></div>
                </div>
                {this.props.userInfo.userInfo.RMI ?
                  <>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <h4>{common.getTrad("Indirizzo Sede Operativa")}:</h4>
                      <p>
                        <span className="label">{common.getTrad("Provincia")}</span>
                        <b><span className="label_value">{(this.props.userInfo.userInfo.INSP[0] ? this.props.userInfo.userInfo.INSP[0].CPROV : this.props.userInfo.userInfo.CPROV)}</span></b>
                      </p>
                      <div className="clear"></div>
                      <p>
                        <span className="label">{common.getTrad("Città")}</span>
                        <b><span className="label_value">{(this.props.userInfo.userInfo.INSP[0] ? this.props.userInfo.userInfo.INSP[0].DLOCA : this.props.userInfo.userInfo.DLOCA)}</span></b>
                      </p>
                      <div className="clear"></div>
                      <p>
                        <span className="label">{common.getTrad("Indirizzo")}</span>
                        <b><span className="label_value">{(this.props.userInfo.userInfo.INSP[0] ? this.props.userInfo.userInfo.INSP[0].DINDI : this.props.userInfo.userInfo.DINDI)}</span></b>
                      </p>
                      <div className="clear"></div>
                      <p>
                        <span className="label">{common.getTrad("CAP")}</span>
                        <b><span className="label_value">{(this.props.userInfo.userInfo.INSP[0] ? this.props.userInfo.userInfo.INSP[0].CAPIN : this.props.userInfo.userInfo.CAPCL)}</span></b>
                      </p>
                      <div className="clear"></div>
                    </div>
                    <div className="col-md-12">
                      <hr/>
                    </div>
                  </>
                :
                  <></>
                }
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <h4>{common.getTrad("Utente")}:</h4>
                  <p>
                    <span className="label">{common.getTrad("Nome e Cognome")}</span>
                    <b><span className="label_value">{this.props.userInfo.userInfo.WUS.RUSER}</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Email")}</span>
                    <b><span className="label_value">{this.props.userInfo.userInfo.WUS.DCAPO}</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Lingua preferita")}</span>
                    <b><span className="label_value">{ common.getTrad("lingua_" + this.props.userInfo.userInfo.WUS.CLING)}</span></b>
                  </p>
                  <div className="clear"></div>
                </div>
              </div>

              <form noValidate action="#" method="post" className="modifica_utente_io mt-5" onSubmit={(e) => {this.handleSubmit(e)}}>

                <div className="row">
                  <div className="col-md-12 mb-3">
                    <h4>{common.getTrad("Modifica dati di accesso")}</h4>
                    {this.state.passwordScaduta ?
                      <div className="col-xl-12 sw-alert alert alert-warning" role="alert">
                        <i className="fas fa-exclamation-circle"></i>&nbsp;
                        {common.getTrad("La tua password è scaduta, aggiornala con una nuova password.")}
                      </div>
                    :
                      <></>
                    }

                    {this.state.emailScaduta ?
                      <div className="col-xl-12 sw-alert alert alert-warning" role="alert">
                        <i className="fas fa-exclamation-circle"></i>&nbsp;
                          {common.getTrad("La tua Email di contatto non è stata ancora inserita.")}
                      </div>
                    :
                      <></>
                    }
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="current_password">
                        {common.getTrad("Password attuale")}: <span className="danger">*</span>
                      </label>
                      <input type="password" className="form-control required" autoComplete="current-password" id="current_password" name="current_password"/>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="new_password">
                        {common.getTrad("Nuova Password")}: {common.getTrad("(min. 8 caratteri)")} <span className="danger">*</span>
                      </label>
                      <input type="password" minLength="8" className="form-control required" autoComplete="new-password" id="new_password" name="new_password"/>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="new_password_confirm">
                        {common.getTrad("Conferma Nuova Password")}: <span className="danger">*</span>
                      </label>
                      <input type="password" name="new_password_confirm" id="new_password_confirm" autoComplete="off" className="form-control required" />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="email_recupero">
                        {common.getTrad("Email di recupero password")}: <span className="danger">*</span>
                      </label>
                      <input disabled={common.hideFieldByUser(this.props.userInfo.userInfo.WUS, 'my-profile', 'email_recupero')} type="email" className="form-control required" autoComplete="email" id="email_recupero" name="email_recupero" defaultValue={ this.props.userInfo.userInfo.WUS.DCAPO }/>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="sw-alert alert col-xl-12" id="changePasswordStatus" role="alert"></div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 bottoni_invio">
                    <button type="submit" className="btn btn_save">{common.getTrad("Salva")}</button>
                  </div>
                </div>
              </form>
            </div>
          :
            <div id="creazioneUtente" className="tab-pane active tab">
              <div className="row">
                <div className="col p-0">
                  <form noValidate action="#" method="post" className="nuovo_utente" onSubmit={(e) => {this.handleAddUtente(e)}}>
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="DCAPOWUS">{common.getTrad("Email")}: <span className="danger">*</span></label>
                          <input maxLength="150" autoComplete="email" type="email" id="DCAPOWUS" name="DCAPOWUS" className="form-control required" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="conferma_email">{common.getTrad("Conferma Email")}: <span className="danger">*</span></label>
                          <input type="email" autoComplete="off" name="conferma_email" id="conferma_email" className="form-control required" maxLength="150" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="RUSERWUS">{common.getTrad("Nome e Cognome del dipendente")}: <span className="danger">*</span></label>
                          <input type="text" autoComplete="off" name="RUSERWUS" id="RUSERWUS" className="form-control required" maxLength="150" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="cpwd0">{common.getTrad("Password: (min. 8 caratteri)")} <span className="danger">*</span></label>
                          <input type="password" autoComplete="new-password"  minLength="8" className="form-control required" id="cpwd0" name="cpwd0" />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="conferma_password">{common.getTrad("Conferma Password")}: <span className="danger">*</span></label>
                            <input type="password" autoComplete="off" name="conferma_password" id="conferma_password" className="form-control required"  />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-12 bottoni_invio">
                        <button id="annullaUtente" type="button" className="btn btn-light  mr-2" onClick={
                          () => {
                            bootbox.confirm(
                              {
                                message: common.getTrad("L'azione cancellerà i dati appena inseriti.<br><b>Si è sicuri di voler procedere?</b>"),
                                buttons: {
                                  confirm: {
                                    label: common.getTrad('Si'),
                                    className: 'btn-danger'
                                  },
                                  cancel: {
                                    label: common.getTrad('No'),
                                    className: 'btn-light'
                                  }
                                },
                                callback: (result) => {
                                  if(result)
                                  this.setState({newUtente: false});
                                }
                              }
                           )
                         }
                        }>{common.getTrad("Annulla")}</button>
                        <button type="submit" className="btn btn_save">{common.getTrad("Salva")}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

class PageUser extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.state.employee_rmi = {};
    this.state.employee_rmi.employee_rmi = [];
    }

  renderSelectLingue(){
    try{
      return window.cc.languages.map(function(index, key){
        return(
          <option key={key} value={index}>
          {env.languageName[index]}
          </option>
        )
      });
    }catch(e){}
  }

  UNSAFE_componentWillMount(){
    document.getElementById('body').className='my_profile';
    common.apiCall(env.BACKEND + "@get-employee", "GET", {}, true)
      .then(response=>{
        this.setState({employee_rmi: response});
    });
  }

  componentDidUpdate(props){
    const $ = window.$;
    var userID = this.props.userEdit;

    if($("#fbloc").val() !== 'S'){
      $('#fbloc').trigger('click');
      $('#stato_utente').html(common.getTrad("Attivo"));
      $("#fbloc").val('N');

    }else{
      $('#stato_utente').html(common.getTrad("Disattivo"));
      $("#fbloc").val('S');
    }

    $(".cambiaStato").click(() => {
      if ($('#fbloc').is(':checked')){
        $('#stato_utente').html(common.getTrad("Attivo"));
        $("#fbloc").val('N');
        $('body #content_sidebar li .active i').hasClass("fa-lock disabilitato");
        $('body #content_sidebar li .active i').removeClass("fa-lock disabilitato");
        $('body #content_sidebar li .active i').addClass("fa-lock-open abilitato");
      }else{
        $('#stato_utente').html(common.getTrad("Disattivo"));
        $("#fbloc").val('S');
        $('body #content_sidebar li .active i').hasClass("fa-lock-open abilitato");
        $('body #content_sidebar li .active i').removeClass("fa-lock-open abilitato");
        $('body #content_sidebar li .active i').addClass("fa-lock disabilitato");
      }

      var data = {
        fbloc: $('#fbloc').val(),
        cuser: userID,
      }

      common.apiCall(common.getBackendUrl(env.BACKEND) + "@sw-update-stato-user", "GET", data, true)
      .then(response=>{
        if(response.error === false){
          $('#notifica_messaggio').html('<div class="sw-alert alert alert-'+ (response.FBLOCWUS === 'S' ? 'warning' : 'success') + ' animated delay-2s fadeOutRight">' + common.getTrad("Stato utente cambiato in " + (response.FBLOCWUS === 'S' ? 'Disattivo' : 'Attivo')) + '</div>');
        }else{
          response = JSON.parse(response)
          $('#notifica_messaggio').html('<div class="alert alert-danger animated">' + common.getTrad("Errore qualcosa è andato storto.") + " <b>" + response["type"] + "</b> " + response["message"] +'</div>');
        }
      });
    });
  }

  render(props){
    return (
      <>
        <div id="notifica_messaggio"></div>
        <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8 linea_sfumata" id="content_page">
          <div className="row">
            <div className="content_titolo">
              <div className="content_titolo_int">
                <span className="titolo_sezione">{common.getTrad("Profilo dipendente")}</span>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
          {this.state.employee_rmi.employee_rmi.map((index, key) => {
            if(index.CUSER === this.props.userEdit){
              return (
                <div key={key} id="modifica_utente_io" className="tab-pane active">
                  <div className="row elenco-voci-dettaglio">
                    <div className="col-md-12">
                      <h4>{common.getTrad("Riepilogo Dati")}</h4>
                      <hr/>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <h4>{common.getTrad("Anagrafica")}:</h4>
                      <p>
                        <span className="label">{common.getTrad("Nome e Cognome")}</span>
                        <b><span className="label_value">{index.RUSER}</span></b>
                      </p>

                      <div className="clear"></div>
                      {this.props.nazioni !== undefined && this.props.nazioni !== null && this.props.nazioni.length > 0 ?
                        <p>
                          <span className="label">{common.getTrad("Paese")}</span>
                          <b>
                            <span className="label_value">{this.props.nazioni[common.object_value_in_array(this.props.nazioni, 'CNASWTNA', index.CNAZI)].TNAZITNA === 'EU' ? common.getTrad("nazione_" + this.props.nazioni[common.object_value_in_array(this.props.nazioni, 'CNASWTNA', index.CNAZI)].CISO2TNA ) : this.props.nazioni[common.object_value_in_array(this.props.nazioni, 'CNASWTNA', index.CNAZI)].DNAZITNA} -&nbsp;
                              <img src={env.STATIC + "images/bandiere/"+ this.props.nazioni[common.object_value_in_array(this.props.nazioni, 'CNASWTNA', index.CNAZI)].CISO3TNA+".svg"} className="img-fluid" width="24" title={common.getTrad("nazione_" + this.props.nazioni[common.object_value_in_array(this.props.nazioni, 'CNASWTNA', index.CNAZI)].CISO2TNA)} alt={common.getTrad("nazione_" + this.props.nazioni[common.object_value_in_array(this.props.nazioni, 'CNASWTNA', index.CNAZI)].CISO2TNA)} />
                            </span>
                          </b>
                        </p>
                      :
                        ""
                      }
                      <div className="clear"></div>
                      <p>
                        <span className="label">{common.getTrad("Email")}</span>
                        <b><span className="label_value">{index.DCAPO}</span></b>
                      </p>
                      <div className="clear"></div>
                      <p>
                        <span className="label">{common.getTrad("Lingua preferita")}</span>
                        <b><span className="label_value">{common.getTrad("lingua_" + index.CLING)}</span></b>
                      </p>
                      <div className="clear"></div>
                      {this.props.userInfo.userInfo.RMI && this.props.userInfo.userInfo.FCONS === "S" ?
                        <form action="#" noValidate>
                           <p>
                            <span className="label">{common.getTrad("Stato utente")}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span id="stato_utente" className="font-weight-bold"></span>
                            <b>
                              <span className="label_value">
                                <label className="switch float-right" htmlFor="fbloc" >
                                  <input name="fbloc" className="cambiaStato" autoComplete="off" id="fbloc" defaultValue={index.FBLOC} data-cuser={this.props.userEdit} type="checkbox" />
                                  <span className="slider round"></span>
                                </label>
                              </span>
                            </b>
                          </p>
                          <div className="clear"></div>
                        </form>
                      :
                        <></>
                      }
                     </div>
                  </div>
                </div>
                )
              }else{
                return ('')
              }
            })}
          </div>
        </div>
      </>
    )
  }
}


export default MyProfile;
