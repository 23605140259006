import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
const common = require('../common/common');
const env = require("../../config.js").default;
try {
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
}

class PaperlessTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.searchRecords = false;
    this.state.recordTabella = {};
    this.state.recordTabella.items = [];
    this.primoGiro = true;
  }

  async handleDownloadFile(id, fileID){
    const $ = window.$;
    var id_file = fileID;
    var param_name = "id=";
    if(id !== undefined && id === 'ALL'){
      id_file = []
      for (var i = 0; i < this.state.recordTabella.items.length; i++) {
        if(id_file.indexOf(this.state.recordTabella.items[i].id) <= -1)
          id_file.push(this.state.recordTabella.items[i].id)
      }
      id_file = id_file.join('|')
      param_name = "ids=";
    }
    await common.getBlob(id, env.BACKEND + "@@get-paperless?" + param_name + id_file)
  }

  componentDidMount(props){
    const $ = window.$;
    this.dichiaraTabella();

    $('#ricerca_filtro_telaio #btn_cercaT').click((e) => {
      e.preventDefault();
      if($("#EDTNTELA").val() !== undefined && $("#EDTNTELA").val() !== null && $("#EDTNTELA").val() !== ''){
        common.apiCall(env.BACKEND + "@paperless?serial_numbers=" + $("#EDTNTELA").val(), "GET", {}, true)
        .then(response=>{
          if(response.error === false){
            $("#resetta_ricerca").show();
    				$("#btn_cercaT").hide()
  						.removeClass("loading")
  						.attr("disabled", false);
            var items = [];
            items = response.items;
            this.setState({searchRecords: true, recordTabella: response});
            $("#table_PaperlessTable").DataTable().clear().draw();
            if(items.length > 0)
              $("#table_PaperlessTable").dataTable().fnAddData(items);
          }
        });
      }
    });
  }

  dichiaraTabella(){
    const $ = window.$;

    if (!this.primoGiro)
      return false;

    this.primoGiro = false;
    $("#table_PaperlessTable").DataTable({
      "retrieve": true,
      responsive: true,
      "tabIndex": -1,
      "pageLength": env.TABLE_PAGE_LENGTH,
      "lengthChange": false,
      "paging": false,
      "info": false,
      "ordering": true,
      "searching": false,
      "autoWidth":	true,
      "order": [[ 0, "asc" ]],
      data: [],
      columns: [
        {
          title: common.getTrad("Titolo"),
          data: 'title',
        },
        {
          data: 'id',
          orderable: false,
          "render": function ( data, type, row, meta) {
            return ReactDOMServer.renderToStaticMarkup(
              <span className="ContentcellTools descrText" id={"ContentcellTools_" + meta.row} data-btn-download={meta.row}>
                <button style={{display: "flex", width: "42px"}} className="bottone_default btn downloadButton float-righ" id={"downloadButton_" + meta.row} data-btn-download={meta.row}>
                  <span className={"downloadIconFile_" + meta.row} data-btn-download={meta.row}>
                    <i className="fas fa-download" data-btn-download={meta.row}></i>
                  </span>
                  <span className={"downloadFileLoader downloadFileLoader_" + meta.row}><i className="fas fa-spinner fa-spin"></i></span>
                </button>
              </span>
            );
          }
        }
      ],
      "language": {
				"url": env.STATIC + "lib/datatables/i18n/" + common.getLanguage() + ".lang"
			},
      fnRowCallback: ( nRow, aData, iDisplayIndex, iDisplayIndexFull ) => {
				$(nRow).on('click', (e) => {
          e.preventDefault();
          if(e.target.cellIndex !== undefined || e.target.className.indexOf('descrText') <= -1){
            if($(e.target).data('btn-download') > -1){
              this.handleDownloadFile($(e.target).data('btn-download'), aData.id);
            }
          }
        });
      },
    });

    $("#table_PaperlessTable").on("click", ".ContentcellTools ", (e) => {
      if($("#table_PaperlessTable").hasClass("collapsed")){
        var rowNum = $(e.target).data('btn-download');
        this.handleDownloadFile(rowNum, this.state.recordTabella.items[rowNum].id);
        return false;
      }
    });

    $("#table_PaperlessTable").DataTable().responsive.recalc();
    $('table').resize();
  }

  componentDidUpdate(nextProps){
    return true;
    const $ = window.$;
    if(this.state.recordTabella.items_total > 0){
      $("#table_PaperlessTable").DataTable().rows.add(this.state.recordTabella.items).draw();
    }
  }

  render(props){
    return(
      <div className="tab active">
        <div className="row">
          {this.state.searchRecords ?
            this.state.recordTabella.items_total <= 0 ?
              <div id="actions_page" className="mb-0 col">
                <div className="clear alert alert-warning">
                  {common.getTrad("Non esistono documenti per la matricola inserita, prego verificare ed eventualmente aprire un ticket")}
                </div>
              </div>
            :
              <></>
          :
            <div id="actions_page" className="mb-0 col">
              <div className="clear alert alert-info">
                {common.getTrad("Inserisci il numero di matricola per effettuare la ricerca dei documenti.")}
              </div>
            </div>
          }
        </div>
        <table id="table_PaperlessTable" className="table table-striped table-bordered dt-responsive" style={{width: '100%'}}></table>
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="row">
            {this.state.recordTabella.items_total > 0 ?
              <div className="col-xs-12">
                <div className="dataTables_info">
                  {common.getTrad("Elementi mostrati") + " (" + this.state.recordTabella.items.length + ") " + common.getTrad(" su un totale di") +" (" + this.state.recordTabella.items_total+ ")"}
                </div>
              </div>
            :
              <></>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default PaperlessTable;
