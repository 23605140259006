import React from 'react';
import './footer.css';
import { Link } from "react-router-dom";
const common = require('../common/common');
const env = require("../../config.js").default;

class Footer extends React.Component {
  renderFooter(){
    try{
      return(
        <p>
          {window.cc.companyInfo.name}<wbr/>
          {window.cc.companyInfo.address !== "" || window.cc.companyInfo.zip !== "" || window.cc.companyInfo.city !== "" || window.cc.companyInfo.province !== "" || window.cc.companyInfo.country !== "" ? " - " : ""}
          {window.cc.companyInfo.address !== "" ? window.cc.companyInfo.address + ", " : ""} <wbr/>
          {window.cc.companyInfo.zip !== "" ? window.cc.companyInfo.zip + " " : ""} <wbr/>
          {window.cc.companyInfo.city !== "" || window.cc.companyInfo.province !== "" ? window.cc.companyInfo.city + " " + (window.cc.companyInfo.province !== "" ? "(" + window.cc.companyInfo.province +")" : "") : ""}
          {window.cc.companyInfo.country !== "" && window.cc.companyInfo.country !== window.cc.companyInfo.province ?
            " - " + window.cc.companyInfo.country
          :
            ""
          }
          <br/>
          <span>
          {window.cc.companyInfo.site !== "" ? <><i className="fas fa-globe"></i>: <a target="_blank" rel="noopener noreferrer" href={window.cc.companyInfo.site}>{window.cc.companyInfo.site.replace("http://", "").replace("https://", "")}</a>&nbsp;|&nbsp;</> : ""}
          </span>
          <span>
          {window.cc.companyInfo.phone !== "" ? <><i className="fas fa-phone"></i>: <a href={"tel:" + window.cc.companyInfo.phone}>{window.cc.companyInfo.phone}</a>&nbsp;|&nbsp;</> : ""}
          </span>
          <span>
            {window.cc.companyInfo.email !== "" ? <><i className="fas fa-at"></i>: <a href={"mailto:" + window.cc.companyInfo.email}>{window.cc.companyInfo.email}</a>&nbsp;|&nbsp;</> : ""}
          </span>
          <span>
          {common.getTrad("P.IVA")}: {window.cc.companyInfo.piva}
          </span>
        </p>
      )
    }catch(e){ return(<></>)}
  }

  componentDidMount(){
    const $ = window.$;
    window.setTimeout(function(){
      //Cambio il colore del logo swan in base al colore del footer
      //Necessario timeout perchè all'inizio il background risulta bianco
      if($("#footer").css("background-color") !== undefined && $("#footer").css("background-color") !== null)
        if(common.colorLightOrDark($("#footer").css("background-color")) === 'dark'){
          $("#poweredby_light").hide();
          $("#poweredby_dark").show();
        }else{
          $("#poweredby_dark").hide();
          $("#poweredby_light").show();
        }
    }, 500);
  }

  render(){
    var d = new Date();
    return(
      <div id="footer" className="footer_home">
				<div id="copyright" className="container-fluid">
					<div className="row">
						<div className={(window.cc.registrationFlag ? "col-xl-4 col-lg-4 col-md-4" : "col-xl-8 col-lg-8 col-md-8") + " col-sm-12 col-xs-12"}id="portal-footer">
              {this.renderFooter()}
						</div>
            {window.cc.registrationFlag ?
  						<div style={{textAlign: "center", marginTop: "1em"}} className="col-xs-4 col-lg-4 col-md-4 col-sm-6 col-xs-12 content_navigazione_standard">
  							<Link to="/sitemap" className="btn bottone_default" rel="noopener" title={common.getTrad("Navigazione Standard RMI")}>
  								{common.getTrad("Navigazione Standard RMI")}
  							</Link>
  						</div>
            :
              ""
            }
						<div className={(window.cc.registrationFlag ? "col-xs-4 col-lg-4 col-md-4 col-sm-6" : "col-xs-4 col-lg-4 col-md-4 col-sm-12") + " col-xs-12 content_logo_footer"}>
							<a className="copyLogo row" href="https://www.softway.it/" target="_blank" rel="noopener noreferrer" title="SOFTWAY">
                <div className="col-lg-8 col-6 text-right p-0">
                  <img className="poweredby" id="poweredby_light" src={env.STATIC + "images/logo-swan.svg"} alt="Swan" title="Swan" />
                  <img className="poweredby" id="poweredby_dark" src={env.STATIC + "images/logo-swan-white.svg"} alt="Swan" title="Swan" style={{"display": "none"}}/>
                  <small className="text-right">© 1993-{d.getFullYear()} </small>
                </div>
                <div className="col-lg-4 col-6 text-left">
                  <img className="poweredby" src={env.STATIC + "images/powered.png"} alt={process.env.REACT_APP_VERSION + " SOFTWAY powered "} title={process.env.REACT_APP_VERSION + " SOFTWAY powered "} />
                </div>
              </a>
						</div>
					</div>
				</div>
			</div>
    )
  }
}

export default Footer;
