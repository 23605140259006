import React from 'react';
const common = require('../common/common');

class TicketState extends React.Component{
	constructor(props){
    super(props);
    this.state = {};
    this.state.userInfo = {};
    this.state.userInfo.roles = [];
    this.state.marcaDefault = this.state.marcaDefault === undefined ?
      window.cc.marche_attive !== undefined && window.cc.marche_attive.length === 1 ?
        window.cc.marche_attive[0].ZFORN
      :
        common.get_localStorage("valoreMarca")
    :
      this.state.marcaDefault;
 	}

	UNSAFE_componentWillMount(props){
		const $ = window.$;
		common.getUserInfo().then(userInfo =>{
			this.setState({userInfo: userInfo});
			var statiTicket = [];
			var parametri = {firstTime: true, statiTicket: []};
			if(common.get_localStorage('filtriTickets') !== null && common.get_localStorage('filtriTickets') !== '' && common.get_localStorage('filtriTickets') !== undefined){
				statiTicket = JSON.parse(common.get_localStorage('filtriTickets'));
			}else if (userInfo.is_admin_ticket !== undefined && userInfo.is_admin_ticket){
				statiTicket = ['pending', 'incomplete', 'published'];
			}else{
				statiTicket = ['pending', 'incomplete', 'published', 'closed'];
			}
			parametri.statiTicket = statiTicket;
			this.props.onClick("stati_ticket", parametri, true);
			return false;

		})
	}

	render(props){
		return (
			<>
				<div className="col-md-12 col-sm-12 col-xs-12 row-eq-height">
	        <div className="col-12 p-0 filtro_stati">
	          <p>{common.getTrad("Filtra per stati")}</p>
						{this.state.title}
	          <div className="form-group">
		 					<div className="c-inputs-stacked">
								<div className="d-inline-block custom-control custom-checkbox mr-3 mb-3">
									<input onClick={() => this.props.onClick("stati_ticket", {statiTicket: "pending"}, false)} type="checkbox" name="pending" value="pending" className="custom-control-input filtri" data-stato="pending" id="pending" />
									<label className="custom-control-label" htmlFor="pending">
									<span style={{background: "#28a745", boxShadow:"1px 1px 3px -1px #000", padding: "0.25em 0.5em",color: "#fff"}}><i className="fas fa-envelope-open-text"></i> {common.getTrad("Ticket Aperti")}</span>
									</label>
								</div>
								<div className="d-inline-block custom-control custom-checkbox mr-3 mb-3">
									<input onClick={() => this.props.onClick("stati_ticket", {statiTicket: "published"}, false)} type="checkbox" name="published" value="published" className="custom-control-input filtri" data-stato="published" id="published" />
									<label className="custom-control-label" htmlFor="published">
										<span style={{background: "#007bff", boxShadow:"1px 1px 3px -1px #000", padding: "0.25em 0.5em",color: "#fff"}}><i className="fas fa-envelope"></i> {common.getTrad("Ticket In lavorazione")}</span>
									</label>
								</div>
								<div className="d-inline-block custom-control custom-checkbox mr-3 mb-3">
									<input onClick={() => this.props.onClick("stati_ticket", {statiTicket: "incomplete"}, false)} type="checkbox" name="incomplete" value="incomplete" className="custom-control-input filtri" data-stato="incomplete" id="incomplete" />
									<label className="custom-control-label" htmlFor="incomplete">
										<span style={{background: "#ffc107", boxShadow:"1px 1px 3px -1px #000", padding: "0.25em 0.5em",color: "#000"}}><i className="fas fa-envelope-open"></i> {common.getTrad("Ticket Incompleti")}</span>
									</label>
								</div>
								<div className="d-inline-block custom-control custom-checkbox mb-3">
									<input onClick={() => this.props.onClick("stati_ticket", {statiTicket: "closed"}, false)} type="checkbox" name="closed" className="custom-control-input filtri" value="closed" data-stato="closed"  id="closed"/>
									<label className="custom-control-label" htmlFor="closed">
									<span style={{background: "#dc3545", boxShadow:"1px 1px 3px -1px #000", padding: "0.25em 0.5em",color: "#fff"}}><i className="fas fa-envelope"></i> {common.getTrad("Ticket Chiusi")}</span>
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
    )
  }
}

export default TicketState;
