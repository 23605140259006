import React from 'react';
import moment from 'moment';
import '../../css/ticket.css';
import { error } from 'jquery';
const common = require('../common/common');
const env = require("../../config.js").default;


class PageTicket extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.keyComments = -1;
    this.state.userInfo = {};
    this.state.userInfo.roles = [];
    this.state.userInfo.userInfo = {};
    this.state.pageContent = {};
    this.state.Lock = {};
    this.state.pageContent.description = [];
    this.state.pathname = "";
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state.tipologie_ticket_attive = common.getTicketTypesByBrand();
    this.state.googleCloudBucketPath = (window.cc.frontendCustomization.bucket_google + 'support-tools/ticket/');
  }

  UNSAFE_componentWillMount(props){
    try{
      this.props.pageContent.description = JSON.parse(this.props.pageContent.description);
    }catch(e){
      this.props.pageContent.description = {};
      return ""
    }

    document.getElementById('body').className='content_page content_ticket';
    this.setState({Lock :  this.props.Lock});


    this.setState({pageContent :  this.props.pageContent});
    this.setState({pathname : this.props.pathname});
    common.getUserInfo().then(userInfo =>{
      this.setState({userInfo: userInfo});
    })

    var brand = this.props.pageContent.subjects.find(a =>a.includes("sw-brand"));
    var tipologia = this.props.pageContent.subjects.find(a =>!a.includes("sw-brand"));
    if(brand !== undefined && brand.length > 0){
      brand = brand.split('sw-brand-')[1];
    }else{
      brand = this.props.marche_attive[0].ZFORN;
    }

    this.setState({marcaDefault: brand})

    if(common.get_localStorage("sisterCompany") !== undefined && common.get_localStorage("sisterCompany") !== null)
      window.cc.sisterCompany = JSON.parse(common.get_localStorage("sisterCompany"));

    if(window.cc.sisterCompany !== undefined && window.cc.sisterCompany.length > 0){
      var tipologia = this.props.pageContent.subjects.find(a =>!a.includes("sw-csoft"));
      var csoft_gruppo = this.props.pageContent.subjects.find(a =>a.includes("sw-csoft"));
      var numSisterCompany = common.object_value_in_array(window.cc.sisterCompany, 'value', csoft_gruppo);
      var csoftGroup = window.cc.sisterCompany[numSisterCompany].CSOFT + ' - ' + window.cc.sisterCompany[numSisterCompany].RSOCI;
      this.setState({csoftGroup: csoftGroup});
    }

    this.setState({tipoTicket: tipologia});
    const $ = window.$;
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });
  }

  addTemporayFile(){
    const $ = window.$;
    var file = $("#file_comment")[0].files[0].name;
    this.setState({file_info_upload: file})
  }

  async handleDownloadFile(fileurl, filename){
    const $ = window.$;

    $("#downloadFileLoader" ).css("display", "inline");
    var fileContent = await common.apiCall(fileurl, "GET", {}, true, false, "blob")
    .then(data => {
      $("#downloadFileLoader").css("display", "none");
      return data;
    });

    var element = document.createElement('a');
    element.setAttribute('href', fileContent);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);

    return;
  }

  async download_file_from_bucket(pFILENAME, pURL) {
    var directory = pFILENAME.split("/")
    var filename = directory.slice(-1);
    fetch(pURL)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => console.error('Download error: ' + filename));
  }

  statoTicket(){
    switch(this.state.pageContent.review_state) {
      case 'closed': case 'closed_visto' :
        return  ('<span style="background: #dc3545;color:#fff;"><i class="fas fa-envelope"></i> ' + common.getTrad("Chiuso") + '</span>')
      case 'incomplete': case 'incomplete_visto': case 'incomplete_draft':
        return  ('<span style="background: #ffc107;color:#000;"><i class="fas fa-envelope-open"></i> ' + common.getTrad("Incompleto") + '</span>')
      case 'published': case 'published_visto':
        return  ('<span style="background: #007bff;color:#fff;"><i class="fas fa-envelope-open"></i> ' + common.getTrad("In lavorazione") + '</span>')
      default:
        return  ('<span style="background: #28a745;color:#fff;"><i class="fas fa-envelope-open-text"></i> ' + common.getTrad("Aperto") + '</span>')
    }
  }

  renderVociTicket(messageErrors={}) {
    var description = this.state.pageContent.description;
    var campiTicket = this.state.tipologie_ticket_attive[this.state.tipoTicket];
    if(this.state.marcaDefault !== undefined)
      campiTicket = this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket];

    this.keyComments = common.object_value_in_array(description, 'id', 'comments');

    return campiTicket.campi.map((index, key) =>{
      if(index.id === 'file')
        this.state.googleCloudBucketPath = false
      if((index.id !== 'file' && index.id !== 'file_multiple' && index.id !== 'comments' && index.id !== 'mandatory_attachments' && index.id !== 'mandatory_attachments_2') || (messageErrors[index.id] === 'REQUIRED')){
        var numCampo = common.object_value_in_array(description, 'id', index.id);
        if(numCampo > -1 && description[numCampo].id === index.id){
          let printValue = description[numCampo].value;
          if(!printValue || index.id === 'file_multiple')
            printValue = '';
          else{
            if (index.html.indexOf("date_input") > -1)
              printValue = moment(description[numCampo].value).isValid() ? moment(description[numCampo].value).format('L') : '';
            else 
              printValue = common.getTrad(description[numCampo].value);
          }
          let errorMessage = "";
          if (messageErrors[index.id] !== undefined && messageErrors[index.id] === 'REQUIRED')
            if (index.id === 'file_multiple') {
              errorMessage = (
                <>
                  <i className="text-danger fas fa-exclamation-circle"></i>&nbsp;
                  <span>{common.getTrad("Attenzione!<br/>È necessario caricare almeno {{nAttachment}} file!", { nAttachment: index.nAttachment }).replace("<br/>", " ")}</span>
                </>
              )
            } else {
              errorMessage = (
                <>
                  <i className="text-danger fas fa-exclamation-circle"></i>&nbsp;
                  <span>{common.getTrad("Campo obbligatorio")}</span>
                </>
              )
            }
          
          return(
            <tr key={key}>
              <td className="voce_dati"><b>{common.getTrad(index.titolo)}</b></td>
              <td className="valore_dati">
                {printValue}
                {errorMessage}
              </td>
            </tr>
          )
        }
      }
      return false
    });
  }


  renderChatComments(){
    if(this.state.pageContent.description[this.keyComments] !== undefined && this.state.pageContent.description[this.keyComments] !== undefined && this.state.pageContent.description[this.keyComments].value !== undefined && this.state.pageContent.description[this.keyComments].value !== undefined){
      var reviewer = false;
      if(this.state.userInfo.is_admin_ticket){
        reviewer = true;
      }

      if(this.state.pageContent.description[this.keyComments] !== undefined && this.state.pageContent.description[this.keyComments].value !== undefined && Array.isArray(this.state.pageContent.description[this.keyComments].value)){
        return this.state.pageContent.description[this.keyComments].value.map((index, key) => {
          var classDefault = reviewer ? index.roles.indexOf("Reviewer") > -1 ? "sent message" : "received message" : index.roles.indexOf("Reviewer") > -1 ? "received message" : "sent message";
          if(index.attachment !== undefined && index.attachment !== null)
            classDefault = classDefault + " has_attachment";
          return(
            index.text !== undefined ?
              <div key={key} className={classDefault}>
                {index.attachment !== undefined && index.attachment !== null ?
                  <div>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center mr-auto">
                        <img src={env.STATIC + "images/estensioni/" + index.attachment.name.split('.').pop() + ".png"}
                          onError={(e)=>{e.target.onerror = null; e.target.src=env.STATIC + "images/estensioni/default.png"}} className="img-responsive mr-2"
                          alt={index.attachment.name} title={index.attachment.name}
                        />
                        <small>
                          <span>{common.getTrad("File")}: {index.attachment.name}</span>
                          {index.attachment.size !== undefined && (
                            <><br />{common.getTrad("Dimensione")}: <small>{common.humanFileSize(index.attachment.size)}</small></>
                          )}
                        </small>
                      </div>
                      <button className="btn btn-sm mr-2" onClick={() => { this.download_file_from_bucket(index.attachment.name, index.attachment.file) }}>
                        <i className="fas fa-file-download"></i>&nbsp;{common.getTrad("Scarica")}
                      </button>
                      <a className="btn btn-sm" target="_blank" href={index.attachment.file}>
                        <i className="fas fa-eye"></i>&nbsp;{common.getTrad("Visualizza")}
                      </a>
                    </div>
                  </div>
                :
                  ""
                }
                {index.text.search("\n") > -1  ?
                  <div dangerouslySetInnerHTML={{ __html: (index.text).replace(/\r?\n/g,'<br/>')}} />
                :
                  index.text
                }
      					<span className="metadata">
                  <span className="time">
                    {moment(index.created).format('LLL')}
                    <br />
                    <i className="fas fa-user"></i>&nbsp;{index.author}
                  </span>
                </span>
      				</div>
            :
            <></>
          )
        });
      }
    }
  }

  componentDidUpdate() {
    const $ = window.$;
    // Rimuovo l'event listener esistente se no invia molteplici commenti con il ctrl + invio
    $(document).off('keydown', this.handleKeyDown);
    $(document).on('keydown', this.handleKeyDown);
  }

  componenteDidMount() {
    const $ = window.$;
    $(document).off('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const $ = window.$;
    if (e.ctrlKey && e.key === 'Enter') {
      if ($("#new_comment").val() != '' && $("#new_comment").val() !== undefined)
        this.handleSubmit(e);
    }
  }

  async handleSubmit (e){
    e.preventDefault();
    var $ = window.$;

    var uploadedFiles = []
    var roles = this.state.userInfo.roles;

    $("#new_comment").prop("disabled", true);
    $(".send.btn").prop("disabled", true);
    $(".send.btn i").addClass("fa-spinner fa-spin");

    if(this.state.userInfo.is_admin_ticket)
      roles.push('Reviewer');
    this.state.pageContent.description[this.keyComments].value.push({
      "text": $('#new_comment').val().replace(/\r?\n/g,'\n'),
      "author": this.state.userInfo.nickname,
      "roles":  roles,
      "created": moment().format(),
    });

    var file = null;
    if($("#file_comment")[0] !== undefined) {
      var file = $("#file_comment")[0].files[0]
    }
    
    if (file && file !== null && file !== undefined && file !== "") {
      var file_name = file.name.split('.')
      var ext_file = file_name[file_name.length - 1];
      file_name.splice(file_name.length - 1)
      var new_file_name = file_name + '_' + Date.now() + '.' + ext_file;

      var dataJson = {
        path_name: this.state.googleCloudBucketPath + this.state.pageContent.id,
        file_name: new_file_name,
        file_type: file.type,
      }
      var mutexUp = new common.Mutex();
      common.apiCall(common.getBackendUrl("@sw-generate-backend-google-url"), "GET", dataJson)
        .then(async (dataBucket) => {
          await common.uploadFileToBucket(this, dataBucket.url, file, 1, 1, mutexUp);
          this.state.pageContent.description[this.keyComments].value[this.state.pageContent.description[this.keyComments].value.length - 1].attachment = {
            file: dataBucket.public_url,
            path: dataBucket.path,
            name: file.name,
            size: file.size,
          };

          await mutexUp.acquireQueued();

          //this.setState({ pageContent: this.state.pageContent });
          this.saveInfo(this.state.pageContent);
        });
    } else {
      this.saveInfo(this.state.pageContent);
    }
  }

  saveInfo(pageContent){
    const $ = window.$;
    var data = {};
    data = {description: JSON.stringify(pageContent.description), create_comment: true}
    if(this.state.marcaDefault !== undefined){
      data.brand = this.state.marcaDefault;
      data.tipologia = this.state.tipoTicket;
    }

    common.apiCall(env.BACKEND + this.state.pathname, "PATCH", data, true, false, "text", false, this.props.Lock.token)
    .then(data =>{
      if(data === ""){
        if(!this.state.userInfo.is_admin_ticket){
          if(pageContent.review_state === 'incomplete' || pageContent.review_state === 'incomplete_visto'){
            this.changeReviewStateComment('pending', this.state.userInfo.roles, this.props.Lock.token);
          }
        }
        $('#notifica_messaggio').html('<div class="sw-alert alert alert-success animated delay-2s fadeOutRight">' + common.getTrad("Commento salvato correttamente.") + '</div>');
        $(".conversation-compose")[0].reset();
        $("#new_comment").prop("disabled", false);
        $(".send.btn").prop("disabled", false);
        $(".send.btn i").removeClass("fa-spinner fa-spin");
        common.clear_cache_after_save("swticket");
      }else{
        data = JSON.parse(data)
        $('#notifica_messaggio').html('<div class="alert alert-danger animated">' + common.getTrad("Errore qualcosa è andato storto.") + " <b>" + data["type"] + "</b> " + data["message"]
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>'
        +'</div>');
      }
      this.setState({ pageContent: pageContent });
    });
    this.setState({file_info_upload: ''})
  }

  render(props){
    let messageErrors = {}, pos = -1;
    pos = common.object_value_in_array(this.state.pageContent.description, "id", "messageErrors");
    if(pos > -1)
      messageErrors = this.state.pageContent.description[pos]?.value;
    
    return (
      <>
        {this.state.pathname !== "" ?
          <>
            {Object.keys(this.state.tipologie_ticket_attive).length === 0 ?
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 content_testo">
                  <div className="clear sw-alert alert alert-danger mt-3">
                    <span dangerouslySetInnerHTML={{ __html: common.getTrad("I Ticket non stanno funzionando correttamente. Contatta il <b>supporto tecnico</b> se il problema persiste.")}}/>
                  </div>
                </div>
              </div>
            :
              <>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 content_testo">
                    <h1>
                      {this.state.pageContent.title}
                    </h1>
                    <div className="info_singolo_contenuto">

                      <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Data pubblicazione")}><i className="far fa-calendar-alt"></i>&nbsp;{moment(this.state.pageContent.created).format('LL')}</span>
                      <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Tipo")}><i className="fas fa-tag"></i>&nbsp;

                      {this.props.pageContent.subjects ?
                        this.state.marcaDefault !== undefined ?
                          common.getTrad(this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket].titolo)
                        :
                          this.state.tipologie_ticket_attive[this.state.tipoTicket].titolo
                      :
                        ""
                      }
                      </span>
                      <p data-toggle="tooltip" data-placement="top" title={common.getTrad("Stato avanzamento")} className="d-inline" dangerouslySetInnerHTML={{ __html: this.statoTicket()}} />
                      <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Numero ID")}><i className="fas fa-info-circle"></i>&nbsp;{this.state.pageContent.id}</span>
                      <ContentCreators creators={this.state.pageContent.creators} />
                      <ContentBrand content={this.state.pageContent} />
                      {this.state.userInfo !== undefined && this.state.userInfo.ticket_group_by_csoft && !this.state.userInfo.is_admin_ticket_group ?
                        <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Sister Company")}>
                          <i className="fas fa-network-wired"></i>&nbsp;
                          {this.state.csoftGroup}
                        </span>
                      :
                        <></>
                      }
                    </div>
                    <br/>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <table className="table table-striped table-bordered dt-responsive nowrap mb-5" style={{width: '100%'}}>
                      <tbody>{this.renderVociTicket(messageErrors)}</tbody>
                    </table>
                  </div>
                </div>
                <div className="row justify-content-between">
                  {this.state.pageContent.file !== null ?
                    <div className="col-xl-5 col-md-12 col-sm-12 content_download">
                      <h3>{common.getTrad("Download")}</h3>
                      <hr/>
                      <p>{common.getTrad("Clicca sul bottone per scaricare il file")}:<br/> <b>{this.state.pageContent.file.filename}</b> ({common.humanFileSize(this.props.pageContent.file.size)})</p>
                      <br/>
                      <button className="bottone_default btn btn-lg mt-5" id="downloadButton" onClick={() => {this.handleDownloadFile(common.getBackendUrl(this.state.pageContent.file.download), this.state.pageContent.file.filename)}}>
                        <b><i className="fas fa-file-download"></i>&nbsp;{common.getTrad("Scarica l'allegato")} &nbsp;&nbsp;<span className="downloadFileLoader" id="downloadFileLoader"><i className="fas fa-spinner fa-spin"></i></span></b>
                      </button>
                    </div>
                  :
                    messageErrors?.file === 'REQUIRED'?
                      <div className="col-sm-12">
                        <i className="text-danger fas fa-exclamation-circle"></i>&nbsp;
                        <span dangerouslySetInnerHTML={{ __html: common.getTrad("L'allegato è obbligatorio")}} />
                      </div>
                    :
                      <></>
                  }
                </div>
                {this.keyComments !== -1 ?
                  <div className="row mt-5">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
                      <div className="content_ticket_form">
                        <h4>{common.getTrad("Storico Commenti")}</h4>
                        <hr/>
                        <div className="page">
                          <div className="marvel-device nexus5">
                            <div className="top-bar"></div>
                            <div className="screen">
                              <div className="screen-container">
                                <div className="chat">
                                  <div className="chat-container">
                                    <div className="conversation">
                                    {this.state.googleCloudBucketPath && this.state.pageContent.file !== undefined && this.state.pageContent.file !== null ?
                                      <div className={this.state.userInfo.is_admin_ticket ? "sent message" : "received message"}>
                                        <div>
                                          <img src={env.STATIC + "images/estensioni/" + this.state.pageContent.file.filename.split('.').pop() + ".png"}
                                            onError={(e)=>{e.target.onerror = null; e.target.src=env.STATIC + "images/estensioni/default.png"}} className="img-responsive"
                                            alt={this.state.pageContent.file.filename} title={this.state.pageContent.file.filename}
                                          />
                                          {this.state.pageContent.file.filename}
                                          <a style={{cursor: "pointer"}} className="float-right" onClick={() => {this.handleDownloadFile(common.getBackendUrl(this.state.pageContent.file.download), this.state.pageContent.file.filename)}}>
                                            <i className="fas fa-2x fa-file-download"></i>
                                          </a>
                                          <br/>
                                          <small>{common.humanFileSize(this.props.pageContent.file.size)}</small>
                                        </div>
                                        <span className="metadata">
                                          <span className="time">
                                            {moment(this.state.pageContent.created).format('LLL')}<br/>
                                            <i className="fas fa-user"></i>&nbsp;{common.customCreators(this.state.pageContent.creators)}
                                          </span>
                                        </span>
                                      </div>
                                    :
                                      <></>
                                    }
                                    {this.renderChatComments()}
                                    {(!this.props.Lock.locked || this.state.userInfo.id === this.props.Lock.creator) && (this.state.userInfo.is_admin_ticket || (common.object_value_in_array(this.state.pageContent['@components']['actions'].object, "id", "edit") > -1 && (this.state.pageContent.review_state === 'incomplete' || this.state.pageContent.review_state === 'incomplete_visto'))) ?
                                      <>
                                        <form className="conversation-compose" type="post" onSubmit={this.handleSubmit} encType="multipart/form-data">
                                          <textarea required id="new_comment" className="input-msg form-control" name="new_comment" placeholder={common.getTrad("Scrivi un commento")}></textarea>
                                          {this.state.googleCloudBucketPath ?
                                            <div className="photo">
                                              <label htmlFor="file_comment" >
                                                <i className="fas fa-paperclip" title={common.getTrad("Carica un file")}></i>
                                              </label>
                                              <input type="file" name="file_comment" id="file_comment" onChange={() => this.addTemporayFile()} />
                                            </div>
                                          :
                                            <></>
                                          }
                                          <button className="send btn" type="submit">
                                            <div className="circle">
                                              <i className="fas fa-paper-plane"></i>
                                            </div>
                                          </button>
                                        </form>
                                        {this.state.file_info_upload !== undefined && this.state.file_info_upload !== null && this.state.file_info_upload !== '' ?
                                          <div className="sw-alert alert alert-info mt-4">
                                            <i className="fas fa-exclamation-circle"></i>&nbsp;
                                            {common.getTrad("File selezionato")}: {this.state.file_info_upload}
                                          </div>
                                        :
                                          <></>
                                        }
                                      </>
                                    :
                                      ""
                                    }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                :
                  <></>
                }
              </>
            }
          </>
          :
            <></>
        }
      </>
    )
  }

  // funzione per aggiornare lo stato solo nel caso di utente dealer e stato incompleto
  async changeReviewStateComment(newStateComment, permessi, tokenTicket){
    var url = common.getBackendUrl(this.state.pageContent["@id"]), response;
    if(!this.state.userInfo.is_admin_ticket && (this.state.pageContent.review_state === 'incomplete' || this.state.pageContent.review_state === 'incomplete_visto')){
      if(newStateComment === "pending"){
        url = url + "/@workflow/submit";
        var data = {
            brand: this.state.marcaDefault,
            tipologia: this.state.tipoTicket
        }
        response = await common.apiCall(url, "POST", data, true, false, "json", false, tokenTicket);
        if(response.error === false){
            var pageContent = this.state.pageContent;
            pageContent.review_state = response.review_state;
            this.setState({pageContent: pageContent});
            //window.location.reload();
        }
      }
    }else{
      return false;
    }
  }
}

class ContentCreators extends React.Component {
  render() {
    const custom_creators = common.customCreators(this.props.creators)
    return <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Pubblicato da")}><i className="fas fa-user"></i>&nbsp;{custom_creators}</span>
  }
}

class ContentBrand extends React.Component {
  render() {
    var brand = this.props.content.subjects.find(a =>a.includes("sw-brand"));
    if(brand !== undefined && brand.length > 0){
      brand = brand.split('sw-brand-')[1];
    }
    var indexBrand = common.object_value_in_array(window.cc.marche_portale, 'ZFORN', brand);
    if(window.cc.marche_portale !== undefined && window.cc.marche_portale[indexBrand] !== undefined)
      return <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Pubblicato da")}><i className="fas fa-tag"></i>&nbsp;
        {window.cc.marche_portale[indexBrand].DFORN}
      </span>;
    else
      return ""
  }
}

export default PageTicket;
export { ContentCreators };
export { ContentBrand };
