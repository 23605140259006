import React from 'react';

import LoginForm from '../LoginForm/LoginForm';

import {getLocalLoginToken} from "../common/common";

const env = require("../../config.js").default;
const common = require('../common/common');

class Login extends React.Component {
  componentDidMount(){
    document.title = window.cc.companyInfo.service + " Service" + " - Login";
    document.getElementById('body').className='login_page';
    common.remove_localStorage('modelli');

  }
  constructor(props) {
    super(props);
    this.state = {};
    this.state.isLoggedIn = false;
    this.state.user = "";

    var user = getLocalLoginToken();
    if(user !== false){
      this.state.isLoggedIn = true;
      this.state.user = user;
    }else{
      this.state.isLoggedIn = false;
      this.state.user = "";
    }
  }

  render(props){
    if(!this.state.isLoggedIn || this.state.user === ""){
      return(
          <LoginForm></LoginForm>
      )
    }else{
      return(
        <div>
          Benvenuto
        </div>
      )
    }
  }
}


export default Login;
