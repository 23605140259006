import React from 'react';
import { Link } from "react-router-dom";
import './GestioneContenutiSidebar.css';
import { svgIconsLoader } from "../MenuHome/MenuHome";
import './sidebar.css';

const common = require('../common/common');
const env = require("../../config.js").default;

export var ACTIONS_STATE_DECODE = [
  {
    action: '/@workflow/hide',
    state: 'private',
    title: 'Privato',
    icon_class: "fa-user-shield fas",
    color_class: "text-danger",
    btn_color_class: "btn-danger",
  },
  {
    action: '/@workflow/impostalivello1',
    state: 'livello1',
    title: 'Privato',
    icon_class: "fa-user-shield fas",
    color_class: "text-danger",
    btn_color_class: "btn-danger",
  },
  {
    action: '/@workflow/impostalivello2',
    state: 'livello2',
    title: 'Gruppo',
    icon_class: "fa-user fas",
    color_class: "text-warning",
    btn_color_class: "btn-warning",
  },
  {
    action: '/@workflow/impostalivello3',
    state: 'livello3',
    title: 'Pubblico',
    icon_class: "fa-users fas",
    color_class: "text-success",
    btn_color_class: "btn-success",
  },
  {
    action: '/@workflow/show_internally',
    state: 'internal',
    title: 'Pubblico',
    icon_class: "fa-users fas",
    color_class: "text-success",
    btn_color_class: "btn-success",
  },
  {
    action: '/@workflow/condividiconsottorete',
    state: 'livello2',
    title: 'Pubblico',
    icon_class: "fa-users fas",
    color_class: "text-success",
    btn_color_class: "btn-danger",
  },
  {
    action: '/@workflow/revocacondivisionerete',
    state: 'livello2',
    title: 'Privato',
    icon_class: "fa-user-shield fas",
    color_class: "text-danger",
    btn_color_class: "btn-success",
  },
  {
    action: '/@workflow/show_public',
    state: 'public',
    title: 'Pubblico sul sito',
    icon_class: "fa-globe fas",
    color_class: "text-primary",
    btn_color_class: "btn-primary",
  },
];

// WorkflowButton visualizza il pulsante col quale e'
// possibile cambiare lo stato del contenuto
export const WorkflowButton = class WorkflowButton extends React.Component {
  render() {
    var wfoptions = [], button_class;
    var trans = this.props.transitions;
    var is_single = this.props.is_single ? this.props.is_single : false;
	for (var i=0; i < trans.length; i++){
		var icon_class="", actionPos=-1, actionTitle="";
		var trans_id = trans[i]['@id'];
		var new_transition = ""
		for(var j in ACTIONS_STATE_DECODE){
			if(trans_id.includes(ACTIONS_STATE_DECODE[j].action)){
			actionPos = j;
			break;
			}
		}
		if(actionPos > -1){
			icon_class = ACTIONS_STATE_DECODE[actionPos].icon_class + " " + ACTIONS_STATE_DECODE[actionPos].color_class;
			actionTitle = common.getTrad(ACTIONS_STATE_DECODE[actionPos].title);
			/*
			if(!is_single)
				new_transition = ACTIONS_STATE_DECODE[actionPos].state;
			*/
		}
		/*
		if(is_single)
			new_transition = trans_id.split('/')[trans_id.split('/').length - 1];
		*/
		new_transition = trans_id.split('/')[trans_id.split('/').length - 1];

		wfoptions.push(
			<span key={i} className="dropdown-item" data-state={ new_transition } data-title={ actionTitle } style={{cursor: "pointer"}}>
				<i className={ icon_class } data-state={ new_transition } data-title={ actionTitle }></i> 
				{ actionTitle }
			</span>
		);

	}

    button_class = "btn btn-light dropdown-toggle";
    if (this.props.add_to_button_class !== undefined && this.props.add_to_button_class !== null)
			button_class = button_class + " " + this.props.add_to_button_class;

    return (
      <>
        <button id={ this.props.id } className={ button_class } type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={ this.props.disabled }>
          <i className="far fa-share-square"></i> {common.getTrad("Cambia Stato")}
        </button>
        <div className="dropdown-menu" onClick={ this.props.handleClick }>
          { wfoptions }
        </div>
      </>
    );
  }

}


class GestioneContenutiSidebar extends React.Component{
	constructor(props){
		super(props);
		this.state = {};
		this.state.classBloccoMenu = this.props.classBloccoMenu ? this.props.classBloccoMenu : "blocco_menu_laterale";
		this.state.pathame = this.props.pathname;
		this.state.render1 = true;//this.props.render1;
 	}

	openSidebar(e){
		e.preventDefault();
		const $ = window.$;
		$("#content_sidebar").toggleClass("pushed");
		$(".blocco_menu_laterale").toggleClass("no_width");
		$("#content_page").toggleClass("main-pushed");
		$(".mobile-bar").toggleClass('open');
    	common.set_localStorage("sidebarOpen", !$("#content_sidebar").hasClass("pushed"));
		// Check se la sidebar è aperta o è chiusa
		if( $("#content_sidebar").hasClass("pushed") === true) {
			$('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'none');
			$('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '1');
			$('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'none');
		} else {
			$('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'translate(0, 8px) rotate(45deg)');
			$('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '0');
			$('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'translate(0, -8px) rotate(-45deg)');
		}
	}

	static getDerivedStateFromProps(props, state){
		let newState = state;
		if(props.render1 !== state.render1)
			newState.render1 = props.render1;
		
		if(newState === state)
			return null;
		else
			return newState;
	}

	componentDidUpdate(){
		setTimeout(function(){svgIconsLoader()}, 1000);
		const $ = window.$;
		if(JSON.parse(common.get_localStorage("sidebarOpen"))) {
			$("#content_sidebar").removeClass("pushed");
			$(".blocco_menu_laterale").removeClass("no_width");
			$("#content_page").removeClass("main-pushed");
			$(".mobile-bar").addClass('open');
			// Mostro icona chiusura (sidebar aperta)
			$('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'translate(0, 8px) rotate(45deg)');
			$('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '0');
			$('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'translate(0, -8px) rotate(-45deg)');
		}else{
			$("#content_sidebar").addClass("pushed");
			$(".blocco_menu_laterale").addClass("no_width");
			$("#content_page").addClass("main-pushed");
			$(".mobile-bar").removeClass('open');
			// Mostro icona menù (sidebar chiusa)
			$('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'none');
			$('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '1');
			$('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'none');
		}
  	}

	onClickHandler(e, status, data){
 		if(status === true){
			e.preventDefault();
			this.openCollapse(e)
			return false;
		}else{
			this.props.onClick(e, e, data);
			return false
		}
	}

	openCollapse(e){
		const $ = window.$;
		if(e.target.nextSibling.classList !== undefined && e.target.nextSibling.classList !== null)
	 		if(e.target.nextSibling.classList.contains("show") !== true ){
				$(e.target).addClass("show in");
				$(e.target.nextSibling).addClass("show in");
			}else{
				$(e.target).removeClass("show in");
				$(e.target.nextSibling).removeClass("show in");
			}
		return false;
	}

	renderMenuArea(){
		var ret;
		try{
			ret = this.props.menuAreaButtons.items.map((value, key) => {
				var description = value.description;
					try{
						description = JSON.parse(value.description);
					}catch(e){}

				if(key > 0 && description.showGestioneContenuti !== 'false' && description.tableType !== 'contact'){
					return(
						<GestioneContenutiMenuArea pathname={this.props.pathname} classBloccoMenu={this.state.classBloccoMenu} onClick={(e, status, data) => this.onClickHandler(e, status, data)} title={value.title} id={value["@id"]} key={key} items={value["items"]}></GestioneContenutiMenuArea>
					)
				}
			});
		}
		catch{
			ret = <div/>
		}

		return ret;
	}

	render(props){
    	var checkDiskSpace = {}, diskSpacePercentage = 0;
    	if(window.cc.checkDiskSpace !== undefined && window.cc.checkDiskSpace !== null){
      		checkDiskSpace = window.cc.checkDiskSpace;
      		diskSpacePercentage = (checkDiskSpace['used'] / checkDiskSpace['available'] * 100).toFixed(2);
    	}
		return(
			<>
				{
					this.state.render1 ?
						this.state.classBloccoMenu && this.state.classBloccoMenu === "blocco_menu_laterale" ?
							<>
								<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-4 blocco_menu_laterale">
						 			<div className="mobile-bar cons">
										{/* <div className="hamburger-menu cons" onClick={(e) => this.openSidebar(e)}>
											<div className="bar cons"></div>
										</div> */}
										<div className="hamburger">
											<input 
												type="checkbox" 
												className="check_hamburger"
												name="check_hamburger" 
												checked={common.get_localStorage("sidebarOpen")}
												onClick={(e) => this.openSidebar(e)}
											/>
											<span></span>
											<span></span>
											<span></span>
										</div>
									</div>
									<div id="content_sidebar">
										<div className="card">
											<div className="card-header">
												<Link className="card-link" title={common.getTrad("Home Page")} to="/">
													<span className="ico-menu">
														<figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-home.svg"} title={common.getTrad("Home Page")} alt={common.getTrad("Home Page")} className="svg icona_menu"></figure>
													</span>
													<span className="title-menu">{common.getTrad("Home Page")}</span>
												</Link>
											</div>
										</div>
										<div className="collapse show">
											<div className="card-body">
												<div className="card">
													<div className="collapse show" >
														<div className="card-body">
															<ul className="nav nav-tabs">
															<div className="list-group list-group-tree well col">
																{ this.renderMenuArea() }
															</div>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
                    					{window.ShowDiskSpace && diskSpacePercentage > 0 ?
                      						<div id="checkDiskSpace">
												<div className="progress mx-2">
													<div className="progress-bar progress-bar-striped p-2" style={{"width": diskSpacePercentage + "%"}} role="progressbar" aria-valuenow={diskSpacePercentage} aria-valuemin="0" aria-valuemax="100"></div>
												</div>
												<small className="mx-2">{diskSpacePercentage}% ({common.humanFileSize(checkDiskSpace['used'])}) di {common.humanFileSize(checkDiskSpace['available'])} utilizzato</small>
											</div>
										:
											<></>
					                    }
									</div>
								</div>
							</>
						:
						<ul className={"nav navbar-nav " + this.props.classBloccoMenu}>
							{ this.renderMenuArea() }
						</ul>
					:
						<></>
				}
			</>
		)
	}
}

class GestioneContenutiMenuArea extends React.Component{
	constructor(props){
		super(props);
		this.state={};
		this.state.title = this.props.title;
		this.state.id = this.props.id;
		this.state.items = this.props.items;
		this.state.classBloccoMenu = this.props.classBloccoMenu;
	}

	renderMenuButton(){
		var ret;
		try{
			ret = this.state.items.map((value, key) => {
				var description = value.description;
					try{
						description = JSON.parse(value.description);
					}catch(e){}

				if(description.showGestioneContenuti !== 'false' && description.tableType !== 'contact'){
					return(
						<GestioneContenutiMenuButton pathname={this.props.pathname} classBloccoMenu={this.state.classBloccoMenu} onClick={(e,status, data) => this.props.onClick(e, status, data)} title={value.title} id={value["@id"]} mykey={key} description={value.description} key={key} items={value["items"]}></GestioneContenutiMenuButton>
					)
				}
			});
		}
		catch{
			ret = <div/>
		}
		return ret;
	}

	render(props){
		return(
			<>
				{this.state.classBloccoMenu && this.state.classBloccoMenu === "blocco_menu_laterale" ?
					this.state.items && this.state.items.length > 0 ?
						<>
							<a title={this.state.title} href="/#" className={"fas list-group-item "  + (window.location.pathname.indexOf(common.getFrontendUrl(this.state.id).split('/').pop()) > -1 ? "show in" : "")} onClick={(e,status) => this.props.onClick(e, true)} data-toggle="collapse">
								{this.state.title}
							</a>
							<div className={"list-group collapse " + (window.location.pathname.indexOf(common.getFrontendUrl(this.state.id).split('/').pop()) > -1 ? "show in" : "")}>
								{this.renderMenuButton()}
							</div>
						</>
					:
						<p title={this.state.title} className={"list-group-item "  + (common.getBackendUrl(this.state.id).search(this.props.pathname.substring(3)) !== -1 ? "active" : "")} onClick={(e,status, data) => this.props.onClick(this.state.id, false, this.state.title)}>
							{this.state.title}
						</p>
				:
					this.state.items && this.state.items.length > 0 ?
						<li className="root">
		        	<p title={this.state.title} style={{cursor: "normal"}} className="dropdown-item dropdown-head">{this.state.title}</p>
							{this.renderMenuButton()}
						</li>
					:
					 	<p disabled={this.props.pathname === this.state.id ? true : false} title={this.state.title} className={"dropdown-item " + (this.props.pathname === this.state.id ? "disabled" : "")} onClick={(e,status, data) => this.props.onClick(this.state.id, false, this.state.title)}>{this.state.title}</p>
				}
			</>
		);
	}
}

class GestioneContenutiMenuButton extends React.Component{
	constructor(props){
		super(props)
		this.state = {};
		this.state.id = this.props.id;
		this.state.key = this.props.mykey;
		this.state.title = this.props.title;
		this.state.classBloccoMenu = this.props.classBloccoMenu;

		var descr = this.props.description
		if (descr === "") {
			this.state.description = {"img": ""}
		}else{
			this.state.description = JSON.parse(descr);
		}
		this.state.items = this.props.items;
	}

	renderSubMenu(){
		var ret = <></>;
		try{
			if(this.state.items.length > 0){
				ret = this.state.items.map((value, key) => {
					var description = value.description;
						try{
							description = JSON.parse(value.description);
						}catch(e){}

					if(description.showGestioneContenuti !== 'false' && description.tableType !== 'contact'){
						return(
							<GestioneContenutiSubMenu pathname={this.props.pathname} classBloccoMenu={this.state.classBloccoMenu} onClick={(e,status, data) => this.props.onClick(e, status, data)} title={value.title} id={value["@id"]} key={key}></GestioneContenutiSubMenu>
						)
					}
				})
				ret =
				<>
					{this.state.classBloccoMenu && this.state.classBloccoMenu === "blocco_menu_laterale" ?
						<div className={"list-group collapse " + (window.location.pathname.indexOf(common.getFrontendUrl(this.state.id).split('/').pop()) > -1 ? "show in" : "")} >
							{ret}
						</div>
					:
						 <li className="dropdown-submenu">
						 		<p className="dropdown-head" title={this.state.title} disabled="disabled">{this.state.title}</p>
						 	 	<ul className="dropdown-menu">
									{ret}
								</ul>
						</li>
					}
				</>
			}
		}
		catch{
			ret = <></>
		}
		return ret;
	}

	render(props){
		return(
			<>
				{this.state.classBloccoMenu && this.state.classBloccoMenu === "blocco_menu_laterale" ?
					this.state.items && this.state.items.length > 0 ?
						<>
							<a title={this.state.title} href="/#" className={"fas list-group-item " + (window.location.pathname.indexOf(common.getFrontendUrl(this.state.id).split('/').pop()) > -1 ? "show in" : "")} onClick={(e,status) => this.props.onClick(e, true)} data-toggle="collapse">
								{this.state.title}
							</a>
							{this.renderSubMenu()}
						</>
					:
						<p title={this.state.title} className={"list-group-item " + (common.getBackendUrl(this.state.id).search(this.props.pathname.substring(3)) > -1 ? "active" : "")} onClick={(e,status, data) => this.props.onClick(this.state.id, false, this.state.title)}>
							{this.state.title}
						</p>
				:
					this.state.items && this.state.items.length > 0 ?
						<ul className="dropdown-menu dropdown-item">
							{this.renderSubMenu()}
						</ul>
					:

					 	<p disabled={this.props.pathname === this.state.id ? true : false} title={this.state.title} className={"dropdown-item " + (this.props.pathname === this.state.id ? "disabled" : "")} onClick={(e,status,data) => this.props.onClick(this.state.id, false, this.state.title)}>{this.state.title}</p>
				}
			</>
		)
	}
}

class GestioneContenutiSubMenu extends React.Component{
	constructor(props){
		super(props);
		this.state = {};
		this.state.id = this.props.id;
		this.state.title = this.props.title;
	  	this.state.classBloccoMenu = this.props.classBloccoMenu;
	}

	render(props){
		return(
			this.state.classBloccoMenu && this.state.classBloccoMenu === "blocco_menu_laterale" ?
				<p title={this.state.title} className={"list-group-item " + (common.getBackendUrl(this.state.id).search(this.props.pathname.substring(3).replace(/\/$/g, '')) > -1 ? "active" : "")}
					onClick={(e,status, data) => this.props.onClick(this.state.id, false, this.state.title)}>
					{this.state.title}
				</p>
			:
				<p disabled={this.props.pathname === this.state.id ? true : false} title={this.state.title} className={"dropdown-item " + (this.props.pathname === this.state.id ? "disabled" : "")} onClick={(e,status, data) => this.props.onClick(this.state.id, false, this.state.title)}>{this.state.title}</p>
		);
	}
}

export default GestioneContenutiSidebar;
