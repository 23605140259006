import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import moment from 'moment';
import bootbox from 'bootbox';
const common = require('../common/common');
const env = require("../../config.js").default;
try {
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
}

class ConsoleUtentiRMI extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.isProfileRMI = false;
    this.state.nazioni = {};
  }

  UNSAFE_componentWillMount(){
    document.getElementById('body').className='my_profile';
    this.setState({nazioni: window.cc.nazioni});
    common.apiCall(env.BACKEND + "@get-employee", "GET", {console: 'S'}, true)
      .then(response=>{
        this.setState({employee_rmi: response});
        if(common.getParam('cuser') !== undefined && common.getParam('cuser') !== null && common.object_value_in_array(response.employee_rmi, 'CUSER', common.getParam('cuser')) > -1){
          this.onClickUser(common.getParam('cuser'));
          return false;
        }
    });
  }

  onClickUser(utente){
    this.setState({userEdit: utente, isProfileRMI: utente !== 0});
  }

  render(props){
    return (
      <div id="portal-columns"  className="clearfix">
        <div className="row">
          <Sidebar userEdit={this.state.userEdit} onClick={(utente) => this.onClickUser(utente)} pageType={'console-utenti-rmi'} employee_rmi={(this.state.employee_rmi)} type="user"></Sidebar>
          {this.state.isProfileRMI ?
            <PageUserRMI employee_rmi={this.state.employee_rmi} nazioni={this.state.nazioni} userEdit={this.state.userEdit}></PageUserRMI>
          :
            <FirstLoading></FirstLoading>
          }
        </div>
      </div>
    )
  }
}

class FirstLoading extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  render(props){
    return(
      <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8 linea_sfumata" id="content_page">
        <div className="row">
          <div className="content_titolo">
            <div className="content_titolo_int">
              <span className="titolo_sezione">{common.getTrad("Console Utenti RMI")}</span>
            </div>
          </div>
        </div>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
          <div className="row">
            <div className="tab-pane active">
              <div className="row elenco-voci-dettaglio">
                <div className="col-md-12">
                  <h3>{common.getTrad("Segui i passaggi indicati")}</h3>
                  <ol>
                    <li>{common.getTrad("Seleziona un Operatore Indipendente")}</li>
                    <li>{common.getTrad("Verifica i dati inseriti")}</li>
                    <li>{common.getTrad("Approva o disapprova la richiesta")}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class PageUserRMI extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.state.employee_rmi = this.props.employee_rmi;
    this.state.employee_rmi.employee_rmi = this.props.employee_rmi.employee_rmi;
    this.state.userCompany = {};
    this.state.lastUser = "";
  }

  componentDidMount(){
    this.getUserCompany();

    const $ = window.$;
    $(".cambiaStato").click(() => {
      $(".btn.btn_save").attr("disabled", false);
      if ($('#fbloc').is(':checked')){
        $('#stato_utente').html(common.getTrad("Attivo"));
      }else{
        $('#stato_utente').html(common.getTrad("Disattivo"));
      }
    });
  }

  componentDidUpdate(props){
    this.getUserCompany();
  }

  changeUserRMIStatus(){
    const $ = window.$;
    if(!$('#fbloc').is(':checked') && $("#commento").val() === ''){
      bootbox.alert(common.getTrad("Inserisci un commento."));
      return false;
    }

    var data = {
      'CCLIE': this.state.userCompany.CCLIE,
      'CSOFT': this.state.userCompany.CSOFT,
      'FBLOC': $('#fbloc').is(':checked') ? 'N' : 'S',
      'DNOTE': $("#commento").val(),
      'RCLIE': this.state.userCompany.RCLIE,
      'DCAPO': this.state.userCompany.DCAPO,
      'abbonamenti_attivi': $(".sw-abbonamento.alert-success").length,
      'CUSER': this.state.userCompany.CUSER,
      'CLING': this.state.userCompany.CLING
    }
    common.apiCall(common.getBackendUrl(env.BACKEND) + "@sw-block-all-clie-users", "GET", data, true)
    .then(response=>{
      if(response.error === false){
        $(".btn.btn_save").attr("disabled", true);
        $('body #content_sidebar li .active').removeClass("font-weight-bold");
        if ($('#fbloc').is(':checked')){
          $('#stato_utente').html(common.getTrad("Attivo"));
          $('body #content_sidebar li .active i').removeClass("fa-lock disabilitato");
          $('body #content_sidebar li .active i').addClass("fa-lock-open abilitato");
        } else {
          $('#stato_utente').html(common.getTrad("Disattivo"));
          $('body #content_sidebar li .active i').removeClass("fa-lock-open abilitato");
          $('body #content_sidebar li .active i').addClass("fa-lock disabilitato");
        }
        $('#notifica_messaggio').html('<div class="sw-alert alert alert-'+ (!$('#fbloc').is(':checked') ? 'warning' : 'success') + ' animated delay-2s fadeOutRight">' + common.getTrad("Stato utente cambiato in " + (!$('#fbloc').is(':checked') ? 'Disattivo' : 'Attivo')) + '</div>');
      }else{
        response = JSON.parse(response)
        $('#notifica_messaggio').html('<div class="alert alert-danger animated">' + common.getTrad("Errore qualcosa è andato storto.") + " <b>" + response["type"] + "</b> " + response["message"] +'</div>');
      }
    });
  }

  getUserCompany(){
    const $ = window.$;
    if(this.props.userEdit !== this.state.lastUser){
      this.setState({lastUser: this.props.userEdit, abbonamenti_attivi: 0})
      var num = common.object_value_in_array(this.state.employee_rmi.employee_rmi, 'CUSER', this.props.userEdit);
      var userCompany = this.state.employee_rmi.employee_rmi[num];
      var data = { cclie: userCompany['CCLIE'] };

      common.apiCall(env.BACKEND + "@get-user-company", "GET", data, true)
      .then(response=>{
        response['CLING'] = userCompany['CLING'];
        response['ZUSER'] = userCompany['ZUSER'];
        response['CGRSW'] = userCompany['CGRSW'];
        response['RUSER'] = userCompany['RUSER'];
        response['FBLOC'] = userCompany['FBLOC'];
        response['DNOTE'] = userCompany['DNOTE'];
        response['CUSER'] = userCompany['CUSER'];
        $(".btn.btn_save").attr("disabled", false);
        if($('#fbloc').is(':checked') && response['FBLOC'] === 'S' || !$('#fbloc').is(':checked') && response['FBLOC'] !== 'S')
          $('#fbloc').trigger('click');

        if(response['FBLOC'] !== 'S'){
          $('#stato_utente').html(common.getTrad("Attivo"));
        }else{
          $('#stato_utente').html(common.getTrad("Disattivo"));
        }

        this.setState({userCompany: response, render: true});
        common.apiCall(env.BACKEND + "@sw-subscriptions?userid="+ response['ZUSER'] +"&cgrsw=" + response['CGRSW'] +"&cling="+common.getLanguage(true) + "&is_anonymous=false", "GET", {}, false)
        .then(subscriptions=>{
          if(subscriptions.error === false){
            this.setState({subscriptions: subscriptions});
          }
        });
      });
    }
  }

  showScadenze(subscriptions){
    var subscriptionObject = Object.keys(subscriptions);
    return subscriptionObject.map((index, key) => {
      var numMarca = common.object_value_in_array(window.cc.marche_portale, 'ZFORN', index);
      if(index !== 'version' && index !== 'error'){
        var status = 'info';
        var stato_servizio = common.getTrad("Servizio non acquistato");
        var subKey = subscriptions[index][Object.keys(subscriptions[index])[0]]
        if(subKey.data_scadenza !== undefined && subKey.data_scadenza !== null){
          if(moment(subKey.data_scadenza) < new Date()){
            status = 'danger';
            stato_servizio = common.getTrad("Servizio scaduto il {{data_scadenza}}", {data_scadenza: moment(subKey.data_scadenza).format('L')})
          }else{
            status = 'success';
            stato_servizio = common.getTrad("Servizio attivo fino al {{data_scadenza}}", {data_scadenza: moment(subKey.data_scadenza).format('L')})
          }
        }

        var description = '';
        var icon = index.toLowerCase();
        if(index === '$$' && window.cc.marche_portale.length > 1){
          icon = 'sw';
          description = common.getTrad("Tutte le marche del portale");
        }else if (index === '$$' && window.cc.marche_portale.length === 1){
          description = window.cc.marche_portale[0].DFORN;
          icon = window.cc.marche_portale[0].ZFORN.toLowerCase();
        }else{
          description = window.cc.marche_portale[numMarca].DFORN;
        }
        return (
          <tr key={key}>
            <td>
              <img style={{maxWidth: "150px"}} className="img-responsive" src={env.SWAN + "static/images/loghi/" + icon + ".png"} title={description} alt={description} />
            </td>
            <td>
              <div className={("sw-abbonamento sw-alert alert alert-" + status + " col")} role="alert">
                {stato_servizio}
              </div>
            </td>
          </tr>
        )
      }else{
        return "";
      }
    });
  }

  render(props){
    var nazione = this.state.userCompany.CNAZI;
    var indexNazione = 1;
    if(this.props.nazioni.length > 0 && nazione !== undefined && nazione !== null){
      indexNazione = common.object_value_in_array(this.props.nazioni, 'CNASWTNA', nazione);
      if(this.props.nazioni[indexNazione] !== undefined){
        var objectNazione = this.props.nazioni[indexNazione];
      }
    }
    return (
      <>
        <div id="notifica_messaggio"></div>
        <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8 linea_sfumata" id="content_page">
          <div className="row">
            <div className="content_titolo">
              <div className="content_titolo_int">
                <span className="titolo_sezione">{common.getTrad("Profilo RMI")}</span>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
            <div className="tab-pane active">
              <div className="row elenco-voci-dettaglio">
                <div className="col-md-12">
                  <h4>{common.getTrad("Riepilogo Dati")}</h4>
                  <hr/>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <h4>{common.getTrad("Anagrafica")}:</h4>
                  <p>
                    <span className="label">{common.getTrad("Ragione Sociale")}</span>
                    <b><span className="label_value">{ this.state.userCompany.RCLIE }</span></b>
                  </p>
                  <div className="clear"></div>
                  {objectNazione !== undefined && this.props.nazioni !== undefined && this.props.nazioni !== null && this.props.nazioni.length > 0 ?
                    <p><span className="label">{common.getTrad("Paese")}</span> <b><span className="label_value">
                      {objectNazione.TNAZITNA === 'EU' ? common.getTrad("nazione_" + objectNazione.CISO2TNA ) : objectNazione.DNAZITNA} -&nbsp;
                      <img src={env.STATIC + "images/bandiere/"+  objectNazione.CISO3TNA+".svg"}
                        className="img-fluid" width="24"
                        title={objectNazione.TNAZITNA === 'EU' ? common.getTrad("nazione_" + objectNazione.CISO2TNA ) : objectNazione.DNAZITNA}
                        alt={objectNazione.TNAZITNA === 'EU' ? common.getTrad("nazione_" + objectNazione.CISO2TNA ) : objectNazione.DNAZITNA}
                      />
                      </span></b>
                    </p>
                  :
                    <p>
                      <span className="label">{common.getTrad("Paese")}</span>
                      <b><span className="label_value"></span></b>
                    </p>
                  }

                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Partita IVA")}</span>
                    <b><span className="label_value">{ this.state.userCompany.NPIVA}</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Legale rappresentante")}</span>
                    <b><span className="label_value">{ this.state.userCompany.RLERA}</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Email legale rappresentante")}</span>
                    <b><span className="label_value">{ this.state.userCompany.DCAPO}</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Lingua preferita")}</span>
                    <b><span className="label_value">{ common.getTrad("lingua_" + (this.state.userCompany.CLING !== '' ? this.state.userCompany.CLING : 'EN'))}</span></b>
                  </p>
                  <div className="clear"></div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <h4>{common.getTrad("Indirizzo Fatturazione")}:</h4>
                  <p>
                    <span className="label">{common.getTrad("Provincia")}</span>
                    <b><span className="label_value">{ this.state.userCompany.CPROV }</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Città")}</span>
                    <b><span className="label_value">{ this.state.userCompany.DLOCA }</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("Indirizzo")}</span>
                    <b><span className="label_value">{ this.state.userCompany.DINDI }</span></b>
                  </p>
                  <div className="clear"></div>
                  <p>
                    <span className="label">{common.getTrad("CAP")}</span>
                    <b><span className="label_value">{ this.state.userCompany.CAPCL }</span></b>
                  </p>
                  <div className="clear"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row p-3">
            <div className="col-md-12">
              <h4>{common.getTrad("Stato utente e abbonamenti")}</h4>
              <hr/>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <form action="#" noValidate>
                <div className="form-group">
                  <label htmlFor="commento"><b>{common.getTrad("Commento")}</b></label>
                  <textarea defaultValue={this.state.userCompany.DNOTE !== undefined && this.state.userCompany.DNOTE !== null && this.state.userCompany.DNOTE !== '' ? this.state.userCompany.DNOTE : ''} rows="8" autoComplete="off" className="form-control" id="commento" name="commento"></textarea>
                </div>
                <div className="form-group">
                  <span className="label">{common.getTrad("Stato utente IO")}</span>&nbsp;&nbsp;
                  <span id="stato_utente" className="font-weight-bold"></span>
                  <b>
                    <span className="label_value">
                      <label className="switch float-right" htmlFor="fbloc">
                        <input name="fbloc" className="cambiaStato" autoComplete="off" id="fbloc" defaultChecked={this.state.userCompany.FBLOC !== 'S'} defaultValue={this.state.userCompany.FBLOC} data-cuser={this.props.userEdit} type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </span>
                  </b>
                </div>
                <div className="form-group bottoni_invio float-right">
                  <button type="button" className="btn btn_save" onClick={(e) => this.changeUserRMIStatus(e)}>{common.getTrad("Salva")}</button>
                </div>
              </form>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <p><b>{common.getTrad("Stato degli abbonamenti")}:</b></p>
              <table className="table table-striped table-bordered col">
                <thead>
                  <tr>
                    <th>{common.getTrad("Marca")}</th>
                    <th>{common.getTrad("Stato")}</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.subscriptions !==  undefined ?
                  this.showScadenze(this.state.subscriptions)
                :
                  <></>
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }
}


export default ConsoleUtentiRMI;
