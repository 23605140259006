const env = require("../../config.js").default;

const INDEX_DB_NAME = env.PORTAL_NAME;
const INDEX_DB_VERSION = 2;
const DEFAULT_TTL = 60*24 //minuti

const defaultDBOpenRequest = indexedDB.open(INDEX_DB_NAME, INDEX_DB_VERSION);
var defaultDB = null;


defaultDBOpenRequest.onupgradeneeded = function (e) {
    defaultDB = e.target.result;
    console.debug('running onupgradeneeded');
    defaultDBUpgrade(e, defaultDBOpenRequest);
};
defaultDBOpenRequest.onsuccess = function (e) {
    console.debug('running onsuccess');
    defaultDB = e.target.result;
};
defaultDBOpenRequest.onerror = function (e) {
    console.debug('onerror!');
    console.dir(e);
};


export async function getCacheDB(key, ttl = DEFAULT_TTL){
    if(ttl === null)
        ttl = DEFAULT_TTL;
        
    return new Promise(function (resolve, reject) {
        const transaction = defaultDB.transaction(["fetchCache"], "readwrite");
        const objectStore = transaction.objectStore("fetchCache");
        const request = objectStore.get(key);
        console.log(request)
        request.onerror = (event) => {
            console.log("cache error")
            // Handle errors!
            resolve(null);
        };
        request.onsuccess = (event) => {
            if (request.result !== undefined && request.result.value !== undefined){
                let now = new Date().getTime();
                let scadenza = request.result.timestamp + (ttl * 60 * 1000);
                if (scadenza < now){
                    console.debug("conteuto " + request.result.request + " scaduto in cache. Elimino");
                    objectStore.delete(key);
                    resolve(null);
                }else{
                    console.debug("conteuto " + request.result.request + " recuperato da cache");
                    resolve(request.result.value);
                }
            } else {
                resolve(null);
            }
        };
    });
}
export function refreshCacheDB(contentType, upperTimestamp){
    const transaction = defaultDB.transaction(["fetchCache"], "readwrite");
    const objectStore = transaction.objectStore("fetchCache");
    const timestampIndex = objectStore.index("timestamp");
    const getAllRequest = timestampIndex.getAll(IDBKeyRange.upperBound(upperTimestamp));
    const CONTENT_TIME_REMOVE_ALWAYS = ["all"];

    getAllRequest.onsuccess = (event) => {
        let contentTypeToSearch = CONTENT_TIME_REMOVE_ALWAYS;
        contentTypeToSearch.push(contentType);
        for (let i in getAllRequest.result){
            const key = getAllRequest.result[i].request;
            if (getAllRequest.result[i].contentType.some(r => contentTypeToSearch.includes(r))){
                console.debug("Elimino risorsa " + key + " perchè più vecchia dell'ultimo aggiornamento");
                objectStore.delete(key);
            }
        }
    }
}
export function addCacheDB(cacheData) {
    const transaction = defaultDB.transaction(["fetchCache"], "readwrite");
    const objectStore = transaction.objectStore("fetchCache");
    const request = objectStore.add(cacheData);
    request.onsuccess = (event) => {
        // event.target.result === cacheData.ssn;
    };

}
export function emptyCacheDB() {
    const transaction = defaultDB.transaction(["fetchCache"], "readwrite");
    const objectStore = transaction.objectStore("fetchCache");
    const request = objectStore.clear();
    request.onsuccess = (event) => {
        console.debug("Svuotata cache")
        // event.target.result === cacheData.ssn;
    };

}

export function deleteRecord(record) {
    const transaction = defaultDB.transaction(["fetchCache"], "readwrite");
    const objectStore = transaction.objectStore("fetchCache");
    var index = objectStore.index("indexes");
    var request = index.get(record);

    request.onsuccess = function(event) {
        var recordToDelete = event.target.result;
        if (recordToDelete) {
            var deleteRequest = objectStore.delete(recordToDelete)
            deleteRequest.onsuccess = function () {
                console.log('Record eliminato con successo:', recordToDelete);
            };
            deleteRequest.onerror = function (event) {
                console.error('Errore durante l\'eliminazione del record:', event.target.error);
            }
        }
        else {
            console.log('Nessun record trovato con il valore:', recordToDelete);
        }
    }
    request.onerror = function (event) {
        console.error('Errore durante la ricerca del record:', event.target.error);
    };
    transaction.oncomplete = function () {
        console.log('Operazione completata');
    };
}

function defaultDBUpgrade(e, rq){
    var objectStore = null;

    try {
        defaultDB.deleteObjectStore('fetchCache');
    } catch (e) {
        console.debug("tabella non presente")
    }
    objectStore = defaultDB.createObjectStore('fetchCache', { keyPath: 'request' });
    objectStore.createIndex("timestamp", "timestamp");
    objectStore.createIndex("contentType", "contentType");
    /*if (e.oldVersion < 2) {
    }*/
}