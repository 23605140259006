import React from 'react';

import MenuHome from '../MenuHome/MenuHome';
import Support from '../Support/Support';
import {UserButton, LanguageButton, SupportButton, Search, MenuButton, AgentiButton, SwitchTheme, NotificationButton, PromoButton} from './HeadersButtons';
import { Link } from "react-router-dom";
import NotificationMenu from '../NotificationMenu/NotificationMenu';
import PromoMenu from '../PromoMenu/PromoMenu';
import { openSubMenu } from 'components/MenuHome/MenuHome';
import { menuHomeOpen } from "../Header/HeadersButtons";

import './header.css';
const common = require('../common/common');
const env = require("../../config.js").default;

class Header extends React.Component {

  componentDidMount(){
    const $ = window.$;
    $("html").attr("lang", common.get_localStorage("language"));

    $(window).on("scroll resize", (e) => {
      this.changeHeaderHeight();
    });
    $(document).ready(() => {this.changeHeaderHeight();});
  }

  changeHeaderHeight(){
    const $ = window.$;
    var deafultHeaderHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-height'));
    var checkDiskSpaceCss = $("#checkDiskSpace").is(":visible") ? " - (" + $("#checkDiskSpace").height() + "px + 30px)" : "";
    if($(window).width()<992 && $(window).scrollTop() > 50 && ($("#portal-columns").height() > $(window).height() || $("#homeMenuContainer").height() > $(window).height())){
      if($(".Content_nav_interno").hasClass("open")){
        $("#portal-top").height(deafultHeaderHeight);
        $(".banner_center_header").show();
      }else{
        $("#portal-top").height($(".header_layer").height());
        $(".banner_center_header").hide();
      }
      $(".blocco_menu_laterale .mobile-bar").css("top", "96px");
      $("#content_sidebar, .blocco_menu_laterale").css("top", $(".header_layer").height());
      $("#content_sidebar").css("height", "calc(100vh - " + $(".header_layer").height() + "px - " + checkDiskSpaceCss + ")");
      $("#portal-top-placeholder").height("100px");
      $(".blocco_menu_laterale").height("100%");
    }else{
      $(".blocco_menu_laterale .mobile-bar").css("top", parseInt(deafultHeaderHeight + 46) + "px" );
      $("#portal-top-placeholder").height(deafultHeaderHeight);
      $("#portal-top").height(deafultHeaderHeight);
      $("#content_sidebar").css("top", 'var(--header-height)');
      $("#content_sidebar").css("height", "calc(100vh - var(--header-height) " + checkDiskSpaceCss + ")");
      $(".blocco_menu_laterale").height("100%");
      $(".banner_center_header").show();
    }
  }

  render(props){
    return (
      <div className={"cont_" + this.props.headerType}>
        <div id="portal-top">
          <div className={this.props.headerType + " header_layer"} >
            <div className="" id="sw_service-navbar">
              <LeftHeaderElement activeElements={this.props.activeElements}></LeftHeaderElement>
              <CenterHeaderElement></CenterHeaderElement>
              <RightHeaderElement activeElements={this.props.activeElements}></RightHeaderElement>
            </div>
          </div>
        </div>
        <div id="portal-top-placeholder"></div>
        {
          this.props.activeElements.indexOf("MenuButton") > -1 ?
            <MenuHome menuPaginaInterna={true}></MenuHome>
          :
            <></>
        }
        {
          this.props.activeElements.indexOf("NotificationButton") > -1 ?
            <NotificationMenu></NotificationMenu>
          :
            <></>
        }
        {
          this.props.activeElements.indexOf("PromoButton") > -1 ?
            <PromoMenu></PromoMenu>
          :
            <></>
        }
      </div>
    )
  }
}

class LeftHeaderElement extends React.Component {
  render(props){
    return(
      <>
        <div className="topHeaderLeft" id="menu_fisso">
          <ul id="portal-personaltools-wrapper" className="nav nav-pills ">
            {
              (
                <>
                {this.props.activeElements.map((item, key) => {
                  var ret = "";
                  switch(item){
                    case "UserButton":
                      ret = <UserButton key={key}></UserButton>;
                    break;
                    case "LanguageButton":
                      ret =  <LanguageButton key={key}></LanguageButton>;
                    break;
                    case "SupportButton":
                      ret =  <SupportButton key={key}></SupportButton>;
                    break;
                    case "AgentiButton":
                      ret =  <AgentiButton key={key}></AgentiButton>;
                    break;
                    case "Search":
                      ret =  <Search key={key}></Search>;
                    break;
                    case "MenuButton":
                      ret =  <MenuButton key={key}></MenuButton>;
                    break;
                    case "SwitchTheme":
                      ret =  <SwitchTheme key={key}></SwitchTheme>;
                    break; 
                    case "NotificationButton":
                      ret = <NotificationButton key={key}></NotificationButton>;
                    break;
                    case "PromoButton":
                      ret = <PromoButton key={key}></PromoButton>;
                    break;

                    default:
                      ret = "";
                  }
                  return (ret);

                })}
                </>)
            }
          </ul>
        </div>
        <Support></Support>
      </>
    )
  }
}

class CenterHeaderElement extends React.Component {

  handleBottoniMenuClick(e) {
    var $ = window.$;
    if ($(e.currentTarget).parent().find(".elenco_voci").length > 0) {
      e.preventDefault();
      openSubMenu(e.currentTarget);
      return false;
    }
    menuHomeOpen(false);
  }

  render(){
    return(
      <div className="banner_center_header">
        <Link to="/">
          <img src={env.STATIC + "custom/logo_" + window.cc.csoft + ".png"} onError={(e) => { e.target.onerror = null; e.target.src = env.STATIC + "custom/logo_900000.png" }} className="brand d-print-none" alt="Logo" onClick={this.handleBottoniMenuClick} />
          <img src={env.STATIC + "custom/logo_stampa_" + window.cc.csoft + ".png"} onError={(e)=>{e.target.onerror = null; e.target.src=env.STATIC + "custom/logo_"+window.cc.csoft+".png"}} className="brand d-none d-print-block" alt="Logo"/>
        </Link>
      </div>
    )
  }
}

class RightHeaderElement extends React.Component {
  render(){
    return(
      <div className="topHeaderRight">
        <ul id="portal-seach" className="nav nav-pills navbar-right">
          {
            (
              <>
              {this.props.activeElements.map((item, key) => {
                var ret = "";
                switch(item){
                  case "UserButton":
                    ret = <UserButton key={key}></UserButton>;
                  break;
                  case "LanguageButton":
                    ret = <LanguageButton key={key}></LanguageButton>;
                  break;
                  case "SupportButton":
                    ret = <SupportButton key={key}></SupportButton>;
                  break;
                  case "AgentiButton":
                    ret =  <AgentiButton key={key}></AgentiButton>;
                  break;
                  case "Search":
                    ret = <Search key={key}></Search>;
                  break;
                  case "MenuButton":
                    ret = <MenuButton key={key}></MenuButton>;
                  break;
                  case "SwitchTheme":
                    ret = <SwitchTheme key={key}></SwitchTheme>;
                  break;
                  case "NotificationButton":
                    ret = <NotificationButton key={key}></NotificationButton>;
                  break;
                  case "PromoButton":
                    ret = <PromoButton key={key}></PromoButton>;
                  break;

                  default:
                    ret = "";
                }
                return ret;
              })}
              </>)
          }
        </ul>
      </div>
    )
  }
}


export default Header;
