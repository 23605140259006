import React from 'react';
import { Link } from "react-router-dom";
import './LoginForm.css';
import ResetPassword from './ResetPassword';
import moment from 'moment';
const common = require('../common/common');
const env = require("../../config.js").default;

class LoginForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
		this.state.loadLogin = false;
		this.state.renderResetPassword = false;
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount(){
		const $ = window.$;
		$(".toggle-password").click((e) => {
			$(".toggle-password i").toggleClass("fa-eye fa-eye-slash");

			if ($("#password").attr("type") === "password") {
				$("#password").attr("type", "text");
			} else {
				$("#password").attr("type", "password");
			}
		});

		$("#login, #password").on('change paste keyup', (e) => {
			$(e.target).val($(e.target).val().replace(/\s+/g, ''));
		});
	}

	handleSubmit (e){
		e.preventDefault();
		const $ = window.$;

		var user = $("[name=login]").val();
		var password = $("[name=password]").val();
		login(user, password);

		return false;
	}

	render(){
		return(
			<>
				<ResetPassword></ResetPassword>
				<div className="background_login pt-5 pb-5">
					<form onSubmit={this.handleSubmit}>
						<div className="form-signin">
							<h1 className="h3 mb-3 font-weight-normal text-center">{common.getTrad('Accesso al portale')} {window.cc.companyInfo ? window.cc.companyInfo.service ? window.cc.companyInfo.service : "" : ""}</h1>
							<div className="col-md-12">
								<div className="form-group">
									<label htmlFor="login">{common.getTrad("Username")}: <span className="danger">*</span></label>
									<input type="text" id="login" name="login" className="form-control mb-3" placeholder={common.getTrad("Username")} autoComplete="username" required autoFocus />
								</div>
								<div className="form-group">
									<label htmlFor="password">{common.getTrad("Password")}: <span className="danger">*</span></label>
                </div>
  				      <div className="input-group mb-3">
      						<input type="password" id="password" name="password" className="form-control" placeholder={common.getTrad("Password")} autoComplete="current-password" required aria-describedby="basic-addon2"/>
                  <div className="input-group-append">
                    <span className="input-group-text toggle-password" id="basic-addon2">
                      <i className="fas fa-eye"></i>
                    </span>
                  </div>
              	</div>
								<div className="sw-alert alert alert-danger alert-dismissible fade show animated bounceInUp" id="result" role="alert" style={{display: "none"}}></div>
								<button className="btn-block btn btn-lg bottone_default" type="submit">
									<i className="fas fa-spinner fa-spin" id="loginLoader" style={{display: "none"}}></i>&nbsp;&nbsp;
									{common.getTrad("Accedi")}
								</button>
								<p className="mt-3 mb-4 text-muted text-center">
									<Link to="/#" data-toggle="modal" data-target="#reset_password">{common.getTrad("Hai dimenticato la password?")}</Link>
								</p>
							</div>
						</div>
						{window.cc.registrationFlag ?
							<div className="form-signin mt-4">
								<div className="col-md-12">
									<h2 className="mt-2 mb-3 text-center" style={{"textShadow": "0 0 0", "fontSize": "1.1rem", }}>{common.getTrad("Servizio RMI per Operatore Indipendente")}</h2>
									<p className="mb-3 text-muted ">
										<Link className="btn btn-block bottone_default" to="sign-up-io" title={common.getTrad("Registrazione IO")}>{common.getTrad("Registrazione IO")}</Link>
										<Link className="btn btn-block bottone_default" to="sign-up-employee" title={common.getTrad("Registrazione Dipendente")}>{common.getTrad("Registrazione Dipendente IO")}</Link>
									</p>
								</div>
							</div>
							:
							<></>
						}
					</form>
				</div>
			</>
		)
	}
}


function login(user, password){
	const $ = window.$;
	$("#loginLoader").fadeIn("fast");

	$("#result").css("display", "none");
	var formData = {login: 'sw_sso_' + user, password: password};
	fetch(env.BACKEND + '@sw-login', {
		method: 'POST',
		body: JSON.stringify(formData),
		headers: new Headers({
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Accept-Language': common.getLanguage(true),
		}),
	})
 .then(function(response) {
		if (!response.ok) {
			setTimeout(() =>{$("#loginLoader").fadeOut("slow");}, 500);
			var error = Error(response.status);
			error.response = response.json()
			throw error
		}
		return response.json();
	})
 .then(function(result){
		common.set_localStorage("appState", JSON.stringify({isLoggedIn: true, user: result.token, userName: formData.login }));
		common.set_localStorage("sw_showPopup", false);
		common.getUserInfo().then(userInfo =>{
			common.setLanguage(userInfo.userInfo.CLIN1, false);

			var passToErros = true;
			if(userInfo.userInfo.WUS.USCAD <= moment().format('YYYY-MM-DD')){
				passToErros = false;
			}

			if(passToErros){
				if(userInfo.userInfo.USCPW <= (moment().format('YYYY-MM-DD')) && userInfo.userInfo.WUS.DCAPO === ''){
					window.location.href = env.FRONTEND +"my-profile?pwd=3";
				}else if(userInfo.userInfo.WUS.DCAPO === ''){
					window.location.href = env.FRONTEND +"my-profile?pwd=2";
				}else if(userInfo.userInfo.USCPW <= (moment().format('YYYY-MM-DD'))) {
					window.location.href = env.FRONTEND +"my-profile?pwd=1";
				}else{
					window.location.reload();
				}
			}else{
				window.location.reload();
			}
		})
	})
	.catch(function(error) {
		try{
			error.response.then((response) => {
				$("#result").css("display", "block");
				if(response.error !== undefined && response.error !== null && response.error !== ''){
					$("#result").html(response.error.errorSubCodeText);
					return response;
				}else {
					$("#result").html(common.getTrad("Errore, riprovare più tardi"));
				}
			})
		}catch{
			$("#result").css("display", "block");
			setTimeout(() =>{$("#loginLoader").fadeOut("slow");}, 500);
			$("#result").html(common.getTrad("Errore, riprovare più tardi"));
		}
	});
}

export default LoginForm;
