import React from 'react';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';


import './notification.css';

const common = require('../common/common');
const env = require("../../config.js").default;


class NotificationMenu extends React.Component {
    constructor(props){
        super(props);
        this.state={};
        
        this.state.filter = {};
        this.state.filter.non_lette = (common.get_localStorage("noty_only_unreaded", common.localStorage_padding(), 'true') == 'true');
        
        this.state.notifications = [];
        this.state.nPageAll = 0;
        this.state.nPageUnreaded = 0;
        this.prevScrollTop = 0;
        this.notificationMenuRef = React.createRef();
        this.get_notifiche();

    }
    
    get_notifiche(changeUnreadedFilter=false){
        const $ = window.$
        const length = 25
        let npage = this.state.filter.non_lette ? this.state.nPageUnreaded : this.state.nPageAll
        let data = {
            only_unreaded: this.state.filter.non_lette ? 1 : 0, 
            length: length,
            start: length * npage
            
        }
        common.apiCall(env.SWAN + "get_user_notification/", "GET", data, false, false, "json", true).then((response) => {
            if(response.error === undefined || response.error === null || response.error === false){                     
                if (response.something_new){
                    if (!$(".sw-notificationButton").hasClass("something_new")){
                        $(".sw-notificationButton").addClass("something_new");
                        this.forceOpening();
                    }
                } else {
                    $(".sw-notificationButton").removeClass("something_new");
                }
                //Se non ci sono risultati viene tolto l'evento onScroll
                if(response.data.length < 25 )
                    this.removeScrollEvent()
                    
                this.state.filter.non_lette ? this.setState({ nPageUnreaded: this.state.nPageUnreaded + 1 }) : this.setState({ nPageAll: this.state.nPageAll + 1 }) //In base al flag incremento il contatore
                if (changeUnreadedFilter) {
                    $(".singleNotification").remove()
                    this.setState({ notifications: [...[], ...response.data]}) //another array 
                } else{
                    this.setState({ notifications: [...this.state.notifications, ...response.data]}) //another array 
                }
            }
        });
    }
    
    forceOpening() {
        const $ = window.$;
        let forced = common.get_localStorage("notificationForceOpening");
        if (forced !== '1') {
            common.set_localStorage("notificationForceOpening", "1");
            $(".sw-notificationButton:visible").click();
        }
    }

    handleChangeUnreadedFilter = (e) => {
        const $ = window.$;
        this.setState({
            filter: { non_lette: $(e.target).is(":checked") },
        }, () => {
            this.prevScrollTop = 0;
            this.state.nPageAll = 0;
            this.state.nPageUnreaded = 0;
            common.set_localStorage("noty_only_unreaded", this.state.filter.non_lette);
            this.addScrollEvent()
            this.get_notifiche(true);
        });
    }

    handlerReadNoty = (id, readed) =>{
        let notifications = this.state.notifications;
        let pos = common.object_value_in_array(notifications, 'ID', id);
        if (pos > -1){
            notifications[pos]["FREAD"] = (readed ? "S" : "");
            this.setState({ notifications: notifications });
        }

    }

    removeScrollEvent = () => {
        this.notificationMenuRef.current?.removeEventListener('scroll', this.handleScroll);
        window.$(".loadingDivNotifiche").html("")
    }
    addScrollEvent = () => {
        this.notificationMenuRef.current?.addEventListener('scroll', this.handleScroll);
        window.$(".loadingDivNotifiche").html('<div><i class="fas fa-spinner fa-spin fa-2x"></i><div className="w-50"></div></div>')
    }

    handleScroll = (e) => {
        const $ = window.$;
        const container = e.target;
        const scrollPosition = container.scrollHeight - container.clientHeight;
        const currentScroll = container.scrollTop;
        const scrollPercentage = (currentScroll / scrollPosition) * 100;
        const scrollBottom = container.scrollHeight - currentScroll - container.clientHeight;

        if (scrollBottom <= 0 && currentScroll > this.prevScrollTop && scrollPercentage >= 80 ) {
            // Chiamiamo la funzione desiderata quando la scrollbar arriva all'80% del contenuto
            $(".loadingDivNotifiche").fadeIn("slow");
            this.get_notifiche()
            $(".loadingDivNotifiche").fadeOut()
            this.prevScrollTop = currentScroll;
        }
    }
    
    

    render(){
        return(
            <div id="notificationMenu" onScroll={this.handleScroll} ref={this.notificationMenuRef}>
                <div className="notificationHeader">
                    <h4 className="notificationMenuTitle">{common.getTrad("Notifiche")}</h4>
                    <div className="notificationFilter">
                        <span>{common.getTrad("Mostra solo non lette")}:</span>
                        <label className="switch float-right">
                            <input name="notificationFilterOnlyUnreaded" autoComplete="off" id="notificationFilterOnlyUnreaded" value="1" type="checkbox" checked={this.state.filter.non_lette} onChange={this.handleChangeUnreadedFilter}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <div className="notificationBody">
                {
                    (() => {
                        if(this.state.notifications.length > 0){
                            return this.state.notifications.map((value, index) => {
                                return <SingleNotification key={index} only_unreaded={this.state.filter.non_lette} notyData={value} readAction={this.handlerReadNoty}/>;
                            })
                        } else {
                            return (
                                <div className="notificationBodyEmptyMessage animated faster fadeIn">
                                    {common.getTrad("Nessuna notifica presente")}
                                </div>
                            )
                        }
                    })()
                }
                </div>
                <div className="notificationFooter">
                    <div className="loadingDivNotifiche" style={{ display: "none" }}>
                        <div>
                            <i className="fas fa-spinner fa-spin fa-2x"></i>
                            <div className="w-50"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class SingleNotification extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
        this.state.notyData = this.props.notyData;
        this.state.only_unreaded = this.props.only_unreaded;
        this.state.unreaded = (this.props.notyData.FREAD !== "S" ? true : false);
        this.state.bodyCollapsed = true;
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({ notyData: nextProps.notyData, unreaded: (nextProps.notyData.FREAD !== "S" ? true : false), only_unreaded: nextProps.only_unreaded });
        }
    }

    messageClean(){
        let mex = this.props.notyData.DMESS;
        
        if(mex === undefined || mex === null)
            return ""
        
        mex = mex.replace("\n\r", "\n");    
        mex = mex.replace("\r\n", "\n");    
        mex = mex.replace("\r", "\n");    
        mex = mex.split("\n").map(function(item, index){
            return(
                <>
                    {item}
                    <br/>
                </>
            )
        }); 
        return mex;   
    }
    
    handleReadClick = (e)=>{
        e.preventDefault();
        var newUnreadedState = !this.state.unreaded;
        let data = {
            id: this.props.notyData.ID,
            readed: !newUnreadedState,
        }
        
        common.apiCall(env.SWAN + "set_user_notification_read/", "POST", data, false, false, "json", true).then((response) => {
            if (response.error === undefined || response.error === null || response.error === false) {
                this.props.readAction(this.props.notyData.ID, !newUnreadedState);
            }
        });

    }

    handleShowMoreClick = (e) => {
        e.preventDefault();
        this.setState({bodyCollapsed: !this.state.bodyCollapsed});
    }

    didUpdateAndMoutCommonAction = () =>{

    }
    
    componentDidMount = () => {
        this.renderMostraAltro();

        this.didUpdateAndMoutCommonAction();
    }
    
    componentDidUpdate = ()=>{
        const $ = window.$;
        if($(".sigleNotification.unreaded").length > 0){
            if (!$(".sw-notificationButton").hasClass("something_new"))
                $(".sw-notificationButton").addClass("something_new");
        } else {
            $(".sw-notificationButton").removeClass("something_new");
        }
        this.didUpdateAndMoutCommonAction();
    }

    renderMostraAltro = () =>{
        const $ = window.$;
        
        let bodyElement = $("#notyBody_" + this.state.notyData.ID);
        let bodyTextElement = $(bodyElement).find(".notyBodyText");
        if (bodyTextElement.length > 0 && bodyElement.hasClass("collapsed")){
            let bodyTextHeight = $(bodyTextElement)[0].scrollHeight;
            let bodyFixedTextHeight = $(bodyTextElement).height();
            
            if (bodyTextHeight <= bodyFixedTextHeight){
                $(bodyElement).find(".notyShowMore").hide();
            }
                
        }
    }

    render() {
        if (this.state.notyData && ((this.state.only_unreaded && this.state.unreaded) || !this.state.only_unreaded)){
            return(
                <div key={this.key} className={"sigleNotification animated faster fadeIn " + (this.state.unreaded ? "unreaded" : "")} id={"noty_" + this.state.notyData.ID}>
                    <h6 className="notyTitle">
                        {this.state.notyData.DNOTY}
                        <span>{this.state.notyData.NDRIF_FORMATTED}</span>
                    </h6>
                    <div className={"notyBody " + (this.state.bodyCollapsed ? "collapsed" : "")} id={"notyBody_" + this.state.notyData.ID}>
                        <div className="notyBodyText">
                            {this.messageClean()}
                        </div>
                        <div className="notyShowMore">
                            <span className="notyShowMoreText">
                                <a href="#" onClick={this.handleShowMoreClick}>
                                    {common.getTrad("Mostra altro")}
                                </a>
                            </span>
                            <span className="notyUnshowMoreText">
                                <a href="#" onClick={this.handleShowMoreClick}>
                                    {common.getTrad("Mostra meno")}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="notyInfo">
                        <small className="notyDate">{moment(this.state.notyData.UINSR).format('LLL')}</small>
                        <span className="notyFooterAction">
                            {this.state.notyData.TNOTY !== 'ACT' ? 
                                <span className="notyReadAction" onClick={this.handleReadClick} title={this.state.unreaded ? common.getTrad("Segna come letta") : common.getTrad("Segna come da leggere")}>
                                    <i className="fas fa-check"></i>    
                                </span>
                            :
                                <></>
                            }
                        </span>
                    </div>
                </div>
            )
        } else {
            return(<></>)
        }
    }
}

export default NotificationMenu;
