import React from 'react';
const common = require('../common/common');
const env = require("../../config.js").default;

class FilterModels extends React.Component{
	constructor(props){
		super(props);
		this.state = {};
		this.state.modelli = [];
		this.state.marche_attive = common.getAppEnabledVM();
	}

	UNSAFE_componentWillMount(){
	 	common.getModelli().then((modelli) => {
			if(modelli){
				if(this.state.marche_attive !== undefined && this.state.marche_attive.length > 1){
					var gruppo = [];
					var modelli_gruppo = [];
					for (var i=0; i< modelli.length; i++){
					  var index = gruppo.findIndex(x => x.id_group === modelli[i]['group']);
					  if(index === -1 && modelli[i] !== 'error'){
							var num = common.object_value_in_array(window.cc.marche_portale, "ZFORN", modelli[i]['group'])
					    gruppo.push({'id_group': modelli[i]['group'], 'text': window.cc.marche_portale[num]['DFORN'], 'children': []})
						}

					  var indexModello = modelli_gruppo.findIndex(x => x.value === modelli[i]['id']);
					  if(indexModello === -1 && modelli[i] !== 'error'){
							var valoreModello = modelli[i]['modello'].substring(modelli[i]['modello'].length - (
								window.cc.marcaPortale ?
									window.cc.marcaPortale.length +1
								:
								3), modelli[i]['modello'].length);
					    modelli_gruppo.push({'id': modelli[i]['id'], 'text': modelli[i]['modello'].substring(valoreModello.length).trim().toUpperCase(), 'group': modelli[i]['group']});
						}
					}

					for (var g = 0; g < gruppo.length; g++) {
					  for (var m = 0; m < modelli_gruppo.length; m++) {
					    if(gruppo[g]['id_group'] === modelli_gruppo[m]['group'])
					      gruppo[g]['children'].push(modelli_gruppo[m])
					  }
					}
					this.setState({modelli: gruppo});
				}else{
					this.setState({modelli: modelli});
				}
 			}
		});
  }

  componentDidMount(){
		const $ = window.$;

		$('#ricerca_filtro_telaio #btn_cercaT').click((e) => {
			e.preventDefault();
			var numeroTelaio = $('#ricerca_filtro_telaio input#EDTNTELA').val();
			if(numeroTelaio !== '' && numeroTelaio !== undefined && numeroTelaio !== null){
				window.numeroTelaio = numeroTelaio;
			}else{
				window.numeroTelaio = "";
 			}
			if (window.numeroTelaio){
        let marca = this.props.marcaDefault !== '' ? this.props.marcaDefault : window.cc.marcaPortale;
        let url = env.SWAN + "epc/ajax_filtro_telaio_service?telaio=" + window.numeroTelaio + "&id_marca=" + marca + "&csoft=" + window.cc.csoft;
				common.apiCall(url, "GET", {}, false)
				.then(response=>{
          $("#resetta_ricerca").show();
  				$("#btn_cercaT").hide()
					.removeClass("loading")
					.attr("disabled", false);
          window.valoreModello = "";
          let error_404 = response.errorCode;
					if(!error_404){
						$("#erroreModello").css("display", "none");

            if(response.modello !== undefined && response.modello !== null)
				      window.valoreModello = response.modello;
            else if(response.CMOTO !== undefined && response.CMOTO !== null)
              window.valoreModello = response.CMOTO;
            else if(response[0] !== undefined && response[0].CMOTO !== undefined && response[0].CMOTO !== null)
              window.valoreModello = response[0].CMOTO;

						error_404 = (window.valoreModello === '' || window.valoreModello === null );

						if(!window.valoreModello.startsWith(marca + "-"))
              window.valoreModello = marca + "-" + window.valoreModello;

						$("select#select_model option").removeAttr("selected");

						$("select#select_model option[value='"+ window.valoreModello + "']").attr("selected","selected");
						$("select#select_model").val(window.valoreModello).trigger("change");
					}

          if(error_404){
						$("#erroreModello").css("display", "block");
						if(response.message !== undefined && response.message !== null)
				      $('#erroreModello').html(response.message);
            else
              $('#erroreModello').html(common.getTrad("Nessun modello trovato"));
          }
					$('.select_modelli').keyup();
				});
			}else{
				window.valoreModello = "";
			}
		});

		function matchCustom(params, data) {
      if ($.trim(params.term) === '') {
        return data;
      }
      var keywords=(params.term).split(/[.+-/_ ]/);
      for (var i = 0; i < keywords.length; i++) {
        if (((data.text).toUpperCase()).indexOf((keywords[i]).toUpperCase()) === -1)
        return null;
      }
      return data;
		}

		$('.select_modelli').select2({
		 	matcher: matchCustom,
			language: common.getLanguage(),
			placeholder: this.state.marche_attive.length <= 1 ? common.getTrad("Seleziona un modello") : common.getTrad("Seleziona prima una marca"),
			allowClear: true,
			sorter: function(data) {
        /* Sort data using lowercase comparison */
        return data.sort(function (a, b) {
            a = a.text.toLowerCase();
            b = b.text.toLowerCase();
            if (a > b) {
                return 1;
            } else if (a < b) {
                return -1;
            }
            return 0;
        });
    	},
		});

	  $(window).resize(function() {
	  	$('.select2').css('width', "100%");
	  });
		$('.select2').css('width', "100%");

		$('.select_modelli').on('select2:select select2:unselect', (e) => {
			e.preventDefault();
			$(".select_modelli").val(e.target.value).trigger("change");
			if(e.target.value !== ''){
				$("input#EDTNTELA").val("").removeAttr('readonly');
				$("#btn_cercaT").show();
				$("#resetta_ricerca").hide();
			}
		});

		$('.select_modelli').on('select2:clearing', (e) => {
			e.preventDefault();
			
			//imposto valore vuoto
			$(".select_modelli").val('').trigger("change");
			//nascondo il dropdown
			$(".select2-container .select2-dropdown").css("display","none")
			//eseguo le stesse operazioni di quando seleziono un modello
			$('.select_modelli').trigger('select2:select')
		})
		

		$('.select_modelli').on('select2:select keyup select2:unselect', (e) => {
			e.preventDefault();
			var modello = '';
			if(e.target.value === ''){
				modello = "";
			}else{
				modello = e.target.value;
			}

			var parametri = {
				filtro_modello: modello
			}
			common.set_localStorage("valoreModello", modello);
			this.props.onClick("filtro_modello", parametri );
			return false;
		});
	}

	componentDidUpdate(){
		const $ = window.$;

		function matchCustom(params, data) {
			if ($.trim(params.term) === '') {
				return data;
			}
			var keywords=(params.term).split(/[.+-/_ ]/);
			for (var i = 0; i < keywords.length; i++) {
				if (((data.text).toUpperCase()).indexOf((keywords[i]).toUpperCase()) === -1)
				return null;
			}
			return data;
		}
		// Ricreo la select modelli solamente con i modelli filtrati per marca
		var marcaDefault = '';
		if(common.get_localStorage("valoreMarca") !== undefined)
			marcaDefault = common.get_localStorage("valoreMarca");
		else
			marcaDefault = this.props.marcaDefault;

		if(this.state.modelli !== undefined && this.state.modelli.length > 0 && marcaDefault !== undefined && marcaDefault !== null && marcaDefault !== ''){
			if(marcaDefault !== 'tutti_modelli'){
				var modelliInObject = this.state.modelli[common.object_value_in_array(this.state.modelli, 'id_group',marcaDefault)];
				if(modelliInObject === undefined)
					$('.select_modelli').empty().append("<option></option>");
				else{
					$('.select_modelli').empty().append("<option></option>").select2({
						data: modelliInObject.children,
						matcher: matchCustom,
						language: common.getLanguage(),
						placeholder: common.getTrad("Seleziona un modello"),
						allowClear: true,
						sorter: function(data) {
							/* Sort data using lowercase comparison */
							return data.sort(function (a, b) {
								a = a.text.toLowerCase();
								b = b.text.toLowerCase();
								if (a > b) {
									return 1;
								} else if (a < b) {
									return -1;
								}
								return 0;
							});
						},
					});
				}
			}else{
				$('.select_modelli').empty().select2({placeholder: common.getTrad("Seleziona prima una marca")});
			}
		}

		if(common.get_localStorage("valoreModello") !== undefined && common.get_localStorage("valoreModello") !== null && common.get_localStorage("valoreModello") !== ''){
			$("select#select_model").val(common.get_localStorage("valoreModello")).trigger("change");
		}
	}

	render(props){
		var marcaDefault = '';
		if(common.get_localStorage("valoreMarca") !== undefined)
			marcaDefault = common.get_localStorage("valoreMarca");
		else
			marcaDefault = this.props.marcaDefault;

		var is_disabled = false;
		if(this.props.marche_attive !== undefined && this.props.marche_attive.length > 1){
			if(marcaDefault === '' || marcaDefault === 'tutti_modelli')
				is_disabled = true;
		}

		return(
      <div className={(this.props.customClass !== undefined && this.props.customClass !== '' ? this.props.customClass : "col-xl-6 col-lg-6") +  " col-md-12 col-sm-12 col-xs-12 row-eq-height"} role="alert" id="ricerca_filtro_modello">
      	<div className="col-12 p-0">
          <p>{common.getTrad("Ricerca Modello")}</p>
					{this.state.title}
          <div className="form-group">
            <select disabled={is_disabled} name="select_model" className="form-control select_modelli" id="select_model">
							<option></option>
							{this.state.modelli && this.state.marche_attive !== undefined && this.state.marche_attive.length <= 1 ?
								this.state.modelli.map((index, key) => {
									return index.disabled === false ?
										<option key={key} value={index.id}>
											{index.descrizione.trim().toUpperCase()}
										</option>
								 	:
									 	""
								})
							:
								<></>
							}
            </select>
          </div>
					<div style={{display: 'none'}} id="erroreModello" className="sw-alert alert alert-warning" role="alert"></div>
        </div>
      </div>
     )
  }
}

export default FilterModels;
