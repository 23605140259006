import React from 'react';
import { Link } from "react-router-dom";
import './Support.css';
const common = require('../common/common');

export class Support extends React.Component {
  constructor(props){
    super(props)
    this.state = {};
    this.state.userInfo = {};
  }
  UNSAFE_componentWillMount(){
    common.setStateUserInfo(this);
  }
  handleClick(){
    var $ = window.$;
    if($("#support").hasClass("open"))
      $("#support").removeClass("open");
    else
      $("#support").addClass("open");
  }

  render(props){
    var supportInfoData = window.cc.supportInfo !== undefined ? window.cc.supportInfo : {};
    var supportInfo = '', supportInfo2;
    for (var i = 0; i < Object.keys(supportInfoData).length; i++) {
      if(supportInfoData.hasOwnProperty(common.getLanguage())){
        supportInfo = (
          <a href={"mailto:" + supportInfoData[common.getLanguage()].email + "?subject=" + ( Object.keys(this.state.userInfo).length > 0 ? "[" + common.getTrad("Portale") + " " + this.state.userInfo.userInfo.CSOFT + "]" + " [User:" + this.state.userInfo.userInfo.CUSER + "] " : "[" + common.getTrad("Portale") + " " + (window.cc.companyInfo !== undefined && window.cc.companyInfo.service !==undefined ? window.cc.companyInfo.service : "") + "] ") + common.getTrad("Richiesta supporto tecnico") }><i className="fas fa-envelope"></i> {supportInfoData[common.getLanguage()].email}</a>
        );
        if(supportInfoData[common.getLanguage()].phone_href !== undefined && supportInfoData[common.getLanguage()].phone_href !== '')
          supportInfo2 = <a href={"tel:" + supportInfoData[common.getLanguage()].phone_href}><i className="fas fa-phone"></i>{supportInfoData[common.getLanguage()].phone_show}</a>;

      }else{
        supportInfo = (
          <a href={"mailto:" + supportInfoData['en'].email + "?subject=" + ( Object.keys(this.state.userInfo).length > 0 ? "[" + common.getTrad("Portale") + " " + this.state.userInfo.userInfo.CSOFT + "]" + " [User:" + this.state.userInfo.userInfo.CUSER + "] " : "[" + common.getTrad("Portale") + " " + (window.cc.companyInfo !== undefined && window.cc.companyInfo.service !==undefined ? window.cc.companyInfo.service : "") + "] ") + common.getTrad("Richiesta supporto tecnico") }><i className="fas fa-envelope"></i> {supportInfoData['en'].email}</a>
        );

        if(supportInfoData['en'].phone_href !== undefined && supportInfoData['en'].phone_href !== '')
          supportInfo2 = <a href={"tel:" + supportInfoData['en'].phone_href}><i className="fas fa-phone"></i>{supportInfoData['en'].phone_show}</a>;
      }
    }

    return(
      <div id="support">
        <h1 id="supportTitle" onClick={() => {this.handleClick()}} className="btn btn-warning">{common.getTrad("Support")}</h1>
        <div id="supportTitlePlaceHolder">&nbsp;</div>
        <div id="supportBody">
          <h5>{common.getTrad("Supporto tecnico")}</h5>
          <Link to="/manuali"><i className="fas fa-book"></i> {common.getTrad("Manuale d'uso RMI")}</Link>
          {supportInfo}
          {supportInfo2}
        </div>
      </div>
    )
  }
}

export default Support;
