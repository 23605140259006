import React from 'react';
import { Route } from "react-router-dom";

import LoaderView from './loader';

const common = require('../common/common');
const env = require("../../config.js").default;

class Logout extends React.Component {
  componentDidMount(){
    doLogout()
  }
  render(){
    return (
      <LoaderView headerType={this.props.myHeaderType} />
    )
  }
}

export function doLogout(){
  common.remove_localStorage("appState");
  common.remove_localStorage("userInfo");
  common.remove_localStorage("filtriTickets");
  common.remove_localStorage("filtriTicketsTipologie");
  common.remove_localStorage("valoreModello");
  common.remove_localStorage("tipologieTicket");
  common.remove_localStorage("sidebarOpen");
  common.remove_localStorage("notificationForceOpening");
  common.remove_localStorage("promoForceOpening");
  common.remove_localStorage("valoreMarca");
  common.remove_localStorage("filtroCerca");
  common.clearCache();

  common.apiCall(env.SWAN + "sso_logout/", "GET", {"next": "noRedirect"}, false, false, "json")
  .then((response) => {
    window.location.href=env.FRONTEND;
  });

}

export default Logout;
