import React from 'react';
import { Link } from "react-router-dom";
const common = require('../../common/common');
const env = require("../../../config.js").default;

class E404 extends React.Component {
  componentDidMount(){
    document.title = document.title = window.cc.companyInfo.service + " Service";
  }
  UNSAFE_componentWillMount(){
    document.getElementById('body').className='content_page';
  }
  render(props){
    return (
        <div className="content_testo tab-content">
					<div id="content-core">
						<div className="row" style={{alignItems: "center", height: "calc(100vh - var(--header-height) - var(--footer-height))"}}>
							<div className="col-xl-12 text-center content_error">
								<h1 className="animated fadeInUp delay-03s">
                  {common.getTrad("Errore")} <span>404</span>
                </h1>
								<h3 className="animated fadeInUp delay-06s">
                  {common.getTrad("Il contenuto che hai cercato non esiste...")}
                </h3>
								<br/>
								<h3 className="animated fadeInUp delay-09s">
									<Link to="/">
                    <i className="fas fa-arrow-left"></i>
                    <b>{common.getTrad("Torna alla Home Page")}</b>
                  </Link>
									&nbsp;{common.getTrad("o prova ad effettuare un'altra ricerca.")}
                </h3>
 							</div>
						</div>
					</div>
        </div>
    )
  }
}

export default E404;
