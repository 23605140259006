import React from 'react';

const common = require('../common/common');
const env = require("../../config.js").default;

class valoreAdminTicket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.state.userInfo = {};
        this.state.listAdminTicket = {};
    }

    componentDidMount() {
        const $ = window.$;
        $(window).resize(function () {
            $('.select2').css('width', "100%");
        });

        $(".select_user_ticket").select2({
            placeholder: common.getTrad("Seleziona un utente"),
            language: common.getLanguage(),
            allowClear: true,
            width: '100%',
            dropdownAutoWidth: true,
            tokenSeparators: [',', '', ' ']
        });

        $('#select_user_ticket').on('select2:clear', (e) => {
            e.preventDefault();
            $("select#select_user_ticket")[0].selectedIndex = -1;
            $("select#select_user_ticket").trigger('change');
            common.set_localStorage("valoreAdminTicket", JSON.stringify($('#select_user_ticket').val()));
            return false;
        });
    }

    componentDidUpdate() {
        const $ = window.$;
        if (common.get_localStorage("valoreAdminTicket") !== undefined && common.get_localStorage("valoreAdminTicket") !== null && common.get_localStorage("valoreNazione") !== '')
            $("select#select_user_ticket").val(JSON.parse(common.get_localStorage("valoreAdminTicket"))).trigger("change");
    }

    UNSAFE_componentWillMount(props) {
        common.getUserInfo().then(userInfo => {
            this.setState({ userInfo: userInfo });
        })
    }

    render(props) {
        return (
            <div className={(this.props.customClass !== undefined ? this.props.customClass : "col-xl-6 col-lg-6") + " col-md-12 col-sm-12 col-xs-12 row-eq-height"} role="alert" id="ricerca_filtro_modello">
                <div className="col-12 p-0">
                    <p>{common.getTrad("Filtra per admin")}</p>
                    <div className="form-group selectSwCustom">
                        <select name="select_user_ticket" className="form-control select_user_ticket" id="select_user_ticket" multiple="multiple">
                            {this.state.userInfo.list_admin_ticket !== undefined ?
                                this.state.userInfo.list_admin_ticket.map((index, key) => {
                                    return (
                                        <option key={key} value={index.CSOFT + '-' + index.ZUSER}>
                                            {index.RUSER}
                                        </option>
                                    )
                                })
                            :
                                <></>
                            }
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

export default valoreAdminTicket;
