import React from 'react';
import { Link, Navigate } from "react-router-dom";
import moment from 'moment';
import jQuery from 'jquery';
import bootbox from 'bootbox';
import { createBrowserHistory } from "history";
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import '../../css/base.css';

import PropTypes from 'prop-types';
import Sidebar from '../Sidebar/Sidebar';
import GestioneContenutiSidebar from "../Sidebar/GestioneContenutiSidebar";
import { WorkflowButton, ACTIONS_STATE_DECODE } from "../Sidebar/GestioneContenutiSidebar";
import DefaultTable from '../Tabelle/DefaultTable';
import ContactTable from '../Tabelle/ContactTable';
import TicketTable from '../Tabelle/TicketTable';
import TicketVehicleTable from '../Tabelle/TicketVehicleTable';
import MainteinanceTable from '../Tabelle/MainteinanceTable';
import PaperlessTable from '../Tabelle/PaperlessTable';
import FilterVM from '../FilterVM/FilterVM';
import FilterModels from '../FilterModels/FilterModels';
import FilterSpareParts from '../FilterSpareParts/FilterSpareParts';
import FilterChassis from '../FilterChassis/FilterChassis';
import FilterTypesTickets from '../FilterTypesTickets/FilterTypesTickets';
import FilterGroup from '../FilterGroup/FilterGroup';
import TicketState from '../TicketState/TicketState';
import FilterNations from '../FilterNations/FilterNations';
import FilterUserTicket from '../FilterUserTicket/FilterUserTicket';
import PageContact from '../Page/PageContact';
import PageTicket from '../Page/PageTicket';
import PageVehicleTicket from '../Page/PageVehicleTicket';
import PageDefault from '../Page/PageDefault';
import {doLogout} from '../views/logout';
import '../../css/page-body.css';
import '../../css/print.css';


const common = require('../common/common');
const multibrand = require('../common/multibrand');
const env = require("../../config.js").default;
window.jQuery = jQuery;

try {
  require ('select2/dist/js/i18n/'+ common.getLanguage(false) +'.js');
} catch (err) {
  require ('select2/dist/js/i18n/en.js');
}


class Page extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
		this.state.render = false;
		this.state.render1 = false;
		this.state.parentPageContent = {};
		this.state.parentPageContent.parent = {};
		this.state.parentPageContent.parent["@id"] = "";
		this.state.pageContent = this.props.pageContent;
		this.state.pathname = this.props.pathname;
    this.state.marche_attive = common.getAppEnabledVM();
	}

	UNSAFE_componentWillMount(){
		document.getElementById('body').className='content_page';

		if (!this.state.pageContent.is_folderish){
      var data = { "path.depth": 1, "expand": "translations,workflow,swowner"};
			common.apiCall(common.getBackendUrl(this.state.pageContent.parent["@id"]), "GET", data, true, true)
			.then(parentPageContent=>{
				if(parentPageContent.error === false){
					this.setState({parentPageContent: parentPageContent, render: true});
					window.translations.items = this.state.pageContent['@components'].translations;
					window.translations.parent = parentPageContent['@components'].translations;
				}
			});
		}else{
			this.setState({render: true});
			window.translationsParent = JSON.stringify(this.state.pageContent['@components'].translations);
		}

    var translations = (this.state.pageContent.is_folderish ?
      this.state.pathname.substring(3)
    :
      this.state.pathname.substr(0, this.state.pathname.lastIndexOf("/")).substring(3)
    );

    common.apiCall(common.getBackendUrl(env.BACKEND + common.getLanguage() + translations), "GET", {}, true, true)
    .then(translationsContent=>{
      if(translationsContent.error === false){
        this.setState({pageTitle: translationsContent.title});
      }
    });
		common.setStateUserInfo(this);
	}

  componentDidMount(){
    document.title = document.title = window.cc.companyInfo.service + " Service";
    this._isMounted = true;
    var description = this.state.pageContent.is_folderish ? this.state.pageContent.description : this.state.pageContent.parent.description;
    try{
      description = this.state.pageContent.is_folderish ? JSON.parse(this.state.pageContent.description) : JSON.parse(this.state.pageContent.parent.description);
    }catch(error){}

    this._isMounted && this.setState({tipoContento: description.contentType});
    var contentType = description.contentType;

    if(this.props.roles && this.props.roles.indexOf("Reviewer") > -1 && contentType === "SWContent"){
      var data = {"expand.navigation.depth": 3};
      var url = env.BACKEND + common.getLanguage() + '/@navigation';
      common.apiCall(url, "GET", data, true, true)
      .then(menuAreaButtons=>{
        if(menuAreaButtons.error === false){
          this._isMounted && this.setState({menuAreaButtons, render1: true});
        }
      });
    }
  }

  goToCMS(target){
    window.location.href = common.getFrontendUrl(target);
  }

	render(props){
			return (
					<div id="portal-columns" className="clearfix">
						<div className="row">
							{ this.state.render ?
                window.cc.gestioneContenutiFlag && this.state.userInfo !== undefined && this.state.userInfo.userInfo.groups.indexOf("SW Service Gestione Contenuti") > -1  && this.state.tipoContento === "SWContent" ?
                  <GestioneContenutiSidebar
                    pathname={(this.state.pageContent.is_folderish ? common.getUrlPath(this.state.pathname.substring(1)) : common.getUrlPath(this.state.pageContent.parent["@id"]))}
                    menuAreaButtons={this.state.menuAreaButtons}
                    render1={this.state.render1}
                    onClick={(target) => this.goToCMS(target)}
                  ></GestioneContenutiSidebar>
                :
									<Sidebar
										pathname={this.state.pageContent.is_folderish ? this.state.pathname : common.getUrlPath(this.state.pageContent.parent["@id"])}
										parentPathname={this.state.pageContent.is_folderish ?
																			(common.getUrlPath(this.state.pageContent.parent['@id']).split('/').length === 4 ? this.state.pageContent.parent['@id'] : this.state.pageContent['@id'])
																		:
																			(common.getUrlPath(this.state.parentPageContent.parent['@id']).split('/').length === 4 ? this.state.parentPageContent.parent['@id'] : this.state.parentPageContent['@id'])
									}></Sidebar>
								:
									<></>
							}
							<div id="notifica_messaggio"></div>
							<div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8 linea_sfumata" id="content_page">
								<div className="row">
									<div className="content_titolo">
										<div className="content_titolo_int">
											<span className="titolo_sezione">
							          {this.state.pageTitle}
											</span>
										</div>

									</div>
									{
										this.state.pageContent.is_folderish ?
											<PageMultipleContent marche_attive={this.state.marche_attive} pageTitle={this.state.pageTitle} pathname={this.state.pathname} pageContent={this.state.pageContent}></PageMultipleContent>
										:
										<>
											<PageSingleContent marche_attive={this.state.marche_attive} filtriTickets={this.state.filtriTickets} pathname={this.state.pathname} pageContent={this.state.pageContent} parentPageContent={this.state.parentPageContent}></PageSingleContent>
										</>
									}
								</div>
							</div>
						</div>
					</div>
			)
	}
}

class PageMultipleContent extends React.Component {

	constructor(props){
		super(props);
		this.state = {};
		this.state.pathname = "";
		this.state.userInfo = {};
		this.state.userInfo.roles = [];
    this.state.userInfo.userInfo = {};
		this.state.pageContent = {};
		this.state.pageContent.items = [];
		this.state.pageContent.description = {};
		this.state.pageContent.description.filters = {};
		this.state.marche_attive = this.props.marche_attive;
		this.state.tipologie_ticket_attive = common.getTicketTypesByBrand();

		this.state.pagina = {
			pagina_attuale:  1,
			tipo_ordinamento: 'modified', //Capire tipo ordinamento iniziale
			verso_ordinamento: -1, // Idem come sopra
      testo_cercato: '',
      filtro_marca: common.get_localStorage("valoreMarca") === undefined || common.get_localStorage("valoreMarca") === null || common.get_localStorage("valoreMarca") === '' ? '' : common.get_localStorage("valoreMarca"),
			filtro_modello: common.get_localStorage("valoreModello") === undefined || common.get_localStorage("valoreModello") === null || common.get_localStorage("valoreModello") === '' ? '' : common.get_localStorage("valoreModello"),
			stati_ticket: [],
			tipologie_ticket: [],
      last_admin_editor: [],
      swcreators: [],
			filtra_risultati: true,
      filtro_nazione: common.get_localStorage("valoreNazione") === undefined || common.get_localStorage("valoreNazione") === null || common.get_localStorage("valoreNazione") === '' ? '' : common.get_localStorage("valoreNazione"),
      batchingAdvanced: true,
      
		};
	}

  mostraNazioneTicket(){
    /*
     * Condizioni per mostrare filtro e colonna nazione sui ticket
     * Buttata qui, trovare un posto migliore se è da utilizzare in altri punti
     * Per ora solo admin
     */
    return (this.state.userInfo.is_admin_ticket !== undefined && this.state.userInfo.is_admin_ticket);
  }

	UNSAFE_componentWillMount(props){
		var pageContent = this.props.pageContent;
		try{
			pageContent.description = JSON.parse(this.props.pageContent.description);
		}catch(e){}

		this.setState({pageContent :  pageContent});
		//this.setState({pageContent :  this.props.pageContent});
		this.setState({pathname : this.props.pathname});
		common.setStateUserInfo(this);
    
	}
  
	onClickPaging(tipofunzione, parametri, force_reload=true){
		var paging = this.generaUrl(tipofunzione, parametri, force_reload);
		if(paging !== false && force_reload)
			this.pagingGetData(paging);
    this.handleFilterAlert()
	}

	pagingGetData(paging){
		const $ = window.$;
		$("body #app .loadingDiv").show();
		if(paging.search("\\?") > -1)
			paging = paging + "&";
		else
			paging = paging + "?";
		if(this.state.pageContent.description.tableType.indexOf('ticket') <= -1){
			paging = paging + "portal_type=swcontent&portal_type=News%20Item";
		}else{
			paging = paging + "portal_type=sw" + this.state.pageContent.description.tableType +"&expand=workflow";
		}

		common.apiCall(common.getBackendUrl(paging), "GET", {}, true, true)
		.then(pagingContent => {
			if(pagingContent.error === false){
				var pageContent = this.state.pageContent;
				pageContent.batching = pagingContent.batching;
				pageContent.items = pagingContent.items;
        pageContent.items_total = pagingContent.items_total;

        if(pageContent.items.length <= 0){
          var b_start = common.getParam("b_start", paging);
          if(b_start !== null){
            var b_size = common.getParam("b_size", paging);
            b_size = b_size === null ? env.TABLE_PAGE_LENGTH : b_size;

            var page =  parseInt(b_start / b_size);
            var totalePagine =  parseInt(pageContent.items_total / b_size);
            if((pageContent.items_total % b_size) > 0)
              totalePagine ++;

            if(page > totalePagine)
              this.onClickPaging("paginazione_custom", {vaiPagina: totalePagine});
          }
        }
				this.setState({pageContent: pageContent});
			}
      if(pagingContent){
        $("body #app .loadingDiv").fadeOut();
      }
		});
	}

  generaUrl(tipofunzione, parametri, force_reload=true){
    //Parametri:
    var statoPagina = this.state.pagina;
    var statoPaginaNext = JSON.parse(JSON.stringify(statoPagina));
    const $ = window.$;
    switch (tipofunzione){
      case "paginazione_custom":
        statoPaginaNext.filtra_risultati = statoPagina.filtra_risultati;
        if (parametri.calcolaPagina !== undefined && parametri.calcolaPagina !== null && parseInt(parametri.calcolaPagina)) {
          statoPaginaNext.pagina_attuale = statoPagina.pagina_attuale + parseInt(parametri.calcolaPagina);
        }
        else if (parametri.vaiPagina !== undefined && parametri.vaiPagina !== null) {
          if (parametri.vaiPagina === "end") {
            statoPaginaNext.pagina_attuale = parseInt(this.state.pageContent.items_total / env.TABLE_PAGE_LENGTH);
            if ((this.state.pageContent.items_total % env.TABLE_PAGE_LENGTH) > 0) {
              statoPaginaNext.pagina_attuale++;
            }
          }
          else if (parseInt(parametri.vaiPagina))
            statoPaginaNext.pagina_attuale = parseInt(parametri.vaiPagina);
        }
        break;
      case "ordinamento":
        statoPaginaNext.tipo_ordinamento = parametri.tipoOrdinam;
        if (this.state.pageContent.description.tableType === 'ticket' || this.state.pageContent.description.tableType === 'ticket_vehicle')
          common.set_localStorage("sortColumnTicket", statoPaginaNext.tipo_ordinamento);
        statoPaginaNext.verso_ordinamento = parametri.versoOrdinam; // 1 -1
        if (this.state.pageContent.description.tableType === 'ticket' || this.state.pageContent.description.tableType === 'ticket_vehicle')
          common.set_localStorage("sortOrderColumnTicket", statoPaginaNext.verso_ordinamento);
        break;
      case "filtro_marca":
        statoPaginaNext.filtro_marca = parametri.filtro_marca;
        statoPaginaNext.pagina_attuale = common.getParam("page") !== null ? common.getParam("page") : 1;
        statoPaginaNext.filtro_modello = "";
        statoPaginaNext.tipologia_ticket = parametri.tipologia_ticket;
        statoPaginaNext.filtra_risultati = force_reload;
        this.setState({ pagina: statoPaginaNext });
        break;
      case "filtro_modello":
        statoPaginaNext.filtro_modello = parametri.filtro_modello;
        statoPaginaNext.pagina_attuale = 1;
      case "filtro_csoft":
        statoPaginaNext.filtro_csoft = parametri.filtro_csoft;
        statoPaginaNext.pagina_attuale = 1;
        break;
      case "cerca":
        statoPaginaNext.testo_cercato = parametri.testo;
        statoPaginaNext.filtra_risultati = true;
        statoPaginaNext.pagina_attuale = 1;
        this.filtra_ticket()
        break;
      case "stati_ticket":
        statoPaginaNext.filtra_risultati = force_reload;
        statoPaginaNext.pagina_attuale = parametri.firstTime !== null ? common.getParam("page") !== null ? common.getParam("page") : 1 : 1;
        if(parametri.firstTime && parseInt(statoPaginaNext.pagina_attuale) >= 1)
          this.generaUrl("paginazione_custom", { vaiPagina: statoPaginaNext.pagina_attuale })
        if(Array.isArray(parametri.statiTicket)) {
          for(let i = 0; i < parametri.statiTicket.length; i++) {
            if(statoPaginaNext.stati_ticket.indexOf(parametri.statiTicket[i]) <= -1)
              statoPaginaNext.stati_ticket.push(parametri.statiTicket[i]);
            else
              statoPaginaNext.stati_ticket.splice(parametri.statiTicket[i], 1);
          }
        }else{
          var statoTicketKey = statoPaginaNext.stati_ticket.indexOf(parametri.statiTicket);
          if (statoTicketKey > -1) {
            statoPaginaNext.stati_ticket.splice(statoTicketKey, 1);
          } else {
            statoPaginaNext.stati_ticket.push(parametri.statiTicket);
          }
        }

        window.$(".filtro_stati .filtri").prop('checked', false)
        for (let i = 0; i < statoPaginaNext.stati_ticket.length; i++) {
          window.$(".filtro_stati .filtri#" + statoPaginaNext.stati_ticket[i]).prop('checked', true)
        }

        if(statoPaginaNext.stati_ticket.length <= 0)
          statoPaginaNext.stati_ticket = ["NONE_STATE_SELECTED"];
        else{
          var statoTicketKey = statoPaginaNext.stati_ticket.indexOf("NONE_STATE_SELECTED");
          if (statoTicketKey > -1)
            statoPaginaNext.stati_ticket.splice(statoTicketKey, 1);
        }
        common.getUserInfo().then(userInfo => {
          this.setState({ userInfo: userInfo });
          if (common.get_localStorage('filtriTickets') === '' || common.get_localStorage('filtriTickets') === undefined){
            if (userInfo.is_admin_ticket !== undefined && userInfo.is_admin_ticket){
              common.set_localStorage('filtriTickets', JSON.stringify(['pending', 'incomplete', 'published']));
            }else{
              common.set_localStorage('filtriTickets', JSON.stringify(['pending', 'incomplete', 'published', 'closed']));
            }
          }
          
        })
        this.setState({ pagina: statoPaginaNext });
        break;

      case "tipologia_ticket":
        statoPaginaNext.tipologia_ticket = parametri.tipologia_ticket;
        statoPaginaNext.pagina_attuale = 1;
        statoPaginaNext.filtra_risultati = force_reload;
        
        if (parametri.filtra){
          this.setState({pagina: statoPaginaNext}, function () {
            this.onClickPaging('filtra_risultati', {filtra_risultati: true})
          });
        }else{
          this.setState({ pagina: statoPaginaNext });
        }

        break;
      case "swcreators":
        statoPaginaNext.swcreators = parametri.swcreators;
        statoPaginaNext.pagina_attuale = 1;
        break;

      case "filtra_risultati":
        statoPaginaNext.filtra_risultati = parametri.filtra_risultati;
        statoPaginaNext.pagina_attuale = 1;
        break;
      case "last_admin_editor":
        statoPaginaNext.last_admin_editor = parametri.last_admin_editor;
        statoPaginaNext.pagina_attuale = 1;
        statoPaginaNext.filtra_risultati = force_reload;

        if (parametri.filtra) {
          this.setState({ pagina: statoPaginaNext }, function () {
            this.onClickPaging('filtra_risultati', { filtra_risultati: true })
          });
        } else {
          this.setState({ pagina: statoPaginaNext });
        }
        break;
      case "filtro_nazione":
        statoPaginaNext.filtra_risultati = force_reload;
        statoPaginaNext.pagina_attuale = 1;
        common.set_localStorage('valoreNazione', JSON.stringify(parametri.filtro_nazione));
        this.setState({ pagina: statoPaginaNext });
        break;
      default:
        return ""

    }
    var newUrl = env.BACKEND;

    window.history.replaceState({}, "", common.updateURLParameter("page", statoPaginaNext.pagina_attuale));
    newUrl = newUrl + this.state.pathname.substring(1, this.state.pathname.length) + "/";

    var pageStart = env.TABLE_PAGE_LENGTH * (statoPaginaNext.pagina_attuale - 1);
    if(pageStart === 1)
      pageStart--;
    if (this.state.pageContent.description.tableType.indexOf('ticket') > -1)
      newUrl = newUrl + "@sw-search-all-lang?fullobjects=1&swhistory=1";
    else if (this.state.pageContent.description.tableType === 'contact')
      newUrl = newUrl + "@sw-search-all-lang?";
    else
      newUrl = newUrl + "@sw-search?fullobjects=1";
    if (this.state.userInfo.roles.indexOf("Reviewer") > -1)
      newUrl = newUrl + "&show_inactive=1";
    newUrl = newUrl + "&b_size=" + env.TABLE_PAGE_LENGTH + "&b_start=" + pageStart;

    newUrl = newUrl + "&sort_on=";

    if((common.get_localStorage("sortColumnTicket") !== undefined && common.get_localStorage("sortColumnTicket")) && (this.state.pageContent.description.tableType === 'ticket' || this.state.pageContent.description.tableType === 'ticket_vehicle') !== null) {
      newUrl = newUrl + common.get_localStorage("sortColumnTicket")
    } else {
      newUrl = newUrl + statoPaginaNext.tipo_ordinamento;
    }
    newUrl = newUrl + "&sort_order=";
    if((common.get_localStorage("sortOrderColumnTicket") !== undefined && common.get_localStorage("sortOrderColumnTicket") !== null && common.get_localStorage("sortOrderColumnTicket") === "-1") && (this.state.pageContent.description.tableType === 'ticket' || this.state.pageContent.description.tableType === 'ticket_vehicle')) {
      newUrl = newUrl + "reverse";
      $("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).removeClass("sorting_asc");
      $("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).addClass("sorting_desc");
    }else if((common.get_localStorage("sortOrderColumnTicket") !== undefined && common.get_localStorage("sortOrderColumnTicket") !== null && common.get_localStorage("sortOrderColumnTicket") === "1") && (this.state.pageContent.description.tableType === 'ticket' || this.state.pageContent.description.tableType === 'ticket_vehicle')) {
      newUrl = newUrl + "ascending";
      $("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).removeClass("sorting_desc");
      $("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).addClass("sorting_asc");
    }else if(statoPaginaNext.verso_ordinamento === -1) {
      newUrl = newUrl + "reverse";
    }else{
      newUrl = newUrl + "ascending";
    }

    if (statoPaginaNext.filtro_marca && !statoPaginaNext.filtro_modello && statoPaginaNext.filtra_risultati) {
      if (statoPaginaNext.filtro_marca !== 'tutti_modelli') {
        newUrl = newUrl + "&Subject:list=sw-brand-" + statoPaginaNext.filtro_marca;
        if (this.state.pageContent.description.tableType.indexOf('ticket') <= -1)
          newUrl = newUrl + "&Subject:list=" + statoPaginaNext.filtro_marca + "_tutti_modelli&Subject:list=tutti_modelli";
      }
    } else {
      if(this.state.pageContent.description.tableType.indexOf('ticket') <= -1 && common.get_localStorage("valoreModello") === undefined && common.get_localStorage("valoreMarca") !== undefined && common.get_localStorage("valoreMarca") !== null && common.get_localStorage("valoreMarca") !== '') {
        newUrl = newUrl + "&Subject:list=sw-brand-" + common.get_localStorage("valoreMarca") + "&Subject:list=" + common.get_localStorage("valoreMarca") + "_tutti_modelli&Subject:list=tutti_modelli";
        newUrl = newUrl + "&Subject:list=" + common.get_localStorage('filtriGroupTicket');
      }
      if(this.state.pageContent.description.tableType.indexOf('ticket') > -1) {
        if (common.get_localStorage("valoreMarca") !== undefined && common.get_localStorage("valoreMarca") !== '' && this.state.marche_attive.length > 1)
          newUrl = newUrl + "&Subject:list=sw-brand-" + common.get_localStorage("valoreMarca");
      }
    }

    if(statoPaginaNext.filtro_csoft) {
      newUrl = newUrl + "&Subject:list=" + statoPaginaNext.filtro_csoft;
    }else{
      if(this.state.userInfo !== undefined && this.state.userInfo.is_admin_ticket_group) {
        newUrl = newUrl + "&Subject:list=sw-csoft-" + this.state.userInfo.userInfo.csoft;
      }else{
        if(common.get_localStorage("filtriGroupTicket") !== undefined && common.get_localStorage("filtriGroupTicket") !== null && common.get_localStorage("filtriGroupTicket") !== '') {
          newUrl = newUrl + "&Subject:list=" + common.get_localStorage('filtriGroupTicket');
        }
      }
    }

    if(statoPaginaNext.filtro_modello) {
      newUrl = newUrl + "&Subject:list=" + statoPaginaNext.filtro_modello;
      if(newUrl.indexOf("&Subject:list=tutti_modelli") <= -1)
        newUrl = newUrl + "&Subject:list=tutti_modelli";
    }else{
      if(this.state.pageContent.description.tableType.indexOf('ticket') <= -1 && common.get_localStorage("valoreModello") !== undefined && common.get_localStorage("valoreModello") !== null && common.get_localStorage("valoreModello") !== '') {
        newUrl = newUrl + "&Subject:list=" + common.get_localStorage("valoreModello");
        if(newUrl.indexOf("&Subject:list=tutti_modelli") <= -1)
          newUrl = newUrl + "&Subject:list=tutti_modelli";
      }
    }

    if(statoPaginaNext.tipologia_ticket) {
      newUrl = newUrl + "&Subject:list=" + statoPaginaNext.tipologia_ticket;
    }else{
      if (this.state.pageContent.description?.allowed_types?.length != 1) {
        if (common.get_localStorage('valoreTipologiaTicket') !== null && common.get_localStorage('valoreTipologiaTicket') !== undefined && common.get_localStorage('valoreTipologiaTicket') !== '') {
          newUrl = newUrl + "&Subject:list=" + common.get_localStorage('valoreTipologiaTicket');
        }
      }
    }
      
    if(this.state.pageContent.description.tableType.indexOf('ticket') <= -1 && common.get_localStorage("valoreModello") !== undefined && common.get_localStorage("valoreModello") !== null && common.get_localStorage("valoreModello") !== '') {
      newUrl = newUrl + "&Subject:list=" + common.get_localStorage("valoreModello");
      if (newUrl.indexOf("&Subject:list=tutti_modelli") <= -1)
        newUrl = newUrl + "&Subject:list=tutti_modelli";
    }

    if(this.state.pageContent.description.tableType.indexOf('ticket') <= -1 && common.get_localStorage("valoreModello") !== undefined && common.get_localStorage("valoreModello") !== '' && common.get_localStorage("valoreMarca") !== undefined && common.get_localStorage("valoreMarca") !== '') {
      if(newUrl.indexOf("&Subject:list=" + common.get_localStorage("valoreMarca") + "_tutti_modelli") <= -1)
        newUrl = newUrl + "&Subject:list=" + common.get_localStorage("valoreMarca") + "_tutti_modelli";
    }

    if(this.state.pageContent.description.tableType.indexOf('ticket') <= -1 && common.get_localStorage("valoreModello") !== undefined && common.get_localStorage("valoreModello") !== '' && this.state.marche_attive.length === 1) {
      if (newUrl.indexOf("&Subject:list=" + this.state.marche_attive[0].ZFORN + "_tutti_modelli") <= -1)
        newUrl = newUrl + "&Subject:list=" + this.state.marche_attive[0].ZFORN + "_tutti_modelli";
    }

    if(statoPaginaNext.swcreators && statoPaginaNext.filtra_risultati) {
      for (let t = 0; t < statoPaginaNext.swcreators.length; t++) {
        newUrl = newUrl + "&Creator:list=" + statoPaginaNext.swcreators[t];
      }
    }

    if(this.state.pageContent.description.tableType.indexOf('ticket') > -1) {
      var filtriTickets = '';
      if (statoPaginaNext.stati_ticket && statoPaginaNext.filtra_risultati) {
        if (statoPaginaNext.stati_ticket.length == 0 && common.get_localStorage('filtriTickets')) {
          filtriTickets = JSON.parse(common.get_localStorage('filtriTickets'));
        } else {
          filtriTickets = statoPaginaNext.stati_ticket;
        }
        for (let i = 0; i < filtriTickets.length; i++) {
          newUrl = newUrl + "&review_state=" + filtriTickets[i] + "&review_state=" + filtriTickets[i] + "_visto";
        }
      }else{
        if (common.get_localStorage('filtriTickets') !== undefined && common.get_localStorage('filtriTickets').length > 2 && common.get_localStorage('filtriTickets') !== null && common.get_localStorage('filtriTickets') !== '' && common.get_localStorage('filtriTickets') !== undefined) {
          filtriTickets = JSON.parse(common.get_localStorage('filtriTickets'));
          for (var i = 0; i < filtriTickets.length; i++) {
            newUrl = newUrl + "&review_state=" + filtriTickets[i] + "&review_state=" + filtriTickets[i] + "_visto";
          }
        }
      }

      if(!this.state.userInfo.is_admin_ticket && newUrl.indexOf("incomplete") > -1)
        newUrl = newUrl + "&review_state=incomplete_draft";
    }

    if(statoPaginaNext.testo_cercato) {
      if (!statoPaginaNext.testo_cercato.replace(/\s/g, '').length) {
        newUrl = newUrl + "";
      } else {
        newUrl = newUrl + "&SearchableText=*" + statoPaginaNext.testo_cercato + "*" + "&Creator=*" + statoPaginaNext.testo_cercato + "*";
      }
    }
    
    if(common.get_localStorage('valoreAdminTicket') !== undefined && common.get_localStorage('valoreAdminTicket') !== null && common.get_localStorage('valoreAdminTicket') !== "") {
      var valoreAdminTicket = JSON.parse(common.get_localStorage("valoreAdminTicket"));
      for (let lae = 0; lae < valoreAdminTicket.length; lae++) {
        newUrl = newUrl + "&last_admin_editor=" + valoreAdminTicket[lae];
      }
    }

    if(common.get_localStorage('valoreNazione') !== undefined && common.get_localStorage('valoreNazione') !== null && common.get_localStorage('valoreNazione') !== "") {
      var valoreNazione = JSON.parse(common.get_localStorage("valoreNazione"));
      for(var user_cnazi = 0; user_cnazi < valoreNazione.length; user_cnazi++) {
        newUrl = newUrl + "&user_cnazi=" + valoreNazione[user_cnazi];
      }
    }

    var showed = common.get_localStorage('mostra_filtri');
    if (showed !== undefined && showed !== null && showed !== ''){
      if ( showed === 'false'){
        $('#collapseFiltri').removeClass('show')
      } else {
        $('#collapseFiltri').addClass('show')
      }
    } 

    this.setState({ pagina: statoPaginaNext });
    return newUrl;
  }

  filtra_ticket = () => {
    const $ = window.$;
    var tipologiaTicket = $("#tipologieTicket").val()
    if(tipologiaTicket === null || tipologiaTicket === undefined) {
      tipologiaTicket = '';
    }

    var	valoreTipologia = tipologiaTicket !== 'all_tipologie' ? tipologiaTicket : '';

    var statoPagina = this.state.pagina;
    var statoPaginaNext = JSON.parse(JSON.stringify(statoPagina));
    
    common.set_localStorage('valoreTipologiaTicket', valoreTipologia);
    if($('#select_nazioni').val() !== undefined)
      common.set_localStorage("valoreNazione", JSON.stringify($('#select_nazioni').val()));
    if($('#select_user_ticket').val() !== undefined)
      common.set_localStorage('valoreAdminTicket', JSON.stringify($('#select_user_ticket').val()));
    if ($('#select_vm').val() !== undefined && $('#select_vm').val())
      if ($('#select_vm').val() === 'tutti_modelli'){
        common.set_localStorage('valoreMarca', '');
      } else{
        common.set_localStorage('valoreMarca',$('#select_vm').val())
      }

    // Filtro cerca funziona anche se scrivi nel campo cerca ma clicchi il button "Applica filtri"
    if ($('input.search_tabella').val() !== undefined && $('input.search_tabella').val() !== '')
      common.set_localStorage('filtroCerca', $('input.search_tabella').val());

    if (statoPaginaNext.stati_ticket !== undefined && statoPaginaNext.stati_ticket.length !== 0)
      common.set_localStorage('filtriTickets', JSON.stringify(statoPaginaNext.stati_ticket)); 
			
    var parametri = {
      tipologia_ticket: valoreTipologia,
      filtra: true,
    }
    
    this.onClickPaging("tipologia_ticket", parametri, false);
    
  }

  // funzione che resetta tutti i filtri dei ticket
  reset_filtri_ticket = () => {
    common.remove_localStorage('valoreTipologiaTicket'); //tipologia
    common.remove_localStorage("valoreNazione"); //nazione
    common.remove_localStorage("valoreMarca"); //marca
    common.remove_localStorage("valoreAdminTicket"); //admin ticket
    common.remove_localStorage("sortColumnTicket"); //colonna
    common.remove_localStorage("sortOrderColumnTicket"); //ordinamento colonna
    common.remove_localStorage("filtriTickets"); //stato tickets
    common.remove_localStorage("filtroCerca"); //cerca tickets
    window.location.reload(false);
  }

  handleFilterAlert() {
    const $ = window.$;
    if (this.state.pageContent.description.tableType.indexOf('ticket') > -1) {
      const alert = $('#filtri_alert')
      var filtriTickets = common.get_localStorage('filtriTickets');
      var states
      
      common.getUserInfo().then(userInfo => {
        this.setState({ userInfo: userInfo });
        const standard = (userInfo.is_admin_ticket !== undefined && userInfo.is_admin_ticket) ? ["pending", "published", "incomplete"] : ["pending", "published", "incomplete", "closed"]
        if (filtriTickets && filtriTickets.trim() !== '') {
          states = JSON.parse(filtriTickets);
        } else {
          states = standard;
        }
        if (userInfo.is_admin_ticket !== undefined && userInfo.is_admin_ticket) {
          if (!states.includes(standard[0]) || !states.includes(standard[1]) || !states.includes(standard[2]) || Object.keys(states).length > 3) {
            alert.fadeIn()
          }else if (common.get_localStorage('valoreTipologiaTicket') !== undefined && common.get_localStorage('valoreTipologiaTicket') !== '' && this.state.pageContent.description?.allowed_types?.length != 1) {
            alert.fadeIn()
          }
          else if (common.get_localStorage('valoreAdminTicket') !== undefined && common.get_localStorage('valoreAdminTicket') !== '[]') {
            alert.fadeIn()
          }
          else if (common.get_localStorage('valoreNazione') !== undefined && common.get_localStorage('valoreNazione') !== '[]') {
            alert.fadeIn()
          }
          else if (common.get_localStorage('valoreMarca') !== undefined && common.get_localStorage('valoreMarca') !== '') {
            alert.fadeIn()
          }
          else if (common.get_localStorage('filtroCerca') !== undefined && common.get_localStorage('filtroCerca') !== '') {
            alert.fadeIn()
          }
          else {
            alert.fadeOut()
          }
        }
        else if (!userInfo.is_admin_ticket !== undefined && !userInfo.is_admin_ticket) {
          if (!states.includes(standard[0]) || !states.includes(standard[1]) || !states.includes(standard[2]) || !states.includes(standard[3]) || Object.keys(states).length > 4) {
            alert.fadeIn()
          }
          else if (common.get_localStorage('valoreTipologiaTicket') !== undefined && common.get_localStorage('valoreTipologiaTicket') !== '' && this.state.pageContent.description?.allowed_types?.length != 1){
            alert.fadeIn()
          }
          else if (common.get_localStorage('valoreAdminTicket') !== undefined && common.get_localStorage('valoreAdminTicket') !== '[]'){
            alert.fadeIn()
          }
          else if (common.get_localStorage('valoreNazione') !== undefined && common.get_localStorage('valoreNazione') !== '[]') {
            alert.fadeIn()
          }
          else if (common.get_localStorage('valoreMarca') !== undefined && common.get_localStorage('valoreMarca') !== '') {
            alert.fadeIn()
          }
          else if (common.get_localStorage('filtroCerca') !== undefined && common.get_localStorage('filtroCerca') !== '') {
            alert.fadeIn()
          }
          else{
            alert.fadeOut()
          }
        }
      })
    }
  }

  changeIcon(param){
    const $ = window.$;
    if (param === 'true') {
      $("#showFilter").addClass('open')
      return "fas fa-caret-up"
    } else if (param === 'false') {
      $("#showFilter").removeClass('open')
      return "fas fa-caret-down"
    } else {
      $("#showFilter").addClass('open')
      return "fas fa-caret-up"
    }
  }

  showFilterCache() {
    const $ = window.$;
    if ($("#showFilter").hasClass('open') && $('#showFilter i').attr('class') === 'fas fa-caret-up') {
        common.set_localStorage('mostra_filtri', false)
        $("#showFilter i").css({ transform: 'rotate(180deg)' });
        $('#borderFilter').css({ borderTop: '1px solid #eee' })
        $("#showFilter").removeClass('open')
    }else if ($('#showFilter i').attr('class') === 'fas fa-caret-up') {
        common.set_localStorage('mostra_filtri', true)
        $("#showFilter i").css({ transform: 'rotate(0deg)' });
        $('#borderFilter').css({ borderTop: '0px' })
        $("#showFilter").addClass('open')
    }else if ($("#showFilter").hasClass('open') && $('#showFilter i').attr('class') === 'fas fa-caret-down'){
        common.set_localStorage('mostra_filtri', false)
        $("#showFilter i").css({ transform: 'rotate(0deg)' });
        $('#borderFilter').css({ borderTop: '0px' })
        $("#showFilter").removeClass('open')
    }else if ($('#showFilter i').attr('class') === 'fas fa-caret-down'){
        common.set_localStorage('mostra_filtri', true)
        $("#showFilter i").css({ transform: 'rotate(180deg)' });
        $('#borderFilter').css({ borderTop: '1px solid #eee' })
        $("#showFilter").addClass('open')
    }
    }

  printMultibrandControls = () =>{
    let is_multibrand = this.state.pageContent.description?.filters?.includes("FilterVM");
    if (is_multibrand && (!multibrand.is_multibrand_portal()))
      is_multibrand = false;

    return is_multibrand;
  }

	renderSwitchTable() {
    let is_multibrand = this.printMultibrandControls();
		switch(this.state.pageContent.description.tableType) {
			case 'contact':
				return (<ContactTable pageTitle={this.props.pageTitle} 
                              paginaDati={this.state.pagina} 
                              onClick={(tipoFunzione, params) => this.onClickPaging(tipoFunzione, params)} 
                              showAllContents={this.state.pageContent.description.showAllContents} 
                              pathname={this.state.pathname} 
                              pageContent={this.state.pageContent}
                ></ContactTable>)
			case 'mainteinance':
				return (<MainteinanceTable  pathname={this.state.pathname} 
                                    pageContent={this.state.pageContent}
                ></MainteinanceTable>)
			case 'ticket':
				return (<TicketTable  marche_attive={this.state.marche_attive}
                              tipologieTicket={this.state.tipologie_ticket_attive} 
                              pageTitle={this.props.pageTitle} 
                              paginaDati={this.state.pagina} 
                              onClick={(tipoFunzione, params, force_reload) => this.onClickPaging(tipoFunzione, params, force_reload)} 
                              pathname={this.state.pathname} 
                              pageContent={this.state.pageContent}
                              is_multibrand={is_multibrand}
                              mostraNazione={this.mostraNazioneTicket()}
                  ></TicketTable>)
      case 'ticket_vehicle':
        return (<TicketVehicleTable marche_attive={this.state.marche_attive} 
                                    tipologieTicket={this.state.tipologie_ticket_attive} 
                                    pageTitle={this.props.pageTitle} 
                                    paginaDati={this.state.pagina} 
                                    onClick={(tipoFunzione, params, force_reload) => this.onClickPaging(tipoFunzione, params, force_reload)} 
                                    pathname={this.state.pathname} 
                                    pageContent={this.state.pageContent}
                                    is_multibrand={is_multibrand}
                  ></TicketVehicleTable>)
			case 'paperless':
				return (<PaperlessTable pageTitle={this.props.pageTitle}></PaperlessTable>)
      case 'gestione-contenuti':
		return <Navigate to="/gestione-contenuti"/>
      case 'statistiche':
        return <Navigate to="/statistiche"/>
      default:
				return (<DefaultTable pageTitle={this.props.pageTitle} 
                              paginaDati={this.state.pagina} 
                              onClick={(tipoFunzione, params) => this.onClickPaging(tipoFunzione, params)} 
                              showAllContents={this.state.pageContent.description.showAllContents} 
                              pathname={this.state.pathname} 
                              pageContent={this.state.pageContent}
                ></DefaultTable>)
		}
	}

	renderSwitchFilters() {
    let is_multibrand = this.printMultibrandControls();
    let numberFilterTicket
		if(this.state.pageContent.description.filters !== undefined && this.state.pageContent.description.filters !== null && this.state.pageContent.description.filters!=="" && this.state.pageContent.description.filters !== "undefined" && this.state.pageContent.description.filters !== "null" && this.state.pageContent.description.filters.length > 0){
      const classDivFilter = "col-xl-6 col-lg-6"
      const classDivFilterTicket = "col-xl-4 col-lg-4"
      const classDivFourFilterTicket = "col-xl-3 col-lg-3"
      return (this.state.pageContent.description.filters.map((item, key) => {
        var ret = (<></>);
				switch(item){
					case "FilterModels":
            is_multibrand ? numberFilterTicket = classDivFilterTicket : numberFilterTicket = classDivFilter
						ret = (<FilterModels
              onClick={(tipoFunzione, params) => this.onClickPaging(tipoFunzione, params)}
              showAllContents={this.state.pageContent.description.showAllContents}
              customClass={numberFilterTicket}
              marche_attive={this.state.marche_attive}
              marcaDefault={this.state.pagina.filtro_marca}
              key={key}></FilterModels>);
          break;
					case "FilterSpareParts":
						ret = (<FilterSpareParts key={key}></FilterSpareParts>);
					break;
          case "FilterVM":
            this.state.pageContent.description.contentType === 'SWContent' ? numberFilterTicket = classDivFilterTicket : numberFilterTicket = classDivFourFilterTicket
            if (is_multibrand)
              ret = (<FilterVM
                customClass={numberFilterTicket}
                marche_attive={this.state.marche_attive}
                tableType={this.state.pageContent.description.tableType}
                onClick={(tipoFunzione, params, force_reload) => this.onClickPaging(tipoFunzione, params, force_reload)}
                key={key}
              ></FilterVM>)
          break;
					case "FilterChassis":
            is_multibrand ? numberFilterTicket = classDivFilterTicket : numberFilterTicket = classDivFilter
			      ret = (<FilterChassis
              customClass={numberFilterTicket}
              marche_attive={this.state.marche_attive}
              marcaDefault={this.state.pagina.filtro_marca}
              key={key}></FilterChassis>);
					break;
					case "FilterTypesTickets":
            is_multibrand ? numberFilterTicket = classDivFourFilterTicket : numberFilterTicket = classDivFilterTicket
						ret = (<FilterTypesTickets
              customClass={numberFilterTicket}
  						tipologie_ticket_attive={this.state.tipologie_ticket_attive}
              marche_attive={this.state.marche_attive}
              marcaDefault={this.state.pagina.filtro_marca}
              allowed_types={this.state.pageContent.description.allowed_types}
              onClick={(tipoFunzione, params, force_reload) => this.onClickPaging(tipoFunzione, params, force_reload)}
  						key={key}></FilterTypesTickets>);
          break;
					case "FilterGroup":
            if(this.state.userInfo.is_admin_ticket && this.state.userInfo.ticket_group_by_csoft && !this.state.userInfo.is_admin_ticket_group){
				      ret = (<FilterGroup
                customClass={classDivFilter}
                onClick={(tipoFunzione, params) => this.onClickPaging(tipoFunzione, params)} key={key}></FilterGroup>);
            }else
              ret = (<></>);
					break;
					case "TicketState":
            ret = (<TicketState
              customClass={classDivFilter}
              tipologie_ticket_attive={this.state.tipologie_ticket_attive}
              onClick={(tipoFunzione, params, force_reload) => this.onClickPaging(tipoFunzione, params, force_reload)}
              key={key}></TicketState>);
          break;
          case "FilterUserTicket":
            is_multibrand ? numberFilterTicket = classDivFourFilterTicket : numberFilterTicket = classDivFilterTicket
            if (this.mostraNazioneTicket())
            ret = (<FilterUserTicket
              customClass={numberFilterTicket}
              onClick={(tipoFunzione, params, force_reload) => this.onClickPaging(tipoFunzione, params, force_reload)}
              tableType={this.state.pageContent.description.tableType}
              key={key}></FilterUserTicket>);
          break;
					case "FilterNations":
            is_multibrand ? numberFilterTicket = classDivFourFilterTicket : numberFilterTicket = classDivFilterTicket
            if (this.mostraNazioneTicket())
            ret = (<FilterNations
              customClass={numberFilterTicket}
              onClick={(tipoFunzione, params, force_reload) => this.onClickPaging(tipoFunzione, params, force_reload)}
              tableType={this.state.pageContent.description.tableType}
              key={key}></FilterNations>);
          break;
				}
				return (ret);
			}));
		}
	}

	render(props){
    if(this.state.userInfo.roles.length > 0 && this.state.userInfo.roles !== undefined && this.state.userInfo.roles !== null){
		  var redirectLink = common.calcTranslationRedirect(this.state.userInfo, this.state.pageContent);
      if(redirectLink !== false)
		return (<Navigate from={this.state.pathname} to={"/" + redirectLink} />);
    }

		return(
      <> 
        {this.state.pageContent.description.contentType.indexOf('ticket') > -1  ?
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content" >
            <div className="collapse show" id="collapseFiltri">
              <div className="row content_filtro" style={{ marginBottom : '0px'}}>
                  {this.renderSwitchFilters()}
                  {this.state.pageContent.description.contentType.indexOf('ticket') > -1 ?
                    <div className="col">
                      <button style={{height: '40px', marginLeft: '15px'}} onClick={this.filtra_ticket}
                      className="btn btn-sm btn-outline-light bottone_default float-right">
                        <i className="fas fa-filter"></i>&nbsp;{common.getTrad("Applica i filtri")}
                      </button>
                      <button style={{ height: '40px', marginLeft: '15px' }} onClick={this.reset_filtri_ticket}
                        className="btn btn-sm btn-light float-right">
                        <i className="fa fa-eraser"></i>&nbsp;{common.getTrad("Resetta i filtri")}
                      </button>
                    </div>
                  :
                  <></>
                  }
                </div>   
              </div>
            </div>
        :
            <div id="filtri_files" className="col-12">
              {this.state.pageContent.description.contentType.indexOf('contact') > -1 ? null :
                <div className="row content_filtro">
                  {this.renderSwitchFilters()}
                  {this.state.pageContent.description.contentType.indexOf('ticket') > -1 ?
                    <div className="col">
                      <button style={{ height: '40px', marginLeft: '15px' }} onClick={this.filtra_ticket}
                        className="btn btn-sm btn-outline-light bottone_default float-right">
                        <i className="fas fa-filter"></i>&nbsp;{common.getTrad("Applica i filtri")}
                      </button>
                      <button style={{ height: '40px', marginLeft: '15px' }} onClick={this.reset_filtri_ticket}
                        className="btn btn-sm btn-light float-right">
                        <i className="fa fa-eraser"></i>&nbsp;{common.getTrad("Resetta i filtri")}
                      </button>
                    </div>
                    :
                    <></>
                  }
                </div>
              }
            </div>
        }
            


				<div id="content-core" className="row" style={{width: '100%'}}>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
            {this.state.pageContent.description.contentType.indexOf('contact') > -1 ? null : (
              <div id="borderFilter"></div>
            )}    
            <div className="row">
              <div className="col-md-6">
                {this.state.pageContent.description.contentType.indexOf('ticket') > -1 ?
                  <div className="row">
                    <div id="filtri_alert" className="alert alert-warning" role="alert">
                      <i className="fa fa-exclamation-triangle"></i>&nbsp;{common.getTrad(" Attenzione! Uno o più filtri sono stati modificati.")}
                    </div>
                  </div>
                  :
                  <></>
                } 
                {this.state.pageContent.description.contentType.indexOf('ticket') > -1 && !this.state.userInfo.is_admin_ticket ?
                  <Link className="btn bottone_default" to={common.getUrlPath(window.location.href) + "/@@newTicket"} style={{ marginBottom: "1.5em", marginTop: "1.5em"}}><span>+</span> {common.getTrad("Nuovo contenuto")}</Link>
                  :
                  this.state.userInfo.roles.indexOf("Reviewer") > -1 && this.state.pageContent.description.tableType === 'contact' ?
                    <Link className="btn bottone_default" to={common.getUrlPath(window.location.href) + "/@@new"} style={{ marginBottom: "1.5em", marginTop: "1.5em"}}><span>+</span> {common.getTrad("Nuovo contenuto")}</Link>
                  :
                    !window.cc.gestioneContenutiFlag && this.state.pageContent.description.tableType === 'default' && this.state.userInfo !== undefined && this.state.userInfo.userInfo.groups !== undefined && this.state.userInfo.userInfo.groups.indexOf("SW Service Gestione Contenuti") > -1 ?
                      <Link className="btn bottone_default" to={common.getUrlPath(window.location.href) + "/@@new"} style={{ marginBottom: "1.5em", marginTop: "1.5em" }}><span>+</span> {common.getTrad("Nuovo contenuto")}</Link>
                :
                  ""
                }
              </div>
              <div className="col-md-6 text-right">
                {this.state.pageContent.description.contentType.indexOf('ticket') > -1 ?
                  <a id="showFilter" className="btn btn-outline-light bottone_default ml-auto" style={{ marginBottom: "1.5em"}} data-toggle="collapse" href="#collapseFiltri" role="button" aria-expanded="true" aria-controls="collapseFiltri" onClick={() => this.showFilterCache()}>
                    <i className={this.changeIcon(common.get_localStorage('mostra_filtri'))}></i>{common.getTrad("Filtri")}
                  </a>
                  :
                  <></>
                }
              </div>
            </div>
            {this.renderSwitchTable()}           
					</div>
				</div>
			</>
		)
	}
}

class PageSingleContent extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }

	constructor(props){
		super(props);
		this.state = {};
		this.state.pageContent = {};
    this.state.parentPageContent = this.props.parentPageContent;
		this.state.pathname = {};
		this.state.userInfo = {};
		this.state.userInfo.roles = [];
    this.state.userInfo.userInfo = {};
		this.state.userInfo.userInfo.groups = [];
		this.state.render1 = false;
		this.state.pageContent.description = {};
		this.stampaPagina = this.stampaPagina.bind(this);
    this.state.Lock = {};
    this.state.marche_attive = this.props.marche_attive;
    this.state.tipologie_ticket_attive = common.getTicketTypesByBrand();
	}

	stampaPagina() {
		window.print();
	}

  checkTicketLocked(typeContent, urlTicket){
    if((window.location.href.indexOf("@@editTicket") <= -1 && window.location.href.indexOf("ticket?") <= -1) && typeContent.indexOf('swticket') > -1 && urlTicket !== undefined && urlTicket !== null && urlTicket !== ''){
      common.apiCall(common.getBackendUrl(urlTicket) + "/@sw-lock", "GET", {}, true, false, "json")
      .then(responseRefresh=>{
        if(!responseRefresh)
          doLogout();
        if(responseRefresh.error === false){
          // mantengo il tempo originale da quando è stato bloccato
          if(responseRefresh.locked){
            var lockObject = this.state.Lock;
            responseRefresh.time = lockObject.time;
            this.setState({Lock: responseRefresh});
          }else{
            this.setState({Lock: {}});
          }
        }else{
          doLogout();
        }
      });
    }
  }

  UNSAFE_componentWillMount = async (props) => {
		common.getUserInfo().then(userInfo =>{
			this.setState({userInfo: userInfo});
		})
		if(this.props.pageContent.video !== undefined && this.props.pageContent.video !== null && this.props.pageContent.video !== ""){
			try{
				this.props.pageContent.video = JSON.parse(this.props.pageContent.video);
			}catch(error){}
		}


		try{
			this.props.pageContent.image.scales.preview.file = await common.apiCall(common.getBackendUrl(this.props.pageContent.image.scales.preview.download), "GET", {}, true, false, "blob");
		}catch(error){}

		if(this.props.pageContent["@type"].indexOf("swticket") > -1){
      this.changeReviewStateRead(this.props.pageContent.review_state, false);

			var parentUrl = this.props.pageContent.parent["@id"];
			if(parentUrl.search("/" + common.getLanguage() + "/") <= -1){
				for(var i=0; i < window.cc.languages.length; i++){
					if(parentUrl.search("/" + window.cc.languages[i] + "/") > -1){
						parentUrl = parentUrl.replace("/" + window.cc.languages[i] + "/", "/" + common.getLanguage() + "/");
						break;
					}
				}
        await common.apiCall(parentUrl, "GET", {}, true, true).then((response) =>{
					if(response.error === false)
						this.props.pageContent.parent.description = response.description;
				});
			}
		}

		if(this.props.pageContent.parent.description !== undefined && this.props.pageContent.parent.description !== null && this.props.pageContent.parent.description !== "" ){
			try{
				this.props.pageContent.parent.description = JSON.parse(this.props.pageContent.parent.description);
			}catch(error){
				return window.location.reload()
			}
		}
    
		this.setState({pageContent :  this.props.pageContent, pathname : this.props.pathname, render1: true});
    if(this.props.pathname && this.props.pageContent['@type'] !== undefined){
      this.checkTicketLocked(this.props.pageContent['@type'], this.props.pathname);
      setInterval(() => {
        this.checkTicketLocked(this.props.pageContent['@type'], this.props.pathname);
      }, 60 * 1000);
    }
  }

  UNSAFE_componentWillReceiveProps(props){
    if(props.parentPageContent !== undefined && props.parentPageContent !== null)
      this.setState({parentPageContent: props.parentPageContent});
  }

	async changeReviewStateByTransition(element){
		const $ = window.$;
		var response;
		var url = this.state.pageContent['@id'] + '/@workflow/' + $(element.target).attr('data-state');
		$("body #app .loadingDiv").fadeIn("fast");
    var data = {}
    for (let i = 0; i < this.state.pageContent.subjects.length; i++) {
      if(this.state.pageContent.subjects[i].indexOf('sw-brand') > -1)
        data.brand = this.state.pageContent.subjects[i].substr(9, 2);
      else {
        if(this.state.pageContent.subjects[i].indexOf("sw-csoft") <= -1)
          data.tipologia = this.state.pageContent.subjects[i];
      }
    }
		response = await common.apiCall(url, "POST", data, true, false, "json");
		if(response.error === false){
			var pageContent = this.state.pageContent;
      let contentType = "";
      if (this.state.pageContent["@type"] !== undefined)
        contentType = this.state.pageContent["@type"];
      common.clear_cache_after_save(contentType);
			pageContent.review_state = response.review_state;
			if(pageContent.parent.description.tableType.indexOf('ticket') > -1){
				common.apiCall(env.BACKEND + this.state.pathname, "PATCH", getTicketRights(pageContent), true, false, "text")
				.then(data=>{
					if(data.error === false){
						$("body #app .loadingDiv").fadeOut();
				}else{
					console.error("error" + data.error);
					$("body #app .loadingDiv").fadeOut();
				}
				});
			}
		  response = await common.apiCall(pageContent["@id"], "GET", {"expand": "workflow"}, true, true);
      if(response.error === false){
        pageContent["@components"]["workflow"]["transitions"] = response["@components"]["workflow"]["transitions"];
			}
			this.setState({pageContent});
		}else{
			console.error("error" + response.error);
		}
		$("body #app .loadingDiv").fadeOut();
	}

	async changeReviewState(newState){
		var url = common.getBackendUrl(this.state.pageContent["@id"]), response;
		if(newState === "private")
			url = url + "/@workflow/hide";
		else if(newState === "internal")
			url = url + "/@workflow/show_internally";
		// solo per ticket
		else if(newState === "closed")
			url = url + "/@workflow/close";
		else if(newState === "incomplete")
			url = url + "/@workflow/reject";
		else if(newState === "pending")
			url = url + "/@workflow/submit";
		else if(newState === "published")
			url = url + "/@workflow/publish";

		else
			return false;

		const $ = window.$;
		$("body #app .loadingDiv").fadeIn("fast");
    var data = {}
    for (let i = 0; i < this.state.pageContent.subjects.length; i++) {
      if(this.state.pageContent.subjects[i].indexOf('sw-brand') > -1)
        data.brand = this.state.pageContent.subjects[i].substr(9, 2);
      else {
        if(this.state.pageContent.subjects[i].indexOf("sw-csoft") <= -1)
          data.tipologia = this.state.pageContent.subjects[i];
      }
    }

		response = await common.apiCall(url, "POST", data, true, false, "json");
		if(response.error === false){
      let contentType = "";
      if (this.state.pageContent["@type"] !== undefined)
        contentType = this.state.pageContent["@type"];
      common.clear_cache_after_save(contentType);
			var pageContent = this.state.pageContent;
			pageContent.review_state = response.review_state;
			this.setState({pageContent: pageContent});

			if(pageContent.parent.description.tableType.indexOf('ticket') > -1){
				common.apiCall(env.BACKEND + this.state.pathname, "PATCH",getTicketRights(pageContent), true, false, "text")
				.then(data=>{
					if(data.error === false){
						$("body #app .loadingDiv").fadeOut();
		      }else{
					  console.error("error" + data.error);
					$("body #app .loadingDiv").fadeOut();
		      }
		    });
			}
		}else{

			console.error("error" + response.error);
		}
		$("body #app .loadingDiv").fadeOut();
	}

	async deleteContent(){
		const $ = window.$;
    $("body #app .loadingDiv").fadeIn("fast");

		var url = common.getBackendUrl(this.state.pageContent["@id"]), response;

		response = await common.apiCall(url, "DELETE", {}, true, false, "text");

		if(response === ""){
			$(".content_sidebar a.nav-link.active").click();
			window.location.href = common.getFrontendUrl(this.state.pageContent.parent["@id"]);
      let contentType = "";
      if (this.state.pageContent["@type"] !== undefined)
        contentType = this.state.pageContent["@type"];
      $('#notifica_messaggio').html('<div class="sw-alert alert alert-success animated">' + common.getTrad("Eliminazione riuscita") + '</div>');
      common.clear_cache_after_save(contentType);
		}else{
			bootbox.alert(common.getTrad("Errore. Si prega di riprovare"));
			console.error("error" + response.error);
		}
	}

  async changeReviewStateRead(newStateRead, manualAction){
    if(this.props.pageContent['@components'] !== undefined && this.props.pageContent['@components']['workflow'] !== undefined && this.props.pageContent['@components']['workflow']['transitions'].length > 0) {
      var url = common.getBackendUrl(this.props.pageContent["@id"]), response;
      if(common.object_value_in_array(this.props.pageContent['@components']['workflow']['transitions'], '@id', this.props.pageContent['@id'] + '/@workflow/' + this.props.pageContent.review_state) > -1){
        //automatismo per cambiare stato tikcet da NON Visto a VISTO
        if(newStateRead.indexOf('visto') <= -1 && !manualAction){
          url = url + "/@workflow/" + newStateRead;
        }else{
          if(manualAction && newStateRead === "pending_visto"){
            url = url + "/@workflow/submit";
          }
          if(manualAction && newStateRead === "pending"){
            url = url + "/@workflow/read_aperto";
          }
        }
      }else{
        if(manualAction && newStateRead === "pending_visto"){
          url = url + "/@workflow/submit";
        }
        if(manualAction && newStateRead === "pending"){
          url = url + "/@workflow/read_aperto";
        }
      }

      if(url.indexOf("@workflow") > -1){
        var data = {}
        for (let i = 0; i < this.props.pageContent.subjects.length; i++) {
          if(this.props.pageContent.subjects[i].indexOf('sw-brand') > -1)
            data.brand = this.props.pageContent.subjects[i].substr(9, 2);
          else {
            if(this.props.pageContent.subjects[i].indexOf("sw-csoft") <= -1)
              data.tipologia = this.props.pageContent.subjects[i];
          }
        }

        response = await common.apiCall(url, "POST", data, true, false, "json");
        if(response.error === false){
          var pageContent = this.props.pageContent;
          let contentType = "";
          if (this.state.pageContent["@type"] !== undefined)
            contentType = this.state.pageContent["@type"];
          common.clear_cache_after_save(contentType);
          pageContent.review_state = response.review_state;
          this.setState({pageContent: pageContent});
        }
      }
    }
  }

	renderSwitchSinglePage() {
		switch(this.state.pageContent.parent.description.contentType){
			case 'contact':
				return (<PageContact pathname={this.state.pathname} pageContent={this.state.pageContent}></PageContact>)
			case 'ticket':
				return (<PageTicket marche_attive={this.state.marche_attive} pathname={this.state.pathname} pageContent={this.state.pageContent} Lock={this.state.Lock}></PageTicket>)
			case 'ticket_vehicle':
				return (<PageVehicleTicket marche_attive={this.state.marche_attive} pathname={this.state.pathname} pageContent={this.state.pageContent} Lock={this.state.Lock}></PageVehicleTicket>)
			case 'SWContent':
				return (<PageDefault isAdminContent={this.state.userInfo.roles.indexOf("Reviewer") > -1} pathname={this.state.pathname} pageContent={this.state.pageContent}></PageDefault>)
			default:
				return (<PageDefault pathname={this.state.pathname} pageContent={this.state.pageContent}></PageDefault>)
		}
	}

  renderStateButton(){
    var statePos = common.object_value_in_array(ACTIONS_STATE_DECODE, "state", this.state.pageContent.review_state);
    if(statePos > -1){
      if(this.state.userInfo.roles.length > 0 && this.state.userInfo.roles.indexOf("Reviewer") === -1){
        var availableActions = this.state.pageContent["@components"]["workflow"]["transitions"];
        for (let i = 0; i < availableActions.length; i++) {
          var stateChanged = common.object_value_in_array(ACTIONS_STATE_DECODE, "action", availableActions[i]['@id'].substring(availableActions[i]['@id'].length,this.state.pageContent['@id'].length));
          if(stateChanged > -1){
            ACTIONS_STATE_DECODE[statePos].btn_color_class = ACTIONS_STATE_DECODE[stateChanged].btn_color_class;
            if(ACTIONS_STATE_DECODE[stateChanged].action.indexOf("revoca") > -1 ){
              ACTIONS_STATE_DECODE[statePos].title = "Pubblico";
              ACTIONS_STATE_DECODE[statePos].icon_class = "fa-users";
            }else{
              ACTIONS_STATE_DECODE[statePos].title = "Privato";
              ACTIONS_STATE_DECODE[statePos].icon_class = "fa-user-shield";
            }
          }
        }
      }
      return (
        <button type="button" className={ACTIONS_STATE_DECODE[statePos].btn_color_class + " btn"}>
          <i className={ACTIONS_STATE_DECODE[statePos].icon_class + " fas"}></i> { common.getTrad(ACTIONS_STATE_DECODE[statePos].title) }
        </button>
      )
    }
    return <></>
  }

  unLockTicket(e, urlTicket){
    // Per poter sbloccare il contenuto servirebbe il codice authorization di chi lo ha bloccato.
    // Passando da Plone con questa chiamata @@plone_lock_operations/force_unlock si forza questa operazione
    common.apiCall(this.state.pageContent['@id'] + "/@@plone_lock_operations/force_unlock", "POST", {}, true, false, "json")
    .then(response=>{
      if(response.error === false)
        this.checkTicketLocked(this.state.pageContent['@type'], urlTicket)
    });
  }

	render(props){
		if(this.state.render1){
      if(this.state.userInfo.roles.length > 0 && this.state.userInfo.roles !== undefined && this.state.userInfo.roles !== null){
        var parentPageContent = this.state.parentPageContent;
        if(this.state.pageContent !== undefined && this.state.pageContent['@type'].indexOf('swticket') <= -1){
          if(parentPageContent === undefined || parentPageContent['@components'] === undefined)
            parentPageContent = null;
    		  var redirectLink = common.calcTranslationRedirect(this.state.userInfo, this.state.pageContent, parentPageContent);
          if(redirectLink !== false)
			return (<Navigate from={this.state.pathname} to={"/" + redirectLink} />);
        }
      }

      var backurl = "";
      if(this.state.pageContent.parent){
        backurl = common.getUrlPath(this.state.pageContent.parent['@id']);
      }
			return(
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
					<div className="tab pane active">
						<div className="row">
							<div id="actions_page" className="mb-3 col">
								{this.state.pageContent.parent ?
									<div className="btn-group btn-group-sm mr-3" role="group" aria-label={common.getTrad("Torna indietro")}>
                    <button type="button" className="btn btn-light" onClick={() => { const history = createBrowserHistory(); common.goBack(history);}}>
											<i className="fas fa-chevron-left"></i> {common.getTrad("Torna indietro")}
										</button>
									</div>
								:
									<></>
								}

                {common.object_value_in_array(this.state.pageContent['@components']['actions'].object, "id", "edit") > -1 && this.state.Lock && this.state.Lock.locked && this.state.userInfo.id !== this.state.Lock.creator ?
                  <>
                    <div className="btn-group btn-group-sm float-right " role="group" aria-label={common.getTrad("Stampa pagina")}>
                      <a href="#">
                        <button type="button" onClick={this.stampaPagina} className="btn btn-light">
                          <i className="fas fa-print"></i> {common.getTrad("Stampa pagina")}
                        </button>
                      </a>
                    </div>
                    <div className="clear sw-alert alert alert-info mt-3">
                      {moment(this.state.Lock.time).diff(moment.unix(this.state.Lock.token.split(':').pop()), 'minutes') >= 5 ?
                        <>
                          {common.getTrad("Se sei sicuro che questo documento non è aperto in modifica da {{utente}}, puoi sbloccarlo cliccando su", {utente: this.state.Lock.fullname})}
                          &nbsp;
                          <span title={common.getTrad("Clicca per sbloccare il documento")} style={{cursor: 'pointer'}} className="text-block alert-link" onClick={(e, urlTicket) => this.unLockTicket(e, this.state.pageContent['@id'])}>
                            <u>{common.getTrad("Sblocca")}</u>
                          </span>.
                        </>
                      :
                        <>
                          {common.getTrad("Questo documento è stato bloccato in modifica da {{utente}}, attendi che venga salvato", {utente: this.state.Lock.fullname})}.
                        </>
                      }
                      &nbsp;
                      {common.getTrad("Poi ti verrà permesso di modificarlo.")}
                    </div>
                  </>
                :
                  <>
                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                      {Object.keys(this.state.tipologie_ticket_attive).length !== 0 && this.state.pageContent['@type'].indexOf('swticket') > -1 && this.state.userInfo.is_admin_ticket ?
                        <>
                          {this.state.pageContent.review_state.indexOf('closed') > -1 ?
                             <button type="button" className="btn btn-danger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{common.getTrad("Chiuso")} <i className="fas fa-caret-down"></i></button>
                          :
                           this.state.pageContent.review_state.indexOf('published') > -1 ?
                             <button type="button" className="btn btn-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{common.getTrad("In lavorazione")} <i className="fas fa-caret-down"></i></button>
                          :
                          this.state.pageContent.review_state.indexOf('incomplete') > -1 ?
                            <button type="button" className="btn btn-warning" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{common.getTrad("Incompleto")} <i className="fas fa-caret-down"></i></button>
                          :
                            <button type="button" className="btn btn-success" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{common.getTrad("Aperto")} <i className="fas fa-caret-down"></i></button>

                           }
                          <div className="dropdown-menu">
                          {this.state.pageContent.review_state.indexOf('closed') > -1 ?
                            <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state.indexOf('published') !== 1 ? 'published' : '');}}>{common.getTrad("In lavorazione")}</span>
                          :
                          this.state.pageContent.review_state.indexOf('incomplete') > -1 ?
                            <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state.indexOf('pending') !== 1 ? 'pending' : '');}}>{common.getTrad("Aperto")}</span>
                          :
                          this.state.pageContent.review_state.indexOf('pending') > -1 ?
                            <>
                              <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state.indexOf('published') !==  1 ? 'published' : '');}}>{common.getTrad("In lavorazione")}</span>
                              <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state.indexOf('incomplete') !== 1 ? 'incomplete' : '');}}>{common.getTrad("Incompleto")}</span>
                            </>
                          :
                            <>
                              <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state.indexOf('incomplete') !== 1 ? 'incomplete' : '');}}>{common.getTrad("Incompleto")}</span>
                              <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state.indexOf('closed') !== 1 ? 'closed' : '');}}>{common.getTrad("Chiuso")}</span>
                            </>
                           }
                          </div>
                          {this.state.userInfo.is_admin_ticket && this.state.pageContent.review_state.indexOf("pending") > -1 ?
                            <button className="btn btn-light" title={common.getTrad("Click per cambiare stato in Letto o da Leggere")} onClick={(e, state) => this.changeReviewStateRead(this.state.pageContent.review_state, true)}>
                              {this.state.pageContent.review_state.indexOf("visto") > -1 ?
                                <><i className="fas fa-check"></i> {common.getTrad("Segna come da leggere")}</>
                              :
                                <><i className="fas fa-check-double"></i> {common.getTrad("Segna come letta")}</>
                              }
                            </button>
                          :
                            <></>
                          }
                        </>
                      :
                        this.state.pageContent['@type'].indexOf('swticket') <= -1 && this.state.pageContent["@components"]["workflow"]["transitions"].length > 0 ?
                          <>
                            { this.renderStateButton() }
                            <WorkflowButton
                            id="wfbar"
                            handleClick={(e) => { this.changeReviewStateByTransition(e) }}
                            is_single={true}
                            transitions={ this.state.pageContent["@components"]["workflow"]["transitions"] }
                            add_to_button_class=""
                            disabled={ false }
                            />
                          </>
                        :
                          <></>
                      }

                      {common.object_value_in_array(this.state.pageContent['@components']['actions'].object, "id", "edit") > -1 && this.state.pageContent['@type'].indexOf('swticket') <= -1 ?
                        <Link to={common.getUrlPath(this.state.pathname + "/@@edit")}>
                          <button type="button" className="btn btn-light">
                            <i className="far fa-edit"></i> {common.getTrad("Modifica")}
                          </button>
                        </Link>
                      :
                        <></>
                      }

                      {Object.keys(this.state.tipologie_ticket_attive).length !== 0 && this.state.pageContent['@type'].indexOf('swticket') > -1 && common.object_value_in_array(this.state.pageContent['@components']['actions'].object, "id", "add") <= -1 && common.object_value_in_array(this.state.pageContent['@components']['actions'].object, "id", "edit") > -1 ?
                        <Link to={common.getUrlPath(this.state.pathname + "/@@editTicket")}>
                          <button type="button" className="btn btn-light">
                            <i className="far fa-edit"></i> {common.getTrad("Modifica")}
                          </button>
                        </Link>
                      :
                        <></>
                      }
                      {(Object.keys(this.state.tipologie_ticket_attive).length !== 0 && common.object_value_in_array(this.state.pageContent['@components']['actions'].object, "id", "delete") > -1 && this.state.pageContent['@type'].indexOf('swticket') > -1) || (this.state.userInfo.roles.indexOf("Reviewer") > -1 && this.state.pageContent['@type'] === 'swcontent') || (this.state.pageContent.parent.description.contentType === 'contact') ?
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                              bootbox.confirm(
                                {
                                  message: common.getTrad("L'azione cancellerà in modo definitivo il contenuto selezionato.<br><b>Si è sicuri di voler eliminare il contenuto?</b>"),
                                  buttons: {
                                    confirm: {
                                        label: common.getTrad('Elimina'),
                                        className: 'btn-danger'
                                    },
                                    cancel: {
                                        label: common.getTrad('Annulla'),
                                        className: 'btn-light'
                                    }
                                  },
                                  callback: (result) => {
                                    if(result)
                                      this.deleteContent();
                                  }
                                }
                              )
                            }
                          }>
                          <i className="far fa-trash-alt"></i> {common.getTrad("Elimina")}
                        </button>
                       :
                        <></>
                      }
                    </div>
                    <button type="button" onClick={this.stampaPagina} className="float-right btn btn-light">
                      <i className="fas fa-print"></i> {common.getTrad("Stampa pagina")}
                    </button>
                  </>
                }

              </div>
            </div>
						{this.renderSwitchSinglePage()}
					</div>
				</div>
			)
		}else{
			return(
				<></>
			)
		}
	}
}


export function getTicketRights(item, as_dict = true){
	// restituisce dizionario destinato al campo rights con data aggiornata
	// il cambio della data e' necessario per far gestire il cambio del contenuto
	// in plone altrimenti non verrebbe lanciato l'evento di modifica in caso di
	// cambio stato o salvataggiosenza modifiche
	var myrights = item.rights
	if ((myrights != '') && (myrights[0] == '{')){
		var mydict = JSON.parse(myrights);
	}
	else{
		var mydict = {};
	}
	mydict['last_edit_date'] = moment().format()
	mydict = JSON.stringify(mydict);
	if (as_dict){
		mydict = { rights: mydict }
  }
	return mydict
}


export default Page;
