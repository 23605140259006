import React from 'react';
const common = require('../common/common');
const env = require("../../config.js").default;

class FilterGroup extends React.Component{

	constructor(props){
		super(props);
		this.state = {};
		this.state.gbadaso = {};
		this.state.filtroBefore = (common.get_localStorage('filtriGroupTicket') !== undefined && common.get_localStorage('filtriGroupTicket') !== null && common.get_localStorage('filtriGroupTicket') !== '' ? common.get_localStorage('filtriGroupTicket') : '');

	}

	componentDidMount(){
		const $ = window.$;
		$("#select_gruppo").select2({
			escapeMarkup: function(m) { return m; },
			language: common.getLanguage(),
			placeholder: common.getTrad("Seleziona una Sister Company"),
		})

		$('.select_gruppo').on('select2:select keyup select2:unselect', (e) => {
			e.preventDefault();
			var	valoreFiltro = e.target.value !== 'all_groups' ? e.target.value : '';
			common.set_localStorage('filtriGroupTicket', valoreFiltro);
			if(common.get_localStorage('filtriGroupTicket') !== undefined && common.get_localStorage('filtriGroupTicket') !== null && common.get_localStorage('filtriGroupTicket') !== ''){
        valoreFiltro = common.get_localStorage('filtriGroupTicket');
      }
			var parametri = {
				filtro_csoft: valoreFiltro,
			}
			this.props.onClick("filtro_csoft", parametri);
			return false;
		});

		if(common.get_localStorage("sisterCompany") !== undefined && common.get_localStorage("sisterCompany") !== null)
      window.cc.sisterCompany = JSON.parse(common.get_localStorage("sisterCompany"));
 	}

	componentDidUpdate(){
    const $ = window.$;
    $('.select2').css('width', "100%");

    if(this.state.filtroBefore !== undefined && this.state.filtroBefore){
      var valoreFiltro = common.get_localStorage('filtriGroupTicket');
      $('.select_gruppo').val(valoreFiltro).trigger("change");
		}
  }

	render(props){
		return(
			<div className={(this.props.customClass !== undefined && this.props.customClass !== '' ? this.props.customClass : "col-xl-6 col-lg-6") +  " col-md-12 col-sm-12 col-xs-12 row-eq-height"}>
        <div className="col-12 p-0">
          <p>{common.getTrad("Filtra per Sister Company")}</p>
					{this.state.title}
          <div className="form-group">
            <select name="select_gruppo" className="form-control select_gruppo" id="select_gruppo">
							<option value="all_groups">{common.getTrad("Tutte le Sister Company")}</option>
							{window.cc.sisterCompany !== undefined && window.cc.sisterCompany.length > 0 ?
								window.cc.sisterCompany.map((index, key) => {
									return (<option key={key} value={index.value}>
										{index.CSOFT} - {index.RSOCI}
									</option>)
								})
							:
								<></>
							}
            </select>
          </div>
        </div>
      </div>
     )
  }
}

export default FilterGroup;
