import React from 'react';
import { Route, Routes, Link } from "react-router-dom";
import './SignUp.css';
import jQuery from 'jquery';
import bootbox from 'bootbox';
import 'jquery-validation/dist/jquery.validate.min.js';
import './wizard-form/jquery.steps.js';
import {wizardStepsInit} from './wizard-form/wizard-steps.js';
import AnagraficaIo from './AnagraficaIo';
import AnagraficaEmployee from './AnagraficaEmployee';
import Indirizzi from './Indirizzi';
import AccessoUtente from './AccessoUtente';
import CondizioniUtilizzo from './CondizioniUtilizzo';
import ResetPassword from '../LoginForm/ResetPassword';
// import RiepilogoForm from './RiepilogoForm';
import LoaderView from '../views/loader';
const common = require('../common/common');
const env = require("../../config.js").default;
const serializeObject = common.serializeObject();
window.jQuery = jQuery;
window.$ = jQuery;

try {
  require ('jquery-validation/dist/localization/messages_'+ common.getLanguage(false) +'.js');
} catch (err) {}

class SignUpIo extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.type = this.props.type;
    this.state.render1 = true;
    this.state.is_SignUpEnd = false;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state.renderResetPassword = false;
    this.state.subscriptions = {};
    this.state.subscriptionsLoaded = false;
  }

  componentDidMount(){
    this.initComponent();
  }

  componentDidUpdate(){
    this.initComponent();
  }

  validationForm() {
    const $ = window.$;
    $(".steps-validation").validate({
      ignore: 'input[type=hidden]', // ignore hidden fields
      errorClass: 'danger',
      successClass: 'success',
      highlight: function(element, errorClass) {
        $(element).removeClass(errorClass);
      },
      unhighlight: function(element, errorClass) {
        $(element).removeClass(errorClass);
      },
      errorPlacement: function(error, element) {
        error.insertAfter(element);
      },
      rules: {
        conferma_password: {
          equalTo: "#cpwd0"
        },
        conferma_email: {
          equalTo: this.state.type === "io" ? "#DCAPOACL" : "#DCAPOWUS"
        }
      }
    });
  }

  initComponent(){
    const $ = window.$;
    var formName = '';
    if(this.state.type === 'io')
      formName = "formIO";
    else
      formName = "formDipendente";
    $("#" + formName).on("keyup change",(e) => {
      common.set_localStorage(formName, JSON.stringify($("#" + formName).find(":input:not(:password)").serializeObject()));
    });

    wizardStepsInit();
    this.validationForm();

    $('#refresh').on('click', function (e) {
      e.preventDefault();
      window.location.reload();
    });

    $(document).keypress(function(e) {
      if(e.which === 13) {
        e.preventDefault();
        $('.actions ul li a[href="#next"]').click();
        if($(".steps li").hasClass("last current"))
          $('button[type="submit"]').click();
      }
      if(e.which === 32){
        $('.custom-checkbox input:focus[type="checkbox"]').click();
      }
    });

    $('.actions.clearfix ul li a[href="#finish"]').remove();

    $("#" + formName).on("keyup change",(e) => {
      common.set_localStorage(formName, JSON.stringify($("#" + formName).find(":input:not(:password)").serializeObject()));
    });
  }

  handleSubmit(e){
    e.preventDefault();
    const $ = window.$;
    var empty = true;
    if(this.state.type === 'io'){
      $('form input.required').each(function(e) {
        if($(this).val() !== ""){

          var checkboxs = $('.custom-checkbox input.required[type="checkbox"]').length;
          if ($('.custom-checkbox input.required[type="checkbox"]').filter(':checked').length === checkboxs) {
            empty = false;
            return false;
          } else {
            empty = true;
            return true;
          }
        }
        return empty;
      });

    }else{
      $('form input.required').each(function() {
      var emptyInput = $('input.required').not(function() {
        return this.value;
      }).length;

      if(emptyInput === 0 ) {
          empty = false;
          return false;
        } else {
          empty = true;
          return true;
        }
      });
    }
    if(empty === false){
      var data = {};
      var callRegistration = '';
      if(this.state.type === 'io'){
        callRegistration = "@sw-new-user";
        data = {
          "RCLIEACL": $('#RCLIEACL').val(),
          "CNAZIACL": $('#CNAZIACL').val(),
          "NPIVAACL": $('#NPIVAACL').val(),
          "RLERAACL" : $('#RLERAACL').val(),
          "CPROVACL": $('#CPROVACL').val(),
          "DLOCAACL": $('#DLOCAACL').val(),
          "DINDIACL": $('#DINDIACL').val(),
          "CAPCLACL": $('#CAPCLACL').val(),
          "RCLIEAIS": $('#CambiaIndirizzo').is(":checked") ? $('#RCLIEAIS').val() : $('#RCLIEACL').val(),
          "CPROVAIS": $('#CambiaIndirizzo').is(":checked") ? $('#CPROVAIS').val() : $('#CPROVACL').val(),
          "DLOCAAIS": $('#CambiaIndirizzo').is(":checked") ? $('#DLOCAAIS').val() : $('#DLOCAACL').val(),
          "DINDIAIS": $('#CambiaIndirizzo').is(":checked") ? $('#DINDIAIS').val() : $('#DINDIACL').val(),
          "CAPINAIS": $('#CambiaIndirizzo').is(":checked") ? $('#CAPINAIS').val() : $('#CAPCLACL').val(),
          "DCAPOACL": $('#DCAPOACL').val(),
          "cpwd0":    $('#cpwd0').val(),
          "CLIN1ACL": $('#CLIN1ACL').val(),
          "confirmURL": env.FRONTEND + "confirmEmail"
        };
      }else{
        callRegistration = "@sw-new-employee";
        data = {
          "CNAZIACL": $('#CNAZIACL').val(),
          "NPIVAACL": $('#NPIVAACL').val(),
          "RUSERWUS" : $('#RUSERWUS').val(),
          "DCAPOWUS": $('#DCAPOWUS').val(),
          "cpwd0":    $('#cpwd0').val(),
          "CLIN1WUS": $('#CLIN1WUS').val(),
          "confirmURL": env.FRONTEND + "confirmEmail"
        };
      }

      $("body #app .loadingDiv").fadeIn("fast");

      common.apiCall(env.BACKEND + callRegistration, "POST", data, false)
      .then(data => {
        if(data.error === false){
          $("body #app .loadingDiv").fadeOut();
          if(data.status === 200){
            var formName = '';
            if(callRegistration === '@sw-new-user')
            formName = "formIO";
            else
            formName = "formDipendente";
            common.remove_localStorage(formName);
          }

          this.setState({is_SignUpEnd: true, SignUpMessage: data});
        }
      });
    }
  }

  render(props){
    if(this.state.render1){
      if(window.cc.nazioni === '' || window.cc.nazioni === undefined){
          return(
            <div className="app-content content registrazione_io">
              <div className="vertical-center justify-content-md-center">
                <div className="sw-alert alert alert-warning">
                  {common.getTrad("Contenuto al momento non disponibile. Effettua un aggiornamento della pagina")}
                  <button type="button" id="refresh" className="btn-primary btn">{common.getTrad("Riprova")}</button>
                  <br/>{common.getTrad("Se la problematica persiste  contattare il supporto tecnico.")}
                  <hr/>
                  <a href="/" className="float-left">
                    <button type="button" className="btn btn-primary">{common.getTrad("Torna indietro")}</button>
                  </a>
                </div>
              </div>
            </div>
          )
      }else{
        return(
          <div className="app-content content registrazione_io ">
            <div className="content-wrapper">
              <div className="content-body">
                <section id="icon-tabs">
                  <div className="row">
                    <div id="content_registrazione_form">
                    {!this.state.is_SignUpEnd ?
                      <>
                        <h2 className="text-center mt-2 mb-2">
                        {this.state.type === 'io' ?
                          <>
                            {common.getTrad("Registrazione di un Operatore Indipendente")}
                          </>
                        :
                          <>
                            {common.getTrad("Registrazione di un Dipendente")}
                          </>
                        }
                        </h2>
                        <form noValidate action="#" method="post" id={this.state.type === 'io' ? "formIO" : "formDipendente"} className="steps-validation wizard-circle" onSubmit={this.handleSubmit}>
                          {this.state.type === 'io' ?
                            <AnagraficaIo type={this.state.type}></AnagraficaIo>
                          :
                            <AnagraficaEmployee type={this.state.type}></AnagraficaEmployee>
                          }

                          {this.state.type === 'io' ? <Indirizzi render={this.state.render1}></Indirizzi> : <></> }

                          <AccessoUtente type={this.state.type} lingue={window.cc.languages}></AccessoUtente>
                          {this.state.type === 'io' ?
                            <CondizioniUtilizzo type={this.state.type}></CondizioniUtilizzo>
                          :
                            <></>
                          }

                        </form>
                        <div className="col-xl-12 mb-4">
                          <div className="col-xl-12">
                            <button type="button" className="btn btn-light" onClick={
                              () => {
                                bootbox.confirm(
                                  {
                                    message: common.getTrad("L'azione cancellerà i dati appena inseriti.<br><b>Si è sicuri di voler procedere?</b>"),
                                    buttons: {
                                      confirm: {
                                          label: common.getTrad('Si'),
                                          className: 'btn-danger'
                                      },
                                      cancel: {
                                          label: common.getTrad('No'),
                                          className: 'btn-light'
                                      }
                                    },
                                    callback: (result) => {
                                      if(result){
                                        var formName = '';
                                        if(this.state.type === 'io')
                                          formName = "formIO";
                                        else
                                          formName = "formDipendente";
                                        common.remove_localStorage(formName);
                                        window.location.href = env.FRONTEND;
                                      }
                                    }
                                  }
                                )
                              }
                            }>{common.getTrad("Annulla")}</button>
                          </div>
                        </div>
                        {this.state.type === 'io' ?
                          <div className="container">
                            <div className="card-deck text-center pb-3">
                              <div className="pricing-header pt-2 pb-3 mx-auto text-center">
                                <a href={env.FRONTEND + "rmi-services"} title="Costi del servizio" className="btn bottone_default">
                                  {common.getTrad("Costi del servizio")}&nbsp;
                                  <i className="fas fa-plus"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        :
                          <></>
                        }
                      </>
                    :
                      <div className="col-xl-12 my-4">
                        <div className="col-xl-12">
                          {this.state.SignUpMessage && this.state.SignUpMessage.errorCode === 403 ?
                            <>
                              {this.state.SignUpMessage.additionalMessage ?
                                <>
                                  <ResetPassword></ResetPassword>
                                  <h2 className="text-center">{common.getTrad("Errore in fase di registazione")}</h2>
                                  <p className="text-center" dangerouslySetInnerHTML={{ __html: common.getTrad("<b>ATTENZIONE!</b><br/>Controlla la tua casella e-mail, verifica se hai ricevuto la conferma dell'attivazione dell' account.<br/>Se non ti ricordi la password richiedi il reset cliccando sul link sotto.")}} />
                                  <p className="mt-3 mb-4 text-center">
                                    <Link to="/#" data-toggle="modal" data-target="#reset_password">{common.getTrad("Hai dimenticato la password?")}</Link>
                                  </p>
                                </>
                              :
                                <p className="text-center"><b>{common.getTrad("ATTENZIONE!")}</b></p>
                              }
                              <br/>
                              <p className="text-center">{common.getTrad("Di seguito il messaggio di errore")}: <b>{this.state.SignUpMessage.message}</b></p>
                            </>
                          :
                            <>
                              <h2 className="text-center">{common.getTrad("Registrazione completata")}</h2>
                              <p className="text-center">{this.state.SignUpMessage.message}</p>
                            </>
                          }
                          <Link className="float-right mb-4" to="/">
                            <button type="button" className="btn btn-primary">{common.getTrad("Torna alla Login")}</button>
                          </Link>
                        </div>
                      </div>
                    }
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          )
        }
      }
    else {
      return(
        <Routes>
          <Route path="/" element={<LoaderView />} />
        </Routes>
      )
    }
  }

}

export default SignUpIo;
