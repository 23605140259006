import React from 'react';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';


import './promo.css';

const common = require('../common/common');
const env = require("../../config.js").default;


class PromoMenu extends React.Component {
    constructor(props){
        super(props);
        this.state={};
        
        this.state.filter = {};
        this.state.promos = [];
        this.nPage = 0;
        this.prevScrollTop = 0;
        this.promoMenuRef = React.createRef();
        this.get_promo();

    }
    
    get_promo(){
        const $ = window.$
        const length = 25
        let data = {
            length: length,
            start: length * this.nPage
        }
        common.apiCall(env.SWAN + "get_user_promo/", "GET", data, false, false, "json", true).then((response) => {
            if(response.error === undefined || response.error === null || response.error === false){
                //Se non ci sono risultati viene tolto l'evento onScroll
                if(response.data.length > 0 ){
                    this.removeScrollEvent()
                }
                this.setState({ promos: [...this.state.promos, ...response.data] }) //another array
            }
        });
        this.nPage += 1
    }
    
    forceOpening() {
        const $ = window.$;
        let forced = common.get_localStorage("promoForceOpening");
        if (forced !== '1') {
            common.set_localStorage("promoForceOpening", "1");
            $(".sw-promoButton:visible").click();
        }
        if (this.state.promos.length > 0) {
            $(".sw-promoButton span").addClass("blink");
        } else {
            $(".sw-promoButton span").removeClass("blink");
        }
    }
    
    handleScroll = (e) => {
        const $ = window.$;
        const container = e.target;
        const scrollPosition = container.scrollHeight - container.clientHeight;
        const currentScroll = container.scrollTop;
        const scrollPercentage = (currentScroll / scrollPosition) * 100;
        const scrollBottom = container.scrollHeight - currentScroll - container.clientHeight;

        if (scrollBottom <= 0 && currentScroll > this.prevScrollTop && scrollPercentage >= 80) {
            // Chiamiamo la funzione desiderata quando la scrollbar arriva all'80% del contenuto
            $(".loadingDivPromo").fadeIn("slow");
            this.get_promo()
            $(".loadingDivPromo").fadeOut()
            this.prevScrollTop = currentScroll;
        }
    }
    
    removeScrollEvent = () => {
        this.promoMenuRef.current?.removeEventListener('scroll', this.handleScroll);
    }

    handlePromoMenuCloseButtonClick = (e) =>{
        e.preventDefault();
        const $ = window.$;
        $("#promoMenuCloseButton").hide()
        $("#promoMenu").toggleClass("open");

        //Rimozione ombra
        var css;
        if ($("#promoMenu").hasClass("open")) {
            css = "0px 1px 4px 2px rgb(0 0 0 / 30%)"
        } else {
            css = 'none'
        }

        $("#promoMenu").css({ '-webkit-box-shadow': css, '-moz-box-shadow': css, 'box-shadow': css });
        return false;
    }

    render(){
        return(
            <div id="promoMenu" onScroll={this.handleScroll} ref={this.promoMenuRef}>
                <div className="promoHeader">
                    <button type="button" id="promoMenuCloseButton" class="close" onClick={this.handlePromoMenuCloseButtonClick} >
                        <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="promoMenuTitle">{common.getTrad("Promozioni attive")}</h4>
                </div>
                <div className="promoBody">
                {
                    (() => {
                        if(this.state.promos.length > 0){
                            this.forceOpening()
                            return this.state.promos.map((value, index) => {
                                return <SinglePromo key={index} promoData={value}/>;
                            })
                        } else {
                            return (
                                <div className="promoBodyEmptyMessage animated faster fadeIn">
                                    {common.getTrad("Nessuna promozione presente")}
                                </div>
                            )
                        }
                    })()
                }
                </div>
                <div className="promoFooter">
                    <div className="loadingDivPromo" style={{ display: "none" }}>
                        <div>
                            <i className="fas fa-spinner fa-spin fa-2x"></i>
                            <div className="w-50"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class SinglePromo extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
        this.state.promoData = this.props.promoData;
        this.state.bodyCollapsed = true;
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({ promoData: nextProps.promoData });
        }
    }

    messageClean(data){
        let mex = '';
        if (data.TRECO=="E"){
            if (data.SAGG1 != undefined && parseInt(data.SAGG1) > 0){
                mex += '-' + data.SAGG1 + '% ' + common.getTrad('su tutti gli articoli della categoria per gli ordini normali');
            }
            if (data.SUAG1 != undefined && parseInt(data.SUAG1) > 0 ){
                if (mex)
                    mex+='\n'
                mex += '-' + data.SUAG1 + '% ' + common.getTrad('su tutti gli articoli della categoria per gli ordini urgenti');
            }
        }
        if(mex === undefined || mex === null)
            return ""
        
        mex = mex.replace("\n\r", "\n");    
        mex = mex.replace("\r\n", "\n");    
        mex = mex.replace("\r", "\n");    
        mex = mex.split("\n").map(function(item, index){
            return(
                <>
                    {item}
                    <br/>
                </>
            )
        }); 
        return mex;   
    }
    titleClean(data){
        let mex = "";
        if (data.TRECO == "E"){
            let dtab1
            if (data.CCLSC == '' && data.CFASC == '')
                dtab1 = ''
            else
                dtab1 = data.DTAB1ATB
            mex = common.getTrad("Sconto extra") + " " + dtab1;
        }
            
        if (data.solo_prezzi_netti == "S")
            mex = common.getTrad('Sono presenti prezzi dedicati per alcuni articoli');

        
        if(mex === undefined || mex === null)
            return ""
        
        mex = mex.replace("\n\r", "\n");    
        mex = mex.replace("\r\n", "\n");    
        mex = mex.replace("\r", "\n");    
        mex = mex.split("\n").map(function(item, index){
            return(
                <>
                    <h6>{item}</h6>
                </>
            )
        }); 
        return mex;   
    }
    
    handleShowParts() {
        let data = {
            CARTI:this.CARTI,
            CFASC:this.CFASC,
            CLINE:this.CLINE,
            ZFORN:this.ZFORN,
        }
        common.apiCall(env.SWAN + "listino/", "GET", data, false, false, "json", true)
    }
    setURL(data) {
        let CARTI = ""
        let CFASC = ""
        let CCLSC = ""
        let solo_prezzi_netti = ""
        if (data.TRECO=="E"){
            CARTI = data.CARTI
            CFASC = data.CFASC
            CCLSC = data.CCLSC
        } 
        if (data.solo_prezzi_netti == "S")
            solo_prezzi_netti = "&solo_prezzi_netti=S"
        var link = env.SWAN + "lst/" + data.ZFORN + "?CARTI=" + CARTI + "&id_marca=" + data.ZFORN + "&cfasc=" + CFASC + "&cline=" + data.CLINE + "&cclsc=" + CCLSC + solo_prezzi_netti
        return link
    }

    didUpdateAndMoutCommonAction = () =>{

    }
    
    componentDidMount = () => {
        this.didUpdateAndMoutCommonAction();
    }
    
    componentDidUpdate = ()=>{
        this.didUpdateAndMoutCommonAction();
    }


    render() {
        if ((this.state.promoData)) {
            if (this.state.promoData.TRECO == "E" || this.state.promoData.solo_prezzi_netti== "S")
            return (
                <a className={"promoMenuButton"} href={this.setURL(this.state.promoData)}>
                <div key={this.key} className={"siglePromo animated faster fadeIn "} id={"promo_" + this.state.promoData.ID}>
                    <h6 className="promoTitle">
                        {this.titleClean(this.state.promoData)}
                    </h6>
                    <div className={"promoBody " + (this.state.bodyCollapsed ? "collapsed" : "")} id={"promoBody_" + this.state.promoData.ID}>
                        <div className="promoBodyText">
                            {this.messageClean(this.state.promoData)}
                                <small class="datatableSmall">{this.state.promoData.solo_prezzi_netti != "S" ? ((this.state.promoData.UINIZ ? (common.getTrad("Valido dal") + " " + moment(this.state.promoData.UINIZ).format('L')) : "") + " " + (this.state.promoData.USCAD ? (common.getTrad("al") + " " + moment(this.state.promoData.USCAD).format('L')) : "")) : ""}</small>
                        </div>
                    </div>
                </div>
                </a>
            )
        } else {
            return (<></>)
        }
    }
}

export default PromoMenu;
