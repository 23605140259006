import React from 'react';
const common = require('../common/common');

class PageContact extends React.Component {

  constructor(props){
    super(props);
    this.state = {};
    this.state.pageContent = this.props.pageContent;
    this.state.pathname = this.props.pathname;
   }
  render(props){
    var description = JSON.parse(this.props.pageContent.description);
    return (
      <div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 content_testo">
            <h1>{this.state.pageContent.title}</h1>
          </div>
        </div>

        <div className="row elenco-voci-dettaglio justify-content-md-center">
          <div className="col">
            <table className="table table-striped table-bordered dt-responsive nowrap mb-5" style={{width: '100%'}}>
              <tbody>
                <tr>
                  <td className="voce_dati"><b>{common.getTrad("Nome")}</b></td>
                  <td className="valore_dati">{description.name}</td>
                </tr>
                <tr>
                  <td className="voce_dati"><b>{common.getTrad("Cognome")}</b></td>
                  <td className="valore_dati">{description.surname}</td>
                </tr>
                {description.department ?
                  <tr>
                    <td className="voce_dati"><b>{common.getTrad("Reparto")}</b></td>
                    <td className="valore_dati">{description.department}</td>
                  </tr>
                :
                  <></>
                }
                {description.email ?
                  <tr>
                    <td className="voce_dati"><b>{common.getTrad("Email")}</b></td>
                    <td className="valore_dati"><a href={"mailto:" + description.email}>{description.email}</a></td>
                  </tr>
                :
                  <></>
                }
                {description.phone ?
                  <tr>
                    <td className="voce_dati"><b>{common.getTrad("Telefono")}</b></td>
                    <td className="valore_dati"><a href={"tel:"+description.phone}>{description.phone}</a></td>
                  </tr>
                :
                  <></>
                }
                {description.phone2 ?
                  <tr>
                    <td className="voce_dati"><b>{common.getTrad("Cellulare")}</b></td>
                    <td className="valore_dati"><a href={"tel:"+description.phone2}>{description.phone2}</a></td>
                  </tr>
                :
                  <></>
                }
                {description.address ?
                  <tr>
                    <td className="voce_dati"><b>{common.getTrad("Indirizzo, Sede o Località")}</b></td>
                    <td className="valore_dati">{description.address}</td>
                  </tr>
                :
                  <></>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}
export default PageContact;
