import React from 'react';
import moment from 'moment';
import TicketVehicleTable from '../Tabelle/TicketVehicleTable';
import '../../css/ticket.css';
const common = require('../common/common');
const env = require("../../config.js").default;

class PageVehicleTicket extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.keyComments = -1;
    this.state.userInfo = {};
    this.state.userInfo.roles = [];
    this.state.userInfo.userInfo = {};
    this.state.pageContent = {};
    this.state.Lock = {};
    this.state.pageContent.description = [];
    this.state.pathname = "";
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state.tipologie_ticket_attive = common.getTicketTypesByBrand();
    this.state.googleCloudBucketPath = (window.cc.frontendCustomization.bucket_google + 'support-tools/ticket/');
    this.state.get_veicolo = false;
    this.state.pagina = {
			pagina_attuale:  1,
			tipo_ordinamento: 'modified', //Capire tipo ordinamento iniziale
			verso_ordinamento: -1, // Idem come sopra
			filtra_risultati: true,
			batchingAdvanced: false,
      testo_cercato: '',
		};
  }

  UNSAFE_componentWillMount(props){
    try{
      this.props.pageContent.description = JSON.parse(this.props.pageContent.description);
    }catch(e){
      this.props.pageContent.description = {};
      return ""
    }

    document.getElementById('body').className='content_page content_ticket';
    this.setState({Lock :  this.props.Lock});

    this.setState({pageContent :  this.props.pageContent});
    this.setState({pathname : this.props.pathname});
    common.getUserInfo().then(userInfo =>{
      this.setState({userInfo: userInfo});
    })

    var brand = this.props.pageContent.subjects.find(a =>a.includes("sw-brand"));
    var tipologia = this.props.pageContent.subjects.find(a =>!a.includes("sw-"));
    if(brand !== undefined && brand.length > 0){
      brand = brand.split('sw-brand-')[1];
    }else{
      brand = this.props.marche_attive[0].ZFORN;
    }

    this.setState({marcaDefault: brand})

    if(common.get_localStorage("sisterCompany") !== undefined && common.get_localStorage("sisterCompany") !== null)
      window.cc.sisterCompany = JSON.parse(common.get_localStorage("sisterCompany"));

    if(window.cc.sisterCompany !== undefined && window.cc.sisterCompany.length > 0){
      var csoft_gruppo = this.props.pageContent.subjects.find(a =>a.includes("sw-csoft"));
      var numSisterCompany = common.object_value_in_array(window.cc.sisterCompany, 'value', csoft_gruppo);
      var csoftGroup = window.cc.sisterCompany[numSisterCompany].CSOFT + ' - ' + window.cc.sisterCompany[numSisterCompany].RSOCI;
      this.setState({csoftGroup: csoftGroup});
    }

    this.setState({tipoTicket: tipologia});
    const $ = window.$;
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }

  componentDidMount(){
    const $ = window.$;
    const campiTicket = this.state.tipologie_ticket_attive[this.state.tipoTicket];
    let updatedCampiTicket = campiTicket;

    if (this.state.marcaDefault !== undefined) {
      updatedCampiTicket = this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket];
    }

    if (updatedCampiTicket.campi.some(index => index.id === 'file')) {
      this.setState({ googleCloudBucketPath: false });
    }
      
    $(window).resize(function() {
      var visibleTables = $.fn.dataTable.tables({ visible: true, api: true });
      if (visibleTables && visibleTables.length > 0) {
        visibleTables.columns.adjust();
      }
    });
    if(!this.state.get_veicolo){
      this.setState({get_veicolo: true})
      var pageContent = this.state.pageContent;
      common.apiCall(env.BACKEND + "@get-veicolo?telaio=" + this.state.pageContent.vin_vehicle + "&marca=" + this.state.marcaDefault, "GET", {}, true)
      .then(response=>{
        pageContent.update_data = new Date();
        if (!response.errorCode) {
          if (this.state.pageContent.veicolo === undefined || this.state.pageContent.veicolo === null){
            pageContent.veicolo = response.veicolo;
          } else {
            let keys = Object.keys(response.veicolo);
            for(let i in keys){
              let key = keys[i];
              if (pageContent.veicolo[key] === undefined || pageContent.veicolo[key] === null || pageContent.veicolo[key] === '')
                pageContent.veicolo[key] = response.veicolo[key];
            }
          }
          this.setState({pageContent: pageContent})
        }
      });
    }

  }

  getTableWarranty(){
    const $ = window.$;
    const cwColPosition = [
      "cwCAVGA",
      "cwUDOCU",
      "cwCCAUS",
      "cwLMANO",
      "cwNGARA",
      "cwUTRAS"
    ];

    if($.fn.dataTable.isDataTable("#table_warranty"))
      return false;

    var login, headers={};

      login = common.getLocalLoginToken();
      if(login === false){
        return false;
      }

    headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': common.getLanguage(true),
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
    };

    headers.authorization = "Bearer " + login;

    var apStr, apDim, apNum;
    const ZERI_NUMERO_DOCUMENTO = "000000"
    $('#table_warranty').DataTable( {
      "order": [[ cwColPosition.indexOf("cwUDOCU"), "desc" ]],
      "deferRender": true,
      "scroller": true,
      "serverSide": true,
      "scrollY": '40vh',
      "bAutoWidth": false,
      "paging": false,
      "searching": false,
      "info": false,
      "pageLength": env.TABLE_PAGE_LENGTH,
      "bScrollCollapse": false,
      "processing": true,
      "language": {
				"url": env.STATIC + "lib/datatables/i18n/" + common.getLanguage() + ".lang"
			},
      "ajax": {
        url: env.BACKEND + "@sw-get-history-vehicle-warranty",
        dataType: 'json',
        headers: headers,
        dataSrc: "",
        data: (data) => {
          data.NTELA = this.state.pageContent.vin_vehicle;
          var order = "";
          var orderSettings = $('#table_warranty').DataTable().order();
          for (var i=0; i<orderSettings.length; i++){
            if(order !== "")
              order = order + ",";
            var orderColumn = $('#table_warranty').DataTable().column(orderSettings[i][0]).dataSrc();
            order = order + "{{" + orderColumn + "}} " + orderSettings[i][1].toUpperCase();
          }
          data.OrderPage = order;
        }
      },
      "columns": [
        {
          "title": common.getTrad("Stato"),
          "targets": cwColPosition.indexOf("cwCAVGA"),
          "data": "CAVGA",
          "width": "40%",
          "orderable": true,
          "render": function ( data, type, row) {
            if (data==="00") {
              return  common.getTrad("Garanzia incompleta");
            } else if (data==="03") {
              return  common.getTrad("Garanzia pronta per trasmissione");
            } else if (data === "06") {
              return  common.getTrad("Garanzia da terminare");
            } else if (data==="05" || data==="10") {
              return  common.getTrad("Garanzia trasmessa");
            } else if (data>="11" && data<="59") {
              return  common.getTrad("Garanzia da correggere");
            } else if (data>="60" && data<="69") {
              return  common.getTrad("Garanzia rifiutata dalla casa");
            } else if (data==="70") {
              return  common.getTrad("Garanzia ricevuta in attesa di elaborazione");
            } else if (data>="71" && data<="89") {
              if (row.CREPA >= 10 && row.CREPA <= 19) {
                return common.getTrad("Garanzia accettata dalla casa e richiesto invio pezzi");
              } else if (row.CREPA >= 20 && row.CREPA <= 29) {
                return common.getTrad("Garanzia accettata dalla casa e pezzi inviati");
              } else {
                return common.getTrad("Garanzia accettata dalla casa");
              }
            } else if (data>="90" && data<="99") {
              if (row.CREPA >= 10 && row.CREPA <= 19) {
                return common.getTrad("Garanzia rimborsata dalla casa e richiesto invio pezzi");
              } else if (row.CREPA >= 20 && row.CREPA <= 29) {
                return common.getTrad("Garanzia rimborsata dalla casa e pezzi inviati");
              } else {
                return common.getTrad("Garanzia rimborsata dalla casa");
              }
            } else {
              return data;
            }
          }
        },
        {
          "title": common.getTrad("Data Garanzia"),
          "targets": cwColPosition.indexOf("cwUDOCU"),
          "data": "UDOCU",
          "type": "date",
          "orderable": true,
          "render": function ( data, type, row) {
            if(data !== null){
              return moment(data).format('L')
            }else{
              return ""
            }
          }
        },
        {
          "title": common.getTrad("N. Garanzia"),
          "targets": cwColPosition.indexOf("cwNGARA"),
          "data": "NGARA",
          "orderable": true,
          "type": "num",
          "render": function ( data, type, row) {
            apStr = row.NDOCU+""
            apDim = apStr.length
            apNum = ZERI_NUMERO_DOCUMENTO+row.NDOCU
            apNum.substr(apDim,ZERI_NUMERO_DOCUMENTO.length)
            return row.TNDOC+apNum;
          }
        },
        {
          "title": common.getTrad("Causale"),
          "targets": cwColPosition.indexOf("cwCCAUS"),
          "data": "CCAUS",
          "orderable": true,
        },
        {
          "title": common.getTrad("Q.tà Manodopera"),
          "targets": cwColPosition.indexOf("cwLMANO"),
          "data": "LMANO",
          "orderable": true,
          "render": function ( data, type, row) {
            if(data !== null){
              return data
            }else{
              return 0
            }
          }
        },
        {
          "title": common.getTrad("Data Trasm."),
          "targets": cwColPosition.indexOf("cwUTRAS"),
          "data": "UTRAS",
          "type": "date",
          "orderable": true,
          "render": function ( data, type, row) {
            if(data !== null){
              return moment(data).format('L')
            }else{
              return ""
            }
          }
        },
      ],
    })
  }

  getTableService(){
    const $ = window.$;
    const ctColPosition = [
  		"ctNSCHE",
  		"ctUDOCU",
  		"ctDCAUS",
  		"ctNKMPE",
  		"ctNTAGL",
  		"ctCRIVE",
  		"ctDRIVE",
  		"ctDDOCU",
  	];

    var login, headers={};

    login = common.getLocalLoginToken();
    if(login === false){
      return false;
    }

    headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': common.getLanguage(true),
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
    };

    headers.authorization = "Bearer " + login;

    if($.fn.dataTable.isDataTable("#table_service"))
      return false;


    var apStr, apDim, apNum;
    const ZERI_NUMERO_DOCUMENTO = "000000";
    $('#table_service').DataTable({
      "order": [[ ctColPosition.indexOf("ctUDOCU"), "desc" ]],
      "deferRender": true,
      "scroller": true,
      "serverSide": true,
      "scrollY": '40vh',
      "bAutoWidth": false,
      "paging": false,
      "searching": false,
      "info": false,
      "pageLength": env.TABLE_PAGE_LENGTH,
      "bScrollCollapse": false,
      "processing": true,
      "language": {
				"url": env.STATIC + "lib/datatables/i18n/" + common.getLanguage() + ".lang"
			},
      "ajax": {
        url: env.BACKEND + "@sw-get-history-vehicle-service",
        dataType: 'json',
        headers: headers,
        dataSrc: "",
        data: (data) => {
          data.NTELA = this.state.pageContent.vin_vehicle;
          var order = "";
          var orderSettings = $('#table_service').DataTable().order();
          for (var i=0; i<orderSettings.length; i++){
            if(order !== "")
              order = order + ",";
            var orderColumn = $('#table_service').DataTable().column(orderSettings[i][0]).dataSrc();
            order = order + "{{" + orderColumn + "}} " + orderSettings[i][1].toUpperCase();
          }
          data.OrderPage = order;
        },
      },
      "columns": [
        {
          "title": common.getTrad("Rif.Doc."),
          "data": "TNDOC",
          "targets": ctColPosition.indexOf("ctNSCHE"),
          "orderable": true,
          "width":"80px",
          "render": function ( data, type, row ) {
            apStr = row.NDOCU+"";
            apDim = apStr.length;
            apNum = (ZERI_NUMERO_DOCUMENTO+row.NDOCU)
            apNum = apNum.substr(apDim,ZERI_NUMERO_DOCUMENTO.length);
            return row.TNDOC+apNum;
          }
        },
        {
          "title": common.getTrad("Data Interv."),
          "targets": ctColPosition.indexOf("ctUDOCU"),
          "data": "UDOCU",
          "orderable": true,
          "width":"50px",
          "type": "date",
          "render": function ( data, type, row ) {
            if (data===null)
              return data
            else
              return moment(data).format('L');
          },
        },
        {
          "title": common.getTrad("Causale"),
          "data": "DCAUS",
          "targets": ctColPosition.indexOf("ctDCAUS"),
          "orderable": true,
          "width":"100px",
        },
        {
          "title": common.getTrad("Km"),
          "data": "NKMPE",
          "targets": ctColPosition.indexOf("ctNKMPE"),
          "orderable": true,
          "width":"80px",
          "class":"col-align-right",
          "type": "num",
        },
        {
          "title": common.getTrad("N.Tagl."),
          "data": "NTAGL",
          "targets": ctColPosition.indexOf("ctNTAGL"),
          "orderable": true,
          "width":"30px",
          "class":"col-align-right",
          "type": "num"
        },
        {
          "title": common.getTrad("Dealer"),
          "data": "DRIVE",
          "targets": ctColPosition.indexOf("ctDRIVE"),
          "width":"120px",
          "render": function ( data, type, row ) {
            let ret = ""
            if(row["CCORR"] !== undefined && row["CCORR"] !== null && row["CCORR"].length > 0)
              ret += "(" + row["CCORR"] + ")";
            ret += " <small>" + data + "</small>";
            return ret;
          }
        },
        {
          "title": common.getTrad("Note"),
          "targets": ctColPosition.indexOf("ctDDOCU"),
          "data": "DDOCU",
          "width":"120px",
        },
      ],
    })
  }

  getTableActivation(){
    const $ = window.$;
    const caColPosition = [
      "caUIMMA",
      "caUMOVI",
      "caNPRTR",
      "caRACQU",
      "caNTARG",
      "caTRITI",
      "caUTRAS",
      "caCAVVE"
    ];


    if($.fn.dataTable.isDataTable("#table_activation"))
      return false;

    var login, headers={};

    login = common.getLocalLoginToken();
    if(login === false){
      return false;
    }

    headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': common.getLanguage(true),
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
    };

    headers.authorization = "Bearer " + login;

    var apStr, apDim, apNum;
    const NPRVE_app = "000000";
    const NPRTR_app = "00"
    $('#table_activation').DataTable({
      "order": [[ caColPosition.indexOf("caUIMMA"), "desc" ]],
      "deferRender": true,
      "scroller": true,
      "serverSide": true,
      "scrollY": '40vh',
      "bAutoWidth": false,
      "paging": false,
      "searching": false,
      "info": false,
      "pageLength": env.TABLE_PAGE_LENGTH,
      "bScrollCollapse": false,
      "processing": true,
      "language": {
				"url": env.STATIC + "lib/datatables/i18n/" + common.getLanguage() + ".lang"
			},
      "ajax": {
        url: env.BACKEND + "@sw-get-history-vehicle-activation",
        dataType: 'json',
        headers: headers,
        dataSrc: "",
        data: (data) => {
          data.NTELA = this.state.pageContent.vin_vehicle;
          var order = "";
          var orderSettings = $('#table_activation').DataTable().order();
          for (var i=0; i<orderSettings.length; i++){
            if(order !== "")
              order = order + ",";
            var orderColumn = $('#table_activation').DataTable().column(orderSettings[i][0]).dataSrc();
            order = order + "{{" + orderColumn + "}} " + orderSettings[i][1].toUpperCase();
          }
          data.OrderPage = order;
        },
      },
      "columns": [
        {
          "title": common.getTrad("Data Imm."),
          "targets": caColPosition.indexOf("caUIMMA"),
          "data": "UIMMA",
          "type": "date",
          "orderable": true,
          "render": function ( data, type, row) {
            if(data !== null){
              return moment(data).format('L')
            }else{
              return ""
            }
          }
        },
        {
          "title": common.getTrad("Data cambio proprietà"),
          "data": "UMOVI",
          "targets": caColPosition.indexOf("caUMOVI"),
          "orderable": true,
          "type": "date",
          "render": function ( data, type, row) {
            if(data !== null){
              return moment(data).format('L')
            }else{
              return ""
            }
          }
        },
        {
          "title": common.getTrad("N. Documento"),
          "targets": caColPosition.indexOf("caNPRTR"),
          "data": "NPRTR",
          "type": "num",
          "orderable": true,
          "render": function ( data, type, row) {
            apStr = row.NPRVE+"";
            apDim = apStr.length;
            apNum = (NPRVE_app+apStr);
            var ret = apNum.substr(apDim,NPRVE_app.length);

            apStr = data+"";
            apDim = apStr.length;
            apNum = (NPRTR_app+apStr);
            var nrBetanet = ret + apNum.substr(apDim,NPRTR_app.length);

            return nrBetanet;
          }
        },
        {
          "title": common.getTrad("Proprietario"),
          "targets": caColPosition.indexOf("caRACQU"),
          "orderable": true,
          "data": "RACQU"
        },
        {
          "title": common.getTrad("Targa"),
          "targets": caColPosition.indexOf("caNTARG"),
          "orderable": true,
          "data": "NTARG"
        },
        {
          "title": common.getTrad("Tipo"),
          "targets": caColPosition.indexOf("caTRITI"),
          "orderable": true,
          "data": "TRITI",
          "render": function ( data, type, row) {
            if(data==="A") {
              return common.getTrad("Nuova");
            } else if(data==="C") {
              return common.getTrad("Cambio Prop.");
            } else if(data==="E") {
              return common.getTrad("Estensione");
            } else if(data==="R") {
              return common.getTrad("Rottamazione");
            } else {
              return data;
            }
          }
        },
        {
          "title": common.getTrad("Stato"),
          "targets": caColPosition.indexOf("caCAVVE"),
          "orderable": true,
          "data": "CAVVE",
          "render": function ( data, type, row) {
            if (data==="00") {
              return  common.getTrad("Attivazione incompleta");
            } else if (data==="03" || data==="04") {
              return  common.getTrad("Attivazione pronta per trasmissione");
            } else if (data==="05" || data==="10") {
              return  common.getTrad("Attivazione trasmessa");
            } else if (data>="11" && data<="69") {
              return  common.getTrad("Attivazione da correggere");
            } else if (data>="70" && data<="89") {
              return  common.getTrad("Attivazione accettata dalla casa");
            } else {
              return data;
            }
          }
        },
        {
          "title": common.getTrad("Data Trasm."),
          "targets": caColPosition.indexOf("caUTRAS"),
          "orderable": true,
          "data": "UTRAS",
          "type": "date",
          "render": function ( data, type, row) {
            if(data !== null){
              return moment(data).format('L')
            }else{
              return ""
            }
          }
        },
      ],
    })
  }

  getTableTicket(statoPaginaNext){
    const data = {
      fullobjects: 1,
      swhistory: 1,
      portal_type: "swticket_vehicle",
      vin_vehicle: this.state.pageContent.vin_vehicle,
    }

    var pageStart = env.TABLE_PAGE_LENGTH  * (statoPaginaNext.pagina_attuale - 1);
		if(pageStart === 1)
			pageStart --;

    data.b_size = env.TABLE_PAGE_LENGTH;
    data.b_start = pageStart;
		data.sort_on = statoPaginaNext.tipo_ordinamento;
		data.sort_order = statoPaginaNext.verso_ordinamento === -1 ? "reverse" : "ascending";
    data.SearchableText = statoPaginaNext.testo_cercato !== undefined && statoPaginaNext.testo_cercato.length > 0 ? "*" + statoPaginaNext.testo_cercato + "*" : "";
    if (statoPaginaNext.testo_cercato !== undefined && statoPaginaNext.testo_cercato.length > 0)
      data.Creator = statoPaginaNext.testo_cercato !== undefined && statoPaginaNext.testo_cercato.length > 0 ? "*" + statoPaginaNext.testo_cercato + "*" : "";
    common.apiCall(this.state.pageContent.parent['@id'] + "/@sw-search-all-lang", "GET", data, true)
		.then(ticketsByVinVehicle => {
      this.setState({ticketsByVinVehicle: ticketsByVinVehicle})
    });
  }

  getLinkPartsFinder() {
    sessionStorage.setItem('FILTRO_NTELA', this.state.pageContent.veicolo.NTELA)
    return window.open(env.FRONTEND + "swan/cruscotto/epc/?ZM=" + this.props.marche_attive[0].ZFORN + "&NT=" + this.state.pageContent.veicolo.NTELA, '_blank')
  }

  componentDidUpdate(nextProps){
		const $ = window.$;
		var pagina = this.state.pagina;
		var paginaAttuale = parseInt(pagina.pagina_attuale), pagineTotali = '';
    if(this.state.ticketsByVinVehicle !== undefined && this.state.ticketsByVinVehicle.items !== undefined){
  		pagineTotali = parseInt(this.state.ticketsByVinVehicle.items_total / env.TABLE_PAGE_LENGTH);
  		if((this.state.ticketsByVinVehicle.items_total % env.TABLE_PAGE_LENGTH) > 0){
  			pagineTotali ++;
  		}

  		var buttons = [];
  		$('#paginazione_custom').html("");

  		if(pagineTotali > 1){
  			var paginazione_custom_content = "";
        const NUM_PAGINE_VISIBILI = 3;
        const SALTO_PAGINE = 5;
        var start=paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - SALTO_PAGINE;
        var end=paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + SALTO_PAGINE;
        var allPreviousMax = (SALTO_PAGINE - parseInt(NUM_PAGINE_VISIBILI/2));
        var allNextMax = pagineTotali - allPreviousMax;
        var startCiclo = (start <= 0 ? 1 : start);
        var endCiclo = (end > pagineTotali ? pagineTotali : end);

        for(var i=startCiclo; i<=endCiclo; i++){
          var print = false;
          var ellipsis = false;

          if((paginaAttuale <= allPreviousMax && i < paginaAttuale) || (paginaAttuale > allNextMax && i > paginaAttuale))
            print = true;
          if(i >= (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) && i <= (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1))
            print = true;
          if(i === startCiclo || i === endCiclo || i === paginaAttuale)
            print = true;
          if(i !== paginaAttuale && ((i === (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) &&  paginaAttuale >= allPreviousMax) || (i === (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1) && paginaAttuale <= allNextMax)))
            ellipsis = true;

          if(print)
            buttons.push('<li class="paginate_button page-item ' + (paginaAttuale===i ? "active" : "") + '"><p id="page_num_' + i +'" class="page-link">' + (ellipsis ? "&hellip;" : i) + '</p></li>');
        }

  			for (var b=0; b<buttons.length; b++){
  				if(buttons[b] !== undefined && buttons[b] !== null){
  					$('#paginazione_custom').html(paginazione_custom_content + buttons[b]);
  					paginazione_custom_content = $('#paginazione_custom').html();
  				}
  			}
  		}

      if(this.state.userInfo.is_admin_ticket !== undefined && !this.state.userInfo.is_admin_ticket){
        $("body").append('<style>.ticket_creator{display:none}</style>');
      }

  		$("#tickets_vehicle_table").DataTable().clear();
  		$("#tickets_vehicle_table").DataTable().rows.add(this.state.ticketsByVinVehicle.items);
  		$("#tickets_vehicle_table").DataTable().draw();
  		$("#tickets_vehicle_table").DataTable().responsive.recalc();
  		// $('table').resize();
      $("table>tbody>tr").attr("role", "row");
    }

    // Rimuovo l'event listener esistente se no invia molteplici commenti con il ctrl + invio
    $(document).off('keydown', this.handleKeyDown);
    $(document).on('keydown', this.handleKeyDown);
	}

  componenteDidMount() {
    const $ = window.$;
    $(document).off('keydown', this.handleKeyDown);
  }

  onClickPaging(tipofunzione, parametri){
		//Parametri:
		var statoPagina = this.state.pagina;
		var statoPaginaNext = JSON.parse(JSON.stringify(statoPagina));
		switch(tipofunzione) {
			case "paginazione_custom":
        statoPaginaNext.filtra_risultati = statoPagina.filtra_risultati;
				if(parametri.calcolaPagina !== undefined && parametri.calcolaPagina !== null && parseInt(parametri.calcolaPagina)){
					statoPaginaNext.pagina_attuale = statoPagina.pagina_attuale + parseInt(parametri.calcolaPagina);
				}
				else if(parametri.vaiPagina !== undefined && parametri.vaiPagina !== null){
					if(parametri.vaiPagina === "end"){
						statoPaginaNext.pagina_attuale = parseInt(this.state.pageContent.items_total / env.TABLE_PAGE_LENGTH);
						if((this.state.pageContent.items_total % env.TABLE_PAGE_LENGTH) > 0){
							statoPaginaNext.pagina_attuale ++;
						}
					}
					else if(parseInt(parametri.vaiPagina))
						statoPaginaNext.pagina_attuale = parseInt(parametri.vaiPagina);
				}
        // window.history.replaceState({},"",common.updateURLParameter("page", statoPaginaNext.pagina_attuale));
			break;
			case "ordinamento":
				statoPaginaNext.tipo_ordinamento = parametri.tipoOrdinam;
				statoPaginaNext.verso_ordinamento = parametri.versoOrdinam; // 1 -1
			break;
      case "cerca":
				statoPaginaNext.testo_cercato = parametri.testo;
				statoPaginaNext.pagina_attuale = 1;
			break;

    }
    this.setState({pagina: statoPaginaNext})
    this.getTableTicket(statoPaginaNext);
  }

  addTemporayFile(){
    const $ = window.$;
    var file = $("#file_comment")[0].files[0].name;
    this.setState({file_info_upload: file})
  }

  async handleDownloadFile(fileurl, filename){
    const $ = window.$;
    $("#downloadFileLoader" ).css("display", "inline");
    var fileContent = await common.apiCall(fileurl, "GET", {}, true, false, "blob")
    .then(data => {
      $("#downloadFileLoader").css("display", "none");
      return data;
    });
    var element = document.createElement('a');
    element.setAttribute('href', fileContent);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);

    return;
  }

  async download_file_from_bucket(pFILENAME, pURL) {
  var directory = pFILENAME.split("/")
  var filename = directory.slice(-1);
  fetch(pURL)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => console.error('Download error: ' + filename));
}

  statoTicket(){
    switch(this.state.pageContent.review_state) {
      case 'closed': case 'closed_visto' :
        return  ('<span class="p-1" style="background: #dc3545;color:#fff;"><i class="fas fa-envelope"></i> ' + common.getTrad("Chiuso") + '</span>')
      case 'incomplete': case 'incomplete_visto': case 'incomplete_draft':
        return  ('<span class="p-1" style="background: #ffc107;color:#000;"><i class="fas fa-envelope-open"></i> ' + common.getTrad("Incompleto") + '</span>')
      case 'published': case 'published_visto':
        return  ('<span class="p-1" style="background: #007bff;color:#fff;"><i class="fas fa-envelope-open"></i> ' + common.getTrad("In lavorazione") + '</span>')
      default:
        return  ('<span class="p-1" style="background: #28a745;color:#fff;"><i class="fas fa-envelope-open-text"></i> ' + common.getTrad("Aperto") + '</span>')
    }
  }

  renderVociTicket(messageErrors) {
    var description = this.state.pageContent.description;
    var campiTicket = this.state.tipologie_ticket_attive[this.state.tipoTicket];
    if(this.state.marcaDefault !== undefined)
      campiTicket = this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket];

    this.keyComments = common.object_value_in_array(description, 'id', 'comments');

    return campiTicket.campi.map((index, key) =>{
      // if(index.id === 'file')
      //   this.setState({googleCloudBucketPath: false})
      if(index.id !== 'file' && index.id !== 'comments' && index.id !== 'file_multiple'){
        
        var numCampo = common.object_value_in_array(description, 'id', index.id);
        if(numCampo > -1 && description[numCampo].id === index.id){
          if ((description[numCampo].value !== '') && (description[numCampo].id !== 'richiesto_contatto_telefonico' || description[numCampo].value !== 'No')) {
            let printValue = (
              <input type="text" className="form-control" disabled="disabled" />
            );
            if (description[numCampo].value) {
              if (index.html.indexOf("date_input") > -1)
                printValue = (
                  <input type="text" className="form-control date_input" disabled="disabled" defaultValue={moment(description[numCampo].value).isValid() ? moment(description[numCampo].value).format('L') : ''} />
                );
              else if (description[numCampo].type === "textarea")
                printValue = (
                  <textarea disabled="disabled" value={description[numCampo].value} className="form-control"></textarea>
                );
              else if (description[numCampo].type === 'select' || description[numCampo].type === 'select-one')
                printValue = (
                  <select className="select2 select2_ticket_option form-control">
                    <option selected>{description[numCampo].value}</option>
                  </select>
                );
              else
                printValue = (
                  <input type="text" className="form-control" disabled="disabled" defaultValue={description[numCampo].value} />
                );
            }

            let errorMessage = "";
            if (messageErrors[index.id] !== undefined && messageErrors[index.id] === 'REQUIRED')
              errorMessage = (
                <>
                  <i className="text-danger fas fa-exclamation-circle"></i>&nbsp;
                  <span>{common.getTrad("Campo obbligatorio")}</span>
                </>
              )


            return(
              <div key={key} className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label htmlFor={index.id}>
                    {this.state.marcaDefault !== undefined || (this.props.marche_attive !== undefined && this.props.marche_attive.length === 1) ? common.getTrad(index.titolo) : common.getTrad(index.titolo)}
                    {index.tip !== undefined && index.tip !== null && index.tip !== '' ?
                      <>&nbsp;<i className="fas fa-info-circle" data-original-title={index.tip} data-toggle="tooltip" data-placement="top"></i></>
                    :
                      ""
                    }
                  </label>
                  <div>
                    {printValue}
                    {errorMessage}
                  </div>
                </div>
              </div>
            )
          }
        }
      }
      return false
    });
  }

  renderChatComments(){
    if(this.state.pageContent.description[this.keyComments] !== undefined && this.state.pageContent.description[this.keyComments] !== undefined && this.state.pageContent.description[this.keyComments].value !== undefined && this.state.pageContent.description[this.keyComments].value !== undefined){
      var reviewer = false;
      if(this.state.userInfo.is_admin_ticket){
        reviewer = true;
      }

      if(this.state.pageContent.description[this.keyComments] !== undefined && this.state.pageContent.description[this.keyComments].value !== undefined && Array.isArray(this.state.pageContent.description[this.keyComments].value)){
        return this.state.pageContent.description[this.keyComments].value.map((index, key) => {
          var classDefault = reviewer ? index.roles.indexOf("Reviewer") > -1 ? "sent message" : "received message" : index.roles.indexOf("Reviewer") > -1 ? "received message" : "sent message";
          if(index.attachment !== undefined && index.attachment !== null)
            classDefault = classDefault + " has_attachment";
          return(
            index.text !== undefined ?
              <div key={key} className={classDefault}>
                {index.attachment !== undefined && index.attachment !== null ?
                  <div>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center mr-auto">
                        <img src={env.STATIC + "images/estensioni/" + index.attachment.name.split('.').pop() + ".png"}
                          onError={(e) => { e.target.onerror = null; e.target.src = env.STATIC + "images/estensioni/default.png" }} className="img-responsive mr-2"
                          alt={index.attachment.name} title={index.attachment.name}
                        />
                        <small>
                          <span>{common.getTrad("File")}: {index.attachment.name}</span>
                          {index.attachment.size !== undefined && (
                            <><br />{common.getTrad("Dimensione")}: <small>{common.humanFileSize(index.attachment.size)}</small></>
                          )}
                        </small>
                      </div>
                      <button className="btn btn-sm mr-2" onClick={() => { this.download_file_from_bucket(index.attachment.name, index.attachment.file) }}>
                        <i className="fas fa-file-download"></i>&nbsp;{common.getTrad("Scarica")}
                      </button>
                      <a className="btn btn-sm" target="_blank" href={index.attachment.file}>
                        <i className="fas fa-eye"></i>&nbsp;{common.getTrad("Visualizza")}
                      </a>
                    </div>
                  </div>
                :
                  ""
                }
                {index.text.search("\n") > -1  ?
                  <div dangerouslySetInnerHTML={{ __html: (index.text).replace(/\r?\n/g,'<br/>')}} />
                :
                  index.text
                }
      					<span className="metadata">
                  <span className="time">
                    {moment(index.created).format('LLL')}
                    <br />
                    <i className="fas fa-user"></i>&nbsp;{index.author}
                  </span>
                </span>
      				</div>
            :
            <></>
          )
        });
      }
    }
  }

  renderTestataDocumento(){
    var numMarca = 0
    if (window.cc.marche_attive !== undefined && window.cc.marche_attive !== null)
      numMarca = common.object_value_in_array(window.cc.marche_attive, 'ZFORN', this.state.marcaDefault)
    return (
      <>
        <div className="row">
          {window.cc.marche_attive !== undefined && window.cc.marche_attive.length > 1 ?
            <div className="col-xs-12 col-md-6">
              <div className="form-group">
			          <b>{common.getTrad("Marca")}:</b>&nbsp;{window.cc.marche_attive[numMarca].DFORN}
              </div>
            </div>
          :
            <></>
          }
          {this.state.userInfo !== undefined && this.state.userInfo.ticket_group_by_csoft && !this.state.userInfo.is_admin_ticket_group ?
            <div className="col-xs-12 col-md-6">
              <div className="form-group">
                <b>{common.getTrad("Sister Company")}:</b>&nbsp;{this.state.csoftGroup}
              </div>
            </div>
          :
            <></>
          }
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <b>{common.getTrad("Tipo")}:</b>&nbsp;
              {this.props.pageContent.subjects ?
                this.state.marcaDefault !== undefined ?
                  common.getTrad(this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket].titolo)
                :
                  this.state.tipologie_ticket_attive[this.state.tipoTicket].titolo
              :
                ""
              }
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <b>{common.getTrad("Stato")}:</b>&nbsp;<span dangerouslySetInnerHTML={{ __html: this.statoTicket()}} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <b>{common.getTrad("Data pubblicazione")}:</b>&nbsp;<span>{moment(this.state.pageContent.created).format('LLL')}</span>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <b>{common.getTrad("Pubblicato da")}:</b>&nbsp;{common.customCreators(this.state.pageContent.creators)}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="form-group">
              <b>{common.getTrad("Ultima modifica")}:</b>&nbsp;<span>{moment(this.state.pageContent.modified).format('LLL')}</span>
            </div>
          </div>
        </div>
      </>
    )
  }

  handleKeyDown = (e) => {
    const $ = window.$;
    if (e.ctrlKey && e.key === 'Enter') {
      if ($("#new_comment").val() != '' && $("#new_comment").val() !== undefined)
        this.handleSubmit(e);
    }
  }

  async handleSubmit (e){
    e.preventDefault();
    var $ = window.$;

    var uploadedFiles = []
    var roles = this.state.userInfo.roles;

    $("#new_comment").prop("disabled", true);
    $(".send.btn").prop("disabled", true);
    $(".send.btn i").addClass("fa-spinner fa-spin");

    if(this.state.userInfo.is_admin_ticket)
      roles.push('Reviewer');
    this.state.pageContent.description[this.keyComments].value.push({
      "text": $('#new_comment').val().replace(/\r?\n/g,'\n'),
      "author": this.state.userInfo.nickname,
      "roles":  roles,
      "created": moment().format(),
    });

    var fileInput = $("#file_comment")[0];
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      var file = fileInput.files[0];
      if (file && fileInput.files[0] !== null && fileInput.files[0] !== undefined && fileInput.files[0] !== "") {
        var file_name = file.name.split('.')
        var ext_file = file_name[file_name.length -1];
        file_name.splice(file_name.length -1)
        var new_file_name =  file_name + '_' + Date.now() + '.' + ext_file;

        var dataJson = {
          path_name: this.state.googleCloudBucketPath + this.state.pageContent.id,
          file_name: new_file_name,
          file_type: file.type,
        }
        var mutexUp = new common.Mutex();
        common.apiCall(common.getBackendUrl("@sw-generate-backend-google-url"), "GET", dataJson)
        .then(async (dataBucket) => {
          await common.uploadFileToBucket(this, dataBucket.url, file, 1, 1, mutexUp);
          this.state.pageContent.description[this.keyComments].value[this.state.pageContent.description[this.keyComments].value.length - 1].attachment = {
            file: dataBucket.public_url,
            path: dataBucket.path,
            name: file.name,
            size: file.size,
          };

          await mutexUp.acquireQueued();

          this.setState({pageContent: this.state.pageContent});
          this.saveInfo();
        });
      }
    }
    this.setState({ pageContent: this.state.pageContent });
    this.saveInfo();
  }

  saveInfo(){
    const $ = window.$;
    var data = {};
    data = {description: JSON.stringify(this.state.pageContent.description), create_comment: true}
    if(this.state.marcaDefault !== undefined){
      data.brand = this.state.marcaDefault;
      data.tipologia = this.state.tipoTicket;
    }

    common.apiCall(env.BACKEND + this.state.pathname, "PATCH", data, true, false, "text", false, this.props.Lock.token)
    .then(data =>{
      if(data === ""){
        if(!this.state.userInfo.is_admin_ticket){
          if(this.state.pageContent.review_state === 'incomplete' || this.state.pageContent.review_state === 'incomplete_visto'){
            this.changeReviewStateComment('pending', this.state.userInfo.roles, this.props.Lock.token);
          }
        }
        $('#notifica_messaggio').html('<div class="sw-alert alert alert-success animated delay-2s fadeOutRight">' + common.getTrad("Commento salvato correttamente.") + '</div>');
        $(".conversation-compose")[0].reset();
        $("#new_comment").prop("disabled", false);
        $(".send.btn").prop("disabled", false);
        $(".send.btn i").removeClass("fa-spinner fa-spin");
        common.clear_cache_after_save("swticket_vehicle");
      }else{
        data = JSON.parse(data)
        $('#notifica_messaggio').html('<div class="alert alert-danger animated">' + common.getTrad("Errore qualcosa è andato storto.") + " <b>" + data["type"] + "</b> " + data["message"]
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>'
        +'</div>');
      }
    });
    this.setState({file_info_upload: ''})
  }

  render(props){
    let messageErrors = {}, pos = -1;
    pos = common.object_value_in_array(this.state.pageContent.description, "id", "messageErrors");
    if (pos > -1)
      messageErrors = this.state.pageContent.description[pos]?.value;

    return (
      <>
        {this.state.pathname !== "" ?
          <>
            {Object.keys(this.state.tipologie_ticket_attive).length === 0 ?
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 content_testo">
                  <div className="clear sw-alert alert alert-danger mt-3">
                    <span dangerouslySetInnerHTML={{ __html: common.getTrad("I Ticket non stanno funzionando correttamente. Contatta il <b>supporto tecnico</b> se il problema persiste.")}}/>
                  </div>
                </div>
              </div>
            :
              <>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 content_testo">
                    <h1>
                      {this.state.pageContent.title}
                    </h1>
                    <div className="info_singolo_contenuto">
                      <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Numero ID")}><i className="fas fa-info-circle"></i>&nbsp;{this.state.pageContent.id}</span>
                    </div>
                    <br/>
                  </div>
                </div>
                {this.renderTestataDocumento()}
                <div className="col">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="pills-richiesta-tab" data-toggle="pill" href="#pills-richiesta" role="tab" aria-controls="pills-richiesta" aria-selected="true">{common.getTrad("Richiesta")}</a>
                    </li>
                    {this.state.pageContent.veicolo !== undefined && typeof this.state.pageContent.veicolo === 'object' && this.state.pageContent.veicolo !== null ?
                      <>
                        {this.state.userInfo.is_admin_ticket ?
                          <li className="nav-item">
                            <a className="nav-link" id="pills-dealer-tab" data-toggle="pill" href="#pills-dealer" role="tab" aria-controls="pills-dealer" aria-selected="false">{common.getTrad("Dealer")}</a>
                          </li>
                        :
                          <></>
                        }
                        <li className="nav-item">
                          <a className="nav-link" id="pills-veicolo-tab" data-toggle="pill" href="#pills-veicolo" role="tab" aria-controls="pills-veicolo" aria-selected="false">{common.getTrad("Veicolo")}</a>
                        </li>
                      </>
                    :
                      <></>
                    }
                    {this.state.userInfo.is_admin_ticket && this.state.pageContent.vin_vehicle ?
                      <>
                        {common.object_value_in_array(this.state.userInfo.userInfo.auth, "TDATI", "CG") > -1 || common.object_value_in_array(this.state.userInfo.userInfo.auth, "TDATI", "GG") > -1 ?
                          <li className="nav-item">
                            <a className="nav-link" id="pills-garanzie-tab" data-toggle="pill" onClick={() => this.getTableWarranty()} href="#pills-garanzie" role="tab" aria-controls="pills-garanzie" aria-selected="false">{common.getTrad("Garanzie")}</a>
                          </li>
                        :
                          <></>
                        }
                        {common.object_value_in_array(this.state.userInfo.userInfo.auth, "TDATI", "CT") > -1 || common.object_value_in_array(this.state.userInfo.userInfo.auth, "TDATI", "GT") > -1 ?
                          <li className="nav-item">
                            <a className="nav-link" id="pills-tagliandi-tab" data-toggle="pill" onClick={() => this.getTableService()} href="#pills-tagliandi" role="tab" aria-controls="pills-tagliandi" aria-selected="false">{common.getTrad("Tagliandi")}</a>
                          </li>
                        :
                          <></>
                        }
                        {common.object_value_in_array(this.state.userInfo.userInfo.auth, "TDATI", "CA") > -1 || common.object_value_in_array(this.state.userInfo.userInfo.auth, "TDATI", "GA") > -1 ?
                          <li className="nav-item">
                            <a className="nav-link" id="pills-attivazioni-tab" data-toggle="pill" onClick={() => this.getTableActivation()} href="#pills-attivazioni" role="tab" aria-controls="pills-attivazioni" aria-selected="false">{common.getTrad("Attivazioni")}</a>
                          </li>
                        :
                          <></>
                        }
                        <li className="nav-item">
                          <a className="nav-link" id="pills-ticket-tab" data-toggle="pill" onClick={() => this.getTableTicket(this.state.pagina)} href="#pills-ticket" role="tab" aria-controls="pills-veicolo" aria-selected="false">{common.getTrad("Ticket")}</a>
                        </li>
                      </>
                    :
                      <></>
                    }
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-richiesta" role="tabpanel" aria-labelledby="pills-richiesta-tab">
                    <div className="row">{this.renderVociTicket(messageErrors)}</div>
                  </div>
                  {this.state.pageContent.veicolo !== undefined && typeof this.state.pageContent.veicolo === 'object' && this.state.pageContent.veicolo !== null ?
                    <>
                      <div className="tab-pane fade" id="pills-dealer" role="tabpanel" aria-labelledby="pills-dealer-tab">
                        {this.state.pageContent['@components'].swowner !== undefined && typeof this.state.pageContent['@components'].swowner === 'object' && this.state.pageContent['@components'].swowner !== null ?
                          <div className="row">
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <b>{common.getTrad("Codice Dealer")}:</b>&nbsp;{this.state.pageContent['@components'].swowner.CCORR}
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <b>{common.getTrad("Nome Dealer")}:</b>&nbsp;{this.state.pageContent['@components'].swowner.RCLIE}
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <b>{common.getTrad("Nazione")}:</b>&nbsp;{this.state.pageContent.user_cnazi}
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <b>{common.getTrad("Indirizzo")}:</b>&nbsp;{this.state.pageContent['@components'].swowner.DINDI}
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <b>{common.getTrad("Località")}:</b>&nbsp;{this.state.pageContent['@components'].swowner.DLOCA}
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <b>{common.getTrad("Email")}:</b>&nbsp;{this.state.pageContent['@components'].swowner.DCAPO}
                              </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <b>{common.getTrad("Telefono")}:</b>&nbsp;{this.state.pageContent['@components'].swowner.NTEL1}
                              </div>
                            </div>
                          </div>
                        :
                          <></>
                        }
                      </div>

                      <div className="tab-pane fade" id="pills-veicolo" role="tabpanel" aria-labelledby="pills-veicolo-tab">
                        <div className="row">
                          <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                              <b>{common.getTrad("Telaio")}:</b>&nbsp;{this.state.pageContent.veicolo.NTELA}
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                              <b>{common.getTrad("Cod. Modello")}:</b>&nbsp;
                              {
                                this.state.pageContent.veicolo.CMODE
                              }
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                              <b>{common.getTrad("Desc. Veicolo")}:</b>&nbsp;
                              {this.state.pageContent.veicolo.DCOLO !== undefined && this.state.pageContent.veicolo.DCOLO !== null ?
                                this.state.pageContent.veicolo.DVEIC + " " + this.state.pageContent.veicolo.DCOLO
                                :
                                this.state.pageContent.veicolo.DVEIC
                              }
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                              <b>{common.getTrad("Cilindrata")}:</b>&nbsp;
                              {
                                this.state.pageContent.veicolo.NCILI
                              }
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                              <b>{common.getTrad("Stato Veicolo")}:</b>&nbsp;
                              {this.state.pageContent.veicolo.FSTAT !== undefined && this.state.pageContent.veicolo.FSTAT !== null ?
                                this.state.pageContent.veicolo.FSTAT === "N" ?
                                  common.getTrad("Nuovo")
                                  :
                                    this.state.pageContent.veicolo.FSTAT === "V" ?
                                      common.getTrad("Venduto")
                                    :
                                      this.state.pageContent.veicolo.FSTAT === "S" ?
                                        common.getTrad("Garanzia Scaduta")
                                    :
                                      <></>
                                :
                                  <></>
                              }
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                              <b>{common.getTrad("KM/Miglia/Ore Percorsi")}:</b>&nbsp;{this.state.pageContent.veicolo.NKMPE_PREC}
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                              <b>{common.getTrad("Targa")}:</b>&nbsp;{this.state.pageContent.veicolo.NTARG}
                            </div>
                          </div>
                          {moment(this.state.pageContent.veicolo.UIMMA).isValid() ?
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <b>{common.getTrad("Data Immatr.")}:</b>&nbsp;{moment(this.state.pageContent.veicolo.UIMMA).format('LL')}
                              </div>
                            </div>
                          :
                            <></>
                          }
                          {moment(this.state.pageContent.veicolo.UGARA).isValid() ?
                            <div className="col-xs-12 col-md-6">
                              <div className="form-group">
                                <b>{common.getTrad("Data Scadenza Garanzia Contrattuale")}:</b>&nbsp;{moment(this.state.pageContent.veicolo.UGARA).format('LL')}
                              </div>
                            </div>
                          :
                            <></>
                          }
                          <div className="col-xs-12 col-md-12">
                            <div className="form-group">
                              <button type="button" class="btn btn-light" onClick={() => this.getLinkPartsFinder()}>
                                <figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-epc.svg"} className="svg">
                                </figure>
                                Parts Finder
                              </button>
                            </div>
                          </div>
                        </div>
                        {this.state.pageContent.veicolo.propretario !== undefined && typeof this.state.pageContent.veicolo.propretario === 'object' && this.state.pageContent.veicolo.propretario !== null ?
                          <>
                            {(this.state.pageContent.veicolo.propretario.RACQU !== null && this.state.pageContent.veicolo.propretario.RAGG1 !== null) ?
                              <>
                                <div className="row mt-3">
                                  <h4 className="col">{common.getTrad("Proprietario Veicolo")}</h4>
                                </div>
                                <div className="row">
                                  <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                      <b>{common.getTrad("Nominativo")}:</b>&nbsp;
                                      {this.state.pageContent.veicolo.propretario.RCLIE !== undefined ?
                                        this.state.pageContent.veicolo.propretario.RCLIE
                                        :
                                        this.state.pageContent.veicolo.propretario.RACQU + " " + this.state.pageContent.veicolo.propretario.RAGG1
                                      }
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                      <b>{common.getTrad("Nazione")}:</b>&nbsp;
                                      {common.object_value_in_array(window.cc.nazioni, 'CISO3TNA', this.state.pageContent.veicolo.propretario.CNAZI) > -1 ?
                                        window.cc.nazioni[common.object_value_in_array(window.cc.nazioni, 'CISO3TNA', this.state.pageContent.veicolo.propretario.CNAZI)]['DLINGTNA']
                                      :
                                        this.state.pageContent.veicolo.propretario.CNAZI
                                      }
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                      <b>{common.getTrad("Località")}:</b>&nbsp;{this.state.pageContent.veicolo.propretario.DLOAQ}
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                      <b>{common.getTrad("Indirizzo")}:</b>&nbsp;{this.state.pageContent.veicolo.propretario.DINAQ}
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                      <b>{common.getTrad("Telefono")}:</b>&nbsp;{this.state.pageContent.veicolo.propretario.NTEL1}
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                      <b>{common.getTrad("Cellulare")}:</b>&nbsp;{this.state.pageContent.veicolo.propretario.NTEL2}
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                      <b>{common.getTrad("Email")}:</b>&nbsp;{this.state.pageContent.veicolo.propretario.DCAPO}
                                    </div>
                                  </div>
                                </div>
                              </>
                            :
                              <></>
                            }
                          </>
                          :
                          <></>
                        }
                      </div>
                    </>
                  :
                    <></>
                  }
                  <div className="tab-pane fade" id="pills-garanzie" role="tabpanel" aria-labelledby="pills-garanzie-tab">
                    <table id="table_warranty" className="table table-striped table-bordered" width='100%'></table>
                  </div>
                  <div className="tab-pane fade" id="pills-tagliandi" role="tabpanel" aria-labelledby="pills-tagliandi-tab">
                    <table id="table_service" className="table table-striped table-bordered" width='100%'></table>
                  </div>
                  <div className="tab-pane fade" id="pills-attivazioni" role="tabpanel" aria-labelledby="pills-attivazioni-tab">
                    <table id="table_activation" className="table table-striped table-bordered" width='100%'></table>
                  </div>
                  <div className="tab-pane fade" id="pills-ticket" role="tabpanel" aria-labelledby="pills-ticket-tab">
                    {this.state.ticketsByVinVehicle !== undefined && this.state.ticketsByVinVehicle.items !== undefined ?
                      <TicketVehicleTable marche_attive={this.props.marche_attive} tipologieTicket={this.state.tipologie_ticket_attive}
                        pageTitle={this.props.pageTitle}
                        paginaDati={this.state.pagina}
                        onClick={(tipoFunzione, params) => this.onClickPaging(tipoFunzione, params)}
                        pathname={this.state.pathname}
                        pageContent={this.state.ticketsByVinVehicle}
                      >
                      </TicketVehicleTable>
                    :
                      <></>
                    }
                  </div>
                  <div className="col">
                    {this.state.get_veicolo && moment(this.state.pageContent.update_data).isValid() ?
                      <small>{common.getTrad("Dati aggiornati al {{update_data}}", {update_data: moment(this.state.pageContent.update_data).format('LLL')})}</small>
                    :
                      <></>
                    }
                  </div>
                </div>
                <div className="row justify-content-between">
                  {this.state.pageContent.file !== null ?
                    <div className="col-xl-5 col-md-12 col-sm-12 content_download">
                      <h3>{common.getTrad("Download")}</h3>
                      <hr/>
                      <p>{common.getTrad("Clicca sul bottone per scaricare il file")}:<br/> <b>{this.state.pageContent.file.filename}</b> ({common.humanFileSize(this.props.pageContent.file.size)})</p>
                      <br/>
                      <button className="bottone_default btn btn-lg mt-5" id="downloadButton" onClick={() => {this.handleDownloadFile(common.getBackendUrl(this.state.pageContent.file.download), this.state.pageContent.file.filename)}}>
                        <b><i className="fas fa-file-download"></i>&nbsp;{common.getTrad("Scarica l'allegato")} &nbsp;&nbsp;<span className="downloadFileLoader" id="downloadFileLoader"><i className="fas fa-spinner fa-spin"></i></span></b>
                      </button>
                    </div>
                  :
                    messageErrors?.file === 'REQUIRED'?
                      <div className="col-sm-12">
                        <i className="text-danger fas fa-exclamation-circle"></i>&nbsp;
                        <span dangerouslySetInnerHTML={{ __html: common.getTrad("L'allegato è obbligatorio")}} />
                      </div>
                    :
                      <></>
                  }
                </div>
                {this.keyComments !== -1 ?
                  <div className="row mt-5">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
                      <div className="content_ticket_form">
                        <h4>{common.getTrad("Storico Commenti")}</h4>
                        <hr/>
                        <div className="page">
                          <div className="marvel-device nexus5">
                            <div className="top-bar"></div>
                            <div className="screen">
                              <div className="screen-container">
                                <div className="chat">
                                  <div className="chat-container">
                                    <div className="conversation">
                                    {this.state.googleCloudBucketPath && this.state.pageContent.file !== undefined && this.state.pageContent.file !== null ?
                                      <div className={this.state.userInfo.is_admin_ticket ? "sent message" : "received message"}>
                                        <div>
                                          <img src={env.STATIC + "images/estensioni/" + this.state.pageContent.file.filename.split('.').pop() + ".png"}
                                            onError={(e)=>{e.target.onerror = null; e.target.src=env.STATIC + "images/estensioni/default.png"}} className="img-responsive"
                                            alt={this.state.pageContent.file.filename} title={this.state.pageContent.file.filename}
                                          />
                                          {this.state.pageContent.file.filename}
                                          <a style={{cursor: "pointer"}} className="float-right" onClick={() => {this.handleDownloadFile(common.getBackendUrl(this.state.pageContent.file.download), this.state.pageContent.file.filename)}}>
                                            <i className="fas fa-2x fa-file-download"></i>
                                          </a>
                                          <br/>
                                          <small>{common.humanFileSize(this.props.pageContent.file.size)}</small>
                                        </div>
                                        <span className="metadata">
                                          <span className="time">
                                            {moment(this.state.pageContent.created).format('LLL')}<br/>
                                            <i className="fas fa-user"></i>&nbsp;{common.customCreators(this.state.pageContent.creators)}
                                          </span>
                                        </span>
                                      </div>
                                    :
                                      <></>
                                    }
                                    {this.renderChatComments()}
                                    {(!this.props.Lock.locked || this.state.userInfo.id === this.props.Lock.creator) && (this.state.userInfo.is_admin_ticket || (common.object_value_in_array(this.state.pageContent['@components']['actions'].object, "id", "edit") > -1 && (this.state.pageContent.review_state === 'incomplete' || this.state.pageContent.review_state === 'incomplete_visto'))) ?
                                      <>
                                        <form className="conversation-compose" type="post" onSubmit={this.handleSubmit} encType="multipart/form-data">
                                          <textarea required id="new_comment" className="input-msg form-control" name="new_comment" placeholder={common.getTrad("Scrivi un commento")}></textarea>
                                          {this.state.googleCloudBucketPath ?
                                            <div className="photo">
                                              <label htmlFor="file_comment" >
                                                <i className="fas fa-paperclip" title={common.getTrad("Carica un file")}></i>
                                              </label>
                                              <input type="file" name="file_comment" id="file_comment" onChange={() => this.addTemporayFile()} />
                                            </div>
                                          :
                                            <></>
                                          }
                                          <button className="send btn" type="submit">
                                            <div className="circle">
                                              <i className="fas fa-paper-plane"></i>
                                            </div>
                                          </button>
                                        </form>
                                        {this.state.file_info_upload !== undefined && this.state.file_info_upload !== null && this.state.file_info_upload !== '' ?
                                          <div className="sw-alert alert alert-info mt-4">
                                            <i className="fas fa-exclamation-circle"></i>&nbsp;
                                            {common.getTrad("File selezionato")}: {this.state.file_info_upload}
                                          </div>
                                        :
                                          <></>
                                        }
                                      </>
                                    :
                                      ""
                                    }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                :
                  <></>
                }
              </>
            }
          </>
          :
            <></>
        }
      </>
    )
  }

  // funzione per aggiornare lo stato solo nel caso di utente dealer e stato incompleto
  async changeReviewStateComment(newStateComment, permessi, tokenTicket){
    var url = common.getBackendUrl(this.state.pageContent["@id"]), response;
    if(!this.state.userInfo.is_admin_ticket && (this.state.pageContent.review_state === 'incomplete' || this.state.pageContent.review_state === 'incomplete_visto')){
      if(newStateComment === "pending"){
        url = url + "/@workflow/submit";
        var data = {
          brand: this.state.marcaDefault,
          tipologia: this.state.tipoTicket
        }
        response = await common.apiCall(url, "POST", data, true, false, "json", false, tokenTicket);
        if(response.error === false){
          var pageContent = this.state.pageContent;
          pageContent.review_state = response.review_state;
          this.setState({pageContent: pageContent});
          //window.location.reload();
        }
      }
    }else{
      return false;
    }
  }
}


function truncateOnWord(str, limit) {
	var trimmable = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
	var reg = new RegExp('(?=[' + trimmable + '])');
	var words = str.split(reg);
	var count = 0;
	return words.filter(function(word) {
			count += word.length;
			return count <= limit;
	}).join('') + "...";
}

export default PageVehicleTicket;
