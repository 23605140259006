import React from 'react';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';

const common = require('../common/common');
const env = require("../../config.js").default;

try {
  require ('select2/dist/js/i18n/'+ common.getLanguage(false) +'.js');
} catch (err) {
  require ('select2/dist/js/i18n/en.js');
}

class AnagraficaEmployee extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.nazioniPortale = [];
    this.state.type =   this.props.type;
  }

  componentDidMount(){
    this.setState({nazioniPortale: common.getNazioniPortale(true)});
  }

  componentDidUpdate(){
    const $ = window.$;
    var getBrowserLang = navigator.language;
    getBrowserLang = getBrowserLang.substring(getBrowserLang.length - 2, getBrowserLang.length).toUpperCase();

    if(getBrowserLang === 'EN'){
        $("#CNAZIACL option[data-iso2='GB']").attr("selected", true);
    }else{
      if(!$("#CNAZIACL option[data-iso2='" + getBrowserLang +"']").length){
        $("#CNAZIACL option[data-iso2='"+ common.getLanguageMoment().toUpperCase() +"']").attr("selected", true);
        getBrowserLang = common.getLanguageMoment().toUpperCase();

      }else{
        $("#CNAZIACL option[data-iso2='" + getBrowserLang +"']").attr("selected", true);
      }
    }

    $("#CNAZIACL_hidden").val($("#CNAZIACL option:selected").attr("data-iso2"));

    // ciclo solamente le nazioni che fanno parte dell'unione europea.
    function format(state) {
      var iso3_image =  $(state.element).attr('data-iso3');
      if (!state.id) return state.text; // optgroup
      return '<img class="bandiere_icone" src="'+env.STATIC+'images/bandiere/'+ iso3_image + '.svg" />' +
        '<span class="flag-text">' + state.text + '</span>';
    }
    // select nazione Paese con cambio codice ISO2 per partita iva
    $("#CNAZIACL").select2({
      templateResult: format,
      templateSelection: format,
      escapeMarkup: function(m) { return m; },
      language: common.getLanguage(),
      placeholder: common.getTrad("Seleziona un Paese")
    });


    $(window).resize(function() {
      $('.select2').css('width', "100%");
    });

    $( '#NPIVAACL' ).focus();
    $( '#NPIVAACL' ).on( 'keyup change', function () {
      $('#nome_ditta').html($( '#NPIVAACL' ).val());
    });

    $("#default_vat").html(getBrowserLang);
    $('#CNAZIACL').on('change', function () {
      var iso_code = $(this).find(':selected').data("iso2");
      $("#CNAZIACL_hidden").val(iso_code);
      $("#default_vat").html(iso_code);
    });

    // ripristino i dati inseriti se viene cambiata lingua portale
    var formName = '',$input='';
    if(this.state.type === 'io')
      formName = "formIO";
    else
      formName = "formDipendente";
    if(common.get_localStorage(formName) !== null && common.get_localStorage(formName) !== undefined && common.get_localStorage(formName) !== ''){
      var filtri_attivi = JSON.parse(common.get_localStorage(formName));
      for (let key in filtri_attivi) {
        if (filtri_attivi.hasOwnProperty(key)) {
          // controllo quale tipologia di INPUT è stata salvata nel ciclo JSON. ed a seconda della tipologia cambio i metodi si selezione se presenti.
          var input = document.getElementsByName(key)[0];
          $input = $(input);
          if(input.nodeName === 'SELECT'){
            if(filtri_attivi[key] != null){
              $('select[name='+key+'] option').each(function() {
                if ($(this).val() === filtri_attivi[key]) {
                  $('select[name='+key+']').val($(this).val()).trigger("change");
                }
              });
            }
          }
          if(input.nodeName === 'INPUT'){
            if($input.attr('type') === 'text' || $input.attr('type') === 'email'){
              $('input[name=' + key + ']').val(filtri_attivi[key]);
            }
            if($input.attr('type') === 'radio' || $input.attr('type') === 'checkbox'){
              $('input[name=' + key + ']').prop('checked', true);
            }
          }
          $input.change();
          $input.trigger("autochange");
        }
      }
    }
  }

  renderSelectNazioni(){
    var nazioniPortale = common.getNazioniPortale(true);
    return nazioniPortale.map(function(index, key){
      return(
        <option key={key} value={index.CNASWTNA} data-iso3={index.CISO3TNA} data-iso2={index.CISO2TNA}>{common.getTrad("nazione_" + index.CISO2TNA)}</option>
      )
    });
  }

  render(props){
    return(
      <>
        <h6>
          <i className="step-icon fas fa-user" title="Anagrafica"></i>
        </h6>
        <fieldset className="mt-2 col">
          <div className="row justify-content-md-center">
            <div className="col-xl-3 col-lg-4 col-md-5">
              <div className="form-group">
                <input type="hidden" name="CNAZIACL_hidden" id="CNAZIACL_hidden" />
                <label htmlFor="CNAZIACL">{common.getTrad("Paese")}: <span className="danger">*</span></label>
                <select autoComplete="country" className="form-control required" id="CNAZIACL" name="CNAZIACL" >
                  {this.renderSelectNazioni()}
                  </select>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 mb-3">
              <label htmlFor="NPIVAACL">{common.getTrad("Partita IVA")}: <span className="danger">*</span></label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="default_vat"></span>
                </div>
                <input type="text" autoComplete="on" className="form-control required" minLength="8" maxLength="30" name="NPIVAACL" id="NPIVAACL" placeholder={common.getTrad("Inserisci la Partita IVA")} />
              </div>
            </div>
          </div>
        </fieldset>
      </>
    )
  }
}

export default AnagraficaEmployee;
