import React from 'react';
import { ACTIONS_STATE_DECODE } from "../Sidebar/GestioneContenutiSidebar";
import ReactDOMServer from 'react-dom/server';
import { Navigate } from "react-router-dom";
const common = require('../common/common');
const env = require("../../config.js").default;

class ContactTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.pathname = this.props.pathname;
    this.state.recordTabella = {};
    this.state.recordTabella.items = [];
    this.state.customTag = <></>;
    this.changePage  = this.changePage.bind(this);
    this.state.showAllContents = 	this.props.showAllContents;
    this.state.userInfo = {};
    this.state.userInfo.roles = [];
    this._isMounted = false;
  }

  UNSAFE_componentWillMount(props){
    const $ = window.$;
    common.getUserInfo().then(userInfo =>{
      this.setState({userInfo: userInfo});
      //CONTROLLO SHOW ALL CONTENTS
      if(this.props.pageContent !== undefined && this.props.pageContent !== null && (typeof this.props.pageContent).toLowerCase() === "object" && Object.keys(this.props.pageContent).length > 0 )
        this.setState({recordTabella :  this.props.pageContent});
      if(this.state.userInfo.roles.indexOf("Reviewer") <= -1){
        if(this.state.showAllContents === "false"){
          this.setState({recordTabella: {}});
          $("#ricercaTabella").click((e) => {
            if($("input.search_tabella").val() === undefined || $("input.search_tabella").val() === null || $("input.search_tabella").val() === ''){
              this.setState({recordTabella: {}});
            }else{
              this.setState({recordTabella :  this.props.pageContent});
            }
          });
        }
      }

      var pagineTotali = parseInt(this.state.recordTabella.items_total / env.TABLE_PAGE_LENGTH);
      if((this.state.recordTabella.items_total % env.TABLE_PAGE_LENGTH) > 0){
        pagineTotali ++;
      }

      var newPage = common.getParam("page");
      if(newPage !== null && parseInt(newPage))
        newPage = parseInt(newPage)
      else
        newPage = 1;

      if(newPage>pagineTotali)
        newPage = pagineTotali;
      if(newPage<1)
        newPage = 1;
      this.props.onClick("paginazione_custom", {vaiPagina: newPage} );
    })     
  }

  componentDidMount(props){
    const $ = window.$;
    this._isMounted = true;
    $('#table_ContactTable').DataTable({
      "retrieve": true,
      responsive: true,
      "tabIndex": -1,
      "pageLength": env.TABLE_PAGE_LENGTH,
      "lengthChange": false,
      "paging": false,
      "info": false,
      "ordering": false,
      "searching": false,
      data: this.state.recordTabella.items,
      columns: [
        {
          title: common.getTrad("Nome"),
          data: null,
          "render": (data,type,row) => {
            var ret = '';
            ret = <>{row.description.name}</>;
            return ReactDOMServer.renderToStaticMarkup(
              <>
                {ret}
              </>
            )
          }
        },
        {
          title: common.getTrad("Cognome"),
          data: null,
          "render": (data,type,row) => {
            var ret = '';
            ret = <>{row.description.surname}</>;
            return ReactDOMServer.renderToStaticMarkup(
              <>
                {ret}
              </>
            )
          }
        },
        {
          title: common.getTrad("Reparto"),
          data: null,
          "render": (data,type,row) => {
            var ret = '';
            ret = <>{row.description.department}</>;
            return ReactDOMServer.renderToStaticMarkup(
              <>
                {ret}
              </>
            )
          }
        },
        {
          title: common.getTrad("Email"),
          data: null,
          "render": (data,type,row) => {
            var ret = '';
            ret = <a href={"mailto:" + row.description.email}>{row.description.email}</a>;
            return ReactDOMServer.renderToStaticMarkup(
              <>
                {ret}
              </>
            )
          }
        },
        {
          title: common.getTrad("Telefono"),
          data: null,
          "render": (data,type,row) => {
            var ret = '';
            ret = <a href={"tel:" + row.description.phone}>{row.description.phone}</a>;
            return ReactDOMServer.renderToStaticMarkup(
              <>
                {ret}
              </>
            )
          }
        },
        {
          title: common.getTrad("Cellulare"),
          data: null,
          "render": (data,type,row) => {
            var ret = '';
                ret = <a href={"tel:" + row.description.phone2}>{row.description.phone2}</a>;

            return ReactDOMServer.renderToStaticMarkup(
              <>
                {ret}
              </>
            )
          }
        },
        {
          title: common.getTrad("Sede/Località"),
          data: null,
          "render": (data,type,row) => {
            var ret = '';
            ret = <>{row.description.address}</>;

            return ReactDOMServer.renderToStaticMarkup(
              <>
                {ret}
              </>
            )
          }
        },
        {
          data: null,
          "class": "DT_bottone_link",
          "render": ( data, type, row ) => {
            return ReactDOMServer.renderToStaticMarkup(
              <button data-link={common.getUrlPath(row["@id"])} className="DT_bottone btn bottone_default">
                {common.getTrad("Continua a leggere")}
              </button>
            )
          }
		    },
        {
          title: common.getTrad("Stato"),
          data: "review_state",
          orderable: true,
          "class": 'review_state sorting',
          "width": "1px",
          "type": "string",
          "visible": false,
          "render": (data,type,row) => {
            var statePos = common.object_value_in_array(ACTIONS_STATE_DECODE, "state", data);
            if(statePos > -1){
              return ReactDOMServer.renderToStaticMarkup(
                <i className={ACTIONS_STATE_DECODE[statePos].icon_class + " " + ACTIONS_STATE_DECODE[statePos].color_class} title={common.getTrad(ACTIONS_STATE_DECODE[statePos].title)}></i>
                );
              }
            }
          },
      ],
      "language": {
        "url": env.STATIC + "lib/datatables/i18n/" + common.getLanguage() + ".lang"
      },
      fnRowCallback: ( nRow, aData, iDisplayIndex, iDisplayIndexFull ) => {
				// Row click
        $(nRow).on('click', (e) => {
          e.preventDefault();
          if($("#table_ContactTable").hasClass("collapsed")){
            if(e.currentTarget.classList[1] === "parent" &&  e.target.cellIndex > 0){
              this.changePage(common.getUrlPath(aData["@id"]));
              return false;
            }
            if(e.target.cellIndex > 0 || e.target.cellIndex === undefined){
              this.changePage(common.getUrlPath(aData["@id"]));
              return false;
            }
          }else{
            this.changePage(common.getUrlPath(aData["@id"]));
            return false;
          }
				});
			}
    });

    $("#table_ContactTable").DataTable().responsive.recalc();
    $('table').resize();

    $(".search_tabella").keypress((e) =>{
      if(e.which === 13 || e.which === 10){
        e.preventDefault();
        $("#ricercaTabella").click();
      }
    });

    $('input.search_tabella').on("change keyup cut search", (e) => {
      if(this.props.paginaDati.testo_cercato !== undefined && this.props.paginaDati.testo_cercato !== null && this.props.paginaDati.testo_cercato !== ''){
        e.preventDefault();
        if(!e.target.value)
          this.props.onClick("cerca", {testo: ''});
      }
    });

    $("#ricercaTabella").click((e) => {
      e.preventDefault();
      const $ = window.$;
      var parametri = {
        testo: $("input.search_tabella").val()
      }
      this.props.onClick("cerca", parametri );
    });

    $("#table_ContactTable").on("click", ".DT_bottone", (e) => {
      this._isMounted && this.setState({
        customTag: (<Navigate to={$(e.target).data('link')} push={true}/>)
      });
    });
  }

  paginazioneCustomClick(e){
    e.preventDefault();
    var parametri = {
      vaiPagina: e.target.id.split("_")[2]
    }
    this.props.onClick("paginazione_custom", parametri );
  }

  componentDidUpdate(nextProps){
    const $ = window.$;
    var pagina = nextProps.paginaDati;
    var paginaAttuale = pagina.pagina_attuale, pagineTotali = '';


    //controllo se l'utente è admin, se lo è verrà mostrata nella tabella anche la colonna "stato"
    try{
      if (this.state.userInfo.roles.indexOf("Reviewer") === 1){
        $("#table_ContactTable").DataTable().columns(8).visible(true)
      }
    } catch (e){
      //continue;
    }
    
    pagineTotali = parseInt(this.state.recordTabella.items_total / env.TABLE_PAGE_LENGTH);
    if((this.state.recordTabella.items_total % env.TABLE_PAGE_LENGTH) > 0){
      pagineTotali ++;
    }

    var buttons = [];
    $('#paginazione_custom').html("");
    if(pagineTotali > 1){
			var paginazione_custom_content = "";
      const NUM_PAGINE_VISIBILI = 3;
      const SALTO_PAGINE = 5;
      var start=paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - SALTO_PAGINE;
      var end=paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + SALTO_PAGINE;
      var allPreviousMax = (SALTO_PAGINE - parseInt(NUM_PAGINE_VISIBILI/2));
      var allNextMax = pagineTotali - allPreviousMax;
      var startCiclo = (start <= 0 ? 1 : start);
      var endCiclo = (end > pagineTotali ? pagineTotali : end);

      for(var i=startCiclo; i<=endCiclo; i++){
        var print = false;
        var ellipsis = false;

        if((paginaAttuale <= allPreviousMax && i < paginaAttuale) || (paginaAttuale > allNextMax && i > paginaAttuale))
          print = true;
        if(i >= (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) && i <= (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1))
          print = true;
        if(i === startCiclo || i === endCiclo || i === paginaAttuale)
          print = true;
        if(i !== paginaAttuale && ((i === (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) &&  paginaAttuale >= allPreviousMax) || (i === (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1) && paginaAttuale <= allNextMax)))
          ellipsis = true;

        if(print)
          buttons.push('<li class="paginate_button page-item ' + (paginaAttuale===i ? "active" : "") + '"><p id="page_num_' + i +'" class="page-link">' + (ellipsis ? "&hellip;" : i) + '</p></li>');
      }

			for (var b=0; b<buttons.length; b++){
				if(buttons[b] !== undefined && buttons[b] !== null){
					$('#paginazione_custom').html(paginazione_custom_content + buttons[b]);
					paginazione_custom_content = $('#paginazione_custom').html();
				}
			}
		}

    if(this.state.recordTabella !== undefined){
      if(this.state.recordTabella.items_total > 0){
        var items = this.state.recordTabella.items;
        for(var r=0; r< items.length; r++){
          try{
            items[r].description = JSON.parse(items[r].description);
          }catch(e){
            items[r].description = {}
          }
        }
      }
    }
    $("#table_ContactTable").DataTable().clear();
    if(this.state.userInfo.roles.indexOf("Reviewer") <= -1){
      if(this.state.recordTabella !== undefined){
        if(this.state.recordTabella.items_total > 0){
          $("#table_ContactTable").DataTable().rows.add(this.state.recordTabella.items);
        }
      }
    }else{
      if(this.state.recordTabella.items_total > 0){
        $("#table_ContactTable").DataTable().rows.add(this.state.recordTabella.items);
      }
    }
    $("#table_ContactTable").DataTable().draw();
    $("table>tbody>tr").attr("role", "row");
  }

  changePage(id){
    this._isMounted && this.setState({
      customTag: (<Navigate to={id} push={true}/>)
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render(props){
    return(
      <div className="tab active">
        {this.state.customTag}
        <div className="offset-xl-8 col-xl-4 col-sm-12 mb-2">
          <div className="input-group ">
            <input className="form-control search_tabella" name="q" type="search" placeholder={common.getTrad("Cerca")} aria-label="Cerca"/>
            <button className="btn btn-outline-light bottone_default" id="ricercaTabella" type="submit">
              <i className="fas fa-search"></i> {common.getTrad("Cerca")}
            </button>
          </div>
        </div>
        <table id="table_ContactTable" className="table table-striped table-bordered dt-responsive nowrap" style={{width: '100%'}}></table>  
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="row">
            {this.state.recordTabella.items_total > 0 ?
              <div className="col-lg-5 col-sm-12 col-xs-12">
                <div className="dataTables_info">
                  {common.getTrad("Elementi mostrati") + " (" + this.state.recordTabella.items.length + ") " + common.getTrad(" su un totale di") +" (" + this.state.recordTabella.items_total+ ")"}
                </div>
              </div>
            :
              <></>
            }
            {this.state.recordTabella.items_total > env.TABLE_PAGE_LENGTH ?
              this.state.recordTabella.batching ?
              <div className="col-lg-7 col-sm-12 col-xs-12">
                <div className="dataTables_paginate paging_full_numbers" id="tickets_table_paginate">
                  <ul className="pagination">
                    <li onClick={() => {if(this.state.recordTabella.batching.prev) this.props.onClick("paginazione_custom", {vaiPagina: 1})}}
                        className={(!this.state.recordTabella.batching.prev ? "disabled" : "") + " paginate_button page-item first"}
                        title={common.getTrad("Inizio")}>
                      <p className="page-link"><i className="fas fa-fast-backward"></i></p>
                    </li>
                    <li onClick={() => {if(this.state.recordTabella.batching.prev) this.props.onClick("paginazione_custom", {calcolaPagina: -1})}}
                        className={(!this.state.recordTabella.batching.prev ? "disabled" : "") + " paginate_button page-item previous"} id="table_DefaultTable_previous"
                        title={common.getTrad("Precedente")}>
                      <p className="page-link"><i className="fas fa-step-backward"></i></p>
                    </li>

                    <div style={{display: 'flex'}} id="paginazione_custom" onClick={(e) => this.paginazioneCustomClick(e)}></div>

                    <li onClick={() => {if(this.state.recordTabella.batching.next) this.props.onClick("paginazione_custom", {calcolaPagina: 1})}}
                        className={(!this.state.recordTabella.batching.next ? "disabled" : "") + " paginate_button page-item next"} id="table_DefaultTable_next"
                        title={common.getTrad("Successivo")}>
                      <p className="page-link" ><i className="fas fa-step-forward"></i></p>
                    </li>
                    <li onClick={() => {if(this.state.recordTabella.batching.next) this.props.onClick("paginazione_custom", {vaiPagina: "end"})}}
                        className={(!this.state.recordTabella.batching.next ? "disabled" : "") + " paginate_button page-item last"}
                        title={common.getTrad("Fine")}>
                      <p className="page-link"><i className="fas fa-fast-forward"></i></p>
                    </li>
                  </ul>
                </div>
              </div>
              :
                <></>
            :
              <></>
            }
          </div>
        </div>
      </div>

    )
  }
}


export default ContactTable;
