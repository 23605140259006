import React from 'react';
const common = require('../common/common');
const multibrand = require('../common/multibrand');

class FilterChassis extends React.Component{
	constructor(props){
		super(props);
		this.state = {};
		this.state.tagliando = {};
		this.state.request_url = this.props.request_url;
 	}

	componentDidMount(){
		const $ = window.$;
		let url = "";
		$("#resetta_ricerca").hide();
		$("#btn_cercaT").click((e) => {
			e.preventDefault();
			if($("#EDTNTELA").val() === ''){
				$("input#EDTNTELA").addClass("is-invalid").attr("placeholder", common.getTrad("Inserisci il telaio..."));
			}else{
				$("input#EDTNTELA").removeClass("is-invalid").attr('readonly','readonly');
				$("#btn_cercaT").addClass("loading").attr("disabled", true);

				if(this.state.request_url !== undefined && this.state.request_url !== null){
					multibrand.is_multibrand_portal() ? url = this.state.request_url + "&telaio=" + $("#EDTNTELA").val() : url = this.state.request_url + $("#EDTNTELA").val() + "&marca=" + this.props.marcaDefault
					common.apiCall(url, "GET", {}, true)
					.then(response=>{
						$("#resetta_ricerca").show();
						$("#btn_cercaT, #erroreVIN").hide().removeClass("loading").attr("disabled", false);
						if(response.errorCode){
							$("#erroreVIN").show();
							if(response.message !== undefined && response.message !== null)
						$('#erroreVIN').html(response.message);
						}

						this.props.onClick(response);
					})
				}
			}
		});

		$("#resetta_ricerca").click((e) => {
			e.preventDefault();
			$("select#select_model option[value='"+ window.valoreModello + "']").removeAttr("selected","selected");
			$("input#EDTNTELA").val("").removeAttr('readonly');
			$('#ricerca_filtro_modello .select_modelli').val('').trigger('change');
 			$("#btn_cercaT").show();
			$("#resetta_ricerca,#erroreVIN,#erroreModello").hide();
			window.valoreModello = "";
			window.numeroTelaio = "";
			$('.select_modelli').keyup();
			this.changeVinCharCounter(e);
			this.props.onClick({});
		});

	}

	changeVinCharCounter(e){
		const $ = window.$;
		var strNtela = $("#EDTNTELA").val();
		strNtela = strNtela.replace(/\s+/g, '');
		$("#EDTNTELA").val(strNtela);

		var count = 17 - strNtela.length;
		$("#charCounterVIN").text(count);
		if(count > 0)
			$("#charCounterVIN").addClass("text-danger");
		else
			$("#charCounterVIN").removeClass("text-danger");

		if(e.which === 10 || e.which === 13)
			$("#btn_cercaT").click();
	}

	render(props){
		const $ = window.$;
		var count = 17;
		if($("#EDTNTELA").val() !== undefined && $("#EDTNTELA").val().length !== undefined)
			count = count - $("#EDTNTELA").val().length;

		var marcaDefault = '';
		if(common.get_localStorage("valoreMarca") !== undefined)
			marcaDefault = common.get_localStorage("valoreMarca");
		else
			marcaDefault = this.props.marcaDefault;

		var is_disabled = false;
		if(this.props.marche_attive !== undefined && this.props.marche_attive.length > 1){
			if(marcaDefault === '' || marcaDefault === 'tutti_modelli')
				is_disabled = true;
		}

		return(
      <div className={(this.props.customClass !== undefined && this.props.customClass !== '' ? this.props.customClass : "col-xl-6 col-lg-6") +  " col-md-12 col-sm-12 col-xs-12 row-eq-height"} role="alert" id="ricerca_filtro_telaio">
        <div className="col-12 p-0">
          <p>{common.getTrad("Ricerca Telaio")}</p>
          <div className="input-group">
						<input disabled={is_disabled} placeholder={is_disabled ? common.getTrad("Seleziona prima una marca") : common.getTrad("Ricerca Telaio")} name="EDTNTELA" id="EDTNTELA" onKeyUp={(e) => this.changeVinCharCounter(e)} className="form-control input-sm text-uppercase" type="text" maxLength="17" />
            <span className="input-group-btn bottone_ricerca">
              <button disabled={is_disabled} id="btn_cercaT" className="btn btn-light">
							  <span className="d-none d-md-block d-sm-block d-md-none">
                  <span title={common.getTrad("Cerca")} className="fas fa-search initialIcon"></span>
                  <span className="fas fa-spinner fa-spin loadingIcon"></span>
                </span>
							  <span className="d-block d-sm-none">
                  <span className="fas fa-search initialIcon"></span>
                  <span className="fas fa-spinner fa-spin loadingIcon"></span>
                </span>
							</button>
							<button id="resetta_ricerca" className="btn btn-light">
                <span title={common.getTrad("Resetta")} className="fas fa-redo initialIcon"></span>
                <span className="fas fa-spinner fa-spin loadingIcon"></span>
              </button>
            </span>
          </div>
					<small className="text-muted">
						{common.getTrad("Caratteri rimanenti")}: <span id="charCounterVIN" className={count > 0 ? "text-danger" : ""}>{count}</span>
					</small>
					{this.state.request_url !== undefined && this.state.request_url !== null ?
						<div style={{display: 'none'}} id="erroreVIN" className="sw-alert alert alert-warning" role="alert"></div>
					:
						<></>
					}
        </div>
      </div>
    )
  }
}

export default FilterChassis;
