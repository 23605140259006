import React from 'react';
const common = require('../common/common');

class Loader extends React.Component {
  componentDidMount(){

  }
  render(props){
    return (
      <div className="content_testo tab-content">
        <div id="content-core">
          <div className="row background_aggiornamento">
            <div className="col-xl-12 text-center content_error">
              <div>
                <h1>
                  <i className="fas fa-spinner fa-spin"></i>
                </h1>
                <div className="w-100"></div>
                <h3>{common.getTrad("Caricamento in corso...")}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Loader;
