import React from 'react';
const common = require('../common/common');
const env = require("../../config.js").default;

class CondizioniUtilizzo extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.type = this.props.type;
    this.renderTerms = this.renderTerms.bind(this);
  }
  componentDidMount(props){
    const $ = window.$;
    this.setState({type: this.props.type});

    $(document).ready(function(){
      $(".policyLink").click(function(e){
        e.preventDefault();
        var terms = JSON.parse(common.get_localStorage("terms"));
        var n = e.target.dataset.number;
        var element = terms[n];

        if(element.type === "html"){
          common.apiCall(env.BACKEND + element.file, "GET", {}, false, false, "text")
          .then((response) => {
            $("#policy_content_" + element.name).html(response);
            $("#policy_content_" + element.name).fadeIn("fast");
          })
        }
        else{
          var win = window.open(env.BACKEND + element.file, '_blank');
          win.focus();
        }
      });
    });

  }

  renderTerms(){
    var terms = JSON.parse(common.get_localStorage("terms"));

    return terms.map((index, key) =>
      <div className="col-xl-12" key={key} id={"container" + index.name}>
        <h4>{index.title}</h4>
        <p>
          <a href="/#" key={key} id={"policy_link_" + index.name} className="policyLink" data-number={key}>
            {
              index.type === "download" ?
                <i className="fas fa-download"></i>
              :
                <i className="fas fa-eye"></i>
            }
            &nbsp;{index.description}
          </a>
        </p>
        <div className="col-md-12">
          <div className="form-group">
            { index.type === "html" ?
              <div className="policy_privacy_content" id={"policy_content_" + index.name}></div>
              :<></>
            }
            <div className="c-inputs-stacked">
              <div className="d-inline-block custom-control custom-checkbox">
                <input type="checkbox" name={index.name} className="custom-control-input required" required  id={index.name} />
                <label className="custom-control-label" htmlFor={index.name}>{index.flag_text}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
  	);
  }

  render(props){
    return(
      <>
        <h6><i className="step-icon fas fa-book" title="Termini & condizioni"></i></h6>
        <fieldset className="mt-2 col">
          <div className="row">
            {window.cc.terms !== '' && window.cc.terms !== undefined && window.cc.terms.length > 0 ?
              this.renderTerms()
            :
              <></>
            }
          </div>
          <div className="col">
            <button type="submit" className="btn btn-primary float-right mb-3">{common.getTrad("Registrati")}</button>
          </div>
        </fieldset>
      </>
    )
  }
}

export default CondizioniUtilizzo;
