import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Navigate, Link } from "react-router-dom";
import GestioneContenutiSidebar from "../Sidebar/GestioneContenutiSidebar";
import { WorkflowButton, ACTIONS_STATE_DECODE } from "../Sidebar/GestioneContenutiSidebar";
import bootbox from 'bootbox';
import moment from 'moment';
const common = require('../common/common');
const env = require("../../config.js").default;

try {
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
}
class GestioneContenutiTable extends React.Component{
	constructor(props){
		super(props);
		this.state = {};
		this.state.pathname = this.props.pathname;
		this.state.recordTabella = {};
		this.state.customTag = <></>;
		this.changePage  = this.changePage.bind(this);
		this.state.userInfo = {};
		this.state.userInfo.roles = [];
		this._isMounted = false;
		this.state.actionCustom = false;
		this.state.isCheckboxChecked = false;

		this.state.dataRecord = {
			review_state: "private",
			id: "",
			num: "",
			type: "",
      workflow: [],
		};
	}

  async handleDownloadFile(id,fileurl){
    const $ = window.$;

    $(".downloadFileLoader_" + id).css("display", "inline");
    $(".downloadIconFile_" + id).css("display", "none");
    await common.apiCall(fileurl, "GET", {}, true, false, "json")
    .then(data => {
      var jsonFile = data.file;
      var numItem = 0;
      if(data.relatedItems !== undefined && data.relatedItems.length > 0){
        numItem = common.object_value_in_array(data.relatedItems, "@type", "File");
        if(numItem > -1)
          jsonFile = {
            download: data.relatedItems[numItem]['@id'] + "/@@download/file/" + data.relatedItems[numItem]['title'],
            filename: data.relatedItems[numItem]['title'],
          }
      }

      common.apiCall(jsonFile.download, "GET", {}, true, false, "blob")
      .then(result => {
        var element = document.createElement('a');
        element.setAttribute('href', result);
        element.setAttribute('download', jsonFile.filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
        $(".downloadFileLoader_" + id).css("display", "none");
        $(".downloadIconFile_" + id).css("display", "inline");
      });

      return data;
    });
    return;
  }

	async actionHandlerClick(element, empty, titleFolder, typeAction){
		this.setState({actionCustom: true});
		const $ = window.$;
		$("#dropdownTableMenu").hide();
		var items = $.map($("#table_GestioneContenutiTable").DataTable().rows('.selected_row_table').data(),  (item) => {
			return {parent: item.parent['@id'], id: item['@id']}
		});
		if(items.length <= 0){
			bootbox.alert(common.getTrad("Seleziona almeno un record in tabella prima di procedere con l'azione scelta."));

		}else{
			var sources = [];
			for (var i=0; i< items.length; i++){
				if(typeAction === '@sw-move' && (items[i].parent + "/") === element)
					return false;

				if(sources.indexOf(items[i].id) <= -1)
					sources.push(items[i].id)
			}

			$("#loadingDivContenuti").show();
			$('#content_page').css({
				'height': 'calc(100vh - var(--header-height) - var(--footer-height))',
				'overflow-y': 'hidden'
			});
			if(typeAction === '@sw-move'){
				if(items.length === 1)
					$('#messageCaricamento').html(common.getTrad("Il documento selezionato verrà spostato nella cartella") +  " <b>" + titleFolder + "</b>");
				else
					$('#messageCaricamento').html(common.getTrad("I documenti selezionati verranno spostati nella cartella") +  " <b>" + titleFolder + "</b>");
			}

			if(typeAction === '@sw-copy'){
				if(items.length === 1)
					$('#messageCaricamento').html(common.getTrad("Il documento selezionato verrà copiato nella cartella") +  " <b>" + titleFolder + "</b>");
				else
					$('#messageCaricamento').html(common.getTrad("I documenti selezionati verranno copiati nella cartella") +  " <b>" + titleFolder + "</b>");
			}

			var data = {source: sources};
			var url = common.getBackendUrl(element + "/" + typeAction), response;
			response = await common.apiCall(url, "POST", data, true, false, "json");
			if(response.error === false){
				if(typeAction === '@sw-move'){
					if(items.length === 1)
						$('#messageCaricamento').html(common.getTrad("Il documento selezionato è stato spostato correttamente nella cartella") +  " <b>" + titleFolder + "</b>");
					else
						$('#messageCaricamento').html(common.getTrad("I documenti selezionati sono stati spostati correttamente nella cartella") +  " <b>" + titleFolder + "</b>");
				}
				if(typeAction === '@sw-copy'){
					if(items.length === 1)
						$('#messageCaricamento').html(common.getTrad("Il documento selezionato è stato copiato correttamente nella cartella") +  " <b>" + titleFolder + "</b>");
					else
						$('#messageCaricamento').html(common.getTrad("I documenti selezionati sono stati copiati correttamente nella cartella") +  " <b>" + titleFolder + "</b>");
				}
				common.clear_cache_after_save("swcontent");
			}else{
        console.log('ERRORE IN FASE DI ' + typeAction + ': E: ' + response.error + " M:  " + response.message);
        response = JSON.parse(response)
        $('#messageCaricamento').html('<div class="alert alert-danger animated">' + common.getTrad("Errore qualcosa è andato storto.") + " <b>" + response["type"] + "</b> " + response["message"]
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>'
        +'</div>');
      }

			if(response){
				setTimeout(() => {
					var parametri = {
						vaiPagina: 1
					}
					this.props.onClick("paginazione_custom", parametri);
					this.setState({actionCustom: false});
          $('#messageCaricamento').html('');
					$('#messageCaricamento').removeClass("text-danger");
				}, 5000);
				$("table input").removeAttr("checked", true);
			}
		}
	}

	changeReviewState(data){
    var pageContent = this.state.recordTabella.items[data.id];
    pageContent.review_state = data.review_state;
    pageContent["@components"]["workflow"]["transitions"] = data.workflow_transitions;
    this.setState({pageContent});
  }

	multipleActions(element, action){
		const $ = window.$;
    	var titleFolder = $(".list-group-item.active").text();
		if(action){
      $("#loadingDivContenuti").show();
	  $('#content_page').css('overflow-y', 'hidden');
      var selectedRows = $("#table_GestioneContenutiTable").DataTable().rows('.selected_row_table').data();
      if(action === 'CHANGE_STATE' || action === 'DELETE'){
        if(selectedRows.length <= 0)
  				bootbox.alert(common.getTrad("Seleziona almeno un record in tabella prima di procedere con l'azione scelta."));

        var source = [];
        for (var i = 0; i < selectedRows.length; i++) {
          source.push({
            uid: selectedRows[i]['UID'],
            id: common.object_value_in_array(this.state.recordTabella.items, 'id', selectedRows[i].id)
          });
        }
        var data = {
    			action: action,
          source: source,
        };

        if(element !== null){
          data['new_transition'] = $(element.target).attr('data-state');
          data['title_transition'] = $(element.target).attr('data-title');
        }

        if(source.length > 1){
          if(action === 'DELETE')
            $('#messageCaricamento').html(common.getTrad("I documenti verranno cancellati dalla cartella") +  " <b>" + titleFolder + "</b>");
          if(action === 'CHANGE_STATE')
            $('#messageCaricamento').html(common.getTrad("I documenti verranno cambiati in {{nuovo_titolo_stato}}", {nuovo_titolo_stato: $(element.target).attr('data-title') }));
        }else{
          if(action === 'DELETE')
            $('#messageCaricamento').html(common.getTrad("Il documento verrà cancellato dalla cartella") +  " <b>" + titleFolder + "</b>");
          if(action === 'CHANGE_STATE')
            $('#messageCaricamento').html(common.getTrad("Il documento verrà cambiato in {{nuovo_titolo_stato}}", {nuovo_titolo_stato: $(element.target).attr('data-title') }));
        }

        var recordTabella = this.state.recordTabella;
        var recordDelete = [];
        common.apiCall(common.getBackendUrl(this.props.pathname) + '/@sw-batch-documents', "POST", data, true, false, "json")
        .then(response => {
          if(!response.error && response.length > 0){
			common.clear_cache_after_save("swcontent");
            $("#dropdownTableMenu").hide();
            for (let i = 0; i < response.length; i++) {
              if(action === 'CHANGE_STATE')
                this.changeReviewState(response[i]);

              if(action === 'DELETE')
                recordDelete.push(response[i].id);

              if(i + 1 === response.length){
                if(source.length > 1){
                  if(action === 'DELETE')
                    $('#messageCaricamento').html(common.getTrad("I documenti sono stati cancellati correttamente dalla cartella") +  " <b>" + titleFolder + "</b>");
                  if(action === 'CHANGE_STATE')
                    $('#messageCaricamento').html(common.getTrad("I documenti sono stati cambiati correttamente in {{nuovo_titolo_stato}}", {nuovo_titolo_stato: data.title_transition }));
                }else{
                  if(action === 'DELETE')
                    $('#messageCaricamento').html(common.getTrad("Il documento è stato cancellato correttamente dalla cartella") +  " <b>" + titleFolder + "</b>");
                  if(action === 'CHANGE_STATE')
                    $('#messageCaricamento').html(common.getTrad("Il documento è stato cambiato correttamente in {{nuovo_titolo_stato}}", {nuovo_titolo_stato: data.title_transition }));
                }

                $("th.select-checkbox, tr").removeClass("selected_row_table");
                $("table input").removeAttr("checked", true);
                $('.dropdown-toggle').prop('disabled', true);
                setTimeout( () => {
                  $('#messageCaricamento').html("");
                  $("#loadingDivContenuti").hide();
				  $('#content_page').css('overflow-y', 'auto');
                }, 5000);
              }
            }

            if(action === 'DELETE'){
              for (let i = recordDelete.length -1; i >= 0; i--){
                recordTabella.items.splice(recordDelete[i], 1);
              }
              recordTabella.items_total = (this.state.recordTabella.items_total - recordDelete.length);
              if(recordTabella.length <= 0 || (this.state.recordTabella.items.length < env.TABLE_PAGE_LENGTH && recordTabella.items_total >= env.TABLE_PAGE_LENGTH))
                this.props.onClick("paginazione_custom", {vaiPagina: 1});
              else
                this.setState({recordTabella: recordTabella});
            }
          }else{
            $("th.select-checkbox, tr").removeClass("selected_row_table");
            $("table input").removeAttr("checked", true);
            $('.dropdown-toggle').prop('disabled', true);
            $("#loadingDivContenuti").hide();
			$('#content_page').css('overflow-y', 'auto');
            $('#messageCaricamento').html('');
          }
        });
      }

      if(action === 'SHOW')
        this.changePage(common.getUrlPath(selectedRows[0]['@id']));

      if(action === 'EDIT')
        this.changePage(common.getUrlPath(selectedRows[0]['@id']) + "/@@edit");
    }
	}

	UNSAFE_componentWillMount(props){
		common.getUserInfo().then(userInfo =>{
			this.setState({userInfo: userInfo});
			this.setState({recordTabella: this.props.pageContent});
    });
    window.$("#dropdownTableMenu").remove();
	}

	componentDidMount = (props) =>{
		const $ = window.$;
		this._isMounted = true;
		$("#table_GestioneContenutiTable").DataTable({
			"retrieve": true,
			responsive: true,
      		"tabIndex": -1,
			"pageLength": env.TABLE_PAGE_LENGTH,
			"lengthChange": false,
			"paging": false,
			"info": false,
			"ordering": false,
			"searching": false,
			"autoWidth":  true,
			data: this.state.recordTabella.items,
			order: [[ 4, 'asc' ]],
			columns: [
				{
					"class": "select-checkbox",
          			orderable: false,
          			data: null,
					"render": (data,type,row,meta) => {
						return ReactDOMServer.renderToStaticMarkup (
							<div className="custom-control custom-checkbox my-1 mr-sm-2">
								<input type="checkbox"  className="custom-control-input" id={"check_" + meta.row + "_D"}  />
								<label className="custom-control-label" htmlFor={"check_" + meta.row + "_D"}></label>
							</div>
						)
					},
					"width": "10px",
				},
				{
					title: common.getTrad("Titolo"),
					data: 'title',
					"width": "200px",
					"class": 'sortable_title sorting descriptionTable',
					"render": (data,type,row) => {
						var ret = '';
						if(data !== null && data !== '' && data !== undefined){
							if(data.length > 75){
								ret = <>{truncateOnWord(data, 75)}</>;
							}else{
								ret = <>{data}</>;
							}
						}
						return ReactDOMServer.renderToStaticMarkup(
							<>{ret}</>
						)
					}
				},
        		{
          			title: common.getTrad("Riassunto"),
					data: 'description',
					orderable: false,
					"class": "descriptionTable",
					"width": "150px",
					"render":  (data,type,row,meta) => {
            		var ret = '';
            		if(data !== null && data !== '' && data !== undefined){
						if(data.length > 120){
							ret = <>{truncateOnWord(data, 120)}</>;
						}else{
							ret = <>{data}</>;
						}
            		}
            		return ReactDOMServer.renderToStaticMarkup(
              			<>
							<span className="descrText">
								{ret}&nbsp;
							</span>
							{row.subjects !== undefined && row.subjects.indexOf("sw-has-file") > -1 ?
							<span className="ContentcellTools descrText" id={"ContentcellTools_" + meta.row} data-btn-download={meta.row}>
								<button style={{display: "flex", width: "42px"}} className="bottone_default btn downloadButton float-righ" id={"downloadButton_" + meta.row} data-btn-download={meta.row}>
								<span className={"downloadIconFile_" + meta.row} data-btn-download={meta.row}>
									<i className="fas fa-download" data-btn-download={meta.row}></i>
								</span>
								<span className={"downloadFileLoader downloadFileLoader_" + meta.row}><i className="fas fa-spinner fa-spin"></i></span>
								</button>
							</span>
							:
							<></>
							}
						</>
					);
          		}
        	},
			{
				title: common.getTrad("Lingue"),
				data: 'subjects',
          		orderable: false,
				"class": "list-inline",
				"width": "10px",
				"render": (data,type,row) => {
					var ret = "";
					window.cc.languages.map(function(lang, key){
						var codiceLingua = "sw-lang-" + lang;
						ret += '<li class="list-inline-item inlineLangFlags ' + (data.indexOf("sw-lang-all") > -1 || data.indexOf(codiceLingua) > -1 ? "" : "disabled") + '">';
						ret += '<img title="' + common.getTrad("lingua_" + lang.toUpperCase()) + '" alt=' + common.getTrad("lingua_" + lang.toUpperCase()) + ' class="img-fluid bandiere" src="' + env.STATIC + "images/bandiere/" + lang.toLowerCase() + ".svg" + '" />';
						ret += '</li>';

						return false
            		})
            		return ret;
				}
			},
			{
				title: common.getTrad("Data ultima modifica"),
				data: 'modified',
				"width": "10px",
				type: 'datetime-moment',
				"class": 'modified sorting sorting_desc',
				"render": function ( data, type, row) {
					return moment(data).format('L')
				}
			},
			{
				title: common.getTrad("Data Pubblicazione"),
				data: 'effective',
				"width": "10px",
				type: 'datetime-moment',
				"class": 'effective sorting',
				"render": function ( data, type, row) {
					return moment(data).format('L')
				}
			},
			{
				title: common.getTrad("Data Scadenza"),
				data: null,
				"width": "10px",
				type: 'datetime-moment',
				"class": 'expires sorting',
				"render": function ( data, type, row) {
					return row.expires !== undefined && row.expires !== null && row.expires !== '' ? moment(row.expires).format('L') : ""
				}
			},
        	{
				title: common.getTrad("Stato"),
				data: "review_state",
				orderable: true,
				"class": 'review_state sorting',
				"width": "1px",
				"type": "string",
				"render": (data, type, row) => {
					const statePos = common.object_value_in_array(ACTIONS_STATE_DECODE, "state", data);
					if (statePos > -1) {
						let iconHtml = `<i class="${ACTIONS_STATE_DECODE[statePos].icon_class} ${ACTIONS_STATE_DECODE[statePos].color_class}" title="${common.getTrad(ACTIONS_STATE_DECODE[statePos].title)}"></i>`;

						let effectiveDate = new Date(moment(row.effective)).getTime();
						const today = new Date().getTime();

						if (today < effectiveDate) {
							iconHtml += ` <i class="fas fa-calendar-alt text-primary" title="` + common.getTrad("Data Pubblicazione ") + moment(row.effective).format('L')+`"></i>`;
						}


						return ReactDOMServer.renderToStaticMarkup(
							<span dangerouslySetInnerHTML={{ __html: iconHtml }} />
						);
					}
					return <></>;
				}
			},
		],
		"language": {
			"url": env.STATIC + "lib/datatables/i18n/" + common.getLanguage() + ".lang"
		},
		createdRow: ( row, data, dataIndex ) => {
        	$(row).attr('title', data.id);
		},
		fnRowCallback: ( nRow, aData, displayNum, iDisplayIndex, iDisplayIndexFull ) => {
        	$(nRow).attr('data-number', displayNum);
			// click singola riga
			$(nRow).on("click", (e) => {
				e.preventDefault();
				if($(e.target).data('btn-download') === iDisplayIndexFull){
					if(!$("#table_GestioneContenutiTable").hasClass("collapsed")){
              			if(aData.subjects !== undefined && aData.subjects.indexOf("sw-has-file") > -1){
                			this.handleDownloadFile(iDisplayIndex, aData['@id'], aData.id);
                			return false;
						}
            		}
				}else{
            		if(!$("#check_" + iDisplayIndexFull + "_D").is(":checked")){
  						$("#check_" + iDisplayIndexFull + "_D").attr("checked", true);
  						$(nRow).addClass("selected_row_table");
  					}else{
  						$("#check_" + iDisplayIndexFull + "_D").removeAttr("checked", true);
  						$(nRow).removeClass("selected_row_table");
  					}
  					var count = $("#table_GestioneContenutiTable").DataTable().rows('.selected_row_table').count();
  					if(count === 0)
  						$("table th.selected_row_table").removeClass("selected_row_table");
          		}
          		if(count > 0)
            		$('.dropdown-toggle').prop('disabled', false);
          		else
            		$('.dropdown-toggle').prop('disabled', true);
			});

			$(nRow).on("dblclick", (e) => {
				e.preventDefault();
				this.changePage(common.getUrlPath(aData["@id"]));
				return false;
			});
				// controllo quale click effettivamente è stato fatto destro o sinistro del mouse
			$(nRow).mousedown((e) => {
				$("#dropdownTableMenu .dropdown-menu").removeClass("show");
				switch (e.which) {
					case 1:
						$("#dropdownTableMenu").hide();
					break;
					case 3:
						$(nRow).bind("contextmenu", (e) => {
							// Lavora con il set state che ri renderizza
							e.preventDefault();
            				$('.dropdown-toggle').prop('disabled', false);
							if(!$("#check_" + iDisplayIndexFull + "_D").is(":checked"))
								$(nRow).click();
							
							var menuVisible = false;
							var menu = $("#dropdownTableMenu");
							$("#newUrlText").val(this.state.dataRecord.id.split('/').pop())
							$("body").on("click", (e) => {
								if (!$(e.target).closest('#dropdownTableMenu, #newUrlText, #content_sidebar').length) {
									if(menuVisible)
									toggleMenu("none");
								}
							});
							function toggleMenu(command) {
								menu.css("display", command); // "block"  o "none"
								menuVisible = !menuVisible;
							};
							
							var element = menu.detach();
							
							toggleMenu("block");
							var offset = $("#table_GestioneContenutiTable_wrapper").offset();
							var relativeX = (e.pageX - offset.left);
							var relativeY = (e.pageY - offset.top);
							
							this.setState({
								dataRecord: {
									review_state: aData.review_state,
									id: aData['@id'],
									num: iDisplayIndexFull,
									type: aData["@type"],
									workflow: aData["@components"]["workflow"]["transitions"],
								},
								contexMenuOpen: menuVisible,
							}, () => {
								$("#table_GestioneContenutiTable_wrapper").append(element);
								$("#check_" + iDisplayIndexFull + "_D").attr("checked", true);
								$("tbody tr:eq(" + iDisplayIndex + ")").addClass("selected_row_table");
							});

							
							var windowHeight = $("#table_GestioneContenutiTable_wrapper").height()/2;
							var windowWidth = $("#table_GestioneContenutiTable_wrapper").width()/2;
							if(relativeY > windowHeight && relativeX <= windowWidth) {
								menu.css("left", relativeX);
								menu.css("bottom", $("#table_GestioneContenutiTable_wrapper").height()-relativeY);
								menu.css("right", "auto");
								menu.css("top", "auto");
								$("#dropdownTableMenu .btn-group .dropdown-submenu").removeClass("dropleft");
							} else if(relativeY > windowHeight && relativeX > windowWidth) {
								menu.css("left", relativeX - menu.width());
								menu.css("bottom", $("#table_GestioneContenutiTable_wrapper").height()-relativeY);
								menu.css("top", "auto");
								$("#dropdownTableMenu .btn-group .dropdown-submenu").addClass("dropleft");
							} else if(relativeY <= windowHeight && relativeX <= windowWidth) {
								menu.css("left", relativeX);
								menu.css("top", relativeY);
								menu.css("right", "auto");
								menu.css("bottom", "auto");
								$("#dropdownTableMenu .btn-group .dropdown-submenu").removeClass("dropleft");
							} else {
								menu.css("right", $("#table_GestioneContenutiTable_wrapper").width()-relativeX);
								menu.css("top", relativeY);
								menu.css("left", "auto");
								menu.css("bottom", "auto");
								$("#dropdownTableMenu .btn-group .dropdown-submenu").addClass("dropleft");
							}
						});
						break;
						default:
							return ""
					}
				})
			}
		});

		$("#table_GestioneContenutiTable").DataTable().responsive.recalc();
		$('table').resize();

		$('#table_GestioneContenutiTable thead').unbind("click");
		$('#table_GestioneContenutiTable thead').on("click", (e) => {
			if((e.target.className.indexOf("select-checkbox") > -1) && this.state.recordTabella.items_total > 0){
				if ($("th.select-checkbox").hasClass("selected_row_table")) {
					$("th.select-checkbox, tr").removeClass("selected_row_table");
					$("table input").removeAttr("checked", true);
				} else {
					$("th.select-checkbox, tr").addClass("selected_row_table");
					$("table input").attr("checked", true);
				}
			}

      		var number_elem = $("#table_GestioneContenutiTable").DataTable().rows('.selected_row_table').count();
      		if(number_elem > 0)
        		$('.dropdown-toggle').prop('disabled', false);
      		else
        		$('.dropdown-toggle').prop('disabled', true);

			var versoOrdinam = 0;
			if($(e.target).hasClass("sorting")){
				if(this.props.paginaDati.verso_ordinamento === 1){
					versoOrdinam = -1;
					$(".sorting_disabled").removeClass("sorting_asc");
					$(e.target).addClass("sorting_desc");
				}else{
					versoOrdinam = 1;
					$(".sorting_disabled").removeClass("sorting_desc");
					$(e.target).addClass("sorting_asc");
				}

				var parametri = {
					tipoOrdinam: e.target.className.split(" ")[1],
					versoOrdinam: versoOrdinam
				}
				this.props.onClick("ordinamento", parametri);
			}
		});

		$(".search_tabella").keypress((e) =>{
			if(e.which === 13 || e.which === 10){
				e.preventDefault();
				$("#ricercaTabella").click();
			}
		});

    $('input.search_tabella').on("change keyup cut search", (e) => {
      if(this.props.paginaDati.testo_cercato !== undefined && this.props.paginaDati.testo_cercato !== null && this.props.paginaDati.testo_cercato !== ''){
        e.preventDefault();
        if(!e.target.value)
          this.props.onClick("cerca", {testo: ''});
      }
    });

		$("#ricercaTabella").click((e) => {
			e.preventDefault();
			const $ = window.$;
			var parametri = {
				testo: $("input.search_tabella").val()
			}
			this.props.onClick("cerca", parametri);
		});
		
    $("#table_GestioneContenutiTable").on("click", ".ContentcellTools ", (e) => {
      if($("#table_GestioneContenutiTable").hasClass("collapsed")){
        var rowNum = $(e.target).data('btn-download');
        if(this.state.recordTabella.items[rowNum].subjects !== undefined && this.state.recordTabella.items[rowNum].subjects.indexOf("sw-has-file") > -1)
          this.handleDownloadFile(rowNum, this.state.recordTabella.items[rowNum]['@id']);
        return false;

      }
    });
	}

	paginazioneCustomClick(e){
		e.preventDefault();
		var parametri = {
			vaiPagina: e.target.id.split("_")[2]
		}
		this.props.onClick("paginazione_custom", parametri);
	}


	componentDidUpdate(nextProps){
		const $ = window.$;

		if (!this.state.contexMenuOpen && !this.state.actionCustom && this.state.recordTabella.items_total >= 0){
			$("#dropdownTableMenu").hide();
		}
		var pagina = nextProps.paginaDati;
		var paginaAttuale = (parseInt(pagina.pagina_attuale) ? parseInt(pagina.pagina_attuale) : 1), pagineTotali = '';

		pagineTotali = parseInt(this.state.recordTabella.items_total / env.TABLE_PAGE_LENGTH);
		if((this.state.recordTabella.items_total % env.TABLE_PAGE_LENGTH) > 0){
			pagineTotali ++;
    }

		var buttons = [];
		$('#paginazione_custom').html("");
    if(pagineTotali > 1){
			var paginazione_custom_content = "";
      const NUM_PAGINE_VISIBILI = 3;
      const SALTO_PAGINE = 5;
      var start=paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - SALTO_PAGINE;
      var end=paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + SALTO_PAGINE;
      var allPreviousMax = (SALTO_PAGINE - parseInt(NUM_PAGINE_VISIBILI/2));
      var allNextMax = pagineTotali - allPreviousMax;
      var startCiclo = (start <= 0 ? 1 : start);
      var endCiclo = (end > pagineTotali ? pagineTotali : end);

      for(var i=startCiclo; i<=endCiclo; i++){
        var print = false;
        var ellipsis = false;

        if((paginaAttuale <= allPreviousMax && i < paginaAttuale) || (paginaAttuale > allNextMax && i > paginaAttuale))
          print = true;
        if(i >= (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) && i <= (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1))
          print = true;
        if(i === startCiclo || i === endCiclo || i === paginaAttuale)
          print = true;
        if(i !== paginaAttuale && ((i === (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) &&  paginaAttuale >= allPreviousMax) || (i === (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1) && paginaAttuale <= allNextMax)))
          ellipsis = true;

        if(print)
          buttons.push('<li class="paginate_button page-item ' + (paginaAttuale===i ? "active" : "") + '"><p id="page_num_' + i +'" class="page-link">' + (ellipsis ? "&hellip;" : i) + '</p></li>');
      }

			for (var i=0; i<buttons.length; i++){
				if(buttons[i] !== undefined && buttons[i] !== null){
					$('#paginazione_custom').html(paginazione_custom_content + buttons[i]);
					paginazione_custom_content = $('#paginazione_custom').html();
				}
			}
		}

		$("#table_GestioneContenutiTable").DataTable().clear();

		if(this.state.recordTabella.items_total > 0)
			$("#table_GestioneContenutiTable").DataTable().rows.add(this.state.recordTabella.items);

		$("#table_GestioneContenutiTable").DataTable().draw();
		$("#table_GestioneContenutiTable").DataTable().responsive.recalc();
		$('table').resize();
    $("table>tbody>tr").attr("role", "row");
	}

	changePage(id){
		this._isMounted && this.setState({
			customTag: (<Navigate to={id} push={true}/>)
		})
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render(props){
    var trans = [];
    var actions_added = [];
    var tab, trans_action, trans_items;
		tab = this.state.recordTabella;
    if((tab.items_total !== undefined) && (tab.items_total > 0)){
			for (var i=0; i< tab.items.length; i++){
        if(tab.items[i]["@components"]["workflow"]["transitions"] !== undefined){
          trans_items = tab.items[i]["@components"]["workflow"]["transitions"];
  				for (var j=0; j< trans_items.length; j++){
            trans_action = trans_items[j]["@id"].split("/").pop();
  					if (actions_added.indexOf(trans_action) <= -1){
  						actions_added.push(trans_action);
  						trans.push(trans_items[j]);
  					}
          }
        }
      }
    } else {
      trans = "";
    }

		return(
			<>
				<div id="notifica_messaggio"></div>
		    <div className="row">
					<div className="mb-3 col">
						<div className="btn-group btn-group-sm mr-xl-5" role="group" aria-label="Basic example">
							<Link to={this.props.pathname ? common.getUrlPath(this.props.pathname + "/@@new") : ""}>
								<button type="button" className="btn btn-light" disabled={this.props.pathname ? false : true}>
									<span>+</span> {common.getTrad("Nuovo contenuto")}
								</button>
							</Link>
						</div>
						<div className="btn-group btn-group-sm mr-xl-2" role="group" aria-label="Basic example">
							<div className="btn-group" style={{width: "100%"}} >
								<button className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={this.props.pathname && this.props.pathname.url !== '' ? false : true}>
									<i className="far fa-copy"></i> {common.getTrad("Copia in")}
								</button>
								<div className="dropdown-menu">
									<GestioneContenutiSidebar menuAreaButtons={this.props.menuAreaButtons} render1={this.props.render1} onClick={(e, empty, data) => this.actionHandlerClick(e,null, data, "@sw-copy")} classBloccoMenu="blocco_menu_tabella"></GestioneContenutiSidebar>
								</div>
							</div>
						</div>
						<div className="btn-group btn-group-sm mr-xl-2" role="group" aria-label="Basic example">
							<div className="btn-group" style={{width: "100%"}} >
								<button className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={this.props.pathname && this.props.pathname.url !== '' ? false : true}>
									<i className="fas fa-file-export"></i> {common.getTrad("Sposta in")}
								</button>
								<div className="dropdown-menu">
									<GestioneContenutiSidebar pathname={this.props.pathname} menuAreaButtons={this.props.menuAreaButtons} render1={this.props.render1} onClick={(e, empty, data) => this.actionHandlerClick(e,null, data, "@sw-move")} classBloccoMenu="blocco_menu_tabella"></GestioneContenutiSidebar>
								</div>
							</div>
						</div>
						<div className="btn-group btn-group-sm mr-xl-2" role="group" aria-label="Basic example">
							<div className="btn-group" style={{width: "100%"}} >
								<button className="btn btn-light dropdown-toggle" type="button" onClick={(e, action) =>
									bootbox.confirm({
										message: common.getTrad("L'azione cancellerà in modo definitivo il contenuto selezionato.<br><b>Si è sicuri di voler eliminare il contenuto?</b>"),
										buttons: {
											confirm: {
												label: common.getTrad('Elimina'),
												className: 'btn-danger'
											},
											cancel: {
												label: common.getTrad('Annulla'),
												className: 'btn-light'
											}
										},
										callback: (result) => {
											if(result)
												this.multipleActions(null, 'DELETE')
										}
									})}
									disabled={this.props.pathname ? false : true}>
									<i className="far fa-trash-alt"></i> {common.getTrad("Elimina")}
								</button>
							</div>
						</div>
						<div className="btn-group btn-group-sm mr-xl-3" role="group" aria-label="Basic example">
							<div className="btn-group" style={{width: "100%"}} >
                <WorkflowButton
                  handleClick={(e, action) => this.multipleActions(e, 'CHANGE_STATE')}
                  transitions={ trans }
                  add_to_button_class=""
                  show_dropdown={true}
                  disabled={this.props.pathname && this.props.pathname.url !== '' ? false : true}
                />
							</div>
						</div>
					</div>
				</div>
				{this.state.customTag}
				<div className='row'>
					<div className="offset-xl-8 col-xl-4 col-sm-12 mb-2">
						<div className="input-group ">
							<input className="form-control search_tabella" name="q" type="search" placeholder={common.getTrad("Cerca")} aria-label="Cerca"/>
							<button className="btn btn-outline-light bottone_default" id="ricercaTabella" type="submit">
								<i className="fas fa-search"></i> {common.getTrad("Cerca")}
							</button>
						</div>
					</div>
				</div>
				<table id="table_GestioneContenutiTable" className="table table-striped table-bordered dt-responsive" style={{width: '100%'}}></table>
				<div id="dropdownTableMenu" className="dropdown-menu pb-0">
					<div className="btn-group" style={{width: "100%"}} >
						<button onClick={(e, action) => this.multipleActions(e, "SHOW")} className="dropdown-item showItem">
							<b><i className="far fa-eye"></i> {common.getTrad("Visualizza")}</b>
						</button>
					</div>
					<div className="btn-group" style={{width: "100%"}} >
						<button onClick={(e, action) => this.multipleActions(e, "EDIT")} className="dropdown-item editItem">
							<i className="far fa-edit"></i> {common.getTrad("Modifica")}&hellip;
						</button>
					</div>
					<div className="btn-group" style={{width: "100%"}} >
						<button className="btn dropdown-item dropdown-toggle copyItem" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i className="far fa-copy"></i> {common.getTrad("Copia in")}
						</button>
						<div className="dropdown-menu">
							<GestioneContenutiSidebar menuAreaButtons={this.props.menuAreaButtons} render1={this.props.render1} onClick={(e,empty, data) => this.actionHandlerClick(e, null,data, "@sw-copy")} classBloccoMenu="blocco_menu_tabella"></GestioneContenutiSidebar>
						</div>
					</div>
					<div className="btn-group" style={{width: "100%"}} >
						<button className="btn dropdown-item dropdown-toggle moveItem" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i className="fas fa-file-export"></i> {common.getTrad("Sposta in")}
						</button>
						<div className="dropdown-menu">
							<GestioneContenutiSidebar pathname={this.props.pathname} menuAreaButtons={this.props.menuAreaButtons} render1={this.props.render1} onClick={(e,empty, data) => this.actionHandlerClick(e,null, data, "@sw-move")} classBloccoMenu="blocco_menu_tabella"></GestioneContenutiSidebar>
						</div>
					</div>
          			<div className="btn-group" style={{width: "100%"}}>
						<button onClick={(element) =>
							bootbox.confirm({
								message: common.getTrad("L'azione cancellerà in modo definitivo il contenuto selezionato.<br><b>Si è sicuri di voler eliminare il contenuto?</b>"),
								buttons: {
									confirm: {
										label: common.getTrad('Elimina'),
										className: 'btn-danger'
									},
									cancel: {
										label: common.getTrad('Annulla'),
										className: 'btn-light'
									}
								},
								callback: (result) => {
									if(result)
										this.multipleActions(null, 'DELETE')
								}
							})}
							className="dropdown-item delItem">
  							<i className="far fa-trash-alt"></i> {common.getTrad("Elimina")}
  						</button>
          			</div>
					<div className="btn-group" style={{width: "100%"}} >
						<WorkflowButton
							handleClick={(e, action) => this.multipleActions(e, 'CHANGE_STATE')}
							transitions={this.state.dataRecord.workflow}
							add_to_button_class="dropdown-item"
							show_dropdown={true}
							disabled={this.props.pathname && this.props.pathname.url !== '' ? false : true}
						/>
					</div>
				</div>
				<div className="dataTables_wrapper dt-bootstrap4 no-footer">
					<div className="row">
						{this.state.recordTabella.items_total > 0 ?
							<div className="col-lg-5 col-sm-12 col-xs-12">
								<div className="dataTables_info">
									{common.getTrad("Elementi mostrati") + " (" + this.state.recordTabella.items.length + ") " + common.getTrad(" su un totale di") +" (" + this.state.recordTabella.items_total+ ")"}
								</div>
							</div>
						:
							<></>
						}
						{this.state.recordTabella.items_total > env.TABLE_PAGE_LENGTH ?
							this.state.recordTabella.batching ?
              <div className="col-lg-7 col-sm-12 col-xs-12">
                <div className="dataTables_paginate paging_full_numbers" id="tickets_table_paginate">
                  <ul className="pagination">
                    <li onClick={() => {if(this.state.recordTabella.batching.prev) this.props.onClick("paginazione_custom", {vaiPagina: 1})}}
                        className={(!this.state.recordTabella.batching.prev ? "disabled" : "") + " paginate_button page-item first"}
                        title={common.getTrad("Inizio")}>
                      <p className="page-link"><i className="fas fa-fast-backward"></i></p>
                    </li>
                    <li onClick={() => {if(this.state.recordTabella.batching.prev) this.props.onClick("paginazione_custom", {calcolaPagina: -1})}}
                        className={(!this.state.recordTabella.batching.prev ? "disabled" : "") + " paginate_button page-item previous"} id="table_DefaultTable_previous"
                        title={common.getTrad("Precedente")}>
                      <p className="page-link"><i className="fas fa-step-backward"></i></p>
                    </li>

                    <div style={{display: 'flex'}} id="paginazione_custom" onClick={(e) => this.paginazioneCustomClick(e)}></div>

                    <li onClick={() => {if(this.state.recordTabella.batching.next) this.props.onClick("paginazione_custom", {calcolaPagina: 1})}}
                        className={(!this.state.recordTabella.batching.next ? "disabled" : "") + " paginate_button page-item next"} id="table_DefaultTable_next"
                        title={common.getTrad("Successivo")}>
                      <p className="page-link" ><i className="fas fa-step-forward"></i></p>
                    </li>
                    <li onClick={() => {if(this.state.recordTabella.batching.next) this.props.onClick("paginazione_custom", {vaiPagina: "end"})}}
                        className={(!this.state.recordTabella.batching.next ? "disabled" : "") + " paginate_button page-item last"}
                        title={common.getTrad("Fine")}>
                      <p className="page-link"><i className="fas fa-fast-forward"></i></p>
                    </li>
                  </ul>
                </div>
              </div>
							:
								<></>
						:
							<></>
						}
					</div>
				</div>
			</>
		)
	}
}

function truncateOnWord(str, limit) {
	var trimmable = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
	var reg = new RegExp('(?=[' + trimmable + '])');
	var words = str.split(reg);
	var count = 0;
	return words.filter(function(word) {
			count += word.length;
			return count <= limit;
	}).join('') + "...";
}

export default GestioneContenutiTable;
