import React from 'react';
const common = require('../common/common');
const env = require("../../config.js").default;

class FilterSpareParts extends React.Component{

	render(props){
		return(
      <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-xs-12 row-eq-height" role="alert" id="ricerca_applicazione">
        <div className="col-12 p-0">
          <h4>{common.getTrad("Ricerca Applicazioni")}
						<figure className="svg" src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-veicoli.svg"} title={common.getTrad("Ricerca Modello")} alt={common.getTrad("Ricerca Modello")}></figure>
					</h4>
          <hr/>
          <div className="input-group">
            <input placeholder="filtro per codice articolo" name="applicazioni_search" id="applicazioni_search" className="form-control" type="text" maxLength="32" />
            <span className="input-group-btn">
              <button id="btn_cercaA" className="btn btn-light" type="button">
                <span className="fas fa-search"></span> {common.getTrad("Cerca")}
              </button>
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default FilterSpareParts;
