import React from 'react';

import '../../css/sitemap.css';

const env = require("../../config.js").default;
const common = require('../common/common');


class SiteMap extends React.Component {
  UNSAFE_componentWillMount(){
    document.getElementById('body').className='content_page';
  }
  componentDidMount(){
    document.title = document.title = window.cc.companyInfo.service + " Service";
    const $ = window.$;

    $('#back').on('click', function (e) {
      e.preventDefault();
      window.history.back();
    });
  }

  render(props){
    return (
        <div className="content_testo tab-content">
        <h2 id="back" className="float-left p-2" style={{position: 'fixed', left: '0px', cursor: 'pointer'}}>
          <i title="Chiudi la Navigazione Standard RMI" className="far fa-times-circle danger"></i>
        </h2>
					<div id="content-core">
						<div className="container contenitoreSitemap">
							<h1>{common.getTrad("Navigazione Standard RMI")}</h1>
              <ol>
                <li>
                  <a href={env.FRONTEND + "login"}>User Authentication, authorization and administration</a>
                  <ol>
                    <li><a href={env.FRONTEND + "sign-up-io" }>Register IO for use of the MA RMI system</a></li>
                    <li><a href={env.FRONTEND + "sign-up-employee" }>Register IO employee for use of the VM RMI system</a></li>
                    <li><a href={env.FRONTEND + "my-profile" }>Maintain IO status</a></li>
                    <li><a href={env.FRONTEND + "my-profile" }>Maintain user status</a></li>
                    <li><a href={env.FRONTEND + "my-profile" }>Request to de-register IO employee</a></li>
                    <li><a href={env.FRONTEND + "login" }>Login to MA RMI system</a></li>
                    <li><a href={env.FRONTEND + "my-profile" }>Grant access to security-related RMI</a></li>
                  </ol>
                </li>
                <li>
                  <a href={env.FRONTEND + "subscribe"}>Payment for RMI access</a>
                </li>
                <li>
                  <a href={env.FRONTEND + "search" }>Vehicle identification</a>
                  <ol>
                    <li><a href={env.FRONTEND + "search" }>Vehicle identification through product identifier</a></li>
                    <li><a href={env.FRONTEND + "search" }>Vehicle type identification via product features</a></li>
                    <li><a href={env.FRONTEND + "search" }>Unequivocal product identification</a></li>
                    <li><a href={env.FRONTEND + "search" }>Product Type Identification Via product features</a></li>
                  </ol>
                </li>
                <li>
                  <a href={env.FRONTEND + "search" }>RMI selection methods</a>
                  <ol>
                    <li><a href={env.FRONTEND + "search" }>Select information type</a></li>
                    <li><a href={env.FRONTEND + "search" }>Search by standardized terms</a></li>
                    <li><a href={env.FRONTEND + "search" }>Navigate using product structure</a></li>
                    <li><a href={env.FRONTEND + "search" }>Select by document identifier</a></li>
                  </ol>
                </li>
                <li>
                  <a href={env.FRONTEND + common.getLanguage() + "/rmi/service/workshop-procedures" }>Retrieve information packages</a>
                  <ol>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/service/workshop-procedures" }>Workshop procedures</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/service/wiring-diagrams"}>Wiring diagrams</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/service/technical-service-bulletin"}>Technical service bulletin</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/service/recall-information"}>Recall information</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/service/maintenance-schedule"}>Maintenance schedule</a></li>
                    <li><a href={env.SWAN + "epc"}>Spare parts</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/service/accessories"}>Accessories</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/service/labour-time"}>Labour times</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/service/special-tools"}>Special tools</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/service/type-approval"}>Type-approval realted information</a></li>
                  </ol>
                </li>
                <li>
                  <a href={env.FRONTEND + common.getLanguage() + "/rmi/vehicle-diagnostics/DTC-resolution" }>Vehicle diagnostics</a>
                  <ol>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/vehicle-diagnostics/DTC-resolution" }>DTC resolution</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/vehicle-diagnostics/MA-symptom-resolution" }>MA symptom resolution</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/vehicle-diagnostics/integrated-diagnostic" }>Integrated diagnostics</a></li>
                  </ol>
                </li>
                <li>
                  <a href={env.FRONTEND + common.getLanguage() + "/rmi/update-replacing-modules" }>Updating and replacing modules</a>
                  <ol>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/update-replacing-modules" }>Updating and replacing modules</a></li>
                  </ol>
                </li>
                <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/mainteinance-history" }>Electronic maintenance history</a></li>
                <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/technical-support" }>Repair assistance technical support</a></li>
                <li>
                  <a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/electronic-tool-information" }>Request contact for specific RMI</a>
                  <ol>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/electronic-tool-information" }>Electronic tool information (Diagnostic, Re-programming, VCI)</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/test-equipment-diagnostic-tool-manufactures" }>Test equipment and diagnostic tool manufacturers</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/training-material" }>Training material (delegate information)</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/re-distributors" }>Re-distributors</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/re-publishers" }>Re-publishers</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/inspection-testing-services" }>Inspection and testing services</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/alternative-fuels-retrofit-systems" }>Alternative fuels retrofit systems</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/engine-components-remanufacturing" }>Engine and components remanufacturing</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/component-parts-manufacturing" }>Component and parts manufacturing</a></li>
                    <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/contact/VCIs" }>Validation of independently developed non-proprietary VCIs</a></li>
                  </ol>
                </li>
                <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/courses-training" }>Courses and training information</a></li>
                <li><a href={env.FRONTEND + common.getLanguage() + "/rmi/download-area" }>Download area</a></li>
              </ol>
						</div>
					</div>
        </div>
    )
  }
}

export default SiteMap;
