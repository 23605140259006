import React from 'react';
import Prompt from '../common/Prompt';
import PropTypes from 'prop-types';
import bootbox from 'bootbox';
import { createBrowserHistory } from "history";

import '../../css/base.css';
import '../../css/page-body.css';

const common = require('../common/common');
const env = require("../../config.js").default;

class EditContact extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props){
    super(props);
    this.state = {};
    this.state.render = false;
    this.state.pageTitle = this.props.pageTitle;
    this.state.pageContent = this.props.pageContent;
    this.state.pathname = this.props.pathname;
    this.handleSubmit = this.handleSubmit.bind(this);
    window.contentSaved = true;
  }

  UNSAFE_componentWillMount(){
    if(!this.props.pageContent.is_folderish){
      if(this.props.pageContent.description !== undefined && this.props.pageContent.description !== null && this.props.pageContent.description !== ""){
        try{
          this.props.pageContent.description = JSON.parse(this.props.pageContent.description);
        }catch(error){
          return window.location.reload()
        }
      }

    }

    document.getElementById('body').className='new_contact';

  }

  componentDidMount(){
    const $ = window.$;
    /*inserimento */
    $("input").change((myInput) => {
      window.contentSaved = false;
    });

    document.title = document.title = window.cc.companyInfo.service + " Service";

    $('#annulla').on('click', (e) => {
      const history = createBrowserHistory();
      e.preventDefault();
      common.goBack(history);
    });

    if(!this.state.pageContent.is_folderish){
      var subjects = this.props.pageContent.subjects;
      // ciclo i dati che trovo nel subjects in formato JSON
      $(".c-inputs-stacked input:checkbox").prop('checked', false);
      for (var i = 0, len = subjects.length; i < len; i++) {
        if(subjects[i] === "sw-lang-all"){
          $(".c-inputs-stacked input:checkbox").prop('checked', true);
        }else{
          $("input:checkbox[value='"+ subjects[i] + "']").prop('checked', true);
        }
      }
    }
  }

  handleSubmit (e){
    e.preventDefault();
    const $ = window.$;
    var method = '', description = {}, data = {}, lingue_selezionate = [];
    if(!this.state.pageContent.is_folderish){
      method = "PATCH";
    }else{
      method = "POST";
    }
    // controllo se almeno uno dei campi Email, Telefono o Cellulare sono compilati
    var contatti = $('form :input.contatti_input').filter(function() {
      return this.value !== "" && this.value !== "0";
    });

    if (contatti.length > 0) {
      description.name = $("#nome").val();
      description.surname = $("#cognome").val();
      description.department = $("#reparto").val();
      description.email = $("#email").val();
      description.phone = $("#telefono").val();
      description.phone2 = $("#cellulare").val();
      description.address = $("#indirizzo_localita").val();

      data = {description: JSON.stringify(description), subjects: lingue_selezionate, '@type': 'News Item', title: $("#reparto").val() + " " + $("#nome").val() + " " + $("#cognome").val(),};

      $("body #app .loadingDiv").fadeIn("fast");
      if(method === "POST"){
        common.apiCall(env.BACKEND + this.state.pathname, "POST", data, true)
        .then(data=>{
          if(data.error === false){
            window.contentSaved = true;
            $('#notifica_messaggio').html('<div class="sw-alert alert alert-success animated">' + common.getTrad("Caricamento elemento avvenuto correttamente.") + '</div>');
            common.clear_cache_after_save("News Item");
            window.location.replace(common.getFrontendUrl(data['@id']));
          }else{
            $("body #app .loadingDiv").fadeOut();
            data = JSON.parse(data)
            $('#notifica_messaggio').html('<div class="sw-alert alert alert-danger animated">' + common.getTrad("Errore qualcosa è andato storto.") + " <b>" + data["type"] + "</b> " + data["message"]
            + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
            + '<span aria-hidden="true">&times;</span>'
            + '</button>'
            +'</div>');
          }
        });
      }else{

        common.apiCall(env.BACKEND + this.state.pathname, "PATCH", data, true, false, "text")
        .then(data=>{
          if(data === ""){
            window.contentSaved = true;
            $('#notifica_messaggio').html('<div class="sw-alert alert alert-success animated">' + common.getTrad("Caricamento elemento avvenuto correttamente.") + '</div>');
            var url_redirect = env.FRONTEND;
            window.location.replace(url_redirect.substring(0, url_redirect.length -1) + this.state.pathname);
          }else{
            $("body #app .loadingDiv").fadeOut();
            data = JSON.parse(data)
            $('#notifica_messaggio').html('<div class="alert alert-danger animated">' + common.getTrad("Errore qualcosa è andato storto.") + " <b>" + data["type"] + "</b> " + data["message"]
            + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
            + '<span aria-hidden="true">&times;</span>'
            + '</button>'
            + '</div>');
          }
        });
      }

    }else{
      bootbox.alert(common.getTrad('Compila almeno uno dei tre campi tra Email, Telefono o Cellulare'));
    }
  }

  render(props){
    return (
        <>
          <Prompt message={common.getTrad("La pagina contiene modifiche non salvate. Si è sicuri di voler abbandonare senza salvare?")}/>
          <div id="notifica_messaggio"></div>
          <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8 linea_sfumata" id="content_page">
            <div className="row">
			        <div className="content_titolo">
							  <div className="content_titolo_int">
                  <span className="titolo_sezione">
                    {this.state.pageTitle}
                  </span>
                </div>
		          </div>
            </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">

            <div className="tab active">
              { this.state.pageContent.is_folderish ?
                <h4 className="col">{common.getTrad("Aggiungi contatto")}</h4>
              :
                <h4 className="col">{common.getTrad("Modifica contatto")}</h4>
              }
              <form autoComplete="off" className="add_element" action="#" method="post" onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="col-xl-12">
                  <div className="sw-alert alert alert-info" role="alert">
                       {common.getTrad("Devi compilare almeno uno dei tre campi Email, Telefono o Cellulare per poter proseguire con il salvataggio.")}
                  </div>
                </div>
                   <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="nome">{common.getTrad("Nome")} <span className="danger">*</span></label>
                      <input autoFocus autoComplete="off" type="text" required className="form-control" id="nome" name="nome" placeholder={common.getTrad("Inserisci il Nome")} defaultValue={!this.state.pageContent.is_folderish && this.state.pageContent.description.name ? this.state.pageContent.description.name : "" }/>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="cognome">{common.getTrad("Cognome")} <span className="danger">*</span></label>
                      <input type="text" required autoComplete="off" className="form-control" id="cognome" name="cognome" placeholder={common.getTrad("Inserisci il Cognome")} defaultValue={!this.state.pageContent.is_folderish && this.state.pageContent.description.surname ? this.state.pageContent.description.surname : "" }/>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="reparto">{common.getTrad("Reparto")}</label>
                      <input type="text" autoComplete="off" className="form-control" id="reparto" name="reparto" placeholder={common.getTrad("Inserisci il Reparto")} defaultValue={!this.state.pageContent.is_folderish && this.state.pageContent.description.department ? this.state.pageContent.description.department : "" }/>
                    </div>
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="email">{common.getTrad("Email")}</label>
                      <input type="email" autoComplete="off" className="form-control contatti_input" id="email" name="email" placeholder={common.getTrad("Inserisci l'email")} defaultValue={!this.state.pageContent.is_folderish && this.state.pageContent.description.email ? this.state.pageContent.description.email : "" } />
                    </div>
                  </div>
                   <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="telefono">{common.getTrad("Telefono")}</label>
                      <input type="tel" autoComplete="off" className="form-control contatti_input" id="telefono" name="telefono" placeholder={common.getTrad("Inserisci il telefono")} defaultValue={!this.state.pageContent.is_folderish && this.state.pageContent.description.phone ? this.state.pageContent.description.phone : "" } />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="cellulare">{common.getTrad("Cellulare")}</label>
                      <input type="tel" autoComplete="off" className="form-control contatti_input" id="cellulare" name="cellulare" placeholder={common.getTrad("Inserisci il Cellulare")} defaultValue={!this.state.pageContent.is_folderish && this.state.pageContent.description.phone2 ? this.state.pageContent.description.phone2 : "" } />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="indirizzo_localita">{common.getTrad("Indirizzo, Sede o Località")}</label>
                      <input type="text" autoComplete="off" className="form-control" id="indirizzo_localita" name="indirizzo_localita" placeholder={common.getTrad("Inserisci L'Indirizzo, la Sede o la Località")} defaultValue={!this.state.pageContent.is_folderish && this.state.pageContent.description.address ? this.state.pageContent.description.address : "" } />
                    </div>
                  </div>
                </div>

                <div className="row">


                  <div id="status"></div>
                  <div className="col-xl-12 col-lg-12 col-xs-12 float-right">
                    <div className="form-row">
                      <div className="form-group col bottoni_invio">
                        <button type="submit" className="btn btn-primary float-right">{common.getTrad("Salva")}</button>
                        <button id="annulla" type="button" className="btn btn-light float-right mr-2">{common.getTrad("Annulla")}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default EditContact;
