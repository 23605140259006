import React from 'react';
import Prompt from '../common/Prompt';
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { ContentCreators, ContentBrand } from '../Page/PageTicket';
import FilterVM from '../FilterVM/FilterVM';
import { getTicketRights } from '../views/page';
import {doLogout} from '../views/logout';
import moment from 'moment';
import bootbox from 'bootbox';
import 'jquery-validation/dist/jquery.validate.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import { createBrowserHistory } from "history";

var tus = require("tus-js-client");
const common = require('../common/common');
const multibrand = require('../common/multibrand');
const env = require("../../config.js").default;
try {
  require("bootstrap-datepicker/js/locales/bootstrap-datepicker."+ common.getLanguageDatePicker() +".js");
  require ('select2/dist/js/i18n/'+ common.getLanguage(false) +'.js');
} catch (err) {
  require("bootstrap-datepicker/js/locales/bootstrap-datepicker.en-GB.js");
  require ('select2/dist/js/i18n/en.js');
}

class EditTicket extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props){
    super(props);
    this.state = {};
    this.state.pageTitle = this.props.pageTitle;
    this.state.userInfo = {};
    this.state.userInfo.roles = [];
    this.state.userInfo.userInfo = {};
    this.state.render = false;
    this.state.pageContent = {};
    this.state.pathname = "";
    this.handleSubmit = this.handleSubmit.bind(this);
    window.contentSaved = true;
    this.state.tipologieTicket = {};
    this.state.tipoTicket = this.props.tipoTicket;
    this.keyComments = -1;
    this.uploadPercentages = {};
    this.handleClickRemoveFile = this.handleClickRemoveFile.bind(this);
    this.state.Lock = {};
    this.state.availableTypes = 0;
    this.state.marcaDefault = common.get_localStorage("valoreMarca") !== undefined ?
      common.get_localStorage("valoreMarca")
    :
      multibrand.get_marca_default("ticket", true);

    this.state.tipologie_ticket_attive = common.getTicketTypesByBrand();
    this.state.fine = false;
    this.state.googleCloudBucketPath = (window.cc.frontendCustomization.bucket_google + 'support-tools/ticket/');
    this.state.indexTitle = false;
  }

  UNSAFE_componentWillMount(){
    let newState = {};
    try{
      this.props.pageContent.description = JSON.parse(this.props.pageContent.description);
    }catch(e){}

    var pageContent = this.props.pageContent;

    if(window.cc.sisterCompany !== undefined && window.cc.sisterCompany.length > 0){
      var csoft_gruppo = this.props.pageContent.subjects.find(a =>a.includes("sw-csoft"));
      // pageContent.tipologia = pageContent.subjects.find(a =>!a.includes("sw-csoft"));
      if(common.get_localStorage("sisterCompany") !== undefined && common.get_localStorage("sisterCompany") !== null)
        window.cc.sisterCompany = JSON.parse(common.get_localStorage("sisterCompany"));
      var numSisterCompany = common.object_value_in_array(window.cc.sisterCompany, 'value', csoft_gruppo);
      var csoftGroup = window.cc.sisterCompany[numSisterCompany].CSOFT + ' - ' + window.cc.sisterCompany[numSisterCompany].RSOCI;
      
      newState["csoftGroup"] = csoftGroup;
    }

    let pos = common.search_text_in_array(pageContent.subjects, 'sw-brand');
    if(pos > -1)
      pageContent.marcaDefault = multibrand.from_subject_to_ZFORN(pageContent.subjects[pos]);
    else
      pageContent.marcaDefault = multibrand.get_marca_default("ticket", true);

    for (let i = 0; i < pageContent.subjects.length; i++) {
      //Cerco la tipologia tra i subjet. Se non sw-brand o sw-csoft assumo che sia la tipologia
      if (pageContent.subjects[i].indexOf('sw-brand') <= -1 && pageContent.subjects[i].indexOf("sw-csoft") <= -1){
        pageContent.tipologia = pageContent.subjects[i];
        break;
      }
    }

    newState["pageContent"] = pageContent;
    newState["pathname"] = this.props.pathname;
    document.getElementById('body').className='new_content content_page content_ticket';

    const $ = window.$;
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    var tipologieTicket = this.state.tipologie_ticket_attive;
    if(this.props.marche_attive !== undefined && this.props.marche_attive.length >= 1){
      var tipologieTicketKeys = Object.keys(tipologieTicket);
      var gruppo = [];
      var ticket_gruppo = [];
      for (let i=0; i< tipologieTicketKeys.length; i++){
        if(tipologieTicketKeys[i] !== undefined && tipologieTicketKeys[i] !== 'version'){
          gruppo.push({'id_group': tipologieTicketKeys[i], 'text': tipologieTicketKeys[i], 'children': []})
        }
      }

      for (let g = 0; g < gruppo.length; g++) {
        var tipologiaKey = Object.keys(tipologieTicket[gruppo[g].id_group]);
        for (var i = 0; i < tipologiaKey.length; i++) {
          if(tipologieTicket[gruppo[g].id_group][tipologiaKey[i]].no_insert === undefined && !tipologieTicket[gruppo[g].id_group][tipologiaKey[i]].no_insert){
            ticket_gruppo.push({
              'id': tipologiaKey[i],
              'text': common.getTrad(tipologieTicket[gruppo[g].id_group][tipologiaKey[i]].titolo),
              'id_group': gruppo[g].id_group,
              'campi': tipologieTicket[gruppo[g].id_group][tipologiaKey[i]].campi,
            });
          }
        }
      }

      for (var g = 0; g < gruppo.length; g++) {
        for (var m = 0; m < ticket_gruppo.length; m++) {
          if(gruppo[g]['id_group'] === ticket_gruppo[m]['id_group'])
            gruppo[g]['children'].push(ticket_gruppo[m])
        }
      }

      newState["tipologieTicket"] = gruppo;
    }else{
      newState["tipologieTicket"] = tipologieTicket;
    }

    this.setState(newState);
    common.setStateUserInfo(this);
  }

  async changeReviewState(newState){
    var url = common.getBackendUrl(this.state.pageContent["@id"]), response;
    if(newState === "closed")
      url = url + "/@workflow/close";
    else if(newState === "incomplete")
      url = url + "/@workflow/reject";
    else if(newState === "pending")
      url = url + "/@workflow/submit";
    else if(newState === "published")
      url = url + "/@workflow/publish";

    const $ = window.$;
    var data = {
      tipologia: this.state.pageContent.tipologia,
      brand: this.state.pageContent.marcaDefault,
    }
    $("body #app .loadingDiv").fadeIn("fast");
    response = await common.apiCall(url, "POST", data, true, false, "json");
    if(response.error === false){
      common.clear_cache_after_save("swticket");
      var pageContent = this.state.pageContent;
      pageContent.review_state = response.review_state;
      this.setState({pageContent: pageContent});
         common.apiCall(env.BACKEND + this.state.pathname, "PATCH", getTicketRights(pageContent), true, false, "text", false, this.state.Lock.token);
    }else{
      console.error("error" + response.error);
    }
    $("body #app .loadingDiv").fadeOut();
  }

  decalreSelectTipologie = () => {
    const $ = window.$;
    if ($('.select_ticket').data('select2'))
      $(".select_ticket").select2("destroy");

    $(".select_ticket").select2({
      placeholder: this.props.marche_attive.length <= 1 ? common.getTrad("Seleziona un tipo") : common.getTrad("Seleziona prima una marca"),
      allowClear: true,
      language: common.getLanguage(),
      minimumResultsForSearch: -1,
      width: '100%',
      dropdownAutoWidth: true
    });

    $('.select_ticket').on('select2:select select2:unselect', (e) => {
      $(".select_ticket").val(e.target.value).trigger("change");
      this.setState({ tipoTicket: e.target.value });
    });
  }

  componentDidMount(){
    const $ = window.$;
    window.convertedFiles = [];
    window.uploadedFiles = [];
    if(!this.state.pageContent.is_folderish && window.location.href.indexOf("@@editTicket") > -1 && this.state.pageContent['@id'] !== undefined){
      common.apiCall(common.getBackendUrl(this.state.pageContent['@id']) + "/@sw-lock", "POST", {timeout: 120}, true, false, "json")
      .then(response=>{
        if(response.error === false && !this.state.Lock.locked){
          this.setState({Lock: response});
        }
      });

      setInterval(() => {
        common.apiCall(this.state.pageContent['@id'] + "/@refresh-lock", "POST", {}, true, false, "json")
        .then(responseRefresh=>{
          if(!responseRefresh)
            doLogout();
          if(responseRefresh.error === false){
            // mantengo il tempo originale da quando è stato bloccato
            var lockObject = this.state.Lock;
    				responseRefresh.time = lockObject.time;
            this.setState({Lock: responseRefresh});
          }else{
            doLogout();
          }
        });
      }, 60 * 1000);
    }

    let tipologie = Object.keys(this.getTipologieTicketAbilitate());
    if (tipologie.length === 1){
      $("#select_ticket").val(tipologie[0]).change();
      this.setState({ tipoTicket: tipologie[0] })
      $(".content_filtro").hide();
    }

    // modifica form per stato/Tipologia
    $('.single_ticket').hide();
    $('#ticket_0.single_ticket').show();

    this.decalreSelectTipologie();

    document.title = document.title = window.cc.companyInfo.service + " Service";
    // check lingue attive dove caricare il contenuto

    // upload file custom javascript
    ;( function ( document, window, index )
    {
    	var inputs = document.querySelectorAll( '.inputfile' );
    	Array.prototype.forEach.call( inputs, function( input )
    	{
    		var label	 = input.nextElementSibling,
    		labelVal = label.innerHTML;
        $(label).css('margin-bottom', 0)
    		input.addEventListener( 'change', function( e ){
          $("label[for='file_multiple'] .span_label_file").html(common.getTrad("File selezionati ({{numero}})", {numero: window.convertedFiles.length}))

    			var fileName = '';
    			fileName = e.target.value.split( '\\' ).pop();

    			if( fileName )
    			  label.querySelector( 'span' ).innerHTML = fileName;
    			else
    			  label.innerHTML = labelVal;
    		});

    		// Firefox bug fix
    		input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
    		input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
    	});
    }( document, window, 0 ));

    /* Modifica */
    if(!this.state.pageContent.is_folderish){
      if(Object.keys(this.state.tipologie_ticket_attive).length === 0)
        return true;
      // resetto le checkbox delle lingue (di default in fase di inserimento tutte le voci sono selezionate.)
      if(this.state.pageContent.file)
        $("#file").removeAttr("required", true)
      var subjects;
      subjects = this.props.pageContent.subjects;
      // ciclo i dati che trovo nel subjects in formato JSON
      for (var i = 0, len = subjects.length; i < len; i++) {
        if(subjects[i].indexOf('sw-brand') <= -1)
          $(".select_ticket option[value='"+ subjects[i] + "']").attr('selected', true);
      }
      // per la select2 ricarico i valori presenti selezionandoli
      $(".select_ticket").trigger("change");
      $(".select_ticket").attr("disabled" , true);

      // prendo i valori inseriti nei campi e li inserisco nei rispettivi campi del ticket indicato.
      var description = this.props.pageContent.description;
      var campiTicket = this.state.tipologie_ticket_attive[this.props.pageContent.subjects];
      if(this.state.pageContent.marcaDefault !== undefined)
        campiTicket = this.state.tipologie_ticket_attive[this.state.pageContent.marcaDefault][this.state.pageContent.tipologia];
      return campiTicket.campi.map((index, key) => {

        const $ = window.$;
        if(description !== undefined && description[key] !== undefined){
          if(description[key].id !== 'file' && description[key].id !== 'file_multiple'){
            $('#'+ description[key].id).val(index.html.indexOf("date_input") > -1 ? moment(description[key].value).isValid() ? moment(description[key].value).format('L') : '' : description[key].value);
            return ("")
          }
        }else{
          return ('')
        }
        return ("");

      });
    }

    $(window).resize(function() {
      $('.select2').css('width', "100%");
    });
    $('.select2').css('width', "100%");

  }

  handleClickRemoveFileConverted = (e) =>{
    const $ = window.$;
    var numFile = 0;
    if (window.convertedFiles !== undefined && window.convertedFiles.length > 0) {
      numFile = common.object_value_in_array(window.convertedFiles, 'id', $(e.target).attr("data-key"));
      if (numFile > -1) {
        window.convertedFiles.splice(numFile, 1);
        window.uploadedFiles.splice(numFile, 1);
        $(".row.fileConverted[data-key='" + $(e.target).attr("data-key") + "']").remove();
        $("label[for='file_multiple'] .span_label_file").html(common.getTrad("File selezionati ({{numero}})", { numero: window.convertedFiles.length }))

      }
    }
  }

  componentDidUpdate(){
    const $ = window.$;
    $('form :input').unbind('change keyup');
    $('form :input').on('change keyup', (e) => {
      window.contentSaved = false;
    });

    $('.select2').css('width', "100%");
    this.decalreSelectTipologie();

    $(() => {
      $('[data-toggle="tooltip"]').tooltip();
      
      $("label[for='file_multiple'] .span_label_file").html("");
      if(window.convertedFiles !== undefined && window.convertedFiles.length > 0){
        this.state.fine = false;
        $("label[for='file_multiple'] .span_label_file").html(common.getTrad("File selezionati ({{numero}})", {numero: window.convertedFiles.length}))
      }else{
        this.state.fine = true;
      }
    });

    $(".Trad_scegli_file").html("<i class='fas fa-file-upload'></i> " + common.getTrad("Scegli..."));
    $(".Trad_dimensione_massima_200MB").html(common.getTrad("Dimensione massima di caricamento 200 MB") + "<br/> " + common.getTrad("Puoi raggruppare più foto in un unico file archivio (zip, rar, etc.)"));
    $("#file").on("change", (e) => {
      var inputId = $("#" + e.target.id);
      if (inputId.length > 0){
        var file = inputId[0].files[0];
        if (file !== undefined && file.size > 209715200){
          bootbox.alert(common.getTrad("Attenzione!<br/>Il file che stai cercando di caricare supera la dimensione massima consentita di 200 MB.<br/>Riduci la dimensione e caricalo nuovamente."));
          inputId.val(null);
          $(".span_label_" + e.target.id).html('');
        }
      }
    });

    $("#file_multiple").on('change', (e) => {
      const files = e.target.files;
      Object.keys(files).forEach(k => {
        if (files[k] !== undefined && files[k].size > 209715200){
          bootbox.alert(common.getTrad("Attenzione!<br/>Il file che stai cercando di caricare supera la dimensione massima consentita di 200 MB.<br/>Riduci la dimensione e caricalo nuovamente."));
          return false;
        }else{
          window.convertedFiles = [
            ...window.convertedFiles,
            { id: URL.createObjectURL(files[k]), file: files[k]}
          ];
        }
      });


      $("#data-pre").html('');
      window.convertedFiles.map((i, key) => {
        const file = i.file;
        key++
        let statusUploadFile = window.convertedFiles[key - 1]?.file?.status;
        let progressCSS = '';
        let progressHTML = ''
        let statusClass = '';
        let disabled = '';
        let divCSS = '';

        if (typeof statusUploadFile === 'boolean') {
          if (statusUploadFile === true) {
            progressCSS = 'width: 100%';
            statusClass = 'statusTrue';
            disabled = 'disabled'
          } else if (statusUploadFile === false) {
            progressCSS = 'width: 100%; background-color: #dc3545';
            statusClass = 'statusFalse';
          }
          progressHTML = '&nbsp;';
          divCSS = 'style="margin-bottom: 16px"';
        }

        $("#data-pre").append('<div class="row fileConverted" data-key="' + i.id +'">'
          + '<div class="col-8 p-0 divInfoFileLoaded" ' + divCSS + '>'
          +   '<span><img alt="what" onerror="this.src=\''+env.STATIC+'images/estensioni/default.png\'" class="mr-2" src="'+env.STATIC+'images/estensioni/' + i.file.name.split('.').pop() +'.png" /></span>'
          +   '<span><p class="p-0 m-0 text-muted">' + common.getTrad('File') + ': ' + i.file.name + '<br/>' + common.getTrad('Dimensione') + ': '  + common.humanFileSize(i.file.size) + '</p></span>'
          + '</div>'
          + '<div class="col-4 p-0 divRemoveFileConverted" ' + divCSS + '>'
          +   '<div class="btn-group">'
          +      '<button class="btn btn-danger btn-sm removeFileConverted ' + statusClass + '" type="button" data-key="' + i.id +'">'
          +        '<i class="far fa-trash-alt" onClick="console.log(19);"></i>&nbsp;' + common.getTrad("Elimina")
          +      '</button>'
          +   '</div>'
          + '</div>'
          + '<div class="flex-grow-1 mb-1">'
          +   '<div class="progress pr_' + key + '" style="height: 5px">'
          +     '<div class="progress-bar progress-bar_' + key + ' progress-bar-striped progress-bar-animated" role="progressbar" style="' + progressCSS + '">' + progressHTML +'</div>'
          +   '</div>'
          + '</div>'
          + '<hr class="hrFileConverted">');
      });
      $(".removeFileConverted").unbind('click');
      $(".removeFileConverted").on('click', (e) => {
        this.handleClickRemoveFileConverted(e);
      });
      $("label[for='file_multiple'] .span_label_file").html(common.getTrad("File selezionati ({{numero}})", {numero: window.convertedFiles.length}))

    });

    function format(state) {
      if (!state.id) return state.text; // optgroup
      return '<span>' + common.getTrad(state.text) + '</span>';
    }

    // select nazione Paese con cambio codice ISO2 per partita iva
    $(".select2_ticket_option").select2({
      templateResult: format,
      templateSelection: format,
      escapeMarkup: function(m) { return m; },
      allowClear: true,
      language: common.getLanguage(),
      placeholder: common.getTrad("Seleziona un' opzione"),
    });

    $("#annulla").unbind('click');
    $('#annulla').on('click', (e) => {
      const history = createBrowserHistory();
      e.preventDefault();
      if(window.confirm(common.getTrad("La pagina contiene modifiche non salvate. Si è sicuri di voler abbandonare senza salvare?"))){
        window.contentSaved = true;
        if(!this.state.pageContent.is_folderish && this.state.userInfo.id === this.state.Lock.creator)
          common.apiCall(common.getBackendUrl(this.state.pageContent['@id']) + "/@unlock", "POST", {}, true, false, "json");
        common.goBack(history);
        return true;
      }else{
        return false;
      }
    });
    var oggi = new Date();
      $("input.date_input").datepicker({
      endDate: oggi.toDateString(),
      weekStart: 1,
      todayBtn: "linked",
      language: common.getLanguageDatePicker(),
      autoclose: true,
      todayHighlight: true
    });
    // upload file custom javascript
    ;( function ( document, window, index )
    {
      var inputs = document.querySelectorAll( '.inputfile:not(#file_multiple)' );
    	Array.prototype.forEach.call( inputs, function( input )
    	{
    		var label	 = input.nextElementSibling,
    		labelVal = label.innerHTML;
    		input.addEventListener( 'change', function( e )
    		{
    			var fileName = '';
    			if( this.files && this.files.length > 1 )
    			fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
    			else
    			fileName = e.target.value.split( '\\' ).pop();

    			if( fileName )
    			label.querySelector( 'span' ).innerHTML = fileName;
    			else
    			label.innerHTML = labelVal;
    		});

    		// Firefox bug fix
    		input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
    		input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
    	});
    }( document, window, 0 ));
    // upload file custom javascript

		function matchCustom(params, data) {
			if ($.trim(params.term) === '') {
				return data;
			}
			var keywords=(params.term).split(/[.+-/_ ]/);
			for (var i = 0; i < keywords.length; i++) {
				if (((data.text).toUpperCase()).indexOf((keywords[i]).toUpperCase()) === -1)
				return null;
			}
			return data;
		}
		// Ricreo la select modelli solamente con i modelli filtrati per marca
		
    if(this.state.tipoTicket !== '')
      $(".select_ticket").val(this.state.tipoTicket).trigger("change");
  }

  handleClickRemoveFile(typeToRemove){
    bootbox.confirm({
      message: common.getTrad("L'azione cancellerà in modo definitivo il contenuto selezionato.<br><b>Si è sicuri di voler eliminare il contenuto?</b>"),
      buttons: {
        confirm: {
          label: common.getTrad('Elimina'),
          className: 'btn-danger'
        },
        cancel: {
          label: common.getTrad('Annulla'),
          className: 'btn-light'
        }
      },
      callback: (result) => {
        if(result)
          this.removeFile(typeToRemove);
      }
    });
  }

  removeFile(fileType){
    const $ = window.$;
    var pageContent = this.state.pageContent;
    $("#remove" + fileType.charAt(0).toUpperCase() + fileType.slice(1)).val("1");
    pageContent[fileType] = false;
    this.setState({pageContent: pageContent});
    var campiTicket = {};
    if(this.state.pageContent.is_folderish){
      var campiTicket = this.state.tipologie_ticket_attive[this.state.tipoTicket];
      if(this.state.marcaDefault !== undefined)
        campiTicket = this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket];
    }else{
      var campiTicket = this.state.tipologie_ticket_attive[this.props.pageContent.subjects];
      if(this.state.pageContent.marcaDefault !== undefined)
        campiTicket = this.state.tipologie_ticket_attive[this.state.pageContent.marcaDefault][this.state.pageContent.tipologia];
    }

    var nFileInput = common.object_value_in_array(campiTicket.campi, 'id', 'file');
    if (nFileInput > -1 && campiTicket.campi[nFileInput].html.search("required") > -1 && !this.state.pageContent.file)
      $("#file").attr("required", true);
  }

  renderFormTicket(){
    const $ = window.$;
    let classNameColTicket = '';
    if(this.state.pageContent.is_folderish){
      var campiTicket = this.state.tipologie_ticket_attive[this.state.tipoTicket];
      if(this.state.marcaDefault !== undefined)
        campiTicket = this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket];

      return campiTicket.campi.map((index, key) => {
        if(index.id !== 'comments'){
          if(index.id === 'file')
            this.state.googleCloudBucketPath = false
          if(index.id === 'file_multiple')
            if(index.titolo != undefined)
              this.state.indexTitle = true;
          if(index.id === 'mandatory_attachments' || index.id === 'mandatory_attachments_2') {
            classNameColTicket = 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'
          } else {
            classNameColTicket = 'col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12'
          }
          return(
            <div key={key} className={classNameColTicket}>
              <div className="form-group">
                <label htmlFor={index.id}>
                  {this.state.marcaDefault !== undefined || (this.props.marche_attive !== undefined && this.props.marche_attive.length === 1) ? common.getTrad(index.titolo) : common.getTrad(index.titolo)}
                  &nbsp;{index.html.search("required") > -1 ? <span className="danger">*</span> : ""}
                  {index.tip !== undefined && index.tip !== null && index.tip !== '' ?
                    <>&nbsp;<i className="fas fa-info-circle" data-original-title={index.tip} data-toggle="tooltip" data-placement="top"></i></>
                  :
                    ""
                  }
                </label>
                <div dangerouslySetInnerHTML={{ __html: index.html }} />
              </div>
           </div>
          )
        }else{
          return ""
        }
      });

    }else{
      var description = this.state.pageContent.description;
      let nAttachmentLoaded = this.countAttachmentsLoaded()
      var campiTicket = this.state.tipologie_ticket_attive[this.props.pageContent.subjects];
      if(this.state.pageContent.marcaDefault !== undefined)
        campiTicket = this.state.tipologie_ticket_attive[this.state.pageContent.marcaDefault][this.state.pageContent.tipologia];

      this.keyComments = common.object_value_in_array(description, 'id', 'comments');

      return campiTicket.campi.map((index, key) => {
        if (index.id !== 'comments') {
          if(index.id === 'file')
            this.state.googleCloudBucketPath = false;
          if(index.id === 'file_multiple')
            if(index.nAttachment <= nAttachmentLoaded || nAttachmentLoaded === -1)
              $("#file_multiple").attr("required", false);
              $("label[for='file_multiple'] .danger").hide();
            if(index.titolo != undefined)
              this.state.indexTitle = true;
          if (index.id === 'mandatory_attachments' || index.id === 'mandatory_attachments_2') {
            classNameColTicket = 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'
          } else {
            classNameColTicket = 'col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12'
          }
          if(this.state.pageContent.review_state === 'incomplete_draft'){
            let ticketErrors = {}, pos = -1;
            pos = common.object_value_in_array(this.state.pageContent.description, "id", "messageErrors");
            if (pos > -1)
              ticketErrors = this.state.pageContent.description[pos]?.value;
              
            if (ticketErrors[index.id] !== undefined && ticketErrors[index.id] === 'REQUIRED'){
              window.$(".labelError_" + index.id).html(common.getTrad("Campo obbligatorio")).show();
            }
          }
          return(
            <div key={key} className={classNameColTicket}>
              <div className="form-group">
                <label htmlFor={index.id}>
                  {this.state.pageContent.marcaDefault !== undefined || (this.props.marche_attive !== undefined && this.props.marche_attive.length === 1) ? common.getTrad(index.titolo)  : index.titolo}
                  &nbsp;{index.html.search("required") > -1 ? <span className="danger">*</span> : ""}
                  {index.tip !== undefined && index.tip !== null && index.tip !== '' ?
                    <>&nbsp;<i className="fas fa-info-circle" data-original-title={index.tip} data-toggle="tooltip" data-placement="top"></i>
                    </>
                  :
                    ""
                  }
                </label>
                <div dangerouslySetInnerHTML={{ __html: index.html }} />
                <p className={"invalid-feedback labelError_" + index.id}></p>

                {index.id === 'file' ?
                  !this.state.pageContent.is_folderish ?
                    <>
                      <input type="hidden" name="removeFile" id="removeFile"/>
                      {this.state.pageContent.file ?
                        <p>
                          {common.getTrad("File caricato")}:&nbsp;
                          <b style={{wordBreak: 'break-word'}}>{this.state.pageContent.file.filename}</b>
                          ({common.humanFileSize(this.props.pageContent.file.size)})&nbsp;
                          <button type="button" className="btn bottone_default buttonRemoveImage" onClick={() => this.handleClickRemoveFile("file")}>
                            <i title={common.getTrad("Elimina")} className="fas fa-times "></i>
                          </button>
                        </p>
                      :
                      <></>
                    }
                    </>
                  :
                    <></>
                :
                  <></>

                }
              </div>
          </div>
          )
        } else {
          return ""
        }
      });
    }
  }

  statoTicket(){
    switch(this.state.pageContent.review_state) {
      case 'closed': case 'closed_visto':
        return  ('<span style="background: #dc3545;color:#fff;"><i class="fas fa-envelope"></i> ' + common.getTrad("Chiuso") + '</span>')
      case 'incomplete': case 'incomplete_visto': case 'incomplete_draft':
        return  ('<span style="background: #ffc107;color:#000;"><i class="fas fa-envelope-open"></i> ' + common.getTrad("Incompleto") + '</span>')
      case 'published': case 'published_visto':
        return  ('<span style="background: #007bff;color:#fff;"><i class="fas fa-envelope-open"></i> ' + common.getTrad("In lavorazione") + '</span>')
      default:
        return  ('<span style="background: #28a745;color:#fff;"><i class="fas fa-envelope-open-text"></i> ' + common.getTrad("Aperto") + '</span>')
    }
  }

  async download_file_from_bucket(pFILENAME, pURL) {
    var directory = pFILENAME.split("/")
    var filename = directory.slice(-1);
    fetch(pURL)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => console.error('Download error: ' + filename));
  }

  renderChatComments(){
    if(this.state.pageContent.description[this.keyComments] !== undefined && this.state.pageContent.description[this.keyComments] !== undefined && this.state.pageContent.description[this.keyComments].value !== undefined && this.state.pageContent.description[this.keyComments].value !== undefined){
      var reviewer = false;
      if(this.state.userInfo.is_admin_ticket !== undefined && this.state.userInfo.is_admin_ticket){
        reviewer = true;
      }

      if(this.state.pageContent.description[this.keyComments] !== undefined && this.state.pageContent.description[this.keyComments].value !== undefined && Array.isArray(this.state.pageContent.description[this.keyComments].value)){
        return this.state.pageContent.description[this.keyComments].value.map((index, key) => {
          var classDefault = reviewer ? index.roles.indexOf("Reviewer") > -1 ? "sent message" : "received message" : index.roles.indexOf("Reviewer") > -1 ? "received message" : "sent message";
          if(index.attachment !== undefined && index.attachment !== null)
            classDefault = classDefault + " has_attachment";
          return(
            index.text !== undefined ?
              <div key={key} className={classDefault}>
                {index.attachment !== undefined && index.attachment !== null ?
                  <div title={index.attachment.path !== undefined ? index.attachment.path : ""}>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="d-flex align-items-center mr-auto">
                        <img src={env.STATIC + "images/estensioni/" + index.attachment.name.split('.').pop() + ".png"}
                          onError={(e) => { e.target.onerror = null; e.target.src = env.STATIC + "images/estensioni/default.png" }} className="img-responsive mr-2"
                          alt={index.attachment.name} title={index.attachment.name}
                        />
                        <small>
                          <span>{common.getTrad("File")}: {index.attachment.name}</span>
                          {index.attachment.size !== undefined && (
                            <><br />{common.getTrad("Dimensione")}: <small>{common.humanFileSize(index.attachment.size)}</small></>
                          )}
                        </small>
                      </div>
                      <button className="btn btn-sm mr-2" onClick={() => { this.download_file_from_bucket(index.attachment.name, index.attachment.file) }}>
                        <i className="fas fa-file-download"></i>&nbsp;{common.getTrad("Scarica")}
                      </button>
                      <a className="btn btn-sm" target="_blank" href={index.attachment.file}>
                        <i className="fas fa-eye"></i>&nbsp;{common.getTrad("Visualizza")}
                      </a>
                    </div>
                  </div>
                :
                  ""
                }
                {index.text.search("\n") > -1  ?
                  <div dangerouslySetInnerHTML={{ __html: (index.text).replace(/\r?\n/g,'<br/>')}} />
                :
                  index.text
                }
      					<span className="metadata">
                  <span className="time">
                    {moment(index.created).format('LLL')}
                    <br />
                    <i className="fas fa-user"></i>&nbsp;{index.author}
                  </span>
                </span>
      				</div>
            :
            <></>
          )
        });
      }
    }
  }

  countAttachmentsLoaded() {
    //funzione che ritorna il numero di allegati caricati
    let nAttachmentLoaded = 0
    var description = this.state.pageContent.description;
    var fileOldTicket = common.object_value_in_array(this.state.pageContent.description, 'id', 'file');
    if (fileOldTicket > 0) {
      nAttachmentLoaded = -1;
    } else {
      for (let i = 0; i < description.length; i++) {
        if (description[i].id === 'comments') {
          for (let a = 0; a < description[i].value.length; a++) {
            if (description[i].value[a].attachment) {
              nAttachmentLoaded++;
            }
          }
        }
      };
    }

    return nAttachmentLoaded;
  }

  async handleSubmit (e){
    e.preventDefault();
    const $ = window.$;
    if($("#title").val() === ''){
      $("#title").attr("required", true);
      $(".add_element")[0].reportValidity();
      return false;
    }

    if($('#file_multiple').val() !== undefined) {
      var indexTipologia
      var nAttachmentsLoaded
      var nAttachment
      nAttachmentsLoaded = $('#data-pre')[0].childElementCount

      if (this.state.pageContent.is_folderish) {
        var campiTicket = this.state.tipologie_ticket_attive[this.state.tipoTicket];
        if (this.state.marcaDefault !== undefined)
          campiTicket = this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket];
      } else {
        var campiTicket = this.state.tipologie_ticket_attive[this.props.pageContent.subjects];
        if (this.state.pageContent.marcaDefault !== undefined)
          campiTicket = this.state.tipologie_ticket_attive[this.state.pageContent.marcaDefault][this.state.pageContent.tipologia];
          nAttachmentsLoaded += this.countAttachmentsLoaded();
      }
        
      indexTipologia = common.object_value_in_array(campiTicket.campi, 'id', 'file_multiple');
      nAttachment = campiTicket.campi[indexTipologia].nAttachment;

      if (nAttachmentsLoaded > 0 && nAttachmentsLoaded < nAttachment){
        bootbox.alert(common.getTrad("Attenzione!<br/>È necessario caricare almeno {{nAttachment}} file!", { nAttachment: nAttachment }));
        return false;
      }
    }

    $('form.add_element :input').attr('disabled', true);
    $('.content_filtro select').attr('disabled', true);

    if (window.uploadedFiles.ticketID) {
      ticketID = window.uploadedFiles.ticketID;
    } else {
      var timestamp = new Date().getTime();
      var ticketID = '';
      if (this.state.pageContent.is_folderish) {
        ticketID = "" + timestamp;
      }
    }

    // aggiungere POST o PATCH
    if (window.convertedFiles !== undefined && window.convertedFiles.length > 0) {

      $('form :input#title').change();
      window.fileCompleted = 0;

      // Dichiarazione di mutexUp all'esterno del ciclo
      var mutexUp = new common.Mutex();

      // uploadPromises per memorizzare le Promise di upload
      let uploadPromises = [];

      for (let i = 0; i < window.convertedFiles.length; i++) {
        if (window.convertedFiles[i].file.status != true || window.uploadedFiles[i].status != true) {
          window.uploadedFiles.splice(i, 1);
          var file_name = window.convertedFiles[i].file.name.split('.');
          var ext_file = file_name[file_name.length - 1];
          file_name.splice(file_name.length - 1);
          var new_file_name = file_name + '_' + Date.now() + '.' + ext_file;
          if (window.uploadedFiles.ticketID) {
            ticketID = window.uploadedFiles.ticketID;
          } else if (!this.state.pageContent.is_folderish) {
            ticketID = this.state.pageContent.id;
          }
          var dataJson = {
            path_name: this.state.googleCloudBucketPath + ticketID,
            file_name: new_file_name,
            file_type: window.convertedFiles[i].file.type,
          };

          try {
            var dataBucket = await common.apiCall(common.getBackendUrl("@sw-generate-backend-google-url"), "GET", dataJson);
            const uploadPromise = common.uploadFileToBucket(this, dataBucket.url, window.convertedFiles[i].file, i, window.convertedFiles.length);
            // in uploadPromises creo una Promise per ogni chiamata.
            uploadPromises.push(uploadPromise);
            window.uploadedFiles.push({
              file: dataBucket.public_url,
              path: dataBucket.path,
              name: window.convertedFiles[i].file.name,
              size: window.convertedFiles[i].file.size,
              status: false,
            });
            window.uploadedFiles.ticketID = ticketID
          } catch (error) {
            console.error(error);
          }
        }
      }

      try {
        // Promise.all() verifica che tutte le Promise siano risolte. 
        // Facendo un console.log le Promise avranno Promise fulfilled 
        await Promise.all(uploadPromises);
        await mutexUp.acquireQueued();
        this.saveInfo(timestamp, window.uploadedFiles);
      } catch (error) {
        // Riabilita tutti gli elementi all'interno della form
        $('form.add_element :input').prop('disabled', false);
        // Messaggio di errore
        bootbox.alert(common.getTrad("Attenzione!<br/>Errore nel caricamento del file.<br/>Cliccare su elimina e riprovare."));
        // Disabilita solo gli elementi con la classe 'statusTrue'
        setTimeout(function () {
          $('.statusTrue').prop('disabled', true);
        }, 500);
      }
    } else {
      $("body #app .loadingDiv").fadeIn("fast");
      this.saveInfo(timestamp);
    }
  }

  saveInfo = (timestamp) => {
    const $ = window.$;
    var file = "";
    if($("#file")[0] !== undefined && $("#file")[0] !== null && $("#file")[0] !== '')
      file = $("#file")[0].files[0];

    if(this.state.userInfo !== undefined && this.state.userInfo !== null)  {
      var author = this.state.userInfo.nickname;
      var roles = this.state.userInfo.roles;
    }
    var method = '';
    var pathname = this.state.pathname;
    if(!this.state.pageContent.is_folderish){
      method = "PATCH";
      var review_state = this.state.pageContent.review_state;
      var pageContent = this.state.pageContent;
      if(this.state.marcaDefault !== undefined){
        pageContent.brand = this.state.marcaDefault;
        pageContent.tipologia = this.state.pageContent.tipologia;
      }
    }else{
      method = "POST";
      if (window.uploadedFiles.ticketID) {
        var ticketID = window.uploadedFiles.ticketID;
      } else {
        var ticketID = "" + timestamp;
      }
    }
    var pathTicketForm = '';
    
    if(this.state.pageContent.is_folderish){
      if(this.state.marcaDefault !== undefined)
        pathTicketForm = this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket].campi;
      else
        pathTicketForm = this.state.tipologie_ticket_attive[this.state.tipoTicket].campi;
    }else{
      if(this.state.pageContent.marcaDefault !== undefined){
        pathTicketForm = this.state.tipologie_ticket_attive[this.state.pageContent.marcaDefault][pageContent.tipologia].campi;
      }else
        pathTicketForm = this.state.tipologie_ticket_attive[this.props.pageContent.subjects].campi;

    }
    var ticket = '';
    var dati = [];
    if(this.state.pageContent.description !== undefined && this.state.pageContent.description !== null && Array.isArray(this.state.pageContent.description))
      dati = this.state.pageContent.description;

    var comments = [];
    pathTicketForm.map((index, key) => {
      let datiKey = common.object_value_in_array(dati, 'id', index.id);
      const $ = window.$;
      if ($('#' + index.id).val() !== undefined){
        var default_value = $('#' + index.id).val();
        if($(index.html).data("uppercase"))
          default_value = default_value.toUpperCase();
        let datiKeyTicket = {
          "id":index.id,
          "value": index.html.indexOf("date_input") > -1 ? moment(moment(default_value,'L')).format('YYYY-MM-DD') : default_value,
          "type": $("#" + index.id)[0].type,
        };
        if (datiKey > -1)
          dati[datiKey] = datiKeyTicket;
        else
          dati.push(datiKeyTicket);
      }else
        if(index.id === "comments"){
          if(window.uploadedFiles !== undefined && window.uploadedFiles.length > 0){
            for (let i = 0; i < window.uploadedFiles.length; i++) {
              comments.push({
                "text": "",
                "author": author,
                "roles":  roles,
                "created": moment().format(),
                "attachment": window.uploadedFiles[i]
              });
            }
          }
          
          if (datiKey <= -1 || method === "POST"){
            dati.push({
              "id":index.id,
              "value": comments,
            });
            ticket = {id:"ticketID",value: ticketID};
            dati.push(ticket);
          }else{
            for (let i = 0; i < comments.length; i++) {
              dati[datiKey].value.push(comments[i])
            }
          }
        }
        return ""
    });

    var data = {
      '@type': 'swticket',
      title: $("#title").val(),
      description: JSON.stringify(dati),
      subjects: (this.state.marcaDefault !== undefined ?
        this.state.pageContent.is_folderish ?
          [multibrand.from_ZFORN_to_subject(this.state.marcaDefault), $('.select_ticket').val()]
        :
          this.state.pageContent.subjects
      :
        $('.select_ticket').val()),
      id: ticketID,
      tipologia: method === "POST" ? $('#select_ticket').val() : this.state.pageContent.tipologia,
    }
    
    data.sso_userid = this.state.userInfo.id;
    if(method === "POST" && this.state.marcaDefault !== undefined){
      data.brand = this.state.marcaDefault;
      data.user_cnazi = this.state.userInfo.userInfo.pw_nation;
      data.tipologia = $('.select_ticket').val();
    }else{
      data.brand = this.state.pageContent.marcaDefault;
      data.tipologia = this.state.pageContent.tipologia;
    }

    if($("#removeFile").val() === "1")
      data.file = false;

    var style = '';
    if(!$('.banner_center_header').is(":visible")){
      style = "top:" + $("#portal-top").height() + "px";
    }else{
      style = '';
    }

    var pathnameEdit = '';
    pathnameEdit = pathname;
    if(pathnameEdit[0] === "/")
      pathnameEdit = pathnameEdit.substr(1)
    common.apiCall(common.getBackendUrl(this.state.pageContent['@id']) + "/@sw-lock", "GET", {}, true, false, "json")
    .then(status=>{
      if(status.error === false){
        this.setState({Lock: status});
        var apiDataMethod = "", tokenTicket = '';
        if(method === "POST"){
          apiDataMethod = "json";
        }else{
          apiDataMethod = "text";
          tokenTicket = this.state.Lock.token;
          if(this.state.userInfo.id === this.state.Lock.creator && (review_state === 'incomplete' || review_state === 'incomplete_visto') && this.state.userInfo.is_admin_ticket !== undefined && !this.state.userInfo.is_admin_ticket){
            common.apiCall(env.BACKEND + pathnameEdit + "/@workflow/submit", "POST", {}, true, false, "json")
            .then(response=>{
              if(response.error === false){
                pageContent.review_state = review_state;
                this.setState({pageContent: pageContent});
                common.apiCall(env.BACKEND + pathnameEdit, "PATCH", getTicketRights(pageContent), true, false, "text", false, tokenTicket);
              }else{
                console.error('error' + response.error);
              }
            });
          }
        }

        if(method === 'POST' || (method === 'PATCH' && this.state.userInfo.id === this.state.Lock.creator) || (method === 'PATCH' && !this.state.Lock.locked)){
          common.apiCall(env.BACKEND + pathnameEdit, method, data, true, false, apiDataMethod,false, tokenTicket)
          .then(async (data) => {
            var in_error = false;

            if(apiDataMethod === "json")
              in_error = data.error !== false;
            else
              in_error = data !== "";

            var contentUrl = env.BACKEND + pathnameEdit;

            if(method === "POST")
              contentUrl = data["@id"];

            if(!in_error){
              common.clear_cache_after_save("swticket");
              var mutexUp = new common.Mutex();
              common.remove_localStorage("valoreMarca");

              if(file && file !== null && file !== undefined && file !== "")
                await this.uploadFile(file, "file", contentUrl, mutexUp);
              await mutexUp.acquireQueued();
              window.contentSaved = true;

              if(this.state.userInfo.id === this.state.Lock.creator && tokenTicket.length > 0 && tokenTicket !== undefined && tokenTicket !== null){
                common.apiCall(common.getBackendUrl(this.state.pageContent['@id']) + "/@unlock", "POST", {}, true, false, "json")
                .then(response=>{
                  if(response.error === false){
                    window.location.replace(common.getFrontendUrl(contentUrl));
                    $('#notifica_messaggio').html('<div class="sw-alert alert alert-success animated">' + common.getTrad("Caricamento elemento avvenuto correttamente.") + '</div>');
                  }
                });
              }else{
                window.location.replace(common.getFrontendUrl(contentUrl));
                $('#notifica_messaggio').html('<div class="sw-alert alert alert-success animated">' + common.getTrad("Caricamento elemento avvenuto correttamente.") + '</div>');
              }
            }else{
              $("body #app .loadingDiv").fadeOut();
              data = JSON.parse(data)
              $('#notifica_messaggio').html('<div class="alert alert-danger animated">' + common.getTrad("Errore qualcosa è andato storto.") + " <b>" + data["type"] + "</b> " + data["message"]
              + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
              + '<span aria-hidden="true">&times;</span>'
              + '</button>'
              + '</div>');
            }
          });
        }else{
          $("body #app .loadingDiv").fadeOut();
          $('#notifica_messaggio').html('<div class="sw-alert alert alert-warning animated">' + common.getTrad("Attenzione il documento risulta aperto in modifica da {{utente}}.", {utente: this.state.Lock.fullname}) + '</div>');
        }
      }
    });
  }

  // add file
  async uploadFile(file, type, path, mutex){
    const $ = window.$;
    const release = await mutex.acquireQueued();
    this.uploadPercentages[file] = 0;
    var uploadFile = new tus.Upload(file, {
      endpoint: path + "/@tus-replace",
      retryDelays: [0, 3000, 5000, 10000, 20000],
      withCredentials: true,
      overridePatchMethod: false,
      headers: {
        'Accept': 'application/json',
        "authorization": "Bearer " + common.getLocalLoginToken()
      },
      metadata: {
        filename: file.name,
        filetype: file.type,
        fieldname: type,
        "content-type": file.type,
        "@type": "swticket"
      },
      onError: (error) => {
        console.error("Errore caricamento file -> " . error)
        release();
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
        this.uploadPercentages[file] = percentage;
        $("#divCaricamento_file").html(
          '<div class="progress">' +
            '<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="' + percentage + '" aria-valuemin="0" aria-valuemax="100" style="width: '+ percentage +'%">' + percentage +'%</div>' +
          '</div>'
        );
      },
      onSuccess: () => {
        window.contentSaved = true;
        release();
      }
    });
    return uploadFile.start();
  }

  clickBrands(tipoFunzione, params){
    this.setState({marcaDefault: params[tipoFunzione], tipoTicket: ''})
  }

  getTipologieTicketAbilitate = () => {
    let ret = [];
    let allowedTypes = null;
    
    if (this.state.marcaDefault === undefined || this.state.marcaDefault === null ||
       this.state.tipologie_ticket_attive === undefined || this.state.tipologie_ticket_attive === null || this.state.tipologie_ticket_attive.length <= 0)
      return ret;

    if (this.state.pageContent.description.allowed_types !== undefined && 
        this.state.pageContent.description.allowed_types !== null && 
        this.state.pageContent.description.allowed_types.length > 0
    ){
      allowedTypes = this.state.pageContent.description.allowed_types;
    }

    let tipologieMarca = this.state.tipologie_ticket_attive[this.state.marcaDefault];
    for (let key in tipologieMarca){
      let elem = tipologieMarca[key];

      if (elem?.no_insert === true)
        continue;
      if (allowedTypes !== null && allowedTypes.indexOf(key) <= -1) 
        continue;
      
      ret[key] = elem;
    }
    
    return ret;
  }

  render(props){
    if(this.state.userInfo.id !== undefined && this.state.Lock.creator !== undefined){
      if(this.state.userInfo.id !== this.state.Lock.creator){
        return (<Navigate from={this.state.pathname} to={common.getUrlPath(this.state.pageContent['@id'])} />)
      }
    }
    var is_disabled = false;
    const $ = window.$
    if(this.props.marche_attive !== undefined && this.props.marche_attive.length > 1){
      if (multibrand.is_multibrand_portal()){
        if ($('.select_marche').val() === "tutti_modelli")
          is_disabled = true;
          $(".select_ticket").val("").trigger("change")
      }
    }

    let filterVM = (
      <FilterVM
        customClass="col-xl-6 col-lg-6"
        marche_attive={this.props.marche_attive}
        tableType='ticket'
        onClick={(tipoFunzione, params) => this.clickBrands(tipoFunzione, params)}>
      </FilterVM>
    )
    
    if (!multibrand.is_multibrand_portal() && this.state.marcaDefault !== undefined){
      filterVM = (
        <input  type="hidden"
                name="select_vm"
                id="select_vm"
                value={this.state.marcaDefault}
        />
      )
    }

    let tipologieTicket = this.getTipologieTicketAbilitate();
    return (
      <>
        <Prompt message={location => common.getTrad("La pagina contiene modifiche non salvate. Si è sicuri di voler abbandonare senza salvare?")}/>
        <div id="notifica_messaggio"></div>
        <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8 linea_sfumata" id="content_page">
          <div className="row">
            <div className="content_titolo">
              <div className="content_titolo_int">
                <span className="titolo_sezione">
                  {this.state.pageTitle}
                </span>
              </div>
            </div>
          </div>
          <>
            {Object.keys(this.state.tipologie_ticket_attive).length === 0 ?
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 content_testo">
                  <div className="content_filtro tab active col-xl-12">
                    <div className="content_testo">
                      <div className="clear sw-alert alert alert-danger mt-3">
                        <span dangerouslySetInnerHTML={{ __html: common.getTrad("I Ticket non stanno funzionando correttamente. Contatta il <b>supporto tecnico</b> se il problema persiste.")}}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            :
              <>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="content_filtro tab active col-xl-12">
                    { this.state.pageContent.is_folderish ?
                      <h4>{common.getTrad("Apertura Ticket")}</h4>
                    :
                      <h4>{common.getTrad("Modifica Ticket")}</h4>
                    }
                    <hr/>
                    {!this.state.pageContent.is_folderish && this.props.pageContent.subjects ?
                      <>
                        <div className="content_testo">
                          <div className="info_singolo_contenuto">
                            <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Data pubblicazione")}><i className="far fa-calendar-alt"></i> {moment(this.state.pageContent.created).format('LL')}</span>
                            <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Tipo")}><i className="fas fa-tag"></i>&nbsp;
                            {
                              this.state.pageContent.subjects ?
                                this.state.pageContent.marcaDefault === undefined ?
                                  this.state.tipologie_ticket_attive[this.state.pageContent.subjects].titolo
                                :
                                  this.state.tipologie_ticket_attive[this.state.pageContent.marcaDefault][this.state.pageContent.tipologia].titolo
                              :
                                ""
                            }
                            </span>
                            <p data-toggle="tooltip" data-placement="top" title={common.getTrad("Stato avanzamento")} className="d-inline" dangerouslySetInnerHTML={{ __html: this.statoTicket()}} />
                            <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Numero ID")}><i className="fas fa-info-circle"></i> {this.state.pageContent.id}</span>
                            <ContentCreators creators={this.state.pageContent.creators} />
                            <ContentBrand content={this.state.pageContent} />
                            {this.state.userInfo !== undefined && this.state.userInfo.ticket_group_by_csoft && !this.state.userInfo.is_admin_ticket_group ?
                              <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Sister Company")}>
                                <i className="fas fa-network-wired"></i>&nbsp;
                                {this.state.csoftGroup}
                              </span>
                            :
                              <></>
                            }
                          </div>
                        </div>
                        {this.state.userInfo.is_admin_ticket !== undefined && this.state.userInfo.is_admin_ticket ?
                          <div id="actions_page" className="mb-3 col p-0">
                            <div className="btn-group-sm" role="group" aria-label="Basic example">
                              {this.state.pageContent.review_state.indexOf('closed') > -1 ?
                                 <button type="button" className="btn btn-danger" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{common.getTrad("Chiuso")} <i className="fas fa-caret-down"></i></button>
                              :
                               this.state.pageContent.review_state.indexOf('published') > -1 ?
                                 <button type="button" className="btn btn-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{common.getTrad("In lavorazione")} <i className="fas fa-caret-down"></i></button>
                              :
                              this.state.pageContent.review_state.indexOf('incomplete') > -1 ?
                                <button type="button" className="btn btn-warning" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{common.getTrad("Incompleto")} <i className="fas fa-caret-down"></i></button>
                              :
                                <button type="button" className="btn btn-success" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{common.getTrad("Aperto")} <i className="fas fa-caret-down"></i></button>

                               }
                                <div className="dropdown-menu">
                                {this.state.pageContent.review_state.indexOf('closed') > -1 ?
                                  <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state !== 'published' ? 'published' : '');}}>{common.getTrad("In lavorazione")}</span>
                                :
                                this.state.pageContent.review_state.indexOf('incomplete') > -1 ?
                                  <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state !== 'pending' ? 'pending' : '');}}>{common.getTrad("Aperto")}</span>
                                :
                                this.state.pageContent.review_state.indexOf('pending') > -1 ?
                                  <>
                                    <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state !== 'published' ? 'published' : '');}}>{common.getTrad("In lavorazione")}</span>
                                    <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state !== 'incomplete' ? 'incomplete' : '');}}>{common.getTrad("Incompleto")}</span>
                                  </>
                                :
                                  <>
                                    <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state !== 'incomplete' ? 'incomplete' : '');}}>{common.getTrad("Incompleto")}</span>
                                    <span className="dropdown-item" onClick={() => {this.changeReviewState(this.state.pageContent.review_state !== 'closed' ? 'closed' : '');}}>{common.getTrad("Chiuso")}</span>
                                  </>
                                 }
                              </div>
                            </div>
                          </div>
                        :
                          <></>
                        }
                      </>
                    :
                      <></>
                    }
                  </div>
                </div>
                {this.state.pageContent.is_folderish && this.props.pageContent.subjects !== undefined && this.props.pageContent.subjects.length <= 0 ?
                  <div id="filtri_files" className="col-12">
                    <div className="row content_filtro">
                      {filterVM}
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 row-eq-height">
                        <div className="col-12 p-0">
                          <p>{common.getTrad("Filtra Tipologie")}</p>
                          <div className="form-group">
                            <select disabled={is_disabled} className="form-control select2 select_ticket" autoComplete="off" id="select_ticket" onChange={this.handleChangeTipoTicket}>
                              <option value=""></option>
                              {
                                  Object.keys(tipologieTicket).map((key, i) => {
                                    let value = tipologieTicket[key];
                                    return (
                                      <option key={key} value={key}>{common.getTrad(value.titolo)}</option>
                                    )
                                  })
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                :
                  <></>
                }
                { this.state.tipoTicket ?
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
                  <div className="tab active ">
                    <form autoComplete="off" className="add_element" action="#" method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-xl-12">
                          <h1>
                          {this.state.marcaDefault !== undefined ?
                              common.getTrad(this.state.tipologie_ticket_attive[this.state.marcaDefault][this.state.tipoTicket].titolo)
                            :
                              this.state.tipologie_ticket_attive[this.state.tipoTicket].titolo
                          }</h1>
                        </div>
                        <div className="col-xl-12">
                          <div className="form-group">
                            <label htmlFor="title"><b>{common.getTrad("Titolo")}</b> <span className="danger">*</span></label>
                            <input autoFocus autoComplete="off" type="text" required className="form-control" id="title" name="title" placeholder={common.getTrad("Inserisci il titolo")} />
                          </div>
                        </div>
                        {this.renderFormTicket()}
                        {this.state.googleCloudBucketPath && this.state.indexTitle === false ?
                          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-group">
                              <label htmlFor="file_multiple">{common.getTrad("File allegato")}</label>
                              <div>
                                <p className="text-muted">
                                  <span className="Trad_dimensione_massima_200MB"></span>
                                </p>
                                <input type="file" name="file_multiple" id="file_multiple" className="inputfile inputfile-6" multiple />
                                <label htmlFor="file_multiple">
                                  <span className="span_label_file"></span>
                                  <strong className="Trad_scegli_file"></strong>
                                </label>
                                <div id="data-pre"></div>
                              </div>
                            </div>
                          </div>
                        :
                          <></>
                        }
                      </div>
                      <div className="row">
                      <div className="col-xl-12 col-lg-12 col-xs-12">
                        <div className="form-row">
                          <div className="form-group col bottoni_invio">
                            <button type="submit" className="btn btn-primary float-right">{common.getTrad("Salva")}</button>
                            <button id="annulla" type="button" className="btn btn-light float-right mr-2">{common.getTrad("Annulla")}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                  </div>
                :
                  <>
                    {!this.state.pageContent.is_folderish ?
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
                        <div className="tab active ">
                          <form autoComplete="off" className="add_element" action="#" method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>
                            <div className="row">
                              <div className="col-xl-12">
                                <h1>
                                {
                                  this.state.pageContent.marcaDefault === undefined ?
                                    common.getTrad(this.state.tipologie_ticket_attive[this.props.pageContent.subjects].titolo)
                                  :
                                    this.state.tipologie_ticket_attive[this.state.pageContent.marcaDefault][this.state.pageContent.tipologia].titolo
                                }
                                </h1>
                              </div>
                              <div className="col-xl-12">
                                <div className="form-group">
                                  <label htmlFor="title"><b>{common.getTrad("Titolo")}</b> <span className="danger">*</span></label>
                                  <input autoFocus autoComplete="off" type="text" required className="form-control" id="title" name="title"
                                  defaultValue={this.state.pageContent.title}   placeholder={common.getTrad("Inserisci il titolo")} />
                                </div>
                              </div>
                              {this.renderFormTicket()}
                              {this.state.googleCloudBucketPath && this.state.indexTitle === false ?
                                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="form-group">
                                    <label htmlFor="file_multiple">File allegato</label>
                                    <div>
                                      <p className="text-muted">
                                        <span className="Trad_dimensione_massima_200MB"></span>
                                      </p>
                                      <input type="file" name="file_multiple" id="file_multiple" className="inputfile inputfile-6" multiple />
                                      <label htmlFor="file_multiple">
                                        <span className="span_label_file"></span>
                                        <strong className="Trad_scegli_file"></strong>
                                      </label>
                                      <div id="data-pre"></div>
                                    </div>
                                  </div>
                                </div>
                              :
                                <></>
                              }
                            </div>
                            <div className="row">
                            <div className="col-xl-12 col-lg-12 col-xs-12">
                              <div className="form-row">
                                <div className="form-group col bottoni_invio">
                                  <button type="submit" className="btn btn-primary float-right">{common.getTrad("Salva")}</button>
                                  <button id="annulla" type="button" className="btn btn-light float-right mr-2" >{common.getTrad("Annulla")}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                        {this.keyComments !== -1 ?
                          <div className="row mt-5">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
                              <div className="content_ticket_form">
                                <h4>{common.getTrad("Storico Commenti")}</h4>
                                <hr/>
                                <div className="page">
                                  <div className="marvel-device nexus5">
                                    <div className="top-bar"></div>
                                    <div className="screen">
                                      <div className="screen-container">
                                        <div className="chat">
                                          <div className="chat-container">
                                            <div className="conversation">
                                              {this.state.googleCloudBucketPath && this.state.pageContent.file !== undefined && this.state.pageContent.file !== null ?
                                                <div className={this.state.userInfo.is_admin_ticket ? "sent message" : "received message"}>
                                                  <div>
                                                    <img src={env.STATIC + "images/estensioni/" + this.state.pageContent.file.filename.split('.').pop() + ".png"}
                                                      onError={(e)=>{e.target.onerror = null; e.target.src=env.STATIC + "images/estensioni/default.png"}} className="img-responsive"
                                                      alt={this.state.pageContent.file.filename} title={this.state.pageContent.file.filename}
                                                    />
                                                    {this.state.pageContent.file.filename}
                                                    <a style={{cursor: "pointer"}} className="float-right" onClick={() => {this.handleDownloadFile(common.getBackendUrl(this.state.pageContent.file.download), this.state.pageContent.file.filename)}}>
                                                      <i className="fas fa-2x fa-file-download"></i>
                                                    </a>
                                                    <br/>
                                                    <small>{common.humanFileSize(this.props.pageContent.file.size)}</small>
                                                  </div>
                                                  <span className="metadata">
                                                    <span className="time">
                                                      {moment(this.state.pageContent.created).format('LLL')}<br/>
                                                      <i className="fas fa-user"></i>&nbsp;{common.customCreators(this.state.pageContent.creators)}
                                                    </span>
                                                  </span>
                                                </div>
                                              :
                                                <></>
                                              }
                                              {this.renderChatComments()}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        :
                          <></>
                        }
                        </div>
                      </div>
                    :
                      ""
                    }
                  </>
                }
              </>
            }
          </>
        </div>
      </>
    )
  }
}


export default EditTicket;
