import React from 'react';
import { Link } from "react-router-dom";
import { ContentCreators } from '../Page/PageTicket';
import moment from 'moment';
import FilterVM from '../FilterVM/FilterVM';
import FilterModels from '../FilterModels/FilterModels';
import FilterChassis from '../FilterChassis/FilterChassis';

const common = require('../common/common');
const env = require("../../config.js").default;

class SearchPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.state.resultSearch = {};
    this.state.resultSearchTicket = {};
    this.state.pagina = {
      pagina_attuale:  1,
      testo_cercato: window.$(".respSearchButton .form_ricerca:visible .search").val() !== undefined ? window.$(".respSearchButton .form_ricerca:visible .search").val() : '',
      filtro_modello: '',
      filtro_marca: '',
      telaio_cercato: '',
    };
    this.state.userInfo = {};
    this.state.userInfo.roles = [];
    this.state.marche_attive = common.getAppEnabledVM();
    this.state.firstSearch = false;
  }

  UNSAFE_componentWillMount(){
    document.getElementById('body').className='content_page';
    common.setStateUserInfo(this);
  }

  componentDidMount(){
    const $ = window.$;
    if(!this.state.firstSearch && this.state.pagina.testo_cercato !== ''){
      $(".search_tabella").val(this.state.pagina.testo_cercato);
      $(".respSearchButton .form_ricerca:visible .search").val('');
      this.setState({firstSearch: true});
      this.onClickPaging("cerca", {testo: this.state.pagina.testo_cercato});
    }

    $(".search_tabella").keypress((e) =>{
      if(e.which === 13 || e.which === 10){
        e.preventDefault();
        $("#ricercaTabella").click();
      }
    });

    $('input.search_tabella').on("change keyup cut search", (e) => {
      if(this.state.pagina.testo_cercato !== undefined && this.state.pagina.testo_cercato !== null && this.state.pagina.testo_cercato !== ''){
        e.preventDefault();
        if(!e.target.value)
          this.onClickPaging("cerca", {testo: ''});
      }
    });

    $("#ricercaTabella").click((e) => {
      e.preventDefault();
      const $ = window.$;
      var parametri = {
        testo: $("input.search_tabella").val()
      }

      this.onClickPaging("cerca", parametri );
    });
  }

  renderVociSearch(){
    if(this.state.resultSearch.items_total > 0){
      return this.state.resultSearch.items.map(function(result, key){
        if(result !== ''){
          var descriptionParent = result.parent.description ? JSON.parse(result.parent.description) : "";
          if(descriptionParent.tableType === 'contact'  || descriptionParent.tableType === 'ticket'){
            var description = result.description ? JSON.parse(result.description) : "";
          }
        }
        return(
          <div className="row mb-4" key={key}>
            <div className="col-xl-4 col-md-12">
              <h3>
                <b><Link to={common.getUrlPath(result['@id'])} style={{wordBreak: 'break-word'}}> {result.title}</Link></b> {
                  common.getUrlPath(result['@id']).split('/').length > 3 ?
                    result.parent ?
                      " - " + result.parent.title
                    :
                      <></>
                  :
                  <></>
                }
              </h3>
              <Link to={common.getUrlPath(result['@id'])}> {common.getFrontendUrl(result['@id'])}</Link>
            </div>
            <div className="col-xl-8 col-md-12">
              {!result.is_folderish ?
                descriptionParent.tableType === 'default' ?
                  result.description !== undefined && result.description !== null && result.description !== '' ?
                    result.description.length > 60 ?
                      result.description.substr(0, result.description.lastIndexOf(' ')) + '...'
                    :
                      result.description
                  :
                    <></>
                :
                  descriptionParent.tableType === 'ticket' ?
                    <>
                      <div className="content_testo">
                        <div className="info_singolo_contenuto m-0">
                          <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Data pubblicazione")}><i className="far fa-calendar-alt"></i> {moment(result.created).format('LL')}</span>
                          <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Tipo")}><i className="fas fa-tag"></i>&nbsp;
                           {
                            result.subjects ?
                              descriptionParent.tipologieTicket[result.subjects].titolo
                            :
                            ""
                          }
                          </span>
                          {result.review_state === "closed" ?
                            <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Stato avanzamento")} style={{background: "#dc3545",boxShadow:"1px 1px 3px -1px #000", padding: "0.25em 0.5em",color: "#fff"}}><i className="fas fa-envelope"></i> {common.getTrad("Chiuso")}</span>
                          :
                          result.review_state === "incomplete" ?
                            <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Stato avanzamento")} style={{background: "#ffc107", boxShadow:"1px 1px 3px -1px #000", padding: "0.25em 0.5em",color: "#000"}}><i className="fas fa-envelope-open"></i> {common.getTrad("Incompleto")}</span>
                          :
                          result.review_state === "published" ?
                            <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Stato avanzamento")} style={{background: "#007bff", boxShadow:"1px 1px 3px -1px #000", padding:" 0.25em 0.5em",color: "#fff"}}><i className="far fa-envelope"></i> {common.getTrad("In lavorazione")}</span>
                          :
                            <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Stato avanzamento")} style={{background:"#28a745", boxShadow:"1px 1px 3px -1px #000", padding: "0.25em 0.5em",color: "#fff"}}><i className="fas fa-envelope-open-text"></i> {common.getTrad("Aperto")}</span>
                          }
                          <span data-toggle="tooltip" data-placement="top" title={common.getTrad("Numero ID")}><i className="fas fa-info-circle"></i> {result.id}</span>
                          <ContentCreators creators={result.creators} />
                          <br/>
                        </div>
                      </div>
                      <div className="mt-2">
                        <table className="table table-striped table-bordered dt-responsive nowrap mb-5" style={{width: '100%'}}>
                          <tbody>
                            {descriptionParent.tipologieTicket[result.subjects].campi.map((singleTicket, keys) => {
                              return (
                                <tr key={keys}>
                                  {singleTicket.id !== 'file' && singleTicket.id !== 'comments' ?
                                    <>
                                      <td className="voce_dati"><b>{singleTicket.titolo}</b></td>
                                        {description[keys].value ?
                                          singleTicket.id === 'descrizione_problema' ?
                                            description[keys].value.search("\n") ?
                                            <td className="valore_dati" dangerouslySetInnerHTML={{ __html:(description[keys].value).replace(/\r?\n/g,'<br/>')}}/>
                                            :
                                            <td className="valore_dati">{description[keys].value}</td>
                                          :
                                            singleTicket.id === 'date_input' ?
                                              <td className="valore_dati">{moment(description[keys].value).format('LL')}</td>
                                            :
                                              <td className="valore_dati">{description[keys].value}</td>
                                        :
                                          <td className="valore_dati">&hellip;</td>
                                        }
                                        </>
                                    :
                                      <></>
                                  }
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  :
                    descriptionParent.tableType === 'contact' ?
                    <table className="table table-striped table-bordered dt-responsive nowrap mb-5" style={{width: '100%'}}>
                      <tbody>
                        <tr>
                          <td className="voce_dati"><b>{common.getTrad("Nome")}</b></td>
                          <td className="valore_dati">{description.name}</td>
                        </tr>
                        <tr>
                          <td className="voce_dati"><b>{common.getTrad("Cognome")}</b></td>
                          <td className="valore_dati">{description.surname}</td>
                        </tr>
                        {description.department ?
                          <tr>
                            <td className="voce_dati"><b>{common.getTrad("Reparto")}</b></td>
                            <td className="valore_dati">{description.department}</td>
                          </tr>
                        :
                          <></>
                        }
                        {description.email ?
                          <tr>
                            <td className="voce_dati"><b>{common.getTrad("Email")}</b></td>
                            <td className="valore_dati"><a href={"mailto:" + description.email}>{description.email}</a></td>
                          </tr>
                        :
                          <></>
                        }
                        {description.phone ?
                          <tr>
                            <td className="voce_dati"><b>{common.getTrad("Telefono")}</b></td>
                            <td className="valore_dati"><a href={"tel:"+description.phone}>{description.phone}</a></td>
                          </tr>
                        :
                          <></>
                        }
                        {description.phone2 ?
                          <tr>
                            <td className="voce_dati"><b>{common.getTrad("Cellulare")}</b></td>
                            <td className="valore_dati"><a href={"tel:"+description.phone2}>{description.phone2}</a></td>
                          </tr>
                        :
                          <></>
                        }
                        {description.address ?
                          <tr>
                            <td className="voce_dati"><b>{common.getTrad("Indirizzo, Sede o Località")}</b></td>
                            <td className="valore_dati">{description.address}</td>
                          </tr>
                        :
                          <></>
                        }
                      </tbody>
                    </table>
                    :
                      <></>
                :
                  <></>
              }
            </div>
            <div className="col-xl-12">
              <hr />
            </div>
          </div>
        )
      });
    }
  }

  onClickPaging(tipofunzione, parametri){
    var paging = this.generaUrl(tipofunzione, parametri);
    if(paging !== false)
      this.pagingGetData(paging);
  }

  pagingGetData(paging){
    const $ = window.$;
    $("body #app .loadingDiv").fadeIn("fast");

    if(paging.search("\\?") > -1)
      paging = paging + "&";
    else
      paging = paging + "?";

    // if(this.state.numResultSearchTicket.items_total > 0){
    //   paging = paging + "portal_type=swticket";
    // }else{
    paging = paging + "portal_type=swcontent&portal_type=News%20Item";
    common.apiCall(common.getBackendUrl(paging), "GET", {}, true, true)
    .then(pagingContent => {
      if(pagingContent.error === false){
        $("body #app .loadingDiv").fadeOut();
        var resultSearch = this.state.resultSearch;
        resultSearch.batching = pagingContent.batching;
        resultSearch.items = pagingContent.items;
        resultSearch.items_total = pagingContent.items_total;
        this.setState({resultSearch: resultSearch});
      }else{
        $("body #app .loadingDiv").fadeOut();
      }
    });
  }

  generaUrl(tipofunzione, parametri){
    //Parametri:
    var statoPagina = this.state.pagina;
    var statoPaginaNext = JSON.parse(JSON.stringify(statoPagina));
    switch(tipofunzione) {
      case "paginazione_custom":
        if(parametri.calcolaPagina !== undefined && parametri.calcolaPagina !== null && parseInt(parametri.calcolaPagina)){
          statoPaginaNext.pagina_attuale = statoPagina.pagina_attuale + parseInt(parametri.calcolaPagina);
        }
        else if(parametri.vaiPagina !== undefined && parametri.vaiPagina !== null){
          if(parametri.vaiPagina === "end"){
            statoPaginaNext.pagina_attuale = parseInt(this.state.resultSearch.items_total / env.TABLE_PAGE_LENGTH);
            if((this.state.resultSearch.items_total % env.TABLE_PAGE_LENGTH) > 0){
              statoPaginaNext.pagina_attuale ++;
            }
          }
          else if(parseInt(parametri.vaiPagina))
            statoPaginaNext.pagina_attuale = parseInt(parametri.vaiPagina);
        }
      break;
      case "filtro_marca":
        statoPaginaNext.filtro_marca = parametri.filtro_marca;
        statoPaginaNext.pagina_attuale = 1;
      case "filtro_modello":
        statoPaginaNext.filtro_modello = parametri.filtro_modello;
        statoPaginaNext.pagina_attuale = 1;
      break;
      case "cerca":
        statoPaginaNext.testo_cercato = parametri.testo;
        statoPaginaNext.pagina_attuale = 1;
      break;
    }
    var newUrl = env.BACKEND + common.getLanguage() + '/';

    // if(this.state.resultSearchTicket.items_total === 0)
    //   newUrl = newUrl + this.state.pathname.substring(1,this.state.pathname.length) + "/";

    var pageStart = env.TABLE_PAGE_LENGTH  * (statoPaginaNext.pagina_attuale - 1);
    if(pageStart === 1)
      pageStart --;

    newUrl = newUrl + "@search?fullobjects=1";
    if(this.state.userInfo.roles.indexOf("Reviewer")  > -1)
      newUrl = newUrl + "&show_inactive=1";
    newUrl = newUrl + "&b_size=" + env.TABLE_PAGE_LENGTH + "&b_start=" + pageStart;

    if(statoPaginaNext.filtro_marca){
      if(statoPaginaNext.filtro_marca !== 'tutti_modelli'){
        newUrl = newUrl + "&Subject:list=sw-brand-" + statoPaginaNext.filtro_marca + "&Subject:list=" + statoPaginaNext.filtro_marca + "_tutti_modelli&Subject:list=tutti_modelli";
      }
    }else{
      if(common.get_localStorage("valoreMarca") !== undefined && common.get_localStorage("valoreMarca") !== null && common.get_localStorage("valoreMarca") !== ''){
        newUrl = newUrl + "&Subject:list=sw-brand-" + common.get_localStorage("valoreMarca") + "&Subject:list=" + common.get_localStorage("valoreMarca") + "_tutti_modelli&Subject:list=tutti_modelli";
      }
    }

    if(statoPaginaNext.filtro_modello){
      newUrl = newUrl + "&Subject:list=" + statoPaginaNext.filtro_modello;
      if(newUrl.indexOf("&Subject:list=tutti_modelli") <= -1)
				newUrl = newUrl + "&Subject:list=tutti_modelli";
    }else{
      if(common.get_localStorage("valoreModello") !== undefined && common.get_localStorage("valoreModello") !== null && common.get_localStorage("valoreModello") !== ''){
        newUrl = newUrl + "&Subject:list=" + common.get_localStorage("valoreModello");
        if(newUrl.indexOf("&Subject:list=tutti_modelli") <= -1)
          newUrl = newUrl + "&Subject:list=tutti_modelli";
      }
    }

    if(common.get_localStorage("valoreModello") !== undefined  && common.get_localStorage("valoreModello") !== '' && common.get_localStorage("valoreMarca") !== undefined && common.get_localStorage("valoreMarca") !== ''){
			if(newUrl.indexOf("&Subject:list=" + common.get_localStorage("valoreMarca") + "_tutti_modelli") <= -1)
				newUrl = newUrl + "&Subject:list=" + common.get_localStorage("valoreMarca") + "_tutti_modelli";
		}

    if(common.get_localStorage("valoreModello") !== undefined  && common.get_localStorage("valoreModello") !== '' && window.cc.marche_attive.length === 1){
			if(newUrl.indexOf("&Subject:list=" + window.cc.marche_attive[0].ZFORN + "_tutti_modelli") <= -1)
				newUrl = newUrl + "&Subject:list=" + window.cc.marche_attive[0].ZFORN + "_tutti_modelli";
		}

    if(statoPaginaNext.testo_cercato){
			if (!statoPaginaNext.testo_cercato.replace(/\s/g, '').length) {
				newUrl = newUrl + "";
			}else{
        newUrl = newUrl + "&SearchableText=*" + statoPaginaNext.testo_cercato + "*";
			}
		}

    this.setState({pagina: statoPaginaNext});
    return newUrl;
  }

  paginazioneCustomClick(e){
    e.preventDefault();
    var parametri = {
      vaiPagina: e.target.id.split("_")[2]
    }
    this.onClickPaging("paginazione_custom", parametri );
  }

  componentDidUpdate(nextProps){
    const $ = window.$;

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });

    var pagina = this.state.pagina;
    var paginaAttuale = pagina.pagina_attuale, pagineTotali = '';

    pagineTotali = parseInt(this.state.resultSearch.items_total / env.TABLE_PAGE_LENGTH);
    if((this.state.resultSearch.items_total % env.TABLE_PAGE_LENGTH) > 0){
      pagineTotali ++;
    }

    var buttons = [];
    var paginaTemp = '';
    $('#paginazione_custom').html("");
    if(pagineTotali > 1){
      // paginazione pagine precedenti
      if(paginaAttuale === pagineTotali && pagineTotali >= 3){
        paginaTemp = (paginaAttuale - 2);
        buttons.push('<li class="paginate_button page-item"><p id="page_num_' + paginaTemp +'" class="page-link">' + paginaTemp + '</p></li>');
      }
      if(paginaAttuale > 1){
        paginaTemp = (paginaAttuale - 1);
        buttons.push('<li class="paginate_button page-item"><p id="page_num_' + paginaTemp +'" class="page-link">' + paginaTemp + '</p></li>');
      }
      // solo la pagina attiva
      if( paginaAttuale === pagina.pagina_attuale){
        buttons.push('<li class="paginate_button page-item active"><p id="page_num_' + paginaAttuale +'" class="page-link">' + paginaAttuale + '</p></li>');
      }

      // le pagine successive
      if(paginaAttuale < pagineTotali){
        paginaTemp = (paginaAttuale + 1);
        buttons.push('<li class="paginate_button page-item"><p id="page_num_' + paginaTemp +'" class="page-link">' + paginaTemp + '</p></li>');
      }
      if(pagineTotali >= 3){
        paginaTemp = (paginaAttuale + 2);
        buttons.push('<li class="paginate_button page-item"><p id="page_num_' + paginaTemp +'" class="page-link">' + paginaTemp + '</p></li>');
      }
      // creazione della paginazione numerica
      var paginazione_custom_content = "";
      for (var i=0; i< 3; i++){
        if(buttons[i] !== undefined && buttons[i] !== null){
          $('#paginazione_custom').html(paginazione_custom_content + buttons[i]);
          paginazione_custom_content = $('#paginazione_custom').html();
        }
      }
    }
  }
  //
  // falseClick(t1,t2){
  //
  // }


  render(props){
    return (
      <div id="portal-columns" className="clearfix">
        <div className="row">
          <div className="col-xl-12 linea_sfumata" id="content_page">
            <div className="row">
              <div className="content_titolo">
                <div className="content_titolo_int">
                  <span className="titolo_sezione">{common.getTrad("Ricerca")}</span>
                </div>
              </div>
            </div>
            <div id="filtri_files" className="col-12">
              <div className="row justify-content-between content_filtro">
                {this.state.marche_attive !== undefined && this.state.marche_attive.length > 1 ?
                  <FilterVM customClass={this.state.marche_attive !== undefined && this.state.marche_attive.length > 1 ? "col-xl-4 col-lg-4" : ""} marche_attive={this.state.marche_attive} onClick={(tipoFunzione, params) => this.onClickPaging(tipoFunzione, params)}></FilterVM>
                :
                  ""
                }
                <FilterChassis marcaDefault={this.state.pagina.filtro_marca} customClass={this.state.marche_attive !== undefined && this.state.marche_attive.length > 1 ? "col-xl-4 col-lg-4" : ""} marche_attive={this.state.marche_attive}></FilterChassis>
                <FilterModels marcaDefault={this.state.pagina.filtro_marca} customClass={this.state.marche_attive !== undefined && this.state.marche_attive.length > 1 ? "col-xl-4 col-lg-4" : ""} marche_attive={this.state.marche_attive} onClick={(tipoFunzione, params) => this.onClickPaging(tipoFunzione, params)}></FilterModels>
                <div className="col-12 mt-2">
                  <div className="col-12 p-0">
                    <p>{common.getTrad("Dettagli ricerca")}</p>
                    <div className="input-group">
                      <input className="form-control search_tabella" name="q" type="search" placeholder={common.getTrad("Cerca")} aria-label="Cerca"/>
                      <button className="btn btn-outline-light bottone_default" id="ricercaTabella" type="submit">
                        <i className="fas fa-search"></i> {common.getTrad("Cerca")}
                      </button>
                    </div>
                    <p className="mt-1">
                    {this.state.resultSearch.items_total > 0 ?
                      this.state.resultSearch.items_total <= 1 ?
                        "(" + this.state.resultSearch.items_total + ") " + common.getTrad("risultato trovato.")
                      :
                        "(" + this.state.resultSearch.items_total + ") " + common.getTrad("risultati trovati.")
                        :
                      common.getTrad("Nessun risultato trovato.")

                    }
                    {this.state.resultSearch.items_total > 0 ?
                      this.state.pagina.testo_cercato ?
                        <>
                         &nbsp;&nbsp;
                          <span>
                            {common.getTrad("Parola ricercata")}:
                            &nbsp;<b>{this.state.searchTextOld}</b>
                          </span>
                        </>
                      :
                        <></>
                    :
                      <></>
                    }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {this.state.resultSearch.items_total > 0 ?
              <div className="tab pane active mb-3">
                {this.renderVociSearch()}
                <div className="row">
                  <div className="col-lg-6 col-sm-12 col-xs-12">
                    {common.getTrad("Elementi mostrati") + " (" + this.state.resultSearch.items.length + ") " + common.getTrad(" su un totale di") +" (" + this.state.resultSearch.items_total + ")"}
                  </div>
                  {this.state.resultSearch.items_total > env.TABLE_PAGE_LENGTH ?
                    this.state.resultSearch.batching ?
                      <div className="col-lg-6 col-sm-12 col-xs-12">
                        <div className="dataTables_paginate paging_full_numbers float-right" id="table_DefaultTable_paginate">
                          <ul className="pagination">
                            {!this.state.resultSearch.batching.prev ?
                              <li className="disabled paginate_button page-item first">
                                <p className="page-link"><i className="fas fa-fast-backward"></i></p>
                              </li>
                            :
                              <li onClick={() => this.onClickPaging("paginazione_custom", {vaiPagina: 1})} className="paginate_button page-item first">
                                <p className="page-link"><i className="fas fa-fast-backward"></i></p>
                              </li>
                            }
                            {this.state.resultSearch.batching.prev ?
                              <li onClick={() => this.onClickPaging("paginazione_custom", {calcolaPagina: -1})} className="paginate_button page-item previous" id="table_DefaultTable_previous">
                                <p className="page-link">{common.getTrad("Precedente")}</p>
                              </li>
                            :
                              <li className="paginate_button page-item previous disabled" id="table_DefaultTable_previous">
                                <p className="page-link">{common.getTrad("Precedente")}</p>
                              </li>
                            }
                            <div style={{display: 'flex'}} id="paginazione_custom" onClick={(e) => this.paginazioneCustomClick(e)}></div>
                            {this.state.resultSearch.batching.next ?
                              <li onClick={() => this.onClickPaging("paginazione_custom", {calcolaPagina: 1})} className="paginate_button page-item next" id="table_DefaultTable_next">
                                <p className="page-link" >{common.getTrad("Successivo")}</p>
                              </li>
                            :
                              <li className="paginate_button page-item next disabled" id="table_DefaultTable_next">
                                <p className="page-link">{common.getTrad("Successivo")}</p>
                              </li>
                            }

                            {this.state.resultSearch.batching.next ?
                              <li onClick={() => this.onClickPaging("paginazione_custom", {vaiPagina: "end"})} className="paginate_button page-item last">
                                <p className="page-link"><i className="fas fa-fast-forward"></i></p>
                              </li>
                            :
                              <li className="disabled paginate_button page-item last">
                                <p className="page-link"><i className="fas fa-fast-forward"></i></p>
                              </li>
                            }
                          </ul>
                        </div>
                      </div>
                    :
                      <></>
                  :
                    <></>
                  }
                </div>
              </div>
            :
              <></>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default SearchPage;
