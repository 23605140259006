import React from 'react';
const common = require('../common/common');
const env = require("../../config.js").default;

class FilterLanguages extends React.Component{

	constructor(props){
		super(props);
		this.state = {};
		this.state.translations = this.props.translations;
		this.state.pathname = this.props.pathname;
		this.state.pathnameBackend = this.props.pathnameBackend;
	}

	componentDidMount(){
		const $ = window.$;
		function format(state) {
			var iso2_image =  $(state.element).attr('data-iso2');
			if (!state.id) return state.text; // optgroup
			return '<img class="bandiere_icone" src="'+env.STATIC+'images/bandiere/'+ iso2_image +'.svg" />' +
				'<span class="flag-text">' + state.text + '</span>';
		}
		$("#select_language").select2({
			templateResult: format,
			templateSelection: format,
			escapeMarkup: function(m) { return m; },
			language: common.getLanguage(),
			placeholder: common.getTrad("Seleziona una Lingua"),
		});

		$('.select_language').on('select2:select keyup select2:unselect', (e) => {
			e.preventDefault();
			var parametri = {
				all_lang: (e.target.value !== "all_lang" ? false : true),
				url_pagina: (e.target.value !== "all_lang" ? e.target.value : common.getBackendUrl(this.state.pathname)),
			}
			common.set_localStorage("valoreLingua", $('.select_language option:selected').data('iso2'));
			this.props.onClick("tipo_ricerca", parametri );
			return false;
		});
 	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				translations: nextProps.translations,
				pathname: nextProps.pathname,
				pathnameBackend: nextProps.pathnameBackend,
			});
		}
	}

	renderSelectLingue(){
	 	try{
			let translations = JSON.parse(JSON.stringify(this.state.translations));
			var lingua_attuale = {
				'@id': common.deleteURLParameter(this.state.pathnameBackend),
				language: common.getUrlPath(this.state.pathname).split('/')[1],
			}
			translations.items.push(lingua_attuale);
			return translations.items.map((index, key) => {
				return(
					<option data-iso2={index.language} key={key} value={index['@id']}>
						{common.getTrad("lingua_" + index.language.toUpperCase())}
					</option>
				)
			});
		}catch(e){
		}
	}

	componentDidUpdate() {
		const $ = window.$;
		if (common.get_localStorage("valoreLingua") !== undefined && common.get_localStorage("valoreLingua") !== null && common.get_localStorage("valoreLingua") !== '') {
			$("select#select_language option[data-iso2='" + common.get_localStorage("valoreLingua") +"']").prop("selected", true).trigger('change');
		}
	}

	render(props){
		return(
      <div className={(this.props.customClass !== undefined ? this.props.customClass : "col-xl-6 col-lg-6") +  " col-md-12 col-sm-12 col-xs-12 row-eq-height"} role="alert" id="ricerca_filtro_language">
				<div className="col-12 p-0">
					<p>{common.getTrad("Ricerca Lingue")}</p>
					{this.state.title}
          <div className="form-group">
            <select name="select_language" className="form-control select_language" id="select_language">
							<option data-iso2="mondo" value="all_lang">{common.getTrad("Tutte le lingue")}</option>
							{this.renderSelectLingue()}
            </select>
          </div>
        </div>
      </div>
     )
  }
}

export default FilterLanguages;
