import React from 'react';
import { Link } from "react-router-dom";
import {doLogout} from '../views/logout';
import { openSubMenu } from 'components/MenuHome/MenuHome';

const env = require("../../config.js").default;
const common = require('../common/common');

export class UserButton extends React.Component {
  constructor(props){
    super(props);
    this.state={};
    this.state.userInfo={};
  }
  UNSAFE_componentWillMount(){
    common.setStateUserInfo(this);
  }

  handleBottoniMenuClick(e) {
    var $ = window.$;
    if ($(e.currentTarget).parent().find(".elenco_voci").length > 0) {
      e.preventDefault();
      openSubMenu(e.currentTarget);
      return false;
    }
    menuHomeOpen(false);
  }

  render(){
    return(
      <li className="menu_drop dropdown-toggle sw-user">
		    <span id="portal-personaltools-header">
          <i className="fas fa-user" style={{float: "left"}}></i>&nbsp;
    			<span id="myUsername" className="truncateWords">&nbsp;{this.state.userInfo.fullname ? this.state.userInfo.fullname : this.state.userInfo.username}</span>
    			<sub><i className="fas fa-caret-down"></i></sub>
    		</span>
    		<ul id="portal-personaltools-menu" className="dropdown-content" role="menu" aria-labelledby="portal-personaltools-header">
    			<li role="presentation" id="myUsernameResp">
    				<Link to="/my-profile">{this.state.userInfo.fullname ? this.state.userInfo.fullname : this.state.userInfo.username}</Link>
    			</li>
    			<li role="presentation" id="personaltools-preferences">
            <Link role="menuitem" to="/my-profile" onClick={this.handleBottoniMenuClick}>{common.getTrad("Il mio profilo")}</Link>
    			</li>
    			<li role="presentation" id="personaltools-logout">
    				<a href="/#" onClick={(e) =>{doLogout();e.preventDefault();}}>{common.getTrad("Esci")}</a>
    			</li>
    		</ul>
    	</li>
    )
  }
}

export class LanguageButton extends React.Component {

  renderLingue(){
    var linguaAttiva = common.getLanguage();
    try{
      return window.cc.languages.map(function(index, key){
        return(
          <li role="presentation" className={"language-" + index + (index === linguaAttiva ? " currentLanguage" : "")} key={key}>
            <a href={common.getUrlPath()} role="menuitem" onClick={function(){common.setLanguage(index)}} title={common.getTrad("lingua_" + index.toUpperCase())}>
              <img className="bandiere_icone" src={env.STATIC +  "images/bandiere/" + index + ".svg"} alt={common.getTrad("lingua_" + index.toUpperCase())} title={common.getTrad("lingua_" + index.toUpperCase())}/> {common.getTrad("lingua_" + index.toUpperCase())}
            </a>
          </li>
        )
      });
    }catch(e){}
  }

  render(){
    return(
      <li id="li-portal-language" className="menu_drop dropdown-toggle sw-language">
		    <span id="lang_dropdown">
	        <i className="fas fa-globe-americas"></i> <sub><i className="fas fa-caret-down"></i></sub>
		    </span>
    		<ul id="portal-languageselector" className="dropdown-content" role="menu" aria-labelledby="lang_dropdown">
          {this.renderLingue()}
    		</ul>
    	</li>
    )
  }
}

export class SupportButton extends React.Component {
  render(){
    return(
      <li id="sw-support" className="menu_drop dropdown-toggle sw-support">
    		<span id="support_dropdown">
    			<i className="fas fa-comments"></i> <sub><i className="fas fa-caret-down"></i></sub>
    		</span>
    		<ul id="portal-support" className="dropdown-content" role="menu" aria-labelledby="support_dropdown">
    			<li className="dropdown-header">Contact Help Desk</li>
    			<li role="presentation" >
    				<a role="menuitem" href="mailto:support@softway.it?subject=[WebOrder:%20850100%20]%20[User:%20rmimv.softway.it]%20Request%20for%20support">
    					<i className="fas fa-envelope"></i> support@softway.it
    				</a>
    			</li>
    			<li role="presentation" >
    				<a href="/#" role="menuitem">
    					<i className="fas fa-phone"></i> 0039 0322 541820
    				</a>
    			</li>
    			<li role="presentation" >
    				<a role="menuitem" href="/#">
    					<img className="bandiere_icone" src={env.STATIC +  "images/bandiere/italy.svg"} alt="IT"/> manuale uso RMI
    				</a>
    			</li>
    			<li role="presentation" >
    				<a role="menuitem" href="/#">
    				<i className="fas fa-cog"></i> Gestisci manuali per lingua sito: it</a>
    			</li>
    		</ul>
    	</li>
    )
  }
}

export class Search extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
  }

  componentDidMount(props){
    const $ = window.$;
    $('input.search').change((e) => {
      $(e.target).find('[type="search"]');
      $('input.search').val(e.target.value);
    });
  }

  showSearch(e){
    e.preventDefault();
    const $ = window.$;
    $('#FORM_RESP').toggleClass('d-none');
  }

  handleBottoniMenuClick(e) {
    var $ = window.$;
    if ($(e.currentTarget).parent().find(".elenco_voci").length > 0) {
      e.preventDefault();
      openSubMenu(e.currentTarget);
      return false;
    }
    menuHomeOpen(false);
  }

  render(){
    return(
    <div>
      <li className="menu_drop dropdown-toggle respSearchButton" >
  			<form className="form-inline form_ricerca" role="search" action="search" onSubmit={(e) => {e.preventDefault();}}>
  				<div className="input-group ">
  					<input required="required" className="form-control search" name="q" type="search" placeholder={common.getTrad("Cerca")} aria-label="Cerca"/>
            {window.location.pathname.indexOf('/search') > -1 ?
                <button to="/search" className="btn btn-outline-light" type="submit" onClick={this.handleBottoniMenuClick}>
                  <Link to="/search" id="searchSubmit">
                  <i className="fas fa-search"></i>
                </Link>
              </button>
            :
            <Link to="/search" id="searchSubmit">
                  <button to="/search" className="btn btn-outline-light" type="submit" onClick={this.handleBottoniMenuClick}>
                <i className="fas fa-search"></i>
              </button>
            </Link>
          }
  				</div>
  			</form>
  		</li>
  		<li className="respSearchButton responsive">
        <span onClick={this.showSearch}><i className="fas fa-search"></i></span>
        <div className="dropdown-toggle respSearchButton responsive d-none animated fadeInDown faster" id="FORM_RESP">
  				<form className="form-inline form_ricerca " role="search" action="search" onSubmit={(e) => {e.preventDefault();}}>
  					<div className="input-group">
  						<input required="required" className="form-control search" name="q" type="search" placeholder={common.getTrad("Cerca")} aria-label="Cerca" />
              {window.location.pathname.indexOf('/search') > -1 ?
                <button to="/search" className="btn btn-light" type="submit">
                  <Link to="/search" id="searchSubmit" className="p-0">
                    <i className="fas fa-search"></i>
                  </Link>
                </button>
              :
                <Link to="/search" id="searchSubmit" className="p-0">
                  <button to="/search" className="btn btn-light" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </Link>
              }
  					</div>
  				</form>
  			</div>
  		</li>
  	</div>
    )
  }
}

export const menuHomeOpen = (open=true)=>{
  const $ = window.$;

  if($('.Content_nav_interno').hasClass('open')){
    $("#body").removeClass('no_scrollbar');
    $('.Content_nav_interno').removeClass('fadeInDown');
    $('.Content_nav_interno').addClass('fadeOutUp');
    // setTimeout(function(){$('.Content_nav_interno').removeClass('open');}, 300); rimosso perchè sfarfalla dopo la chiusura
    $('.Content_nav_interno').removeClass('open');
    var menuAperti = $(document).find(".elenco_voci.open");
    var menuApertiButtons = $(document).find(".bottoni_menu_link.submenuOpen");
    if(menuApertiButtons.length > 0){
      $(menuAperti).each(function(){
        $(this).height(0);
        $(this).removeClass("flipInX");
        $(this).addClass("flipOutX");
        $(this).removeClass("open");
      });
      $(menuApertiButtons).each(function(){
        $(this).removeClass("submenuOpen");
        $(".submenuPlaceHolder").height(0);
      });
      //$(".submenuPlaceHolder").hide();
    }
    $(window).scroll();
  }else{
    if(open){
      $('.Content_nav_interno').removeClass('fadeOutUp');
      $('.Content_nav_interno').addClass('open fadeInDown');
      $("#body").addClass('no_scrollbar');
      $(window).scroll();
    }
  }
}

export class MenuButton extends React.Component {
	navInternoClick(){
    menuHomeOpen();
	}
  render(){
    return(
      <li className="nav_interno" onClick={this.navInternoClick}>
        <span>
          <i id="stato_nav_interno" className="fas fa-bars"></i>
          <span> {common.getTrad("Menu")}</span>
        </span>
      </li>
    )
  }
}

export class SwitchTheme extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.state.checked = false;
  }

  componentDidMount(props){

    const $ = window.$;
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches){
      $(".content_switch_theme").click();
      common.set_localStorage('theme', 'dark');
    }else{
      if(common.get_localStorage('theme') !== 'dark')
        common.set_localStorage('theme', 'light');
    }

    $(window).on('load', () => {
      common.set_localStorage('theme', common.get_localStorage('theme'));
    });

    if(common.get_localStorage('theme') !== undefined && common.get_localStorage('theme') !== null && common.get_localStorage('theme') !== ''){
      $('html').attr('data-theme', common.get_localStorage('theme'));
      if(common.get_localStorage('theme') === 'dark')
        $(".theme_switch").attr("checked", true);
    }
  }

  handleSwitchTheme(e, checked) {
    const $ = window.$;
   if ($("#theme_switch").is(":checked")) {
     $('html').attr('data-theme', 'dark');
     common.set_localStorage('theme', 'dark');
   }else {
     $('html').attr('data-theme', 'light');
     common.set_localStorage('theme', 'light');
   }
  }

  render(){
    return(
      <li className="sw-switch-theme">
        <span>
          <div className={this.state.checked ? "switch-toggle--checked" : " switch-toggle"} onClick={(e, checked) => this.handleSwitchTheme(e, this.state.checked)}>
            <label className="mb-0 switch-toggle-track" htmlFor="theme_switch">
              <div className="switch-toggle-track-check">
                <span className="toggle_keGJ moon_1gwN"></span>
              </div>
              <div className="switch-toggle-track-x">
                <span className="toggle_keGJ sun_3CPA"></span>
              </div>
              <div className="switch-toggle-thumb"></div>
            </label>
            <input type="checkbox" aria-label="Dark mode toggle" id="theme_switch" className="switch-toggle-screenreader-only" />
          </div>
        </span>
      </li>
    )
  }
}

export class AgentiButton extends React.Component {
  constructor(props){
    super(props);
    this.state={};
    this.state.userInfo={};
  }
  UNSAFE_componentWillMount(){
    common.setStateUserInfo(this);
  }
  render(){
    return(
      <li className="sw-agente" >
        <span>
          <a href={env.SWAN + "agente?next=" + window.location.href } title={common.getTrad("Seleziona Cliente")}>
            <i className="fas fa-users"></i>
          </a>
        </span>
      </li>
    )
  }
}


export class NotificationButton extends React.Component {
    
    handle_click(e){
      e.preventDefault();
      const $ = window.$;
      $("#notificationMenu").toggleClass("open");

      //Rimozione ombra
      var css;
      if($("#notificationMenu").hasClass("open")){
        css = "0px 1px 4px 2px rgb(0 0 0 / 30%)"
      }else{
        css = 'none'
      }

      $("#notificationMenu").css({'-webkit-box-shadow' : css, '-moz-box-shadow' : css, 'box-shadow' : css});
      return false;
    }
    render(){
        return(
            <li className="sw-notificationButton" style={{"cursor": "pointer"}} onClick={this.handle_click} title={common.getTrad("Notifiche")}>
                <span>
                    <i className="fas fa-bell"></i>
                </span>
            </li>
        )
    }
}
export class PromoButton extends React.Component {
    
    handle_click(e){
      e.preventDefault();
      const $ = window.$;
      $("#promoMenuCloseButton").show()
      $("#promoMenu").toggleClass("open");

      //Rimozione ombra
      var css;
      if($("#promoMenu").hasClass("open")){
        css = "0px 1px 4px 2px rgb(0 0 0 / 30%)"
      }else{
        css = 'none'
      }

      $("#promoMenu").css({'-webkit-box-shadow' : css, '-moz-box-shadow' : css, 'box-shadow' : css});
      return false;
    }
    render(){
        return(
            <li className="sw-promoButton" style={{"cursor": "pointer"}} onClick={this.handle_click} title={common.getTrad("Promozioni attive")}>
                <span>
                    <i className="fas fa-percent"></i>
                </span>
            </li>
        )
    }
}