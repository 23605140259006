import React from 'react';
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
import moment from 'moment';
import { createBrowserHistory } from "history";
const common = require('../common/common');
const env = require("../../config.js").default;
try {
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
}

class RmiServices extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props){
    super(props);
    this.state = {};
    this.state.subscribeLink = "";
    this.state.userInfo = this.props.userInfo !== undefined ? this.props.userInfo : {};
    this.state.subscriptions = {};
  }

  redirectToPayments(codeProduct, brandProduct){
    this.setState({subscribeLink: "subscribe?codeProduct=" + codeProduct + "&brandProduct="+ brandProduct})
  }

  UNSAFE_componentWillMount(){
    document.getElementById('body').className='rmi_services';
    var userid = this.state.userInfo.userInfo.ZUSER !== undefined ? this.state.userInfo.userInfo.ZUSER : "";
    var cgrsw = this.state.userInfo.userInfo.CGRSW !== undefined ? this.state.userInfo.userInfo.CGRSW : "999";
    common.apiCall(env.BACKEND + "@sw-subscriptions?userid="+ userid +"&cgrsw=" + cgrsw +"&cling="+ common.getLanguage(true) + "&is_anonymous=" + !this.props.login, "GET", {}, false)
    .then(response=>{
      if(response.error === false){
        this.setState({subscriptions: response})
      }
    });
  }

  renderStatusPacchetto(object){
    var subscription = Object.keys(object)[0];
    var status = 'info';
    var stato_servizio = "";
    if(object[subscription].data_scadenza !== undefined && object[subscription].data_scadenza !== null){
      if(moment(object[subscription].data_scadenza) < new Date()){
        stato_servizio = common.getTrad("Servizio scaduto il {{data_scadenza}}", {data_scadenza: moment(object[subscription].data_scadenza).format('L')})
        status = 'danger';
      }else{
        stato_servizio = common.getTrad("Servizio attivo fino al {{data_scadenza}}", {data_scadenza: moment(object[subscription].data_scadenza).format('L')})
      }
    }

    if(object[subscription].acquistato && this.props.login && (this.state.userInfo !== undefined && this.state.userInfo.userInfo.RMI && this.state.userInfo.userInfo.FCONS === 'S' && this.state.userInfo.userInfo.FATTI === 'S')){
      return (
        <div className={("sw-alert alert alert-" + status + " col")} role="alert">
          {stato_servizio}
        </div>
      );
    }
  }

  renderPacchettiMarca(object, brand){
    return Object.keys(object).map((index, key) => {
      var disattivo = moment(object[index].data_scadenza) < new Date() || object[index].data_scadenza === null;
      return (
        <div key={key} className="row text-center lista_acquisto">
          <div className="col-4 p-1">
            {object[index].name}
          </div>
          <div className="col-4 p-1">
            {object[index].description} *
          </div>
          {disattivo && this.props.login && (this.state.userInfo !== undefined && this.state.userInfo.userInfo.RMI && this.state.userInfo.userInfo.FCONS === 'S' && this.state.userInfo.userInfo.FATTI === 'S') ?
            <div className="col-4 p-1 bottone_default" onClick={() => this.redirectToPayments(index, brand)}>
              {common.getTrad("Acquista Ora")}
            </div>
          :
            <div className="col-4 p-1">&nbsp;</div>
          }
        </div>
      )
    });
  }

  renderPacchettiMarche(){
    if (window.cc.registrationFlag){
      var subscriptionObject = Object.keys(this.state.subscriptions);
      return subscriptionObject.map((index, key) => {
        var subscriptions = (this.state.subscriptions[index] !== undefined ? this.state.subscriptions[index] : this.state.subscriptions);
        var numMarca = common.object_value_in_array(window.cc.marche_portale, 'ZFORN', index);
        if(index !== 'version' && index !== 'error'){

          var description = '';
          var icon = index.toLowerCase();
          if(index === '$$' && window.cc.marche_portale.length > 1){
            icon = 'sw';
            description = common.getTrad("Tutte le marche del portale");
          }else if (index === '$$' && window.cc.marche_portale.length === 1){
            description = window.cc.marche_portale[0].DFORN;
            icon = window.cc.marche_portale[0].ZFORN.toLowerCase();
          }else{
            description = window.cc.marche_portale[numMarca].DFORN;
          }
          return (
            <div className="content_acquisto" key={key}>
              <div className="row py-4">
                <div className="col-8">
                  <h2 className="titolo_marca">{description}</h2>
                </div>
                <div className="col-4" style={{background: 'none'}}>
                  <img className="float-right" style={{maxWidth: "80%"}} src={env.SWAN + "static/images/loghi/" + icon + ".png"}
                    title={description}
                    alt={description}
                  />
                </div>
              </div>
              <div className="container mb-4" style={{width: '90%'}}>
                {this.renderStatusPacchetto(subscriptions)}
              </div>
              <div className="container mb-4" style={{width: '90%'}}>
                <br/>
                {this.renderPacchettiMarca(subscriptions, index)}
              </div>
              <span className="container d-block mb-3" style={{width: '90%'}}>
                <small>{common.getTrad("* Più imposta di legge se applicabile al cliente.")}</small>
              </span>
            </div>
          )
        }else{
          return "";
        }

      });
    }
  }

  render(props){

    if(this.state.subscribeLink !== undefined && this.state.subscribeLink !== '')
      return(<Navigate push={true} to={"/" + this.state.subscribeLink} />);

    return(
      <div className="app-content content registrazione_io" id="subscribeContent">
        <div className="content-wrapper">
          <div className="content-body">
            <section id="icon-tabs">
              <div className="row">
                <div className="content_acquisto">
                  <h2 className="text-center mt-3 mb-3">{common.getTrad("Acquista l'abbonamento alla documentazione")}</h2>
                  <div className="text-center mb-5">{common.getTrad("Per poter accedere ai contenuti del portale, è necessario acquistare uno dei piani d'abbonamento proposti")}</div>
                </div>
                <div className="content_acquisto_button">
                  <div className="btn-group btn-group-sm mr-3" role="group" aria-label={common.getTrad("Torna indietro")}>
                    <button type="button" className="btn btn-light" onClick={() => { const history = createBrowserHistory(); common.goBack(history); }}>
                      <i className="fas fa-chevron-left"></i> {common.getTrad("Torna indietro")}
                    </button>
                  </div>
                </div>
                {this.state.subscriptions !== undefined && this.state.subscriptions !== null && this.state.subscriptions !== '' ?
                  this.renderPacchettiMarche() !== '' ? this.renderPacchettiMarche().sort() : <></>
                :
                  <div className="col align-center sw-alert alert alert-danger">
                    {common.getTrad("Gli abbonamenti non sono stati caricati correttamente.")}
                  </div>
                }
              </div>
            </section>
          </div>
        </div>
      </div>
    )
  }
}

export default RmiServices;
