import React from 'react';
import moment from 'moment';
import bootbox from 'bootbox';
const env = require("../../config.js").default;
const common = require('../common/common');
const $ = window.$;

try {
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
}


export class PrivacyBox extends React.Component {
  constructor(props){
    super(props);
    this.state={
      userInfo: {},
      moduli:{},
    };
    this.state.marche_attive = common.getAppEnabledVM();
    this.pdfDownloaded = false;
    common.getUserInfo().then(userInfo =>{
      this.setState({"userInfo": userInfo});
      if(userInfo.need_privacy_update){
        common.apiCall(env.BACKEND + "/@get-modelli-privacy", "GET", {}, true).then(async (modelliPrivacy) => {
          if(!modelliPrivacy.error)
            this.setState({moduli: modelliPrivacy});
        });
      }
    });
    // TODO: get_privacy_da_compilare
  }

  modalShow(backdrop, keyboard){
    $('#privacy_box').modal("show");
    $('#privacy_box').data("bs.modal")._config.backdrop = backdrop;
    $('#privacy_box').data("bs.modal")._config.keyboard = keyboard;
    $('#privacy_box').modal("show");
  }

  modalHide(){
    $('#privacy_box').modal('hide');
  }

  modalDispose(){
    $('#privacy_box').modal('dispose');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  componentDidUpdate(){
    if(this.state.userInfo !== undefined && this.state.userInfo.need_privacy_update && this.state.moduli.length > 0){
      var modulo = this.state.moduli[0];
      var options = {backdrop: (modulo.FRICH === "S" ? "static" : "true"), keyboard: modulo.FRICH !== "S"};
      $(".actualDate").html(moment().format("L"));
      this.modalShow((modulo.FRICH === "S" ? "static" : "true"), modulo.FRICH !== "S");
    }
  }

  savePrivacy(e){
    e.preventDefault();
    var moduli = this.state.moduli;
    var form = e.target;
    var modulo = moduli.shift();
    $(form).find("button[type='submit']").prop("disabled", true);
    $(form).find("button[type='submit']>.fa-spin").show();
    // TODO: salvataggio flag
    var saveData = {
      "ZFORN": modulo.ZFORN,
      "CMODP": modulo.CMODP,
      "CREPO": modulo.CREPO,
      "FLAGS": [],
    };
    
    for(var k in modulo.FLAGS){
      var datiFlag = modulo.FLAGS[k];
      var flag = {
        "CFLAG": datiFlag.CFLAG,
      };
      
      if(datiFlag.TFLAG === "C"){
        flag.FPRIV = ($("#check_" + datiFlag.CMODP + "_" + datiFlag.CFLAG).is(":checked") ? "S" : "N");
      }
      this.pdfDownloaded = false;
      saveData.FLAGS.push(flag);
    }

    common.apiCall(env.BACKEND + "/@save-privacy", "POST", saveData, true).then((data) =>{
      if(true){
        // TODO: da fare solo se il salvataggio va bene
        if(moduli.length === 0){
          common.remove_localStorage("userInfo");
          window.location.reload();
        }
        this.modalDispose();
        $(form).find("input").prop("checked", false);
        this.setState({moduli: moduli});
        $(form).find("button[type='submit']>.fa-spin").hide();
        $(".privacy_policy_container").scrollTop(0);
        $("#privacy_box .modal-body").scrollTop(0);
        $(form).find("button[type='submit']").prop("disabled", false);
      }
    });
  }

  printFlagModulo(modulo){
    if(modulo.FLAGS === undefined)
      return "";

    return modulo.FLAGS.map((flag)=>{
      return (
        <div className="privacy_flag_container" key={flag.ID}>
          {
            (flag.DTEXT !== null && flag.DTEXT !== "") ?
              <div className="privacy_policy_container"  dangerouslySetInnerHTML={{__html: flag.DTEXT}}></div>
            :
              <></>
          }
          {
            (flag.TFLAG === "C") ?
              <>
                <input type="checkbox" id={"check_" + flag.CMODP + "_" + flag.CFLAG} required={flag.FRICH === 'S'} style={this.handleStyle(modulo)} />
                &nbsp;

              </>
            :
              <></>
          }
          <label htmlFor={"check_" + flag.CMODP + "_" + flag.CFLAG} style={this.handleStyle(modulo)} >
            {flag.DFLAG}&nbsp;
            {
              flag.FRICH === 'S' ?
                <sup className="danger"><i className="fas fa-asterisk"></i></sup>
              :
                <></>
            }
          </label>
        </div>
      );
    })
  }
  handleStyle(modulo) {
    if (modulo.TDISP === "PDF") {
      if (!this.pdfDownloaded) {
        return { pointerEvents: "none" };
      } else {
        return { pointerEvents: "auto" };
      }
    }
  }
  printPrivacy(modulo){
    if (modulo.TDISP === 'HTML' || modulo.TDISP == '' || modulo.TDISP == null)
      return <div dangerouslySetInnerHTML={{ __html: modulo.DTEXT }} />;
    var DFORN = '';
    for (var i=0;i<this.state.marche_attive.length;i++){
      if(this.state.marche_attive[i].ZFORN == modulo.ZFORN)
        DFORN = this.state.marche_attive[i].DFORN;
    }
    //Get PDF from Django
    return (<a onClick={(e) => this.enableFlag(e)} id={modulo.CMODP + "_" + modulo.FLAGS[0].CFLAG} href={env.SWAN + "get_modelli_privacy/?cling=" + modulo.CLING + "&zforn=" + modulo.ZFORN + "&cmodp=" + modulo.CMODP + "&pdfOnly=1&dmodp=" + modulo.DMODP}>{common.getTrad("Scarica condizioni di vendita") + " " + DFORN}</a>);  
  }

  //Enable flag after PDF download
  enableFlag (e) {
    this.pdfDownloaded = true
    $("#check_" + e.target.id).css("pointer-events","auto");
    $("label[for=check_" + e.target.id + "]").css("pointer-events","auto");
  }

  render(){
    var modulo = [];
    if(this.state.userInfo !== undefined && this.state.userInfo.need_privacy_update && this.state.moduli.length > 0){
      modulo = this.state.moduli[0];
    }
    if(modulo.length === 0)
      return ""
    return(
      <div className="modal fade bd-example-modal-xl" id="privacy_box" tabIndex="-1" role="dialog" aria-labelledby="privacy_box" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <form onSubmit={(e) => this.savePrivacy(e)}>
            <div className="modal-content" style={{"maxHeight": "90vh"}}>
              <div className="modal-header">
                <h5 className="modal-title"><i className="far fa-file-alt"></i> {common.getTrad("Per procedere all'utilizzo del portale è necessario fornire i seguenti consensi")}</h5>
                {
                  modulo.FRICH !== "S" ?
                    <button type="button" className="close closeModal" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  :
                    <></>
                }
              </div>
              <div className="modal-body" style={{"overflowY": "auto"}}>
                <h4 id="titoloModulo" dangerouslySetInnerHTML={{__html: modulo.DMODP}}></h4>
                <div id="testoModulo" className="privacy_policy_container"> 
                  { this.printPrivacy(modulo) }
                </div>
                <div id="flag_cont">
                { this.printFlagModulo(modulo) }
                </div>
              </div>
              <div className="modal-footer">
                {
                  modulo.FRICH !== "S" ?
                    <button type="button" type="cancel" className="btn btn-light closeModal" data-dismiss="modal">{common.getTrad("Chiudi")}</button>
                  :
                    <></>
                }
                <button type="submit" className="btn bottone_default">
                  <i className="fas fa-spinner fa-spin" style={{display: "none"}}></i>&nbsp;&nbsp;
                  {common.getTrad("Conferma")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default PrivacyBox
