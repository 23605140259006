import React from 'react';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';

const common = require('../common/common');
const env = require("../../config.js").default;
try {
  require ('select2/dist/js/i18n/'+ common.getLanguage(false) +'.js');
} catch (err) {
  require ('select2/dist/js/i18n/en.js');
}

class AccessoUtente extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.lingue = {};
    this.state.type = this.props.type;
  }

  componentDidMount(props){
    this.setState({lingue: this.props.lingue});
    const $ = window.$;
    $('#DCAPOACL').focus();
  }

  componentDidUpdate(){

    const $ = window.$;
    function format(state) {
      if (!state.id) return state.text; // optgroup
      return '<img class="bandiere_icone" src="'+env.STATIC+'images/bandiere/'+state.id+'.svg" />' +
        '<span class="flag-text">' + state.text + '</span>';
    }
    $(this.state.type === "io" ? "#CLIN1ACL" : "#CLIN1WUS").select2({
      templateResult: format,
      templateSelection: format,
      escapeMarkup: function(m) { return m; },
      language: common.getLanguage(),
      placeholder: common.getTrad("Seleziona una Lingua")
    });

    $('#conferma_email, #conferma_password').bind("cut copy paste", function(e) {
      e.preventDefault();
    });
    $(document).on('keydown', '#DCAPOACL', function(e) {
      if (e.keyCode === 32) return false;
    });
    $(document).on('keydown', '#DCAPOWUS', function(e) {
      if (e.keyCode === 32) return false;
    });
    $(document).on('keydown', '#cpwd0', function(e) {
      if (e.keyCode === 32) return false;
    });
  }

  renderSelectLingue(){
     try{
      return this.props.lingue.map(function(index, key){
        return(
          <option key={key} value={index}>
            {common.getTrad("lingua_" + index.toUpperCase())}
          </option>

        )
      });
    }catch(e){
    }
  }

  render(props){
    return(
      <>
        <h6><i className="step-icon fas fa-lock" title={common.getTrad("Accesso utente")}></i></h6>
        <fieldset className="mt-2 col">
          <div className="row">
            <div className="col-md-12">
              {this.props.type === 'io' ?
                <>
                  <p>{common.getTrad("È necessario specificare l'indirizzo email del legale rappresentante dell'azienda. Questo verrà utilizzato esclusivamente per effettuare l'accesso al portale e per ricevere comunicazioni da parte del costruttore veicoli.")}</p>
                </>
              :
                <>
                  <p>{common.getTrad("Lindirizzo email inserito sarà quello utilizzato per effettuare accesso al portale.")}</p>
                </>
            }
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor={this.state.type === "io" ? "DCAPOACL" : "DCAPOWUS"}>{common.getTrad("Email")}: <span className="danger">*</span></label>
                <input maxLength="150" autoComplete="email" type="email" id={this.state.type === "io" ? "DCAPOACL" : "DCAPOWUS"} name={this.state.type === "io" ? "DCAPOACL" : "DCAPOWUS"} className="form-control required" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="conferma_email">{common.getTrad("Conferma Email")}: <span className="danger">*</span></label>
                <input type="email" autoComplete="off" name="conferma_email" id="conferma_email" className="form-control required" maxLength="150" />
              </div>
            </div>
            {this.props.type === 'employee' ?
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="form-group">
                  <label htmlFor="RUSERWUS">{common.getTrad("Nome e Cognome del dipendente")}: <span className="danger">*</span></label>
                  <input type="text" autoComplete="off" name="RUSERWUS" id="RUSERWUS" className="form-control required" maxLength="150" />
                </div>
              </div>
            :
              <div className="col-lg-4 col-md-6 col-sm-12"></div>
            }

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="cpwd0">{common.getTrad("Password: (min. 8 caratteri)")} <span className="danger">*</span></label>
                <input type="password" autoComplete="new-password"  minLength="8" className="form-control required" id="cpwd0" name="cpwd0" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor="conferma_password">{common.getTrad("Conferma Password")}: <span className="danger">*</span></label>
                <input type="password" autoComplete="off" name="conferma_password" id="conferma_password" className="form-control required"  />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <label htmlFor={this.state.type === "io" ? "CLIN1ACL" : "CLIN1WUS"}>{common.getTrad("Lingua preferita")}: <span className="danger">*</span></label>
                <select autocomlete="on" className="form-control required" id={this.state.type === "io" ? "CLIN1ACL" : "CLIN1WUS"} name={this.state.type === "io" ? "CLIN1ACL" : "CLIN1WUS"} defaultValue={common.getLanguage()} >
                  {this.renderSelectLingue()}
                </select>
              </div>
            </div>
          </div>
          {this.state.type === 'employee' ?
            <div className="col">
              <button type="submit" className="btn btn-primary float-right mb-3">{common.getTrad("Registrati")}</button>
            </div>
          :
            <></>
          }
        </fieldset>
      </>
    )
  }
}

export default AccessoUtente;
