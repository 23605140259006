const common = require("./common.jsx");

export function get_marca_default(content_type = "", zforn_only=false){
    /*
     * Calcolo la marca di default del portale nel caso serva su contenuti vecchi senza marca indicata.
     * Ritorno sempre o marcaPortale o la prima delle marche attive
     * Previsto parametro content_type in caso serva per distinguere il calcolo tra ticket e contenuti, ma per ora non prevedo logica.
     */
    if (!window.cc.marche_attive || window.cc.marche_attive === undefined || window.cc.marche_attive === null || window.cc.marche_attive.length <= 0)
        return undefined;
    
    let marcaDaTestare = null;
    if (window.cc.marcaPortale !== undefined && window.cc.marcaPortale !== null && window.cc.marcaPortale !== "")
        marcaDaTestare = window.cc.marcaPortale;

    if (common.object_value_in_array(window.cc.marche_attive, "ZFORN", marcaDaTestare) > -1)
        return marcaDaTestare;
    
    if (zforn_only && "ZFORN" in window.cc.marche_attive[0])
        return window.cc.marche_attive[0]["ZFORN"];
    return window.cc.marche_attive[0];
}

export function is_multibrand_portal(){
    if (window.cc.marche_attive === undefined || window.cc.marche_attive.length <= 1)
        return false;
    else 
        return true;
}

export function from_subject_to_ZFORN(subject){
    /*
     * Trasformo il subject salvato in Plone contenente il dato della marca in ZFORN
     * es. "sw-brand-EA" diventa "EA"
     */
    return subject.replace("sw-brand-", "");
}

export function from_ZFORN_to_subject(zforn){
    /*
     * Trasformo il subject salvato in Plone contenente il dato della marca in ZFORN
     * es. "sw-brand-EA" diventa "EA"
     */
    return "sw-brand-" + zforn;
}