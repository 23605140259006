import React from 'react';
import { Link, Navigate } from "react-router-dom";
import GestioneContenutiSidebar from "../Sidebar/GestioneContenutiSidebar";
import FilterVM from '../FilterVM/FilterVM';
import FilterModels from '../FilterModels/FilterModels';
import FilterChassis from '../FilterChassis/FilterChassis';
import FilterLanguages from '../FilterLanguages/FilterLanguages';
import FilterShowInactive from '../FilterShowInactive/FilterShowInactive';
import GestioneContenutiTable from "../Tabelle/GestioneContenutiTable";
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js';
import 'datatables.net-select/js/dataTables.select.min.js';
import '../../css/base.css';
import '../../css/page-body.css';

const common = require('../common/common');
const env = require("../../config.js").default;

class GestioneContenuti extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
		this.state.render1 = false;
		this.state.pathname = this.props.pathname;
		this.state.parentPageContent = {};
		this.state.pageContent = {};
		this.state.pageContent.items = [];
		this.state.pathName = {
			url: '',
			title: '',
		}

		this.state.pagina = {
			pagina_attuale: common.getParam("page") !== null ? common.getParam("page") : 1,
			tipo_ordinamento: 'modified', //Capire tipo ordinamento iniziale
			verso_ordinamento: -1, // Idem come sopra
			testo_cercato: '',
			filtro_modello: '',
			filtro_marca: '',
			stati_ticket: [],
			tipologie_ticket: [],
			tipo_lingua: '',
			tipo_ricerca: { 
				all_lang: true,
				url_pagina: this.state.pathname,
			},
			show_inactive: '', // 0: Parametro per nascondere i contenuti scaduti, 1 tutti
		};
		this._isMounted = false;
		this.state.userInfo = {};
    	this.state.userInfo.roles = [];
		this.state.marche_attive = [];
	}

	UNSAFE_componentWillMount(){
		document.getElementById('body').className='content_page gestione_contenuti home_page';
		common.getUserInfo().then(userInfo =>{
      		this.setState({userInfo: userInfo});
		});
		var marche_attive = common.getAppEnabledVM();
    	this.setState({marche_attive: marche_attive})
	}

	componentDidMount = () => {
		document.title = document.title = window.cc.companyInfo.service + " Service";
		this._isMounted = true;
		var data = {"expand.navigation.depth": 3};
		var url = env.BACKEND + common.getLanguage() + '/@navigation';
		common.apiCall(url, "GET", data, true)
		.then(menuAreaButtons=>{
			if(menuAreaButtons.error === false){
				this._isMounted && this.setState({menuAreaButtons, render1: true});
			}
		});

		common.apiCall( common.getBackendUrl(env.BACKEND + this.state.pathname.substring(1) + "?expand=translations"), "GET", {}, true, true)
		.then(traduzioniContent => {
			if(traduzioniContent.error === false){
				traduzioniContent.description = JSON.parse(traduzioniContent.description)
				this.setState({parentPageContent: traduzioniContent});
				window.translationsParent = JSON.stringify(traduzioniContent['@components'].translations);
			}
		});
		if(this.state.pathname !== undefined || (window.performance && performance.navigation.type === 1) || (common.get_localStorage("valoreModello") === undefined || common.get_localStorage("valoreModello") === null || common.get_localStorage("valoreModello") === '')){
			this.firstTimeUrlLoad(env.BACKEND + this.state.pathname.substring(1), env.BACKEND + this.state.pathname.substring(1));
		}
	}

	onClickPagingConsole(tipofunzione, parametri){
		var paging = this.generaUrlConsole(tipofunzione, parametri);
		if(paging !== false){
			this.pagingGetDataConsole(paging, this.state.pathname, false);
		}
	}

	firstTimeUrlLoad(pageContent, path_url, clean_pagina=false){
		const $ = window.$;
		var new_url_path = '';
		var pagina_precedente = clean_pagina === true ? 1 : common.getParam('page');

		window.history.replaceState({},"", common.getFrontendUrl(path_url));
		var actual_path = window.location.href.substring(window.location.href.length, env.FRONTEND.length);
		if($('.select_language option:selected').data('iso2') !== "mondo")
			var edit_url = env.BACKEND + $('.select_language option:selected').data('iso2') + actual_path.substring(2);
		var parametri = {
			all_lang: ($('.select_language').val() !== "all_lang" ? false : true),
			url_pagina: ($('.select_language').val() !== "all_lang" ? edit_url : "/"+actual_path),
			pagina_attuale: pagina_precedente !== null ? pagina_precedente : 1,
		}
		new_url_path = this.generaUrlConsole("tipo_ricerca", parametri);

		var translations = actual_path.substring(2);
		let data = { "path.depth": 1, "expand": "translations,workflow,swowner" };
		common.apiCall(common.getBackendUrl(env.BACKEND + common.getLanguage() + translations), "GET", data, true, true)
		.then(translationsContent=>{
		if(translationsContent.error === false){
			this.setState({
				pageTitle: translationsContent.title,
				parentPageContent: translationsContent,
			});
		}
		});

		this.pagingGetDataConsole(new_url_path, path_url)
		if(pagina_precedente !== null)
			window.history.replaceState({},"",common.updateURLParameter("page", pagina_precedente));
	}

	pagingGetDataConsole(paging, path_url){
		this.setState({pathname: path_url});
		const $ = window.$;
		$('#content_page').css('overflow-y', 'hidden');
		$('.dropdown-toggle').prop('disabled', true);
		$("#loadingDivContenuti").show();
		$('#content_page').css({
			'height': 'calc(100vh - var(--header-height) - var(--footer-height))',
			'overflow-y': 'hidden'
		});
		$("html, body").animate({ scrollTop:0 }, 500);
 		common.apiCall(common.getBackendUrl(paging), "GET", {}, true, true)
		.then(pagingContent => {
			$("th.select-checkbox, tr").removeClass("selected_row_table");
			if(pagingContent.error === false){
				$("#loadingDivContenuti").hide();
				$('#content_page').css('overflow-y', 'auto');
				var pageContent = this.state.pageContent ? this.state.pageContent : pagingContent;
				pageContent.batching = pagingContent.batching;
				pageContent.items = pagingContent.items;
				pageContent.items_total = pagingContent.items_total;
				this.setState({pageContent: pageContent});
			}else{
				var error = pagingContent.error.message;
				if(error.search("NotFound") > -1 || error.search("TypeError")  > -1 || error.search("not found")  > -1 || error.search("Not Found")  > -1 || error.search("Unauthorized") > -1){
					window.location = common.getFrontendUrl(env.FRONTEND);
				}

				$("#loadingDivContenuti").hide();
				$('#content_page').css('overflow-y', 'auto');
			}
		});
	}

	generaUrlConsole(tipofunzione, parametri){
		//Parametri:
		var statoPagina = this.state.pagina;
		var statoPaginaNext = JSON.parse(JSON.stringify(statoPagina));
		switch(tipofunzione) {
			case "paginazione_custom":
				if(parametri.calcolaPagina !== undefined && parametri.calcolaPagina !== null && parseInt(parametri.calcolaPagina)){
					statoPaginaNext.pagina_attuale = parseInt(statoPagina.pagina_attuale) + parseInt(parametri.calcolaPagina);
				}
				else if(parametri.vaiPagina !== undefined && parametri.vaiPagina !== null){
					if(parametri.vaiPagina === "end"){
						statoPaginaNext.pagina_attuale = parseInt(this.state.pageContent.items_total / env.TABLE_PAGE_LENGTH);
						if((this.state.pageContent.items_total % env.TABLE_PAGE_LENGTH) > 0){
							statoPaginaNext.pagina_attuale ++;
						}
					}
					else if(parseInt(parametri.vaiPagina))
						statoPaginaNext.pagina_attuale = parseInt(parametri.vaiPagina);
				}
        		window.history.replaceState({},"",common.updateURLParameter("page", statoPaginaNext.pagina_attuale));
			break;
			case "ordinamento":
				statoPaginaNext.tipo_ordinamento = parametri.tipoOrdinam;
				statoPaginaNext.verso_ordinamento = parametri.versoOrdinam; // 1 -1
			break;
			case "filtro_marca":
				statoPaginaNext.filtro_marca = parametri.filtro_marca;
				statoPaginaNext.pagina_attuale = common.getParam("page") !== null ? common.getParam("page") : 1;
				statoPaginaNext.filtro_modello = "";
			break;
			case "filtro_modello":
				statoPaginaNext.filtro_modello = parametri.filtro_modello;
				statoPaginaNext.pagina_attuale = common.getParam("page") !== null ? common.getParam("page") : 1;
			break;
			case "cerca":
				statoPaginaNext.testo_cercato = parametri.testo;
				statoPaginaNext.pagina_attuale = common.getParam("page") !== null ? common.getParam("page") : 1;
			break;
			case "tipo_ricerca":
				statoPaginaNext.tipo_ricerca = {
					all_lang: parametri.all_lang,
					url_pagina: parametri.url_pagina,
				}
        		if(parametri.pagina_attuale !== undefined && parametri.pagina_attuale !== null)
			    	statoPaginaNext.pagina_attuale = parametri.pagina_attuale;
			  	this.setState({pagina: statoPaginaNext});
			break;
			case "show_inactive":
				if (parametri.checked === true) {
					statoPaginaNext.show_inactive = 1;
				} else {
					statoPaginaNext.show_inactive = 0;
				}
				statoPaginaNext.pagina_attuale = common.getParam("page") !== null ? common.getParam("page") : 1;
			break;
			default:
				return "";
		}

		var newUrl = '';
		if (common.get_localStorage("valoreLingua") !== undefined && common.get_localStorage("valoreLingua") !== null && common.get_localStorage("valoreLingua") !== '' && common.get_localStorage("valoreLingua") !== 'mondo') {
			newUrl = statoPaginaNext.tipo_ricerca.url_pagina.replace('/'+common.getLanguage()+'/', '/'+common.get_localStorage("valoreLingua")+'/') + "/@search";
		} else {
			newUrl = statoPaginaNext.tipo_ricerca.url_pagina + "/@sw-search-all-lang";
		}

		var pageStart = env.TABLE_PAGE_LENGTH  * (statoPaginaNext.pagina_attuale - 1);
		if(pageStart === 1)
			pageStart --;

		newUrl = newUrl + "?fullobjects=1";
		newUrl = newUrl + "&expand=workflow,translations";
		newUrl = newUrl + "&portal_type=swcontent&portal_type=News Item";
		newUrl = newUrl + "&show_inactive=1";
		newUrl = newUrl + "&b_size=" + env.TABLE_PAGE_LENGTH + "&b_start=" + pageStart;
		newUrl = newUrl + "&sort_on=" + statoPaginaNext.tipo_ordinamento;
		newUrl = newUrl + "&sort_order=";
		if (statoPaginaNext.verso_ordinamento === -1) {
			newUrl = newUrl + "reverse";
		} else {
			newUrl = newUrl + "ascending";
		}

		if (statoPaginaNext.show_inactive === 0) {
			newUrl = newUrl + "&expires=1";
		} else {
			if (common.get_localStorage("show_inactive") !== '' && common.get_localStorage("show_inactive") !== undefined && common.get_localStorage("show_inactive") !== null && common.get_localStorage("show_inactive") === 'false') {
				newUrl = newUrl + "&expires=1";
			}
		}

		if(statoPaginaNext.filtro_marca && !statoPaginaNext.filtro_modello){
      		if(statoPaginaNext.filtro_marca !== 'tutti_modelli'){
        		newUrl = newUrl + "&Subject:list=sw-brand-" + statoPaginaNext.filtro_marca + "&Subject:list=" + statoPaginaNext.filtro_marca + "_tutti_modelli&Subject:list=tutti_modelli";
      		}
    	}else{
      		if(common.get_localStorage("valoreModello") === '' && common.get_localStorage("valoreMarca") !== undefined && common.get_localStorage("valoreMarca") !== null && common.get_localStorage("valoreMarca") !== ''){
        		newUrl = newUrl + "&Subject:list=sw-brand-" + common.get_localStorage("valoreMarca") + "&Subject:list=" + common.get_localStorage("valoreMarca") + "_tutti_modelli&Subject:list=tutti_modelli";
      		}
    	}

		if(statoPaginaNext.filtro_modello){
			newUrl = newUrl + "&Subject:list=" + statoPaginaNext.filtro_modello;
				if(newUrl.indexOf("&Subject:list=tutti_modelli") <= -1)
					newUrl = newUrl + "&Subject:list=tutti_modelli";
		}else{
			if(common.get_localStorage("valoreModello") !== undefined && common.get_localStorage("valoreModello") !== null && common.get_localStorage("valoreModello") !== ''){
				newUrl = newUrl + "&Subject:list=" + common.get_localStorage("valoreModello");
				if(newUrl.indexOf("&Subject:list=tutti_modelli") <= -1)
					newUrl = newUrl + "&Subject:list=tutti_modelli";
			}
		}

		if(common.get_localStorage("valoreModello") !== undefined  && common.get_localStorage("valoreModello") !== '' && common.get_localStorage("valoreMarca") !== undefined && common.get_localStorage("valoreMarca") !== ''){
			if(newUrl.indexOf("&Subject:list=" + common.get_localStorage("valoreMarca") + "_tutti_modelli") <= -1)
				newUrl = newUrl + "&Subject:list=" + common.get_localStorage("valoreMarca") + "_tutti_modelli";
		}

		if(common.get_localStorage("valoreModello") !== undefined  && common.get_localStorage("valoreModello") !== '' && this.state.marche_attive.length === 1){
			if(newUrl.indexOf("&Subject:list=" + this.state.marche_attive[0].ZFORN + "_tutti_modelli") <= -1)
				newUrl = newUrl + "&Subject:list=" + this.state.marche_attive[0].ZFORN + "_tutti_modelli";
		}

		if(statoPaginaNext.testo_cercato){
			if (!statoPaginaNext.testo_cercato.replace(/\s/g, '').length) {
				newUrl = newUrl + "";
			}else{
				newUrl = newUrl + "&SearchableText=*" + statoPaginaNext.testo_cercato + "*";
			}
		}

		if(statoPaginaNext.stati_ticket){
			for(var i=0; i<statoPaginaNext.stati_ticket.length; i++){
				newUrl = newUrl + "&review_state=" + statoPaginaNext.stati_ticket[i] + "&review_state=" + statoPaginaNext.stati_ticket[i] + "_visto";
			}
		}

		this.setState({pagina: statoPaginaNext});
		return newUrl;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleSidebarElementClick(pageContent, path_url){
		this.firstTimeUrlLoad(pageContent, path_url, true)
	}

	render(props){
    	if(this.state.userInfo.roles.length > 0 && this.state.userInfo.roles !== undefined && this.state.userInfo.roles !== null && Object.keys(this.state.parentPageContent).length > 0){
			var pageContent = this.state.parentPageContent;
			var redirectLink = common.calcTranslationRedirect(this.state.userInfo, pageContent);
			if(redirectLink !== false)
				return (<Navigate from={this.state.pathname} to={"/" + redirectLink} />);
    	}
		if (Object.keys(this.state.parentPageContent).length > 0 && this.state.parentPageContent["@components"]?.translations !== undefined)
			var translations = this.state.parentPageContent["@components"].translations; //JSON.parse(window.translationsParent);
 		return (
			<div id="portal-gestione_contenuti" className="row">
      		<GestioneContenutiSidebar
					pathname={common.getUrlPath(this.state.pathname)}
					menuAreaButtons={this.state.menuAreaButtons}
					render1={this.state.render1}
					onClick={(pageContent, path_url) => this.handleSidebarElementClick(pageContent, path_url)}
				></GestioneContenutiSidebar>
				<div id="notifica_messaggio"></div>
				<div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8 linea_sfumata p-0" id="content_page">
					<div style={{display: "none"}} id="loadingDivContenuti">
						<div className="content_testo tab-content">
							<div id="content-core">	
								<div className="row background_aggiornamento">
									<div className="col-xl-12 text-center content_error">
										<div>
											<h1>
												<i className="fas fa-spinner fa-spin"></i>
											</h1>
											<div className="w-100"></div>
											<h3 id="testoCaricamento">{common.getTrad("Caricamento in corso...")}</h3>
											<p id="messageCaricamento"></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row col">
						<div className="content_titolo">
							<div className="content_titolo_int">
								<span className="titolo_sezione">
								  {this.state.pageTitle}
								</span>
							</div>
						</div>
						{this.state.pathname !== undefined ?
							<div id="filtri_files" className="col-12">
								<div className="row content_filtro">
									{this.state.marche_attive !== undefined && this.state.marche_attive.length > 1 ?
										<FilterVM marche_attive={this.state.marche_attive} customClass="col-xl-3 col-lg-6" onClick={(tipoFunzione, params) => this.onClickPagingConsole(tipoFunzione, params)}></FilterVM>
									:
										<></>
									}
									<FilterChassis marcaDefault={this.state.pagina.filtro_marca} marche_attive={this.state.marche_attive} customClass={this.state.marche_attive !== undefined && this.state.marche_attive.length > 1 ? "col-xl-3 col-lg-6" : "col-xl-4 col-lg-4"}></FilterChassis>
									<FilterModels marcaDefault={this.state.pagina.filtro_marca} marche_attive={this.state.marche_attive} customClass={this.state.marche_attive !== undefined && this.state.marche_attive.length > 1 ? "col-xl-3 col-lg-6" : "col-xl-4 col-lg-4"} onClick={(tipoFunzione, params) => this.onClickPagingConsole(tipoFunzione, params)}></FilterModels>
									<FilterLanguages 
										translations={translations} 
										pathname={this.state.pathname} 
										pathnameBackend={this.state.parentPageContent["@id"]}
										customClass={this.state.marche_attive !== undefined && this.state.marche_attive.length > 1 ? "col-xl-3 col-lg-6" : "col-xl-4 col-lg-4"} 
										onClick={(tipoFunzione, params) => this.onClickPagingConsole(tipoFunzione, params)}
									></FilterLanguages>
									<FilterShowInactive
										onClick={(tipoFunzione, params) => this.onClickPagingConsole(tipoFunzione, params)}
									></FilterShowInactive>
								</div>
							</div>
						:
							""
						}
					</div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
						<div className="col">
              <div className="tab pane active">
								<div className="row">
                  <div className="col-md-12 p-0">
                    <GestioneContenutiTable 
						pathname={this.state.pathname} 
						menuAreaButtons={this.state.menuAreaButtons} 
						render1={this.state.render1}  
						paginaDati={this.state.pagina} 
						pageContent={this.state.pageContent} 
						onClick={(tipoFunzione, params) => this.onClickPagingConsole(tipoFunzione, params)}
					></GestioneContenutiTable>
                  </div>
                </div>
              </div>
			 			</div>
					</div>
				</div>
			</div>
		)
	}
}

export default GestioneContenuti;
