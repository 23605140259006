import React from 'react';
import NewsInEvidenzaBox from '../NewsInEvidenzaBox/NewsInEvidenzaBox';
import MenuHome from '../MenuHome/MenuHome';
import '../../css/home.css';

const env = require("../../config.js").default;
class Home extends React.Component {
  UNSAFE_componentWillMount(){
    document.getElementById('body').className='home_page';
  }

  componentDidMount(){
    document.title = document.title = window.cc.companyInfo.service + " Service";
    for (var filtro in localStorage){
      if(filtro.indexOf("Filtri_") > -1) {
        localStorage.removeItem(filtro);
      }
    }

    // pulisce rif. a doc attivo
		if (sessionStorage.getItem('tordi') !== null) {
			sessionStorage.removeItem("tordi");
		}
		if (sessionStorage.getItem('tnord') !== null) {
			sessionStorage.removeItem("tnord");
		}
		if (sessionStorage.getItem('nordi') !== null) {
			sessionStorage.removeItem("nordi");
		}
		if (sessionStorage.getItem('IDTestata') !== null) {
			sessionStorage.removeItem("IDTestata");
		}
  }

  render(props){
    return (
        <div id="homeMenuContainer" className="row">
          <div className="clear"></div>
          <NewsInEvidenzaBox></NewsInEvidenzaBox>
          <div className="col-lg-4 col-md-5 col-sm-5 hidden-xs-down" id="newsPreviewPlaceHolder">&nbsp;</div>
          <MenuHome type="home"></MenuHome>
        </div>
    )
  }
}

export default Home;
