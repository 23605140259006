import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Navigate } from "react-router-dom";
import moment from 'moment';
const common = require('../common/common');
const env = require("../../config.js").default;

try {
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
}


class TicketVehicleTable extends React.Component{
	constructor(props){
		super(props);
		this.state = {};
		this.state.pathname = this.props.pathname;
		this.state.recordTabella = {};
		this.state.recordTabella.items = [];
		this.state.userInfo = {};
		this.state.userInfo.roles = [];
		this.state.userInfo.userInfo = {};
		this.state.customTag = <></>;
		this.changePage  = this.changePage.bind(this);
		this.state.filtriTicket = {};
		this.state.paginazioneCustomButtons = [];
		this._isMounted = false;
		this.state.is_multibrand = this.props.is_multibrand;
    	this.state.marcaDefault = common.get_localStorage("valoreMarca") !== undefined ?
      		common.get_localStorage("valoreMarca")
    	: this.props.marche_attive !== undefined && this.props.marche_attive.length > 1 ?
      		this.props.marcaDefault
    	:
      		this.props.marche_attive[0].ZFORN;
	}

	UNSAFE_componentWillMount(props){
		if(this.props.pageContent !== undefined && this.props.pageContent !== null && (typeof this.props.pageContent).toLowerCase() === "object" && Object.keys(this.props.pageContent).length > 0){
			var items = this.props.pageContent.items;
			for(var i=0; i<items.length; i++){
				if(items[i].creators === undefined || items[i].creators === null)
					items[i].creators = [];
				if(items[i].subjects === undefined || items[i].subjects === null)
					items[i].subjects = [];
			}
	    this.setState({recordTabella: this.props.pageContent});
		}
		common.setStateUserInfo(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({ is_multibrand: nextProps.is_multibrand });
		}
	}

	componentDidMount(props){
		const $ = window.$;
		this._isMounted = true;

		$(document).ready(function () {
			$(window).resize(function () {
				window.setTimeout(function(){
					if ($('#tickets_vehicle_table').is(":visible"))
						$('#tickets_vehicle_table').DataTable()?.columns?.adjust();
				}, 600);
			});
		});

		$('#tickets_vehicle_table').DataTable({
			"retrieve": true,
			responsive: false,
			"tabIndex": -1,
			"pageLength": env.TABLE_PAGE_LENGTH,
			"lengthChange": false,
			"paging": false,
			"info": false,
			"ordering": false,
			"searching": false,
			"width": "100%",
			"scrollX": true,
			"autoWidth": true,
			"sScrollX": "100%",
			"sScrollXInner": "100%",
			columnDefs: [
				{
					targets: [ 9 ],
					orderData: [ 0, 9 ],
					"defaultContent": "",
					"targets": "_all",
				},
				{
					targets: [ 10 ],
					orderData: [ 0, 10 ]
				},
			],
			data: this.state.recordTabella.items,
			columns: [
				{
					data: null,
					"class": "never",
					"render": (data,type,row) => {
            if(row.sw_read !== undefined && row.sw_read !== null && row.sw_read)
              return "0"
						else
              return "1";
					},
					"visible":false,
				},
				{
					title: common.getTrad("Telaio"),
					data: null,
					width: "10px",
					"class": 'vin_vehicle sorting',
					"render": (data,type,row) => {
						if (row.vin_vehicle !== undefined && row.vin_vehicle !== null && row.vin_vehicle)
							return row.vin_vehicle;
						else
							return "";
          			},
				},
				{
					title: common.getTrad("Titolo"),
					data: null,
					"width": "30px",
					"class": 'sortable_title sorting',
					"render": (data,type,row) => {
						var ret = '';
						if(row.title.length > 50){
							ret = <>{truncateOnWord(row.title, 50)}<br/><small>({row.id})</small></>;
						} else {
							ret = <>{row.title}</>;
						}

						return ReactDOMServer.renderToStaticMarkup(
							<>
								<small className="ticketIDsmall">{row.id}</small>
								{ret}
							</>
						)
					}
				},
        {
					title: common.getTrad("Stato"),
					data: null,
					width: "10px",
					"class": 'review_state sorting',
					"render": function ( data, type, row ) {
						if (row.review_state.indexOf("closed") > -1) {
							return  "<span title=" + common.getTrad("Chiuso") +" class=\"closed\" style=\"font-weight:normal;background: #dc3545;box-shadow:1px 1px 3px -1px #000;padding: 0.25em 0.5em;color: #fff;\"><i class=\"fas fa-envelope\"></i></span>";
						}else if(row.review_state.indexOf("incomplete") > -1) {
							return  "<span title="+ common.getTrad("Incompleto") +" style=\"font-weight:normal;background: #ffc107;box-shadow:1px 1px 3px -1px #000; padding: 0.25em 0.5em;color: #000;\"><i class=\"fas fa-envelope-open\"></i></span>";
						}else if(row.review_state.indexOf("published") > -1) {
							return  "<span title="+ common.getTrad("In lavorazione") +" style=\"font-weight:normal;background: #007bff;box-shadow:1px 1px 3px -1px #000; padding: 0.25em 0.5em;color: #fff;\"><i class=\"far fa-envelope\"></i></span>";
						}else{
							return  "<span title="+ common.getTrad("Aperto") +" style=\"font-weight:normal;background: #28a745;box-shadow:1px 1px 3px -1px #000; padding: 0.25em 0.5em;color: #fff;\"><i class=\"fas fa-envelope-open-text\"></i></span>";
						}
					}
        },
        {
          title: common.getTrad("Pubblicato da"),
          data: 'creators',
          width: "30px",
          "class": "Creator sorting ticket_creator",
          "render": (data,type,row) => {
            var ret = '';
			if(data.length > 30){
				ret = <span alt={data} title={data}>{truncateOnWord(data, 30)}</span>;
			}else{
				ret = <>{data}</>;
			}

			return ReactDOMServer.renderToStaticMarkup(
				<>
					{ret}
				</>
			)
          }
				},
        {
					title: common.getTrad("Nazione"),
        	width: "10px",
					"class": "user_cnazi sorting",
					data: null,
        	"render": ( data, type, row ) => {
            return row.user_cnazi !== undefined && row.user_cnazi !== null ? row.user_cnazi : "";
          }
        },
		{
			data: 'review_state',
			"visible":false,
		},
		{
			title: common.getTrad("Marca"),
			data: null,
			width: "90px",
			visible: this.state.is_multibrand,
			"render": (data, type, row) => {
				var brand = row.subjects.find(a => a.includes("sw-brand"));
				if (brand !== undefined && brand.length > 0)
					brand = brand.split('sw-brand-')[1];
				else
					brand = this.state.marcaDefault;


				if (type !== "display")
					return brand;

				var indexBrand = common.object_value_in_array(window.cc.marche_portale, 'ZFORN', brand);
				if (window.cc.marche_portale !== undefined && window.cc.marche_portale[indexBrand] !== undefined) {
					let text = window.cc.marche_portale[indexBrand].DFORN
					return '<img class="tableBrandImg" title="' + text + '" alt="' + text + '" src="' + env.SWAN + 'static/images/loghi/' + brand.toLowerCase() + '.png"/>';
				}
				else
					return brand;
			}
		},
        {
          title: common.getTrad("Tipo"),
          data: 'subjects',
          width: "10px",
          "render": ( data, type, row ) => {
            var brand = data.find(a =>a.includes("sw-brand"));
            var tipologia = data.find(a =>!a.includes("sw-"));
            if(brand !== undefined && brand.length > 0){
              brand = brand.split('sw-brand-')[1];
            }else{
              brand = this.state.marcaDefault;
            }
            if(this.props.tipologieTicket !== undefined && this.props.tipologieTicket[brand] !== undefined && this.props.tipologieTicket[brand][tipologia] !== undefined && this.props.tipologieTicket[brand][tipologia].titolo !== undefined)
              return common.getTrad(this.props.tipologieTicket[brand][tipologia].titolo)
            else
              return ""
          }
        },
        {
          title: common.getTrad("Data Ins."),
          width: "10px",
          data: null,
          "class": 'created sorting',
          type: 'datetime-moment',
          "render": function ( data, type, row) {
            return moment(row.created).format('L') + " " + moment(row.created).format('LT')
          }
        },
        {
          title: common.getTrad("Data Mod."),
          data: null,
          width: "10px",
          type: 'datetime-moment',
          "class": 'modified sorting',
          "render": function ( data, type, row) {
            return moment(row.modified).format('L') + " " + moment(row.modified).format('LT')
          }
        },
        {
          title: common.getTrad('Modificato da'),
          width: "10px",
          data: null,
          "class": "ticket_creator",
          "render": ( data, type, row ) => {
            if(row.last_admin_editor !== undefined && row.last_admin_editor !== null && row.last_admin_editor !== ''){
              return row.last_admin_editor;
            } else {
              return "";
            }
          }
				},
			],
			"language": {
				"url": env.STATIC + "lib/datatables/i18n/" + common.getLanguage() + ".lang"
			},
			fnRowCallback: (nRow, aData, iDisplayIndex, iDisplayIndexFull) => {
				// Row click
				$(nRow).on('click', (e) => {
					let $container = $("#tickets_vehicle_table_wrapper").parent();
					if ($container.hasClass("dragging") || $container.hasClass("dragged")) {
						$container.removeClass("dragged")
						return false
					}

					if ($("#tickets_table").hasClass("collapsed")) {
						if (e.currentTarget.classList[1] === "parent" && e.target.cellIndex > 0) {
							this.changePage(common.getUrlPath(aData["@id"]));
							return false;
						}
						if (e.target.cellIndex === undefined || e.target.cellIndex > 0) {
							this.changePage(common.getUrlPath(aData["@id"]));
							return false;
						}
					} else {
						this.changePage(common.getUrlPath(aData["@id"]));
						return false;
					}
				});

				if (aData['sw_read'] !== undefined && aData['sw_read'] !== null && aData['sw_read'])
					$(nRow).addClass('font-weight-bold');
			},
			"drawCallback": function (settings) {
				var $container = $("#tickets_vehicle_table_wrapper").parent();
				var $scroller = $("#tickets_vehicle_table_wrapper .dataTables_scrollBody");
				common.bindDragScroll($container, $scroller);
			},
		});

		if (common.get_localStorage("sortOrderColumnTicket") !== undefined && common.get_localStorage("sortOrderColumnTicket"))
			this.props.paginaDati.verso_ordinamento = parseInt(common.get_localStorage("sortOrderColumnTicket"))

		$('#tickets_vehicle_table thead').click((e) => {
			var versoOrdinam = 0;
			if($(e.target).hasClass("sorting")){

				if(this.props.paginaDati.verso_ordinamento === 1){
					versoOrdinam = -1;
					$(".sorting_disabled").removeClass("sorting_asc");
					$(e.target).addClass("sorting_desc");
				}else{
					versoOrdinam = 1;
					$(".sorting_disabled").removeClass("sorting_desc");
					$(e.target).addClass("sorting_asc");
				}

				var parametri = {
					tipoOrdinam: e.target.className.split(" ")[1],
					versoOrdinam: versoOrdinam
				}
				this.props.onClick("ordinamento", parametri );
			}
		});

		$(".search_tabella").keypress((e) =>{
			if(e.which === 13 || e.which === 10){
				e.preventDefault();
				$("#ricercaTabella").click();
			}
		});

    $('input.search_tabella').on("change keyup cut search", (e) => {
      if(this.props.paginaDati.testo_cercato !== undefined && this.props.paginaDati.testo_cercato !== null && this.props.paginaDati.testo_cercato !== ''){
        e.preventDefault();
        if(!e.target.value)
          this.props.onClick("cerca", {testo: ''});
		  common.set_localStorage("filtroCerca", e.target.value)
      }
    });

		$("#ricercaTabella").click((e) => {
			e.preventDefault();
			const $ = window.$;
			var parametri = {
				testo: $("input.search_tabella").val()
			}
			this.props.onClick("cerca", parametri );
			common.set_localStorage("filtroCerca", parametri.testo)
		});

		$("#tickets_vehicle_table").on("click", ".DT_bottone", (e) => {
			 this._isMounted && this.setState({
				 customTag: (<Navigate to={$(e.target).data('link')} push={true}/>)
			});
		});

		if (common.get_localStorage("filtroCerca") !== undefined && common.get_localStorage("filtroCerca") !== null && common.get_localStorage("filtroCerca") !== '') {
			var parametri = {
				testo: common.get_localStorage("filtroCerca")
			}
			this.props.onClick("cerca", parametri);
			$("input.search_tabella").val(parametri.testo);
		}

		if (common.get_localStorage("sortOrderColumnTicket") !== undefined && common.get_localStorage("sortOrderColumnTicket") !== null && common.get_localStorage("sortOrderColumnTicket") === "-1") {
			$("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).removeClass("sorting_asc");
			$("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).addClass("sorting_desc");
		} else if (common.get_localStorage("sortOrderColumnTicket") !== undefined && common.get_localStorage("sortOrderColumnTicket") !== null && common.get_localStorage("sortOrderColumnTicket") === "1") {
			$("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).removeClass("sorting_desc");
			$("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).addClass("sorting_asc");
		}
	}

	paginazioneCustomClick(e){
		e.preventDefault();
		var parametri = {
			vaiPagina: e.target.id.split("_")[2]
		}
		this.props.onClick("paginazione_custom", parametri );
	}



	componentDidUpdate(nextProps){
		const $ = window.$;
    if(!nextProps.paginaDati.filtra_risultati)
      return false;
		var pagina = nextProps.paginaDati;
		var paginaAttuale = parseInt(pagina.pagina_attuale), pagineTotali = '';

		pagineTotali = parseInt(this.state.recordTabella.items_total / env.TABLE_PAGE_LENGTH);
		if((this.state.recordTabella.items_total % env.TABLE_PAGE_LENGTH) > 0){
			pagineTotali ++;
		}

		var buttons = [];
		$('#paginazione_custom').html("");

		if(pagineTotali > 1){
			var paginazione_custom_content = "";
      const NUM_PAGINE_VISIBILI = 3;
      const SALTO_PAGINE = 5;
      var start=paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - SALTO_PAGINE;
      var end=paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + SALTO_PAGINE;
      var allPreviousMax = (SALTO_PAGINE - parseInt(NUM_PAGINE_VISIBILI/2));
      var allNextMax = pagineTotali - allPreviousMax;
      var startCiclo = (start <= 0 ? 1 : start);
      var endCiclo = (end > pagineTotali ? pagineTotali : end);

      for(var i=startCiclo; i<=endCiclo; i++){
        var print = false;
        var ellipsis = false;

        if((paginaAttuale <= allPreviousMax && i < paginaAttuale) || (paginaAttuale > allNextMax && i > paginaAttuale))
          print = true;
        if(i >= (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) && i <= (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1))
          print = true;
        if(i === startCiclo || i === endCiclo || i === paginaAttuale)
          print = true;
        if(i !== paginaAttuale && ((i === (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) &&  paginaAttuale >= allPreviousMax) || (i === (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1) && paginaAttuale <= allNextMax)))
          ellipsis = true;

        if(print)
          buttons.push('<li class="paginate_button page-item ' + (paginaAttuale===i ? "active" : "") + '"><p id="page_num_' + i +'" class="page-link">' + (ellipsis ? "&hellip;" : i) + '</p></li>');
      }

			for (var b=0; b<buttons.length; b++){
				if(buttons[b] !== undefined && buttons[b] !== null){
					$('#paginazione_custom').html(paginazione_custom_content + buttons[b]);
					paginazione_custom_content = $('#paginazione_custom').html();
				}
			}
		}


    if(this.state.userInfo.is_admin_ticket !== undefined && !this.state.userInfo.is_admin_ticket){
      $("body").append('<style>.ticket_creator{display:none}</style>');
    }

		$("#tickets_vehicle_table").DataTable().clear();
		$("#tickets_vehicle_table").DataTable().rows.add(this.state.recordTabella.items);
		$("#tickets_vehicle_table").DataTable().draw();
		$("#tickets_vehicle_table").DataTable().responsive.recalc();
		// $('table').resize();
    $("table>tbody>tr").attr("role", "row");
	}

	changePage(id){
		this._isMounted && this.setState({
			customTag: (<Navigate to={id} push={true}/>)
		})
	}

	componentWillUnmount() {
		 this._isMounted = false;
	}

	sortColumn() {
		const $ = window.$;
		if (common.get_localStorage("sortOrderColumnTicket") !== undefined && common.get_localStorage("sortOrderColumnTicket") !== null && common.get_localStorage("sortOrderColumnTicket") === "-1") {
			$("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).removeClass("sorting_asc");
			$("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).addClass("sorting_desc");
		} else if (common.get_localStorage("sortOrderColumnTicket") !== undefined && common.get_localStorage("sortOrderColumnTicket") !== null && common.get_localStorage("sortOrderColumnTicket") === "1") {
			$("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).removeClass("sorting_desc");
			$("#tickets_vehicle_table_wrapper ." + common.get_localStorage('sortColumnTicket')).addClass("sorting_asc");
		}
	}

	render(props){
		return(

			<div className="tab active">
				{this.state.customTag}
				<div className="offset-xl-8 col-xl-4 col-sm-12 mb-2">
					<div className="input-group ">
						<input className="form-control search_tabella" name="q" type="search" placeholder={common.getTrad("Cerca")} aria-label="Cerca"/>
						<button className="btn btn-outline-light bottone_default" id="ricercaTabella" type="submit">
							<i className="fas fa-search"></i> {common.getTrad("Cerca")}
						</button>
					</div>
				</div>
				<button id="initial_load_ticket" style={{ display: "none" }}></button>
				<div>
				<table id="tickets_vehicle_table" className="table table-striped table-bordered dt-responsive nowrap" style={{ width: '100%' }}></table>
				<div className="dataTables_wrapper dt-bootstrap4 no-footer">
					<div className="row">
            <div className="col-lg-5 col-sm-12 col-xs-12">
  						{this.props.paginaDati.batchingAdvanced && this.state.recordTabella.items_total > 0 ?
  								<div className="dataTables_info">
  									{common.getTrad("Elementi mostrati") + " (" + this.state.recordTabella.items.length + ") " + common.getTrad(" su un totale di") +" (" + this.state.recordTabella.items_total+ ")"}
  								</div>
  						:
  							<></>
  						}
            </div>
						{this.state.recordTabella.items_total > env.TABLE_PAGE_LENGTH ?
							this.state.recordTabella.batching ?
								<div className="col-lg-7 col-sm-12 col-xs-12">
									<div className="dataTables_paginate paging_full_numbers" id="tickets_vehicle_table_paginate">
										<ul className="pagination">
                      {this.props.paginaDati.batchingAdvanced ?
                        <>
                          <li onClick={() => {if(this.state.recordTabella.batching.prev) this.props.onClick("paginazione_custom", {vaiPagina: 1})}}
                              className={(!this.state.recordTabella.batching.prev ? "disabled" : "") + " paginate_button page-item first"}
                              title={common.getTrad("Inizio")}>
                            <p className="page-link"><i className="fas fa-fast-backward"></i></p>
                          </li>
                          <li onClick={() => {if(this.state.recordTabella.batching.prev) this.props.onClick("paginazione_custom", {calcolaPagina: -1})}}
                              className={(!this.state.recordTabella.batching.prev ? "disabled" : "") + " paginate_button page-item previous"} id="table_DefaultTable_previous"
                              title={common.getTrad("Precedente")}>
                            <p className="page-link"><i className="fas fa-step-backward"></i></p>
                          </li>
                        </>
                      :
                        <></>
                      }

											<div style={{display: 'flex'}} id="paginazione_custom" onClick={(e) => this.paginazioneCustomClick(e)}></div>
                      {this.props.paginaDati.batchingAdvanced ?
                        <>
                          <li onClick={() => {if(this.state.recordTabella.batching.next) this.props.onClick("paginazione_custom", {calcolaPagina: 1})}}
                              className={(!this.state.recordTabella.batching.next ? "disabled" : "") + " paginate_button page-item next"} id="table_DefaultTable_next"
                              title={common.getTrad("Successivo")}>
                            <p className="page-link" ><i className="fas fa-step-forward"></i></p>
                          </li>
                          <li onClick={() => {if(this.state.recordTabella.batching.next) this.props.onClick("paginazione_custom", {vaiPagina: "end"})}}
                              className={(!this.state.recordTabella.batching.next ? "disabled" : "") + " paginate_button page-item last"}
                              title={common.getTrad("Fine")}>
                            <p className="page-link"><i className="fas fa-fast-forward"></i></p>
                          </li>
                        </>
                      :
                        <></>
                      }
										</ul>
									</div>
								</div>
							:
								<></>
						:
							<></>
						}
						</div>
					</div>
				</div>
				{ this.sortColumn() }
			</div>
		)
	}
}

function truncateOnWord(str, limit) {
	var trimmable = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
	var reg = new RegExp('(?=[' + trimmable + '])');
	var words = str.split(reg);
	var count = 0;
	return words.filter(function(word) {
			count += word.length;
			return count <= limit;
	}).join('') + "...";
}

export default TicketVehicleTable;
