import React from 'react';
const common = require("../common/common");
const env = require("../../config.js").default;

class ResetPassword extends React.Component {

  constructor(props){
    super(props);
    this.state = {};
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  componentDidMount(){
    const $ = window.$;
    $('#reset_password').on('hide.bs.modal', () => {
      $("#result_resetpassword").hide();
    });
  }

  handleResetPassword (e){
    e.preventDefault();
    const $ = window.$;
    $("#loginLoaderReset").fadeIn("fast");
    if($("#username").val() !== '' && $("#email").val() !== ''){
      var usernameValue = $("#username").val().trim();
      if(usernameValue.indexOf(window.cc.prefix) > -1){
        usernameValue = usernameValue.substring(window.cc.prefix.length, usernameValue.length);
      }

      common.apiCall(env.SWAN + 'ajax_reset_pwd/?email='+ $("#email").val()+'&cuser=' + window.cc.prefix + usernameValue, "GET", {}, false)
      .then((response) => {
        setTimeout(() =>{$("#loginLoaderReset").fadeOut("slow");}, 500);
        $("#result_resetpassword").css("display", "block");
        if (response.ires === 200 && response.errors.length === 0){
          $("#result_resetpassword").html('<div class="sw-alert alert alert-success">'+common.getTrad("Password aggiornata. a breve riceverai una email con la tua nuova password")+'</div>');
        }else{
          $("#result_resetpassword").html('<div class="sw-alert alert alert-danger">'+common.getTrad("Oh no... qualcosa non funziona come dovrebbe.") + '<br/>' + response.errors +'</div>');
        }
        $("#username").val('');
        $("#email").val('');
      });
    }
  }

  render(){
    return(
      <div className="modal fade" id="reset_password" tabIndex="-1" role="dialog" aria-labelledby="reset_password" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"><i className="fas fa-lock"></i> {common.getTrad("Reset Password")}</h5>
              <button type="button" className="close closeModal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h4>{common.getTrad("Password dimenticata?")}</h4>
              <p>{common.getTrad("Se avete dimenticato la password - richiedetela qui inserendo il Vostro indirizzo mail di registrazione")}</p>
              <form className="form-inline" id="reset_passwordForm" onSubmit={this.handleResetPassword}>
                <div className="col-md-12 p-0">
                  <div className="form-group mb-1">
                    <label htmlFor="email">{common.getTrad("Email")}:&nbsp;<span className="danger">*</span></label>
                  </div>
                  <div className="input-group mb-4 mr-sm-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text"><i className="fas fa-envelope"></i></div>
                    </div>
                    <input type="email" required className="form-control" name="email" id="email" placeholder={common.getTrad("Email con la quale sei iscritto")} />
                  </div>
                  <div className="form-group mb-1">
                    <label htmlFor="username">{common.getTrad("Username")}:&nbsp;<span className="danger">*</span></label>
                  </div>
                  <div className="input-group mb-5 mr-sm-2">
                    <div className="input-group-prepend">
                      <div className="input-group-text"><i className="fas fa-user"></i></div>
                    </div>
                    <input type="text" required className="form-control" name="username" id="username" placeholder={common.getTrad("Il tuo Username")} />
                  </div>
                  <div className="fade show animated bounceInUp" id="result_resetpassword" style={{display: "none"}}></div>
                  <button type="submit" className="btn btn-primary btn-block">
                    <i className="fas fa-spinner fa-spin" id="loginLoaderReset" style={{display: "none"}}></i>&nbsp;{common.getTrad("Richiedi nuova password")}
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light closeModal" data-dismiss="modal">{common.getTrad("Chiudi")}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ResetPassword;
