const serviceWorker = require('../../serviceWorker.js');
const env = require("../../config.js").default;
const db = require("./indexDB.js");

window.tryLogin = 0;

/*
  Common library. Functions are divided in sections:

  1. Login
  2. API
  3. Language
  4. Utility Functions
  5. Local Storage Managers
  6. Cookies functions
  7. Modelli

*/

/* ================================================================================================================================================== */


/*  1. Login  */
  export var getLocalLoginToken = ()=>{
    /*
     * This function gets the user token from local storage.
     * Return the user token in case of success, or false in failture case
     *
     */
    var appState = get_localStorage("appState");

    if (appState && appState !== "undefined" && appState !== undefined && appState !== "" && appState !== null) {
      appState = JSON.parse(appState);
      var isLoggedIn = appState.isLoggedIn;
      if(isLoggedIn){
        return appState.user;
      }
      else
        return false;
    }else{
      return false;
    }

  }

  export var getUserName = ()=>{
    /*
     * This function get user name from app state local storage.
     * No Params
     * Returning:
     * the user name in case of success
     * False in case of failture or anonymous user
     */

    var appState = get_localStorage("appState");

    if (appState && appState !== "undefined" && appState !== undefined && appState !== "" && appState !== null) {
      appState = JSON.parse(appState);
      var isLoggedIn = appState.isLoggedIn;
      if(isLoggedIn){
        return appState.userName;
      }
      else
        return false;
    }
  }

  export function getUserInfoSync(key){
    /*
     * BOH
     */
    var userInfo = get_localStorage("userInfo");
    userInfo = JSON.parse(userInfo);
    if (isDefined(key)){
      userInfo = userInfo[key];
    }
    return userInfo;
  }

  export async function getUserInfo(noCache=false){
    /*
     * This function is used to gat user information.
     * It checks in local storage, if nothing is avaible, calls the backend api and save the result in local storage.
     * No params
     * Returning:
     * False in case of failture or anonymous user
     * Proise with user info object in case of success
     */
    var userInfo = get_localStorage("userInfo");
    var user = getLocalLoginToken();
    if (userInfo && userInfo !== "undefined" && userInfo !== undefined && userInfo !== "" && userInfo !== null && !noCache) {
      return JSON.parse(userInfo);
    }
    else{
      if(user !== false){
        return apiCall(env.BACKEND + "/@sw-autenticated-info", "GET", {}, true)
        .then(function(response) {
          if(response.error === false){
            if(response.marche_attive !== undefined)
              window.cc.marche_attive = response.marche_attive;
	          // inizio a salvare i dati di sw-ath...
            // se funziona la chiamata a swan salvero' anche gli altri dati
            // mi autentico su swan
            return apiCall(env.SWAN + response.sso_url, "GET", {}, false, false, "json", true)
              .then((sso_response) => {
                if(sso_response.error === false && sso_response.login){
                  response.is_agente = sso_response.has_agente_view;
                  return apiCall(env.SWAN + 'get_user_menu/', "GET", {}, false, false, "json", true)
                    .then(function(get_user_menu_response) {
                      if(get_user_menu_response.error === false){
                        response["user_menu"] = get_user_menu_response;
                        set_localStorage("userInfo", JSON.stringify(response));
                        return response;
                      }else{
                        console.error("Errore get user menu");
                        set_localStorage("userInfo", JSON.stringify(response));
                        return response;
                      }
                    });

                }else{
                  console.error("Errore SSO");
                  set_localStorage("userInfo", JSON.stringify(response));
                  return response;
                }
              });
          }else{
            console.error("Errore SW authenticated info");
            return false;
          }
        });
      }
    }
  }

  export async function verifyLogin(){
    /*
     * This function gets verify if user is logged in backend.
     * Get's user's token from local storage and calls a backend resource to verify token validity.
     * If token is valid return it, else return false.
     *
     */
    var user = getLocalLoginToken();
    var ret = null;

    if(user !== false){
      do{
        var response = await getUserInfo(true)
        if(response === false)
          ret = "notLogged";
        else
          return user;

        window.tryLogin ++;
      }while(window.tryLogin <2 || ret !=="notLogged");
      return ret;
    }else{
      return "noToken";
    }
  }
  
  export function userHasFBAuth(auth, zforn=''){
    let userInfo; 
    userInfo = getUserInfoSync();
    if(userInfo === undefined || userInfo === false)
        return false;
        
    if(zforn == ''){
      if(object_value_in_array(userInfo.userInfo.auth, 'TDATI', auth) > -1)
          return true;
    }
    //TODO: prevedere else (se devo verificare lo zforn)
    
    return false;
     
  }
/* 1. Login END */



/* ================================================================================================================================================== */

async function readResponseAsBlob(response) {
	// downlaod di blob con nome file preso dall'header della risposta
	var contentDisposition = response.headers.get('content-disposition');
  if(contentDisposition !== undefined && contentDisposition !== null && contentDisposition !== ''){
  	var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
    var element = document.createElement('a');
    element.setAttribute('download', filename);
  	let myBlob = await response.blob();
    element.setAttribute('href', URL.createObjectURL(myBlob));
  	element.style.display = 'none';
  	document.body.appendChild(element);
  	element.click();
  	document.body.removeChild(element);
  }
}


/* 2. API */
  export async function apiCall (url, method="GET", data={}, useLogin=false, useCache=false, encodingType="json", useSSO = false, lockToken='', cacheTTL = null){
    /*
     * This function is used to implement the restfull API call to backend.
     *
     * Params:
     * url -> Required, is the full url to the API service (e.g. https://google.com);
     * method -> Optional, is method used by the function to call the service. In case of not known http protocol return false. Default GET;
     * data -> Optional, Object of data you need to pass to the service. You have to use it also with GET services. Default is empty;
     * useLogin -> Optional, boolean. If true this function use user token (if is present in local storage) to call the service as autenticated. Dafault false;
     * useCache -> Optional, boolean. Not implemented. Default false.
     * encodingType -> Optional, json; blob; text.
     * useSSO -> Optional, boolean. Default false.
     * lockToken -> Optional, string. Default '' use it for PATCH content if it's locked before
     *
     * Return: false in case of failture otherwise return and object with response data.
     * If the call fails, object attribute's "error" has full description of error status, else is false
     *
     */

    const $ = window.$;
    var login, headers={};

    method = method.toUpperCase();

    if(useLogin){
      login = getLocalLoginToken();
      if(login === false){
        return false;
      }
    }

    if(!isValidHTTPProtocol(method)){
      return false;
    }

    headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getLanguage(true),
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Origin': '*',
    };

    if(useLogin){
      headers.authorization = "Bearer " + login;
    }

    if(lockToken !== ''){
      headers['Lock-Token'] = lockToken;
    }

    if(method==="GET" && !$.isEmptyObject(data)){
      /*
      var params=[];
      for (const key in data) {
        params.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
      }
      params = params.join('&');
      */
      let params = $.param(data);
      url = url + "?" + params;
    }
    headers = new Headers(headers);
    var fetchParams;
    fetchParams = {
      method: method,
      headers: headers,
    };
    if(useSSO)
      fetchParams.credentials = 'include';

    if(method!=="GET"){
      fetchParams.body = JSON.stringify(data);
    }

    return cachedFetch(url, fetchParams, encodingType, useCache, cacheTTL);
  }

  export async function getBlob (id, url){
		const $ = window.$;
    $(".downloadFileLoader_" + id).css("display", "inline");
    $(".downloadIconFile_" + id).css("display", "none");
    await apiCall(url, "GET", {}, true, false, "named_blob");
    $(".downloadFileLoader_" + id).css("display", "none");
    $(".downloadIconFile_" + id).css("display", "inline");
	}

  const cachedFetch = (url, options, encodingType = '', useCache = false, cacheTTL = null) => {
    /*
     * This function fetch data from a specific url and store the result in local storage.
     * The next call it chek if in local storage are presents the requested data before call the service.
     * Same param of fetch function.
     * Same retun of fetch function.
     *
     */
    if (useCache && options.method === 'GET' && encodingType === 'json'){
      let cacheKey = url;
      return db.getCacheDB(cacheKey, cacheTTL).then(function (cached){
        if (cached !== null) {
          return cached;
        } else {
          return updateData(url, options, encodingType).then(function(data){
            if (data?.error === false){
              let cacheData = {
                "request": cacheKey,
                "value": data,
                "timestamp": new Date().getTime(),
                "contentType": getDataContentTypes(data),
              }
              db.addCacheDB(cacheData);
            }
            return data;
          });
        }
      });
    }
    
    return updateData(url, options, encodingType);
  }

  function getDataContentTypes(data){
    let out = [];
    if(data.items !== undefined && data.items !== null){
      for(let i in data.items){
        if (data.items[i]["@type"] !== undefined && data.items[i]["@type"] !== null && out.indexOf(data.items[i]["@type"]) <= -1)
          out.push(data.items[i]["@type"]);
      }
      if (data.items.length == 0)
        out.push("all");
    } else if (data["@type"] !== undefined && data["@type"] !== null && out.indexOf(data["@type"]) <= -1) {
      out.push(data["@type"]);
    }

    return out;
  }

  function updateData(url, options, encodingType=''){
    /*
     * Call to server of the cachedFetch function. Do not use!
     *
     */

    if (encodingType === "json") {
      return fetch(url, options)
        .then(function (response) {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then(function(result){
          result.error = false;
          return result;
        })
        .catch(function (error) {
          return { error: error };
        });
    } else if (encodingType === "blob") {
      return fetch(url, options)
        .then(function (response) {
          return response.blob();
        })
        .then(function (myBlob) {
          return URL.createObjectURL(myBlob);
        });
    } else if (encodingType === "named_blob") {
      return fetch(url, options)
        .then(readResponseAsBlob);
    } else {
      return fetch(url, options)
        .then(function (response) {
          return response.text();
        });
    }
     
  }
/* 2. API End */



/* ================================================================================================================================================== */



/* 3. Language */
  function getBrowserLanguage(){
    /*
     * This function get the browser default language and check if it's present in app's avaible languages.
     * If language is present in array return it, else return the dafualt app language.
     * In case of failture return false
     *
     */
    var browserLang = navigator.language || navigator.userLanguage;
    browserLang = browserLang.substr(0,2).toLowerCase();
    return browserLang;
  }

  export function getLanguage(browserLang = true, environmentSuffix = null){
    /*
     * This function return preferred user language. Calculates it inbase 3 params.
     * If is setted return local storage language;
     * else return preferred language from backend; -- NOT IMPLEMENTED
     * If backend is empty or user isn't logged in return default browser language
     * environment param is used to get language with specific suffix
     */
    var language = false;
    var appLang = get_localStorage("language");
    if(environmentSuffix !== null)
      appLang = get_localStorage("language", environmentSuffix);
    if(appLang !== null && appLang !== undefined && appLang !== "")
      language = appLang;
    else
      if(browserLang)
        language = getBrowserLanguage();

    //Ne caso in cui non sia ancora stato caricato nessun file di configurazione, setto momentaneamente lingua inglese
    if(language === false)
      language = "en";

    return language;
  }

  export function getLanguageMoment(){
    //Restituisce la lingua dell'utente con eccezioni per moment js
    var language = getLanguage(false);

    if(language.toLowerCase() === "en")
      language = "en-gb";

    return language;
  }

  export function getLanguageDatePicker(){
    //Restituisce la lingua dell'utente con eccezioni per datepicker
    var language = getLanguage(false);

    if(language.toLowerCase() === "en")
      language = "en-GB";

    return language;
  }

  export function getTrad(label_id, replace_param){
    /*
     * Funzione di traduzione stringhe statiche del template. Paramentri:
     * label_id -> String. contiene la stringa da tradurre
     * replace_param -> Object. contiene l'oggetto con variabile e valore da sostituire. è possibile che siano più di uno
     * La funzione restituisce:
     *  - la stringa tradotta nel caso in cui venga trovata nell'oggetto evn.labels contentente tutte le traduzioni disponibili;
     *  - La stringa originale nel caso in cui non sia stata trovata alcuna traduzione
     * **************************************************************************************************
     * Per facilitare lo sviluppo, la funzione può salvare in un file tutte le stringhe trovate senza una corrispondente traduzione.
     * Questa funzionalità è attivabile tramite la variabile d'ambiente MISSING_TRANSLATIONS_ALERT
     */
    if (label_id in env.labels){
      if(replace_param !== undefined && replace_param !== null && replace_param !== ''){
        var val = env.labels[label_id];
        var entries = Object.entries(replace_param);
        entries.forEach((para) => {
          var find = '{{' + para[0] + '}}';
          var regExp = new RegExp(find,'g');
          val = val.replace(regExp, para[1]);
        });
        return val;
      }else{
        return env.labels[label_id];
      }
    }
    else {
      if(env.MISSING_TRANSLATIONS_ALERT){
        var traduzioniMancanti;
        if(sessionStorage["traduzioniMancanti"] && sessionStorage["traduzioniMancanti"] !== "" && sessionStorage["traduzioniMancanti"] !== undefined && sessionStorage["traduzioniMancanti"] !== null)
          traduzioniMancanti = JSON.parse(sessionStorage["traduzioniMancanti"]);
        else
          traduzioniMancanti = [];
        if(traduzioniMancanti.indexOf(label_id)<=-1)
          traduzioniMancanti.push(label_id);
        sessionStorage["traduzioniMancanti"] = JSON.stringify(traduzioniMancanti);
        console.warn("Traduzione Manacante: \"" + label_id + "\"");

      }

      return label_id;
    }
  }

  export function setInitialLanguage(){
    /*
     * Function used to set default language of very first access to the app.
     * Try for max. 10 times to get browser language. In case of failture set en as default.
     * In case there is a language in local storage nothing returns
     *
     */
    var tent = 0, language=false;
    if(get_localStorage("language") !== null && get_localStorage("language") !== undefined && get_localStorage("language") !== "")
      return
    do{
      if(get_localStorage("language") !== null && get_localStorage("language") !== undefined && get_localStorage("language") !== "")
        language = get_localStorage("language");
      else
        language = getBrowserLanguage();
      tent ++;
    }while (language === false && tent<10);

    if(language === false)
      language = "en";

    set_localStorage("language", language);
    return
  }

  export function setLanguage(language, reload=true){
    /*
     * This function is used to set user's selected language.
     * Params:
     *  - language, string, required. -> Is the new language value
     *  - Reload, bool, optional -> If true the function execute an autoreload of the page to change language settings. Default True
     * If isn't avaible function fail else save it in: local storage and in backend (NOT IMPLEMENTED),
     * then reload the page with new language.
     *
     */
    if(!language)
      return false
    var data = {
      'CLING': language.toUpperCase(),
      'WUS': {
        'CLING': language.toUpperCase(),
      }
    };

    apiCall(env.BACKEND + '@sw-update-userinfo', "POST", data, true, false, "json", false)
    .then((response) => {
      language = language.toLowerCase();
      set_localStorage("language", language);
      setCookie("I18N_LANGUAGE", language);
      remove_localStorage('currentLanguage');
      remove_localStorage('labels');
      remove_localStorage('nazioni');
      remove_localStorage('subscriptions');
      remove_localStorage('terms');
      remove_localStorage('modelli');
      remove_localStorage('filtriTicketsTipologie');
      remove_localStorage('tipologieTicket');
      remove_localStorage("userInfo")
      if(!reload)
        return false;

      set_localStorage("isLanguageChanged", "true");
      window.location.reload();

      return false;
    });
  }

  export function calcTranslationRedirect(userInfo, pageContent, pageTranslationsParent=null){
    var languageChangedLocal = window.languageChanged;
    if(window.languageChanged)
      window.languageChanged = false;

    if(pageContent.description.tableType !== 'ticket' && (userInfo.roles.indexOf("Reviewer") === -1 || languageChangedLocal)){
			var traduzioniLingua = pageContent['@components'].translations.items;
			for(var lang=0; lang < traduzioniLingua.length; lang++){
				if(traduzioniLingua[lang].language === getLanguage()){
					var contentLink = traduzioniLingua[lang]['@id'];
					contentLink = contentLink.substring(env.BACKEND.length + getLanguage().length, contentLink.length);
					contentLink = (getLanguage() + contentLink)

          return contentLink;
				}
			}

      if(pageTranslationsParent !== null){
        var traduzioniLingua = pageTranslationsParent['@components'].translations.items;
  			for(var lang=0; lang < traduzioniLingua.length; lang++){
          if(traduzioniLingua[lang].language === getLanguage()){
  					var contentLink = traduzioniLingua[lang]['@id'];
  					contentLink = contentLink.substring(env.BACKEND.length + getLanguage().length, contentLink.length);
  					contentLink = (getLanguage() + contentLink)

            return contentLink;
  				}
        }
      }
		}

    return false;
  }



/* 3. Language END */




/* ================================================================================================================================================== */




/* 4. Utility Functions */

  export function goBack(history, saveUrl = true){
    set_localStorage("goBack", "1");
    if(saveUrl)
      set_localStorage("goBackPreviousPage", window.location.href);
    window.history.back();
  }

  export function setStateUserInfo(mythis){
    /*
     * This function is used to set the state of class with user info value.
     * Params:
     * mythis -> React node, required. The object where save the state
     * No returns
     */
    getUserInfo().then(userInfo =>{
      if (isDefined(userInfo)&& userInfo !== false){
        mythis.setState({userInfo: userInfo});
      }
    })
  }

  export function colorLightOrDark(color) {
    /*
     * This function chek if color given as parameter is light or dark
     * Return 'light' in case of light color
     * Return 'dark' in case of dark color
     */

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5)
      return 'light';
    else
      return 'dark';
  }

  export function isDefined(myvar){
    /*
     * This function check if a variable is defined.
     * Params:
     * myvar -> Variable, required. The variable to check is defined.
     * Returning:
     * True if variable is isDefined
     * False if variable is undefined
     */
    return (typeof myvar !== "undefined");
  }


  export function humanFileSize(size, binary=1024) {
    /*
     * This function transform the file size in B, kB, MB, GB or TB.
     * Params:
     * size -> Integer, required. Total file size (in Byte)
     * Returning:
     * Srting file size number + unit
     */
    var i = Math.floor( Math.log(size) / Math.log(binary) );
    return ( size / Math.pow(binary, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];

  }

  export function getParam(parameterName, url = "") {
    /*
     * This function is used to get "GET" parameter from URL
     * Params:
     * parameterName -> String, required. Is the GET parameter which is looking for.
     * Returning:
     * The parameter value in case of success
     * null in case of failture
     *
     */
    var result = null, tmp = [];
    if(url === undefined || url === null || url === "")
      url = window.location.search;

    url
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName)
          result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  export function updateURLParameter(param, paramVal, url=window.location.href){
    /*
     * This function return an url with get parameter value updated
     */
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
      tempArray = additionalURL.split("&");
      for (var i=0; i<tempArray.length; i++){
        if(tempArray[i].split('=')[0] !== param){
          newAdditionalURL += temp + tempArray[i];
          temp = "&";
        }
      }
    }

    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
  }

  export function deleteURLParameter(url) {
    /*
    * This function delete all parameter
    */
    return url.split("?")[0];
  }

  export const getFrontendUrl = (backendUrl) =>{
    /*
     * Get the backend url as raquired param and return his frontend equivalent url.
     * Use evironment BACKEND and FRONTEND constants
     *
     */

    if(backendUrl === "" || backendUrl === null || backendUrl === undefined)
      return backendUrl;

    var staticUrls = [env.BACKEND, env.BACKEND_ANSWER];
    staticUrls.sort(function(a, b){
      return -(a.length - b.length);
    });

    for (var i = 0; i<staticUrls.length; i++){
      if(backendUrl.search(staticUrls[i]) > -1){
        backendUrl = backendUrl.replace(staticUrls[i], env.FRONTEND);
        break;
      }
    }
    return backendUrl;
  }

  export const getBackendUrl = (frontendUrl) =>{
    /*
     * Get the frontend url as raquired param and return his backend equivalent url.
     * Use evironment BACKEND and FRONTEND constants
     *
     */
    if(frontendUrl === "" || frontendUrl === null || frontendUrl === undefined)
      return frontendUrl;

    var staticUrls = [env.FRONTEND, env.BACKEND_ANSWER];
    staticUrls.sort(function(a, b){
      return -(a.length - b.length);
    });

    for (var i = 0; i<staticUrls.length; i++){
      if(frontendUrl.search(staticUrls[i]) > -1){
        frontendUrl = frontendUrl.replace(staticUrls[i], env.BACKEND);
        break;
      }
    }
    if(!frontendUrl.startsWith("http://") &&
       !frontendUrl.startsWith("https://") &&
       !frontendUrl.startsWith(env.BACKEND))
      frontendUrl = env.BACKEND + frontendUrl;

    return frontendUrl;
  }

  export const getUrlPath = (url) =>{
    /*
     * Get the frontend url as required param and return his backend equivalent url.
     * Use evironment BACKEND and FRONTEND constants
     *
     */
    if(url === "" || url === null || url === undefined)
      return url;
    var staticUrls = [env.BACKEND_ANSWER, env.BACKEND, env.FRONTEND];
    staticUrls.sort(function(a, b){
      return -(a.length - b.length);
    });

    for (var i = 0; i<staticUrls.length; i++){
      if(url.search(staticUrls[i]) > -1){
        url = url.replace(staticUrls[i], "/");
        break;
      }
    }

    url = url.split("?")[0];
    return url;
  }

  export var isValidHTTPProtocol=(str)=>{
    /*
     * This function check if the http protocol recived as param is valid.
     * Valid protocols are "GET", "POST", "PUT", "DELETE".
     */
    const validProtocols = ["GET", "POST", "PUT", "PATCH", "DELETE"];

    for (var i=0; i<validProtocols.length; i++){
      if(validProtocols[i] === str){
        return true;
      }
    }
    return false
  }

  async function getUpgradeInfo(){
    return apiCall(env.BACKEND + "@sw-upgrade-info", "GET", {}, false, true, 'json', false, '', 0.25);
  }

  export function do_reactUpgrade(){
    getUpgradeInfo().then((version) => {
      if (is_fullUpdateNeeded(version)){
        serviceWorker.unregister();
        var version = get_localStorage("actualVersion");
        var updateCounter = get_localStorage("updateCounter");
        var exclude = ['appState'];
        clear_localStorage(exclude);
        clearCache();
        if (updateCounter !== null && updateCounter !== undefined)
          set_localStorage("updateCounter", updateCounter);
        sessionStorage.clear();
        set_localStorage("actualVersion", version);
        window.location.reload();
      } else {
        if (version.content_last_edit !== undefined){
          const keys = Object.keys(version.content_last_edit);
          for (let i in keys){
            const key = keys[i];
            if (version.content_last_edit[key] > 0){
              console.debug("Refresh cache " + key + " sotto i " + version.content_last_edit[key]);
              db.refreshCacheDB(key, version.content_last_edit[key]);
            }
          }
        } else {
          clearCache();
        }
        
      }
    });    
  }

  export function clear_cache_after_save(contentType){
    db.refreshCacheDB(contentType, new Date().getTime() + 10);
  }

export function is_fullUpdateNeeded(version){
    /*
     * This function is used to check if some updates are avible.
     * It checks the version of backend product and compares it with localStorage versions.
     * No params needed
     * Returning:
     * True:
     *      - If the local versions and the remote versions are different
     *      - If there was an error with the apiCall and the local "updateCounter" is lower than 0 (the first check time)
     * False:
     *      - If local and remote versions are equal
     *      - If there was an error and the local "updateCounter" is greather than 0 (from the second check time)
     */

    var actualVersion = [];
    var actualVersion = "", newVersion;
    actualVersion = get_localStorage("actualVersion");
    if(version.error === false){
      newVersion = version.version + "_" + process.env.REACT_APP_VERSION;
      remove_localStorage("updateCounter");
      set_localStorage("actualVersion", newVersion);

      if(actualVersion !== "" && actualVersion !== newVersion){
        return true;
      }
      return false;
    }else{
      var updateCounter = get_localStorage("updateCounter");
      if(updateCounter === undefined || updateCounter === null || parseInt(updateCounter) <= 0){
        updateCounter = 1
        set_localStorage("updateCounter", updateCounter);

        return  (actualVersion === undefined || actualVersion === null || actualVersion === "" || actualVersion === "false");
      }
      //set_localStorage("actualVersion", "false");

      return false;
    }
  }

  export function getNazioniPortale(filtra){
    /*
     * This function gets countries from custom config and returns them.
     * Params:
     * filtra -> Boolean, required.
     *           If true this function returns only european countries
     *           If false returns all countries
     * Returning:
     * A list of objects. (in case of failture an empty list)
     */
    var nazioni = window.cc.nazioni;
    var nazioni_custom = window.cc.nazioni_custom;
    var nazioniPortale = [];
    if(filtra === true){
      nazioni.map(function(index, key){
        var addNazione = -1, addNazioniEU;
        if (nazioni_custom !== undefined && nazioni_custom !== null && Object.keys(nazioni_custom).length > 0 && nazioni_custom.add.length > 0){
          addNazione = nazioni_custom.add.indexOf(index.CISO2TNA);
          if(nazioni_custom.add.indexOf("YES_EU") > -1)
            addNazioniEU = "EU";
          if(nazioni_custom.add.indexOf("NOT_EU") > -1)
            addNazioniEU = null;
        }
        if((index.TNAZITNA !== undefined && index.TNAZITNA === addNazioniEU) || addNazione > -1){
          nazioniPortale.push(index);
        }
        return false
      });

      if (nazioni_custom !== undefined && nazioni_custom !== null && Object.keys(nazioni_custom).length > 0 && nazioni_custom.remove.length > 0){
        var nazioneToRemove = nazioni_custom.remove;
        for (var i = 0; i < nazioneToRemove.length; i++) {
          var numToRemove = object_value_in_array(nazioniPortale, 'CISO2TNA', nazioneToRemove[i]);
          if(numToRemove > -1){
            nazioniPortale.splice(numToRemove, 1);
          }
        }
      }

      return nazioniPortale;
    }else{
      return nazioni;
    }
  }


  /*
  * Funzione di raggruppamento di un array creando ulteriori array per ogni gruppo
  * Prendo i dati dal parent se non esiste lascio un  oggetto vuoto.
  *
  */

  export function groupBy(list, keyGetter) {

    const map = {};
    for(var i = 0; i < list.length; i++){
      if(map[list[i].parent["@id"]] === undefined || map[list[i].parent["@id"]] === null){
        map[list[i].parent["@id"]] = {};

        //Salvo titolo parent
        if(list[i].parent !== undefined){
          map[list[i].parent["@id"]].title = list[i].parent.title;
          map[list[i].parent["@id"]].description = list[i].parent.description;
        }else
          map[list[i].parent["@id"]].title = "";
        map[list[i].parent["@id"]].items = [];
      }

      // Aggiungo items alla lista
      map[list[i].parent["@id"]].items.push(list[i]);
    }
    return map;
  }

  export function serializeObject(){
    const $ = window.$;
    $.fn.serializeObject = function() {
      var o = Object.create(null),
      elementMapper = function(element) {
        element.name = $.camelCase(element.name);
        return element;
      },
      appendToResult = function(i, element) {
        var node = o[element.name];
        if ('undefined' != typeof node && node !== null) {
          o[element.name] = node.push ? node.push(element.value) : [node, element.value];
        } else {
          o[element.name] = element.value;
        }
      };

      $.each($.map(this.serializeArray(), elementMapper), appendToResult);
      return o;
    };
  }

  export class Mutex {
    constructor() {
      this._lock = Promise.resolve();
    }
    _acquire() {
      var release;
      const lock = this._lock = new Promise(resolve => {
        release = resolve;
      });
      return release;
    }
    acquireQueued() {
      const q = this._lock.then(() => release);
      const release = this._acquire();
      return q;
    }
  }


  export function object_value_in_array(array, key, value){
    var index = -1;
    for(var i=0; i<array.length; i++){
      if(array[i] !== undefined && array[i] !== null && array[i] !== ''){
        if(array[i][key] !== undefined && array[i][key] !== null && array[i][key] === value){
          index = i;
          break;
        }
      }
    }
    return index;
  }

  export function object_key_in_array(array, key) {
    var index = -1;
    for (var i = 0; i < array.length; i++) {
      if (array[i] !== undefined && array[i] !== null && array[i] !== '') {
        index = i;
      }
    }
    return index;
  }

  export function search_text_in_array(array, text){
    var index = -1;
    for (var i = 0; i < array.length; i++) {
      if(array[i].indexOf(text) > -1)
        return i;
    }
    return index;
  }

  export function hideFieldByUser(userInfo, page, field){
    if(Object.keys(userInfo).length > 0){
      var pagesFields = {};

      if(userInfo.CCLCR === 850034 && userInfo.NLIVE !== null && (userInfo.NLIVE >= 0 && userInfo.NLIVE <= 10)){
        pagesFields = {
          'my-profile': ['email_recupero']
        };
        return (pagesFields[page][pagesFields[page].indexOf(field)].length > -1);
      }else{
        return false;
      }
    }
  }


  export function uploadFileToBucket(mythis, signedUrl, file, key = 0, total_keys = 0) {
    return new Promise((resolve, reject) => {
      const $ = window.$;

      const xhr = new window.XMLHttpRequest();
      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', file.type);

      $(".divInfoFileLoaded, .divRemoveFileConverted").css({"margin-bottom": "16px", "transition": "margin 0.5s ease-in-out"});

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          var complete = (event.loaded / event.total * 100 | 0);
          $('.progress-bar_' + (key+1)).css('width', complete + '%').html('&nbsp;');
          $('.removeFileConverted').eq(key).addClass('statusTrue');
          if (complete === 100) {
            file.status = true;
            if (window.uploadedFiles && window.uploadedFiles[key]) {
              window.uploadedFiles[key].status = true;
            }
            window.fileCompleted++;
            if (total_keys > 0 && window.fileCompleted === total_keys) {
              mythis.setState({ fine: true });
            }
            resolve(file);
          }
        }
      });

      xhr.upload.addEventListener("error", (event) => {
        $('.progress-bar_' + (key+1)).css({ 'width': '100%', 'background-color': '#dc3545' }).html('&nbsp;');
        $('.removeFileConverted').eq(key).addClass('statusFalse');
        file.status = false;
        if (window.uploadedFiles && window.uploadedFiles[key]) {
          window.uploadedFiles[key].status = false;
        }
        reject(file.name);
      });

      xhr.send(file);
    });
  }

  export function customCreators(creators){
    return creators.map(c => c[1]);
  }


  export function bindDragScroll($container, $scroller) {
    const $ = window.$;
    var $window = $(window);

    var x = 0;
    var y = 0;

    var x2 = 0;
    var y2 = 0;
    var t = 0;

    $container.on("mousedown", down);
    $container.on("click", preventDefault);
    $scroller.on("mousewheel", horizontalMouseWheel); // prevent macbook trigger prev/next page while scrolling

    function down(evt) {
      //alert("down");
      if (evt.button === 0) {

        t = Date.now();
        x = x2 = evt.pageX;
        y = y2 = evt.pageY;

        $container.addClass("down");
        $window.on("mousemove", move);
        $window.on("mouseup", up);

        evt.preventDefault();

      }

    }
    
    function move(evt) {
      // alert("move");
      if ($container.hasClass("down")) {
        var _x = evt.pageX;
        var _y = evt.pageY;
        var deltaX = _x - x;
        var deltaY = _y - y;
        
        if ((!$container.hasClass("dragging")) && (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10))
          $container.addClass("dragging");
        
        $scroller[0].scrollLeft -= deltaX;

        x = _x;
        y = _y;

      }

    }

    function up(evt) {

      $window.off("mousemove", move);
      $window.off("mouseup", up);

      var deltaT = Date.now() - t;
      var deltaX = evt.pageX - x2;
      var deltaY = evt.pageY - y2;
      if (deltaT <= 300) {
        $scroller.stop().animate({
          scrollTop: "-=" + deltaY * 3,
          scrollLeft: "-=" + deltaX * 3
        }, 500, function (x, t, b, c, d) {
          // easeOutCirc function from http://gsgd.co.uk/sandbox/jquery/easing/
          return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
        });
      }

      t = 0;

      $container.removeClass("down");
      if ($container.hasClass("dragging")){
        $container.removeClass("dragging");
        $container.addClass("dragged");
      }

    }

    function preventDefault(evt) {
      if (x2 !== evt.pageX || y2 !== evt.pageY) {
        evt.preventDefault();
        return false;
      }
    }

    function horizontalMouseWheel(evt) {
      evt = evt.originalEvent;
      var x = $scroller.scrollLeft();
      var max = $scroller[0].scrollWidth - $scroller[0].offsetWidth;
      var dir = (evt.deltaX || evt.wheelDeltaX);
      var stop = dir > 0 ? x >= max : x <= 0;
      if (stop && dir) {
        evt.preventDefault();
      }
    }

  }

export function clearCache(){
  db.emptyCacheDB();
}

export function urlify(text) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  let urls = text.match(urlRegex);
  let out = [];
  let initialPos = 0;
  for(let i in urls){
    let url = urls[i];
    let pos = text.indexOf(url);
    let target = "_self";
    if (is_external_url(url))
      target = "_blank";
    out.push(text.substring(initialPos, pos));
    initialPos = pos + url.length;
    out.push(<a href = {url} target = {target}>{url}</a>)
  }
  if (out.length == 0)
    out = [text];
  return out;
}

export function is_external_url(url){
  if (url.indexOf(env.FRONTEND) > -1)
    return false;
  return true;
}

export function beautifyShortDescription(shortDescription){
  return shortDescription.split("\n").map(function (item, key) {
    return (
      <span key={key} style={{ display: "initial" }}>
        {urlify(item)}
        <br />
      </span>
    )
  });
}
/* 4. Utility Functions END */



/* ================================================================================================================================================== */



/* 5. Local Storage Managers */
  export function get_localStorage(name, padding = localStorage_padding(), defaultValue=undefined){
    /*
     * This function is used to get data from localStorage.
     * It's necessary because we could ave multiple app on the same domain name,
     * so the local storage wouldn't be different between the different application.
     * To solve this, this function get only the local storage parameters saved as:
     * parameter_name##application_base_path , where in application_base_path / are replaceed by _
     * Params:
     * name -> String, required. The parameter name without basepath suffix
     * Returning:
     * The local storage parameter value
     */
    var ret = localStorage[name + padding];
    if(ret === undefined)
      return defaultValue;
    else  
      return ret;
  }

  export function set_localStorage(name, value){
    /*
     * This function is used to save data in local storage.
     * Obiective and rules are the same of the get function.
     * Params:
     * name -> String, required. The parameter name without basepath suffix
     * value -> Number or string, required. The value to save in local storge
     */
    const padding = localStorage_padding();
    return localStorage.setItem(name + padding, value);
  }

  export function remove_localStorage(name){
    /*
     * This function is used to delete an item from local storage
     * Obiective and rules are the same of the get and set function.
     * Params:
     * name -> String, required. The parameter name without basepath suffix
     */
    const padding = localStorage_padding();
    return localStorage.removeItem(name + padding);
  }

  export function clear_localStorage(excludeKeys=[]){
    /*
     * Remove all localStorage items for this portal.
     * It remove all localstorge that has the same padding
     */
    const padding = localStorage_padding();
    var keys=Object.keys(localStorage);

    for (let i = 0; i < excludeKeys.length; i++) {
      excludeKeys[i] = excludeKeys[i] + padding;
    }

    for (let i = 0; i < keys.length; i++){
      if(keys[i].search(padding) > -1 && excludeKeys.indexOf(keys[i]) <= -1)
        localStorage.removeItem(keys[i]);
    }
  }

  function localStorage_suffix(){
    /*
     * Returns the localstorge standard suffix.
     * It's calculated replacing basepath slashes (/) with underscore (_)
     */
    return env.BASEPATH.replace("/", "_");
  }

  export function localStorage_padding(){
    /*
     * Returns the usual padding for localstorage.
     * It's calculated adding two ashes (##) before the localStorage_suffix return
     */
    return "##" + localStorage_suffix();
  }

  export function find_localStorage(name){
    var ret = {};

    var keys=Object.keys(localStorage);

    for (var i = 0; i < keys.length; i++){
      if(keys[i].search(name + "##") > -1){
        var suffix = keys[i].replace(name + "##", "");
        ret[suffix] = localStorage[keys[i]];
      }
    }

    return ret;
  }

/* 5. Local Storage Managers END */



/* ================================================================================================================================================== */


/* 6. Cookies functions */

  export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

/* 6. Cookies functions */


/* ================================================================================================================================================== */



/* 7. Modelli */

  export async function getModelli(){
    /*
     * This function is used to get portal's models from backend.
     * To improve performance, this function save model list in local storage after the first time used.
     * No params Required
     * Returning:
     * False in case of failture
     * An array of objects in case of success
     */
    var modelli = false;
    if(get_localStorage("modelli") !== null && get_localStorage("modelli") !== undefined && get_localStorage("modelli") !== ""){
      try{
        modelli = JSON.parse(get_localStorage("modelli"))
      }catch(e){}
    }
    if(!modelli)
      modelli = await apiCall(env.BACKEND + "@get-modelli", "GET", {}, true).then((response) => {
        if(response.error === false){
          set_localStorage("modelli",JSON.stringify(response));
          return response
        }else{
          return false;
        }
      });
    return modelli;
  }

  export function getAppEnabledVM(){
    /*
     * This function is used to get from backend only enabled
     * Veicle Manufacturer for the app
     */
    let marche_attive = window.cc.marche_attive;

    if(!marche_attive){
      if(get_localStorage("userInfo") !== undefined)
        marche_attive = JSON.parse(get_localStorage("userInfo"))['marche_attive'];
    }

    return marche_attive;
  }

  export function getTicketTypesByBrand(){
    /*
     * This function is used to get from backend only enabled
     * Get all Tickets Brands available for specific user
     */
    let tipologie_ticket_attive = window.cc.tipologieTicket;

    if(!tipologie_ticket_attive){
      if(get_localStorage("userInfo") !== undefined)
        tipologie_ticket_attive = JSON.parse(get_localStorage("userInfo"))['tipologieTicket'];
    }

    return tipologie_ticket_attive;
  }

/* 7. Modelli END */

export default verifyLogin;
