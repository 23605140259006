const oldUrlMethod = "service";
const commonPrefix = "swan-";

// Array con custom url, per chi vuole accedere al portale tramite url non standard
// Non mettere mai www. iniziale (lo prevede già il programma), ma solo il dominio
const customUrls = {
  "epta-service.com" : "epta",
}
const portalInformations = {
  askoll: {
    csoft: '850026',
  },
  beta: {
    csoft: '850036',
    hasPartsfinder:   true,
  },
  'beta-uk': {
    csoft: '850301',
  },
  dip: {
    csoft: '850037',
  },
  energica: {
    csoft: '850020',
  },
  ekletta: {
    csoft: '850033',
  },
  epta: {
    csoft: '850034',
  },
  sym: {
    csoft: '850005',
  },
  kymco: {
    csoft: '850025',
  },
  mvagusta: {
    csoft: '850100',
  },
  qooder: {
    csoft: '850200',
  },
  zontes: {
    csoft: '850038',
  },
  zanoni: {
    csoft: '850041',
  },
  voge: {
    csoft: '850042',
  },
  motomorini: {
    csoft: '850030',
  },
  lifan: {
    csoft: '850043',
  },
  logicom: {
    csoft: '850044',
  },
  vmoto: {
    csoft: '850048',
  },
  m3: {
    csoft: '850049',
  },
  onex: {
    csoft: '850002',
  },
  pelpi: {
    csoft: '850050',
  },
  nito: {
    csoft: '850052',
  },
  vogescandinavia: {
    csoft: '850053',
  },
  motomoriniusa: {
    csoft: '850054',
  },
  qjmotor: {
    csoft: '850055',
  },
};

var basePath = window.location.protocol + "//" + window.location.host + "/" + window.location.pathname.split('/')[1];

const devOauth = {
  LOGIN_BACKEND: window.location.protocol + "//" + window.location.host + "/",
  BACKEND: basePath + "/backend/",
  BACKEND_ANSWER: basePath + "/backend/",
  FRONTEND: basePath + "/",
  STATIC: basePath + "/static/",
  MENU_ICONS_PATH: basePath + "/static/images/icone/",
  BASEPATH: "/" + window.location.pathname.split('/')[1] + "/",
  SWAN: window.location.protocol + "//" + window.location.host + "/swan/",
  SWDOMAIN: window.location.protocol + "//" + window.location.host,
  APP_NAME: "RMI Service",
  TABLE_PAGE_LENGTH: "25",
  PARTS_FINDER_ENABLED: false,
  PORTAL_NAME: "softway",
  CSOFT: 900000,
}

const proxied = {
  // configurazione per sviluppo utilizzando il proxy react
  // non necessita di apache, puntare direttamente a react nel browser es: http://localhost:3000
  // aggiungere la seguente riga in package.json
  //"proxy": "http://plone:8080",
  // e adeguare la variabile BACKEND e BACKEND_ANSWER facendole puntare al nome del sito Plone
  // tutte le richieste a BACKEND in reac verranno inviate in automatico al proxy
  // il proxy evitera' problemi di CORS, config apache ecc...
  BACKEND: "/snellman/snellmanrmi/",
  //BACKEND_ANSWER:  "http://plone:8080/backend/",
  BACKEND_ANSWER:  "http://plone:8080/snellman/snellmanrmi/",
  FRONTEND: window.location.protocol + "//" + window.location.host + "/",
  STATIC: window.location.protocol + "//" + window.location.host + "/static/",
  MENU_ICONS_PATH: window.location.protocol + "//" + window.location.host + "/static/images/icone/",
  BASEPATH: "/",
  SWAN: "http://localhost:8000/",
  SWDOMAIN: "http://localhost:8000/",
  APP_NAME: "RMI Service",
  TABLE_PAGE_LENGTH: "25",
  PARTS_FINDER_ENABLED: false,
  PORTAL_NAME: "softway",
  CSOFT: 900000,
}

const dev = {
  //LOGIN_BACKEND: Manca
  BACKEND: "http://localhost:8080/Plone6/",
  BACKEND_ANSWER: "http://localhost:8080/Plone6/",
  FRONTEND: window.location.protocol + "//" + window.location.host + "/",
  STATIC: window.location.protocol + "//" + window.location.host + "/static/",
  MENU_ICONS_PATH: window.location.protocol + "//" + window.location.host + "/static/images/icone/",
  BASEPATH: "/",
  SWAN: "http://localhost:8000/",
  SWDOMAIN: "http://localhost:8000/",
  APP_NAME: "RMI Service",
  TABLE_PAGE_LENGTH: "25",
  PARTS_FINDER_ENABLED: false,
  PORTAL_NAME: "softway",
  CSOFT: 900000,
}


const prod = {
  LOGIN_BACKEND: window.location.protocol + "//" + window.location.host + "/",
  BACKEND: basePath + "/backend/",
  BACKEND_ANSWER: basePath + "/backend/",
  FRONTEND: basePath + "/",
  STATIC: basePath + "/static/",
  MENU_ICONS_PATH: basePath + "/static/images/icone/",
  BASEPATH: "/" + window.location.pathname.split('/')[1] + "/",
  SWDOMAIN: window.location.protocol + "//" + window.location.host,
  SWAN: window.location.protocol + "//" + window.location.host + "/swan/",
  APP_NAME: "RMI Service",
  TABLE_PAGE_LENGTH: "25",
  PARTS_FINDER_ENABLED: false,
  PORTAL_NAME: "softway",
  CSOFT: 900000,
}

var config = {}, tmp = false, portalName = window.location.host.split(".")[0];
if(portalName.startsWith(commonPrefix))
  portalName = portalName.replace(new RegExp(commonPrefix,""), "");

var locationHost = window.location.host;
if(locationHost.startsWith("www."))
  locationHost.replace(/www./, "");
if(locationHost in customUrls)
  portalName = customUrls[locationHost];

if(process.env.NODE_ENV === "production"){
  config = prod;
  config.PRODUZIONE = true;
}else{
  try{
    config = eval(process.env.REACT_APP_PERSONAL_ENV);
  }catch(e){
    config = dev;
  }
  if(typeof(config) !== "object" || config.FRONTEND === undefined || config.FRONTEND === null || config.FRONTEND === '')
    config = dev;
  if(portalName === undefined || portalName === null)
    portalName = config.PORTAL_NAME;
  else
    config.PORTAL_NAME = portalName;
  config.PRODUZIONE = false;
}

config.OLD_METHOD = window.location.host.startsWith(oldUrlMethod);
if(config.OLD_METHOD){

  config.LOGIN_BACKEND = undefined;
  config.PORTAL_NAME = window.location.pathname.split('/')[1];
  portalName = config.PORTAL_NAME;

  config.BACKEND = basePath + "/backend/";
  config.BACKEND_ANSWER = basePath + "/backend/";
  config.FRONTEND = basePath + "/";
  config.STATIC = basePath + "/static/";
  config.MENU_ICONS_PATH = basePath + "/static/images/icone/";
  config.BASEPATH = "/" + window.location.pathname.split('/')[1] + "/";
  config.MENU_ICONS_PATH = config.STATIC + "/images/icone/";
  config.SWDOMAIN = window.location.protocol + "//" + window.location.host;
  config.SWAN = basePath + "/swan/";
}

if(config.MISSING_TRANSLATIONS_ALERT !== undefined && config.MISSING_TRANSLATIONS_ALERT !== null)
  config.MISSING_TRANSLATIONS_ALERT = !config.PRODUZIONE;
if(!config.PRODUZIONE)
  config.APP_NAME = "!!TEST MODE!! " + config.APP_NAME;

tmp = get_portal_information(portalName, "csoft");
config.CSOFT = (tmp !== false ? tmp : 900000);
tmp = get_portal_information(portalName, "hasPartsfinder");
config.PARTS_FINDER_ENABLED = tmp;

if(config.PARTSFINDER === undefined || config.PARTSFINDER === null || config.PARTSFINDER === ""){
  if(config.OLD_METHOD){
    if(window.location.host.search("localhost") > -1)
      config.PARTSFINDER = window.location.protocol + "//" + window.location.host;
    else{
      var actual_position = window.location.host.split(".");
      if(actual_position === "service-test")
        config.PARTSFINDER =  "http://" + "partsfinder-test";
      else
        config.PARTSFINDER =  "http://" + "partsfinder";

      for(var i = 1; i<actual_position.length; i++ )
        config.PARTSFINDER = config.PARTSFINDER + "." + actual_position[i];
      config.PARTSFINDER = config.PARTSFINDER + "/" + window.location.pathname.split('/')[1];
    }
  }else{
    config.PARTSFINDER = window.location.protocol + "//" + window.location.host + "/partsfinder/";
  }
}


function get_portal_information(portalName, infoName){
  if(portalInformations[portalName] !== undefined && portalInformations[portalName][infoName] !== undefined && portalInformations[portalName][infoName] !== null)
    return portalInformations[portalName][infoName]

  return false;
}


export default {
  ...config,
  languageName: {it: "Italiano", en: "English", de: "Deutsch", es: "Español", fr: "Français"},
  labels: {},
  tipologieTicket: {},
}
