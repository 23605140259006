import React from 'react';
import PropTypes from 'prop-types';
import Prompt from '../common/Prompt';
import moment from 'moment';
import jQuery from 'jquery';
import bootbox from 'bootbox';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.min.css';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import '../../css/base.css';
import '../../css/page-body.css';
import { Editor } from '@tinymce/tinymce-react';
import MultiSelectNazioni from '../MultiSelect/MultiSelectNazioni';
import MultiSelectModelli from '../MultiSelect/MultiSelectModelli';
import { createBrowserHistory } from "history";

var tus = require("tus-js-client")
const common = require('../common/common');
const env = require("../../config.js").default;

window.jQuery = jQuery;
try {
  require("bootstrap-datepicker/js/locales/bootstrap-datepicker."+ common.getLanguageDatePicker() +".js");
  require ('select2/dist/js/i18n/'+ common.getLanguage(false) +'.js');
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
  require ('select2/dist/js/i18n/en.js');
  require("bootstrap-datepicker/js/locales/bootstrap-datepicker.en-GB.js");
}

class EditContentDefault extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props){
    super(props);
    this.state = {};
    this.state.render = false;
    this.state.pageTitle = this.props.pageTitle;
    this.state.pageContent = {};
    this.state.modelli = [];
    this.state.nazioniPortale = [];
    this.state.pageContent.description = [];
    this.state.pageContent.description.parent = {};
    this.state.pathname = this.props.pathname;
    window.contentSaved = true;
    this.state.lingueSelezionate = window.cc.languages;
    this.state.lingueEdit = [];
    this.state.tinyEditText = {};
    this.state.duplicate = false;
    this.state.duplicateAttuale = false;
    if(!this.state.pageContent.is_folderish){
      this.state.tinyEditText[this.props.pageContent.language] = this.props.pageContent.text ? this.props.pageContent.text.data : "";
    }
    this.state.selectedItems = [];
    this.state.selectedItemsModelli = [];
    this.handleSubmit = this.handleSubmit.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleClickRemoveFile = this.handleClickRemoveFile.bind(this);
    this.uploadPercentages = {};
    this.state.marche_attive = common.getAppEnabledVM();
  }

  handleEditorChange = (content, editor) => {
    var lingua = editor.id.split("_")[1];
    var tinyEditText = this.state.tinyEditText;
    tinyEditText[lingua] =  content;
    this.setState({ tinyEditText: tinyEditText});
  }

  UNSAFE_componentWillMount(){
    var languagesAll = common.get_localStorage("customConfig");
    if(languagesAll !== undefined){
      var langsJSON = JSON.parse(languagesAll);
      this.setState({linguePortale: langsJSON.languages});
    }

    try{
      this.props.pageContent.description = JSON.parse(this.props.pageContent.description);
    }catch(e){}

    if(this.props.pageContent.video !== undefined && this.props.pageContent.video && this.props.pageContent.video !== null && this.props.pageContent.video !== "")
      try{
        this.props.pageContent.video = JSON.parse(this.props.pageContent.video);
      }catch(ex){ }


    this.setState({pageContent :  this.props.pageContent});
    this.setState({pathname : this.props.pathname});

    common.getModelli().then((modelli) => {
      if(modelli){
        var gruppo = [];
        var modelli_gruppo = [];
        for (var i=0; i< modelli.length; i++){
          var index = gruppo.findIndex(x => x.id_group === modelli[i]['group']);
          if(index === -1 && modelli[i] !== 'error'){
            var num = common.object_value_in_array(window.cc.marche_portale, "ZFORN", modelli[i]['group'])
            gruppo.push({'id_group': modelli[i]['group'], 'gruppo': window.cc.marche_portale[num]['DFORN'], 'children': []})
          }

          var indexModello = modelli_gruppo.findIndex(x => x.value === modelli[i]['id']);
          if(indexModello === -1 && modelli[i] !== 'error'){
            modelli_gruppo.push({'id': modelli[i]['id'], 'modello': modelli[i]['modello'], 'group': modelli[i]['group'], 'disabled': modelli[i]['disabled']});
          }
        }
				for (var g = 0; g < gruppo.length; g++) {
				  for (var m = 0; m < modelli_gruppo.length; m++) {
				    if(gruppo[g]['id_group'] === modelli_gruppo[m]['group'])
				      gruppo[g]['children'].push(modelli_gruppo[m])
				  }
				}

				this.setState({modelli: gruppo});
 			}
    });
    document.getElementById('body').className='new_content';
  }

  componentDidMount(){
    const $ = window.$;

    $('a[data-toggle="pill"]').on('shown.bs.tab', (e) => {
      var target = $(e.target).attr("data-flag");
      $("#title_" + target).focus();
    });

    this.setState({nazioniPortale: common.getNazioniPortale(false)});
    if(this.state.pageContent.is_folderish){
      if(this.state.pageContent.description.modelRequired !== 'true'){
        $('select').val('tutti_modelli').trigger('change');
      }
    }

    /*inserimento */
    $("input").change((myInput) => {
      window.contentSaved = false;
    });

    $("textarea").change((myText) => {
      window.contentSaved = false;
    });

    // upload file custom javascript
    ;( function ( document, window, index )
    {
    	var inputs = document.querySelectorAll( '.inputfile' );
    	Array.prototype.forEach.call( inputs, function( input )
    	{
    		var label	 = input.nextElementSibling,
    		labelVal = label.innerHTML;

    		input.addEventListener( 'change', function( e )
    		{
    			var fileName = '';
    			if( this.files && this.files.length > 1 )
    			fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
    			else
    			fileName = e.target.value.split( '\\' ).pop();

    			if( fileName )
    			label.querySelector( 'span' ).innerHTML = fileName;
    			else
    			label.innerHTML = labelVal;
    		});

    		// Firefox bug fix
    		input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
    		input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
    	});
    }( document, window, 0 ));

    $('#printButton').on('click' , function(){
        window.print();
    });
    document.title = document.title = window.cc.companyInfo.service + " Service";
    // check lingue attive dove caricare il contenuto
    $(".c-inputs-stacked input").prop('checked', true);

    $("#seleziona_tutti").on("keyup click", (e) => {
      var lingue_selezionate = this.state.lingueSelezionate;
      var checked = $(e.target).is(":checked");

      $("input.lingue_selezionate").each(function(){
        $(this).prop("checked", checked);
        if(checked){
          if(lingue_selezionate.indexOf($(this)[0].id) <= -1) {
            lingue_selezionate.push($(this)[0].id);
          }
        }
      });
      //
      if(!checked){
        lingue_selezionate = [];
      }

      if($("#pills-tab .nav-link").hasClass("disabled")){
        $("#pills-tab .nav-link").removeClass("disabled");
      }else{
        if($(".lingue_selezionate:checked").length === 0){
          $("#pills-tab .nav-link").removeClass("active");
        }
        $("#pills-tab .nav-link").toggleClass("disabled");
      }
      $("#pills-tab .nav-link").each((i, elem) => {
        if(!$(elem).hasClass("disabled")){
          $("#pills-tab-"+ common.getLanguage()).click();
          return false;
        }
      });
      window.cc.languages = this.state.linguePortale;
      this.setState({lingueSelezionate: lingue_selezionate});
    });

		var stato_evidenza = $('#stato_evidenza');
		$('#evidenza').on( 'click', function () {
			if ($('#evidenza').is(':checked')){
				stato_evidenza = stato_evidenza.html(common.getTrad('Si'));
			}else{
				stato_evidenza = stato_evidenza.html(common.getTrad('No'));
			}
		});

 		var oggi = "";
    var oggiPopup = "";
    var oggiEvidenza = "";
    //is_folderish quando creo un nuovo contenuto
    if(this.state.pageContent.is_folderish){
      oggi = new Date();
      oggiPopup = new Date();
      oggiEvidenza = new Date();
      this.handlePopup()
      this.handleEvidenza()
    //modifico un contenuto esistente
    }else{
      oggi = this.state.pageContent.effective;
      oggiPopup = this.state.pageContent.effective_popup_method === null ? oggiPopup = new Date() : this.state.pageContent.effective_popup_method
      oggiEvidenza = this.state.pageContent.effective_evidenza_method === null ? oggiEvidenza = new Date() : this.state.pageContent.effective_evidenza_method
    }
    var domani = moment(oggi).add(1, 'days').format('L');
    var domaniPopup = moment(oggiPopup).add(1, 'days').format('L');
    var domaniEvidenza = moment(oggiEvidenza).add(1, 'days').format('L');
    var defaultEndDate = null;
    if(!this.state.pageContent.is_folderish && $("#expires").val() != ''){
      var startdate_expires = $("#expires").val();
      var nuova_data_expires = moment(startdate_expires, "L");
      defaultEndDate = nuova_data_expires.subtract(1, 'days').format('L');
    }

    $("#effective").datepicker({
			startDate: this.state.pageContent.is_folderish ? oggi.toDateString() : null,
			endDate: defaultEndDate,
			weekStart: 1,
			todayBtn: "linked",
			language: common.getLanguageDatePicker(),
			autoclose: true,
			todayHighlight: true
		});

    $("#effective_popup").datepicker({
      startDate: this.state.pageContent.is_folderish ? oggiPopup.toDateString() : null,
      endDate: defaultEndDate,
      weekStart: 1,
      todayBtn: "linked",
      language: common.getLanguageDatePicker(),
      autoclose: true,
      todayHighlight: true
    });

    $("#effective_evidenza").datepicker({
      startDate: this.state.pageContent.is_folderish ? oggiEvidenza.toDateString() : null,
      endDate: defaultEndDate,
      weekStart: 1,
      todayBtn: "linked",
      language: common.getLanguageDatePicker(),
      autoclose: true,
      todayHighlight: true
    });

    $("#expires").datepicker({
      startDate: domani,
      weekStart: 1,
      todayBtn: false,
      language: common.getLanguageDatePicker(),
      autoclose: true,
      todayHighlight: true
    });

    $("#expires_popup").datepicker({
      startDate: domaniPopup,
      weekStart: 1,
      todayBtn: false,
      language: common.getLanguageDatePicker(),
      autoclose: true,
      todayHighlight: true
    });

    $("#expires_evidenza").datepicker({
      startDate: domaniEvidenza,
      weekStart: 1,
      todayBtn: false,
      language: common.getLanguageDatePicker(),
      autoclose: true,
      todayHighlight: true
    });

    $("#effective, #expires").on('keyup changeDate', function (e) {
      var startdate = $("#effective").val();
      var nuova_data = moment(startdate, "L");
      var expires_new = nuova_data.add(1, 'days').format('L');

      var startdate_expires = $("#expires").val();
      var nuova_data_expires = moment(startdate_expires, "L");
      var effective_last = nuova_data_expires.subtract(1, 'days').format('L');

      $('#expires').datepicker('setStartDate', expires_new);
      if($('#expires').val() !== '' && $('#expires').val() !== undefined && $('#expires').val() !== null)
        $('#effective').datepicker('setEndDate', effective_last);
      else
        $('#effective').datepicker('setEndDate', null);
    })

    $("#effective_popup, #expires_popup").on('keyup changeDate', function (e) {
      var startdate = $("#effective_popup_method").val();
      var nuova_data = moment(startdate, "L");
      var expires_new = nuova_data.add(1, 'days').format('L');

      var startdate_expires = $("#expires_popup").val();
      var nuova_data_expires = moment(startdate_expires, "L");
      var effective_last = nuova_data_expires.subtract(1, 'days').format('L');

      $('#expires_popup').datepicker('setStartDate', expires_new);
      if ($('#expires_popup').val() !== '' && $('#expires_popup').val() !== undefined && $('#expires_popup').val() !== null)
        $('#effective_popup').datepicker('setEndDate', effective_last);
      else
        $('#effective_popup').datepicker('setEndDate', null);
    })

    $("#effective_evidenza, #expires_evidenza").on('keyup changeDate', function (e) {
      var startdate = $("#effective_evidenza").val();
      var nuova_data = moment(startdate, "L");
      var expires_new = nuova_data.add(1, 'days').format('L');

      var startdate_expires = $("#expires_evidenza").val();
      var nuova_data_expires = moment(startdate_expires, "L");
      var effective_last = nuova_data_expires.subtract(1, 'days').format('L');

      $('#expires_evidenza').datepicker('setStartDate', expires_new);
      if ($('#expires_evidenza').val() !== '' && $('#expires_evidenza').val() !== undefined && $('#expires_evidenza').val() !== null)
        $('#effective_evidenza').datepicker('setEndDate', effective_last);
      else
        $('#effective_evidenza').datepicker('setEndDate', null);
    })

    $('#annulla').on('click', (e) => {
      const history = createBrowserHistory();
      e.preventDefault();
      common.goBack(history);
    });

    var nazioniPortale = common.getNazioniPortale(false);
    var items = [];
    nazioniPortale.map((index, key) => {
      items.push({id: "sw-nazione-" + index.CISO3TNA, text: index.DNAZITNA});
      return false;
    });
    this.setState({nazioniPortale: items})


    $(".lingue_selezionate").on("keyup click", (e) => {
      var checked = $(e.target).is(":checked");
      var thisLanguage = e.target.id;
      var lingue_selezionate = this.state.lingueSelezionate;
      var lingue_edit = this.state.lingueEdit;

      if(checked){
        if(lingue_selezionate.indexOf(thisLanguage)<=-1)
          lingue_selezionate.push(thisLanguage);
      }
      else
        if(lingue_selezionate.indexOf(thisLanguage)>-1)
          lingue_selezionate.splice(lingue_selezionate.indexOf(thisLanguage), 1);

      if(!checked){
        if(lingue_edit.indexOf(thisLanguage)>-1)
          lingue_edit.splice(lingue_edit.indexOf(thisLanguage), 1);
      }

      if(!checked){
        $("#pills-tab-" + thisLanguage + ".nav-link").addClass("disabled");
        $("#pills-tab .nav-link").each((i, elem) => {
          if(!$(elem).hasClass("disabled")){
            $(elem).click();
            return false;
          }
        });
      }else{
        $("#pills-tab-" + thisLanguage + ".nav-link").removeClass("disabled");
      }

      if($(".lingue_selezionate").length === lingue_selezionate.length){
        $("#seleziona_tutti").prop('checked', true);
      }else{
        $("#seleziona_tutti").prop('checked', false);
      }

      if(checked){
        $("#pills-tab-" + thisLanguage).click();
      }
      if(lingue_selezionate.length === 0){
        $("#pills-tab .nav-link").removeClass("active");
      }

      window.cc.languages = this.state.linguePortale;
      this.setState({lingueSelezionate: lingue_selezionate, lingueEdit: lingue_edit});
    });

    /* Modifica */
    if(!this.state.pageContent.is_folderish){
      this.setState({lingueEdit: [common.getLanguage()]});
      $("#pills-tab .nav-link").click((e) => {
        var lingueEdit = this.state.lingueEdit;
        var lingue_selezionate = this.state.lingueSelezionate;
        var textEditorEdit = this.state.tinyEditText;
        this.state.pageContent['@components'].translations.items.map((index, key) => {
          if(index.language === $(e.target).data('flag')){
            // PRENDO I dati del modifica della singola lingua
            var data = {"path.depth": 1, "expand": "translations"};
            common.apiCall(common.getBackendUrl(index['@id']), "GET", data, true)
            .then(editContent=>{
        			if(editContent.error === false){
                $(".tab-pane.fade").removeClass("show active");
                $("#pills-content-" + index.language).addClass("show active");
                if(!textEditorEdit[editContent.language] &&
                  !$("#title_" + index.language).val() &&
                  !$("#description_" + index.language).val() &&
                  !$("#image_caption_" + index.language).val())
                {
                  $("input#title_"+ index.language).val(editContent.title);
                  $("textarea#description_"+ index.language).val(editContent.description);
                  $("input#image_caption_"+ index.language).val(editContent.image_caption);
                  textEditorEdit[editContent.language] = editContent.text ? editContent.text.data : "";
                  this.setState({tinyEditText: textEditorEdit});
                }
              }
        		});
          }
          return false;
        });

        if(lingueEdit.indexOf($(e.target).data('flag')) <= -1 && lingue_selezionate.indexOf($(e.target).data('flag')) > -1){
          lingueEdit.push($(e.target).data('flag'));
        }

        this.setState({lingueEdit: lingueEdit});
      });

      // resetto le checkbox delle lingue (di default in fase di inserimento tutte le voci sono selezionate.)
      $("input.lingue_selezionate:checkbox").prop('checked', false);
      $(".c-inputs-stacked input").prop('checked', false);
      $("#pills-tab .nav-link").toggleClass("disabled");

      var subjects;
      subjects = this.props.pageContent.subjects;
      // ciclo i dati che trovo nel subjects in formato JSON
      for (var i = 0, len = subjects.length; i < len; i++) {
        // Se l'evidenza e il popup non hanno le date impostate (contenuti creati pre rilascio 1.26.0) 
        // imposto come data la data di pubblicazione
        if (subjects[i] === "sw_in_evidenza" && $('#effective_evidenza').val() === '') {
          $('#effective_evidenza').val(moment(this.state.pageContent.effective).format('L'));
        }
        if (subjects[i] === "sw_popup" && $('#effective_popup').val() === '') {
          $('#effective_popup').val(moment(this.state.pageContent.effective).format('L'));
        }
        if(subjects[i] === "sw-lang-all"){
          $(".c-inputs-stacked input:checkbox").prop('checked', true);
          $("#pills-tab .nav-link").toggleClass("disabled");
        }else{
          $("input:checkbox[value='"+ subjects[i] + "']").prop('checked', true);
          $("#item_"+ subjects[i] + " input:checkbox[value='"+ subjects[i] + "']").prop('checked', true);
          $("#pills-tab-" + (subjects[i]).split("sw-lang-")[1] + ".nav-link").toggleClass("disabled");
         }
      }
      this.handlePopup()
      this.handleEvidenza()
      // ripristino le nazioni in fase di modifica
      // devo rifare la chiamata perché in this.state.nazioniPortale non ci sono ancora ...
      var nazioniPortaleEdit = common.getNazioniPortale(false);
      var selectedItems = [];
      if(subjects.indexOf("sw-nazione-tutte") > -1){
        selectedItems.push("sw-nazione-tutte");
      }else{
        nazioniPortaleEdit.map((index, key) => {
          if(subjects.indexOf("sw-nazione-" + index.CISO3TNA) > -1)
            selectedItems.push("sw-nazione-" + index.CISO3TNA); // trovare il modo per prendere il nome della nazione corretto
        });
      }

      var jsonFile = this.state.pageContent.file;
      var jsonImage = this.state.pageContent.image;
      var pageContent = this.state.pageContent;
      var numItem = 0;
      if(this.state.pageContent.relatedItems !== undefined && this.state.pageContent.relatedItems.length > 0){
        numItem = common.object_value_in_array(this.state.pageContent.relatedItems, "@type", "File");
        if(numItem > -1){
          jsonFile = {
            filename: this.state.pageContent.relatedItems[numItem]['title'],
            size: 0,
          }
          common.apiCall(this.state.pageContent.relatedItems[numItem]['@id'], "GET", {}, true, false, "json")
          .then(data=>{
            jsonFile['size'] = data.file.size;
            pageContent.file = jsonFile;
            this.setState({pageContent: pageContent});
          });
        }

        numItem = common.object_value_in_array(this.state.pageContent.relatedItems, "@type", "Image");
        if(numItem > -1){
          jsonImage = {
            filename: this.state.pageContent.relatedItems[numItem]['title'],
            size: 0,
          }
          common.apiCall(this.state.pageContent.relatedItems[numItem]['@id'], "GET", {}, true, false, "json")
          .then(data=>{
            jsonImage['size'] = data.image.size;
            pageContent.image = jsonImage;
            this.setState({pageContent: pageContent});
          });
        }
        pageContent.file = jsonFile;
        pageContent.image = jsonImage;

        this.setState({pageContent: pageContent});
      }

      var modelli = common.get_localStorage("modelli") !== undefined ? JSON.parse(common.get_localStorage("modelli")) : this.state.modelli;
      var selectedItemsModelli = [];
      if(subjects.indexOf("tutti_modelli") > -1){
        selectedItemsModelli.push("tutti_modelli");
      }else{
        for (var m=0; m< window.cc.marche_portale.length; m++){
          if(subjects.indexOf(window.cc.marche_portale[m].ZFORN + "_tutti_modelli") > -1){
            selectedItemsModelli.push(window.cc.marche_portale[m].ZFORN + "_tutti_modelli");
          }
        }
        for (var i=0; i< modelli.length; i++){
          if(subjects.indexOf(modelli[i].id) > -1){
            selectedItemsModelli.push(modelli[i].id);
          }
        }
        // si verifica quando l'utente entra in un contenuto e cambia lingua
        // successivamente rientra nel contenuto | trac #3998
        if (modelli.length === 0 && selectedItemsModelli.length === 0) {
          for (var m = 0; m < window.cc.marche_portale.length; m++) {
            const marca = window.cc.marche_portale[m].ZFORN;
            const filteredValues = subjects.filter(value => value.startsWith(marca + '-'));
            selectedItemsModelli.push(...filteredValues);
          }
        }
      }

      this.setState({selectedItems: selectedItems, selectedItemsModelli: selectedItemsModelli});

      var linguePreSelezionate = [];
      subjects.map((index, key) => {
        if(subjects.indexOf("sw-lang-all") <= -1){
          if(index.search("sw-lang") > -1){
            linguePreSelezionate.push(index.split("sw-lang-")[1]);
          }
        }
      });

      if(subjects.indexOf("sw-lang-all") > -1)
        linguePreSelezionate = window.cc.languages;

      if(!$("#pills-tab-" + common.getLanguage()).hasClass("disabled"))
        $("#pills-tab-" + common.getLanguage()).click();
      else {
        $("#pills-tab-" + this.state.pageContent.language).click();
      }

      this.setState({lingueSelezionate: linguePreSelezionate});

      $("#pills-tab .nav-link").removeClass("translationCopy");
      $("#pills-tab .nav-link.active").addClass("translationCopy");
      $("#pills-tab-"+ this.state.pageContent.language).addClass("translationCopy");
      this.state.pageContent['@components'].translations.items.map((index, key) => {
        $("#pills-tab-"+ index.language).addClass("translationCopy");
      });
      // this.setState({numeroLingue: lingueSelezionate.length});

    }

    if(this.state.pageContent.is_folderish){
    // check per sapere se far comparire il bootbox della duplicazione
      $("form").on("keyup change",(e) => {
        e.preventDefault();
        if (
          $('.tab-pane.fade.active.show input[name=title]').val() || $('.tab-pane.fade.active.show input[name=image_caption]').val()
          || $('.tab-pane.fade.active.show textarea[name=text]').val() || $('.tab-pane.fade.active.show textarea[name=description]').val()){
          this.setState({duplicateAttuale: true});
        }else{
          this.setState({duplicateAttuale: false});
        }
      });
    }
  }

  componentDidUpdate(){
    const $ = window.$;
    if(!this.state.pageContent.is_folderish){
      var subjects;
      subjects = this.props.pageContent.subjects;
      $('.seleziona_modelli').val(subjects).trigger("change");
    }
  }
  
  handlePopup = () => {
    const $ = window.$;
    if( $('#popup').is(":checked") === false ) {
      $('#datepicker-popup').hide()
      $('#datepicker-popup #effective_popup').prop('required', false);
    } else {
      $('#datepicker-popup').show()
      $('#datepicker-popup #effective_popup').prop('required', true);
    }
  }

  handleEvidenza = () => {
    const $ = window.$;
    if ($('#evidenza').is(":checked") === false) {
      $('#datepicker-evidenza').hide()
      $('#datepicker-evidenza #effective_evidenza').prop('required', false);
    } else {
      $('#datepicker-evidenza').show()
      $('#datepicker-evidenza #effective_evidenza').prop('required', true);
    }
  }

  removeFile(fileType){
    const $ = window.$;
    var pageContent = this.state.pageContent;
    $("#remove" + fileType.charAt(0).toUpperCase() + fileType.slice(1)).val("1");
    pageContent[fileType] = false;
    this.setState({pageContent: pageContent});
  }

  getTitle(traduzioni, lingueSelezionate){
    var trad = lingueSelezionate[0].indexOf(this.state.pageContent.language) > -1 ?
                this.state.pageContent.language
              :
                lingueSelezionate[0]
    return (
      traduzioni[trad] !== undefined ?
        traduzioni[trad] && traduzioni[trad].title ?
          traduzioni[trad].title
        :
          traduzioni[lingueSelezionate[0]].title !== '' ?
            traduzioni[lingueSelezionate[0]].title
          :
            ""
      :
        traduzioni[common.getLanguage()].title

      );
  }

  async handleSubmit (e){
    e.preventDefault();
    const $ = window.$;
    var method = '', error = false;
    var lingueSelezionate = this.state.lingueSelezionate, lingueEdit = this.state.lingueEdit;
    var immagine = $("#image")[0].files[0];
    var file = $("#file")[0].files[0];
    var pathname = this.state.pathname, tinyEditText = this.state.tinyEditText, modelliSelezionati = this.state.selectedItemsModelli, nazioniSelezionate = this.state.selectedItems;

    if(!this.state.pageContent.is_folderish)
      method = "PATCH";
    else
      method = "POST";

    if (lingueSelezionate.length === 0){
      bootbox.alert(common.getTrad("Seleziona almeno una lingua per procedere con la pubblicazione."));
      return false;
    }

    for(var i = 0 ; i < lingueSelezionate.length && !error; i++){
      var lingua = lingueSelezionate[i], trovataLingua = false;

      if($("#title_"+lingua).val() === ""){
        if(method === "PATCH"){
          if(lingueEdit.indexOf(lingua) > -1)
            error = true;

          for(var j = 0; j < this.state.pageContent['@components'].translations.items.length; j++){
            var linguaTrans = this.state.pageContent['@components'].translations.items[j].language;
            if(lingua === linguaTrans){
              trovataLingua = true;
              break;
            }
          }
          error = !trovataLingua;
        }else
          error = true;
      }
    }

    if (error) {
      bootbox.alert(common.getTrad("Hai dimenticato di compilare il documento per le lingue attivate. Puoi riscriverlo, duplicarlo da quello che hai inserito oppure disattivare la lingua."));
      return false;
    }

    //Upload contenuto base
    var effective_iso = moment($('#effective').val(),'L'), expires_iso = '';
    var effective_popup_iso = '', expires_popup_iso = '';
    var effective_evidenza_iso = '', expires_evidenza_iso = '';
    var in_evidenza='', popup = '', lingue_selezionate = [], modelli_selezionati = [], data, traduzioni = {}, subject;

    if($('#expires').val() !== '')
      expires_iso = moment($('#expires').val(),'L').add(23,'hours').add(59,'minutes').add(59,'seconds');
    
    if ($('#effective_popup').val() !== '')
      effective_popup_iso = moment($('#effective_popup').val(), 'L');
    
    if ($('#expires_popup').val() !== '')
      expires_popup_iso = moment($('#expires_popup').val(), 'L').add(23, 'hours').add(59, 'minutes');

    if ($('#effective_evidenza').val() !== '')
      effective_evidenza_iso = moment($('#effective_evidenza').val(), 'L');

    if ($('#expires_evidenza').val() !== '')
      expires_evidenza_iso = moment($('#expires_evidenza').val(), 'L').add(23, 'hours').add(59, 'minutes');

    if ($('#evidenza').is(':checked'))
      in_evidenza = ['sw_in_evidenza'];

    if ($('#popup').is(':checked'))
      popup = ['sw_popup'];

    if ($('#seleziona_tutti').is(':checked'))
     lingue_selezionate.push('sw-lang-all');
    else
      $('.lingue_selezionate:checked').each(function (){ lingue_selezionate.push($(this).val()); });

    modelli_selezionati = modelliSelezionati;

    var marchePortale = window.cc.marche_portale;
    for (var i = 0; i < marchePortale.length; i++) {
      if($("#" + marchePortale[i].ZFORN + "_collapse_Original input:checked").length > 0)
        modelli_selezionati.push("sw-brand-" + marchePortale[i].ZFORN);
    }

    if(nazioniSelezionate.length <= 0){
      bootbox.alert(common.getTrad("Seleziona almeno una nazione per procedere con la pubblicazione."));
      return false;
    }

    if(modelli_selezionati <= 0){
      bootbox.alert(common.getTrad("Seleziona almeno un modello per procedere con la pubblicazione."));
      return false;
    }
    $("body #app .loadingDiv").fadeIn("fast");

    if(in_evidenza !== '')
      subject = lingue_selezionate.concat(in_evidenza,modelli_selezionati,nazioniSelezionate);
    else
      subject = lingue_selezionate.concat(modelli_selezionati,nazioniSelezionate);
    if(popup !== '')
      subject = subject.concat(popup);

    var arrayLingueSwData = lingueEdit;
    if(method === 'POST')
      arrayLingueSwData = lingueSelezionate;

    var mutexUp1 = new common.Mutex(), mutexUp2 = new common.Mutex();
    if(immagine && immagine !== null && immagine !== undefined && immagine !== "")
      await this.uploadFile(immagine, "Image", window.cc.assetsPath.images, mutexUp1);

    await mutexUp1.acquireQueued();

    if(file && file !== null && file !== undefined && file !== "")
      await this.uploadFile(file, "File", window.cc.assetsPath.files, mutexUp2);

    await mutexUp2.acquireQueued();

    arrayLingueSwData.map((indexData, keyData) => {
      traduzioni[indexData] = {
        title: $('#title_'+ indexData).val(),
        description: $('#description_'+indexData).val() ? $('#description_'+indexData).val() : '',
        text: {
          'content-type': "text/html",
          data: tinyEditText[indexData],
          encoding: "utf-8"
        },
        image_caption: $('#image_caption_'+indexData).val() ? $('#image_caption_'+indexData).val() : '',
      }
      return false;
    });

    data = {
      // default lingua interfaccia altrimenti prendo la prima lingua selezionata
      title: this.getTitle(traduzioni, lingueSelezionate),
      '@type': 'swcontent',
      effective: effective_iso !== '' ? moment(effective_iso).format('YYYY-MM-DD') : null,
      expires: expires_iso !== '' ? moment(expires_iso).format('YYYY-MM-DDTHH:mm:ss') : null,
      effective_popup_method: effective_popup_iso !== '' ? moment(effective_popup_iso).format('YYYY-MM-DD') : null,
      expires_popup_method: expires_popup_iso !== '' ? moment(expires_popup_iso).format('YYYY-MM-DD') : null,
      effective_evidenza_method: effective_evidenza_iso !== '' ? moment(effective_evidenza_iso).format('YYYY-MM-DD') : null,
      expires_evidenza_method: expires_evidenza_iso !== '' ? moment(expires_evidenza_iso).format('YYYY-MM-DD') : null,
      created: moment().format(),
      subjects: subject,
      rights: moment().format(),
      sw_data: traduzioni,
      sw_relatedItems: !this.state.pageContent.is_folderish ? this.state.pageContent.relatedItems : [],
      sw_relatedItemsToDelete: [],
    }

    var numFile = common.object_value_in_array(this.state.pageContent.relatedItems, "@type", "File");
    var numImage = common.object_value_in_array(this.state.pageContent.relatedItems, "@type", "Image");

    if(window.sw_file !== undefined && window.sw_file !== null){
      if(numFile > -1){
        data.sw_relatedItemsToDelete.push(data.sw_relatedItems[numFile]);
        data.sw_relatedItems[numFile] = {'type': 'files', 'file': window.sw_file};
      }else
        data.sw_relatedItems.push({'type': 'files', 'file': window.sw_file});
    }else{
      if(numFile > -1 && $("#removeFile").val() === "1"){
        data.sw_relatedItemsToDelete.push(data.sw_relatedItems[numFile]);
        data.sw_relatedItems.splice(numFile, 1);
      }
    }

    if(window.sw_image !== undefined && window.sw_image !== null){
      if(numImage > -1){
        data.sw_relatedItemsToDelete.push(data.sw_relatedItems[numImage]);
        data.sw_relatedItems[numImage] = {'type': 'images', 'file': window.sw_image};
      }else
        data.sw_relatedItems.push({'type': 'images', 'file': window.sw_image});
    }else{
      if (numImage > -1 && $("#removeImage").val() === "1" && data.sw_relatedItems.length == 1){
        data.sw_relatedItemsToDelete.push(data.sw_relatedItems[0]);
        data.sw_relatedItems.splice(0, 1);
      } else if (numImage > -1 && $("#removeImage").val() === "1" && data.sw_relatedItems.length == 2){
        data.sw_relatedItemsToDelete.push(data.sw_relatedItems[numImage]);
        data.sw_relatedItems.splice(numImage, 1);
      }
    }

    if($("#removeImage").val() === "1")
      data.image = "";
    if($("#removeFile").val() === "1")
      data.file = "";
    if($("#removeVideo").val() === "1")
      data.video = "";

    var style = '';
    if(!$('.banner_center_header').is(":visible")){
      style = "top:" + $("#portal-top").height() + "px";
    }else{
      style = '';
    }
    $(".bottoni_invio .btn-primary").prop("disabled",true);

    var pathnameEdit = '', pathnameUrl;
    if(traduzioni[common.getLanguage()] !== undefined  && traduzioni[common.getLanguage()].title !== undefined){
      pathnameEdit = pathname;
      pathnameUrl = common.getLanguage() + pathname.substring(3);
    }else if (Object.keys(traduzioni).indexOf(this.state.pageContent.language) > -1){
      pathnameEdit = pathname;
      pathnameUrl = this.state.pageContent.language + pathname.substring(3);
    }else{
      pathnameEdit = '/' + lingueSelezionate[0] + pathname.substring(3);
      pathnameUrl = '/' + lingueSelezionate[0] + pathname.substring(3);
    }
    if(pathnameEdit[0] === "/")
      pathnameEdit = pathnameEdit.substr(1)

    var apiDataMethod = "";
    if(method === "POST")
      apiDataMethod = "json";
    else
      apiDataMethod = "text";

    // verifico se esiste
    if(!this.state.pageContent.is_folderish){
      common.apiCall(env.BACKEND + pathnameEdit, "GET", {}, true)
      .then((documentExits) => {
        if(documentExits.error !== false){
          pathnameEdit = pathname;
          this.saveDataPlone(pathnameEdit,pathnameUrl, method, data, apiDataMethod, traduzioni, lingueSelezionate)
        }else{
          this.saveDataPlone(pathnameEdit,pathnameUrl, method, data, apiDataMethod, traduzioni, lingueSelezionate)
        }
      });
    }else{
      this.saveDataPlone(pathnameEdit,pathnameUrl, method, data, apiDataMethod, traduzioni, lingueSelezionate)
    }
  }

  async saveDataPlone(pathnameEdit, pathnameUrl, method, data, apiDataMethod, traduzioni, lingueSelezionate){
    const $ = window.$;
    common.apiCall(env.BACKEND + pathnameEdit, method, data, true, false, apiDataMethod)
    .then(async (data) => {
      var in_error = false;

      if(apiDataMethod === "json")
        in_error = data.error !== false;
      else
        in_error = data !== "";

      var contentUrl = env.BACKEND + pathnameEdit;
      var title = this.getTitle(traduzioni, lingueSelezionate);

      if(method === "POST")
        contentUrl = data["@id"];

      if(!in_error){
        var mutexUp3 = new common.Mutex();

        var fileInfo = $("#video")[0].files[0];
        if(fileInfo && fileInfo !== null && fileInfo !== undefined && fileInfo !== ""){
          $("#testoCaricamento").text(common.getTrad("Caricamento video in corso..."));
          this.uploadVideo(title, contentUrl, mutexUp3);
        }

        await mutexUp3.acquireQueued();
        var new_url = pathnameUrl;
        if (method === 'POST')
          new_url = common.getLanguage() + (pathnameUrl[0] === '/' ? pathnameUrl.substring(1) : pathnameUrl).substring(common.getLanguage().length)
        else
          new_url = (pathnameUrl).substring(0, pathnameUrl.lastIndexOf("/") + 1);
        window.contentSaved = true;
        $('#notifica_messaggio').html('<div class="sw-alert alert alert-success animated">' + common.getTrad("Caricamento elemento avvenuto correttamente.") + '</div>');
        common.clear_cache_after_save("swcontent");
        window.location.replace(env.FRONTEND + (new_url[0] === '/' ? new_url.substring(1) : new_url));
      }else{
        $("body #app .loadingDiv").fadeOut();
        data = JSON.parse(data)['error'];
        $('#notifica_messaggio').html('<div class="alert alert-danger animated">' + common.getTrad("Errore qualcosa è andato storto.") + " <b>" + data["type"] + "</b> " + data["message"]
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>'
        +'</div>');
      }
    });
  }

  async uploadFile(file, type, path, mutex){
    const $ = window.$;
    const release = await mutex.acquireQueued();
    this.uploadPercentages[file] = 0;
    var uploadFile = new tus.Upload(file, {
      endpoint: path + "/@tus-upload",
      retryDelays: [0, 3000, 5000, 10000, 20000],
      withCredentials: true,
      overridePatchMethod: false,
      headers: {
        'Accept': 'application/json',
        "authorization": "Bearer " + common.getLocalLoginToken()
      },
      metadata: {
        filename: file.name,
        filetype: file.type,
        "content-type": file.type,
        "@type": type,
      },
      onError: (error) => {
        console.error("Errore caricamento file -> " + error);
        release();
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
        this.uploadPercentages[file] = percentage;
        $("#divCaricamento_file").html(
          '<p>' + common.getTrad("File") + ': ' + file.name + '</p>' +
          '<div class="progress">' +
            '<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="' + percentage + '" aria-valuemin="0" aria-valuemax="100" style="width: '+ percentage +'%">' + percentage +'%</div>' +
          '</div>'
        );
      },
      onSuccess: () => {
        $("#divCaricamento_file").html('');
        release();
      },
      onAfterResponse: (req, res) => {
        var value = res.getHeader("Location")
        if(req._method !== undefined && req._method === 'PATCH' && value !== undefined && value !== null)
          window['sw_' + type.toLowerCase()] = value.split('/')[value.split('/').length - 1];
      }
    });
    return uploadFile.start();
  }

  async uploadVideo(name, contentUrl, mutex){
    const release = await mutex.acquireQueued();
    var $ = window.$;
    var file = $("#video")[0].files[0]
    if(file && file !== null && file !== undefined && file !== ""){
      return common.apiCall(env.BACKEND + "@sw-video", "GET", {'size': file.size, 'name': name}, true)
      .then((data) => {
        if(data.error === false){
            var upload_link = data['upload_link'];
            if (upload_link.includes('http')){
              var uploadFile = new tus.Upload(file, {
                uploadUrl: upload_link,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                onError: (error) => {
                  console.error("Errore caricamento file -> " . error)
                },
                onProgress: (bytesUploaded, bytesTotal) => {
                  var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                  this.uploadPercentages[file] = percentage;
                },
                onSuccess: () => {
                  var videoData = JSON.stringify({html: data['html'], filename: file.name, size: file.size})
                  common.apiCall(contentUrl, "PATCH", {video: videoData}, true).then(()=>{
                    release();
                  });
                }
              });
              return uploadFile.start();
            } else {
                // TODO: gestire erroe link non assegnato
            }
        }else{
          // TODO: gestire video non caricato
        }
        return data['html'];
      });
    }
  }

  renderSelectLingue(){
    try{
      return this.state.linguePortale.map(function(index, key){
        return(
          <div key={key} className="d-inline-block custom-control custom-checkbox mr-3">
            <input value={"sw-lang-"+ index} type="checkbox" id={index} name="lingue[]" className="custom-control-input lingue_selezionate" />
            <label className="custom-control-label" htmlFor={index}>
              {common.getTrad("lingua_" + index.toUpperCase())} <img title={common.getTrad("lingua_" + index.toUpperCase())} alt={common.getTrad("lingua_" + index.toUpperCase())} className="img-fluid bandiere" src={ env.STATIC + "images/bandiere/"+ index +".svg"} />
            </label>
          </div>
        )
      });
    }catch(e){}
  }

  saveSelectNazioni(nazioni) {
    this.setState({selectedItems: nazioni });
  }

  saveSelectModels(modelli){
    this.setState({selectedItemsModelli: modelli });
  }

  copiaTraduzioniAll(languageFrom, valueTinymce){
    const $ = window.$;
    return this.state.lingueSelezionate.map((index, key) => {
      if (
        valueTinymce[languageFrom] ||
        $("#title_" + languageFrom).val() ||
        $("#description_" + languageFrom).val() ||
        $("#image_caption_" + languageFrom).val()
      ){
        if($("#title_" + languageFrom).val() !== ''){
          $("#pills-tab-"+ index).addClass("translationCopy");
        }
        $("#title_" + index).val($("#title_" + languageFrom).val());
        $("#image_caption_" + index).val($("#image_caption_" + languageFrom).val());
        $("#description_" + index).val($("#description_" + languageFrom).val());
        $("#text_" + index).val(valueTinymce[languageFrom]);
        var tinyEditText = this.state.tinyEditText;
        tinyEditText[index] =  valueTinymce[languageFrom];
        this.setState({tinyEditText: tinyEditText});
      }
      return false;
    });
  }

  copiaTraduzioniDa(languageFrom, languageTo, valueTinymce){
    const $ = window.$;
    var textEditorEdit = this.state.tinyEditText;

    if(valueTinymce[languageTo] ||
      $("#title_" + languageTo).val() ||
      $("#description_" + languageTo).val() ||
      $("#image_caption_" + languageTo).val()
    ){
      valueTinymce[languageTo] ||
      $("#title_" + languageTo).val() ||
      $("#description_" + languageTo).val() ||
      $("#image_caption_" + languageTo).val()
    }else{
      this.state.pageContent['@components'].translations.items.map((index, key) => {
        if(index.language === languageTo){
          // PRENDO I dati del modifica della singola lingua
          var data = {"path.depth": 1, "expand": "translations"};
          common.apiCall(common.getBackendUrl(index['@id']), "GET", data, true)
          .then(editContent=>{
            if(editContent.error === false){
              $(".tab-pane.fade").removeClass("show active");
              $("#pills-content-" + languageFrom).addClass("show active");
              $("input#title_"+ languageFrom).val(editContent.title);
              $("textarea#description_"+ languageFrom).val(editContent.description);
              $("input#image_caption_"+ languageFrom).val(editContent.image_caption);
              textEditorEdit[editContent.language] = editContent.text ? editContent.text.data : "";
              this.setState({tinyEditText: textEditorEdit});
            }
          });
        }
        return false;
      });
    }
    $("#pills-tab-"+ languageFrom).addClass("translationCopy");
    $("#title_" + languageFrom).val($("#title_" + languageTo).val());
    $("#image_caption_" + languageFrom).val($("#image_caption_" + languageTo).val());
    $("#description_" + languageFrom).val($("#description_" + languageTo).val());
    $("#text_" + languageFrom).val(valueTinymce[languageTo]);
    var tinyEditText = this.state.tinyEditText;
    tinyEditText[languageFrom] = valueTinymce[languageTo];
    this.setState({tinyEditText: tinyEditText});
  }

  handleClickRemoveFile(typeToRemove){
    bootbox.confirm({
      message: common.getTrad("L'azione cancellerà in modo definitivo il contenuto selezionato.<br><b>Si è sicuri di voler eliminare il contenuto?</b>"),
      buttons: {
        confirm: {
          label: common.getTrad('Elimina'),
          className: 'btn-danger'
        },
        cancel: {
          label: common.getTrad('Annulla'),
          className: 'btn-light'
        }
      },
      callback: (result) => {
        if(result)
          this.removeFile(typeToRemove);
      }
    });
  }

  limiteFileSize(inputName, bytes) {
    const $ = window.$;
    var immagine = $("#image")[0].files[0];
    var video = $("#video")[0].files[0]
    var inputId = $("#" + inputName);
    if (inputId.length > 0 && !inputName === 'video') {
      var file = inputId[0].files[0];
      if (file.size > 209715200) {
        bootbox.alert(common.getTrad("Attenzione!<br/>Il file che stai cercando di caricare supera la dimensione massima consentita di 200 MB.<br/>Riduci la dimensione e caricalo nuovamente."));
        inputId.val(null);
        $(".span_label_" + inputName).html('');
      }
    }
    const allowedFormatsImg = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/tiff',
      'image/webp',
      'image/jxr', // JPEG XR
      'image/vnd.adobe.photoshop', // Photoshop Document
      'image/svg+xml', // Scalable Vector Graphics
    ];
    const allowedFormatsvide = [
      'video/mp4',
      'video/webm',
      'video/ogg',
      'video/quicktime', // QuickTime video format
      'video/x-msvideo', // AVI - Audio Video Interleave
      'video/x-ms-wmv', // Windows Media Video
      'video/mpeg', // MPEG Video
      'video/x-matroska', // Matroska video format
      'video/3gpp', // 3GP video format
      'video/3gpp2', // 3GPP2 video format
    ]
    if (inputName === 'image') {
      if (!allowedFormatsImg.includes(immagine['type'])) {
        bootbox.alert(common.getTrad('Attenzione! Formato file non accettato in questo campo! Utilizzare un formato differente.'));
        inputId.val(null);
        $(".span_label_" + inputName).html('');
      }
    }
    if (inputName === 'video') {
      if (!allowedFormatsvide.includes(video['type'])) {
        bootbox.alert(common.getTrad('Attenzione! Formato file non accettato in questo campo! Utilizzare un formato differente.'));
        inputId.val(null);
        $(".span_label_" + inputName).html('');
      }
    }
  }

  changeTitle(lang){
    const $ = window.$;
    if($("#title_"+ lang).val() !== ''){
      if(!$("#pills-tab-"+ lang).hasClass("translationCopy")){
        $("#pills-tab-"+ lang).addClass("translationCopy");
      }
    }else{
      $("#pills-tab-"+ lang).removeClass("translationCopy");
    }
  }

  render(props){
    var modelliObject = Object.keys(this.state.modelli);   

    return(
      <>
        <Prompt message={common.getTrad("La pagina contiene modifiche non salvate. Si è sicuri di voler abbandonare senza salvare?")}/>
        <div id="notifica_messaggio"></div>
        <div className="col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-8 linea_sfumata" id="content_page">
          <div className="row">
            <div className="content_titolo">
              <div className="content_titolo_int">
                <span className="titolo_sezione">
                  {this.state.pageTitle}
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 tab-content">
            <div className="tab active col-xl-12">
              { this.state.pageContent.is_folderish ?
                <h4>{common.getTrad("Aggiungi elemento")}</h4>
              :
                <h4>{common.getTrad("Modifica elemento")}</h4>
              }
              <div id="actions_page" className="mb-3">
                <div className="btn-group btn-group-sm mr-3" role="group" aria-label="Basic example">
                  <button type="button" className="btn btn-light" onClick={() => { const history = createBrowserHistory(); common.goBack(history);}}>
                    <i className="fas fa-chevron-left"></i> {common.getTrad("Torna indietro")}
                  </button>
                </div>
              </div>
              <form autoComplete="off" className="add_element" action="#" method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>
                <div className="form-group mt-5 mb-3">
                  <label><b>{common.getTrad("Lingue di pubblicazione")}:</b> <span className="danger">*</span></label>
                  <div className="c-inputs-stacked">
                    <div className="d-inline-block custom-control custom-checkbox mr-3">
                      <input autoComplete="off" type="checkbox" id="seleziona_tutti" value="sw-lang-all" name="seleziona_tutti" className="custom-control-input" />
                      <label className="custom-control-label" htmlFor="seleziona_tutti">{common.getTrad("Tutte le lingue")}</label>
                    </div>
                    {this.renderSelectLingue()}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-sm-12 p-0">
                    <ul className="nav nav-pills mb-4 tabs_lingue" id="pills-tab" role="tablist">
                      {this.state.linguePortale.map((indexTab, keyTab) => {
                        return(
                          <li className="nav-item" data-flag={indexTab} key={keyTab}>
                            <a data-flag={indexTab} className={(common.getLanguage() === indexTab ? "active" : "" ) + " nav-link"} id={"pills-tab-" + indexTab} data-toggle="pill" href={"#pills-content-" + indexTab} role="tab" aria-controls={"pills-content-" + indexTab} aria-selected="false">
                              <img data-flag={indexTab} title={common.getTrad("lingua_" + indexTab.toUpperCase())} alt={common.getTrad("lingua_" + indexTab.toUpperCase())} className="img-fluid bandiere" src={ env.STATIC + "images/bandiere/"+ indexTab +".svg"} />
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <hr/>
                <div className="row">
                  <>
                    <div className={"tab-content col-xl-12 " + (this.state.lingueSelezionate.length > 0 ? "d-block" : "d-none")} id="pills-tabContent" >
                    {this.state.linguePortale.map((indexContent, keyContent) => {
                      window.$("#text_" + indexContent).attr("name","text");
                      if (this.state.tinyEditText[indexContent] === undefined || this.state.tinyEditText[indexContent] === null || this.state.tinyEditText[indexContent] === '') {
                        if (this.props.pageContent.language === indexContent)
                          this.state.tinyEditText[indexContent] = this.state.pageContent?.text?.data;
                      }  
                      return (
                        <div key={keyContent} className={"tab-pane fade" + (
                          this.state.pageContent.is_folderish ?
                            common.getLanguage() === indexContent ?
                              " show active"
                              :
                                ""
                            :
                              (common.getLanguage() === indexContent) ?
                                " show active"
                                :
                              ""
                            )}
                            id={"pills-content-" + indexContent} role="tabpanel" aria-labelledby={"pills-tab-" + indexContent}>
                          <div className="row">
                          {this.state.pageContent.is_folderish && this.state.lingueSelezionate.length > 1 ?
                            <div className="col p-0 mb-5">
                              <button type="button"
                                onClick={() => {
                                  bootbox.confirm({
                                    message: common.getTrad("L'azione sovrascriverà in modo definitivo i contenuti testuali precedentemente inseriti, utilizzando come riferimento la lingua attiva.") + "<br><b>" + common.getTrad("Si è sicuri di voler proseguire con la sovrascrittura?</b>"),
                                    buttons: {
                                      confirm: {
                                          label: common.getTrad('Si'),
                                          className: 'btn-primary'
                                      },
                                      cancel: {
                                          label: common.getTrad('No'),
                                          className: 'btn-light'
                                      }
                                    },
                                    callback: (result) => {
                                      if(result){
                                        this.copiaTraduzioniAll(indexContent, this.state.tinyEditText)
                                      }
                                    }
                                  })
                                }}
                                className="btn bottone_default">
                                <i className="fas fa-globe"></i> {common.getTrad("Duplica i contenuti nelle altre lingue" )}
                              </button>
                            </div>
                          :
                            <div className="col p-0 mb-5">
                              {this.state.lingueSelezionate.length > 1 ?
                                <div className="btn-group">
                                  <button className="btn dropdown-toggle bottone_default" type="button" disabled={this.state.lingueSelezionate.length > 0 ? false : true} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fas fa-flag"></i> {common.getTrad("Copia i contenuti da una lingua in elenco")}
                                  </button>
                                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" >
                                  {this.state.lingueSelezionate.map((index, key) =>{
                                    if(indexContent !== index){
                                      return (
                                        <button type="button" key={key}
                                          onClick={() =>
                                            bootbox.confirm({
                                              message: common.getTrad("L'azione sovrascriverà in modo definitivo i contenuti testuali precedentemente inseriti nella lingua attiva.") + "<br><b>" + common.getTrad("Si è sicuri di voler proseguire con la sovrascrittura?</b>"),
                                              buttons: {
                                                confirm: {
                                                    label: common.getTrad('Si'),
                                                    className: 'btn-primary'
                                                },
                                                cancel: {
                                                    label: common.getTrad('No'),
                                                    className: 'btn-light'
                                                }
                                              },
                                              callback: (resultEdit) => {
                                                if(resultEdit){
                                                  this.copiaTraduzioniDa(indexContent, index, this.state.tinyEditText)
                                                }
                                              }
                                            })
                                          }
                                          className="dropdown-item">
                                          <img alt={common.getTrad("lingua_" + index.toUpperCase())} title={common.getTrad("lingua_" + index.toUpperCase())} width="25" src={env.STATIC + "images/bandiere/" + index + ".svg"} /> {common.getTrad("lingua_" + index.toUpperCase())}
                                        </button>
                                      )
                                    }
                                  })}
                                  </div>
                                </div>
                              :
                                <></>
                              }
                            </div>
                          }
                          </div>
                          <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                              <div className="form-group">
                                <label htmlFor="title"><b>{common.getTrad("Titolo")}</b> <span className="danger">*</span></label>
                                <input onChange={() => this.changeTitle(indexContent)} autoFocus autoComplete="off" type="text" className="form-control" id={"title_" + indexContent} name="title"
                                defaultValue={
                                  !this.state.pageContent.is_folderish ?
                                    this.props.pageContent.language === indexContent ?
                                      this.props.pageContent.title ?
                                        this.props.pageContent.title
                                      : ""
                                    : ""
                                  :
                                  ""
                                }
                                placeholder={common.getTrad("Inserisci il titolo")} />
                              </div>
                              <div className="form-group">
                                <label htmlFor="description"><b>{common.getTrad("Riassunto")}</b></label>
                                <textarea rows="8" autoComplete="off" className="form-control" id={"description_" + indexContent} name="description"
                                  defaultValue={
                                  !this.state.pageContent.is_folderish ?
                                    this.props.pageContent.language === indexContent ?
                                      this.props.pageContent.description ?
                                        this.props.pageContent.description
                                      :
                                        ""
                                    :
                                      ""
                                  :
                                    ""
                                  }
                                  placeholder={common.getTrad("Usato nell'elenco degli elementi e nei risultati delle ricerche.")}></textarea>
                              </div>
                              <div className="form-group">
                                <label className="col p-0" htmlFor="image_caption"><b>{common.getTrad("Didascalia immagine")}</b></label>
                                <input type="text" autoComplete="off" className="form-control" id={"image_caption_" + indexContent} name="image_caption"
                                  defaultValue={
                                    !this.state.pageContent.is_folderish ?
                                      this.props.pageContent.language === indexContent ?
                                        this.props.pageContent.image_caption ?
                                          this.props.pageContent.image_caption
                                        :
                                          ""
                                      :
                                        ""
                                    :
                                      ""
                                  } />
                              </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-xl-3 px-md-0 px-sm-0 px-0">
                              <div className="form-group">
                                <label htmlFor={"text_" + indexContent}><b>{common.getTrad("Testo")}</b></label>
                                <Editor tinymceScriptSrc={env.STATIC + 'lib/tinymce/tinymce.min.js'}
                                  id={"text_" + indexContent}
                                  value={(this.state.tinyEditText[indexContent] === undefined || this.state.tinyEditText[indexContent] === null) ? '' : this.state.tinyEditText[indexContent]}
                                  onEditorChange={this.handleEditorChange}
                                  init={{
                                    branding: false,
                                    theme: 'silver',
                                    browser_spellcheck : true,
                                    language_url: env.STATIC + 'lib/tinymce/langs/'+common.getLanguage() +'.js',
                                    language: common.getLanguage(),
                                    height: 350,
                                    relative_urls : false,
                                    menubar: 'format edit insert table tools view',
                                    menu: {
                                      insert: {title: 'Insert', items: 'hr'},
                                      format: {title: 'Format', items: 'bold italic underline strikethrough superscript subscript | removeformat'},
                                      edit: {title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall'},
                                      table: {title: 'Table', items: 'inserttable tableprops deletetable | cell row column'},
                                      tools: {title: 'Tools', items: 'code'},
                                      view: {title: 'View', items: 'visualaid fullscreen'}
                                    },
                                    plugins: [
                                      'advlist', 'autolink', 'lists', 'link', 'preview', 'anchor',
                                      'searchreplace', 'fullscreen',
                                      'media', 'table', 'code', 'wordcount'
                                    ],
                                    toolbar: 'undo redo | styles | bold italic  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link unlink',
                                  }}></Editor>
                               </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                    </div>
                    <div className={"sw-alert alert alert-warning " + (this.state.lingueSelezionate.length > 0 ? "d-none" : "d-block")} dangerouslySetInnerHTML={{ __html: common.getTrad('Nessuna lingua selezionata. Seleziona almeno una delle lingue in elencate sotto: "<b>Lingue di pubblicazione</b>" e compila i campi obbligatori.')}} />
                  </>
                </div>
                <hr/>
                <div className="row">
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
                    <div className="form-group input-daterange" id="datepicker">
                      <div className="form-group col-md-6">
                        <label htmlFor="effective"><b>{common.getTrad("Data Pubblicazione")}</b> <span className="danger">*</span></label>
                        <input required autoComplete="off" type="text" className="form-control" id="effective" name="effective"
                          defaultValue={
                            !this.state.pageContent.is_folderish ?
                              this.state.pageContent.effective ?
                                moment(this.state.pageContent.effective).format('L')
                              :
                                ""
                            :
                            moment().format('L')
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="expires"><b>{common.getTrad("Data Scadenza")}</b> </label>
                        <input type="text" autoComplete="off" className="form-control" id="expires" name="expires"
                          defaultValue={
                            !this.state.pageContent.is_folderish ?
                              this.props.pageContent.expires ?
                                moment(this.props.pageContent.expires).format('L')
                              :
                                ""
                            :
                              ""
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                        <div className="col-10 pl-0 float-left">
                          <span>{common.getTrad("Contenuto in evidenza")}</span>
                        </div>
                        <div className="col-2 p-0 float-left">
                          <label className="switch float-right">
                          <input name="evidenza" autoComplete="off" id="evidenza" value="sw_in_evidenza" type="checkbox" onChange={this.handleEvidenza} />
                            <span className="slider round"></span>
                          </label>
                        </div>
                    </div>

                    <div className="form-group input-daterange" id="datepicker-evidenza">
                      <div className="form-group col-md-6">
                        <label htmlFor="effective_evidenza"><b>{common.getTrad("Data Pubblicazione")}</b> <span className="danger">*</span></label>
                        <input autoComplete="off" type="text" className="form-control" id="effective_evidenza" name="effective_evidenza"
                          defaultValue={
                            !this.state.pageContent.is_folderish ?
                              this.state.pageContent.effective_evidenza_method ?
                                moment(this.state.pageContent.effective_evidenza_method).format('L')
                                :
                                ""
                              :
                              moment().format('L')
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="expires_evidenza_method"><b>{common.getTrad("Data Scadenza")}</b> </label>
                        <input type="text" autoComplete="off" className="form-control" id="expires_evidenza" name="expires_evidenza_method"
                          defaultValue={
                            !this.state.pageContent.is_folderish ?
                              this.props.pageContent.expires_evidenza_method ?
                                moment(this.props.pageContent.expires_evidenza_method).format('L')
                                :
                                ""
                              :
                              ""
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="col-10 pl-0 float-left">
                        <span>{common.getTrad("Contenuto mostrato a popup")}</span>
                      </div>
                      <div className="col-2 p-0 float-left">
                          <label className="switch float-right">
                            <input name="popup" autoComplete="off" id="popup" value="sw_popup" type="checkbox" onChange={this.handlePopup} />
                            <span className="slider round"></span>
                          </label>
                      </div>
                    </div>

                    <div className="form-group input-daterange" id="datepicker-popup">
                      <div className="form-group col-md-6">
                        <label htmlFor="effective_popup"><b>{common.getTrad("Data Pubblicazione")}</b> <span className="danger">*</span></label>
                        <input autoComplete="off" type="text" className="form-control" id="effective_popup" name="effective_popup"
                          defaultValue={
                            !this.state.pageContent.is_folderish ?
                              this.state.pageContent.effective_popup_method ?
                                moment(this.state.pageContent.effective_popup_method).format('L')
                                :
                                ""
                              :
                              moment().format('L')
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="expires_popup"><b>{common.getTrad("Data Scadenza")}</b> </label>
                        <input type="text" autoComplete="off" className="form-control" id="expires_popup" name="expires_popup"
                          defaultValue={
                            !this.state.pageContent.is_folderish ?
                              this.props.pageContent.expires_popup_method ?
                                moment(this.props.pageContent.expires_popup_method).format('L')
                                :
                                ""
                              :
                              ""
                          }
                        />
                      </div>
                    </div>

                    <div className="clearfix"></div>
                    <hr/>
                    <div className="form-group">
                      <label className="col p-0 file_old  ">
                        <b>{common.getTrad("File")}</b>
                        <p className="text-muted">{common.getTrad("Dimensione massima di caricamento 200 MB")}</p>
                      </label>
                      {
                        !this.state.pageContent.is_folderish && this.state.pageContent.file ?
                          <p>
                            {common.getTrad("File caricato")}:
                            <b>{this.state.pageContent.file.filename}</b>
                            {this.state.pageContent.file.size !== undefined ? "(" + common.humanFileSize(this.state.pageContent.file.size) + ")" : ""}&nbsp;

                            <button type="button" className="btn bottone_default buttonRemoveImage" onClick={() => this.handleClickRemoveFile("file")}>
                              <i title={common.getTrad("Elimina")} className="fas fa-times "></i>
                            </button>
                          </p>
                        :
                          ""
                      }
                      <input type="hidden" name="removeFile" id="removeFile"/>
                      <input type="file" autoComplete="off" onChange={(e) => this.limiteFileSize("file", 209715200)} name="file" id="file" className="inputfile inputfile-6" />
                      <label htmlFor="file">
                        <span className="span_label_file"></span>
                        <strong><i className="fas fa-file-upload"></i> {common.getTrad("Scegli...")}</strong>
                      </label>
                    </div>

                    <div className="form-group">
                      <label className="col p-0 video_old">
                        <b>{common.getTrad("Video")}</b>
                      </label>
                      {
                        !this.state.pageContent.is_folderish && this.state.pageContent.video ?
                          <p>
                            {common.getTrad("Video caricato")}:
                            <b>{this.state.pageContent.video.filename}</b>
                            ({common.humanFileSize(this.state.pageContent.video.size)})&nbsp;
                            <button type="button" className="btn bottone_default buttonRemoveImage" onClick={() => this.handleClickRemoveFile("video")}>
                              <i title={common.getTrad("Elimina")} className="fas fa-times "></i>
                            </button>
                          </p>
                        :
                          ""
                      }
                      <input type="hidden" name="removeVideo" id="removeVideo"/>
                      <input type="file" autoComplete="off" onChange={(e) => this.limiteFileSize("video", 209715200000)} name="video" id="video" className="inputfile inputfile-6" accept="video/*"/>
                      <label htmlFor="video">
                        <span className= "span_label_video"></span>
                        <strong><i className="fas fa-file-upload"></i> {common.getTrad("Scegli...")}</strong>
                      </label>
                    </div>

                    <div className="form-group">
                      <label className="col p-0 image_old">
                        <b>{common.getTrad("Immagine")}</b>
                        <p className="text-muted">{common.getTrad("Dimensione massima di caricamento 200 MB")}</p>
                      </label>
                      {
                        !this.state.pageContent.is_folderish && this.state.pageContent.image ?
                          <p>
                            {common.getTrad("Immagine caricata")}:
                            <b>{this.state.pageContent.image.filename}</b>
                            {this.state.pageContent.image.size !== undefined ? "(" + common.humanFileSize(this.state.pageContent.image.size) + ")" : ""}&nbsp;

                            <button type="button" className="btn bottone_default buttonRemoveImage" onClick={() => this.handleClickRemoveFile("image")}>
                              <i title={common.getTrad("Elimina")} className="fas fa-times "></i>
                            </button>
                          </p>
                        :
                          ""
                      }
                      <input type="hidden" name="removeImage" id="removeImage"/>
                      <input type="file" autoComplete="off" name="image" id="image" onChange={(e) => this.limiteFileSize("image", 209715200)} className="inputfile inputfile-6" accept="image/*"/>
                      <label htmlFor="image">
                        <span className="span_label_image"></span>
                        <strong><i className="fas fa-file-upload"></i> {common.getTrad("Scegli...")}</strong>
                      </label>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-xl-3 px-md-0 px-sm-0 px-0">

                    <div className="form-group">
                      <label htmlFor="seleziona_nazioni">
                        <b>{common.getTrad("Filtro per Nazioni")}</b> <span className="danger">*</span><br />
                      </label>
                      <div className="row content_multiselect mb-5">
                        <div className="col-6 p-0">
                          <MultiSelectNazioni
                            onClick={(nazioni) => this.saveSelectNazioni(nazioni)}
                            nazioni={this.state.nazioniPortale}
                            nazioniSelected={this.state.selectedItems}
                            id="selectNazioni"
                            showInput={true}
                            showDelete={false}
                            edit={!this.state.pageContent.is_folderish}
                          >
                          </MultiSelectNazioni>
                        </div>
                        <div className="col-6 p-0">
                          <MultiSelectNazioni
                            onClick={(nazioni) => this.saveSelectNazioni(nazioni)}
                            nazioni={this.state.nazioniPortale}
                            id="selectNazioniChecked"
                            showInput={false}
                            showDelete={true}
                            edit={!this.state.pageContent.is_folderish}
                          >
                          </MultiSelectNazioni>
                        </div>
                      </div>
                    </div>
                  </div>

                  <>
                    <div className="col-12 p-0">
                      <div className="form-group">
                        <label htmlFor="seleziona_modelli">
                          <b>{common.getTrad("Modelli")}</b>&nbsp;<span className="danger">*</span>
                        </label>
                        <br/>
                        {common.getTrad("Associare i contenuti a uno i piu' modelli; nel caso in cui il contenuto sia generico associarlo unicamente alla prima voce \"tutti i modelli\"")}
                      </div>
                    </div>
                    <div className="row content_multiselect mb-5">
                      <div className="col-6 p-0">
                        <MultiSelectModelli
                          onClick={(modelli) => this.saveSelectModels(modelli)}
                          modelli={this.state.modelli}
                          modelliSelected={this.state.selectedItemsModelli}
                          id="selectModelliGruppi"
                          head="Original"
                          collapse="Original"
                          showInput={true}
                          showDelete={false}
                          marche_attive={this.state.marche_attive}
                          edit={!this.state.pageContent.is_folderish}
                          modelRequired={this.state.pageContent.description.modelRequired === 'true' || (!this.state.pageContent.is_folderish && this.state.pageContent.parent.description.modelRequired === 'true')}
                        >
                        </MultiSelectModelli>
                      </div>
                      <div className="col-6 p-0">
                        <MultiSelectModelli
                          onClick={(modelli) => this.saveSelectModels(modelli)}
                          modelli={this.state.modelli}
                          id="selectModelliGruppiChecked"
                          head="Checked"
                          collapse="Checked"
                          showInput={false}
                          showDelete={true}
                          marche_attive={this.state.marche_attive}
                          edit={!this.state.pageContent.is_folderish}
                          modelRequired={this.state.pageContent.description.modelRequired === 'true' || (!this.state.pageContent.is_folderish && this.state.pageContent.parent.description.modelRequired === 'true')}
                        >
                        </MultiSelectModelli>
                      </div>
                    </div>
                  </>

                  <div id="status"></div>
                  <div className="col-xl-12 col-lg-12 col-xs-12 p-0">
                    <div className="form-row">
                      <div className="form-group col bottoni_invio">
                        <button type="submit" className="btn btn-primary float-right">{common.getTrad("Salva")}</button>
                        <button id="annulla" type="button" className="btn btn-light float-right mr-2">{common.getTrad("Annulla")}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default EditContentDefault;
