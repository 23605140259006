import React from 'react';
import moment from 'moment';
const common = require('../common/common');

class PageDefault extends React.Component {

  constructor(props){
    super(props);
    this.state = {};
    this.imageBig = "";

    if(this.props.pageContent.video !== undefined && this.props.pageContent.video && this.props.pageContent.video !== null && this.props.pageContent.video !== "")
      try{
        this.props.pageContent.video = JSON.parse(this.props.pageContent.video)
      }catch(ex){ }

    this.state.pageContent = this.props.pageContent;
    this.state.pathname = this.props.pathname;
    this.zoomImage = this.zoomImage.bind(this);
  }

  async componentDidMount(){
    const $ = window.$;
    var jsonFile = this.state.pageContent.file;
    var jsonImage = this.state.pageContent.image;
    var pageContent = this.state.pageContent;
    var numItem = 0;
    if(this.state.pageContent.relatedItems !== undefined && this.state.pageContent.relatedItems.length > 0){
      numItem = common.object_value_in_array(this.state.pageContent.relatedItems, "@type", "File");
      if(numItem > -1){
        jsonFile = {
          download: this.state.pageContent.relatedItems[numItem]['@id'] + "/@@download/file/" + this.state.pageContent.relatedItems[numItem]['title'],
          filename: this.state.pageContent.relatedItems[numItem]['title'],
          size: 0
        }

        await common.apiCall(this.state.pageContent.relatedItems[numItem]['@id'], "GET", {}, true, false, "json")
        .then(data=>{
          jsonFile['size'] = data.file.size;
        });
      }

      numItem = common.object_value_in_array(this.state.pageContent.relatedItems, "@type", "Image");
      if(numItem > -1) {
        jsonImage = {
          getBlob: false,
          download: this.state.pageContent.relatedItems[numItem]['@id'],
          scales: {preview: {file: this.state.pageContent.relatedItems[numItem]['@id'] + "/@@images/image"}},
        }
        common.apiCall(jsonImage.scales.preview.file, "GET", {}, true, false, "blob")
          .then(result => {
            var img = document.querySelector('figure .figure-img');
            if (img) {
              img.src = result;
            }
          });
      }
    }

    pageContent.file = jsonFile;
    pageContent.image = jsonImage;
    this.setState({pageContent: pageContent});
  }

  async handleDownloadFile(fileurl, filename){
    const $ = window.$;
    $("#downloadFileLoader").css("display", "inline");
    var fileContent = await common.apiCall(fileurl, "GET", {}, true, false, "blob")
    .then(data => {

      $("#downloadFileLoader").css("display", "none");
      return data;
    });


    var element = document.createElement('a');
    element.setAttribute('href', fileContent);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);

    return;
  }

  async zoomImage(title, image, getBlob=true){
    const $ = window.$;
    var imageGet = getBlob ? this.imageBig : image;
    var imgHtml = $("body #app .content_img_enlarge .img_enlarge img");
    var contentHtml= $("body #app .content_img_enlarge");
    if(getBlob && imageGet === ""){
      try{
  			imageGet = await common.apiCall(common.getBackendUrl(image), "GET", {}, true, false, "blob");
  		}catch(error){}
      this.imageBig = imageGet;
    }

    common.apiCall(this.state.pageContent.image.scales.preview.file, "GET", {}, true, false, "blob")
      .then(result => {
        var img = document.querySelector('.img-responsive');
        if (img) {
          contentHtml.fadeIn("fast");
          imgHtml.attr("src", result).attr("title", title).attr("alt", title);
        }
      });

    // prevedere ESC e click
    $(document).keyup(function (e) {
      if (e.which === 27)
        contentHtml.click();
    });

    contentHtml.click((e) => {
      e.preventDefault();
      contentHtml.fadeOut("fast");
    });
  }

  render(props){
    return (
      <div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 content_testo">
            <h1>{this.state.pageContent.title}</h1>
            <p className="info_singolo_contenuto">
              <span><i className="far fa-calendar-alt"></i> {moment(this.state.pageContent.created).format('LL')}</span>
              {this.state.pageContent.subjects.indexOf('sw_in_evidenza') !== -1 ?
                <span><i className="fas fa-star"></i> {common.getTrad("In Evidenza")}</span>
              :
                <></>
              }
              {this.state.pageContent.subjects.indexOf('sw_popup') !== -1 ?
                <span><i className="fas fa-highlighter"></i> {common.getTrad("Pop-up")}</span>
              :
                <></>
              }
              {this.props.isAdminContent && (this.state.pageContent.expires !== undefined && moment(this.state.pageContent.expires) < new Date()) ?
                <span className="text-danger">
                  <i className="fas fa-calendar-times"></i> {common.getTrad("Contenuto scaduto il {{data_scadenza}}", {data_scadenza: moment(this.state.pageContent.expires).format('LL')})}
                </span>
              :
                <></>
              }
            </p>
            <br/>
          </div>
        </div>
        <>
          {
            this.state.pageContent.image !== null ?
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                {this.state.pageContent.description.search("\n") > -1 ?
                  <p dangerouslySetInnerHTML={{ __html:(this.state.pageContent.description).replace(/\r?\n/g,'<br/>')}}/>
                :
                  <p>{this.state.pageContent.description}</p>
                }
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <figure className="figure float-right d-flex flex-column align-items-end">
                  <img style={{ cursor: "zoom-in", maxWidth: "60%" }} onClick={(e) => this.zoomImage((this.state.pageContent.image_caption !== null && this.state.pageContent.image_caption !== '' ?  this.state.pageContent.image_caption : this.state.pageContent.title ), this.state.pageContent.image.download, this.state.pageContent.image.getBlob)} className="figure-img img-fluid" src=""
                  title={this.state.pageContent.image_caption !== null && this.state.pageContent.image_caption !== '' ?  this.state.pageContent.image_caption : this.state.pageContent.title}
                  alt={this.state.pageContent.image_caption !== null && this.state.pageContent.image_caption !== '' ?  this.state.pageContent.image_caption : this.state.pageContent.title}
                />
                {this.state.pageContent.image_caption !== null ?
                  <figcaption className="figure-caption text-right">{this.state.pageContent.image_caption}</figcaption>
                  :
                  <>
                  </>
                }
                </figure>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
              <hr/>
              {this.state.pageContent.text !== null ?
                <div dangerouslySetInnerHTML={{ __html: this.state.pageContent.text.data }} />
              :
                <>
                </>
              }
              </div>
            </div>
            :
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p>{common.beautifyShortDescription(this.state.pageContent.description)}</p>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
                <hr/>
                {this.state.pageContent.text !== null ?
                  <div dangerouslySetInnerHTML={{ __html: this.state.pageContent.text.data }} />
                  :
                  <>
                  </>
                }
              </div>
            </div>
          }
        </>
        <div className="row mt-5 justify-content-between">
          {this.state.pageContent.video && this.state.pageContent.video !== null && this.state.pageContent.video.html !== undefined && this.state.pageContent.video.html !== null ?
            <div className="col-xl-5 col-md-12 col-sm-12 content_video">
              <h3>
                {common.getTrad("Video")}
              </h3>
              <hr/>
              <div className="embed-responsive embed-responsive-16by9" id="embed-video">
                <div dangerouslySetInnerHTML={{ __html: this.state.pageContent.video.html}} />
              </div>
            </div>
          :
            <></>
          }
          {this.state.pageContent.file !== null ?
            <div className="col-xl-5 col-md-12 col-sm-12 content_download">
              <h3>{common.getTrad("Download")}</h3>
              <hr/>
              <p>{common.getTrad("Clicca sul bottone per scaricare il file")}:<br /> <b>{this.state.pageContent.file.filename}</b> {this.state.pageContent.file.size !== undefined && this.state.pageContent.file.size !== 0 ? "(" + common.humanFileSize(this.state.pageContent.file.size) + ")" : ""}</p>
              <br/>
              <button className="bottone_default btn btn-lg mt-5" id="downloadButton" onClick={() => {this.handleDownloadFile(common.getBackendUrl(this.state.pageContent.file.download), this.state.pageContent.file.filename)}}>
                <b><i className="fas fa-file-download"></i> {common.getTrad("Scarica l'allegato")} &nbsp;&nbsp;<span className="downloadFileLoader" id="downloadFileLoader"><i className="fas fa-spinner fa-spin"></i></span></b>
              </button>
            </div>
          :
            <></>
          }
        </div>
      </div>
    )
  }
}
export default PageDefault;
