import React from 'react';
import { Link } from "react-router-dom";
import { svgIconsLoader } from "../MenuHome/MenuHome";
import './sidebar.css';

const common = require('../common/common');
const env = require("../../config.js").default;

class Sidebar extends React.Component{
	constructor(props){
		super(props);
		this.state = {};
		this.state.vociSidebar = {};
		this.state.userInfo = {};
		this.state.userInfo.roles = [];
		this.state.userInfo.userInfo = {};
		this.state.userInfo.userInfo.WUS = {};
		this.state.userInfo.userInfo.groups = [];
		this.state.vociSidebar.parent = {};
		this.state.vociSidebar.parent.description = {};
		this.state.vociSidebar.description = {};
		this.state.parentPathname1 = this.props.parentPathname;
		this.state.parentPathname = common.getBackendUrl(this.props.parentPathname);
		this.state.pathname = this.props.pathname;
		this.state.userEdit = "";
		this.state.renderVociSidebar = false;
	}

	UNSAFE_componentWillMount(props){
		if(this.props.type !== "user"){
			var data = {"path.depth": 1, "portal_type": "Folder" };
			var url = this.state.parentPathname;

			if(url.search("/" + common.getLanguage() + "/") <= -1){
				for(var i=0; i < window.cc.languages.length; i++){
					if(url.search("/" + window.cc.languages[i] + "/") > -1){
						url = url.replace("/" + window.cc.languages[i] + "/", "/" + common.getLanguage() + "/");
						break;
					}
				}
			}

			common.apiCall(url, "GET", data, true, true)
			.then(vociSidebar=>{
				if(vociSidebar.error === false){
					var description = vociSidebar.description;
					if(vociSidebar.description  !== ''){
						try{
							vociSidebar.description = JSON.parse(description);
						}
						catch{	}
					}
					var descriptionP = vociSidebar.parent.description;
					if(vociSidebar.parent.description  !== ''){
						vociSidebar.parent.description = JSON.parse(descriptionP);
					}
					this.setState({vociSidebar, renderVociSidebar: true});
				}
			});
		}
		common.setStateUserInfo(this);
	}

	componentDidUpdate() {
		setTimeout(function () { svgIconsLoader() }, 1000);
		const $ = window.$;
		if (JSON.parse(common.get_localStorage("sidebarOpen"))) {
			$("#content_sidebar").removeClass("pushed");
			$(".blocco_menu_laterale").removeClass("no_width");
			$("#content_page").removeClass("main-pushed");
			$(".mobile-bar").addClass('open');
			// Mostro icona chiusura (sidebar aperta)
			$('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'translate(0, 8px) rotate(45deg)');
			$('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '0');
			$('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'translate(0, -8px) rotate(-45deg)');
		} else {
			$("#content_sidebar").addClass("pushed");
			$(".blocco_menu_laterale").addClass("no_width");
			$("#content_page").addClass("main-pushed");
			$(".mobile-bar").removeClass('open');
			// Mostro icona menù (sidebar chiusa)
			$('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'none');
			$('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '1');
			$('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'none');
		}
	}

	renderVociSidebar(){
		try{
      		var resultNodes = this.state.vociSidebar.items.map( function (result, key){
				if(result["@type"] === "Folder"){
					return (
						<VociSidebar key={key} mykey={key} pathname={env.BACKEND + (this.state.pathname).substring(1)} thisMenu={result} />
					);
				}
      		}.bind(this));
      		return resultNodes;
		}catch (error){
			return ;
		}
	}

	openSidebar(e){
		e.preventDefault();
		const $ = window.$;
		$("#content_sidebar").toggleClass("pushed");
		$(".blocco_menu_laterale").toggleClass("no_width");
		$("#content_page").toggleClass("main-pushed");
		$('.bar').toggleClass('animate');
		$(".mobile-bar").toggleClass('open');
		if(this.props.type !== "user")
    	common.set_localStorage("sidebarOpen", !$("#content_sidebar").hasClass("pushed"));
		// Check se la sidebar è aperta o è chiusa
		if ($("#content_sidebar").hasClass("pushed") === true) {
			$('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'none');
			$('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '1');
			$('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'none');
		} else {
			$('.check_hamburger').siblings('span:nth-of-type(1)').css('transform', 'translate(0, 8px) rotate(45deg)');
			$('.check_hamburger').siblings('span:nth-of-type(2)').css('opacity', '0');
			$('.check_hamburger').siblings('span:nth-of-type(3)').css('transform', 'translate(0, -8px) rotate(-45deg)');
		}
		$(window).trigger('resize');
	}

	searchUsers(e){
		const $ = window.$;
		var element = $(e.target);
		$("#accordion_int li.nav-item:not(:contains('" + element.val().toUpperCase() + "'))").hide();
		$("#accordion_int li.nav-item:contains('" + element.val().toUpperCase() + "')").show();
	}

  render(props) {
		if(this.props.type === "user"){
			return(
				<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-4 blocco_menu_laterale">
					<div className="mobile-bar cons">
						{/* <div className="hamburger-menu cons" onClick={(e) => this.openSidebar(e)}>
											<div className="bar cons"></div>
										</div> */}
						<div className="hamburger">
							<input
								type="checkbox"
								className="check_hamburger"
								name="check_hamburger"
								checked={common.get_localStorage("sidebarOpen")}
								onClick={(e) => this.openSidebar(e)}
							/>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					<div id="content_sidebar">
						<div className="card">
					    <div className="card-header">
					      <Link className="card-link" title={common.getTrad("Home Page")} to="/">
					        <span className="ico-menu">
					          <figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-home.svg"} title={common.getTrad("Home Page")} alt={common.getTrad("Home Page")} className="svg icona_menu"></figure>
					        </span>
					        <span className="title-menu">{common.getTrad("Home Page")}</span>
					      </Link>
					    </div>
					  </div>
						<div className="card">
							<div className="collapse show" data-parent="#accordion_int">
								<div className="card-body">
									<div id="accordion_int">
										<div className="card">
											<div className="card-header">
												<Link className="card-link" to="/">
													<span className="ico-menu">
														<figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-utenti.svg"} title={common.getTrad("Utenti")} alt={common.getTrad("Utenti")} className="svg icona_menu"></figure>
													</span>
													<span className="title-menu">
														{common.getTrad("Utenti")}
													</span>
												</Link>
											</div>
											<div className="collapse show">
												<div className="card-body">
													<ul className="nav nav-tabs">
														{this.props.pageType !== 'console-utenti-rmi' ?
															<li className="nav-item" onClick={() => this.props.onClick(0)}>
																<Link id="" className={((!this.props.userEdit) ? "active" : "") + " nav-link"} to={common.getUrlPath("/my-profile")}>{this.state.userInfo.fullname ? this.state.userInfo.fullname : this.state.userInfo.username}</Link>
															</li>
														:
															<>
																<div className="input-group m-0">
																	<input onChange={(e) => this.searchUsers(e)} className="form-control" type="search" placeholder={common.getTrad("Cerca...")} autoFocus="" autoComplete="off" />
																	<div className="input-group-prepend">
																	 	<span className="input-group-text">
																		<i className="fas fa-search"></i>
																		</span>
																 	</div>
																</div>
																<li className="nav-item" onClick={() => this.props.onClick(0)}>
																	<span style={{cursor: "pointer"}} className={((this.props.userEdit === 0 || this.props.userEdit  === undefined) ? "active" : "") + " nav-link"}>{common.getTrad("Torna alla Console")}</span>
																</li>
															</>
														}
 														{this.props.employee_rmi ?
															this.props.employee_rmi.employee_rmi.map((index, key) => {
																if(index.CUSER !== this.state.userInfo.userInfo.CUSER){
																	var classIconUser;
																	switch (index.FBLOC) {
																		case "S":
																			classIconUser = "float-right fas fa-lock disabilitato";
																			break;
																		case "N": case "":
																			classIconUser	= "float-right fas fa-lock-open abilitato";
																			break;
																	}

																	var to_read = '';
																	if(!index.DNOTE && index.FBLOC === 'S'){
																		to_read = ' font-weight-bold';
																	}

				                        	return (
																		<li key={key} className="nav-item" onClick={(e) => this.props.userEdit ? (this.props.userEdit === index.CUSER) ? "" : this.props.onClick(index.CUSER) : this.props.onClick(index.CUSER)}>
																			<span style={{cursor: "pointer"}} className={((this.props.userEdit === index.CUSER) ? "active" : "") + to_read + " nav-link"}>
																				{this.props.myprofile ?
																					index.CUSER.search(window.cc.prefix) > -1 ?
																						index.CUSER.substring(window.cc.prefix.length, index.CUSER.length)
																					:
																						index.CUSER
																				:
																					index.RCLIE.toUpperCase()
																				}
																				<i className={classIconUser}></i>
																			</span>
																		</li>
																	)
																}
																return ("")
				                      })
														:
															<></>
														}
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}else{
			return(
				this.state.renderVociSidebar ?
					<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-4 blocco_menu_laterale">
						<div className="mobile-bar cons">
							{/* <div className="hamburger-menu cons" onClick={(e) => this.openSidebar(e)}>
											<div className="bar cons"></div>
										</div> */}
							<div className="hamburger">
								<input
									type="checkbox"
									className="check_hamburger"
									name="check_hamburger"
									checked={common.get_localStorage("sidebarOpen")}
									onClick={(e) => this.openSidebar(e)}
								/>
								<span></span>
								<span></span>
								<span></span>
							</div>
						</div>
						<div id="content_sidebar">
							<div className="card">
								<div className="card-header">
									<Link className="card-link" title={common.getTrad("Home Page")} to="/">
										<span className="ico-menu">
											<figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-home.svg"} title={common.getTrad("Home Page")} alt={common.getTrad("Home Page")} className="svg icona_menu"></figure>
										</span>
										<span className="title-menu">{common.getTrad("Home Page")}</span>
									</Link>
								</div>
							</div>
							<div className="card">
								<div className="card-header">
								{
									this.state.vociSidebar["@id"] !== undefined && this.state.vociSidebar["@id"].search("/messaggi-sistema") <= -1 ?
										this.state.vociSidebar.is_folderish ?
		 									<Link className="card-link" title={this.state.vociSidebar.title} to="/">
												<span className="ico-menu">
													<figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/" + this.state.vociSidebar.parent.description.img} className="svg icona_menu"></figure>
												</span>
												<span className="title-menu">{this.state.vociSidebar.parent.title}</span>
											</Link>
										:
											<Link className="card-link" title={this.state.vociSidebar.title} to="/">
												<span className="ico-menu">

													<figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/" + this.state.vociSidebar.description.img} className="svg icona_menu"></figure>
												</span>
												<span className="title-menu">{this.state.vociSidebar.title}</span>
											</Link>
									:
									""
								}
								</div>
							</div>
							<div className="collapse show">
								<div className="card-body">
									<div className="card">
										<div className="card-header">
													{this.state.vociSidebar.is_folderish ?
											<Link to={this.state.pathname} className="card-link" title={this.state.vociSidebar.title}>
												<span className="ico-menu">
													<figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/" + this.state.vociSidebar.description.img} title={this.state.vociSidebar.title} alt={this.state.vociSidebar.title} className="svg  icona_menu"></figure>
												</span>
												<span className="title-menu">{this.state.vociSidebar.title}</span>
											</Link>
											:
											<Link to="/" className="card-link" title={this.state.vociSidebar.parent.title}>
												<span className="ico-menu">
													<figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/" + this.state.vociSidebar.parent.description.img} title={this.state.vociSidebar.parent.title} alt={this.state.vociSidebar.parent.title} className="svg  icona_menu"></figure>
												</span>
												<span className="title-menu">{this.state.vociSidebar.parent.title}</span>
											</Link>
										}
										</div>
										<div className="collapse show" >
											<div className="card-body">
												<ul className="nav nav-tabs">
													{this.renderVociSidebar()}
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				:
					<></>
			)
		}
	}
}

class VociSidebar extends React.Component{
	constructor(props){
    super(props);
    this.state = {};

    this.state.userInfo = {};
    this.state.userInfo.user_menu = [];
  }
  UNSAFE_componentWillMount(){
    common.getUserInfo().then(userInfo =>{
      if(userInfo !== undefined && userInfo !== false){
        if(userInfo.user_menu === undefined || userInfo.user_menu === null)
          userInfo.user_menu = [];
        this.setState({userInfo: userInfo});
      }
    });
  }
	isInUserMenuObject(item){
    var ret = false;
    if(this.state.userInfo.user_menu !== undefined && this.state.userInfo.user_menu !== null && Array.isArray(this.state.userInfo.user_menu))
      this.state.userInfo.user_menu.map((value) =>{
        if(value.url !== undefined && value.url !== null && value.url.search(item) > -1){
          ret = true;
        }
        return false;
      });

    return ret
  }

	render(props){
		return(
		<li className="nav-item">
			{this.props.thisMenu['@id'].indexOf("spare-parts") > -1 ?
				<a className="nav-link" title={this.props.thisMenu.title}  href={env.PARTS_FINDER_ENABLED ? env.PARTSFINDER : env.SWAN + "epc"} target={env.PARTS_FINDER_ENABLED ? "_blank" : ""} rel="noopener noreferrer" >
					{this.props.thisMenu.title}
				</a>
			: this.props.thisMenu['@id'].indexOf('accessories') > -1 && this.isInUserMenuObject("acc/") ?
				<a className="nav-link" title={this.props.thisMenu.title} href={env.SWAN + "acc"}>
	        {this.props.thisMenu.title}
	      </a>
			:
				<Link className={((common.getBackendUrl(this.props.thisMenu['@id']).search(common.getUrlPath(this.props.pathname).substring(3)) !== -1) ? "active" : "") + " nav-link"} title=	{this.props.thisMenu.title}  to={common.getUrlPath(this.props.thisMenu["@id"])}>
					{this.props.thisMenu.title}
				</Link>
			}
		</li>
		)
	}
}

export default Sidebar;
