import React from 'react';

import Loader from '../Loader/Loader';

const env = require("../../config.js").default;

class LoaderView extends React.Component {
  componentDidMount(){
        if (window.cc.companyInfo === undefined){
      document.title = "RMI - Service"
    }else{
      document.title = window.cc.companyInfo.service + " Service" + " - Login";
    }
  }
  render(props){
    return (
        <div className="content_testo tab-content">
					<div id="content-core">
						<div className="row" style={{alignItems: "center", height: "calc(100vh - var(--header-height) - var(--footer-height))"}}>
							<div className="col-xl-12 text-center content_error">
			          <Loader></Loader>
              </div>
						</div>
					</div>
        </div>
    )
  }
}

export default LoaderView;
