import React from 'react';
import { Link } from "react-router-dom";
import '../../css/base.css';
import '../../css/page-body.css';
var Chart = require("chart.js");
const common = require('../common/common');
const env = require("../../config.js").default;
// README https://www.chartjs.org/docs/
// Data generation
function getRandomArray(numItems) {
  // Create random array of objects
  let names = ['spazio totale', 'files', 'video', 'immagini', 'pdf / documenti'];
  let data = [];
  for(var i = 0; i < numItems; i++) {
    data.push({
      label: names[i],
      value: Math.round(20 + 80 * Math.random())
    });
  }
  return data;
}

function getRandomDateArray(numItems) {
  // Create random array of objects (with date)
  let data = [];
  let baseTime = new Date('2018-05-01T00:00:00').getTime();
  let dayMs = 24 * 60 * 60 * 1000;
  for(var i = 0; i < numItems; i++) {
    data.push({
      time: new Date(baseTime + i * dayMs),
      value: Math.round(20 + 80 * Math.random())
    });
  }
  return data;
}

function getData() {
  let data = [];

  data.push({
    title: 'Visite',
    data: getRandomDateArray(20)
  });

  data.push({
    title: 'Categorie',
    data: getRandomArray(5)
  });

  data.push({
    title: 'Categorie',
    data: getRandomArray(5)
  });

  data.push({
    title: 'Data 4',
    data: getRandomArray(5)
  });

  return data;
}

// BarChart
class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.data.map(d => d.label);
    this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
    // this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'horizontalBar',
      options: {
	      maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max: 100
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.data.map(d => d.label),
        datasets: [{
          label: this.props.title,
          data: this.props.data.map(d => d.value),
          backgroundColor: this.props.color
        }]
      }
    });
  }

  render() {
    return (
        <canvas ref={this.canvasRef} />
    );
  }
}

// LineChart
class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.data.map(d => d.time);
    this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
    // this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'line',
      options: {
			  maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'week'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                min: 0
              }
            }
          ]
        }
      },
      data: {
        labels: this.props.data.map(d => d.time),
        datasets: [{
          label: this.props.title,
          data: this.props.data.map(d => d.value),
          fill: 'none',
          backgroundColor: this.props.color,
          pointRadius: 2,
          borderColor: this.props.color,
          borderWidth: 1,
          lineTension: 0
        }]
      }
    });
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}

// Doughnut
class DoughnutChart extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {
    this.myChart.data.labels = this.props.data.map(d => d.label);
    this.myChart.data.datasets[0].data = this.props.data.map(d => d.value);
    // this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.canvasRef.current, {
      type: 'doughnut',
      options: {
	      maintainAspectRatio: false
      },
      data: {
        labels: this.props.data.map(d => d.label),
        datasets: [{
          data: this.props.data.map(d => d.value),
          backgroundColor: this.props.colors
        }]
      }
    });
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}


// spazio acquistato e spazio occupato nel portale documentale.
// puoi iniziare ad impostare delle costanti, abbozzare la parte grafica con grafico stile pie
// e poi decidiamo dove salvare i dati.

class Statistiche extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
		this.state.render = false;
		this.state = {
 		 data: getData()
 	 };
	}

	componentWillMount(){
		document.getElementById('body').className='statistiche';
		common.setStateUserInfo(this);
	}

	componentDidMount(){
		window.setInterval(() => {
 		 this.setState({
 			 data: getData()
 		 })
 	 }, 5000);

		document.title = document.title = window.cc.companyInfo.service + " Service";
	}

	render(props){
		return (
			<div id="content_page">
        <div className="row">

          <div className="col-md-6 col-xs-12">

          </div>
          <div className="col-md-6 col-xs-12">
  			    <LineChart
  				  	data={this.state.data[0].data}
  			      title={this.state.data[0].title}
  					  color="#3E517A"
  		      />
  				</div>
        </div>
        <div className="row">
        <div className="col-md-6 col-xs-12">

        </div>
          <div className="col-md-6 col-xs-12 col-lg-4" style={{position: "relative", float: "left"}}>
					<BarChart
						data={this.state.data[1].data}
						title={this.state.data[1].title}
						color="#70CAD1"
	        />
        </div>
        </div>
        <div className="row">
        <div className="col-md-6 col-xs-12">

        </div>
        <div className="col-md-6 col-xs-12 col-lg-4" style={{position: "relative", float: "left"}}>
			    <DoughnutChart
						data={this.state.data[1].data}
						title={this.state.data[1].title}
						colors={['#0eb452', '#c4639c', '#70cad1', '#3e517a', '#f3501e', '#BBB6DF']}
	        />
				</div>
      </div>
      </div>
		)
	}
}

export default Statistiche;
