import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Navigate } from "react-router-dom";
import moment from 'moment';
const common = require('../common/common');
const env = require("../../config.js").default;
try {
  require("moment/locale/" + common.getLanguageMoment() + ".js");
} catch (err) {
  require("moment/locale/en-gb.js");
}
class DefaultTable extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.pathname = this.props.pathname;
    this.state.recordTabella = {};
    this.state.recordTabella.items = [];
    this.state.customTag = <></>;
    this.changePage  = this.changePage.bind(this);
    this.state.showAllContents = 	this.props.showAllContents;
    this.state.userInfo = {};
    this.state.userInfo.roles = [];
    this._isMounted = false;
  }

  async handleDownloadFile(id,fileurl){
    const $ = window.$;
    $(".downloadFileLoader_" + id).css("display", "inline");
    $(".downloadIconFile_" + id).css("display", "none");
    await common.apiCall(fileurl, "GET", {}, true, false, "json")
    .then(data => {
      var jsonFile = data.file;
      var numItem = 0;
      if(data.relatedItems !== undefined && data.relatedItems.length > 0){
        numItem = common.object_value_in_array(data.relatedItems, "@type", "File");
        if(numItem > -1)
          jsonFile = {
            download: data.relatedItems[numItem]['@id'] + "/@@download/file/" + data.relatedItems[numItem]['title'],
            filename: data.relatedItems[numItem]['title'],
          }
      }
      common.apiCall(jsonFile.download, "GET", {}, true, false, "blob")
      .then(result => {
        var element = document.createElement('a');
        element.setAttribute('href', result);
        element.setAttribute('download', jsonFile.filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
        $(".downloadFileLoader_" + id).css("display", "none");
        $(".downloadIconFile_" + id).css("display", "inline");
      });

      return data;
    });
    return;
  }

  UNSAFE_componentWillMount(props){
    const $ = window.$;
    common.getUserInfo().then(userInfo =>{
      this.setState({userInfo: userInfo});
      //CONTROLLO SHOW ALL CONTENTS
      if(this.props.pageContent !== undefined && this.props.pageContent !== null && (typeof this.props.pageContent).toLowerCase() === "object" && Object.keys(this.props.pageContent).length > 0)
        this.setState({recordTabella :  this.props.pageContent});

      if(this.state.userInfo.roles.indexOf("Reviewer") <= -1){
        if(this.state.showAllContents === "false"){
          if(this.props.paginaDati.filtro_modello !== undefined && this.props.paginaDati.filtro_modello !== '')
            this.setState({recordTabella :  this.props.pageContent});
          else
            this.setState({recordTabella: {}});
          $('.select_modelli').on('select2:select keyup select2:unselect', (e) => {
            if(!e.target.value)
              this.setState({recordTabella: {}});
            else
              this.setState({recordTabella :  this.props.pageContent});
          });
        }
      }

      var pagineTotali = parseInt(this.state.recordTabella.items_total / env.TABLE_PAGE_LENGTH);
      if((this.state.recordTabella.items_total % env.TABLE_PAGE_LENGTH) > 0){
        pagineTotali ++;
      }

      var newPage = common.getParam("page");
      if(newPage !== null && parseInt(newPage))
        newPage = parseInt(newPage)
      else
        newPage = 1;

      if(newPage>pagineTotali)
        newPage = pagineTotali;
      if(newPage<1)
        newPage = 1;

      this.props.onClick("paginazione_custom", {vaiPagina: newPage} );
      var modelNotSelected = (common.get_localStorage("valoreModello") === undefined || common.get_localStorage("valoreModello") === null || common.get_localStorage("valoreModello") === '');
      if (this.state.userInfo !== undefined && this.state.userInfo.roles.indexOf("Reviewer") <= -1 && this.state.showAllContents === "false" && modelNotSelected){
        $("#tabella_all").hide()
        $("#cerca").hide()
        $(".tab active").hide()}
        else {
        $("#tabella_all").show()
          $("#cerca").show()
          $("#ricercaTabella").show()
        }
    });
  }
  
  componentDidMount(props){
    const $ = window.$;
    this._isMounted = true;
    $("#table_DefaultTable").DataTable({
      responsive: true,
      "retrieve": true,
      "tabIndex": -1,
      "pageLength": env.TABLE_PAGE_LENGTH,
      "lengthChange": false,
      "paging": false,
      "info": false,
      "ordering": false,
      "searching": false,
      "autoWidth":	true,
      data: this.state.recordTabella.items,
      columns: [
        {
          title: common.getTrad("Titolo"),
          data: 'title',
          "width": "10px",
          "class": 'sortable_title sorting descriptionTable',
          "render": (data,type,row) => {
            var ret = '';
            if(data !== null && data !== '' && data !== undefined){
              if(data.length > 75){
                ret = <>{truncateOnWord(data, 75)}</>;
              }else{
                ret = <>{data}</>;
              }
            }

            return ReactDOMServer.renderToStaticMarkup(
              <>
                {ret}
              </>
            )
          }
        },
        {
          title: common.getTrad("Descrizione"),
          data: null,
          "class": "descriptionTable",
          "width": "10px",
          "render":  (data,type,row,meta) => {
            var ret = '';
            if(row.description !== null && row.description !== '' && row.description !== undefined){
              if(row.description.length > 150){
                ret = <>{truncateOnWord(row.description, 150)}</>;
              }else{
                ret = <>{row.description}</>;
              }
            }
            return ReactDOMServer.renderToStaticMarkup(
              <>
                <span className="descrText">
                  {ret}&nbsp;
                </span>
                {row.subjects !== undefined && row.subjects.indexOf("sw-has-file") > -1 ?
                  <span className="ContentcellTools descrText" id={"ContentcellTools_" + meta.row} data-btn-download={meta.row}>
                    <button style={{display: "flex", width: "42px"}} className="bottone_default btn downloadButton float-righ" id={"downloadButton_" + meta.row} data-btn-download={meta.row}>
                      <span className={"downloadIconFile_" + meta.row} data-btn-download={meta.row}>
                        <i className="fas fa-download" data-btn-download={meta.row}></i>
                      </span>
                      <span className={"downloadFileLoader downloadFileLoader_" + meta.row}><i className="fas fa-spinner fa-spin"></i></span>
                    </button>
                  </span>
                :
                  <></>
                }
              </>
            );
          }
        },
        {
          title: common.getTrad("Data"),
          data: null,
          "width": "10px",
          type: 'datetime-moment',
          "class": 'modified sorting sorting_desc',
          "render": function ( data, type, row) {
            return moment(row.modified).format('LLL')
          }
        },
        {
          title: common.getTrad("Stato"),
          data: "review_state",
					"class": 'review_state sorting sw_show_review_state',
        	"type": "string",
          "width": "1px",
					"render": (data,type,row) => {
            var icon_title, icon_class;
            if(row.sw_show_review_state && row["@components"] !== undefined && row["@components"]["workflow"] !== undefined){
              var availableActions = row["@components"]["workflow"]["transitions"];
              for (let i = 0; i < availableActions.length; i++) {
                if(availableActions[i]['@id'].indexOf("revoca") > -1 ){
                  icon_title = "Pubblico";
                  icon_class = "fa-users fas text-success";
                }else{
                  icon_title = "Privato";
                  icon_class = "fa-user-shield fas text-danger";
                }
              }
							return ReactDOMServer.renderToStaticMarkup(
                <i className={icon_class} title={common.getTrad(icon_title)}></i>
              );
						}
            return <></>
          }
        },
        {
          "class": "DT_bottone_link",
          "data": null,
          "render": ( data, type, row, meta ) => {
            return ReactDOMServer.renderToStaticMarkup(
              <button data-link={common.getUrlPath(row["@id"])} className="DT_bottone btn bottone_default">
                {common.getTrad("Continua a leggere")}
              </button>
            )
          }
		    },
      ],
      "language": {
				"url": env.STATIC + "lib/datatables/i18n/" + common.getLanguage() + ".lang"
			},
      fnRowCallback: ( nRow, aData, iDisplayIndex, iDisplayIndexFull ) => {
				$(nRow).on('click', (e) => {
          e.preventDefault();
          if(e.target.cellIndex !== undefined || e.target.className.indexOf('descrText') > -1){
            if($("#table_DefaultTable").hasClass("collapsed")){
              if(e.currentTarget.classList[1] === "parent" &&  e.target.cellIndex > 0){
                this.changePage(common.getUrlPath(aData["@id"]));
                return false;
              }
              if(e.target.cellIndex === undefined || e.target.cellIndex > 0){
                this.changePage(common.getUrlPath(aData["@id"]));
                return false;
              }
            }else{
              this.changePage(common.getUrlPath(aData["@id"]));
              return false;
            }
          }else{

            if(aData.subjects !== undefined && aData.subjects.indexOf("sw-has-file") > -1){
              this.handleDownloadFile(iDisplayIndex, aData['@id'], aData.id);
              return false;
            }
          }
				});
      },
    });

    $("#table_DefaultTable").DataTable().responsive.recalc();
    $('table').resize();

    $('#table_DefaultTable thead').click((e) => {

      var versoOrdinam = 0;
      if($(e.target).hasClass("sorting")){

        if(this.props.paginaDati.verso_ordinamento === 1){
          versoOrdinam = -1;
          $(".sorting_disabled").removeClass("sorting_asc");
          $(e.target).addClass("sorting_desc");
        }else{
          versoOrdinam = 1;
          $(".sorting_disabled").removeClass("sorting_desc");
          $(e.target).addClass("sorting_asc");
        }

        var parametri = {
          tipoOrdinam: e.target.className.split(" ")[1],
          versoOrdinam: versoOrdinam
        }
        this.props.onClick("ordinamento", parametri );
      }
    });

    $(".search_tabella").keypress((e) =>{
      if(e.which === 13 || e.which === 10){
        e.preventDefault();
        $("#ricercaTabella").click();
      }
    });

    $('input.search_tabella').on("change keyup cut search", (e) => {
      if(this.props.paginaDati.testo_cercato !== undefined && this.props.paginaDati.testo_cercato !== null && this.props.paginaDati.testo_cercato !== ''){
        e.preventDefault();
        if(!e.target.value)
          this.props.onClick("cerca", {testo: ''});
      }
    });

    $("#ricercaTabella").click((e) => {
      e.preventDefault();
      const $ = window.$;
      var parametri = {
        testo: $("input.search_tabella").val()
      }
      this.props.onClick("cerca", parametri );
    });

    $("#table_DefaultTable").on("click", ".DT_bottone", (e) => {
       this._isMounted && this.setState({
        customTag: (<Navigate to={$(e.target).data('link')} push={true}/>)
      });
    });

    $("#table_DefaultTable").on("click", ".ContentcellTools ", (e) => {
      if($("#table_DefaultTable").hasClass("collapsed")){
        var rowNum = $(e.target).data('btn-download');
        if(this.state.recordTabella.items[rowNum].subjects !== undefined && this.state.recordTabella.items[rowNum].subjects.indexOf("sw-has-file") > -1)
          this.handleDownloadFile(rowNum, this.state.recordTabella.items[rowNum]['@id']);
        return false;
      }
    });
  }

  paginazioneCustomClick(e){
    e.preventDefault();
    var parametri = {
      vaiPagina: e.target.id.split("_")[2]
    }
    this.props.onClick("paginazione_custom", parametri );
  }

  componentDidUpdate(nextProps){
    const $ = window.$;
    var pagina = nextProps.paginaDati;
    var paginaAttuale = pagina.pagina_attuale, pagineTotali = '';

    pagineTotali = parseInt(this.state.recordTabella.items_total / env.TABLE_PAGE_LENGTH);
    if((this.state.recordTabella.items_total % env.TABLE_PAGE_LENGTH) > 0){
      pagineTotali ++;
    }

    var buttons = [];
    var paginaTemp = '';
    $('#paginazione_custom').html("");
    if(pagineTotali > 1){
			var paginazione_custom_content = "";
      const NUM_PAGINE_VISIBILI = 3;
      const SALTO_PAGINE = 5;
      var start=paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - SALTO_PAGINE;
      var end=paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + SALTO_PAGINE;
      var allPreviousMax = (SALTO_PAGINE - parseInt(NUM_PAGINE_VISIBILI/2));
      var allNextMax = pagineTotali - allPreviousMax;
      var startCiclo = (start <= 0 ? 1 : start);
      var endCiclo = (end > pagineTotali ? pagineTotali : end);

      for(var i=startCiclo; i<=endCiclo; i++){
        var print = false;
        var ellipsis = false;

        if((paginaAttuale <= allPreviousMax && i < paginaAttuale) || (paginaAttuale > allNextMax && i > paginaAttuale))
          print = true;
        if(i >= (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) && i <= (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1))
          print = true;
        if(i === startCiclo || i === endCiclo || i === paginaAttuale)
          print = true;
        if(i !== paginaAttuale && ((i === (paginaAttuale - parseInt(NUM_PAGINE_VISIBILI/2) - 1) &&  paginaAttuale >= allPreviousMax) || (i === (paginaAttuale + parseInt(NUM_PAGINE_VISIBILI/2) + 1) && paginaAttuale <= allNextMax)))
          ellipsis = true;

        if(print)
          buttons.push('<li class="paginate_button page-item ' + (paginaAttuale===i ? "active" : "") + '"><p id="page_num_' + i +'" class="page-link">' + (ellipsis ? "&hellip;" : i) + '</p></li>');
      }

			for (var i=0; i<buttons.length; i++){
				if(buttons[i] !== undefined && buttons[i] !== null){
					$('#paginazione_custom').html(paginazione_custom_content + buttons[i]);
					paginazione_custom_content = $('#paginazione_custom').html();
				}
			}
		}


    $("#table_DefaultTable").DataTable().clear();
    if(this.state.userInfo.roles.indexOf("Reviewer") <= -1){
      if(this.state.recordTabella !== undefined){
        if(this.state.recordTabella.items_total > 0){
          $("#table_DefaultTable").DataTable().rows.add(this.state.recordTabella.items);
        }
      }
    }else{
      if(this.state.recordTabella.items_total > 0){
        $("#table_DefaultTable").DataTable().rows.add(this.state.recordTabella.items);
      }
    }
    if(this.state.userInfo !== undefined && this.state.userInfo.userInfo !== undefined && this.state.userInfo.userInfo.FADMI !== 'P'){
      $("body").append('<style>.sw_show_review_state{display:none}</style>');
    }

    $("#table_DefaultTable").DataTable().draw();
    $("#table_DefaultTable").DataTable().responsive.recalc();
    $('table').resize();
    $("table>tbody>tr").attr("role", "row");
    var modelNotSelected = (common.get_localStorage("valoreModello") === undefined || common.get_localStorage("valoreModello") === null || common.get_localStorage("valoreModello") === '');
    if (this.state.userInfo !== undefined && this.state.userInfo.roles.indexOf("Reviewer") <= -1 && this.state.showAllContents === "false" && modelNotSelected){
      $("#tabella_all").hide()
      $("#cerca").hide()
      $("#ricercaTabella").hide()
    }else{
      $("#tabella_all").show()
      $("#cerca").show()
      $("#ricercaTabella").show()
    }
  }

  changePage(id){
    this._isMounted && this.setState({
      customTag: (<Navigate to={id} push={true}/>)
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render(props){
    var modelNotSelected = (common.get_localStorage("valoreModello") === undefined || common.get_localStorage("valoreModello") === null || common.get_localStorage("valoreModello") === '');
    return(
      <>
        {this.state.userInfo !== undefined && this.state.userInfo.roles.indexOf("Reviewer") <= -1 && this.state.showAllContents === "false" && modelNotSelected ?
            <div className='row'>  
              <div className="sw-alert alert alert-info col-xl-8 col-md-8 col-sm-12 col-12" style={{top: '105px'}} role="alert">
                {common.getTrad("Per poter visualizzare i contenuti devi selezionare un modello dall'elenco.")}
              </div>
              <div className='bounce-animation col-xl-4 col-md-4 col-sm-6'>
                <span className='arrow-rmi ' style={{ top: '-45px' }}></span>
                <span className='arrow-rmi2' style={{ top: '-45px' }}></span>
                <span className='arrow-rmi3' style={{ top: '-45px' }}></span>
                <span className='traits' style={{ top: '-45px' }}></span>
              </div>
            </div> 
        :
          <></>
        }
        <div className="tab active" id='tabella_all'>
          {this.state.customTag}
          <div className="offset-xl-8 col-xl-4 col-sm-12 mb-2">
            <div className="input-group ">
              <input className="form-control search_tabella" name="q" id='cerca' type="search" placeholder={common.getTrad("Cerca")} aria-label="Cerca"/>
              <button className="btn btn-outline-light bottone_default" id="ricercaTabella" type="submit">
                <i className="fas fa-search"></i> {common.getTrad("Cerca")}
              </button>
            </div>
          </div>

          <table id="table_DefaultTable" className="table table-striped table-bordered dt-responsive" style={{width: '100%'}}></table>

          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              {this.state.recordTabella.items_total > 0 ?
                <div className="col-lg-5 col-sm-12 col-xs-12">
                  <div className="dataTables_info">
                    {common.getTrad("Elementi mostrati") + " (" + this.state.recordTabella.items.length + ") " + common.getTrad(" su un totale di") +" (" + this.state.recordTabella.items_total+ ")"}
                  </div>
                </div>
              :
                <></>
              }
              {this.state.recordTabella.items_total > env.TABLE_PAGE_LENGTH ?
                this.state.recordTabella.batching ?
                <div className="col-lg-7 col-sm-12 col-xs-12">
                  <div className="dataTables_paginate paging_full_numbers" id="tickets_table_paginate">
                    <ul className="pagination">
                      <li onClick={() => {if(this.state.recordTabella.batching.prev) this.props.onClick("paginazione_custom", {vaiPagina: 1})}}
                          className={(!this.state.recordTabella.batching.prev ? "disabled" : "") + " paginate_button page-item first"}
                          title={common.getTrad("Inizio")}>
                        <p className="page-link"><i className="fas fa-fast-backward"></i></p>
                      </li>
                      <li onClick={() => {if(this.state.recordTabella.batching.prev) this.props.onClick("paginazione_custom", {calcolaPagina: -1})}}
                          className={(!this.state.recordTabella.batching.prev ? "disabled" : "") + " paginate_button page-item previous"} id="table_DefaultTable_previous"
                          title={common.getTrad("Precedente")}>
                        <p className="page-link"><i className="fas fa-step-backward"></i></p>
                      </li>

                      <div style={{display: 'flex'}} id="paginazione_custom" onClick={(e) => this.paginazioneCustomClick(e)}></div>

                      <li onClick={() => {if(this.state.recordTabella.batching.next) this.props.onClick("paginazione_custom", {calcolaPagina: 1})}}
                          className={(!this.state.recordTabella.batching.next ? "disabled" : "") + " paginate_button page-item next"} id="table_DefaultTable_next"
                          title={common.getTrad("Successivo")}>
                        <p className="page-link" ><i className="fas fa-step-forward"></i></p>
                      </li>
                      <li onClick={() => {if(this.state.recordTabella.batching.next) this.props.onClick("paginazione_custom", {vaiPagina: "end"})}}
                          className={(!this.state.recordTabella.batching.next ? "disabled" : "") + " paginate_button page-item last"}
                          title={common.getTrad("Fine")}>
                        <p className="page-link"><i className="fas fa-fast-forward"></i></p>
                      </li>
                    </ul>
                  </div>
                </div>
                :
                  <></>
              :
                <></>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

function truncateOnWord(str, limit) {
  var trimmable = '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
  var reg = new RegExp('(?=[' + trimmable + '])');
  var words = str.split(reg);
  var count = 0;
  return words.filter(function(word) {
      count += word.length;
      return count <= limit;
  }).join('') + "...";
}

export default DefaultTable;
