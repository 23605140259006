import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import GestioneContenutiSidebar from "../Sidebar/GestioneContenutiSidebar";
import EditContentDefault from '../Content/editContentDefault';
import EditContact from '../Content/editContact';
import EditTicket from '../Content/editTicket';
import EditTicketVehicle from '../Content/editTicketVehicle';
import LoaderView from './loader';

const common = require('../common/common');
const env = require("../../config.js").default;

class EditContent extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.state.render = false;
    this._isMounted = false;
    this.state.new_title = false;
    this.state.pageContent = {};
    this.state.pathname = "";
    this.state.pageContent.description = {};
    this.state.pageContent.parent = {};
    this.state.pageContent.description.filters = {};
    this.state.parentPathname = {};
    this.state.description = {}
    this.state.tipoContento = '';
    this.state.tipologie_ticket_attive = common.getTicketTypesByBrand();
    this.state.marche_attive = common.getAppEnabledVM();
  }

  UNSAFE_componentWillMount(props){
    try{
      if(this.props.pageContent.is_folderish){
        this.props.pageContent.description = JSON.parse(this.props.pageContent.description);
        this.setState({description: this.props.pageContent.description});

      }else{

        this.props.pageContent.parent.description = JSON.parse(this.props.pageContent.parent.description);
        this.setState({description: this.props.pageContent.parent.description});
        common.apiCall(common.getBackendUrl(this.props.pageContent.parent["@id"] + "/@translations"), "GET", {}, true)
    		.then(parentPageContent=>{
    			if(parentPageContent.error === false){
    			  this.setState({parentPageContent});
            this.setState({render: true});
            window.translations.parent = parentPageContent.items;
    			}
    		});
       }
    }catch(e){}

    var translations = (this.props.pageContent.is_folderish ?
      this.props.pathname.substring(3)
    :
      this.props.pathname.substr(0, this.props.pathname.lastIndexOf("/")).substring(3)
    );
    common.apiCall(common.getBackendUrl(env.BACKEND + common.getLanguage() + translations), "GET", {}, true)
    .then(translationsContent=>{
      if(translationsContent.error === false){
        this.setState({pageTitle: translationsContent.title, new_title: true});
      }
    });

    this.setState({pageContent :  this.props.pageContent});
    this.setState({pathname : this.props.pathname});
    this.setState({render: true});
    window.translations.items  = this.props.pageContent['@components'].translations;
  }

  componentDidMount(){
    document.title = document.title = window.cc.companyInfo.service + " Service";
    this._isMounted = true;
    var description = this.state.pageContent.is_folderish ? this.state.pageContent.description : this.state.pageContent.parent.description;
    try{
      description = this.state.pageContent.is_folderish ? JSON.parse(this.state.pageContent.description) : JSON.parse(this.state.pageContent.parent.description);
    }catch(error){}

    this._isMounted && this.setState({tipoContento: description.contentType});
    var contentType = description.contentType;

    if(this.props.roles && this.props.roles.indexOf("Reviewer") > -1 && contentType === "SWContent"){

      var data = {"expand.navigation.depth": 3};
      var url = env.BACKEND + common.getLanguage() + '/@navigation';
      common.apiCall(url, "GET", data, true, true)
      .then(menuAreaButtons=>{
        if(menuAreaButtons.error === false){
          this._isMounted && this.setState({menuAreaButtons, render1: true});
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goToCMS(target){
    window.location.href = common.getFrontendUrl(target);
  }

  renderSwitchEditPage() {
    switch(this.state.pageContent.parent.description.contentType ? this.state.pageContent.parent.description.contentType : this.state.pageContent.description.contentType){
      case 'contact':
        return (<EditContact  pageTitle={this.state.pageTitle} 
                              pathname={this.state.pathname} 
                              pageContent={this.state.pageContent}></EditContact>)
      case 'ticket':
        return (<EditTicket marche_attive={this.state.marche_attive} 
                            tipologie_ticket_attive={this.state.tipologie_ticket_attive} 
                            pageTitle={this.state.pageTitle} 
                            pathname={this.state.pathname} 
                            pageContent={this.state.pageContent}></EditTicket>)
      case 'ticket_vehicle':
        return (<EditTicketVehicle  marche_attive={this.state.marche_attive} 
                                    tipologie_ticket_attive={this.state.tipologie_ticket_attive} 
                                    pageTitle={this.state.pageTitle} 
                                    pathname={this.state.pathname} 
                                    pageContent={this.state.pageContent}></EditTicketVehicle>)
      case 'SWContent':
        return (<EditContentDefault pageTitle={this.state.pageTitle} 
                                    pathname={this.state.pathname} 
                                    pageContent={this.state.pageContent}></EditContentDefault>)
      default:
        return (<EditContentDefault pageTitle={this.state.pageTitle} 
                                    pathname={this.state.pathname} 
                                    pageContent={this.state.pageContent}></EditContentDefault>)
    }
  }

  render(props){
    return (
      <>
      { this.state.render && this.state.new_title ?
        <div id="portal-columns" className="clearfix">
 		      <div className="row">
          {window.cc.gestioneContenutiFlag && this.props.roles && this.props.roles.indexOf("Reviewer") > -1  && this.state.tipoContento === "SWContent" ?
            <GestioneContenutiSidebar
              pathname={(this.state.pageContent.is_folderish ? common.getUrlPath(this.state.pathname.substring(1)) : common.getUrlPath(this.state.pageContent.parent["@id"]))}
              menuAreaButtons={this.state.menuAreaButtons}
              render1={this.state.render1}
              onClick={(target) => this.goToCMS(target)}
            ></GestioneContenutiSidebar>
          :
            <Sidebar
              pathname={this.state.pageContent.is_folderish ? this.state.pathname : common.getUrlPath(this.state.pageContent.parent["@id"])}
              parentPathname={
                this.state.pageContent.is_folderish ?
                  (common.getUrlPath(this.state.pageContent.parent['@id']).split('/').length === 4 ? this.state.pageContent.parent['@id'] : this.state.pageContent['@id'])
                :
                  common.getUrlPath(this.state.pageContent.parent['@id']).split('/').length === 3 ?
                    common.getUrlPath(this.state.pageContent.parent["@id"].split('/').slice(0, this.state.pageContent["@id"].split('/').length - 1).join('/'))
                  :
                    common.getUrlPath(this.state.pageContent.parent['@id']).split('/').length === 4 ?
                      common.getUrlPath(this.state.pageContent["@id"].split('/').slice(0, this.state.pageContent["@id"].split('/').length - 1).join('/'))
                    :
                      common.getUrlPath(this.state.pageContent.parent["@id"].split('/').slice(0, this.state.pageContent.parent["@id"].split('/').length - 1).join('/'))
            }></Sidebar>
          }
           {this.renderSwitchEditPage()}
          </div>
        </div>
      :
        <LoaderView></LoaderView>
      }
      </>
    )
  }
}
export default EditContent;
