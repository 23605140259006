import React from 'react';

const common = require('../common/common');
const env = require("../../config.js").default;

class FilterNations extends React.Component{
  constructor(props){
		super(props);
    this.state = {};
    this.state.tableType = this.props.tableType !== undefined ? this.props.tableType : '';
    this.state.nazioniPortale = common.getNazioniPortale(false);
  }

  componentDidMount(){
    const $ = window.$;
    $(window).resize(function() {
	  	$('.select2').css('width', "100%");
    });
    
    const addSelectAll = matches => {
      if (matches.length > 0) {
        return [
          { id: 'selectAll', text: common.getTrad('Seleziona tutti'), matchIds: matches.map(match => match.id) },
          ...matches
        ];
      }
    };

    const handleSelection = event => {
      if (event.params.data.id === 'selectAll') {
        $('#select_nazioni').val(event.params.data.matchIds);
        $('#select_nazioni').trigger('change');
      };
    };

    $(".select_nazioni").select2({
      placeholder: common.getTrad("Seleziona una nazione"),
      language: common.getLanguage(),
      allowClear: true,
      width: '100%',
      sorter: addSelectAll,
      dropdownAutoWidth: true,
      tokenSeparators: [',', '', ' ']
    });

    $('#select_nazioni').on('select2:select', handleSelection);

    $('#select_nazioni').on('select2:select select2:unselect', (e) => {
      e.preventDefault();
    });

    $('#select_nazioni').on('select2:clear', (e) => {
      e.preventDefault();
      $("select#select_nazioni")[0].selectedIndex = -1;
      $("select#select_nazioni").trigger('change');
      common.set_localStorage("valoreNazione", JSON.stringify($('#select_nazioni').val()));
      return false;
    });

    if (common.get_localStorage("valoreNazione") !== undefined && common.get_localStorage("valoreNazione") !== null && common.get_localStorage("valoreNazione") !== '') 
      $("select#select_nazioni").val(JSON.parse(common.get_localStorage("valoreNazione"))).trigger("change");

  }

  componentDidUpdate(){
    const $ = window.$;
    
  }

  render(props){
  	return(
      <div className={(this.props.customClass !== undefined ? this.props.customClass : "col-xl-6 col-lg-6") +  " col-md-12 col-sm-12 col-xs-12 row-eq-height"} role="alert" id="ricerca_filtro_modello">
        <div className="col-12 p-0">
          <p>{common.getTrad("Filtra per nazione")}</p>
          <div className="form-group selectSwCustom">
            <select name="select_nazioni" className="form-control select_nazioni" id="select_nazioni" multiple="multiple">
              {this.state.nazioniPortale.map((index, key) => {
                return (
                  <option key={key} value={index.CISO3TNA}>
                    {index.DNAZITNA}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
    )
  }
}

export default FilterNations;
