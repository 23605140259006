import React from 'react';
const common = require('../common/common');

class MultiSelectNazioni extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.state.stopUpdate = false;
  }

  componentDidUpdate(){
    const $ = window.$;
    // in update entro solo la prima volta e ogni volta che viene selezionato un modello nuovo o tolto
    // cambio il semaforo evitando che faccia la render all'infinito
    if(!this.state.stopUpdate && this.props.edit && this.props.nazioniSelected !== undefined){
      var nazioniSelected = this.props.nazioniSelected;
      for (var i = 0; i < nazioniSelected.length; i++) {
        if(nazioniSelected[i] !== undefined && nazioniSelected[i] !== null){
          if(nazioniSelected[i] === "sw-nazione-tutte"){
            $("#selectNazioni input.inputCheck").prop('checked', true);
          }else{
            $("#" + nazioniSelected[i]).prop('checked', true)
          }
        }
      }

      $("#selectNazioni .content_input input:checked").each(function (){
        $("#selectNazioniChecked .content_input." + $(this).val()).show();
      });
    }
  }

  getSelezionatiNazioni(element, mySelect){
    const $ = window.$;
    var elementValue = element.val();
    var nazioniSelected = [];
    if(elementValue === 'sw-nazione-tutte'){
      nazioniSelected = $("#sw-nazione-tutte").is(':checked') ? [elementValue] : [];
    }else{
      nazioniSelected.splice('sw-nazione-tutte', 1);
      var groupCheckedChecks = $(".selezionaTutti:checked", mySelect);
      var singleCheckedChecks = $(".singleCheck:checked", mySelect);

      for (var i = 0; i < groupCheckedChecks.length; i++) {
        if(nazioniSelected.indexOf($(groupCheckedChecks[i]).val()) <= -1)
          nazioniSelected.push($(groupCheckedChecks[i]).val());
      }
      for (var s = 0; s < singleCheckedChecks.length; s++) {
        if(nazioniSelected.indexOf('sw-nazione-tutte') <= -1){
          if(nazioniSelected.indexOf($(singleCheckedChecks[s]).val()) <= -1)
            nazioniSelected.push($(singleCheckedChecks[s]).val());
        }
      }
    }
    this.props.onClick(nazioniSelected);
    $("#selectNazioniChecked .content_input.first_input").show();
    $("#selectNazioniChecked .content_input:not(.first_input)").hide();
    $("#selectNazioni .content_input input:checked").each(function (){
      $("#selectNazioniChecked .content_input." + $(this).val()).show();
    });
  }

  setCheckedNazione(e, update=false){
    this.setState({stopUpdate: update});
    const $ = window.$;
    var mySelect = $("#" + this.props.id);
    var element = $(e.target);

    var checkGroup = $(".selezionaTutti", mySelect);
    var checkedGroup = checkGroup.is(':checked');
    var singleChecks = $(".singleCheck", mySelect);
    var singleCheckedChecks = $(".singleCheck:checked", mySelect);

    var checkTuttiCapo = $(".selezionaTutti#sw-nazione-tutte", mySelect);
    var tuttiChecks = $(".inputCheck", mySelect);

    if(element.attr("id") === 'sw-nazione-tutte'){
      var checkedTuttiCapo = checkTuttiCapo.is(':checked');
      tuttiChecks.prop('checked', checkedTuttiCapo);
      this.getSelezionatiNazioni(element, mySelect);
      return false;
    }

    if(element.attr("id") === checkGroup.attr("id")){
      singleChecks.prop('checked', checkedGroup);
    }else{
      checkGroup.prop('checked',singleCheckedChecks.length === singleChecks.length);
    }

    this.getSelezionatiNazioni(element, mySelect);
    var singleCheckstutti = $(".singleCheck", mySelect);
    var singleCheckedCheckstutti = $(".singleCheck:checked", mySelect);
    checkTuttiCapo.prop('checked',singleCheckstutti.length === singleCheckedCheckstutti.length); // senza [group]
  }

  removeSelectNazione(){
    const $ = window.$;
    var tuttiChecks = $("#selectNazioni input");
    tuttiChecks.prop('checked', false);
    $("#selectNazioniChecked .content_input:not(.first_input)").hide();
    this.props.onClick([]);
  }

  searchNazione(e){
    const $ = window.$;
    var element = $(e.target);
    let captalizeWord = text => text.toLowerCase().split(' ').map( (i, j) => i.charAt(0).toUpperCase()+i.slice(1)).join(' ')

    $("#selectNazioni .content_input:not(:contains('" + captalizeWord(element.val()) + "'))").hide();
    $("#selectNazioni .content_input:contains('" + captalizeWord(element.val()) + "')").show();
  }

  render(props){
    var numSelected = window.$("#selectNazioni .content_input input.singleCheck:checked");
    
    return (
      <div id={this.props.id} className="col p-0">
        {this.props.showInput ?
          <div id="idSearch" className="input_head">
            <input onChange={(e) => this.searchNazione(e)} className="form-control" type="search" placeholder={common.getTrad("Cerca...")} autoFocus="" autoComplete="off" />
            <i className="fas fa-search"></i>
          </div>
        :
          <></>
        }
        <div className={"content_input first_group first_input" + (this.props.showInput ? "" : " text-right input_head pl-2")}>
          {this.props.showInput ?
            <>
              <input type="checkbox" value="sw-nazione-tutte"
                id="sw-nazione-tutte"
                className="selezionaTutti checkboxClean inputCheck"
                onChange={(e) => this.setCheckedNazione(e, true)}
              />&nbsp;
              <label htmlFor="sw-nazione-tutte">
                {common.getTrad("Seleziona tutti")}
              </label>
            </>
          :
            <>
              {numSelected !== undefined ?
                <>
                  {numSelected.length > 0 ?
                    <>
                      <span style={{textShadow: "0 0 black"}}>{numSelected.length}</span>&nbsp;
                      {numSelected.length === 1 ? common.getTrad("selezionato") : common.getTrad("selezionati")}
                      <span className="float-right col">
                        <span style={{cursor: 'pointer'}} onClick={() => this.removeSelectNazione()}>{common.getTrad("Deseleziona tutti")}</span>
                      </span>
                    </>
                  :
                    common.getTrad("Nessuno selezionato")
                  }
                </>
              :
                <div>&nbsp;</div>
              }
            </>
          }
        </div>
        <div className="content_scrollbar_multiselect">
          {this.props.nazioni.map((nazione, index) => {
            return (
              <div style={{display: this.props.showInput ? '' : 'none'}} className={"content_input " + nazione.id} key={index}>
                {this.props.showInput ?
                  <input className="singleCheck inputCheck checkboxClean"
                    onChange={(e) => this.setCheckedNazione(e, true)} type="checkbox"
                    value={nazione.id} id={nazione.id}
                  />
                :
                  <></>
                }
                <label htmlFor={nazione.id} title={nazione.text}>
                  <>
                    {nazione.text}
                    <p style={{display: 'none'}}>{nazione.text}</p>
                  </>
                  {this.props.showDelete ?
                    <span title={common.getTrad("rimuovilo dall'elenco")} className="removeCheck"></span>
                  :
                    <></>
                  }
                </label>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default MultiSelectNazioni;
