import React from 'react';

const common = require('../common/common');
const env = require("../../config.js").default;

class FilterShowInactive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate() {
        const $ = window.$;
        if ((common.get_localStorage("show_inactive") !== undefined && common.get_localStorage("show_inactive") !== null && common.get_localStorage("show_inactive") === 'true') || (common.get_localStorage("show_inactive") === undefined)) {
            $('#checkContExpired').prop("checked", true);
        }
    }

    handleCheckboxChange = (e) => {
        var parametri = {
            checked: e.target.checked,
        }
        common.set_localStorage("show_inactive", parametri.checked);
        this.props.onClick("show_inactive", parametri);
    }

    render(props) {
        return (
            <div className="col-md-12 col-xl-12 col-sm-12 mt-2 d-flex align-items-center">
                <div className="checkbox-wrapper-3 pt-2">
                    <input
                        id="checkContExpired"
                        className="checkbox-wrapper"
                        type="checkbox"
                        onChange={this.handleCheckboxChange}
                    />
                    <label htmlFor="checkContExpired" className="toggle">
                        <span></span>
                    </label>
                </div>
                <span className="pl-2">{common.getTrad("Mostra scaduti")}</span>
            </div>
        )
    }
}

export default FilterShowInactive;