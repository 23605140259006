import React from 'react';
import { Route, Routes, Link, Redirect } from "react-router-dom";
import LoaderView from './loader';
import {doLogout} from './logout';

import bootbox from 'bootbox';
import '../../css/subscribe.css';
const common = require('../common/common');
const env = require("../../config.js").default;

class Subscribe extends React.Component {
    constructor(props){
      super(props);
      this.state = {};
      this.state.renderBoxPrezzi = false;
      this.state.renderConfirmPage = false;
      this.state.renderButtonLogin = false;
      this.state.renderButtonRetry = false;
      this.state.title = ""
      this.state.description = ""
      this.state.userInfo = {};
      this.state.userInfo.userInfo = {};
    }

    UNSAFE_componentWillMount(){
      common.getUserInfo().then(userInfo =>{
        this.setState({userInfo: userInfo});
      })

      var codeProduct = common.getParam("codeProduct");
      var brandProduct = common.getParam("brandProduct");
      var token = common.getParam("token");
      var paymentId = common.getParam("paymentId");
      if (paymentId === null && token === null && codeProduct !== null && brandProduct !== null)
        this.acquistaAbbonamento(codeProduct, brandProduct);

      if (paymentId === null && token === null && codeProduct === null && brandProduct === null)
        window.location.href = env.FRONTEND;
    }

    componentDidMount(){
      if(this.props.confirmPayment){
        var state = common.getParam("state");
        var paymentId = common.getParam("paymentId");
        var token = common.getParam("token");
        var PayerID = common.getParam("PayerID");
        var brand = common.getParam("brand");

        if( state === undefined || state === null || state === "" ||
            paymentId === undefined || paymentId === null || paymentId === "" ||
            token === undefined || token === null || token === "" ||
            PayerID === undefined || PayerID === null || PayerID === "")
          this.setState({renderBoxPrezzi: true});
        else{
          if(parseInt(state) === 1)
            this.setState({renderBoxPrezzi: true});
          else if(parseInt(state) === 0){
            this.setState({title: common.getTrad("Verifica pagamento in corso")});
            this.setState({description: <i className="fas fa-spinner fa-spin"></i>});
            this.setState({renderConfirmPage: true});
            this.verifyPayment(state, brand, paymentId, token, PayerID);
          }else{
            this.setState({title: common.getTrad("Impossibile verificare il pagamento")});
            this.setState({description: <>{common.getTrad("Errore stato pagamento non previsto.")}<br />{common.getTrad("Per problemi contattare l'assistenza tramite l'indirizzo email assistenza@softway.it indicando gli estremi del pagamento")}</>});
            this.setState({renderConfirmPage: true});
          }
        }

      }else{
        this.setState({renderBoxPrezzi: true});
      }
    }

    verifyPayment(state, brand, paymentId, token, PayerID){
      common.apiCall(env.BACKEND + "@sw-confirm-payment", "POST", {"state" : state, "brand": brand, "paymentId" : paymentId, "token" : token, "PayerID" : PayerID }, true)
      .then((response) =>{
        if(response.error === false){
          if((response.errorCode !== undefined && response.errorCode !== null && response.errorCode !== "") || response.status !== 200)
            if(response.message !== undefined && response.message !== null && response.message !== ""){
              this.setState({title: common.getTrad("Impossibile verificare il pagamento")});
              this.setState({description: <>{response.message}<br />{common.getTrad("Per problemi contattare l'assistenza tramite l'indirizzo email assistenza@softway.it indicando gli estremi del pagamento")}</>});
              this.setState({renderButtonRetry: true});
              return false
            }
            else{
              this.setState({title: common.getTrad("Impossibile verificare il pagamento")});
              this.setState({description: <>{common.getTrad("Non è stato possibile verificare la validità del pagamento.")}<br />{common.getTrad("Per problemi contattare l'assistenza tramite l'indirizzo email assistenza@softway.it indicando gli estremi del pagamento")}</>});
              this.setState({renderButtonRetry: true});
              return false
            }

          this.setState({title: common.getTrad("Pagamento verificato")});
          this.setState({description: common.getTrad("Hai acquistato il tuo abbonamento, al prossimo login avrai accesso ai contenuti del sito")});
          common.remove_localStorage("appState");
          common.remove_localStorage("Info");
          this.setState({renderButtonLogin: true});

        }else{
          this.setState({title: common.getTrad("Impossibile verificare il pagamento")});
          this.setState({description: <>{common.getTrad("Non è stato possibile verificare la validità del pagamento.")}<br />{common.getTrad("Per problemi contattare l'assistenza tramite l'indirizzo email assistenza@softway.it indicando gli estremi del pagamento")}</>});
          this.setState({renderButtonRetry: true});
        }
      });
    }

    acquistaAbbonamento(code, brand){
      if(code === null && brand === null){
        brand = common.getParam(brand);
        code = common.getParam(code);
      }
      this.setState({renderBoxPrezzi: false});
      common.apiCall(env.BACKEND + "@sw-do-purchase", "POST", {"productCode": code, "brandProduct": brand, "confirmUrl": (env.FRONTEND + "confirmPayment/") }, true)
      .then((response) =>{
        var waitmSec = 1000;
        if(response.error === false){
          if((response.errorCode !== undefined && response.errorCode !== null && response.errorCode !== "") || response.status !== 200)
            if(response.message !== undefined && response.message !== null && response.message !== ""){
              bootbox.alert(response.message);
              setTimeout(()=>{this.setState({renderBoxPrezzi: true});}, waitmSec);
              return false
            }
            else{
              bootbox.alert(common.getTrad("Errore. Impossibile procedere al pagamento. Si prega di riprovare"));
              setTimeout(()=>{this.setState({renderBoxPrezzi: true});}, waitmSec);
              return false
            }

          if(response.redirectUrl){
            window.location.href = response.redirectUrl;
            return true
          }
          else{
            bootbox.alert(common.getTrad("Errore. Riprovare piu tardi"));
            setTimeout(()=>{this.setState({renderBoxPrezzi: true});}, waitmSec);
            return false
          }

        }else{
          bootbox.alert(common.getTrad("Errore. Impossibile procedere al pagamento. Si prega di riprovare"));
          setTimeout(()=>{this.setState({renderBoxPrezzi: true});}, waitmSec);
          return false
        }

      })
    }


    render(props){
      if(this.state.renderConfirmPage){
        return(
          <div className="app-content content registrazione_io" id="subscribeContent">
            <div className="content-wrapper">
              <div className="content-body">
                <section id="icon-tabs">
                  <div className="row">
                    <div id="content_registrazione_form">
                      <h2 className="text-center mt-5 mb-2">{this.state.title}</h2>
                      <div className="text-center mb-3">{this.state.description}</div>
                      <div className="text-center mb-5">
                        {
                          this.state.renderButtonLogin ?
                            <button type="button" className="bottone_default btn btn-light" onClick={() =>{doLogout()}}>
                              {common.getTrad("Accedi al portale RMI")}
                            </button>
                          : this.state.renderButtonRetry ?
                            <Link to="/rmi-services">
                              <button type="button" className="bottone_default btn btn-light">
                                {common.getTrad("Ritenta l'acquisto")}
                              </button>
                            </Link>
                          : <></>
                        }
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        )
      }else {
        return(
          <Routes>
            <Route path="/" element={<LoaderView />} />
          </Routes>
        )
      }
    }

}

export default Subscribe;
