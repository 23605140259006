import React from 'react';
import { Link } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import NewsInEvidenza from "../NewsInEvidenzaBox/NewsInEvidenzaBox";
import {menuHomeOpen} from "../Header/HeadersButtons";
import bootbox from 'bootbox';
import moment from 'moment';
import './menu-home.css';
const common = require('../common/common');
const env = require("../../config.js").default;

function checkConsoleButtonPrint(doAction=false){
  const $ = window.$;
  var printButton = false;
  var user_menu = common.getUserInfoSync('user_menu');
  var is_user_menu_not_empty = (user_menu !== undefined && user_menu !== null && Array.isArray(user_menu) && user_menu.length > 0);
  printButton = (is_user_menu_not_empty && (common.object_value_in_array(user_menu, 'tipo', 'console') > -1));

  if(doAction){
    if(!is_user_menu_not_empty){
      $(".menu-application").parent().hide();
    }else
      $(".menu-application").parent().show();
  }
  if(!is_user_menu_not_empty && !env.PRODUZIONE){
    if(doAction){
      $(".menu-application").parent().show();
      $(".menu-console").parent().show();
    }
    return true;
  }else if(printButton){
    if(doAction)
      $(".menu-console").parent().show();
    return true;
  }
  else{
    if(doAction)
      $(".menu-console").parent().hide();
    return false;
  }
}

function get_elem_from_user_menu(this_type, userInfo){
  var user_menu;
  user_menu = common.getUserInfoSync('user_menu');
  if(userInfo === undefined || userInfo === null)
    return ("")

  user_menu = userInfo['user_menu'];
  var empty = true;

  if (user_menu !== undefined && user_menu !== null && Array.isArray(user_menu) && user_menu.length > 0){
    var ret = user_menu.map(function(value, key){
      if (value.tipo === this_type){
        empty = false;
        return(
          <SubMenuSwan key={key} type={this_type} title={value.desc} href={env.SWDOMAIN + value.url} img={value.icon}></SubMenuSwan>
         )
      }else{
        return ("")
      }
    });
    checkConsoleButtonPrint(true);

    return (empty ? !empty : ret)
  }else{
    checkConsoleButtonPrint(true);
    return ("")
  }
}


class MenuHome extends React.Component{
  constructor(props) {
    super(props);
    this.state = {};
    this.state.messaggiSistema = {};
    this.state.userInfo = {};
    this.state.userInfo.roles = [];
    this.state.render1 = false;
    this.state.renderPopup = false;
    this.state.menuPaginaInterna = this.props.menuPaginaInterna !== undefined ? this.props.menuPaginaInterna : false;
  }

  UNSAFE_componentWillMount(){
    common.setStateUserInfo(this);
    
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;
    if(!this.state.menuPaginaInterna && common.get_localStorage("sw_showPopup") !== 'true'){
      if(env.FRONTEND === window.location.href || env.FRONTEND + "?app=1" === window.location.href){
        var data = { fullobjects: 1, Subject: "sw_popup", sort_on: "modified", "effective_popup.query": formattedToday, "effective_popup.range": "max", "expires_popup.query": formattedToday, "expires_popup.range": "min", sort_order: "reverse" };
        common.apiCall(env.BACKEND + common.getLanguage() + '/@search', "GET", data, true, true)
        .then(popupNews=>{
          if(popupNews.error === false && popupNews.items_total > 0){
            common.set_localStorage("sw_showPopup", true);
            var messages = '';
            for (let i = 0; i < popupNews.items_total; i++) {
              messages += '<div><a href="'+ env.FRONTEND.substring(0, env.FRONTEND.length - 1) + common.getUrlPath(popupNews.items[i]['@id']) +'">'
              messages += '<h4>' + popupNews.items[i].title + '</h4>'
              messages += '</a>'
              messages += '<div class="pb-3"><small>' + moment(popupNews.items[i].effective).format('LL') +'</small></div>'
              if (popupNews.items[i].description)
                messages += '<div class="pb-3"><span style="word-break: break-word;">' + ReactDOMServer.renderToStaticMarkup(common.beautifyShortDescription(popupNews.items[i].description)) + '</span></div>';
              messages += '<div class="d-flex justify-content-between align-items-center">';
              messages += '<a href="' + env.FRONTEND.substring(0, env.FRONTEND.length - 1) + common.getUrlPath(popupNews.items[i].parent["@id"]) + '" class="btn popup-tag-link btn-sm active" role="button" aria-pressed="true"><i class="fas fa-xs fa-tag"></i>&nbsp;' + popupNews.items[i].parent.title + '</a>';
              messages += '<a href="' + env.FRONTEND.substring(0, env.FRONTEND.length - 1) + common.getUrlPath(popupNews.items[i]['@id']) +'" class="btn popup-read-link btn-sm active" role="button" aria-pressed="true">'+common.getTrad("Continua a leggere")+'</a>';
              if (i !== popupNews.items_total - 1) {
                messages += '</div>';
                messages += '<hr>';
              } else {
                messages += '</div><br/>';
              }
            }
            bootbox.dialog({
              message: messages,
              onEscape: function() {},
            });
          }
        });
      }
    }
  }

  componentDidUpdate(){
    setTimeout(function(){
      if(env.FRONTEND === window.location.href || env.FRONTEND + "?app=1" === window.location.href){
        getNumberNotificheTicket();
      }
      svgIconsLoader()
    }, 1000);
  }

  componentDidMount(){
    const $ = window.$;

    $(window).resize(function(){
      var vociAperte = $(".bottoni_menu").find(".bottoni_menu_link.submenuOpen").parent();
      if(vociAperte.length > 0){
        $(vociAperte).find(".submenuPlaceHolder").css("height", $(vociAperte).find(".elenco_voci").height()+35);
      }
    });
    var data = {"expand.navigation.depth": 3 };
    var url = env.BACKEND + common.getLanguage() + '/@navigation';
    common.apiCall(url, "GET", data, true, true)
    .then(menuAreaButtons=>{
      if(menuAreaButtons.error === false){
        this.setState({menuAreaButtons});
        this.setState({render1: true});
      }
    });
  }

  renderMenuArea(){
    var userInfo = this.state.userInfo;
    var ret;
    try{
      ret = this.state.menuAreaButtons.items.map(function(value, key){
        // salto la home
        if (key > 0 && value["@id"].search("/messaggi-sistema") <= -1 && value["@id"].search("/console-utenti-rmi") <= -1 && value["@id"].search("/statistiche") <= -1) {
          return(
            <MenuArea userInfo={userInfo} title={value.title} id={value["@id"]} key={key} items={value["items"]}></MenuArea>
          )
        }
        return ("")
      });
    }
    catch{
      ret = <div/>
    }
    return ret;
  }

  render(props){
    var menuPaginaInterna = 'col-lg-8 col-md-7 col-sm-12';
    if(this.props.menuPaginaInterna)
      menuPaginaInterna = 'Content_nav_interno fadeInDown animated faster';
    return(
      <>
        {
          this.state.render1 ?
            <div className={menuPaginaInterna} id="buttonStyleNavContainer">
              <nav className="container" id="buttonStyleNav">
                { !this.props.menuPaginaInterna ? <NewsInEvidenza responsive={true}></NewsInEvidenza> : <div/>}
                { this.state.userInfo.roles.indexOf("Reviewer") > -1  || (!this.state.userInfo.userInfo.RMI && this.state.userInfo.userInfo.FCONS === 'S') ?
                    <MenuAdmin aree={this.state.menuAreaButtons.items}></MenuAdmin>
                  :
                    ""
                }
                { this.renderMenuArea() }
              </nav>
            </div>
          :
            <></>
        }
      </>
      )
  }
}

class MenuArea extends React.Component{
  constructor(props){
    super(props);
    this.state={};

    this.state.title = this.props.title;
    this.state.id = this.props.id;
    this.state.items = this.props.items;
    this.state.userInfo = this.props.userInfo;
    this.state.userInfo.userInfo = this.props.userInfo.userInfo;
  }

  renderMenuButton(){
    var ret;
    try{
      ret = this.state.items.map(function(value, key){
          return(
            <MenuButton title={value.title} id={value["@id"]} mykey={key} description={value.description} key={key} items={value["items"]}></MenuButton>
          )
      });
    }
    catch{
      ret = <div/>
    }
    return ret;
  }

  render(props){

    return(
      <div>
        <div id="dealerAreaGroup">
          <div className="">
            <div className="viewlet-above-content-title">
              <h1 id="parent-fieldname-title" className="documentFirstHeading">{this.state.title}
                {this.state.userInfo.userInfo.RMI && this.state.userInfo.userInfo.FCONS === 'S' ?
                  <a style={{display: "inline-block",
                    position: "inherit",
                    width: "inherit",
                    textShadow: "0 0 0",
                    height: "inherit",
                    marginLeft: "10px"}} className="btn bottoni_menu_link animated fadeInRight fast delay-02s" href={env.FRONTEND + "rmi-services/"}>
                    RMI Services
                  </a>
                :
                  <></>
                }
              </h1>
              <div id="viewlet-below-content-title"></div>
              <div id="viewlet-above-content-body"></div>
              <div id="content-core">
                <div>&nbsp;</div>
              </div>
            </div>
          </div>


          {this.renderMenuButton()}

        </div>
        <div className="clear"></div>
      </div>
    );

  }
}

class MenuButton extends React.Component{
  constructor(props){
    super(props)
    this.state = {};
    this.state.id = this.props.id;
    this.state.key = this.props.mykey;
    this.state.title = this.props.title;
    var descr = this.props.description
    if (descr === "") {
        this.state.description = {"img": "", "count": ""}
    }else{
        this.state.description = JSON.parse(descr);
    }
    this.state.items = this.props.items;
    this.state.userInfo = {};

    this.handleBottoniMenuClick = this.handleBottoniMenuClick.bind(this);
  }

  UNSAFE_componentWillMount(props){
    common.getUserInfo().then(userInfo =>{
      if(userInfo !== undefined && userInfo !== false){
        this.setState({userInfo: userInfo});
      }
    });
  }

  renderSubMenu(){
    var ret = <></>;
    try{
      if(this.state.items.length > 0){
        ret = this.state.items.map(function(value, key){
          return(
            <SubMenu title={value.title} id={value["@id"]} key={key}></SubMenu>
          )
        })
        ret =
          <>
            <ul className="elenco_voci list">
              {ret}
            </ul>
            <div className="submenuPlaceHolder" style={{height: "0"}}></div>
          </>
      }
    }
    catch{
      ret = <></>
    }
    return ret;
  }

  renderSubMenuSwan(){
    var ret;
    var ret_service;
    var ret_catalogo;
    var ret_concessionario;
    try{
      ret_service = get_elem_from_user_menu("service", this.state.userInfo);
      ret_catalogo = get_elem_from_user_menu("catalogo", this.state.userInfo);
      ret_concessionario = get_elem_from_user_menu("concessionario", this.state.userInfo);
    }
    catch{
      ret_concessionario = <div/>
      ret_service = <div/>
    }

    ret =
      <>
        <ul className="elenco_voci">
          { ret_service ?
            <>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 title_application title-border">
                  <p className="p_titolo text-left">{common.getTrad("Service")}</p>
                </div>
              </div>
              <div className="row">
                  {ret_service}
              </div>
            </>
            : ""
          }
          { ret_catalogo ?
            <>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 title_application title-border">
                  <p className="p_titolo text-left">{common.getTrad("Catalogo")}</p>
                </div>
              </div>
              <div className="row">
                  {ret_catalogo}
              </div>
              </>
              :
              ""
            }
            { ret_concessionario ?
              <>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 title_application title-border">
                    <p className="p_titolo text-left">{common.getTrad("Concessionario")}</p>
                  </div>
                </div>
                <div className="row">
                    {ret_concessionario}
                </div>
              </>
              :
              ""
            }
        </ul>
        <div className="submenuPlaceHolder" style={{height: "0"}}></div>
      </>
    return ret;
    // return (<SubMenuSwan></SubMenuSwan>)
  }

  handleBottoniMenuClick(e){

    var $ = window.$;
    if($(e.currentTarget).parent().find(".elenco_voci").length > 0){
      e.preventDefault();
      openSubMenu(e.currentTarget);
      return false;
    }
    menuHomeOpen(false);
  }

  calcButtonType = () => {
    if(this.state.description.external_link !== undefined && this.state.description.external_link !== null && this.state.description.external_link !== ""){
      return(<a
              className={"bottoni_menu_link animated fadeInRight fast delay-02s dropdownMenu " + this.state.description.img.slice(0,-4)}
              onClick={this.handleBottoniMenuClick}
              href={this.state.description.external_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h3>{this.state.title}</h3>
              {
                this.state.description.img === "logo-swan.svg" ?
                <img src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/" + this.state.description.img} title={this.state.title} alt={this.state.title} />
                :
                <figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/" + this.state.description.img} title={this.state.title} alt={this.state.title} className="svg"></figure>
              }
            </a>)
    }else{
      let  link = common.getUrlPath(this.state.id);
      //console.log(this.state);
      if(this.state.description.flagSwan || this.state.items?.length > 0)
        link = '';
      return(
        <Link
          className={"bottoni_menu_link animated fadeInRight fast delay-02s dropdownMenu " + this.state.description.img.slice(0,-4)}
          onClick={this.handleBottoniMenuClick}
          to={link}
        >
          <h3>{this.state.title}</h3>
          {
            this.state.description.img === "logo-swan.svg" ?
            <img src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/" + this.state.description.img} title={this.state.title} alt={this.state.title} />
            :
            <figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/" + this.state.description.img} title={this.state.title} alt={this.state.title} className="svg"></figure>
          }
        </Link>
      )
    }
  }

  render(props){


    return(
      <>
        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 bottoni_menu">
          {this.calcButtonType()}
          {!this.state.description.flagSwan ? this.renderSubMenu() : this.renderSubMenuSwan() }
        </div>
        { this.state.key % 4 === 3 && this.state.key > 0 ? <div className="clear"></div> : this.state.key % 2 === 1 ? <div className="clear-sm"></div> : <div className="clear-xs"></div> }
      </>
    )
  }
}


class SubMenu extends React.Component{
  constructor(props){
    super(props);
    this.state = {};

    this.state.id = this.props.id;
    this.state.title = this.props.title;
    this.state.userInfo = {};
    this.state.userInfo.user_menu = [];
  }
  UNSAFE_componentWillMount(){
    common.getUserInfo().then(userInfo =>{
      if(userInfo !== undefined && userInfo !== false){
        if(userInfo.user_menu === undefined || userInfo.user_menu === null)
          userInfo.user_menu = [];
        this.setState({userInfo: userInfo});
      }
    });
  }
  isInUserMenuObject(item){
    var ret = false;
    if(this.state.userInfo.user_menu !== undefined && this.state.userInfo.user_menu !== null && Array.isArray(this.state.userInfo.user_menu))
      this.state.userInfo.user_menu.map((value) =>{
        if(value.url !== undefined && value.url !== null && value.url.search(item) > -1){
          ret = true;
        }
        return false;
      });

    return ret
  }

  handleBottoniMenuClick(e) {

    var $ = window.$;
    if ($(e.currentTarget).parent().find(".elenco_voci").length > 0) {
      e.preventDefault();
      openSubMenu(e.currentTarget);
      return false;
    }
    menuHomeOpen(false);
  }

  render(props){
    // env.SWAN

    return(
      <li>
      {this.state.id.indexOf('spare-parts') > -1 ?
        <a href={env.PARTS_FINDER_ENABLED ? env.PARTSFINDER : env.SWAN + "epc"} target={env.PARTS_FINDER_ENABLED ? "_blank" : ""} rel="noopener noreferrer" onClick={menuHomeOpen(false)}>
          {this.state.title}
        </a>
      : this.state.id.indexOf('accessories') > -1 && this.isInUserMenuObject("acc/") ?
        <a href={env.SWAN + "acc/"} onClick={menuHomeOpen(false)}>
          {this.state.title}
        </a>
      :
            <Link to={common.getUrlPath(this.state.id)} onClick={this.handleBottoniMenuClick}>
          {this.state.title}
        </Link>
      }
      </li>
    );
  }
}


class SubMenuSwan extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.href = this.props.href;
    this.state.title = this.props.title;
    this.state.img = this.props.img;
  }

  componentDidMount(){
    const $ = window.$;
    const app = common.getParam("app");
    const lastPage = document.referrer;

    //If app get parameter = 1, open the application button, because is coming from SWAN
    if(app !== undefined && app !== null && parseInt(app) === 1){
      common.getUserInfo().then(userInfo =>{
        if (userInfo!==undefined && userInfo !== false){
          var cliccato = false;
          var trovati = [];
          $("#homeMenuContainer .swanMenuButton").each((i, el) => {
            if(lastPage.search($(el).attr("href")) > -1 ){
              trovati.push(el);
            }
          });
          if(trovati.length !== 0){
            var maxI = 0;
            for(var i=0; i<trovati.length; i++){
              if(trovati[i].length > trovati[maxI].length)
                maxI = i;
            }
            var el = trovati[maxI];
            if($(el).hasClass("swanMenu_console")){
              if($("#homeMenuContainer .menu-console")[0] !== undefined && !$("#homeMenuContainer .menu-console").hasClass("submenuOpen"))
                $("#homeMenuContainer .menu-console")[0].click();
            }else{
              if($("#homeMenuContainer .menu-application")[0] !== undefined && !$("#homeMenuContainer .menu-application").hasClass("submenuOpen"))
                $("#homeMenuContainer .menu-application")[0].click();
            }
            cliccato = true;
            return
          }
          if(!cliccato){
            if(userInfo.roles.indexOf("Reviewer") > -1){
              if($("#homeMenuContainer .menu-console")[0] !== undefined && !$("#homeMenuContainer .menu-console").hasClass("submenuOpen"))
                $("#homeMenuContainer .menu-console")[0].click();
            }else
              if($("#homeMenuContainer .menu-application")[0] !== undefined && !$("#homeMenuContainer .menu-application").hasClass("submenuOpen"))
                $("#homeMenuContainer .menu-application")[0].click();
          }
        }
      })
    }
  }

  render(props){
    return(
      <li className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 col-application">
        <a rel="noopener noreferrer" href={this.state.href} onClick={menuHomeOpen(false)} className={"swanMenuButton swanMenu_" + this.props.type}>
          <figure src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/" + this.state.img} title={this.state.title} alt={this.state.title} className="svg application_icone"></figure>
          <div className="caption">
            <p className="captionInside">{this.state.title}</p>
          </div>
        </a>
      </li>
    );
  }
}


class MenuAdmin extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.userInfo = {};
  }

  UNSAFE_componentWillMount(props){
    common.getUserInfo().then(userInfo =>{
      if(userInfo !== undefined && userInfo !== false){
        this.setState({userInfo: userInfo});
      }
    });
  }

  handleBottoniMenuClick(e){
    var $ = window.$;
    if($(e.currentTarget).parent().find(".elenco_voci").length > 0){
      e.preventDefault();
      openSubMenu(e.currentTarget);
      return false;
    }
    menuHomeOpen(false);
  }
  render(props){
    var countEccezioniStampa = 0;

    return(
      <>
        <div id="adminAreaGroup">
          <div className="">
            <div className="viewlet-above-content-title">
              <h1 id="parent-fieldname-title" className="documentFirstHeading">{common.getTrad("Admin Area")}</h1>
              <div id="viewlet-below-content-title">
              </div>
              <div id="viewlet-above-content-body">
              </div>
              <div id="content-core">
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 bottoni_menu">
            <a className="bottoni_menu_link animated fadeInRight fast dropdownMenu menu-console" href="" onClick={this.handleBottoniMenuClick}>
              <h3>{common.getTrad("Console")}</h3>
              <figure className="img-fluid svg" src={env.MENU_ICONS_PATH + (window.cc.usePersonalIcons ? window.cc.csoft : "900000") + "/menu-console.svg"} alt={common.getTrad("Console")} ></figure>
            </a>
            <ul className="elenco_voci">
              <div className="row">
              {get_elem_from_user_menu("console", this.state.userInfo)}
              </div>
            </ul>
            <div className="submenuPlaceHolder" style={{height: "0"}}></div>
          </div>
          {
            this.props.aree.map((value, index) => {
              if(value["@id"].search("/messaggi-sistema") > -1 ){
                countEccezioniStampa ++;
                return (<MenuButton title={value.title} id={value["@id"]} mykey={countEccezioniStampa} description={value.description} key={index} items={value["items"]}></MenuButton>)
              }else if (value["@id"].search("/console-utenti-rmi") > -1 && window.cc.registrationFlag && window.cc.consoleUtentiFlag) {
                countEccezioniStampa ++;
                return (<MenuButton title={value.title} id="/console-utenti-rmi" mykey={countEccezioniStampa} description={value.description} key={index} items={value["items"]}></MenuButton>)
              }else if (value["@id"].search("/statistiche") > -1) {
                countEccezioniStampa ++;
                return (<MenuButton title={value.title} id="/statistiche" mykey={countEccezioniStampa} description={value.description} key={index} items={value["items"]}></MenuButton>)
              }else{
                return ""
              }
            })
          }
        </div>
        <div className="clear"></div>
      </>
    )
  }
}

export function svgIconsLoader(){
  const $ = window.$;

  $.fn.outerHTML = function(){

      // IE, Chrome & Safari will comply with the non-standard outerHTML, all others (FF) will have a fall-back for cloning
      return (!this.length) ? this : (this[0].outerHTML || (
        function(el){
            var div = document.createElement('div');
            div.appendChild(el.cloneNode(true));
            var contents = div.innerHTML;
            div = null;
            return contents;
      })(this[0]));

  }

  var cachedIcons = {};
  try{
    cachedIcons = JSON.parse(common.get_localStorage("svgIcons"));
  }catch(e){
  }
  $("figure.svg").each(function () {
    // Perf tip: Cache the image as jQuery object so that we don't use the selector muliple times.
    var img = $(this);
    // Get all the attributes.
    var attributes = img.prop("attributes");
    // Get the image's URL.
    var imgURL = img.attr("src");
    // Fire an AJAX GET request to the URL.
    if(cachedIcons!==null && cachedIcons[imgURL] && cachedIcons[imgURL] !== "" && cachedIcons[imgURL]!==null && cachedIcons[imgURL]!=="null" && cachedIcons[imgURL]!==undefined && cachedIcons[imgURL]!=="undefined"){
      // The data you get includes the document type definition, which we don't need.
      // We are only interested in the <svg> tag inside that.
      var svg = $(cachedIcons[imgURL]);
      // Loop through original image's attributes and apply on SVG
      $.each(attributes, function() {
        svg.attr(this.name, this.value);
      });

      img.replaceWith(svg);
    }else{
      $.get(imgURL, function (data) {
        // The data you get includes the document type definition, which we don't need.
        // We are only interested in the <svg> tag inside that.

        var svg = $(data).find('svg');
        // Remove any invalid XML tags as per http://validator.w3.org
        svg = svg.removeAttr('xmlns:a');

        cachedIcons[imgURL] = $(svg).outerHTML();
        common.set_localStorage("svgIcons", JSON.stringify(cachedIcons));

        // Loop through original image's attributes and apply on SVG
        $.each(attributes, function() {
          svg.attr(this.name, this.value);
        });
        // Replace image with new SVG
        img.replaceWith(svg);



      });
    }
  });
}

export function openSubMenu(button){
  const $ = window.$;
  if(button == null)
  return false;

  button = $(button).parent();
  var aButton = $(button).find("a");
  var elencoVoci = $(button).find(".elenco_voci");

  var placeHolder = $(button).find(".submenuPlaceHolder");
  var openMyMenu = true;

  if($(elencoVoci).find("li").length <= 0)
  return true;

  var menuAperti = $(document).find(".elenco_voci.open");
  var menuApertiButtons = $(document).find(".bottoni_menu_link.submenuOpen");

  if($(aButton).attr("class").search("submenuOpen") > 0)
    openMyMenu = false;
  else
    openMyMenu = true;

  if(menuApertiButtons.length > 0){
    $(menuAperti).each(function(){
      $(this).height(0);
      $(this).removeClass("flipInX");
      $(this).addClass("flipOutX");
      $(this).removeClass("open");
    });
    $(menuApertiButtons).each(function(){
      $(this).removeClass("submenuOpen");
      $(".submenuPlaceHolder").height(0);

    });
    //$(".submenuPlaceHolder").hide();
  }


  if(openMyMenu){
    $(elencoVoci).removeClass("flipOutX");
    $(elencoVoci).addClass("animated faster flipInX");
    $(elencoVoci).addClass("open");
    $(elencoVoci).height("auto");
    $(placeHolder).css("height", $(elencoVoci).height()+35);
    $(aButton).addClass("submenuOpen");

    // $("#buttonStyleNavContainer, #buttonStyleNav").animate({scrollTop: ($(aButton).offset().top - $("#portal-top-placeholder").height() - 20)});
    // $(placeHolder).show()
    // $(window).scroll();
  }
}


export async function getNumberNotificheTicket(){
  const $ = window.$;
  const now = new Date().getTime();
  var updTime = $("#menuLastNotifyUpdate").val();

  if (!parseInt(updTime))
    updTime = 0;

  if(parseInt(updTime) + 3000 < now){
    $("#menuLastNotifyUpdate").remove();
    $(".Content_nav_interno").append("<input type='hidden' id='menuLastNotifyUpdate' value='" + now + "'>");

    userInfo = await common.getUserInfo();
    var url = env.BACKEND + "@search", userInfo;

    if(userInfo !== undefined && userInfo.is_admin_ticket_group)
      url = url + "&Subject:list=sw-csoft-" + userInfo.userInfo.csoft;
    var ticketNumber = 0;
    if(userInfo !== undefined && userInfo !== false){
      if(userInfo.is_admin_ticket)
        url = url + "?portal_type=swticket&portal_type=swticket_vehicle&review_state=pending";
      else
        url = url + "?portal_type=swticket&portal_type=swticket_vehicle&review_state=closed&review_state=incomplete&review_state=published";

      common.apiCall(url, "GET", {}, true, true ).then((response)=>{
        ticketNumber = response.items_total;
        $(".menu-forum .notify_counter").remove();
        if(ticketNumber > 0){
          if(ticketNumber > 99)
            ticketNumber = 99;
          $(".menu-forum").append("<div class='notify_counter'>" + ticketNumber + "</div>");
        }
      });

      if(env.PRODUZIONE){
        common.apiCall(env.SWAN + "get_user_counter/", "GET", {}, false, false, "json", true).then((response) => {
          if(response.error === false){
            var totaleNotificheApp = 0;
            var totaleNotificheCons = 0;
            $("#menu-application").css("display", "block");
            $(".swanMenuButton").each(function() {
              var bottone = this;
              response.map( (item, key) => {
                if ($(bottone).attr("href") === env.SWDOMAIN + item.url){
                  if(item.num > 99)
                    item.num = 99;
                  if(item.num > 0){
                    $(bottone).find(".notify_counter").remove();
                    $(bottone).append("<div class='notify_counter'>" + item.num + "</div>");
                  }
                  if($(bottone).hasClass("swanMenu_console"))
                    totaleNotificheCons = totaleNotificheCons + parseInt(item.num);
                  else
                    totaleNotificheApp = totaleNotificheApp + parseInt(item.num);
                }
                return false;
              });
            });

            //Divido il totale calcolato precedentemente per il numero di button presenti nel menu
            //Questo perchè il ciclo precedente viene eseguito una volta per ogni button "application" e "console" stampato (possono essere 2 nella home)
            totaleNotificheApp = totaleNotificheApp / $(".menu-application").length;
            totaleNotificheCons = totaleNotificheCons / $(".menu-console").length;

            if(totaleNotificheApp > 99)
              totaleNotificheApp = 99;

            if(totaleNotificheCons > 99)
              totaleNotificheCons = 99;

            if(totaleNotificheApp > 0) {
              $(".menu-application .notify_counter").remove();
              $(".menu-application").append("<div class='notify_counter'>" + totaleNotificheApp + "</div>");
            }
            if(totaleNotificheCons > 0) {
              $(".menu-console .notify_counter").remove();
              $(".menu-console").append("<div class='notify_counter'>" + totaleNotificheCons + "</div>");
            }
          }
        })
      }
    }
  }else{
    $(".menu-forum .notify_counter").each(function(){
      var num = $(this).text();
      if(num !== undefined && num!== null && num!== "" && parseInt(num)>0){
        $(".menu-forum .notify_counter").remove();
        $(".menu-forum").append("<div class='notify_counter'>" + num + "</div>");
      }
    });
    $(".menu-application .notify_counter").each(function(){
      var num = $(this).text();
      if(num !== undefined && num!== null && num!== "" && parseInt(num)>0){
        $(".menu-application .notify_counter").remove();
        $(".menu-application").append("<div class='notify_counter'>" + num + "</div>");
      }
    });
    $(".swanMenuButton").each(function() {
      var num = $(this).find(".notify_counter").text();
      if(num !== undefined && num!== null && num!== "" && parseInt(num)>0){
        $(this).find(".notify_counter").remove();
        $(this).append("<div class='notify_counter'>" + num + "</div>");
      }
    });
  }


}

export default MenuHome;
