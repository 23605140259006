import React from 'react';
const common = require('../common/common');
const env = require("../../config.js").default;

class FilterTypesTickets extends React.Component {
  constructor(props){
    super(props);
    this.state = {};
    this.state.tipologieTicket = window.cc.tipologieTicket;
    this.state.tipologiaTicket = (common.get_localStorage('valoreTipologiaTicket') !== undefined && common.get_localStorage('valoreTipologiaTicket') !== null && common.get_localStorage('valoreTipologiaTicket') !== '' ? common.get_localStorage('valoreTipologiaTicket') : '');
  }

  componentDidMount(){
    const $ = window.$;
    $(window).resize(function() {
	  	$('.select2').css('width', "100%");
	  });

    var filtriNuovi = [];
    var filtriOld = this.props.tipologie_ticket_attive;

    var keys = Object.keys(this.props.tipologie_ticket_attive);
    for (let g = 0; g < keys.length; g++) {
      var addFiltro = false;
      if(common.object_value_in_array(this.props.marche_attive, 'ZFORN', keys[g]) > -1)
        addFiltro = true;

      if(keys[g] !== undefined && keys[g] !== 'version' && addFiltro){
        if (this.props.allowed_types === undefined) {
          var tipiTicket = Object.keys(filtriOld[keys[g]])
        } else {
          var tipiTicket = this.props.allowed_types
        }
        for (let t = 0;t < tipiTicket.length; t++) {
          if(common.object_value_in_array(filtriNuovi, 'id', tipiTicket[t]) <= -1){
            filtriNuovi.push({id: tipiTicket[t], text: common.getTrad(filtriOld[keys[g]][tipiTicket[t]].titolo)})
          }
        }
      }
    }
    this.setState({tipologieTicket: filtriNuovi});
    $('.tipologieTicket').empty().append("<option></option>").select2({
      data: filtriNuovi,
      allowClear: true,
      language: common.getLanguage(),
      placeholder: common.getTrad("Seleziona un tipo"),
    });


    common.remove_localStorage('valoreModello');

    $('.tipologieTicket').on("select2:select keyup select2:unselect", (e) => {
      e.preventDefault();
			

			return false;
		});


    if(window.performance && performance.navigation.type === 1){
      if(common.get_localStorage('valoreTipologiaTicket') !== undefined && common.get_localStorage('valoreTipologiaTicket') !== null && common.get_localStorage('valoreTipologiaTicket') !== ''){
        var parametri = {
          tipologia_ticket: common.get_localStorage('valoreTipologiaTicket')
        }
        this.props.onClick("tipologia_ticket", parametri, true);
      }

      this.changeGruppoTicket(common.get_localStorage('valoreMarca'));
		}

    $('#select_vm').on('select2:select keyup select2:unselect', (e) => {
      this.changeGruppoTicket(e.target.value);
    });

    if(common.get_localStorage('valoreTipologiaTicket') !== '' && common.get_localStorage('valoreTipologiaTicket') !== null && common.get_localStorage('valoreTipologiaTicket') !== undefined){
        $('.tipologieTicket').val(common.get_localStorage('valoreTipologiaTicket')).trigger("change");
      }else{
        $('.tipologieTicket').val('').trigger("change");
      }
    
  }

  changeGruppoTicket(marca){
    const $ = window.$;
    if(marca === undefined || marca === null || marca === '')
      return false;

    var tipologieTicket = this.props.tipologie_ticket_attive;
    var tipologieTicketKeys = Object.keys(this.props.tipologie_ticket_attive);
    if(tipologieTicketKeys.indexOf('version') > 1){
      if(marca !== 'tutti_modelli'){
        var gruppo = [];
        var ticket_gruppo = [];
        for (let i=0; i< tipologieTicketKeys.length; i++){
          if(tipologieTicketKeys[i] !== undefined && tipologieTicketKeys[i] !== 'version'){
            gruppo.push({'id_group': tipologieTicketKeys[i], 'text': tipologieTicketKeys[i], 'children': []})
          }
        }

        for (let g = 0; g < gruppo.length; g++) {
          var tipologiaKey = Object.keys(tipologieTicket[gruppo[g].id_group]);
          for (let i = 0; i < tipologiaKey.length; i++) {
            ticket_gruppo.push({
              'id': tipologiaKey[i],
              'text': common.getTrad(tipologieTicket[gruppo[g].id_group][tipologiaKey[i]].titolo),
              'id_group': gruppo[g].id_group,
              'campi': tipologieTicket[gruppo[g].id_group][tipologiaKey[i]].campi
            });
          }
        }

        for (let g = 0; g < gruppo.length; g++) {
          for (let m = 0; m < ticket_gruppo.length; m++) {
            if(gruppo[g]['id_group'] === ticket_gruppo[m]['id_group'])
              gruppo[g]['children'].push(ticket_gruppo[m])
          }
        }

        this.setState({tipologieTicket: gruppo});
      }else{
        var filtriNuovi = [];
        var filtriOld = this.props.tipologie_ticket_attive;
        var keys = Object.keys(this.props.tipologie_ticket_attive);
        if(keys.indexOf('version') > 1){
          for (let g = 0; g < keys.length; g++) {
            if(keys[g] !== undefined && keys[g] !== 'version'){
              var tipiTicket = Object.keys(filtriOld[keys[g]])
              for (let t = 0;t  < tipiTicket.length; t++) {
                if(common.object_value_in_array(filtriNuovi, 'id', tipiTicket[t]) <= -1){
                  filtriNuovi.push({id: tipiTicket[t], text: common.getTrad(filtriOld[keys[g]][tipiTicket[t]].titolo)})
                }
              }
            }
          }

          this.setState({tipologieTicket: filtriNuovi});
          $('.tipologieTicket').empty().append("<option></option>").select2({
            data: filtriNuovi,
            allowClear: true,
            language: common.getLanguage(),
            placeholder: common.getTrad("Seleziona un tipo"),
          });
        }
      }
    }
  }

  componentDidUpdate(){
    const $ = window.$;
    $('.select2').css('width', "100%");

    var marcaDefault = '';
    if(common.get_localStorage("valoreMarca") !== undefined)
      marcaDefault = common.get_localStorage("valoreMarca");
    else if(this.props.marche_attive !== undefined && this.props.marche_attive.length > 1)
      marcaDefault = this.props.marcaDefault;
    else
      marcaDefault = this.props.marche_attive[0].ZFORN;

    if(this.props.marche_attive !== undefined && this.props.marche_attive.length > 1 && this.state.tipologieTicket !== undefined && this.state.tipologieTicket.length > 0 && marcaDefault !== undefined && marcaDefault !== null && marcaDefault !== ''){
      if(marcaDefault !== 'tutte_marche' && common.object_value_in_array(this.state.tipologieTicket, 'id_group', marcaDefault) > -1){
        var newTipologie = this.state.tipologieTicket[common.object_value_in_array(this.state.tipologieTicket, 'id_group',marcaDefault)].children;
        $('.tipologieTicket').empty().append("<option></option>").select2({
          data: newTipologie,
          allowClear: true,
          language: common.getLanguage(),
          placeholder: common.getTrad("Seleziona un tipo"),
        });
      }
    }

    if(common.get_localStorage('valoreTipologiaTicket') !== '' && common.get_localStorage('valoreTipologiaTicket') !== null && common.get_localStorage('valoreTipologiaTicket') !== undefined){
        $('.tipologieTicket').val(common.get_localStorage('valoreTipologiaTicket')).trigger("change");
      }else{
        $('.tipologieTicket').val('').trigger("change");
      }

  }

  render(props){
    return (
      <>
        {this.props.tipologie_ticket_attive !== undefined && Object.keys(this.props.tipologie_ticket_attive).length === 0 ?
          <div className="col-12 p-0">
            <div className="tab active col-xl-12">
              <div className="content_testo">
                <div className="clear sw-alert alert alert-danger mt-3">
                  <span dangerouslySetInnerHTML={{ __html: common.getTrad("I Ticket non stanno funzionando correttamente. Contatta il <b>supporto tecnico</b> se il problema persiste.")}}/>
                </div>
              </div>
            </div>
          </div>
        :
          <div className={(this.props.customClass !== undefined && this.props.customClass !== '' ? this.props.customClass : "col-xl-6 col-lg-6") +  " col-md-12 col-sm-12 col-xs-12 row-eq-height"} role="alert" id="ricerca_filtro_modello">
            <div className="col-12 p-0">
              <p>{common.getTrad("Filtra Tipologie")}</p>
              <div className="form-group">
    						<select name="tipologie" className="form-control tipologieTicket" id="tipologieTicket">
                  {this.props.tipologieTicket !== undefined ?
                  	Object.keys(this.props.tipologieTicket).map((index, key) => {
                      return (this.props.allowed_types !== undefined && this.props.allowed_types.indexOf(index) <= -1 ?
                        ""
                      :
                        <option key={key} data-tipologia={index} value={index}>{this.props.tipologieTicket[index].titolo}</option>)
                    })
    							:
    								<></>
					        }
    						</select>
    					</div>
  					</div>
          </div>
        }
      </>
    )
  }
}

export default FilterTypesTickets;
