import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { createBrowserHistory } from "history";

import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.js';
import './css/fontawesome/css/all.min.css';
import './css/animate/css/animate.min.css';
import Home from './components/views/home';
import Page from './components/views/page';
import EditContent from './components/views/editContent';
import Login from './components/views/login';
import Logout from './components/views/logout';
import {doLogout} from './components/views/logout';
import SiteMap from './components/views/sitemap';
import E401 from './components/views/errors/401';
import E404 from './components/views/errors/404';
import E500 from './components/views/errors/500';
import LoaderView from './components/views/loader';
import MyProfile from './components/views/my-profile';
import ConsoleUtentiRMI from './components/views/console-utenti-rmi';
import SignUp from './components/views/sign-up';
import Subscribe from './components/views/subscribe';
import SearchPage from './components/views/search';
import Manuali from './components/views/manuali';
import GestioneContenuti from './components/views/gestione-contenuti';
import Statistiche from './components/views/statistiche';
import RmiServices from './components/views/rmi-services';
import ConfirmEmail from './components/views/confirm-email';
import Header from './components/Header/Header';
import PrivacyBox from './components/Header/PrivacyBox';
import { getNumberNotificheTicket } from './components/MenuHome/MenuHome';
import Footer from './components/Footer/Footer';

import customConfig from "./components/common/customConfig";

const common = require('./components/common/common');
const serviceWorker = require('./serviceWorker.js');
const env = require("./config.js").default;

window.$ = $;
window.cc = "";
const VERIFY_AUTHENTICATION = 1;
const DONT_VERIFY_AUTHENTICATION = 0;


class AppRouter extends React.Component{
  constructor(props){
    super(props);
    this.state = {};
    this.state.user = false;
    this.state.userInfo={};
    this.state.userInfo.userInfo = {};
    this.state.userInfo.userInfo.groups = [];
    this.state.render1 = false;
    this.state.render2 = false;
    this.state.render3 = false;
    this.state.renderLogout = false;
    this.state.verifyDone = false;
  }

  componentDidMount(){
    if(common.get_localStorage("sidebarOpen") === undefined || common.get_localStorage("sidebarOpen") === null || common.get_localStorage("sidebarOpen") === '')
      common.set_localStorage("sidebarOpen", JSON.stringify(true));
  }

  UNSAFE_componentWillMount = (props)=>{
    $("body").append('<link rel="stylesheet" type="text/css" href="' + env.STATIC + "custom/css/variables_" + env.CSOFT + '.css">');
    $("body").append('<style>:root{--nav-container-background: url("' + env.STATIC + 'custom/background_900000-no-login.jpg") no-repeat center center/cover;}</style>');

    window.cc = new customConfig();
    window.cc.csoft = env.CSOFT;
    window.languageChanged = common.get_localStorage("isLanguageChanged");
    common.remove_localStorage("isLanguageChanged");
    if(window.languageChanged !== "true"){
      window.languageChanged = false;
    }

    //Verifica le info dell'utente e salva tutto nello state
    common.verifyLogin().then(response=>{
      if(response === "notLogged" || response === "noToken"){
        var appState = common.get_localStorage("appState");
        if(appState !== undefined && appState !== null && appState !== "" && appState ){
          this.setState({renderLogout: true});
        }
        response = false;
      }
      
      this.setState({user: response, render1: true, verifyDone: true});
    });

    // Inizializza le Custom config e carica i file necessari
    window.cc.init().then(response=>{
      const $ = window.$;
      common.setInitialLanguage();
      if(window.cc.companyInfo !== undefined && window.cc.companyInfo !== null)
        $('meta[name=description]').attr('content', common.getTrad("Benvenuto sul sito RMI di {{service_rmi}} per la ricerca di contenuti tecnici. Per accedere ai contenuti è necessario effettuare l'accesso con le proprie credenziali oppure registrarsi.", {service_rmi: window.cc.companyInfo.service}));

      $("head").append('<link rel="icon" type="image/png" href="' + env.STATIC + 'custom/favicon_' + window.cc.csoft + '.ico"/>');
      $("body").append('<link rel="stylesheet" type="text/css" href="' + env.STATIC + "custom/css/variables_" + window.cc.csoft + '.css">');
      $("body").append('<style>:root{--nav-container-background: url("' + env.STATIC + 'custom/background_900000-login.jpg") no-repeat center center/cover;}</style>');

      if(parseInt(window.cc.csoft) === 850034){
        $("body").append('<!-- Global site tag (gtag.js) - Google Analytics - EPTA --><script async src="https://www.googletagmanager.com/gtag/js?id=UA-42330396-22"></script><script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag("js", new Date()); gtag("config", "UA-42330396-22"); </script>');
      }

      this.setState({render2: true});
      //Carica le informazioni dell'utente nello state
      common.getUserInfo().then(userInfo =>{
        if(userInfo !== undefined && userInfo !== false){
          this.setState({ userInfo: userInfo, render3: true });
        }
      });
    });
    //this.update();

    // pulisce rif. a doc attivo per SWAN
		sessionStorage.removeItem("tordi");
		sessionStorage.removeItem("tnord");
		sessionStorage.removeItem("nordi");

  }

  update = (checkUser) => {
    //console.log("update")
    if (this.state.user === "notLogged" || this.state.user === "noToken")
      this.state.user = false;

    if(checkUser){
      common.verifyLogin()
      .then(response => {
        /*console.log("checkuser then")
        console.log(response)
        console.log(this.state.user)*/
        if(response === "notLogged" || response === "noToken"){
          if (this.state.user !== false){
            this.setState({renderLogout: true});
            this.state.user = response;
          }
        } else {
          if (this.state.user === false)
            window.location.reload();
        }
      });
    }

    common.do_reactUpgrade();
    window.translations = {};
  

  }

  render(props){
    if (this.state.renderLogout){
      doLogout();
      return;
    }

    var myHeaderType = window.cc.frontendCustomization.header; //header_sinistra, header_centrato, header_destra
    if(this.state.user !== false)
      $("body").append('<style>:root{--nav-container-background: url("' + env.STATIC + 'custom/background_' + window.cc.csoft + '-login.jpg") no-repeat center center/cover;}</style>');
    else
      $("body").append('<style>:root{--nav-container-background: url("' + env.STATIC + 'custom/background_' + window.cc.csoft + '-no-login.jpg") no-repeat center center/cover;}</style>');

    if(this.state.render1 && this.state.render2 && this.state.render3 ){
      var language = common.getLanguage();
      var languageChangedArray = common.find_localStorage("languageChanged");
      for (let suffix in languageChangedArray){
        let languageChanged = languageChangedArray[suffix];
        if(languageChanged !== undefined && languageChanged !== null && parseInt(languageChanged) === 1){
          language = common.getLanguage(false, "##" + suffix);
          localStorage.removeItem("languageChanged##" + suffix);
          common.setLanguage(language);
        }
      }
    }

    var activeElementsButtons = [];
    if(this.state.render1 || this.state.render2){
      if(this.state.user !== false && this.state.render3){
        activeElementsButtons.push("UserButton")
      }
      activeElementsButtons.push("LanguageButton");
      if(this.state.user !== false && this.state.render3){
        if(!this.state.userInfo.userInfo.scaduto){
          activeElementsButtons.push("Search");
          if(common.userHasFBAuth("NTY")){
              activeElementsButtons.push("NotificationButton");
          }
          if (this.state.userInfo.userInfo.GTSFINI && this.state.userInfo.userInfo.GTSFINI.PORTALE_PANNELLO_PROMO == "S" && this.state.userInfo.view_prezzi && common.userHasFBAuth("LN")){
            activeElementsButtons.push("PromoButton");
          }
          activeElementsButtons.push("MenuButton");
          if(this.state.userInfo.is_agente)
            activeElementsButtons.push("AgentiButton");
        }
      }

      if(window.cc.frontendCustomization.switch)
        activeElementsButtons.push("SwitchTheme");
    }
    /*if(this.state.render1 && this.state.render2 && this.state.user && this.state.render3)
      this.update(!this.state.verifyDone);*/

    let marche_attive = common.getAppEnabledVM();
    return(
      <div id="wrap" className="clearfix">
        <div className="content_img_enlarge" style={{display: "none"}}>
          <div className="img_enlarge">
            <img alt="" title="" className="img-responsive" src=""/>
          </div>
        </div>
        <div className="loadingDiv" style={{display: "none"}}>
          <div className="content_testo tab-content">
        		<div id="content-core">
          		<div className="row background_aggiornamento">
            		<div className="col-xl-12 text-center content_error">
              		<div className="">
                		<h1>
                		  <i className="fas fa-spinner fa-spin"></i>
                		</h1>
                    <div className="w-100"></div>
                		<h3 id="testoCaricamento">{common.getTrad("Caricamento in corso...")}</h3>
                    <div id="divCaricamento_file"></div>
              		</div>
            		</div>
          		</div>
        		</div>
      		</div>
        </div>
        <BrowserRouter basename={env.BASEPATH}>
          <>
            <Header headerType={myHeaderType} activeElements={activeElementsButtons}></Header>
            <>
              {this.state.user !== false ?
                <PrivacyBox />
                :
                <></>
              }
            </>
            <>
              {!this.state.render1 || !this.state.render2 ?
                <Routes>
                  <Route path="*" element={
                    <LoaderView headerType={this.props.myHeaderType} />
                  }/>
                </Routes>
              :
                this.state.user !== false ?
                  this.state.render3 ?
                    <>
                      <Routes>
                        {
                          this.state.userInfo.userInfo.RMI && window.cc.registrationFlag && (marche_attive === undefined || marche_attive !== undefined && marche_attive.length === 0) ?
                          [
                            <Route path="/" exact key={1} element={ 
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <RmiServices login={true} headerType={myHeaderType} userInfo={this.state.userInfo}/>
                              </AutenticationWrapper>
                            }/>,
                            <Route path="/home" key={2} exact element={
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <RmiServices login={true} headerType={myHeaderType} userInfo={this.state.userInfo}/>
                              </AutenticationWrapper>
                            }/>,
                            <Route path="/index" key={3} element={
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <RmiServices login={true} headerType={myHeaderType} userInfo={this.state.userInfo}/>
                              </AutenticationWrapper>
                            }/>,
                            <Route path="/confirmPayment" key={3} exact element={
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <Subscribe headerType={myHeaderType} confirmPayment={true}/>
                              </AutenticationWrapper>
                            }/>,
                            <Route path="/subscribe" key={4} element={
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <Subscribe headerType={myHeaderType}/>
                              </AutenticationWrapper>
                            }/>,

                          ]
                        :
                          false
                        }
                        {
                          this.state.userInfo.userInfo.RMI && window.cc.registrationFlag && (marche_attive === undefined || marche_attive !== undefined && marche_attive.length === 0) ?
                            <Route index exact element={
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <RmiServices login={true} headerType={myHeaderType} userInfo={this.state.userInfo} />
                              </AutenticationWrapper>
                            } />
                          :
                            <Route index exact element={
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <Home headerType={myHeaderType} />
                              </AutenticationWrapper>
                            } />
                        }
                        {["/index", "/home"].map((path, key) => {
                          return (
                            <Route path={path} key={key} exact element={
                              <Navigate path={path} to="/" />
                            }/>
                          )
                        })}
                        <Route path="/home" exact element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <Home headerType={myHeaderType} />
                          </AutenticationWrapper>
                        }/>
                        <Route path="/manuali" exact element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <Manuali headerType={myHeaderType} roles={this.state.userInfo.roles} login={true}/>
                          </AutenticationWrapper>
                        }/>
                        <Route path="/sitemap" exact element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <SiteMap headerType={myHeaderType} login={true}/>
                          </AutenticationWrapper>
                        }/>
                        <Route path="/my-profile" exact element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <MyProfile headerType={myHeaderType} />
                          </AutenticationWrapper>
                        }/>
                        <Route path="/logout" exact element={
                          <Logout headerType={myHeaderType} />
                        }/>
                        <Route path="/statistiche" exact element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <Statistiche headerType={myHeaderType} roles={this.state.userInfo.roles} login={true}/>
                          </AutenticationWrapper>
                        }/>
                        {this.state.userInfo.userInfo.RMI && !this.state.userInfo.userInfo.scaduto ?
                          [
                            <Route path="/rmi-services" key={1} exact element={
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <RmiServices headerType={myHeaderType} userInfo={this.state.userInfo} login={true}/>
                              </AutenticationWrapper>
                            }/>,
                            <Route path="/confirmPayment" key={2} exact element={
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <Subscribe headerType={myHeaderType} confirmPayment={true}/>
                              </AutenticationWrapper>
                            }/>,
                            <Route path="/subscribe" key={3} exact element={
                              <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                                <Subscribe headerType={myHeaderType} confirmPayment={true}/>
                              </AutenticationWrapper>
                            }/>
                          ]
                        :
                        <Route path="/console-utenti-rmi" element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <ConsoleUtentiRMI headerType={myHeaderType} userInfo={this.state.userInfo} login={true}/>
                          </AutenticationWrapper>
                        }/>
                        }
                        {["/sign-up-io", "/sign-up-employee", "/login"].map((path, key) => {
                          return (
                            <Route path={path} key={key} element={
                              <Navigate path={path} to="/" />
                            }/>
                          )
                        })}
                        { window.cc.registrationFlag ?
                          <Route path="/confirmEmail" exact element={
                            <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                              <ConfirmEmail headerType={myHeaderType} login={true}/>
                            </AutenticationWrapper>
                          }/>
                        :
                          ""
                        }
                        {
                          !this.state.userInfo.userInfo.scaduto ?
                          <Route path="/search" exact element={
                            <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                              <SearchPage headerType={myHeaderType}/>
                            </AutenticationWrapper>
                          }/>
                          :
                          <Route path="/search" element={
                            <Navigate to="/" />
                          }/>
                        }
                        <Route path="*" element={
                          <OtherLocations update={this.update}/>
                        }/>
                      </Routes>
                      
                    </>
                  :
                    <Routes>
                      <Route path="*" element={
                        <LoaderView headerType={this.props.myHeaderType} />
                      }/>
                    </Routes>
                :
                  <Routes>
                    <Route path="/sitemap" exact element={
                      <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                        <SiteMap headerType={myHeaderType} login={false}/>
                      </AutenticationWrapper>
                    }/>
                    <Route path="/manuali" exact element={
                      <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                        <Manuali roles={""} headerType={myHeaderType} login={false}/>
                      </AutenticationWrapper>
                    }/>
                    { window.cc.registrationFlag ?
                      [
                        <Route path="/confirmEmail" key={1} exact element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <ConfirmEmail headerType={myHeaderType} login={false}/>
                          </AutenticationWrapper>
                        }/>,
                        <Route path="/sign-up-io" key={2} exact element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <SignUp headerType={myHeaderType} login={false} type="io"/>
                          </AutenticationWrapper>
                        }/>,
                        <Route path="/sign-up-employee" key={3} exact element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <SignUp headerType={myHeaderType} login={false} type="employee"/>
                          </AutenticationWrapper>
                        }/>,
                        <Route path="/rmi-services" key={4} exact element={
                          <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                            <RmiServices headerType={myHeaderType} userInfo={this.state.userInfo} login={false}/>
                          </AutenticationWrapper>
                        }/>,
                      ]
                    :
                      ""
                    }
                    <Route path="/logout" element={
                      <Navigate to="/" />
                    }/>
                    <Route path="*" element={
                      <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                        <Login headerType={myHeaderType} />
                      </AutenticationWrapper>
                    }/>
                  </Routes>
              }
            </>
            <Footer></Footer>
          </>
        </BrowserRouter>
      </div>
    )
  }
}


class OtherLocationsWork extends React.Component{
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props){
    super(props);
    this.state = {};
    this.state.newContent = false;
    this.state.editContent = false;
    this.state.newTicket = false;
    this.state.editTicket = false;
    this.state.user = false;
    this.state.location = props.location;
    this.state.userInfo={};
    this.state.render1=false;
    this.state.renderLogout=false;
    this.state.goingBack = false;
    this.lastPathname = "";
    this.update = this.props.update;
  }

  UNSAFE_componentWillReceiveProps = (next) => {
    getNumberNotificheTicket();

    this.state.goingBack = true;
    var render1     = false;
    var newContent  = false;
    var newTicket   = false;
    var editTicket  = false;
    var location    = {pathname: next.location.pathname};
    var render      = false;
    var editContent = false;
    const history = createBrowserHistory();
    this.setState({render: render, location: location});

    //Con il tasto indietro non devo mai arrivare ad una pagina di edit
    var goBack = common.get_localStorage("goBack");
    if(goBack === undefined || goBack === null)
      goBack = "0";

    this.state.goingBack = false;
    var path = location.pathname;
    if(path.search("@@newTicket") > -1){
      path = path.replace("/@@newTicket", "");
      common.remove_localStorage("valoreMarca");
      location = {pathname: path};
      if(goBack === "1"){
        this.state.goingBack = true;
        common.goBack(history, false);
        newTicket = false;
      }else
        newTicket = true;
    }
    if(path.search("@@editTicket") > -1){
      path = path.replace("/@@editTicket", "");
      location = {pathname: path};
      if(goBack === "1"){
        this.state.goingBack = true;
        common.goBack(history, false);
        editTicket = false;
      }else
        editTicket = true;
    }
    if(path.search("@@new") > -1){
      path = path.replace("/@@new", "");
      location = {pathname: path};
      if(goBack === "1"){
        this.state.goingBack = true;
        common.goBack(history, false);
        newContent = false;
      }else if(this.state.userInfo.roles?.indexOf("Reviewer") > -1){
        newContent = true;
      }
    }
    if(path.search("@@edit") > -1){
      path = path.replace("/@@edit", "");
      location = {pathname: path};
      if(goBack === "1"){
        this.state.goingBack = true;
        common.goBack(history, false);
        editContent = false;
      }else if(this.state.userInfo.roles?.indexOf("Reviewer") > -1){
        editContent = true;
      }
    }

    var data = { "expand": "translations,workflow,actions", "b_size": "1"};
    if(this.state.userInfo.roles?.indexOf("Reviewer")  > -1)
      data.show_inactive = 1

    if(!this.state.goingBack){
      var new_path = common.getLanguage() + path.substring(common.getLanguage().length +1);
      if(newTicket)
        path = new_path;
      common.apiCall(env.BACKEND + path, "GET", data, true, true)
      .then(pageContent=>{
        render = true;
        this.setState({
          contentExists: (pageContent.error === false),
          pageContent: pageContent,
          render1 : render1,
          newContent : newContent,
          newTicket : newTicket,
          editTicket : editTicket,
          location : location,
          render : render,
          editContent: editContent
        });

      });
    } else{
      this.setState({ render: true });
    }
  }

  componentDidUpdate(props){
    if (this.lastPathname !== this.state.location.pathname){
      this.lastPathname = this.state.location.pathname;
      this.update(true);
    }
  }

  async UNSAFE_componentWillMount(props){
    this.state.goingBack = true;
    var render1     = false;
    var newContent  = false;
    var newTicket   = false;
    var editTicket  = false;
    var location    = { pathname: this.props.location?.pathname };
    var render      = false;
    var editContent = false;
    const history = createBrowserHistory();

    this.setState({render: render});

    //Con il tasto indietro non devo mai arrivare ad una pagina di edit
    var goBack = common.get_localStorage("goBack");
    if(goBack === undefined || goBack === null)
      goBack = "0";

    var userInfo = await common.getUserInfo();
    this.setState({ userInfo: userInfo });
    this.setState({user: this.props.user});
    var path = location.pathname;
    this.state.goingBack = false;
    if(path.search("@@newTicket") > -1){
      path = path.replace("/@@newTicket", "");
      common.remove_localStorage("valoreMarca");
      location = {pathname: path};
      if(goBack === "1"){
        this.state.goingBack = true;
        common.goBack(history, false);
        newTicket = false;
      }else
        newTicket = true;
    }
    if(path.search("@@editTicket") > -1){
      path = path.replace("/@@editTicket", "");
      location = {pathname: path};
      if(goBack === "1"){
        this.state.goingBack = true;
        common.goBack(history, false);
        editTicket = false;
      }else
        editTicket = true;
    }
    if(path.search("@@new") > -1){
      path = path.replace("/@@new", "");
      location = {pathname: path};
      if(goBack === "1"){
        this.state.goingBack = true;
        common.goBack(history, false);
        newContent = false;
      }else if(userInfo.roles?.indexOf("Reviewer") > -1){
        newContent = true;
      }
    }
    if(path.search("@@edit") > -1){
      path = path.replace("/@@edit", "");
      location = {pathname: path};
      if(goBack === "1"){
        this.state.goingBack = true;
        common.goBack(history, false);
        editContent = false;
      }else if(userInfo.roles?.indexOf("Reviewer") > -1){
        editContent = true;
      }
    }

    var data = { "expand": "translations,workflow,actions,swowner", "b_size": "1"};
    if(this.state.userInfo.roles?.indexOf("Reviewer")  > -1)
      data.show_inactive = 1
    // contenuto singolo
    if(!this.state.goingBack){
      var new_path = common.getLanguage() + path.substring(common.getLanguage().length +1);
      if(newTicket)
        path = new_path;
      common.apiCall(env.BACKEND + path, "GET", data, true, true)
      .then(pageContent=>{
        render = true;
        this.setState({
          contentExists: (pageContent.error === false),
          pageContent: pageContent,
          render1 : render1,
          newContent : newContent,
          newTicket : newTicket,
          editTicket : editTicket,
          location : location,
          render : render,
          editContent: editContent
        });
      });
    } else {
      this.setState({ render: true });
    }
  }

  render(props){
    const history = createBrowserHistory();
    var goBack = common.get_localStorage("goBack"), goBackPreviousPage = common.get_localStorage("goBackPreviousPage");
    if(goBack === undefined || goBack === null)
      goBack = "0";
    if(goBackPreviousPage === undefined || goBackPreviousPage === null)
      goBackPreviousPage = "";

    goBackPreviousPage = common.getUrlPath(goBackPreviousPage);
    
    if(goBackPreviousPage[goBackPreviousPage.length - 1] === "/" && this.state.location.pathname !== "/")
      goBackPreviousPage = goBackPreviousPage.slice(0, -1);
    else if(goBackPreviousPage[goBackPreviousPage.length - 1] !== "/" && this.state.location.pathname === "/")
      goBackPreviousPage = goBackPreviousPage + "/";
    
    //Se con il goback sono tornato nella stessa pagina vado indietro ancora
    if(goBack === "1" && goBackPreviousPage === this.state.location.pathname && !this.state.goingBack && this.state.render){
      this.state.render = false;
      this.state.goingBack = true;
      common.goBack(history, false);
      return (<Routes>
        <Route path="*" element={
          <LoaderView
            headerType={this.props.myHeaderType}
            pathname={this.state.location?.pathname} />
        } />
      </Routes>);
    }
    

    if(this.state.pageContent !== undefined)
      if(this.state.pageContent !== undefined && this.state.pageContent.description !== undefined){
        var description = this.state.pageContent.description;
        try{
          description = JSON.parse(this.state.pageContent.description);
        }catch(error){}

        if(description && description.contentType !== undefined){
          var contentType = description.contentType;
        }
      }

    if(this.state && this.state.render){
      if(!this.state.goingBack){
        common.set_localStorage("goBack", "0");
        common.set_localStorage("goBackPreviousPage", "");
      }

      if (this.state.pageContent !== undefined && this.state.pageContent.error !== undefined)
        var error = this.state.pageContent.error.toString();
      else
        error = "";

      if(this.state.renderLogout){
        doLogout();
      }

      return(
        <Routes>
          {
            this.state.userInfo.userInfo.scaduto ?
              <Navigate to="/" />
            :this.state.contentExists ?
              this.state.newContent ?
              <Route path = "*" element={
                  <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                    <EditContent
                      headerType={this.props.myHeaderType}
                      pathname={this.state.location?.pathname}
                      pageContent={this.state.pageContent}
                      roles={this.state.userInfo.roles}/>
                  </AutenticationWrapper>
                }/>
              : this.state.editContent?
                <Route path = "*" element={
                  <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                    <EditContent
                      headerType={this.props.myHeaderType}
                      pathname={this.state.location?.pathname}
                      pageContent={this.state.pageContent}
                      roles={this.state.userInfo.roles}/>
                  </AutenticationWrapper>
                }/>
              : this.state.newTicket ?
                <Route path="*" element={
                  <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                    <EditContent
                      headerType={this.props.myHeaderType}
                      pathname={this.state.location?.pathname}
                      pageContent={this.state.pageContent}
                      roles={this.state.userInfo.roles}/>
                  </AutenticationWrapper>
                }/>
              : this.state.editTicket ?
                ( !this.state.userInfo.is_admin_ticket && this.state.pageContent.review_state?.indexOf("incomplete") <= -1) ?
                  <Navigate to={this.state.location?.pathname}/>
                :
                  <Route path="*" element={
                    <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                      <EditContent
                        headerType={this.props.myHeaderType}
                        pathname={this.state.location?.pathname}
                        pageContent={this.state.pageContent}
                        roles={this.state.userInfo.roles}/>
                    </AutenticationWrapper>
                  }/>
              :
              window.cc.gestioneContenutiFlag && this.state.userInfo.userInfo.groups?.indexOf("SW Service Gestione Contenuti") > -1 && contentType === "SWContent"  ?
                <Route path="*" element={
                  <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                    <GestioneContenuti
                      headerType={this.props.myHeaderType}
                      pathname={this.state.location?.pathname}
                      roles={this.state.userInfo.roles}
                      login={true}/>
                  </AutenticationWrapper>
                }/>
              :
                <Route path="*" element={
                  <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                    <Page
                      headerType={this.props.myHeaderType}
                      pathname={this.state.location?.pathname}
                      pageContent={this.state.pageContent}
                      roles={this.state.userInfo.roles}/>
                  </AutenticationWrapper>
                }/>

            : error.search("NotFound") > -1 || error.search("TypeError")  > -1 || error.search("not found")  > -1 || error.search("Not Found")  > -1 ?
              <Route path="*" element={
                <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                  <E404
                    headerType={this.props.myHeaderType}
                    pathname={this.state.location?.pathname}
                    pageContent={this.state.pageContent}/>
                </AutenticationWrapper>
              }/>
            : error.search("Unauthorized") > -1 ?
              <Route path="*" element={
                <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                  <E401
                    headerType={this.props.myHeaderType}
                    pathname={this.state.location?.pathname}
                    pageContent={this.state.pageContent}/>
                </AutenticationWrapper>
              }/>
            :
              <Route path="*" element={
                <AutenticationWrapper action={() => { this.update(VERIFY_AUTHENTICATION) }}>
                  <E500
                    headerType={this.props.myHeaderType}
                    pathname={this.state.location?.pathname}
                    pageContent={this.state.pageContent}/>
                </AutenticationWrapper>
              }/>
          }
        </Routes>
      )
    }else{
      return (
        <Routes>
          <Route path="*" element={
            <LoaderView
              headerType={this.props.myHeaderType}
              pathname={this.state.location?.pathname} />
          }/>
        </Routes>
      )
    }
  }

}


const OtherLocations = props => {
  const location = useLocation()
  return <OtherLocationsWork location={location} {...props} /> // your component
}

const AutenticationWrapper = props => {
  /*console.log("passo")
  console.log(props.children)*/
  useEffect(() => {
    props.action();
  });
  
  return props.children;
};

export default AppRouter;
