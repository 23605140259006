import React from 'react';

const common = require('../common/common');
const env = require("../../config.js").default;

class FilterVM extends React.Component{
  constructor(props){
		super(props);
    this.state = {};
    this.state.marche_attive = this.props.marche_attive;
    this.state.tableType = this.props.tableType !== undefined ? this.props.tableType : '';
    this.tipologie_ticket_attive = common.getTicketTypesByBrand();
  }

  componentDidMount(){
        const $ = window.$;
    if(this.state.tableType === 'ticket'){
      var new_marche_attive = [];
      var marcheAttive = this.state.marche_attive;
      for (var i = 0; i < marcheAttive.length; i++) {
        if(this.tipologie_ticket_attive[marcheAttive[i].ZFORN] !== undefined)
          new_marche_attive.push(marcheAttive[i]);
      }
      this.setState({marche_attive: new_marche_attive})
    }

    function format(state) {
      if (!state.id || state.id === 'tutti_modelli') return state.text; // optgroup
      return '<img style="height: 30px;" title="' + state.text +'" src="' + env.SWAN + 'static/images/loghi/' + state.id.toLowerCase() + '.png"/>'; // optgroup
    }

    $("#select_vm").append('<option value="">' + common.getTrad("Tutte le marche") + '</option>').select2({
      templateResult: format,
      templateSelection: format,
      escapeMarkup: function(m) { return m; },
      language: common.getLanguage(),
      placeholder: common.getTrad("Seleziona una marca"),
    });

    $('#select_vm').on('select2:select keyup select2:unselect', (e) => {
      e.preventDefault();
			var marca = '';
      common.set_localStorage("valoreModello", '');

			if(e.target.value === '' || e.target.value === 'tutti_modelli'){
				marca = "";
        $('#EDTNTELA').val('');
        if(this.state.tableType !== 'ticket' && this.state.tableType !== 'ticket_vehicle') {
          $('.select_modelli').empty("<option value='tutti_modelli'>"+ common.getTrad("Seleziona un modello") + "</option>").select2({placeholder: common.getTrad("Seleziona prima una marca")});
          common.set_localStorage("valoreMarca", '');
        }else{
          common.set_localStorage("filtriTicketsTipologie", '');
          $('.tipologieTicket').empty("<option value='tutti_modelli'>"+ common.getTrad("Seleziona un tipo") + "</option>").select2({placeholder: common.getTrad("Seleziona un tipo")});
          var tipologia_ticket = "";
          common.remove_localStorage('valoreTipologiaTicket');
        }
      }else{
        if(this.state.tableType !== 'ticket' && this.state.tableType !== 'ticket_vehicle') {
          common.set_localStorage("valoreMarca", e.target.value);
        }
        common.set_localStorage("filtriTicketsTipologie", '');
				marca = e.target.value;
			}
      
			var parametri = {
				filtro_marca: marca,
        tipologia_ticket: tipologia_ticket,
			}
      this.props.onClick("filtro_marca", parametri, this.state.tableType.indexOf('ticket') > -1 ? false : true);
			return false;
		});

    if (common.get_localStorage("valoreMarca") !== undefined && common.get_localStorage("valoreMarca") !== null && common.get_localStorage("valoreMarca") !== '') {
      $("select#select_vm").val(common.get_localStorage("valoreMarca")).trigger("change");
      }
   
  }

  componentDidUpdate(){
    const $ = window.$;
  }

  render(props){
  	return(
      <div className={(this.props.customClass !== undefined ? this.props.customClass : "col-xl-6 col-lg-6") +  " col-md-12 col-sm-12 col-xs-12 row-eq-height"} role="alert" id="ricerca_filtro_modello">
        <div className="col-12 p-0">
          <p>{common.getTrad("Filtra per casa costruttrice")}</p>
          <div className="form-group selectSwCustom">
            <select name="select_vm" className="form-control select_marche" id="select_vm">
              <option value="tutti_modelli">{common.getTrad("Tutte le marche")}</option>
              {this.state.marche_attive.map((item, key) => {
                return (
                  <option key={key} value={item.ZFORN}>{item.DFORN}</option>
                )
              })}
            </select>
          </div>
        </div>
      </div>
     )
  }
}

export default FilterVM;
