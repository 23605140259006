import React from 'react';
import SignUpIo from '../SignUp/SignUpIo';
// import SignUpIndipendente from '../SignUp/SignUpIndipendente';

const env = require("../../config.js").default;

class SignUp extends React.Component {
  componentDidMount(){
    document.title = document.title = window.cc.companyInfo.service + " Service" + " - SignUp";
    document.getElementById('body').className='sign_up';
  }
  constructor(props) {
    super(props);
    this.state = {};
      this.state.type  = this.props.type;
  }

  render(props){
      return(
    			<SignUpIo type={this.props.type}></SignUpIo>
      )
  }
}



export default SignUp;
